import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import WorkCardView from './WorkCardView';
import {
    storeWorkCard,
    setWorkCardStatusPending,
    setWorkCardInformation,
    addWorkCardDocument,
    deleteWorkcard,
    updateWorkCard,
    deleteMaterial,
    deleteWorkCardDocument,
    deleteMeasurementGroupWorkCard,
    deleteWorkPhase,
    downloadDocument,
    fetchWorkcardPermits,
    fetchWorkCardLimitedWorkStatuses,
} from './actions';
import {
	fetchViewSettingsByGroups,
    setMachineGroupId,
    getWorkCardColors,
    fetchDocumentMetadatas
} from './../../../commonActions/actions';
import FormList from '../../../components/FormList';
import { HasRight, DefineColors } from '../../../components/HelperFunctions';
import i18n from '../../../translations/i18n';
import { fetchMachine } from '../../machines/actions';
import { fetchPurchaseOrderItems } from './../../purchases/actions';
import { isOrderable } from '../../purchases/utils';

const mapStateToProps = (state, ownProps) => {
    const wcId = parseInt(ownProps.match.params.workCardId, 10);
    const isOffline = !state.offline.online;
    const wc = state.workcards.workcards.find(wc => wc.id === wcId);
    const materialsArr = !isOffline
        ? state.workcards.materials
        : state.workcards.storedMaterials
    const permitsArr = !isOffline
        ? state.workcards.permits && state.workcards.permits.filter(permit => permit.workCardId === wcId)
        : state.workcards.storedPermits && state.workcards.storedPermits.filter(permit => permit.workCardId === wcId);
    const { SparePartReservationEnabled } = state.settings && state.settings.noviConfigs;

    let documentsData = state.documents.workCard.workcardDocuments;

    const materials = materialsArr
        .filter(mat => mat.workCard.id === wcId)
        .map(mat => {
            return {
                id: mat.id,
                name: mat.sparePart
                    ? FormList({
                        list: [
                            mat.sparePart.code,
                            mat.sparePart.name,
                            mat.sparePart.type
                        ],
                        listSeparator: ' // '
                    })
                    : mat.wildSparePart,
                details: {
                    amount: mat.amount,
                    takeAmount: mat.takeAmount,
                    type: mat.type,
                    memo: mat.memo
                },
                editable: true,
                removable: true,
                sparePartId: mat.sparePart && mat.sparePart.id,
                type: mat.sparePart ? 'mat' : 'wild_mat',
                spReservationId: mat.sparePartReservationItemId,
                purchaseOrderItem: mat.purchaseOrderItem,
                orderable: isOrderable(mat)
            }
        });

    let wcColors = {}

    const formDocumentsData = (documentData, docType = null) => {
        return documentData.map(doc => {
            return {
                id: doc.id,
                name: doc.caption || doc.documentLink,
                details: {
                    type: doc.type,
                    description: doc.description
                },
                editable: false,
                removable: true,
                view: true,
                createDate: doc.createDate,
                pathName: doc.documentLink,
                isUrl: doc.isUrl ? true : doc?.files ? doc.files[0]?.isUrl || false : false,
                downloadLink: doc.downloadLink,
                type: docType || 'doc'
            }
        });
    }

    const documents = {
        workcardDocuments: documentsData.documents ? formDocumentsData(documentsData.documents) : [],
        machineDocuments: documentsData.machineDocuments ? formDocumentsData(documentsData.machineDocuments, 'm_doc') : [],
        maintenanceDocumentLists: {
            subList: [
                { subHeader: i18n.t('MAINTENANCE_SUB_PLAN_HEADER'), documents: documentsData.maintenanceSubPlanDocuments ? formDocumentsData(documentsData.maintenanceSubPlanDocuments, 'maintenance_doc') : [] },
                { subHeader: i18n.t('OPERATOR_MAINTENANCE'), documents: documentsData.operatorMaintenanceDocuments ? formDocumentsData(documentsData.operatorMaintenanceDocuments, 'maintenance_doc') : [] },
                { subHeader: i18n.t('ROUTE_MAINTENANCE'), documents: documentsData.routeMaintenancePhaseDocuments ? formDocumentsData(documentsData.routeMaintenancePhaseDocuments, 'maintenance_doc') : [] }
            ]
        }
    }

    const permits = permitsArr && permitsArr.map(permit => {
        return {
            id: permit.id,
            name: permit.name,
            details: [],
            editable: HasRight('workpermitedit', state.settings.userRights),
            removable: HasRight('workpermitdele', state.settings.userRights),
            type: 'permit'
        }
    });

    const colorConfigs = {
        colors: state.settings.colors,
        settings: state.settings.colorSettings,
        config: state.settings.colorConfig['FillTags']
    }

    const workPhaseColorConfigs = {
        colors: state.settings.colors,
        settings: state.settings.colorSettings,
        config: state.settings.colorConfig['FillPhaseTags']
    }

    const getWorkCardHourCards = (hcs) => {
        let hourCards = [];

        hcs?.forEach(hc => {
            hourCards.push({ ...hc, type: 'hourcard' })
        });

        return hourCards
    }

    const getWorkCardMeasurements = (m) => {
        let measurements = [];

        m?.forEach(m => {
            measurements.push({ ...m.measurementGroup, type: 'measurement' })
        });

        return measurements
    }

    if (wc) {
        wcColors = { [wc.id]: DefineColors(wc, colorConfigs) };

        if (wc.phases && wc.phases.length > 0) {
            wc.phases.map(wp => {
                wp.colors = DefineColors(wp, workPhaseColorConfigs);
            });
        }
    }

    return {
        workcard: wc,
        wcDetails: state.workcards.details,
        wcColors: wcColors,
        materials: materials,
        documents: documents,
        documentQueue: state.documents.queue,
        permits: permits,
        location: ownProps.location,
        history: ownProps.history,
        viewSettings: state.workcards.viewSettings,
        settings: state.settings,
        status: state.workcards.wcStatus,
        isOffline,
        originalDocuments: documentsData,
        machine: state.machines.machines.find(m => m.id === wc?.machine?.id),
        hourCards: getWorkCardHourCards(state.workcards.hourCards) || [],
        limitedWorkStatuses: state.workcards.limitedWorkStatuses,
        measurements: getWorkCardMeasurements(state.workcards.workCardMeasurements) || [],
        sparePartReservationEnabled: SparePartReservationEnabled === 'True',
        purchaseOrderItems: state.workcards.purchaseOrderItems ?? [],
        documentMetadatas: state.documents.metadatas,
        workPermits: state.workcards.workPermits
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    storeWorkCard,
    setWorkCardStatusPending,
    setWorkCardInformation,
    fetchWorkcardPermits,
    deleteWorkcard,
    deleteMaterial,
    deleteWorkCardDocument,
    deleteMeasurementGroupWorkCard,
    deleteWorkPhase,
    downloadDocument,
    updateWorkCard,
    fetchViewSettingsByGroups,
    addWorkCardDocument,
    fetchMachine,
    setMachineGroupId,
    fetchWorkCardLimitedWorkStatuses,
    fetchPurchaseOrderItems,
    getWorkCardColors,
    fetchDocumentMetadatas
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WorkCardView);
