import React, { useState } from "react";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select'
import i18n from "translations/i18n";

import { SortTypes } from "constants/quickSearches/SortTypes";

declare interface IProps {
    sortType: IItemPropertyType;
    onChange: Function;
    options: object;
    setWrapperRef: (node: any) => void;
}

export const SortMenu = (props: IProps) => {

    const { sortType, onChange, options, setWrapperRef } = props;
    const [open, setOpen] = useState(false)

    return (
        <div className="header-element-container" ref={setWrapperRef}>
            <div 
                className={'header-action-element ' + ((sortType?.id == SortTypes.worklist.NEWEST.id || sortType.id == null) ? '' : 'icon-primary')} 
                style={{ position: "relative" }} 
                onClick={() => setOpen(!open)} 
            >
                <FontAwesomeIcon icon={faSort} size="2x" />
                {open && <div className="overlay-canvas"></div>}
                <Select
                    className="react-select-container hide-input"
                    classNamePrefix="react-select"
                    value={sortType}
                    options={Object.values(options).map(({id, label}) => {
                        return {
                            id: id,
                            label: i18n.t(label),
                            value: label
                        }
                    })}
                    menuIsOpen={open}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}