import { faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ActionButton from '../buttons/ActionButton';
import ListWithRemoveItem, { IListWithRemoveItemProps } from '../lists/ListWithRemoveItem';

const ListWithButtonAndRemoveItem = (props: IProps) => {

    const title = props.title ? <h2>{props.title}</h2> : '';

    const button = (
        <ActionButton handleClick={props.handleBtnClick}>
            <FontAwesomeIcon icon={faFileMedical} />
            <span className="left-margin-5">
                {props.buttonText}
            </span>
        </ActionButton>
    )

    return (
        <React.Fragment>
            {title}
            <ListWithRemoveItem
                itemList={props.itemList}
                removeItem={props.removeItem}
                noItemsText={props.noItemsText ?? ''}
            />
            <div className="v-paddings-15">
                {button}
            </div>
        </React.Fragment>
    )
}

interface IProps extends IListWithRemoveItemProps {
    title?: string;
    buttonText: string;
    handleBtnClick: () => void;
}

export default ListWithButtonAndRemoveItem;