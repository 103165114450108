import { settingsAPI }  from './config/settingsAPI';
import Moment from 'moment';

export function getWorkCardsData(filterParams = null, all = false, wcId = null) {
    const workCards = [
        {
            id: 1445,
            machines: [{ id: 3700, name: '700-10-110-02 / NOSTIN 250 KG' }],
            orderer: { id: 4, name: 'Matti-Liisa Meikäläinen' },
            workStatus: 1,
            workType: 5,
            faultDescription: 'Viikkohuolto',
            urgency: 4,
            machineGroupId: 1,
            machineHalt: false,
            workers: [{ id: 3, name: 'Eppu Kuusela' }, { id: 4, name: 'Antti Asentaja' }],
            workPhases: [
                {
                    id: 1,
                    name: 'Asennuksen valmistelut',
                    status: { id: 3, name: 'Valmis' },
                    hours: 3,
                    instructions: 'Tsekkaa pora',
                    workers: [{ id: 2, name: 'Antti Asentaja' }, { id: 1, name: 'Kirjautunut Käyttäjä' }],
                    comments: 'Valmista tuli. Helppo homma'
                },
                {
                    id: 2,
                    name: 'Automaation säädöt',
                    status: { id: 2, name: 'Keskeytetty' },
                    hours: 2,
                    instructions: 'Automaatio täytyy säätää',
                    workers: [{ id: 2, name: 'Antti Asentaja' }],
                    comments: null
                },
                {
                    id: 3,
                    name: 'Koekäyttö',
                    status: { id: 1, name: 'Ilmoitettu' },
                    hours: 0,
                    instructions: 'Paina isoa vihreää nappia',
                    workers: [{ id: 1, name: 'Kirjautunut Käyttäjä' }],
                    comments: null
                },
            ],
            workHours: 0,
            procedure: 'Vaihdettiin öljyt',
            orderDate: '4.1.2019 07:00:00',
            workCanBegin: '4.11.2018 20:38:00',
            workCanEnd: '15.1.2019 09:00:00',
            workBegin: '5.11.2018 08:45:00',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 2235,
            machines: [{ id: 3288, name: '700-10-10 / ALUMIINI 1' }],
            orderer: { id: 2, name: 'Aku-Paavo Ankka' },
            workStatus: 1,
            workType: 2,
            faultDescription: 'Mennyt tylsäksi',
            urgency: 1,
            machineGroupId: 1,
            machineHalt: true,
            workers: [{ id: 5, name: 'Testi Testaaja' }],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '4.1.2019 07:00:00',
            workCanBegin: '4.11.2018 20:38:00',
            workCanEnd: '15.1.2019 09:00:00',
            workBegin: '5.11.2018 08:45:00',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 777,
            machines: [{ id: 6233, name: '700-10 / KIINTEISTÖT' }],
            orderer: { id: 4, name: 'Mikko Mallikas'},
            workStatus: 4,
            workType: 4,
            faultDescription: '',
            urgency: 2,
            machineGroupId: 1,
            machineHalt: false,
            workers: [{ id: 4, name: 'Antti Asentaja'}],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '6.11.2018 20:38:00',
            workCanEnd: '5.12.2018 16:00:00',
            workBegin: '5.12.2018 08:00:00',
            workEnded: '5.12.2018 15:12:00',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 1557,
            machines: [{ id: 3288, name: '700-10-10 / ALUMIINI 1'}],
            orderer: { id: 3, name: 'Risto Reipas'},
            workStatus: 2,
            workType: 3,
            faultDescription: 'Osa rikki',
            urgency: 2,
            machineGroupId: 1,
            machineHalt: false,
            workers: [
                { id: 1, name: 'Kirjautunut Käyttäjä' },
                { id: 3, name: 'Eppu Kuusela' },
                { id: 4, name: 'Antti Asentaja' },
                { id: 5, name: 'Testi Testaaja' }
            ],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '4.11.2018 20:38:00',
            workCanEnd: '',
            workBegin: '5.11.2018 08:45:00',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 4655,
            machines: [{ id: 5503, name: '700-10-100 / PAKKAUSTARVIKEVARASTO'}],
            orderer: { id: 3, name: 'Risto Reipas'},
            workStatus: 4,
            workType: 1,
            faultDescription: 'Linko pää vuotaa',
            urgency: 3,
            machineGroupId: 1,
            machineHalt: false,
            workers: [{id: 1, name: 'Kirjautunut Käyttäjä'}],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '2.1.2019 08:45:00',
            workCanEnd: '',
            workBegin: '',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 4633,
            machines: [{ id: 3245, name: '700-10-110 / PUUMUOTTI'}],
            orderer: { id: 4, name: 'Mikko Mallikas'},
            workStatus: 1,
            workType: 6,
            faultDescription: 'Pumppujen rasvausreitti',
            urgency: 4,
            machineGroupId: 1,
            machineHalt: false,
            workers: [{ id: 1, name: 'Kirjautunut Käyttäjä'}],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '12.12.2018 08:45:00',
            workCanEnd: '',
            workBegin: '',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 2135,
            machines: [{ id: 9821, name: '700-10-110-01 / NOSTIN 500 KG' }],
            orderer: {id: 2, name: 'Aku-Paavo Ankka'},
            workStatus: 2,
            workType: 6,
            faultDescription: 'Pumppujen rasvausreitti',
            urgency: 4,
            machineGroupId: 1,
            machineHalt: true,
            workers: [{ id: 1, name: 'Kirjautunut Käyttäjä'}],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '13.12.2018 08:49:00',
            workCanEnd: '',
            workBegin: '',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 1235,
            machines: [{ id: 3700, name: '700-10-110-02 / NOSTIN 250 KG' }],
            orderer: { id: 3, name: 'Risto Reipas' },
            workStatus: 2,
            workType: 2,
            faultDescription: 'Kattopuhallinreitti',
            urgency: 3,
            machineGroupId: 1,
            machineHalt: true,
            workers: [{id: 1, name: 'Kirjautunut Käyttäjä'}],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '16.12.2018 08:45:00',
            workCanEnd: '',
            workBegin: '',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 1243,
            machines: [{ id: 7752, name: '700-20 / MUOTIT' }],
            orderer: { id: 4, name: 'Mikko Mallikas' },
            workStatus: 3,
            workType: 1,
            faultDescription: 'Köysi katki',
            urgency: 3,
            machineGroupId: 1,
            machineHalt: true,
            workers: [],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '5.1.2019 08:45:00',
            workCanEnd: '',
            workBegin: '',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 8821,
            machines: [{ id: 2211, name: '700-20-10 / KÄSITYÖLINJA MUOTIT JA PIIRUSTUKSET'}],
            orderer: { id: 1, name: 'Matti-Liisa Meikäläinen'},
            workStatus: 1,
            workType: 1,
            faultDescription: 'Ei toimi',
            urgency: 4,
            machineGroupId: 1,
            machineHalt: false,
            workers: [{id: 2, name: 'Touko Tekijä'}],
            workPhases: [
                {
                    id: 1,
                    name: 'Projektin suunnittelu ja dokumentointi',
                    status: { id: 1, name: 'Ilmoitettu' }, 
                    hours: null,
                    instructions: 'Dokumentit liitteeksi',
                    workers: [{ id: 1, name: 'Kirjautunut Käyttäjä' }], 
                    comments: ''
                },
                { 
                    id: 2, 
                    name: 'Perustuksien valaminen', 
                    status: { id: 1, name: 'Ilmoitettu' }, 
                    hours: null,
                    instructions: '', 
                    workers: [{ id: 1, name: 'Kirjautunut Käyttäjä' }],
                    comments: ''
                },
                { 
                    id: 3, 
                    name: 'Laiteasennus', 
                    status: { id: 1, name: 'Ilmoitettu' },
                    hours: null,
                    instructions: '', 
                    workers: [{ id: 1, name: 'Kirjautunut käyttäjä' }, { id: 2, name: 'Antti Asentaja' }], 
                    comments: ''
                }
            ],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '16.1.2019 08:45:00',
            workCanEnd: '',
            workBegin: '',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 521,
            machines: [{ id: 547, code: '700-50-70-10 / JÄÄHDYTYSLIINA NO.1 (LI-301)' }],
            orderer: { id: 4, name: 'Mikko Mallikas'},
            workStatus: 1,
            workType: 1,
            faultDescription: 'Köysi katki',
            urgency: 3,
            machineGroupId: 1,
            machineHalt: true,
            workers: [],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '4.1.2019 07:00:00',
            workCanBegin: '5.1.2019 08:45:00',
            workCanEnd: '15.1.2019 09:00:00',
            workBegin: '',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: false
        },
        {
            id: 32,
            machines: [
                { id: 831, code: 'IL-352 / ILMASTOINTI KATTOKOPISSA PUHALLIN 1.' },
                { id: 832, code: 'IL-353 / ILMASTOINTI KATTOKOPISSA PUHALLIN 2.' },
                { id: 833, code: 'IL-354 / ILMASTOINTIHUONE SUODATTIMET' }
            ],
            orderer: {},
            workStatus: 1,
            workType: 5,
            faultDescription: 'Kattopuhallinreitti',
            urgency: 3,
            machineGroupId: 1,
            machineHalt: false,
            workers: [],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '8.10.2018 09:00:00',
            workCanEnd: '12.02.2019 07:00:00',
            workBegin: '3.02.2019 08:00:00',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: true
        },
        {
            id: 34,
            machines: [
                { id: 846, name: 'PU-106 / PU-106 / LATTIAKAIVON 1. PUMPPU' },
                { id: 847, name: 'PU-107 / LATTIAKAIVON 2. PUMPPU' },
                { id: 1173, name: 'P-6303 / Jäähdytysvesipumppu' },
                { id: 692, name: 'PU-004 / PROSESSIVESIPUMPPU 2' }
            ],
            orderer: {},
            workStatus: 1,
            workType: 5,
            faultDescription: 'Pumppujen rasvausreitti',
            urgency: 4,
            machineGroupId: 1,
            machineHalt: false,
            workers: [],
            workPhases: [],
            workHours: 0,
            procedure: '',
            orderDate: '',
            workCanBegin: '3.11.2018 12:39:00',
            workCanEnd: '01.03.2019 11:00:00',
            workBegin: '3.02.2019 08:00:00',
            workEnded: '',
            faultBegin: '',
            lastModified: '',
            routeWc: true
        }
    ];

    let searchParams = {
        workStatus: null,
        startDate: null,
        endDate: null,
    }

    const definedSearchParams = getDefinedSearchOptions('workcard');
    const defaultSearchParams = settingsAPI['defaultWorkCardSearch'];

    // Priority: 
    //  1) use search filter as primary option
    //  2) use defined default search option from DB if filter option is not set
    //  3) use default search option from settingsAPI (factory default) if no other options exist
    searchParams.workStatus = filterParams && filterParams.quickSearch && filterParams.quickSearch.value
        ? filterParams.quickSearch.value
        : definedSearchParams && definedSearchParams.workStatus
            ? definedSearchParams.workStatus
            : defaultSearchParams.workStatus;

    searchParams.startDate = filterParams && filterParams.filterDateStart
        ? filterParams.filterDateStart
        : definedSearchParams && definedSearchParams.startDate
            ? definedSearchParams.startDate
            : defaultSearchParams.startDate;

    searchParams.endDate = filterParams && filterParams.filterDateEnd
        ? filterParams.filterDateEnd
        : definedSearchParams && definedSearchParams.endDate
            ? definedSearchParams.endDate
            : defaultSearchParams.endDate;

    let workCardsList = [];
    const startDate = Moment(searchParams.startDate, 'DD.MM.YYYY');
    const endDate = Moment(searchParams.endDate, 'DD.MM.YYYY');

    // TODO: next foreach is pretending to be a backend query => filters workcards by given parameters, remove this when backend is done.
    // Offline filtering will be done in workcardsReducer
    if (wcId) {
        const workCard = workCards.find(wc => wc.id === parseInt(wcId, 10));

        return {
            workCardsData: [workCard],
            timePeriod: [searchParams.startDate, searchParams.endDate]
        }
    }

    workCards.forEach(function (wc) {
        const wcStartDate = Moment(wc.workCanBegin, 'DD.MM.YYYY');

        let valid = false;

        if (all || (wc.workStatus === searchParams.workStatus || searchParams.workStatus === 0)) {
            valid = true;
        }

        if (valid
            && wcStartDate.diff(startDate, 'hours') >= 0
            && wcStartDate.diff(endDate, 'hours') <= 0) {
            workCardsList.push(wc);
        }
    });

    return {
        workCardsData: workCardsList,
        timePeriod: [searchParams.startDate, searchParams.endDate]
    }
    
}

export function getMachines(mId = null) {
    const machines = [
        {
            id: 6233,
            typeId: 0,
            parentId: null,
            code: '700-10',
            name: 'KIINTEISTÖT',
            level: 'ESIMERKKITEHDAS OY',
            model: 'ANTURIT',
            costCenter: null,
            information: 'Lorem ipsum',
            children: [3288, 5503, 3245],
            supplierId: 224,
            manufacturerId: 882,
            productNumber: '18326/320 KG',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;',
            machineImg: getImageAttachment('./anturi.jpg'),
            extraData: [
                { id: 1, name: 'Kappaleen max. leveys (mm)', value: 38000 },
                { id: 2, name: 'Kappaleen max. pituus (mm)', value: 6500 },
                { id: 3, name: 'Kappaleen max. paino (kg)', value: 2000 },
            ]
        },
        {
            id: 2,
            typeId: 0,
            parentId: 6233,
            code: '700-10-10',
            name: 'ALUMIINI 1',
            level: '700-10 / KIINTEISTÖT',
            model: '234264-xxx',
            costCenter: null,
            information: null,
            children: [],
            supplierId: 552,
            manufacturerId: 371,
            productNumber: '9030028/15',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;1,700-10,KIINTEISTÖT;',
            machineImg: getImageAttachment('./anturi.jpg')
        },
        {
            id: 5503,
            typeId: 0,
            parentId: 6233,
            code: "700-10-100",
            name: 'PAKKAUSTARVIKEVARASTO',
            level: '700-10 / KIINTEISTÖT',
            model: 'PE-LL',
            costCenter: null,
            information: null,
            children: [],
            supplierId: 335,
            manufacturerId: 882,
            productNumber: '9030028/15',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;1,700-10,KIINTEISTÖT;2,700-10-10,ALUMIINI 1;'
        },
        {
            id: 3245,
            typeId: 0,
            parentId: 6233,
            code: '700-10-110',
            name: 'PUUMUOTTI',
            level: '700-10 / KIINTEISTÖT',
            model: 'HZUR 461 B3',
            costCenter: null,
            information: null,
            children: [9821, 3700],
            supplierId: 446,
            manufacturerId: 371,
            productNumber: '103-139B',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;1,700-10,KIINTEISTÖT;2,700-10-10,ALUMIINI 1;',
            machineImg: getImageAttachment('./moottori.jpg'),
            extraData: [
                { id: 4, name: 'Muotin leveys (mm)', value: 120 },
                { id: 5, name: 'Muotin pituus (mm)', value: 200 },
                { id: 6, name: 'Muotin paino (kg)', value: 4000 },
            ]
        },
        {
            id: 9821,
            typeId: 0,
            parentId: 3245,
            code: '700-10-110-01',
            name: 'NOSTIN 500 KG',
            level: '700-10-110 / PUUMUOTTI',
            model: 'GEMINI KIINNIPUHALLUS',
            costCenter: null,
            information: null,
            children: [],
            supplierId: 224,
            manufacturerId: 114,
            productNumber: '103-139B',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;1,700-10,KIINTEISTÖT;4,700-10-110,PUUMUOTTI;',
            machineImg: getImageAttachment('./nostin.jpg')
        },
        {
            id: 3700,
            typeId: 0,
            parentId: 3245,
            code: '700-10-110-02',
            name: 'NOSTIN 250 KG',
            level: '700-10-110 / PUUMUOTTI',
            model: 'SUUPUHALLUSMUOTIT',
            costCenter: null,
            information: null,
            children: [],
            supplierId: 335,
            manufacturerId: 114,
            productNumber: 'M-08T065',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;1,700-10,KIINTEISTÖT;4,700-10-110,PUUMUOTTI;',
            machineImg: getImageAttachment('./nostin.jpg')
        },
        {
            id: 7752,
            typeId: 3,
            parentId: null,
            code: '700-20',
            name: 'MUOTIT',
            level: 'ESIMERKKITEHDAS OY',
            model: 'VERTEX ARKISTO',
            costCenter: null,
            information: 'Lorem ipsum',
            children: [2211],
            supplierId: 335,
            manufacturerId: 877,
            productNumber: 'M-08T067',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;'
        },
        {
            id: 108,
            typeId: 3,
            parentId: 7752,
            code: '700-20-10',
            name: 'KÄSITYÖLINJA MUOTIT JA PIIRUSTUKSET',
            level: '700-20 / MUOTIT',
            model: null,
            costCenter: null,
            information: 'Lorem ipsum',
            children: [],
            supplierId: 446,
            manufacturerId: 877,
            productNumber: '7.7219.01.07',
            hierarchyPath: ';l2,,ESIMERKKITEHDAS OY;1,700-20,MUOTIT;'
        },
    ];

    if (!mId) return machines;

    return machines.find(m => m.id === mId);
}

export function getRoutePhases(wcId = null) {
    const routeWorkCardPhases = {
        32: [
            {
                id: 1,
                machine: 'IL-352 / ILMASTOINTI KATTOKOPISSA PUHALLIN 1.',
                machineId: 831,
                description: 'Tarkastus',
                isDone: true,
                comment: '',
                doneDate: '',
                workHours: 0,
                dataType: 1
            },
            {
                id: 2,
                machine: 'IL-353 / ILMASTOINTI KATTOKOPISSA PUHALLIN 2',
                machineId: 832,
                description: 'Tarkastus',
                isDone: false,
                comment: '',
                doneDate: '',
                workHours: 0,
                dataType: 2
            },
            {
                id: 3,
                machine: 'IL-354 / ILMASTOINTIHUONE SUODATTIMET',
                machineId: 833,
                description: 'Tarkastus',
                isDone: true,
                comment: '',
                doneDate: '',
                workHours: 0,
                dataType: 1
            }
        ],
        34: [
            {
                id: 12,
                machine: 'PU-106 / LATTIAKAIVON 1. PUMPPU',
                machineId: 846,
                description: 'rasvaus, mobil 68 4g',
                isDone: false,
                comment: '',
                doneDate: '',
                workHours: 0,
                dataType: 2
            },
            {
                id: 13,
                machine: 'PU-107 / LATTIAKAIVON 2. PUMPPU',
                machineId: 847,
                description: 'rasvaus, mobil 68 4g',
                isDone: true,
                comment: '',
                doneDate: '',
                workHours: 0,
                dataType: 2
            },
            {
                id: 21,
                machine: 'P-6303 / Jäähdytysvesipumppu',
                machineId: 1173,
                description: 'tarkastus + rasvaus',
                isDone: false,
                comment: '',
                doneDate: '',
                workHours: 0,
                dataType: 2
            },
            {
                id: 24,
                machine: 'PU-004 / PROSESSIVESIPUMPPU 2',
                machineId: 692,
                description: 'ohjeistus',
                isDone: false,
                comment: '',
                doneDate: '',
                workHours: 0,
                dataType: 1
            }
        ]
    };

    return routeWorkCardPhases[parseInt(wcId,10)];
}

export function getOperatorMaintenancePhases(opMaintenanceId = null) {
    const operatorMaintenancePhases = {
        1: [
            {
                id: 1,
                caption: 'IFVW 112 kulmapäiden(2 kpl) rasvaus KIRJOITA LISÄTTY MÄÄRÄ KOMMENTTIKENTTÄÄN GRAMMOINA!!!!',
                isDone: false,
                dataType: 1,
                comment: '',
                imgName: 'huolto_3.png',
                imgAttachment: getImageAttachment('./huolto_3.png')
            },
            {
                id: 2,
                caption: 'Työkalunvaihtajan lähestymiskytkimet',
                isDone: false,
                dataType: 2,
                comment: '',
            },
            {
                id: 3,
                caption: 'Puhdista jäähdytyskoneikon lämmönvaihdin imuroimalla',
                isDone: false,
                dataType: 2,
                comment: '',
            },
        ],
        3: [
            {
                id: 10,
                caption: 'Öljymäärän tarkistus',
                isDone: false,
                dataType: 1,
                comment: '',
            },
            {
                id: 11,
                caption: 'Lämpötilanmittaus',
                isDone: false,
                dataType: 2,
                comment: '',
            },
        ],
        6: [
            {
                id: 14,
                caption: 'Puhdista moottori',
                isDone: false,
                dataType: 2,
                comment: '',
                imgName: 'huolto_1.jpg',
                imgAttachment: getImageAttachment('./huolto_1.jpg')
            },
            {
                id: 15,
                caption: 'Tarkasta pyyhkimet',
                isDone: false,
                dataType: 1,
                comment: '',
                imgName: 'huolto_2.jpg',
                imgAttachment: getImageAttachment('./huolto_2.jpg')
            },
        ],
    }

    return operatorMaintenancePhases[opMaintenanceId];
}

export function getOperatorMaintenances(machineId = null) {
    const operatorMaintenances = [
        {
            id: 1,
            faultDescription: 'Viikkohuolto df ds sdfds asdsad adadsd sd ad adsd adad sdsad  d ds',
            description: 'Viikottainen huolto djjdskjd d sdsad d sksssssssssssssssssssssssssssssssssssssss',
            machineHalt: false,
            urgency: 4,
            procedures: [],
            workHours: 0.5,
            ordererText: '',
            machine: [
                { id: 9821, name: '700-10-110-01 / NOSTIN 500 KG' },
            ],
            workType: { id: 5, name: 'A14 Operaattorihuolto' },
            workCardDetailIds: [5],
            workCardType: 5,
            workerGroup: [],
            machineGroup: 1,
            opMaintenance: true
        },
        {
            id: 2,
            faultDescription: 'Viikkohuolto',
            description: 'Viikottainen huolto',
            machineHalt: false,
            urgency: 4,
            procedures: [],
            workHours: 0,
            ordererText: '',
            machine: [
                { id: 1173, name: 'P-6303 / Jäähdytysvesipumppu' }
            ],
            workType: { id: 5, name: 'A14 Operaattorihuolto' },
            workCardDetailIds: [5],
            workCardType: 5,
            workerGroup: [],
            machineGroup: 1,
            opMaintenance: true
        },
        {
            id: 3,
            faultDescription: 'Puhtauden tarkastus',
            description: 'Kahtotaan onko siistiä',
            machineHalt: false,
            urgency: 3,
            procedures: [],
            workHours: 0,
            ordererText: '',
            machine: [
                { id: 9821, name: '700-10-110-01 / NOSTIN 500 KG' },
            ],
            workType: { id: 10, name: 'C Lakisääteinen tarkastus' },
            workCardDetailIds: [10],
            workCardType: 5,
            workerGroup: [],
            machineGroup: 1,
            opMaintenance: true
        },
        {
            id: 4,
            faultDescription: 'Puhtauden tarkastus',
            description: '',
            machineHalt: false,
            urgency: 3,
            procedures: [],
            workHours: 0,
            ordererText: '',
            machine: [
                { id: 1173, name: 'P-6303 / Jäähdytysvesipumppu' }
            ],
            workType: { id: 10, name: 'C Lakisääteinen tarkastus' },
            workCardDetailIds: [5],
            workCardType: 5,
            workerGroup: [],
            machineGroup: 1,
            opMaintenance: true
        },
        {
            id: 5,
            faultDescription: 'Viikkohuolto',
            description: 'Viikottainen huolto',
            machineHalt: false,
            urgency: 4,
            procedures: [],
            workHours: 1,
            ordererText: '',
            machine: [
                { id: 547, name: '700-50-70-10 / JÄÄHDYTYSLIINA NO.1 (LI-301)' },
            ],
            workType: { id: 5, name: 'A14 Operaattorihuolto' },
            workCardDetailIds: [5],
            workCardType: 5,
            workerGroup: [],
            machineGroup: 1,
            opMaintenance: true
        },
        {
            id: 6,
            faultDescription: 'Päivittäistarkastus',
            description: '',
            machineHalt: false,
            urgency: 4,
            procedures: [],
            workHours: 0,
            ordererText: '',
            machine: [
                { id: 547, name: '700-50-70-10 / JÄÄHDYTYSLIINA NO.1 (LI-301)' },
            ],
            workType: { id: 10, name: 'C Lakisääteinen tarkastus' },
            workCardDetailIds: [10],
            workCardType: 5,
            workerGroup: [],
            machineGroup: 1,
            opMaintenance: true
        },
    ];

    let opMaintenances = [];

    operatorMaintenances.forEach(function (opM) {
        if (opM.machine.find(m => m.id === machineId)) {
            opMaintenances.push(opM);
        }
    });

    return opMaintenances;
}

export function getOperatorMaintenanceLevels() {
    return [
        {
            id: 1,
            machinegroupid: 1,
            name: 'Porakoneet'
        },
        {
            id: 4,
            machinegroupid: 1,
            name: 'Höyrystimet'
        },
        {
            id: 7,
            machinegroupid: 1,
            name: 'Autot'
        },
        {
            id: 8,
            machinegroupid: 2,
            name: 'Vekottimet'
        } 
    ];
}

export function getRouteMaintenanceLevels() {
    return [
        {
            id: 1,
            rownumber: 0,
            machinegroupid: 1,
            name: 'Pumput ja puhaltimet'
        },
        {
            id: 2,
            rownumber: 0,
            machinegroupid: 1,
            name: 'Päivittäiset reitit'
        },
        {
            id: 3,
            rownumber: 0,
            machinegroupid: 1,
            name: 'Viikkokierrokset'
        },
        {
            id: 4,
            rownumber: 0,
            machinegroupid: 1,
            name: 'Kuukausireitit'
        },
        {
            id: 5,
            rownumber: 0,
            machinegroupid: 2,
            name: 'Kaivotarkastukset'
        }
    ];
}

export function getMaintenances(type = null) {
    const maintenances = {
        //Maintenance plans:
        plan: [
            {
                id: 1,
                machinegroupid: 1,
                name: 'LAITEKOHTAISET SUUNNITELMAT',
                rownumber: 0,
                maintenanceplanid: null
            },
            {
                id: 543,
                machinegroupid: 1,
                name: 'LAITETYYPPIKOHTAISET SUUNNITELMAT',
                rownumber: 0,
                maintenanceplanid: null
            },
            {
                id: 2,
                machinegroupid: 1,
                name: 'Linja 8 Huollot',
                rownumber: 1136,
                maintenanceplanid: 528
            },
            {
                id: 3,
                machinegroupid: 1,
                name: 'Linja 10 Huollot',
                rownumber: 1047,
                maintenanceplanid: 528
            },
            {
                id: 5,
                machinegroupid: 1,
                name: '700-60-10-20 MÄNGINSYÖTTÖ',
                rownumber: 1161,
                maintenanceplanid: 1
            },
            {
                id: 509,
                machinegroupid: 1,
                name: 'PÄIVITTÄISET REITIT',
                rownumber: 0,
                maintenanceplanid: 508
            },
            {
                id: 547,
                machinegroupid: 2,
                name: 'Mittalaitteiden kalibroinnnit',
                rownumber: 0,
                maintenanceplanid: null
            },
            {
                id: 129,
                machinegroupid: 1,
                name: 'MÄ-211 MÄNKISIILON NOSTURI',
                rownumber: 1150,
                maintenanceplanid: 1
            },
        ],
        // Operator maintenances:
        operator: [
            {
                id: 1,
                name: 'Viikkohuolto',
                description: '',
                machinehalt: 0,
                urgency: 4,
                procedure: null,
                instructions: null,
                workhours: 0.5,
                orderertext: null,
                operatormaintenancemachineid: 1
            },
            {
                id: 2,
                name: 'Puhtauden tarkastus',
                description: '',
                machinehalt: 1,
                urgency: 2,
                procedure: null,
                instructions: null,
                workhours: 1,
                orderertext: null,
                operatormaintenancemachineid: 1
            },
            {
                id: 3,
                name: 'Päivittäistarkastus',
                description: '',
                machinehalt: 0,
                urgency: 4,
                procedure: null,
                instructions: null,
                workhours: null,
                orderertext: null,
                operatormaintenancemachineid: 3
            },
            {
                id: 4,
                name: 'Päivittäistarkastus',
                description: '',
                machinehalt: 0,
                urgency: 1,
                procedure: null,
                instructions: null,
                workhours: 0.58,
                orderertext: null,
                operatormaintenancemachineid: 6
            }
        ],
        // Route maintenances:
        route: [
            {
                id: 1,
                name: 'Kattopuhallinreitti',
                rownumber: 1,
                interval: 1,
                intervalunit: 3,
                duration: 1,
                durationunit: 0,
                startdate: '2018-11-27 10:27:00.000',
                routemaintenancelevelid: 4,
                personid: 132,
                orderertext: null,
                urgency: 1,
                transferwhencomplete: 0,
            },
            {
                id: 2,
                name: 'Kellarireitti',
                rownumber: 1,
                interval: 1,
                intervalunit: 1,
                duration: 1,
                durationunit: 0,
                startdate: '2017-01-18 10:40:00.000',
                routemaintenancelevelid: 1,
                personid: 132,
                orderertext: null,
                urgency: 4,
                transferwhencomplete: 1,
            },
            {
                id: 3,
                name: 'Pumppujen rasvausreitti',
                rownumber: 2,
                interval: 2,
                intervalunit: 1,
                duration: 1,
                durationunit: 0,
                startdate: '2018-10-29 12:39:00.000',
                routemaintenancelevelid: 1,
                personid: 132,
                orderertext: null,
                urgency: 4,
                transferwhencomplete: 1,
            },
            {
                id: 5,
                name: 'Kaivoreitti 1',
                rownumber: 1,
                interval: 1,
                intervalunit: 2,
                duration: 1,
                durationunit: 0,
                startdate: '2018-06-20 10:09:00.000',
                routemaintenancelevelid: 5,
                personid: 133,
                orderertext: null,
                urgency: 1,
                transferwhencomplete: 0,
            }
        ],
        // Running time maintenances:
        RTM: [
            {
                id: 2,
                counterlast: 0,
                checkinterval: 1000,
                running: 1,
                runningtimemaintenancegroupid: 1,
                active: 0,
                precounterpercent: 5,
                pretimedays: 0,
                counterid: 3,
                machinemaintenanceplan_id: 491
            },
            {
                id: 3,
                counterlast: 0,
                checkinterval: 1000,
                running: 1,
                runningtimemaintenancegroupid: 1,
                active: 0,
                precounterpercent: 5,
                pretimedays: 0,
                counterid: 1,
                machinemaintenanceplan_id: 144
            }
        ]
    }

    if (!type) {
        return ({maintenances: maintenances['plan'], counters: []});
    } else {
        if (type === 'RTM') {
            return ({maintenances: maintenances[type], counters: getCounters()})
        } else {
            return ({ maintenances: maintenances[type], counters: []});
        }
    }
}

export function getCounters() {
    return [
        {
            id: 1,
            name: 'Käyttötunnit',
            value: 2200,
            machineid: 1
        },
        {
            id: 2,
            name: 'Linjan 1 Käyttötunnit',
            value: 850,
            machineid: 557
        },
        {
            id: 3,
            name: '636464643702417634',
            value: 500,
            machineid: 321
        }
    ]
}

export function getWorkPhase(wpId, wcId) {
    const data = getWorkCardsData(null, false, wcId);

    return data.workCardsData[0].workPhases.find(wp => wp.id === parseInt(wpId, 10));
}

export function getWorkcardDocuments(id = null, wcId = null) {
    const documents = [
        { id: 1, workcardId: 1445, caption: 'lp-001.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '1.4.2019' },
        { id: 2, workcardId: 1445, caption: 'lp-002.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '1.4.2019' },
        { id: 3, workcardId: 1445, caption: 'agreement_sample.pdf', description: 'Sopimuspohja', type: 'Sopimus', createDate: '2.4.2019' },
        { id: 4, workcardId: 2235, caption: 'lp-004.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '2.4.2019' },
        { id: 5, workcardId: 2235, caption: 'lp-005.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '2.4.2019' },
        { id: 6, workcardId: 2235, caption: 'lp-006.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '3.4.2019' },
        { id: 7, workcardId: 777, caption: 'lp-007.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '4.4.2019' },
        { id: 8, workcardId: 777, caption: 'lp-008.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '4.4.2019' },
        { id: 9, workcardId: 777, caption: 'lp-009.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '5.4.2019' },
        { id: 10, workcardId: 1557, caption: 'lp-010.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '6.4.2019' },
        { id: 11, workcardId: 1557, caption: 'lp-011.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '7.4.2019' },
        { id: 12, workcardId: 1557, caption: 'lp-012.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '7.4.2019' },
        { id: 13, workcardId: 4655, caption: 'lp-013.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '8.4.2019' },
        { id: 14, workcardId: 4655, caption: 'lp-014.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '8.4.2019' },
        { id: 15, workcardId: 4655, caption: 'lp-015.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '8.4.2019' },
        { id: 16, workcardId: 4633, caption: 'lp-016.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '9.4.2019' },
        { id: 17, workcardId: 4633, caption: 'lp-017.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '9.4.2019' },
        { id: 18, workcardId: 4633, caption: 'lp-018.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '9.4.2019' },
        { id: 19, workcardId: 2135, caption: 'lp-019.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '10.4.2019' },
        { id: 20, workcardId: 2135, caption: 'lp-020.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '11.4.2019' },
        { id: 21, workcardId: 2135, caption: 'lp-021.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '11.4.2019' },
        { id: 22, workcardId: 1235, caption: 'lp-022.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '12.4.2019' },
        { id: 23, workcardId: 1235, caption: 'lp-023.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '12.4.2019' },
        { id: 24, workcardId: 1235, caption: 'lp-024.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '13.4.2019' },
        { id: 25, workcardId: 1243, caption: 'lp-025.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '14.4.2019' },
        { id: 26, workcardId: 1243, caption: 'lp-026.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '15.4.2019' },
        { id: 27, workcardId: 1243, caption: 'lp-027.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '15.4.2019' },
        { id: 28, workcardId: 8821, caption: 'lp-028.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '16.4.2019' },
        { id: 29, workcardId: 8821, caption: 'lp-029.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '17.4.2019' },
        { id: 30, workcardId: 8821, caption: 'lp-030.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '18.4.2019' },
        { id: 31, workcardId: 521, caption: 'lp-031.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '18.4.2019' },
        { id: 32, workcardId: 521, caption: 'lp-032.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '18.4.2019' },
        { id: 33, workcardId: 521, caption: 'lp-033.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '19.4.2019' },
        { id: 34, workcardId: 32, caption: 'lp-034.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '20.4.2019' },
        { id: 35, workcardId: 32, caption: 'lp-035.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '21.4.2019' },
        { id: 36, workcardId: 32, caption: 'lp-036.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '22.4.2019' },
        { id: 37, workcardId: 34, caption: 'lp-037.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '23.4.2019' },
        { id: 38, workcardId: 34, caption: 'lp-038.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '24.4.2019' },
        { id: 39, workcardId: 34, caption: 'lp-039.pdf', description: 'Tekniset tiedot', type: 'Käyttöohje', createDate: '24.4.2019' },
    ];

    if (id) {
        return documents.find(doc => doc.id === id);
    } else if (wcId) {
        return documents.filter(doc => doc.workcardId === parseInt(wcId, 10));
    } else {
        return documents;
    }
}

export function getWorkcardMaterials(id = null) {
    const materials = [
        {
            id: 1,
            workcardId: 1445,
            materialId: 1,
            code: '',
            name: 'ARR00481 / URAKUULALAAKERI // 6203-2Z',
            type: '',
            amount: 2,
            unit: 'kpl',
            memo: ''
        },
        {
            id: 2,
            workcardId: 1445,
            materialId: 2,
            code: '',
            name: 'ZBB00690 / PORANTERÄ // 5598-0I',
            type: '',
            amount: 1,
            unit: 'kpl',
            memo: '',
        },
        {
            id: 3,
            workcardId: 2235,
            materialId: 3,
            code: '',
            name: 'ARR00999 / SUODATINPUTKI // 1112-AA',
            type: '',
            amount: 1,
            unit: 'kpl',
            memo: ''
        },
        {
            id: 4,
            workcardId: 2235,
            materialId: 3,
            code: '',
            name: 'ARR00999 / SUODATINPUTKI // 1112-AA',
            type: '',
            amount: 1,
            unit: 'kpl',
            memo: '',
        },
    ];

    if (id) {
        const singleMaterial = materials.find(mat => mat.id === id);

        return singleMaterial;
    } else {
        return materials;
    }
}

export function getWorkcardPermits(id = null, wcId = null) {
    const permits = [
        {
            id: 1,
            workcardId: 1445,
            worker: [{ id: 3, name: 'Eppu Kuusela' }, { id: 4, name: 'Antti Asentaja' }],
            name: 'Tulityölupa',
            status: 3,
            group: 'workpermittulityölupa',
            tts: '',
            tra: '',
            suoritettavatyo: 'Hitsaushommia tiedossa',
            ohjeet: ' Kempin sivuilta löytyy hyviä histausohjeita',
            tyonsuorittajat: 'Kirjautunut käyttäjä ja Viron Vahvistus',
            signature: null
        },
        {
            id: 2,
            workcardId: 1445,
            name: 'Sähkötyövalmiuslupa',
            status: 3,
            group: 'workpermitsähkötyövalmiuslupa',
            tts: '',
            tra: '',
            suoritettavatyo: 'Turvavalaistuksen kaapelointi',
            ohjeet: '',
            tyonsuorittajat: [],
            signature: null
        },
        {
            id: 3,
            workcardId: 1445,
            name: 'Normaalityölupa',
            status: 1,
            group: 'workpermitgroup',
            tts: '',
            tra: '',
            suoritettavatyo: 'Sitä sun tätä',
            ohjeet: '',
            tyonsuorittajat: 'Vladimir Kuznetsov',
            signature: null
        }
    ];

    if (id) {
        return permits.find(permit => permit.id === parseInt(id, 10));
    } else if (wcId) {
        return permits.filter(permit => permit.workcardId === parseInt(wcId, 10));
    } else {
        return permits;
    }
}

export function getWorkcardPermitFields(groupType = null) {
    const permitFields = [
    ];

    return permitFields;
}

export function getViewSettingsData(type, machineGroup) {
    if (Array.isArray(type)) {
        const fields = {};

        type.forEach(key => {
            fields[key] = settingsAPI[key.toLowerCase()];
        });
        //const combinedFields = Object.assign.apply({}, [...fields]);
        return fields;
    }

    return settingsAPI[type.toLowerCase()];
}

export function getRequiredFieldsData(type) {
    //getRequiredFields('workcard');
    //OR getRequiredFields(['workcardnotice','workcardreport']);
    if (Array.isArray(type)) {
        //const fields = type.map(key => {
        //    return requiredFields[key.toLowerCase()];
        //});
        //const combinedFields = Object.assign.apply({}, [...fields]);
        //return combinedFields;
    }

    return requiredFields[type.toLowerCase()];
}

export function getDefinedSearchOptions(type = null) {
    // TODO: query params to backend for fetching saved search params for view (workcards or machines)
    // searchOptions object will be removed afterwards and only queried data is returned from backend
    const searchOptions = {
        'workcard': null,
        'machine': null
    };

    return searchOptions[type];
}

const requiredFields = {
    'workcard': {
        'code': true,
        'orderdate': false,
        'urgency': false,
        'machinehalt': false,
        'faultbegin': false,
        'orderer': false,
        'workcanbegin': true,
        'machine': false,
        'faultdescription': false,
        'workcanend': true,
        'machinepart': false,
        'faulttype': false,
        'faultreason': false,
        'faultlocation': false,
    },
    'workcardreport': {
        'workstatus': false,
        'workCardType': false,
        'worker': false,
        'procedure': false,
        'workhours': false,
        'workbegin': false,
        'workended': false
    },
    'workcardphase': {
        'name': true,
        'status': false,
        'hours': false,
        'instructions': false,
        'workers': false,
        'comments': false,
    },
}

export function getImageAttachment(pathName = null) {
    if (!pathName) return null;

    const imgPaths = require.context('./images');
    let filePath = '';

    imgPaths.keys().forEach(function (key) {
        if (key === pathName) {
            filePath = imgPaths(key);
        }
    });

    return filePath;
}

export function getWorkStatusOptions() {
    const workStatuses = [
        { id: 1, label: 'WORKSTATUS1' },
        { id: 2, label: 'WORKSTATUS2' },
        { id: 3, label: 'WORKSTATUS3' },
        { id: 4, label: 'WORKSTATUS4' },
        { id: 5, label: 'WORKSTATUS5' }
    ]

    return workStatuses
}

export function getUrgencyOptions() {
    const urgencies = [
        { id: 1, label: 'URGENCY1' },
        { id: 2, label: 'URGENCY2' },
        { id: 3, label: 'URGENCY3' },
        { id: 4, label: 'URGENCY4' },
    ]
    
    return urgencies;
}

export function getWorkCardTypeOptions() {
    const workCardTypes = [
        { id: 1, label: 'WORK_CARD_TYPE1' },
        { id: 2, label: 'WORK_CARD_TYPE2' },
        { id: 3, label: 'WORK_CARD_TYPE3' },
        { id: 4, label: 'WORK_CARD_TYPE4' },
        { id: 5, label: 'WORK_CARD_TYPE5' }
    ]
    
    return workCardTypes;
}
