import * as types from '../../config/actionTypes';
import {
    getOperatorMaintenanceLevels,
    getRouteMaintenanceLevels,
    getMaintenances
} from '../../testData';

export const fetchMaintenanceItems = (params) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_MAINTENANCE_ITEMS}_PENDING`,
        });
        try {
            let maintenanceItems = {};

            if (params.type === 'operatorLevels') {
                maintenanceItems = params.level ? getOperatorMaintenanceLevels() : getMaintenances('operator');
            } else if (params.type === 'routeLevels') {
                maintenanceItems = params.level ? getRouteMaintenanceLevels() : getMaintenances('route');
            } else {
                maintenanceItems = getMaintenances;
            }
            
            dispatch({
                type: `${types.FETCH_MAINTENANCE_ITEMS}_FULFILLED`,
                payload: {
                    items: maintenanceItems,
                    params: params
                }
            })
        } catch (error) {
            dispatch({
                type: `${types.FETCH_MAINTENANCE_ITEMS}_REJECTED`,
                payload: error
            })
        }
    }
)