import React from "react";
import { css } from '@emotion/core';
import { FadeLoader, PropagateLoader, BarLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    height: 100%!important;
    margin-top: 50%;
`;

export class Loader extends React.Component<any, any> {
    render() {
        const { status, initialLoader = false, loading, ready = true, loadingText = "" } = this.props;

        return (
            <div>
                {(status === 'pending' || !ready) && <div className="loading-container">
                    {!initialLoader
                        ? <FadeLoader
                            css={override.styles}
                            height={15}
                            width={5}
                            radius={2}
                            margin={'2px'}
                            color={'#888888'}
                            loading={true}
                        />
                        : <BarLoader
                            color={'#e30059'}
                            height={5}
                            width={200}
                        />}
                    <div className="loading-text">
                        {loadingText}
                    </div>
                </div>}
                {loading && <div className="fixed-loading-container">
                    <FadeLoader
                        css={override.styles}
                        height={15}
                        width={5}
                        radius={2}
                        margin={'2px'}
                        color={'#888888'}
                        loading={true}
                    />

                    <div className="progress">
                        <div className="progress-bar progress-bar-success"
                            role="progressbar"
                            aria-valuenow={this.props.progressPercentage}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ width: this.props.progressPercentage + '%' }}
                        >
                            {this.props.progressPercentage}%
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}