import React from 'react';
import { Col, Row } from 'react-bootstrap';
import TextTruncate from 'react-text-truncate';

const OperatorMaintenanceCardTitle = (props: IProps) => {
    return (
        <Row>
            <Col xs={12}>
                <b>
                    <TextTruncate line={1}
                        truncateText="�"
                        text={props.text}
                    />
                </b>
            </Col>
        </Row>
    );
}

interface IProps {
    text: string;
}

export default OperatorMaintenanceCardTitle;