import React from 'react';
import i18n from '../../translations/i18n';
import DialogHeader from '../dialogs/DialogHeader';

// If field name != translationKey, add a correcting entry here
const mapFieldToTKey = {
    workCardCode: "WORKCARD_CODE",
};

const SearchModalHeader = ({ customTitle = null }) => {
    const newTitle = customTitle ? `${i18n.t("SEARCH_CAPTION")}: ${i18n.t(mapFieldToTKey[customTitle] ?? customTitle)}` : null;
    return (
        <DialogHeader>
            {i18n.t(newTitle ?? 'LAAJENNETTU_HAKU')}
        </DialogHeader>
    );
}

export default SearchModalHeader;