import React from 'react';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import '../../../styles/global.scss';
import './../styles/dashboardCustomizer.scss';
import i18n from '../../../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ComponentTypes } from 'constants/dashboard/componentTypes';

export interface IProps {
    selectComponent: (elementValue: number) => void;
}

const DashboardItems = (props: IProps) => (
    <div>
        <Container className="dboard-container-section bottom-margin-100">
            <Row>
                <Col xs={12}>
                    <Card className="dashboard-panel narrow-container no-margins action" onClick={() => props.selectComponent(ComponentTypes.Link)}>
                        <div className="info-section">
                            <div className="info-title">
                                {i18n.t('DASHBOARD_BUTTON_COUNTER_SEARCH')}
                            </div>
                            <div className="info-details">
                                <p>1</p>
                                {i18n.t('PCS').toLowerCase()}
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card className="dashboard-button-panel" onClick={() => props.selectComponent(ComponentTypes.Button)}>
                        <div className="dashboard-button-container">
                            <Button className="action input-row-button novi-default-btn-color">
                                <div className="flex-center">
                                    <span className="button-label">{i18n.t('DASHBOARD_BUTTON_SCANNER')}</span>
                                    <FontAwesomeIcon icon={faQrcode} size="2x" />
                                </div>
                            </Button>
                        </div>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card className="dashboard-button-panel" onClick={() => props.selectComponent(ComponentTypes.Search)}>
                        <div className="dashboard-button-container">
                            <Button className="action input-row-button novi-default-btn-color">
                                <div className="flex-center">
                                    <span className="button-label">{i18n.t('DASHBOARD_BUTTON_SEARCH')}</span>
                                    <FontAwesomeIcon icon={faSearch} size="2x" />
                                </div>
                            </Button>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
);

export default DashboardItems;
