import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import i18n from '../../../translations/i18n';

export default function Form(props) {
    const { inputFields, formData, onChange, selectAll } = props;

    const options = {
        warehouse: useSelector(state => state['warehouse']['warehouses']) ?? [],
        supplier: useSelector(state => state['settings']['thirdParties']['byType'][1]) ?? []
    };

    return (
        <Container>
            <div className="form-table-container">
                <form className={'sparepart-form'} >
                    {inputFields.map( ({ label, type, key }) => {
                        return <FormElement
                            name='spareParts'
                            type={type}
                            label={i18n.t(label)}
                            value={formData[key]}
                            options={options[key]}
                            onChange={x => {
                                if (type === 'singleline-checkbox') {
                                    const val = !formData[key];
                                    onChange({ [key]: val});
                                }
                                else if (type === 'select') {
                                    onChange({ [key]: x});
                                }
                                else {
                                    const str = x?.target?.value ?? "";
                                    onChange({ [key]: str});
                                }
                            }}
                            extraFunctions={{ QR: false, BR: false }}
                            isDisabled={false}
                        />
                    })}
                    <FormElement
                        name='spareParts'
                        type={"singleline-checkbox"}
                        label={i18n.t("SELECT_ALL")}
                        value={selectAll.val}
                        onChange={e => selectAll.fn(!e)}
                        extraFunctions={{ QR: false, BR: false }}
                    />
                </form>
            </div>
        </Container>
    );
}