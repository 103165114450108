import React from 'react';

const PanelText = (props: IProps) => {
    let className = 'no-margins word-break';
    className += props.small ? ' small' : '';

    return (
        <p className={className}>
            {props.children}
        </p>
    )
}

interface IProps {
    small?: boolean;
    children: React.ReactNode;
}

export default PanelText;