import React from 'react';
import i18n from '../../../../translations/i18n';
import FormElement from '../../work-card/components/FormElement';
import SelectField from 'components/form/SelectField';
import { mapIdValueToOptionType } from 'scenes/machines/utils';

const WorkCardFilters = ({
    values,
    options,
    handleChange,
    handleSelect,
    handleDatetime,
    limitToField,
    visibleFields,
    extraFields
}: IProps) => (
    limitToField ? (
        <FormElement
            name={limitToField}
            type="text"
            value={values[limitToField]}
            onChange={handleChange}
        />
    ) : (
        <div>
            <FormElement
                name="startDate"
                type="datetime"
                label={i18n.t('ALK_PVM')}
                value={values.startDate}
                onChange={handleDatetime}
            />
            <FormElement
                name="endDate"
                type="datetime"
                label={i18n.t('LOPPVM')}
                value={values.endDate}
                onChange={handleDatetime}
            />
            {visibleFields.find(x => x.field == 'code') && <FormElement
                name="workCardCode"
                type="text"
                label={i18n.t('WORKCARD_CODE')}
                value={values.workCardCode}
                onChange={handleChange}
            />}
            {visibleFields.find(x => x.field == 'machine') && <FormElement
                name="machineCode"
                type="text"
                label={i18n.t('MACHINE_CODE')}
                value={values.machineCode}
                onChange={handleChange}
            />}
            {visibleFields.find(x => x.field == 'workstatus') && <FormElement
                name="workStatuses"
                type="worker"
                label={i18n.t('TYON_TILA')}
                value={values.workStatuses}
                options={options.statusTypes}
                onChange={handleSelect}
            />}
            {visibleFields.find(x => x.field == 'urgency') && <FormElement
                name="urgencies"
                type="worker"
                label={i18n.t('URGENCY')}
                value={values.urgencies}
                options={options.urgencyOptions}
                onChange={handleSelect}
            />}
            {visibleFields.find(x => x.field == 'orderer') && <FormElement
                name="ordererIds"
                type="multi+user"
                label={i18n.t('ORDERER')}
                value={values.ordererIds}
                options={options.ordererOptions}
                onChange={handleSelect}
            />}
            {visibleFields.find(x => x.field == 'worker') && <FormElement
                name="workerIds"
                type="multi+user"
                label={i18n.t('TEKIJA')}
                value={values.workerIds}
                options={options.workerOptions}
                onChange={handleSelect}
            />}
            {visibleFields.find(x => x.field == 'workergroup') && <FormElement
                name="workerGroupIds"
                type="multi+user"
                label={i18n.t('WORKER_GROUP')}
                value={values.workerGroupIds}
                options={options.workerGroups}
                onChange={handleSelect}
            />}
            {visibleFields.find(x => x.field == 'worktype') && <SelectField
                name="detailIds"
                label={i18n.t('TYOLAJI')}
                value={values.detailIds}
                options={options.workTypeOptions}
                onChange={handleSelect}
                isMulti
            />}
            <FormElement // Always display workCardType selection
                name="workCardTypes"
                type="worker"
                label={i18n.t('WORK_CARD_TYPE')}
                value={values.workCardTypes}
                options={options.workCardTypeOptions.map(wcType => ({ id: wcType.id, label: i18n.t(wcType.label) }))}
                onChange={handleSelect}
            />
            {visibleFields.find(x => x.field == 'machinehalt') && <SelectField
                name="machineHalt"
                label={i18n.t('MACHINE_HALT')}
                value={values.machineHalt}
                options={[
                    { value: 'false', label: i18n.t('NO') },
                    { value: 'true', label: i18n.t('YES') }
                ]}
                onChange={handleSelect}
            />}

            {extraFields.details.map(field => {

                const mappedOptions = options['details']
                    .filter(i => !i.hidden && i.group == field.field).sort((a, b) => a.rowNumber - b.rowNumber)
                    .map(i => mapIdValueToOptionType(i, true));

                return <FormElement 
                    name={field.field}
                    label={i18n.t(field.translationKey)}
                    options={mappedOptions}
                    value={values[field.field]}
                    onChange={handleSelect}
                    type="worker"
                />;
            })}

            {extraFields.extraDatas.map(field => {
                return <FormElement 
                    name={field.field}
                    label={i18n.t(field.translationKey)}
                    value={values.extraDatas[field.field] ?? ''}
                    onChange={(e) => {
                        const { name, value } = e.target;
                        const newObj = {
                            ...(values.extraDatas ?? {}),
                            [name]: value
                        }
                        handleSelect(newObj, { name: "extraDatas" });
                    }}
                    type={field.type.includes("number") ? "float-input" : "text"}
                />;
            })}

            {extraFields.costpools.map(field => {
                    const { costpoolOptions } = options;
                    const isMachineCostpool = field.group === 'machine';

                    const [id] = field.field.split("_").slice(-1);
                    const actualOptions = costpoolOptions[id]?.sort((a, b) => a.tabOrder - b.tabOrder).map(i => ({ value: i.id, label: i.translationKey })) ?? [];

                    // Since we're using machine viewSettings instead of workcard, we have to prepend the machine prefixes that are normally on workcard viewSettings
                    const name = isMachineCostpool ? `machine_${field.field}` : field.field;
                    const tKey = isMachineCostpool ? `COSTPOOL_ON_MACHINE_${field.translationKey}` : field.translationKey;

                    let translation = "";

                    // COSTPOOL_ON_<Target> (eg. MACHINE) + <Space or _> + <translationKey>
                    const matches = tKey.match(/^(COSTPOOL_ON_[A-Z0-9]*)[ |_](.*)$/i);

                    if (matches && matches[1] && matches[2]) {
                        translation = `${i18n.t(matches[1])} ${matches[2].split("_").map(i => i18n.t(i)).join(" ")}`;
                    }
                    else {
                        const translated = i18n.t(tKey);
                        translation = tKey == translated ? translated.replace("_", " ").toLowerCase() : translated;
                    }

                    return <FormElement 
                        name={name}
                        label={i18n.t(translation)}
                        options={actualOptions}
                        value={values[name]}
                        onChange={handleSelect}
                        type="worker"
                    />;
                })}

                <FormElement // Always display machine id field
                    name="machineIds"
                    type="number-input"
                    label={`${i18n.t('MACHINE')} ${i18n.t("NOTIFICATION_CONDITION_FIELD_ID")}`}
                    value={values.machineIds?.[0]}
                    onChange={handleChange}
                />

        </div>
    )
);

interface IProps {
    values: IWorkListFilters;
    options: Options;
    limitToField: string;
    visibleFields: IViewSetting[];
    extraFields: { [key: string]: IViewSetting[] };
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (value, actionMeta) => void;
    handleDatetime: (value, name) => void;
}

type Options = {
    workTypeOptions: IOptionType[];
    urgencyOptions: IdLabel[];
    ordererOptions: IdLabel[];
    workerOptions: IdLabel[];
    workerGroups: IdLabel[];
    statusTypes: IdLabel[];
    workCardTypeOptions: IdLabel[];
    details: IWorkCardDetail[];
    costpoolOptions: { [costpoolGroupId: string]: ICostPool[] };
}

export default WorkCardFilters;