import * as types from '../../config/actionTypes';
import { HandleError, Toaster } from '../../components/HelperFunctions';
import noviAPI from '../../api/noviAPI';
import { IRedirect } from '../machines/actions';

export type IFetchSparePartExtraData = (id: number) => any;

export const fetchSparePartExtraDatas: IFetchSparePartExtraData = id => (
    (dispatch) => {
        noviAPI.sparePartExtraDatas.fetch(id)
            .then(response => {
                dispatch({
                    type: `${types.FETCH_SPARE_PART_EXTRA_DATAS}_FULFILLED`,
                    payload: {
                        data: response.data
                    }
                })
            }).catch(error => {
                HandleError(error, `${types.FETCH_SPARE_PART_EXTRA_DATAS}`)
            })
    }
)

export const fetchSparePartWarehouseData = (sparePartId: number) => (
    (dispatch) => {
        noviAPI.warehouseSparePartLinks.fetchSparePartWarehouseData(sparePartId)
            .then(response => {
                dispatch({
                    type: `${types.FETCH_SPARE_PART_WAREHOUSE_DATA}`,
                    payload: response.data
                })
            })
            .catch(error => {
                HandleError(error, 'Fetch spare part warehouse data')
                dispatch({
                    type: `${types.FETCH_SPARE_PART_WAREHOUSE_DATA}_REJECTED`,
                    payload: error
                })
            })
    }
)

export const deleteSparePart = (sparePartId: number, redirect: IRedirect) => (
    dispatch => {
        noviAPI.spareParts.delete(sparePartId)
            .then(() => {
                dispatch({
                    type: `${types.DELETE_SPAREPART}`,
                    payload: sparePartId
                });

                const location = {
                    pathname: '/warehouse',
                    state: { notificationMsg: 'SPAREPART_DELETED' }
                }

                redirect(location);
            })
            .catch(error => {
                console.log(error);
                HandleError(error, 'Delete spare part')
            });
    })

export const fetchDocuments = id => (
    dispatch => {
        dispatch({
            type: `${types.FETCH_SPARE_PART_DOCUMENTS}_PENDING`,
        });
        noviAPI.spareParts.fetchDocuments(id)
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_SPARE_PART_DOCUMENTS}_FULFILLED`,
                    payload: data
                })
            })
            .catch(error => {
                HandleError(error, 'Fetch spare part documents');

                dispatch({
                    type: `${types.FETCH_SPARE_PART_DOCUMENTS}_REJECTED`,
                    payload: error
                })
            })
    }
);

export const addDocument = (document) => (
    dispatch => {
        noviAPI.documents.add(document)
            .then(response => {
                dispatch(fetchDocuments(document.SparePartId));
                Toaster({ msg: 'DOCUMENT_ADDED', type: 'success' });
            })
            .catch(error => {
                HandleError(error, "Add document to spare part");
            })
    }
);

export const deleteDocument = (id) => (
    dispatch => {
        noviAPI.documents.delete(id)
            .then(response => {
                dispatch({
                    type: types.DELETE_SPARE_PART_DOCUMENT,
                    payload: id,
                })
            })
            .catch(error => {
                HandleError(error, 'Delete spare part document');
            })
    }
)

export const fetchAnnualSparePartConsumption = (id: number) => (
    dispatch => {
        noviAPI.warehouseOperations.fetchAnnualSparePartConsumption(id)
            .then(response => {
                dispatch({
                    type: types.FETCH_ANNUAL_SPARE_PART_CONSUMPTION,
                    payload: response.data
                })
            })
            .catch(error => {
                HandleError(error, 'Fetch spare part takes')
            })
    }
)
