import React, { useState } from 'react';
import { Accordion, Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import PanelContainer from '../../work-schedule/work-card/components/PanelContainer';
import i18n from '../../../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEllipsisV, faStream } from '@fortawesome/free-solid-svg-icons';
import { propertiesToLowerCase, Toaster } from '../../../components/HelperFunctions';
import { Link } from 'react-router-dom';
import request from '../../../api/interceptors';
import PopoverAction from '../../../components/PopoverAction';
import { getDate } from 'utils';

type Props = {
    personSettings: IUserSetting[];
    personSettingsGroup?: string;
    machines?: ISparePartMachine[];
    sparePartWarehouseData?: IWarehouseSparePartLink[];
    data?: any;
    documents?: any[];
    onClick?: any
}

const ToggleDisplayTypeButton = ({ showHierarchy, setShowHierarchy }) => {
    const button = showHierarchy
        ? <button className="display-type-button" onClick={() => setShowHierarchy(false)}>
            {i18n.t('DISPLAY_ALL')} <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
        : <button className="display-type-button" onClick={() => setShowHierarchy(true)}>
            {i18n.t('DISPLAY_AS_HIERARCHY')} <FontAwesomeIcon icon={faStream} size="lg" />
        </button>
    return (
        <div className="display-type-button-container">
            {button}
        </div>
    );
}

const openDocument = item => {
    // open new tab if document is a link and return
    if (item.isUrl && item.documentLink) {
        window.open(item.documentLink, item.name);
        return;
    }

    // get image as blob object
    request.get(item.downloadLink || `/api/Documents/${item.id}/download`, {
        responseType: 'blob'
    }).then(response => {
        // const { objectUrl } = this.state;
        // // revoke previous objectURL
        // if (objectUrl) window.URL.revokeObjectURL(objectUrl);

        const url = window.URL.createObjectURL(response.data);
        // this.setState({ objectUrl: url });

        const officeDocument = 'application/vnd.openxmlformats-officedocument';

        // open an empty window
        const tab = window.open('about:blank');

        if (response.data.type.includes(officeDocument)) {
            // create a link elemenet and simulate click event to download document with original file name
            let link = tab.document.createElement('a');
            link.href = url;
            link.download = item.caption;
            link.click();
            link.remove();
        } else if (response.data.type.includes('image')) {
            // resize large images to fit the viewport
            let css = 'body { margin: 0 } img { max-width: 100vw; }',
                head = tab.document.head,
                style = tab.document.createElement('style'),
                textNode = tab.document.createTextNode(css);
            style.type = 'text/css';
            head.appendChild(style);
            style.appendChild(textNode)
            // create an image element and append it to the new tab
            let img = tab.document.createElement('img'),
                body = tab.document.body;
            img.src = url;
            img.alt = item.description;
            body.appendChild(img);
            tab.focus();
        } else {
            tab.location.href = url;
            tab.focus();
        }

    }).catch(error => {
        Toaster({ msg: 'ACTION_FAILED', type: 'error' });
    });
}

const SparePartHierarchyPanelGroup = ({ data, personSettings, personSettingsGroup }: Props) => {
    return (
        <Accordion
            id="additional-info-sub-panel"
            className="novi-sub-panel"
        >
            {data.map((element, i) => (
                element = propertiesToLowerCase(element),
                <PanelContainer
                    key={i}
                    id={i}
                    title={personSettingsGroup === 'sparepartwarehousegrid' ? `#${element.warehouse?.name}` : `#${element.machine?.code}`}
                    panelIcons={[]}
                    subTitle={''}
                >
                    <table className="spare-part-machines-hierarchy-panel-group-table">
                        <tbody>
                            {personSettings
                                .filter(personSettingsGroup === 'sparepartmachinegrid'
                                    ? setting => personSettingsGroup === setting.group && 'code' !== setting.field
                                    : setting => personSettingsGroup === setting.group && 'name' !== setting.field)
                                .sort((a, b) => a.tabOrder - b.tabOrder)
                                .map((setting, i) => (
                                    <tr key={i}>
                                        <th style={{ display: 'block' }}>
                                            {i18n.t(setting.label)}
                                        </th>
                                        <td style={{ wordBreak: 'break-word' }}>
                                            {setting.field === 'name' && personSettingsGroup === 'sparepartmachinegrid'
                                                ? <Link key={element.id} to={'/machine/' + element.machine.id}>
                                                    {element.machine?.name}
                                                </Link>
                                                : setting.field === 'name' && personSettingsGroup === 'sparepartwarehousegrid'
                                                    ? element.warehouse.name
                                                    : element[setting.field]
                                            }
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </PanelContainer>
            ))}
        </Accordion>
    );
}

const SparePartDataTable = ({ data, personSettings, personSettingsGroup }: Props) => {
    return (
        <div style={{ overflow: 'auto' }}>
            <table className="spare-part-machines-table">
                <thead>
                    {data.length > 0 && <tr style={{ backgroundColor: '#030303' }}>
                        {
                            personSettings
                                .filter(setting => personSettingsGroup === setting.group)
                                .sort((a, b) => a.tabOrder - b.tabOrder)
                                .map((setting, i) => (
                                    <th key={i}>
                                        {i18n.t(setting.label)}
                                    </th>))
                        }
                    </tr>}
                </thead>
                <tbody>
                    {data.map((element, i) => (
                        element = propertiesToLowerCase(element),
                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '#333' : '#181818' }}>
                            {
                                personSettings
                                    .filter(setting => personSettingsGroup === setting.group)
                                    .sort((a, b) => a.tabOrder - b.tabOrder)
                                    .map((setting, i) => (
                                        <td key={i}>
                                            {setting.field === 'name' && personSettingsGroup === 'sparepartmachinegrid'
                                                ? <Link key={element.machine.id} to={'/machine/' + element.machine.id}>
                                                    {element.machine?.name}
                                                </Link>
                                                : setting.field === 'mmemo' && personSettingsGroup === 'sparepartmachinegrid'
                                                    ? element.memo
                                                    : setting.field === 'code' && personSettingsGroup === 'sparepartmachinegrid'
                                                        ? element.machine?.code
                                                        : setting.field === 'name' && personSettingsGroup === 'sparepartwarehousegrid'
                                                            ? element.warehouse?.name
                                                            : element[setting.field]
                                            }
                                        </td>
                                    ))
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

const SparePartPanelGroup = ({ personSettings, machines = null, sparePartWarehouseData = [], documents = [], onClick }: Props) => {
    const [showHierarchy, setShowHierarchy] = useState(false);

    // map documents to have isUrl value
    const docs = documents.map(d => {
        return {
            isUrl: d.isUrl ? true : d?.files ? d.files[0]?.isUrl || false : false,
            ...d
        }
    });

    const getMenu = (doc) => {
        return (<Popover id="settings-menu-popover" className="panel-menu-popover">
            <div className="sub-section">
                <div className="setting-option">
                    <div>
                        <PopoverAction
                            icon="delete"
                            paClassName={''}
                            label={i18n.t('REMOVE')}
                            onClick={() => onClick(doc)}
                        />
                    </div>
                    <div>
                        {doc.editable
                            ? <PopoverAction
                                icon="edit"
                                paClassName={''}
                                label={i18n.t('EDIT')}

                            />
                            : <PopoverAction
                                icon="view"
                                label={i18n.t('VIEW_DOCUMENT')}
                                onClick={() => openDocument(doc)}
                            />}
                    </div>
                </div>
            </div>
        </Popover>);
    }

    return (
        <div id="panel-group-wrapper">
            <Accordion
                id="panel-with-sub-panels"
                className="margin-top-15 novi-panel"
            >
                <ToggleDisplayTypeButton showHierarchy={showHierarchy} setShowHierarchy={setShowHierarchy} />
                {machines && <PanelContainer
                    id={1}
                    title={i18n.t('MACHINES')}
                    panelIcons={[]}
                    subTitle=""
                    itemCount={machines.length}
                >
                    {showHierarchy
                        ? <SparePartHierarchyPanelGroup data={machines} personSettings={personSettings} personSettingsGroup={'sparepartmachinegrid'} />
                        : <SparePartDataTable data={machines} personSettings={personSettings} personSettingsGroup={'sparepartmachinegrid'} />
                    }
                </PanelContainer>}
            </Accordion>

            <Accordion
                id="panel-with-sub-panels"
                className="margin-top-15 novi-panel">
                {sparePartWarehouseData && <PanelContainer
                    id={2}
                    title={i18n.t('WAREHOUSES')}
                    panelIcons={[]}
                    subTitle=""
                    itemCount={sparePartWarehouseData.length}
                >
                    {showHierarchy
                        ? <SparePartHierarchyPanelGroup data={sparePartWarehouseData} personSettings={personSettings} personSettingsGroup={'sparepartwarehousegrid'} />
                        : <SparePartDataTable data={sparePartWarehouseData} personSettings={personSettings} personSettingsGroup={'sparepartwarehousegrid'} />
                    }

                </PanelContainer>
                }
            </Accordion>
            <Accordion
                id="panel-with-sub-panels"
                className="margin-top-15 novi-panel"
            >
                <PanelContainer
                    id={3}
                    title={i18n.t('DOCUMENTS')}
                    panelIcons={[]}
                    subTitle={''}
                    itemCount={docs.length}
                >
                    {docs.map((doc, k) => (
                        <div key={k}>
                            <Container fluid={true}>
                                <Row key={k} className="item-container-with-color-border">
                                    <Col xs={doc.createDate ? 7 : 10}>
                                        <p className="no-margins word-break">{doc.caption}</p>
                                        {doc.description &&
                                            <p key={'desc'} className="small no-margins">{doc.description}</p>
                                        }
                                    </Col>
                                    {doc.createDate && <Col xs={3}>
                                        <p className="small right">{getDate(doc.createDate)}</p>
                                    </Col>}
                                    <Col xs={2}>
                                        <div className="options-container">
                                            <span className="bar" />
                                            <OverlayTrigger
                                                trigger="click"
                                                rootClose={true}
                                                placement="left"
                                                overlay={getMenu(doc)}
                                            >
                                                <span className="options-button" ><FontAwesomeIcon icon={faEllipsisV} size="lg" /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    ))}
                </PanelContainer>
            </Accordion>
        </div>
    );
}

export default SparePartPanelGroup;
