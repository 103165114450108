export const FETCH_WORKCARD = 'FETCH_WORKCARD';
export const FETCH_WORKCARDS = 'FETCH_WORKCARDS';
export const FETCH_WORK_CARDS = 'FETCH_WORK_CARDS';
export const FETCH_MACHINE = 'FETCH_MACHINE';
export const FETCH_MACHINES = 'FETCH_MACHINES';
export const FETCH_FILTERED_MACHINES = 'FETCH_FILTERED_MACHINES';
export const FETCH_MACHINES_COUNT = 'FETCH_MACHINES_COUNT';
export const SAVE_WORK_LIST_SETTINGS = 'SAVE_WORK_LIST_SETTINGS';
export const SAVE_MACHINES_SETTINGS = 'SAVE_MACHINES_SETTINGS';
export const ADD_WORKCARD_DOCUMENT = 'ADD_WORKCARD_DOCUMENT';
export const ADD_MACHINE_DOCUMENT = 'ADD_MACHINE_DOCUMENT';
export const ADD_WORKCARD = 'ADD_WORKCARD';
export const ADD_WORKPHASE = 'ADD_WORKPHASE';
export const UPDATE_WORKCARD = 'UPDATE_WORKCARD';
export const UPDATE_WORKCARDS = 'UPDATE_WORKCARDS';
export const ADD_MACHINE = 'ADD_MACHINE';
export const UPDATE_MACHINE = 'UPDATE_MACHINE';
export const UPDATE_WORKPHASE = 'UPDATE_WORKPHASE';
export const DELETE_WORKCARD = 'DELETE_WORKCARD';
export const DELETE_WORKPHASE = 'DELETE_WORKPHASE';
export const DELETE_WORKCARD_DOCUMENT = 'DELETE_WORKCARD_DOCUMENT';
export const DELETE_MACHINE_DOCUMENT = 'DELETE_MACHINE_DOCUMENT';
export const DELETE_MATERIAL = 'DELETE_MATERIAL';
export const DELETE_MACHINE = 'DELETE_MACHINE';
export const FETCH_MAINTENANCE_ITEMS = 'FETCH_MAINTENANCE_ITEMS';
export const FETCH_ROUTE_PHASES = 'FETCH_ROUTE_PHASES';
export const ADD_MATERIAL = 'ADD_MATERIAL';
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL';
export const FETCH_WORKCARD_DOCUMENTS = 'FETCH_WORKCARD_DOCUMENTS';
export const FETCH_WORKCARD_MATERIALS = 'FETCH_WORKCARD_MATERIALS';
export const FETCH_WORKCARD_PERMITS = 'FETCH_WORKCARD_PERMITS';
export const FETCH_PERMIT_FIELDS = 'FETCH_PERMIT_FIELDS';
export const FETCH_OPERATOR_MAINTENANCES = 'FETCH_OPERATOR_MAINTENANCES';
export const FETCH_MAINTENANCE_MACHINES = 'FETCH_MAINTENANCE_MACHINES';
export const FETCH_OPERATOR_MAINTENANCE = 'FETCH_OPERATOR_MAINTENANCE';
export const SAVE_OPERATOR_MAINTENANCE = 'SAVE_OPERATOR_MAINTENANCE';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const EDIT_PERMIT = 'EDIT_PERMIT';
export const FETCH_WORKCARD_OPTIONS = 'FETCH_WORKCARD_OPTIONS';
export const SET_WORK_CARD_DETAILS = 'SET_WORK_CARD_DETAILS';
export const GET_ENABLED_MODULES = 'GET_ENABLED_MODULES';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_MACHINE_GROUPS = 'SET_MACHINE_GROUPS';
export const FETCH_MACHINE_LEVELS = 'FETCH_MACHINE_LEVELS';
export const FETCH_MACHINE_OPTIONS = 'FETCH_MACHINE_OPTIONS';
export const FETCH_MACHINE_DOCUMENTS = 'FETCH_MACHINE_DOCUMENTS';
export const FETCH_CHILD_MACHINE_DOCUMENTS = 'FETCH_CHILD_MACHINE_DOCUMENTS';
export const FETCH_MACHINE_WORKCARD_DOCUMENTS = 'FETCH_MACHINE_WORKCARD_DOCUMENTS';
export const FETCH_DOCUMENTS_LISTS = 'FETCH_DOCUMENTS_LISTS';
export const FETCH_WORKLIST_GRID_SETTINGS = 'FETCH_WORKLIST_GRID_SETTINGS';
export const FETCH_MACHINELIST_GRID_SETTINGS = 'FETCH_MACHINELIST_GRID_SETTINGS';
export const FETCH_MATERIAL_VIEW_SETTINGS = 'FETCH_MATERIAL_VIEW_SETTINGS';
export const FETCH_OPMAINTENANCE_VIEW_SETTINGS = 'FETCH_OPMAINTENANCE_VIEW_SETTINGS';
export const FETCH_WORKPHASE_VIEW_SETTINGS = 'FETCH_WORKPHASE_VIEW_SETTINGS';
export const FETCH_PERMIT_VIEW_SETTINGS = 'FETCH_PERMIT_VIEW_SETTINGS';
export const FETCH_WORKCARD_VIEW_SETTINGS = 'FETCH_WORKCARD_VIEW_SETTINGS';
export const FETCH_MACHINE_VIEW_SETTINGS = 'FETCH_MACHINE_VIEW_SETTINGS';
export const FETCH_VIEW_SETTINGS_BY_GROUPS = 'FETCH_VIEW_SETTINGS_BY_GROUPS';
export const FETCH_USER_RIGHTS = 'FETCH_USER_RIGHTS';
export const UPDATE_WORKCARD_DOCUMENT = 'UPDATE_WORKCARD_DOCUMENT';
export const UPDATE_CURRENT_MACHINE_GROUP_ID = 'UPDATE_CURRENT_MACHINE_GROUP_ID';
export const SET_COLOR_CONFIGURATION = 'SET_COLOR_CONFIGURATION';
export const SET_COLORS = 'SET_COLORS';
export const SET_COLOR_SETTINGS = 'SET_COLOR_SETTINGS';
export const FETCH_HIERARCHY_MACHINES = 'FETCH_HIERARCHY_MACHINES';
export const FETCH_WORKER_GROUPS = 'FETCH_WORKER_GROUPS';
export const FETCH_USER_GROUPS = 'FETCH_USER_GROUPS';
export const FETCH_PROCEDURE_DATATYPES = 'FETCH_PROCEDURE_DATATYPES';
export const FETCH_POINT_IMAGE = 'FETCH_POINT_IMAGE';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SET_APPLICATION_SETTINGS = 'SET_APPLICATION_SETTINGS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const FETCH_HIERARCHY_BREADCRUMB = 'FETCH_HIERARCHY_BREADCRUMB';
export const CLEAR_CACHE = 'CLEAR_CACHE';
export const FETCH_OPMAINTENANCE_DOCUMENTS = 'FETCH_OPMAINTENANCE_DOCUMENTS';
export const SET_WORK_STATUSES = 'SET_WORK_STATUSES';
export const FETCH_MACHINE_DETAILS = 'FETCH_MACHINE_DETAILS';
export const UPDATE_ROUTE_WORKCARD = 'UPDATE_ROUTE_WORKCARD';
export const FETCH_SPARE_PART_VIEW_SETTINGS = 'FETCH_SPARE_PART_VIEW_SETTINGS';
export const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES';
export const FETCH_WAREHOUSE_GRID_SETTINGS = 'FETCH_WAREHOUSE_GRID_SETTINGS';
export const FETCH_SPARE_PARTS_BY_WAREHOUSE = 'FETCH_SPARE_PARTS_BY_WAREHOUSE';
export const FETCH_MACHINE_SPARE_PARTS = 'FETCH_MACHINE_SPARE_PARTS';
export const FETCH_MACHINE_SPARE_PART_LINKS = 'FETCH_MACHINE_SPARE_PARTS_LINKS';
export const TAKE_WORKCARD_SPARE_PARTS = 'TAKE_WORKCARD_SPARE_PARTS';
export const RETURN_WORKCARD_SPARE_PARTS = 'RETURN_WORKCARD_SPARE_PARTS';
export const FETCH_MACHINE_EXTRA_DATA = 'FETCH_MACHINE_EXTRA_DATA';
export const FETCH_THIRD_PARTY_TYPES = 'FETCH_THIRD_PARTY_TYPES';
export const FETCH_THIRD_PARTIES = 'FETCH_THIRD_PARTIES';
export const FETCH_THIRD_PARTY_DETAILS = 'FETCH_THIRD_PARTY_DETAILS';
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';
export const SET_URGENCIES = 'SET_URGENCIES';
export const TAKE_WAREHOUSE_SPARE_PART = 'TAKE_WAREHOUSE_SPARE_PART';
export const RETURN_WAREHOUSE_SPARE_PART = 'RETURN_WAREHOUSE_SPARE_PART';
export const ARRIVE_WAREHOUSE_SPARE_PART = 'ARRIVE_WAREHOUSE_SPARE_PART';
export const TRANSFER_WAREHOUSE_SPARE_PART = 'TRANSFER_WAREHOUSE_SPARE_PART';
export const INVENT_WAREHOUSE_SPARE_PART = 'INVENT_WAREHOUSE_SPARE_PART';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_WORK_CARD_SETTINGS = 'SET_WORK_CARD_SETTINGS';
export const FETCH_MACHINE_ERROR = 'FETCH_MACHINE_ERROR';
export const FETCH_WORKCARD_ERROR = 'FETCH_WORKCARD_ERROR';
export const FETCH_WORKPHASE_ERROR = 'FETCH_WORKPHASE_ERROR';
export const FETCH_SPARE_PART_ERROR = 'FETCH_SPARE_PART_ERROR';
export const FETCH_ROUTE_WORKCARD_ERROR = 'FETCH_ROUTE_WORKCARD_ERROR';
export const FETCH_WORKCARD_EXTRA_DATAS = 'FETCH_WORKCARD_EXTRA_DATAS';
export const ADD_WC_EXTRA_DATA = 'ADD_WC_EXTRA_DATA';
export const UPDATE_WC_EXTRA_DATA = 'UPDATE_WC_EXTRA_DATA';
export const ADD_WP_EXTRA_DATA = 'ADD_WP_EXTRA_DATA';
export const UPDATE_WP_EXTRA_DATA = 'UPDATE_WP_EXTRA_DATA';
export const ADD_WC_NOTIFICATION_TARGET = 'ADD_WC_NOTIFICATION_TARGET';
export const UPDATE_WC_NOTIFICATION_TARGET = 'UPDATE_WC_NOTIFICATION_TARGET';
export const DELETE_WC_NOTIFICATION_TARGET = 'DELETE_WC_NOTIFICATION_TARGET';
export const SET_QUICK_SEARCHES = 'SET_QUICK_SEARCHES';
export const FETCH_DATA_BY_QUICK_SEARCH = 'FETCH_DATA_BY_QUICK_SEARCH';
export const SET_ACTIVE_QUICK_SEARCH = 'SET_ACTIVE_QUICK_SEARCH';
export const RESET_QUICK_SEARCH = 'RESET_QUICK_SEARCH';
export const CLEAR_USER_CACHE = 'CLEAR_USER_CACHE';
export const FETCH_WAREHOUSES_WITH_PARAMS = 'FETCH_WAREHOUSES_WITH_PARAMS';
export const FETCH_PERMIT_EXTRA_DATAS = 'FETCH_PERMIT_EXTRA_DATAS';
export const FETCH_PERMIT_DETAILS = 'FETCH_PERMIT_DETAILS';
export const CHANGE_VALUE_WAREHOUSE_SPARE_PART = 'CHANGE_VALUE_WAREHOUSE_SPARE_PART';
export const FETCH_RESPONSIBLE_PEOPLE_CATEGORIES = 'FETCH_RESPONSIBLE_PEOPLE_CATEGORIES';
export const FETCH_RESPONSIBLE_PEOPLE_BY_CATEGORY = 'FETCH_RESPONSIBLE_PEOPLE_BY_CATEGORY';
export const STATUS_FULFILLED = 'STATUS_FULFILLED';
export const DELETE_OPMAINTENANCE_DOCUMENT = 'DELETE_OPMAINTENANCE_DOCUMENT';
export const ADD_OPERATOR_MAINTENANCE_DOCUMENT = 'ADD_OPERATOR_MAINTENANCE_DOCUMENT';
export const API_CALL_ERROR = 'API_CALL_ERROR';
export const API_CALL_ERROR_RESET = 'API_CALL_ERROR_RESET';
export const DELETE_OPERATOR_MAINTENANCE_DOCUMENT = 'DELETE_OPERATOR_MAINTENANCE_DOCUMENT';
export const FETCH_WORKPHASE_DETAILS = 'FETCH_WORKPHASE_DETAILS';
export const UPDATE_WAREHOUSE_LINK = 'UPDATE_WAREHOUSE_LINK';
export const UPDATE_MACHINE_SPARE_PART = 'UPDATE_MACHINE_SPARE_PART';
export const ADD_MACHINE_SPARE_PART = 'ADD_MACHINE_SPARE_PART';
export const OPEN_SCANNER = 'OPEN_SCANNER';
export const CLOSE_SCANNER = 'CLOSE_SCANNER';
export const SET_SCANNER_RESULT = 'SET_SCANNER_RESULT';
export const CLEAR_SCANNER_RESULT = 'CLEAR_SCANNER_RESULT';
export const FETCH_USER_DETAILS_BY_ADNAME = 'FETCH_USER_DETAILS_BY_ADNAME';
export const SET_WORK_CARDS_STATUS_PENDING = 'SET_WORK_CARDS_STATUS_PENDING';
export const SET_WORK_CARDS_STATUS_FULFILLED = 'SET_WORK_CARDS_STATUS_FULFILLED';
export const FETCH_ROUTE_WORKCARD_DOCUMENTS = 'FETCH_ROUTE_WORKCARD_DOCUMENTS';
export const DELETE_ROUTE_WORKCARD_DOCUMENT = 'DELETE_ROUTE_WORKCARD_DOCUMENT';
export const FETCH_SPARE_PART_EXTRA_DATAS = 'FETCH_SPARE_PART_EXTRA_DATAS';
export const RESET_MACHINE_CURRENTFILTERS = 'RESET_MACHINE_CURRENTFILTERS';
export const SET_WORK_LIST_FILTERS = 'SET_WORK_LIST_FILTERS';
export const RESET_WORKCARD_CURRENTFILTERS = 'RESET_WORKCARD_CURRENTFILTERS';
export const ADD_ROUTE_WORKCARD_DOCUMENT = 'ADD_ROUTEMAINTENACE_WORKCARD_DOCUMENT';
export const FETCH_COST_POOLS = 'FETCH_COST_POOLS';
export const FETCH_COST_POOL_TYPES = 'FETCH_COST_POOL_TYPES';
export const FETCH_SPARE_PART_WAREHOUSE_DATA = 'FETCH_SPARE_PART_WAREHOUSE_DATA';
export const DELETE_SPAREPART = 'DELETE_SPAREPART'
export const FETCH_HOURCARDS = 'FETCH_HOURCARDS';
export const FETCH_HOURCARD_VIEW_SETTINGS = 'FETCH_HOURCARD_VIEW_SETTINGS';
export const UPDATE_HOURCARD = 'UPDATE_HOURCARD';
export const FETCH_WORK_CARD_HOUR_CARDS = 'FETCH_WORK_CARD_HOUR_CARDS';
export const FETCH_WORK_HISTORY = 'FETCH_WORK_HISTORY';
export const SET_HIERARCHY_TREE_PARENT_ID = 'SET_HIERARCHY_TREE_PARENT_ID';
export const SET_HIERARCHY_TREE_ROUTE = 'SET_HIERARCHY_TREE_ROUTE';
export const SET_HIERARCHY_TREE_PARENT_ID_TO_PREVIOUS = 'SET_HIERARCHY_TREE_PARENT_ID_TO_PREVIOUS';
export const RESET_HIERARCHY_TREE = 'RESET_HIERARCHY_TREE';
export const OPEN_HIERARCHY_TREE = 'OPEN_HIERARCHY_TREE';
export const CLOSE_HIERARCHY_TREE = 'CLOSE_HIERARCHY_TREE';
export const SET_WORK_HISTORY = 'SET_WORK_HISTORY';
export const RESET_WORK_HISTORY = 'RESET_WORK_HISTORY';
export const FETCH_SPARE_PART_DOCUMENTS = 'FETCH_SPARE_PART_DOCUMENTS';
export const DELETE_SPARE_PART_DOCUMENT = 'DELETE_SPARE_PART_DOCUMENT';
export const SET_WORK_LIST_PAGE_NUMBER = 'SET_WORK_LIST_PAGE_NUMBER';
export const FETCH_MACHINE_MEASUREMENTS = 'FETCH_MACHINE_MEASUREMENTS';
export const FETCH_ALL_QUICK_SEARCHES = 'FETCH_ALL_QUICK_SEARCHES';
export const FETCH_DASHBOARD_ITEM_TYPES = 'FETCH_DASHBOARD_ITEM_TYPES';
export const FETCH_DASHBOARD_TYPE_OPTIONS = 'FETCH_DASHBOARD_TYPE_OPTIONS';
export const UPDATE_DASHBOARD_ITEMS = 'UPDATE_DASHBOARD_ITEMS';
export const FETCH_SEARCHES = 'FETCH_SEARCHES';
export const FETCH_ANNUAL_SPARE_PART_CONSUMPTION = 'FETCH_ANNUAL_SPARE_PART_CONSUMPTION';
export const FETCH_WORK_CARD_LIMITED_WORKSTATUSES = 'FETCH_WORK_CARD_LIMITED_WORKSTATUSES';
export const FETCH_WORK_CARD_MEASUREMENTS = 'FETCH_WORK_CARD_MEASUREMENTS';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const ADD_PURCHASE_ORDER_ITEM = 'ADD_PURCHASE_ORDER_ITEM';
export const FETCH_PURCHASE_ORDER_ITEMS = 'FETCH_PURCHASE_ORDER_ITEMS';
export const ARRIVE_PURCHASE_ORDER_ITEM = 'ARRIVE_PURCHASE_ORDER_ITEM';
export const FETCH_MATERIAL_ORDER_GRID = 'FETCH_MATERIAL_ORDER_GRID';
export const DELETE_WORK_CARD_MEASUREMENT = 'DELETE_WORK_CARD_MEASUREMENT';
export const FETCH_MACHINE_WORKCARDS = "FETCH_MACHINE_WORKCARDS";
export const UPDATE_DASHBOARD = "UPDATE_DASHBOARD";
export const RESET_WAREHOUSE_CURRENTFILTERS = "RESET_WAREHOUSE_CURRENTFILTERS";
export const REMOVE_MACHINE_FROM_LIST = "REMOVE_MACHINE_FROM_LIST";
export const FETCH_DOCUMENT_METADATA_GROUPS = "FETCH_DOCUMENT_METADATA_GROUPS";
export const UPDATE_M_EXTRA_DATA = 'UPDATE_M_EXTRA_DATA';
export const ADD_M_EXTRA_DATA = 'ADD_M_EXTRA_DATA';
export const SET_WORKCARD_INFORMATION = 'SET_WORKCARD_INFORMATION';
export const FETCH_FAULT_NOTICE_DEFAULT_TEXT = 'FETCH_FAULT_NOTICE_DEFAULT_TEXT';
export const FETCH_PERSON_GROUPS = 'FETCH_PERSON_GROUPS';
export const FETCH_MACHINE_COUNTERS = 'FETCH_MACHINE_COUNTERS';
export const FETCH_SAFETY_TYPES = 'FETCH_SAFETY_TYPES';
export const FETCH_SAFETY_NOTICES = 'FETCH_SAFETY_NOTICES';
export const SET_SAFETY_SETTINGS = 'SET_SAFETY_SETTINGS';
export const SET_PERSON_MACHINE_GROUP_SETTINGS = 'SET_PERSON_MACHINE_GROUP_SETTINGS ';
export const FETCH_ALL_WAREHOUSES = 'FETCH_ALL_WAREHOUSES';