import React from 'react';
import { Modal } from 'react-bootstrap';

const DialogModal = (props: IProps) => {
    return <Modal
        show={props.showDialog}
        dialogClassName={props.className ?? 'dialog-modal'}
        animation={false}
        onHide={props.closeDialog}
        onShow={props.onShow}
    >
        {props.children}
    </Modal>
};

interface IProps {
    showDialog: boolean;
    className?: string;
    children: React.ReactNode | React.ReactNode[];
    closeDialog: () => void;
    onShow?: () => void;
}

export default DialogModal;
