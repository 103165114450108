import React from "react";
import i18n from "../translations/i18n";

const SearchResultInfo = ({ pageNumber, pageSize, totalResultCount }) => {
    const firstItemCount = (pageNumber - 1) * pageSize + 1;
    const lastItemCount = pageNumber * pageSize;
    const results = totalResultCount > 0
        ? `${firstItemCount} - ${lastItemCount <= totalResultCount ? lastItemCount : totalResultCount} / ${totalResultCount}`
        : '0';

    return (
        <span className="sub-header">
            {i18n.t('RESULT')}: {results}
        </span>
    );
}

export default SearchResultInfo;