import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetQuickSearch } from '../../../../commonActions/actions';
import { CountFilters as countActiveFilters, HasRight } from '../../../../components/HelperFunctions';
import PopoverAction from '../../../../components/PopoverAction';
import { PAGE_SIZES } from '../../../../constants/displaySettings';
import { UserRights } from '../../../../constants/userRights';
import i18n from '../../../../translations/i18n';
import { resetCurrentFilters, saveWorkListSettings } from '../actions';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    enabledPhases: boolean;
    onlyShowPaginationSettings?: boolean;
    closeDialog?: () => void;
    toggleDialog?: () => void;
    dialogOpen?: boolean;
}

const WorkListSettingsMenu = (props: IProps) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const displaySettings = useSelector((state: State) => state.workcards.settings);
    const userRights = useSelector((state: State) => state.settings.userRights);
    const filters = useSelector((state: State) => state.workcards.currentFilters);
    const isQuickSearchActive = useSelector((state: State) => state.navigation.activeQuickSearches.worklist) !== null;

    const toggleLayout = (value: boolean) => {
        if (displaySettings.cardlayout !== value) {
            dispatch(saveWorkListSettings({ cardlayout: value }));
        }
    }

    const togglePhases = (value: boolean) => {
        if (displaySettings.displayphases !== value) {
            dispatch(saveWorkListSettings({ displayphases: value }));
        }
    }

    const setPageSize = (value: number) => {
        if (displaySettings.pageSize !== value) {
            dispatch(saveWorkListSettings({ pageSize: value, pageNumber: 1 }));
        }
    }

    const setUseDefaultSearch = (value: boolean) => {
        if (displaySettings.useDefaultSearch !== value) {
            // Reset filters if there are any active filters
            if (countActiveFilters(filters) > 0) {
                dispatch(resetCurrentFilters());
            }
            // Reset quick search if there is a quick search selected
            if (isQuickSearchActive) {
                dispatch(resetQuickSearch('worklist'));
            }
            // Set default search on/off and set page to 1
            dispatch(saveWorkListSettings({
                useDefaultSearch: value,
                pageNumber: 1
            }));
        }
    }

    const addNewCard = () => {
        history.push('/workcard/new/workcard', { cardType: 'newWorkCard' });
    }

    let settingsMenuHeadings = [];
    let settingsMenuContents = [];

    if (props.enabledPhases) {
        settingsMenuHeadings.push(i18n.t('PHASES'));
        settingsMenuContents.push(<div key={settingsMenuContents.length}>
            <PopoverAction
                idValue={'showPhases'}
                icon="show"
                label={i18n.t('SHOW_PHASES')}
                active={displaySettings.displayphases}
                onClick={() => togglePhases(true)}
                inline
            />
            <PopoverAction
                idValue={'hidePhases'}
                icon="hide"
                label={i18n.t('HIDE_PHASES')}
                active={!displaySettings.displayphases}
                onClick={() => togglePhases(false)}
                inline
            />
            <hr />
        </div>);
    }

    settingsMenuHeadings.push(i18n.t('LISTING_TYPE'));
    settingsMenuContents.push(<div key={settingsMenuContents.length}>
        <PopoverAction
            icon="cards"
            label={i18n.t('LAYOUT_CARDS')}
            active={displaySettings.cardlayout}
            onClick={() => toggleLayout(true)}
            inline
        />
        <PopoverAction
            icon="table"
            label={i18n.t('LAYOUT_TABLE')}
            active={!displaySettings.cardlayout}
            onClick={() => toggleLayout(false)}
            inline
        />
        <hr />
    </div>);

    settingsMenuHeadings.push(i18n.t('DISPLAY_AMOUNT'));
    settingsMenuContents.push(<div key={settingsMenuContents.length}>
        <div className="small-options-list">
            <PopoverAction
                label={PAGE_SIZES.OPT_1.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_1}
                onClick={() => setPageSize(PAGE_SIZES.OPT_1)}
                inline
            />
            <PopoverAction
                label={PAGE_SIZES.OPT_2.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_2}
                onClick={() => setPageSize(PAGE_SIZES.OPT_2)}
                inline
            />
            <PopoverAction
                label={PAGE_SIZES.OPT_3.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_3}
                onClick={() => setPageSize(PAGE_SIZES.OPT_3)}
                inline
            />
            <PopoverAction
                label={PAGE_SIZES.OPT_4.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_4}
                onClick={() => setPageSize(PAGE_SIZES.OPT_4)}
                inline
            />
        </div>
        {!props.onlyShowPaginationSettings && <hr />}
    </div>);

    if (props.onlyShowPaginationSettings !== true) {

        settingsMenuContents.push(<div key={settingsMenuContents.length}>
            <PopoverAction
                icon="search"
                label={i18n.t('RESET_SEARCH')}
                active={displaySettings.useDefaultSearch}
                onClick={() => setUseDefaultSearch(!displaySettings.useDefaultSearch)}
            />
            <hr />
        </div>);

        if (HasRight(UserRights.WorkScheduleAdd, userRights)) {
            settingsMenuContents.push(<div data-cy={'new_workcard'} key={settingsMenuContents.length}>
                <PopoverAction
                    icon="add-square"
                    label={i18n.t('NEW_WORKCARD')}
                    onClick={addNewCard}
                />
            </div>);
        }

    }

    return (
        <div>
            {props.dialogOpen && <div className="overlay-canvas" onClick={() => props.closeDialog()} ></div>}
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={<Popover id="settings-menu-popover" className="menu-popover">
                    {React.Children.map(settingsMenuContents, (child, i) => (
                        <div className="sub-section" key={`setting_${i}`}>
                            {settingsMenuHeadings !== null &&
                                settingsMenuHeadings?.length > 0
                                && settingsMenuHeadings[i] !== null &&
                                <div className="setting-header">
                                    {settingsMenuHeadings[i]}
                                </div>
                            }
                            <div className="setting-option">
                                {child}
                            </div>
                        </div>
                    ))}
                </Popover>}
            >
                <Button
                    id="footerMenuButton"
                    variant="primary"
                    className="action action-button novi-default-btn-color"
                    size="lg"
                    onClick={() => props.toggleDialog()}
                >
                    <div>
                        <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
                    </div>
                </Button>
            </OverlayTrigger>
        </div>
    );
}

export default WorkListSettingsMenu;
