import React from 'react';
import ActionIcon, { ActionIconType } from './ActionIcon';

const PopoverAction = ({
    icon = null,
    onClick = null,
    paClassName = null,
    active = false,
    inline = false,
    label,
    idValue = '',
    disabled = false
}: IProps) => {
    let actionClass = 'action'
    if (paClassName !== null) {
        actionClass = actionClass + ' ' + paClassName;
    }
    if (inline) {
        actionClass = actionClass + ' ' + 'inline-option';
    }

    if (active) {
        actionClass = actionClass + ' ' + 'active-option';
    }

    if (disabled) {
        actionClass = actionClass + ' ' + 'disabled-element';
    }

    return (
        <div
            id={idValue}
            onClick={!disabled ? onClick : null}
            className={actionClass}
        >
            {icon !== null
                ? (
                    <div className={inline ? 'icon-space inline-option' : 'icon-space'}>
                        <ActionIcon iconType={icon} />
                    </div>
                )
                : <div className={inline ? 'icon-space inline-option' : 'icon-space'} />
            }
            <div className={inline ? 'inline-option' : undefined}>{label}</div>
        </div>
    );
}

interface IProps {
    label: string;
    icon?: ActionIconType;
    onClick?: () => void;
    idValue?: string;
    paClassName?: string;
    active?: boolean;
    inline?: boolean;
    dataCy?: string;
    disabled?: boolean;
}

export default PopoverAction;
