import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from "react-redux";
import FormElement from './../../work-schedule/work-card/components/FormElement';
import i18n from '../../../translations/i18n';
import {
    fetchWarehouses,
    inventWarehouseSparePart,
    fetchWarehousesWithParams
} from '../actions';
import NavigationBar from '../../navigation';
import { Container, Row, Button } from 'react-bootstrap';
import QrReaderComponent from '../../../components/scanners/QrReaderComponent';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SparePartInventoryForm from './SparePartInventoryForm';
import noviAPI from '../../../api/noviAPI';
import { Loader } from '../../../components/Loader';
import { HandleError } from '../../../components/HelperFunctions';
import { RouteChildrenProps } from 'react-router-dom';
import { handleDateTimeInput } from 'utils';
import { withStorage, StorageProps, sortTypeKey } from 'hooks/useLocalStorage';

interface MatchParams {
    sparePartId: string;
}

interface LocationState {
    warehouseId: number;
    inventFromSparePart?: ISparePartLite;
    warehouseOptions: IdLabel[];
    notificationMsg?: string;
}

type IProps = StorageProps & PropsFromRedux & RouteChildrenProps<MatchParams, LocationState>;

interface IState {
    [name: string]: any;
    inventoryProcess: boolean;
    qrReading: boolean;
    sparePartIndex: number;
    currentChunk: number;
    amountNotSet: boolean;
    selectedSparePart: IWarehouseSparePartLink;
    amount: any;
    sparePartChunks: any[];
    currentSparePart: IWarehouseSparePartLink;
    sparePartCount: number;
    isFetching: boolean;
    shelfLocationOptions?: IItemPropertyType [];
    sortBy: typeof sortOptions[0];
    startShelfLocation: any;
    inventableSpareParts: IWarehouseSparePartLink[];
    loading: {
        [field: string]: boolean
    };
}

const inventoryTypes = [
    { id: 1, label: 'WAREHOUSE_SPECIFIC' },
    //{ id: 2, label: i18n.t('SHELF_SPECIFIC') },
    { id: 3, label: 'BY_SCANNER_QR_BARCODE' },
];

const sortOptions = [
    { id: 1, label: i18n.t('CODE'), value: 'CODE' },
    { id: 2, label: i18n.t('NAME'), value: 'NAME' },
];

const screen = 'invent';

class WarehouseInvent extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        
        this.state = {
            inventoryProcess: false,
            sparePartIndex: 0,
            currentChunk: 0,
            sortinventorder: { id: 1, label: i18n.t('SHELF_LOCATION') },
            inventorytype: { id: 1, label: i18n.t('WAREHOUSE_SPECIFIC') },
            qrReading: false,
            amountNotSet: false,
            selectedSparePart: null,
            amount: null,
            sparePartChunks: [],
            currentSparePart: null,
            sparePartCount: 0,
            isFetching: false,
            startShelfLocation: "",
            sortBy: props.getStorageSetting(sortTypeKey, screen),
            shelfLocationOptions: [],
            inventableSpareParts: [],
            loading: {
                shelfLocation: false
            }
        }

    }

    componentDidMount() {
        const { location, match, warehouses } = this.props;
        this.props.fetchWarehousesWithParams({ sparePartId: match.params.sparePartId });

        const warehouse = warehouses.find(whouse => whouse.id === location.state.warehouseId);

        if (warehouse) {
            this.setState({ warehouse: warehouse });
        }
        
        const sparePartId = match.params.sparePartId;

        if (warehouse && sparePartId) {
            this.getSparePartAndSetSelected(sparePartId, warehouse.id);
        }
    }

    componentDidUpdate(prevProps) {
        const { location, history } = this.props;

        if (prevProps.location !== location && location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }
        }
    }

    onInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;

        this.setState(state => ({
            [name]: value,
            amountNotSet: value > 0 ? false : state.amountNotSet
        }));
    }

    handleSelect = (value, actionMeta) => {
        const selectedValue = value && value.id > 0 ? {
            id: value.id,
            label: value.label
        } : null;

        const name = actionMeta.name;
        this.setState({
            [name]: selectedValue,
            ...(name === 'warehouse' && { startShelfLocation: null, shelfLocationOptions: [] })
        })

        if (name === 'warehouse' && selectedValue.id > 0) {
            const { match } = this.props;

            if (match.params.sparePartId) {
                this.getSparePartAndSetSelected(match.params.sparePartId, selectedValue.id);
            } else {
                this.callFetchSparePartLink(selectedValue.id)
            }
        }
    }

    handleSortChange = (value) => {
        const { warehouse, sortBy } = this.state;
        const newType = {
            id: value?.id,
            label: value?.label,
            value: value?.value
        };

        this.props.setStorageSetting(sortTypeKey, newType, screen);

        this.setState({ sortBy: newType }, () => {
            if (warehouse?.id > 0 && sortBy?.id != newType.id) {
                this.callFetchSparePartLink(warehouse?.id)
            }
        });


    }

    callFetchSparePartLink(warehouseId = null, index = 0) {
        const { warehouse, sortBy, loading } = this.state;
        const { location } = this.props;
        let newIndex = index;

        const startFromId = location?.state?.inventFromSparePart?.id;
        const wHouseId = warehouseId || (warehouse ? warehouse.id : null);

        if (!wHouseId) return;

        this.setState({ 
            isFetching: true,
            loading: {
                ...loading,
                shelfLocation: true
            }
        });

        let queryParams = new URLSearchParams();
        queryParams.append('WarehouseIds', wHouseId);
        queryParams.append('InnerOrderBy', sortBy?.value ?? "NAME");

        noviAPI.warehouseSparePartLinks.fetchForInvent(queryParams)
            .then(({ data }) => {

                let links = [...data];
               
                const startFromLink = startFromId > 0 
                    ? links.find((link, i) => {
                        if (link.sparePart?.id == startFromId) {
                            newIndex = i;
                            return true;
                        }
                    }) 
                    : null;
                        
                const startShelfLocation = startFromLink?.shelfLocation ?? '-';
                
                if (startFromLink) {

                    const firstShelfLocationItemIndex = links.indexOf(links.find(link => link.shelfLocation == startFromLink.shelfLocation));
                    
                    const rest = links.slice(firstShelfLocationItemIndex);

                    // Manually add scanned sparepart to the start of shelfLocation, remove it from the 'rest' to avoid duplicate
                    rest.splice(newIndex - firstShelfLocationItemIndex, 1);

                    links = links.slice(0, firstShelfLocationItemIndex)
                        .concat(startFromLink)
                        .concat(rest);

                    newIndex = firstShelfLocationItemIndex;

                }

                const uniqueShelfLocations = Array.from(new Set(links.map(link => link.shelfLocation || '-')));
                const options = uniqueShelfLocations.map((shelfLocation, i) => ({ id: i + 1, label: shelfLocation }));

                this.setState(prevState => ({
                    inventableSpareParts: links,
                    currentSparePart: links[newIndex],
                    sparePartCount: links.length,
                    sparePartIndex: newIndex,
                    isFetching: false,
                    shelfLocationOptions: options,
                    ...(startFromLink && { startShelfLocation: options.find(x => x.label == startShelfLocation) }),
                    loading: {
                        ...prevState.loading,
                        shelfLocation: false
                    }
                }));
            })
            .catch(error => {
                HandleError(error, 'Warehouse sparepart links fetch');
                this.setState({ isFetching: false })
            })
    }

    handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        this.setState({
            [dateInput.name]: dateInput.value
        });
    }

    // Reset all values when invent proces is toggled
    toggleInventoryProcess = () => {
        const { startShelfLocation, inventableSpareParts, warehouse, inventoryProcess } = this.state;
        let newIndex = 0;

        if (startShelfLocation) {

            inventableSpareParts.find((link, i) => {
                if (link.warehouse?.id == warehouse?.id && link.shelfLocation === startShelfLocation.label) {
                    newIndex = i;
                    return true;
                }
                return false;
            });
        }
        if (inventoryProcess) {
            this.callFetchSparePartLink();  
        }

        this.setState(state => ({
            inventoryProcess: !state.inventoryProcess,
            sparePartIndex: newIndex,
            amount: 0,
            currentChunk: 0,
            currentSparePart: inventableSpareParts[newIndex]
        }));

        // Once invent is initiated clear the location state from scanned item data
        this.props.history.replace({
            state: {
                inventFromSparePart: null,
                warehouseOptions: null
            }
        });
    }

    toggleQrReader = (value) => {
        this.setState({ qrReading: !value ? false : value })
    }

    handleScan = id => {
        const { warehouse } = this.state;

        if (warehouse.id && id) {
            this.getSparePartAndSetSelected(id, warehouse.id);
        }
    }

    getSparePartAndSetSelected = (spId, whouseId) => {
        noviAPI.warehouseSparePartLinks.fetchByWarehouseId(whouseId, spId)
            .then(({ data }) => {
                this.setState({
                    selectedSparePart: data,
                    inventoryProcess: true
                });
            })
            .catch(error => {
                toast.warn(i18n.t('SPARE_PART_WAS_NOT_FOUND'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            })
    }

    skipInvent = () => {
        const { isFetching, sparePartCount, sparePartIndex } = this.state;
        if (isFetching) {
            return;
        }

        const index = sparePartIndex + 1;

        if (index >= sparePartCount) {
            this.completeInventoryProcess();
        } else {
            const newIndex = sparePartIndex + 1;
            this.setState(prevState => ({
                sparePartIndex: newIndex,
                currentSparePart: prevState.inventableSpareParts[newIndex]
            }))
        }
    }

    updateSparePart = () => {
        const {
            amount, 
            warehouse, 
            selectedSparePart,
            currentSparePart,
            sparePartCount,
            sparePartIndex
        } = this.state;

        if (!amount || amount < 0) {
            toast.info(i18n.t('ENTER_AMOUNT'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ amountNotSet: true })
            return;
        }

        // If invent of single spare part, complete process
        if (selectedSparePart) {
            this.props.inventWarehouseSparePart(warehouse.id, selectedSparePart, { newAmount: parseInt(amount, 10) });
            this.completeInventoryProcess();
            return;
            // If current spare part index is going to be set higher than current chunk's length, change chunk and pick first spare part index (i.e. 0 -index)
        } else {
            this.props.inventWarehouseSparePart(warehouse.id, currentSparePart, { newAmount: parseInt(amount, 10) });

            const index = sparePartIndex + 1;

            if (index >= sparePartCount) {
                this.completeInventoryProcess();
            } else {
                const newIndex = sparePartIndex + 1;
                this.setState(prevState => ({
                    sparePartIndex: newIndex,
                    currentSparePart: prevState.inventableSpareParts[newIndex]
                }))
            }
        }
    }

    completeInventoryProcess = () => {

        const { history } = this.props;

        this.setState({
            inventoryProcess: false,
            amount: null,
            amountNotSet: false,
            sparePartIndex: 0,
            currentChunk: 0
        });

        toast.success(i18n.t('INVENTORY_COMPLETED'), {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });

        // Return to the view from where the sparepart inventory was started
        if (this.state.selectedSparePart) {
            history.goBack();
        }
        else {
            this.callFetchSparePartLink();  
        }
    }

    render() {
        const { 
            history, 
            location, 
            warehouses,
            match,
            sPartLinksStatus,
        } = this.props;

        const {
            inventoryProcess, 
            inventorytype, 
            warehouse, 
            qrReading, 
            selectedSparePart,
            currentSparePart,
            amount,
            amountNotSet,
            sparePartCount,
            isFetching,
            sparePartIndex,
            shelfLocationOptions,
            loading,
            sortBy,
            startShelfLocation
        } = this.state;

        const backAction =
            match.params.sparePartId
                ? { action: history.goBack }
                : inventoryProcess
                    ? { action: this.toggleInventoryProcess } 
                    : qrReading
                        ? { action: this.toggleQrReader }
                        : { action: history.goBack }

        const sceneData = {
            view: selectedSparePart ? 'sparepart' : 'warehouseinvent',
            title: selectedSparePart ? selectedSparePart.sparePart.name : i18n.t('INVENT'),
            subPhase: warehouse && inventoryProcess ? warehouse.label : '',
            subPhaseLabel: selectedSparePart && inventoryProcess ? i18n.t('INVENT') : '',
            location: location,
            history: history,
            backAction: backAction
        };

        const viewAction = !inventoryProcess
            && inventorytype.id !== 3
            && !isFetching
            && sparePartCount > 0
            ? {
                icon: 'next',
                label: '',
                clickFn: this.toggleInventoryProcess,
                isActionFn: true,
                paClass: 'start-phase'
            } : null;

        const sparePartParam = match.params.sparePartId;
        const inventFromSparePart = location?.state?.inventFromSparePart ?? null;
        const warehouseOptions = inventFromSparePart ? location?.state?.warehouseOptions : warehouses;

        return (
            <div>
                <NavigationBar
                    currentView={sceneData}
                    viewAction={viewAction}
                    popoverData={''}
                />
                <div className="work-card-view">
                    <Container className="margin-top-15">
                        <div className="form-table-container bottom-nav-space">
                            {inventoryProcess && <span className="sub-header" style={{ float: 'right' }}>{`${sparePartIndex + 1} / ${sparePartCount}`}</span>}
                            {inventoryProcess
                                ? <div>
                                    <Loader ready={!isFetching} />
                                    {selectedSparePart
                                        // single spare part invent
                                        ? <SparePartInventoryForm
                                            sparePartLink={selectedSparePart}
                                            onInputChange={this.onInputChange}
                                            skipInvent={this.skipInvent}
                                            updateSparePart={this.updateSparePart}
                                            sparePartsCount={selectedSparePart}
                                            inventorytype={!sparePartParam ? inventorytype.id : null}
                                            inventoryAmount={amount ? amount : null}
                                            inventoryAmountNotSet={amountNotSet}
                                            currentSparePartIndex={null}
                                        />
                                        // mass invent process
                                        : <SparePartInventoryForm
                                            sparePartLink={currentSparePart}
                                            onInputChange={this.onInputChange}
                                            skipInvent={this.skipInvent}
                                            updateSparePart={this.updateSparePart}
                                            sparePartsCount={sparePartCount}
                                            inventType={inventorytype.id}
                                            inventoryAmount={amount ? amount : null}
                                            inventoryAmountNotSet={amountNotSet}
                                            currentSparePartIndex={sparePartIndex}
                                        />
                                    }
                                    <Row>
                                        <div className="choice-buttons">
                                            {inventorytype.id === 1 && !sparePartParam && <Button
                                                onClick={this.skipInvent}
                                                variant="warning"
                                                className={isFetching ? 'disabled-button action' : 'action'}
                                                size="lg"
                                            >
                                                <span>{i18n.t('SKIP')}</span>
                                            </Button>}
                                            <Button
                                                onClick={this.updateSparePart}
                                                variant="success"
                                                className={isFetching ? 'disabled-button action' : 'action'}
                                                size="lg"
                                            >
                                                <span>{i18n.t('SAVE')}</span>
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                                : <div>
                                    <div style={{ marginBottom: '25px' }}>
                                        {inventFromSparePart && <FormElement
                                            name={'inventFromSparePart'}
                                            type={'label'}
                                            label={i18n.t('SPARE_PART')}
                                            value={inventFromSparePart?.name ?? ''}
                                            options={[]}
                                            onChange={null}
                                        />}
                                        <div className={(sparePartParam || inventFromSparePart) ? 'disabled-element' : ''}>
                                            <FormElement
                                                name={'inventorytype'}
                                                type={inventFromSparePart ? 'label' : 'select-choice'}
                                                label={i18n.t('INVENTORY_METHOD')}
                                                value={inventFromSparePart ? i18n.t('WAREHOUSE_SPECIFIC') : this.state['inventorytype'] || ''}
                                                options={inventoryTypes.map(type => ({ id: type.id, label: i18n.t(type.label) }))}
                                                onChange={this.handleSelect}
                                                isDisabled={sparePartParam ? true : false}
                                            />
                                        </div>
                                        <FormElement
                                            name={'warehouse'}
                                            type={'select-choice'}
                                            label={i18n.t('WAREHOUSE')}
                                            value={this.state['warehouse'] || ''}
                                            options={warehouseOptions}
                                            onChange={this.handleSelect}
                                        />
                                    </div>
                                    {!sparePartParam && <div>
                                        <hr />
                                        <form>
                                            {inventorytype.id === 1 && <div>
                                                {/*<FormElement
                                                    name={'inventdatefilter'}
                                                    type={'datetime'}
                                                    label={i18n.t('SPARE_PARTS_INVENTORY_AFTER_DATE')}//TODO: translation keys: SPARE_PARTS_INVENTORY_AFTER_DATE
                                                    value={this.state['inventdatefilter']}
                                                    options={[]}
                                                    onChange={this.handleDateTime}
                                                />*/}
                                                <label> {i18n.t('AMOUNT_IN_WAREHOUSE')}</label>
                                                <input
                                                    name={'sparepartcount'}
                                                    className={"disabled " + (sPartLinksStatus === 'pending' ? 'info-field' : '')}
                                                    type="text"
                                                    value={sparePartCount}
                                                    readOnly
                                                />
                                                {warehouse && sparePartCount < 1 && <span className="cursive icon-default">
                                                    {i18n.t('NO_INVENTABLE_SPARE_PARTS')}
                                                </span>}

                                                <FormElement
                                                    name={'startShelfLocation'}
                                                    type={inventFromSparePart ? 'label' : 'async-search-select'}
                                                    label={i18n.t('START_FROM_SHELF_LOCATION')}
                                                    value={inventFromSparePart ? startShelfLocation?.label || '' : startShelfLocation || ''}
                                                    options={(inputValue, callback) => {
                                                        const regex = new RegExp(`^${inputValue}`, 'i');
                                                        const options = shelfLocationOptions.filter(option => option.label.match(regex));
                                                        callback(options);
                                                    }}
                                                    onChange={this.handleSelect}
                                                    loading={loading?.shelfLocation}
                                                    defaultOptions={shelfLocationOptions}
                                                    getNoOptionsMessage={val => val.length == 0 ? i18n.t("NOT_IN_WAREHOUSE") : i18n.t('NO_MATCHES')}
                                                />
                                                <FormElement
                                                    name={'sortBy'}
                                                    type={'select-choice'}
                                                    label={i18n.t('SORT_SHELF_LOCATION_BY')}
                                                    value={sortBy ?? sortOptions[0]}
                                                    options={sortOptions.map(({ id, value, label }) => ({ id, value, label: i18n.t(label) }))}
                                                    onChange={this.handleSortChange}
                                                />
                                            </div>}
                                            {inventorytype.id === 3 && warehouse && <div className="scanner-tools-container">
                                                <QrReaderComponent
                                                    onChange={this.toggleQrReader}
                                                    scanQrData={this.handleScan}
                                                    readerState={qrReading}
                                                />
                                            </div>}
                                        </form>
                                    </div>}
                                </div>
                            }
                        </div>
                    </Container>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        sPartLinksStatus: state.warehouse.sPartLinksStatus,
        settings: state.warehouse.settings,
        warehouses: state.warehouse.searchedWarehouses
            ? state.warehouse.searchedWarehouses.map(whouse => ({ id: whouse.id, label: whouse.name }))
            : [],
        warehouseSparePartLinks: state.warehouse.warehouseSparePartLinks || [],
        currentSparePart: state.warehouse.warehouseSparePartLinks[0]
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchWarehouses,
    fetchWarehousesWithParams,
    inventWarehouseSparePart
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStorage(WarehouseInvent));
