export const setPageSizeAction = payload => ({
    type: 'SET_HOURCARDS_PAGE_SIZE',
    payload
});

export const setPageNumberAction = payload => ({
    type: 'SET_HOURCARDS_PAGE_NUMBER',
    payload
});

export const setListLayoutAction = payload => ({
    type: 'SET_HOURCARDS_LIST_LAYOUT',
    payload
});

export const setFiltersAction = (payload: IHourCardFilters) => ({
    type: 'SET_HOURCARDS_FILTERS',
    payload
});

export const clearFiltersAction = () => ({
    type: 'CLEAR_HOURCARDS_FILTERS'
});
