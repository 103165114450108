import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import MaterialForm from '../../../../components/MaterialForm';
import NavigationBar from '../../../navigation';
import i18n from '../../../../translations/i18n';
import settingsAPI from '../../../../config/settingsAPI';
import { addMaterial, updateMaterial } from '../actions';
import useViewSettings from '../../../../hooks/useViewSettings'
import { FETCH_MATERIAL_VIEW_SETTINGS } from '../../../../config/actionTypes';
import 'react-toastify/dist/ReactToastify.css';
import { mapMaterialAddition, mapMaterialUpdate } from '../utils';
import { ScrollToTop } from 'components/ScrollToTop';
import { fetchSparePartDetails } from 'scenes/warehouse/actions';

type UrlParams = { workCardId?: string; materialId?: string; operation?: string; };

const moduleSettings = settingsAPI.moduleData.editmaterial;

const EditMaterial = () => {
    const [viewSettings] = useViewSettings('workcardmaterialaddedit', FETCH_MATERIAL_VIEW_SETTINGS);

    let history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    const { workCardId, materialId, operation } = useParams<UrlParams>();
    const [workCardSparePart] = useSelector((state: State) => state.workcards.materials.filter(material => material.id === parseInt(materialId)));
    const [submit, setSubmit] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        dispatch(fetchSparePartDetails())
    }, []);

    const handleSubmit = useCallback((material, type) => {

        if (material == null && type == null) {
            setSubmit(false);
            return;
        }

        const { spareParts, ...rest } = material;
        const redirectFn = history.replace;

        const id = parseInt(materialId);
        const wcId = parseInt(workCardId);
        if (type === 'sparepart') {
            spareParts.forEach(sparePart => {
                if (operation === 'new') {
                    // add a new spare part material(s)
                    dispatch(addMaterial(
                        mapMaterialAddition({ ...rest, sparePart }, wcId),
                        redirectFn
                    ));
                } else {
                    // update an existing spare part material
                    dispatch(updateMaterial(
                        id,
                        mapMaterialUpdate({ ...rest, sparePart }, wcId, viewSettings),
                        redirectFn
                    ));
                }
            });
        } else {
            if (operation === 'new') {
                // add a new wild spare part material
                dispatch(addMaterial(
                    mapMaterialAddition(rest, wcId),
                    redirectFn
                ));
            } else {
                // update an existing wild spare part material
                dispatch(updateMaterial(
                    id,
                    mapMaterialUpdate(rest, wcId, viewSettings),
                    redirectFn
                ));
            }
        }
    }, [dispatch, materialId, operation, workCardId, history.replace, viewSettings]);

    const getBackButtonConfig = () => { 
        if (hasChanged === true) {
            return { action: history.goBack, params: {mainEditView: true}};
        }
        return null;
    }

    const sceneData = {
        view: moduleSettings.name,
        title: workCardId + ' > ' + (materialId
            ? materialId
            : i18n.t('NEW_MATERIAL')),
        location: location,
        history: history,
        backAction: getBackButtonConfig(),
        hasChanged: hasChanged
    }

    const viewAction = {
        icon: 'save',
        label: '',
        clickFn: () => setSubmit(true),
        isActionFn: true,
        paClass: 'start-phase'
    }

    return (
        <div>
            <ScrollToTop />
            <NavigationBar
                currentView={sceneData}
                navHistory={history}
                viewAction={viewAction}
                popoverData={''}
            />
            <Container>
                <MaterialForm
                    material={workCardSparePart}
                    submit={submit} // boolean
                    onSubmit={handleSubmit}
                    workScheduleRights="editing"
                    setHasChanged={setHasChanged}
                />
            </Container>
        </div>
    );
}

export default EditMaterial;
