import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import i18n from '../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faFileAlt, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import FormElement from './../scenes/work-schedule/work-card/components/FormElement';
import { isNullOrWhitespace } from './HelperFunctions';
import { ScrollToTop } from './ScrollToTop';

interface State {
    file: any;
    linkToDocument: string;
    description: string;
    type: { id: number; label: string; };
    metaData: { [key: string]: IDocumentMetaDataGroup };
}

interface Props {
    ref: any;
    docRequirements?: IGlobalSetting[]
    invalidFields?: boolean;
    missingMetaDatas?: number[];
    docMetadata?: IDocumentMetaDataGroup[];
}

class FileInput extends React.Component<Props,State> {
    fileInput: React.RefObject<HTMLInputElement>;
    constructor(props) {
        super(props);

        this.fileInput = React.createRef();

        this.state = {
            file: null,
            linkToDocument: '',
            description: '',
            type: { id: 0, label: '' },
            metaData: {}
        }
    }

    handleChange = (e) => {
        const { files } = e.target;

        this.setState({
            file: files[0],
            linkToDocument: ''
        });
    }

    selectFile = (e) => {
        this.fileInput.current.click();
    }

    removeFile = () => {
        this.setState({ file: null });
    }

    getTypeOptions = () => {
        const options = [
            { id: 0, label: '' },
            { id: 1, label: 'Kuva' },
            { id: 2, label: 'Huolto-ohje' },
            { id: 3, label: 'Käyttöohje' },
            { id: 4, label: 'Automaatiokaavio' },
            { id: 5, label: 'Huoltoraportti' }
        ]

        return options;
    }

    handleMetadata = (value, { name }) => {
        this.setState(prevState => ({
            ...prevState,
            metaData: {
                ...prevState.metaData,
                [name]: value
            }
        }), () => {
            // Clear each metadata field whose parent value was changed
            this.props.docMetadata?.forEach(metadataGroup => {
                metadataGroup.documentMetaDatas.forEach(metadata => {
                    const parentId = metadata.parentDocumentMetadataGroupId;
                    const currentValue = this.state.metaData[metadataGroup.label];
                    if (parentId) {
                        if (!this.canShowMetadata(parentId) && currentValue?.id === metadata.id) {
                            const { [metadataGroup.label]: current, ...rest} = this.state.metaData;
                            this.setState({ metaData: rest });
                        }
                    }
                });
            })
        })
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    canShowMetadata = (parentDocumentMetadataGroupId: number) => (
        Object.values(this.state.metaData).some(({ id }) => id === parentDocumentMetadataGroupId)
    )

    metadataFilter = (metadata: IDocumentMetaData) => {
        const parentId = metadata.parentDocumentMetadataGroupId;
        if (parentId) {
            return this.canShowMetadata(parentId);
        } else {
            return true;
        }
    }

    render() {
        const { docRequirements, invalidFields, docMetadata, missingMetaDatas } = this.props;

        return (
            <Container>
                <ScrollToTop />
                <Row className="file-container">
                    <Col className="margin-top-15" xs={12}>
                        <div className="v-paddings-15">
                            <Button onClick={this.selectFile} className="action action-button novi-default-btn-color" variant="small">
                                <div>
                                    <FontAwesomeIcon icon={faFolderOpen} />
                                    <span className="left-margin-5">{i18n.t('SELECT_FILE')}</span>
                                </div>
                            </Button>
                        </div>
                        <input ref={this.fileInput} type="file" style={{ display: "none" }} onChange={this.handleChange} />
                    </Col>
                    <Col xs={12}>
                        {this.state.file !== null
                            ? <div className="files-container">
                                <Row className="file-row v-paddings-5">
                                    <Col xs={10}>
                                        <span>
                                            <FontAwesomeIcon icon={faFileAlt} size="lg" />
                                        </span>
                                        <span className="left-margin-5">
                                            {this.state.file?.name}
                                        </span>
                                    </Col>
                                    <Col xs={2}>
                                        <span className="row-action icon-warning" onClick={this.removeFile}>
                                            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                            : <div className="icon-default">
                                {i18n.t('NO_FILE')}
                            </div>
                        }
                    </Col>
                </Row>
                <div className="form-table-container margin-top-15 bottom-nav-space">
                    {!this.state.file && <div>
                        <FormElement
                            name="linkToDocument"
                            type="input"
                            label={i18n.t('LINK_TO_DOCUMENT')}
                            value={this.state.linkToDocument}
                            onChange={this.handleInputChange}
                        />
                    </div>}
                    <div className={docRequirements?.find(req => req.field === 'description') && invalidFields && !this.state.description ? 'invalid-field' : ''} >
                        <FormElement
                            name="description"
                            type="input"
                            label={i18n.t('DESCRIPTION')}
                            value={this.state.description}
                            onChange={this.handleInputChange}
                            required={docRequirements?.find(req => req.field === 'description')}
                        />
                    </div>
                    {/*<FormElement //ExtendedDocumentProperties
                        name="type"
                        type="select"
                        label={i18n.t('TYPE')}
                        value={this.state.type}
                        options={this.getTypeOptions()}
                        onChange={this.handleSelect}
                    />*/}
                    {docMetadata?.map(meta => {
                        const value = this.state.metaData[meta.label]
                        const isValid = !(missingMetaDatas?.find(i => i == meta.id) && (isNullOrWhitespace(value) || value?.id == -1))
                        return (
                            <div
                                key={'metadata' + meta.id}
                                className={isValid ? '' : 'invalid-field'}>
                                <FormElement
                                    key={meta.label}
                                    name={meta.label}
                                    type="select"
                                    label={i18n.t(meta.label)}
                                    value={value}
                                    onChange={this.handleMetadata}
                                    required={meta.required}
                                    options={meta.documentMetaDatas.filter(this.metadataFilter)}
                                />
                            </div>
                        );
                    })}
                </div>
            </Container>
        ) 
    }
}

export default FileInput;