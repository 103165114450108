import React, { useState, useEffect } from 'react';

function CustomCheckbox({ isChecked = false, setIsChecked = null, className = '' }) {
    const [isCustomBoxChecked, setIsCustomBoxChecked] = useState(isChecked);

    //toggle (prop) isChecked when chekcbox is checked
    useEffect(() => {
        if (setIsChecked !== null) {
            setIsChecked(isCustomBoxChecked);
        }
    }, [isCustomBoxChecked]);

    //toggle checkbox when (prop) isChecked is changed outside CustomCheckbox component
    useEffect(() => {
        if (setIsChecked !== null) {
            setIsCustomBoxChecked(isChecked);
        }
    }, [isChecked]);

    const spanClassName = `novi-checkbox ${className}`;

    return (
        <span className={spanClassName} onClick={() => setIsCustomBoxChecked(!isCustomBoxChecked)}>
            <input type="checkbox" checked={isCustomBoxChecked} readOnly />
            <span></span>
        </span>
    );
}

export default CustomCheckbox;