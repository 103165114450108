import NavigationBar from './NavigationBar';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { UserState } from 'redux-oidc';
import {
    getEnabledModules,
    setMachineGroupId,
    fetchUserRights,
    fetchUserGroups,
    setStatusesFulfilled,
    fetchSafetyTypes
} from './../../commonActions/actions';
import { logout, logoutSSO } from './../login/actions';
import { fetchMachineOptions } from '../machines/actions';
import { fetchWorkcardOptions } from '../work-schedule/work-card/actions';

interface MapState extends State { oidc: UserState };

const mapStateToProps = (state: MapState) => {
    const { userId, userDetails, userAdNameDetails, machineGroupId, machineGroups, userRights, noviConfigs } = state.settings;
    const { safetyTypes } = state.safetycards;

    const currentMachineGroup = machineGroups && machineGroups.find(mg => mg.value === machineGroupId);

    return {
        user: state.oidc && state.oidc.user ? state.oidc.user.profile.sub : userId || null,
        userDetails,
        userAdNameDetails,
        machineGroups,
        currentMachineGroup,
        users: state.workcards.options.workerOptions,
        userRights,
        noviConfigs,
        safetyTypes,
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    logout,
    logoutSSO,
    getEnabledModules,
    setMachineGroupId,
    fetchWorkcardOptions,
    fetchMachineOptions,
    fetchUserRights,
    fetchUserGroups,
    setStatusesFulfilled,
    fetchSafetyTypes
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type NavigationPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NavigationBar);
