import {
    FETCH_COST_POOLS,
    FETCH_COST_POOL_TYPES,
    CLEAR_CACHE,
    UPDATE_CURRENT_MACHINE_GROUP_ID
} from "../config/actionTypes"


const initialState = {
    costPools: {
        currentPageNumber: 0,
        totalResultCount: 0,
        totalPageCount: 0,
        results: []
    },
    costPoolTypes: [],
    byGroup: {},
    groups: []
}

const allGroups = (results: ICostPool[]) => {
    return results.reduce((arr, item) =>
        !arr.includes(item.costPoolGroupId)
            ? arr.concat(item.costPoolGroupId)
            : arr,
        []
    );
}

const byGroup = (results: ICostPool[], groups: number[]) => {
    return groups.reduce((obj, id) => (
        Object.assign(
            obj,
            { [id]: results.filter(i => i.costPoolGroupId === id) })
    ), {})
}

const scannersReducer = (state: ICostPoolsState = initialState, action) => {
    switch (action.type) {
        case FETCH_COST_POOLS: {
            const groups = allGroups(action.payload.results);
            return Object.assign({}, state,
                {
                    costPools: action.payload,
                    byGroup: byGroup(action.payload.results, groups),
                    groups: groups
                }
            )
        }
        case `${FETCH_COST_POOLS}_REJECTED`: {
            return Object.assign({}, state, { costPools: initialState.costPools })
        }
        case FETCH_COST_POOL_TYPES: {
            return Object.assign({}, state, { costPoolTypes: action.payload })
        }
        case `${FETCH_COST_POOL_TYPES}_REJECTED`: {
            return Object.assign({}, state, { costPoolTypes: initialState.costPoolTypes })
        }
        case UPDATE_CURRENT_MACHINE_GROUP_ID:
        case CLEAR_CACHE:
            return initialState
        default:
            return state;
    }
}

export default scannersReducer;