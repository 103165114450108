import { isNullOrWhitespace } from "components/HelperFunctions";
import { DocumentSource } from "constants/documents/documentSource";
import moment from "moment";
import 'moment/min/locales';
import i18n from "translations/i18n";

export const mapApplicationSettings = (applicationSettings: IApplicationSetting[]): INoviConfigs => {
    let obj: INoviConfigs = {};

    const noviConfigs = applicationSettings.reduce((acc, cur) => (
        Object.assign(acc, { [cur.name]: cur.value })
    ), obj);

    return noviConfigs;
}

export const searchTypeSearchesTypeParamMap = {
    'workcard': 'workCardSearches',
    'machine': 'machineSearches',
    'sparepart': 'sparePartSearches'
}

export const emptySearchData = <T>(): ISearchData<T> => ({
    currentPageNumber: 0,
    totalResultCount: 0,
    totalPageCount: 0,
    results: []
})

export const documentAdditionToFormData = (document: IDocumentAddition) => {
    // Backend API Add method has [FromForm] configuration and therefore document has to be sent as FormData
    const formData = new FormData();
        
    Object.entries(document).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(val => {
                formData.append(key, val);
            })
        } else {
            formData.append(key, value);
        }
    })

    return formData;
}

const emptyDocumentAddition = (): IDocumentAddition => ({
    file: null,
    linkToDocument: '',
    description: '',
    type: ''
})

export const documentAddition = (document: IDocumentAddition, targetType: DocumentSource, documentTargetId: number) => {
    let doc = emptyDocumentAddition();
    doc.file = document.file;
    doc.linkToDocument = document.linkToDocument;
    doc.description = document.description;
    doc.type = document.type;
    if (document.hasOwnProperty('isPreviewPicture')) {
        doc.isPreviewPicture = document.isPreviewPicture;
    }
    Object.assign(doc, getDocumentAdditionSource(targetType, documentTargetId));
    return doc;
}

export const getDocumentAdditionSource = (targetType: DocumentSource, id: number): IDocumentAdditionSource => {
    let obj: IDocumentAdditionSource = {};
    if (targetType === DocumentSource.Machine) {
        obj = { machineId: id }
    } else if (targetType === DocumentSource.SparePart) {
        obj = { sparePartId: id }
    } else if (targetType === DocumentSource.WorkCard) {
        obj = { workCardId: id }
    } else if (targetType === DocumentSource.UnreleasedMachine) {
        obj = { unreleasedMachineId: id }
    } else if (targetType === DocumentSource.OperatorMaintenance) {
        obj = { operatorMaintenanceId: id }
    } else if (targetType === DocumentSource.RouteMaintenanceWorkCard) {
        obj = { routeMaintenanceWorkCardId: id }
    } else if (targetType === DocumentSource.SafetyNotice) {
        obj = { safetyNoticeId: id }
    }
    return obj;
}

export const documentLinkAdditionToUrlParams = (documentLink: IDocumentLinkAddition): URLSearchParams => {
    const obj = {
        documentId: `${documentLink.documentId}`,
        targetType: `${documentLink.targetType}`,
        targetId: `${documentLink.targetId}`,
        isPreviewPicture: `${documentLink.isPreviewPicture}`
    }

    return new URLSearchParams(obj);
}

export const documentUnlinkToUrlParams = (documentLink: IDocumentUnlink): URLSearchParams => {
    const obj = {
        documentId: `${documentLink.documentId}`,
        targetType: `${documentLink.targetType}`,
        targetId: `${documentLink.targetId}`
    }

    return new URLSearchParams(obj);
}

export const DATE_FORMAT = 'L';
export const TIME_FORMAT = 'LT';
export const DATE_TIME_FORMAT = 'L LT';
export const DATE_DAY_NAME_FORMAT = 'L / dddd';
export const DATE_TIME_DAY_NAME_FORMAT = 'L LT / dddd';

function localizeMoment(date: moment.Moment) {
    if (isNullOrWhitespace(date)) {
        return;
    }

    let locales: string[] = [];
    i18n.language && locales.push(i18n.language)
    locales.push(navigator.language); // language of the browser UI
    locales.push('en'); // fallback language

    date.locale(locales);
}

export function addTimeDiff(date: moment.Moment) {
    if (isNullOrWhitespace(date)) {
        return date;
    }
    
    const timediff = Number.parseFloat(localStorage.getItem('timediff'));
    if (!Number.isNaN(timediff)) {
        date.add(timediff, 'hours');
    }
    return date;
}

export function subtractTimeDiff(date: moment.Moment) {
    if (isNullOrWhitespace(date)) {
        return date;
    }
    
    const timediff = Number.parseFloat(localStorage.getItem('timediff'));
    if (!Number.isNaN(timediff)) {
        date.subtract(timediff, 'hours');
    }
    return date;
}

/**
 * Returns moment with user's locale. Use carefully because it doesn't handle `timediff` automatically.
 * @param date 
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function getDateTimeBase(date?: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    if (date === null || (typeof date === 'string' && !date.trim())) {
        return null;
    }
    let m = moment(date);
    localizeMoment(m);
    if (fn) { fn(m); }
    return m;
}

/**
 * Use to display date in local date format (eg. 31.12.2030). Adds `timediff` if it is set.
 * @param date
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function getDate(date?: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    let m = getDateTimeBase(date, fn);
    addTimeDiff(m);
    return moment.isMoment(m) ? m.format(DATE_FORMAT) : '';
}

/**
 * Use to display date and time in local datetime format (eg. 31.12.2030 22:00). Adds `timediff` if it is set.
 * @param date
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function getDateTime(date?: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    let m = getDateTimeBase(date, fn);
    addTimeDiff(m);
    return moment.isMoment(m) ? m.format(DATE_TIME_FORMAT) : '';
}

/**
 * Use to display date and day of the week in local format (eg. 31.12.2030 22:00 / tiistai). Adds `timediff` if it is set.
 * @param date
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function getDateAndDayName(date?: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    let m = getDateTimeBase(date, fn);
    addTimeDiff(m);
    return moment.isMoment(m) ? m.format(DATE_TIME_DAY_NAME_FORMAT) : '';
}

/**
 * Use to display date, time, and day of the week in local format (eg. 31.12.2030 22:00 / tiistai). Adds `timediff` if it is set.
 * @param date
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function getDateTimeAndDayName(date?: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    let m = getDateTimeBase(date, fn);
    addTimeDiff(m);
    return moment.isMoment(m) ? m.format(DATE_TIME_DAY_NAME_FORMAT) : '';
}

/**
 * Use to parse *current* datetime into valid format for <input> elements of type datetime-local. Doesn't add `timediff`.
 * 
 * Note: react-datetime component `<Datetime>` doesn't work with this format but the value can be wrapped with moment before passing the prop
 * @param date 
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function initInputDateTime(fn?: (date: moment.Moment) => void) {
    let m = getDateTimeBase(moment(), fn);
    return moment.isMoment(m) ? m.format(moment.HTML5_FMT.DATETIME_LOCAL) : '';
}

/**
 * Use to parse datetime into valid format for <input> elements of type datetime-local. Adds `timediff` if it is set.
 * 
 * Note: react-datetime component `<Datetime>` doesn't work with this format but the value can be wrapped with moment before passing the prop
 * @param date 
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function getInputDateTime(date?: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    let m = getDateTimeBase(date, fn);
    addTimeDiff(m);
    return moment.isMoment(m) ? m.format(moment.HTML5_FMT.DATETIME_LOCAL) : '';
}

/**
 * Use to parse datetime into valid *date* format for the API. Substracts `timediff` if it is set.
 * @param date 
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function parsePayloadDate(date: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    if (isNullOrWhitespace(date)) {
        return null;
    }

    let m = moment(date);
    subtractTimeDiff(m);
    if (fn) { fn(m); }
    return m.format(moment.HTML5_FMT.DATE);
}

/**
 * Use to parse datetime into valid *datetime* format for the API. Substracts `timediff` if it is set.
 * @param date 
 * @param fn optional parameter that allows to manipulate `Moment`
 * @returns 
 */
export function parsePayloadDateTime(date: string | moment.Moment, fn?: (date: moment.Moment) => void) {
    if (isNullOrWhitespace(date)) {
        return null;
    }

    let m = moment(date);
    subtractTimeDiff(m);
    if (fn) { fn(m); }
    return m.format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
}

/**
 * Handles date from both `<Datetime>` and `<input type="datetime-local">` and returns { name, value } where 'name' is the field name and 'value' is the date
 * @param date
 * @param key
 */
export function handleDateTimeInput(date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) {
    let dateData = {
        name: name,
        value: ''
    }
    if (isNullOrWhitespace(date)) {
        return dateData;
    }
    else if (moment.isMoment(date)) {
        dateData.value = date.format(moment.HTML5_FMT.DATETIME_LOCAL);
    }
    else if (date.target) {
        dateData.name = date.target.name;
        dateData.value = date.target.value;
    }
    return dateData;
}
