import { HandleError, Toaster } from '../../components/HelperFunctions';
import * as types from '../../config/actionTypes';
import noviAPI from '../../api/noviAPI';
import { IRedirect } from '../machines/actions';

export const fetchWarehouses = () => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${types.FETCH_WAREHOUSES}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            const { data } = await noviAPI.warehouses.fetchAllByMachineGroup(machineGroupId);

            dispatch({
                type: `${types.FETCH_WAREHOUSES}_FULFILLED`,
                payload: data
            });
        } catch (error) {
            HandleError(error, 'Fetch warehouse');

            dispatch({
                type: `${types.FETCH_WAREHOUSES}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchWarehousesWithParams = (params) => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${types.FETCH_WAREHOUSES_WITH_PARAMS}_PENDING`
        });

        let queryParams = new URLSearchParams();

        queryParams.append('PageSize', params.pageSize || 100);
        queryParams.append('PageNumber', params.pageNumber || 0);
        queryParams.append('SparePartId', params.sparePartId || '');
        queryParams.append('MachineGroupId', params.machineGroupId || `${getState().settings.machineGroupId}`)

        noviAPI.warehouses.search(queryParams)
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_WAREHOUSES_WITH_PARAMS}_FULFILLED`,
                    payload: data.results
                });
            }).catch(error => {
                HandleError(error, 'Fetch warehouses with params');

                dispatch({
                    type: `${types.FETCH_WAREHOUSES_WITH_PARAMS}_REJECTED`,
                    payload: error
                });
            });
    }  
);

export type IInventWarehouseSparePart = (
    warehouseId: number, 
    sparePartLink: IWarehouseSparePartLink | IWarehouseSparePartLinkSearchResult,
    amount: ISparePartInventAmount,
    redirect?: IRedirect
) => void;

export const inventWarehouseSparePart: IInventWarehouseSparePart = (warehouseId, sparePartLink, amount, redirect = null) => (
    dispatch => {
        dispatch({
            type: `${types.INVENT_WAREHOUSE_SPARE_PART}_PENDING`
        });

        noviAPI.warehouseOperations.invent(warehouseId, sparePartLink.sparePart.id, amount)
            .then(_response => {
                dispatch({
                    type: `${types.INVENT_WAREHOUSE_SPARE_PART}_FULFILLED`,
                    payload: sparePartLink
                });

                if (redirect !== null) {
                    const location = {
                        pathname: '/warehouseinvent',
                        state: { notificationMsg: 'SPARE_PART_SAVED' }
                    }

                    redirect(location);
                }
            })
            .catch(error => {
                HandleError(error, 'Update warehouse spare part data (invent)');
            })
    }
)

export type ITakeWarehouseSparePart = (warehouseId: number, sparePartId: number, sparePart: ISparePartTake, redirect: () => void) => void;

export const takeWarehouseSparePart: ITakeWarehouseSparePart = (warehouseId, sparePartId, sparePart, redirect) => (
    dispatch => {
        dispatch({
            type: `${types.TAKE_WAREHOUSE_SPARE_PART}_PENDING`
        });

        noviAPI.warehouseOperations.take(warehouseId, sparePartId, sparePart)
            .then(_response => {
                dispatch({
                    type: `${types.TAKE_WAREHOUSE_SPARE_PART}_FULFILLED`,
                    payload: {
                        warehouseId,
                        sparePart: {
                            sparePartId,
                            ...sparePart
                        }
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Update warehouse spare part data');
            });
    }
)


export type IReturnWarehouseSparePart = (warehouseId: number, sparePartId: number, sparePart: ISparePartReturn, redirect: () => void) => void;

export const returnWarehouseSparePart: IReturnWarehouseSparePart = (warehouseId, sparePartId, sparePart, redirect) => (
    dispatch => {
        dispatch({
            type: `${types.RETURN_WAREHOUSE_SPARE_PART}_PENDING`
        });

        noviAPI.warehouseOperations.return(warehouseId, sparePartId, sparePart)
            .then(_response => {
                dispatch({
                    type: `${types.RETURN_WAREHOUSE_SPARE_PART}_FULFILLED`,
                    payload: {
                        warehouseId,
                        sparePart: {
                            sparePartId,
                            ...sparePart
                        }
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Update warehouse spare part data');

                dispatch({
                    type: `${types.RETURN_WAREHOUSE_SPARE_PART}_REJECTED`,
                    payload: error
                });
            });
    }
)


export type IArriveWarehouseSparePart = (warehouseId: number, sparePartId: number, sparePart: ISparePartArrive, redirect: () => void) => void;

export const arriveWarehouseSparePart: IArriveWarehouseSparePart = (warehouseId, sparePartId, sparePart, redirect) => (
    dispatch => {
        dispatch({
            type: `${types.ARRIVE_WAREHOUSE_SPARE_PART}_PENDING`
        });

        noviAPI.warehouseOperations.arrive(warehouseId, sparePartId, sparePart)
            .then(_response => {
                dispatch({
                    type: `${types.ARRIVE_WAREHOUSE_SPARE_PART}_FULFILLED`,
                    payload: {
                        warehouseId,
                        sparePart: {
                            sparePartId,
                            ...sparePart
                        }
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Update warehouse spare part data');
            });
    }
)

export type IArrivePurchaseOrderItem = (warehouseId: number, sparePartId: number, params: IPurchaseOrderItemArrival, redirect: () => void) => void;
export const arrivePurchaseOrderItem : IArrivePurchaseOrderItem = (warehouseId, sparePartId, params, redirect) => (
    dispatch => {
        dispatch({
            type: `${types.ARRIVE_PURCHASE_ORDER_ITEM}_PENDING`
        });

        noviAPI.purchaseOrderItems.arrive(warehouseId, sparePartId, params)
            .then(_response => {
                dispatch({
                    type: `${types.ARRIVE_PURCHASE_ORDER_ITEM}_FULFILLED`,
                    payload: {
                        warehouseId,
                        sparePart: {
                            sparePartId,
                        },
                        response: _response
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Arrive purchaseOrderItem');
                
                dispatch({
                    type: `${types.ARRIVE_PURCHASE_ORDER_ITEM}_REJECTED`,
                    payload: error
                });
            });
    }
)

export type IChangeWarehouseSparePart = (warehouseId: number, sparePartId: number, sparePart: ISparePartChange, redirect: () => void) => void;

export const changeWarehouseSparePart: IChangeWarehouseSparePart = (warehouseId, sparePartId, sparePart, redirect) => (
    dispatch => {
        dispatch({
            type: `${types.CHANGE_VALUE_WAREHOUSE_SPARE_PART}_PENDING`
        });

        noviAPI.warehouseOperations.price(warehouseId, sparePartId, sparePart)
            .then(_response => {
                dispatch({
                    type: `${types.CHANGE_VALUE_WAREHOUSE_SPARE_PART}_FULFILLED`,
                    payload: {
                        warehouseId,
                        sparePart: {
                            sparePartId,
                            ...sparePart
                        }
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Update warehouse spare part data');
            });
    }
)

export type ITransferWarehouseSparePart = (warehouseId: number, sparePartId: number, sparePart: ISparePartTransfer, redirect: () => void) => void;

export const transferWarehouseSparePart: ITransferWarehouseSparePart = (warehouseId, sparePartId, sparePart, redirect) => (
    dispatch => {
        dispatch({
            type: `${types.TRANSFER_WAREHOUSE_SPARE_PART}_PENDING`
        });

        noviAPI.warehouseOperations.transfer(warehouseId, sparePartId, sparePart)
            .then(_response => {
                dispatch({
                    type: `${types.TRANSFER_WAREHOUSE_SPARE_PART}_FULFILLED`,
                    payload: {
                        warehouseId,
                        sparePart: {
                            sparePartId,
                            ...sparePart
                        }
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Update warehouse spare part data');
            });
    }
)

export type UpdateWarehouseLink = (id: number, warehouseLink: IWarehouseSparePartLinkUpdate, redirect: IRedirect) => void;

export const updateWarehouseLink: UpdateWarehouseLink = (id, warehouseLink, redirect) => (
    dispatch => {
        noviAPI.warehouseSparePartLinks.update(id, warehouseLink)
            .then(() => {
                dispatch({
                    type: `${types.UPDATE_WAREHOUSE_LINK}_FULFILLED`,
                    payload: {
                        id,
                        warehouseLink
                    }
                })

                const location = {
                    pathname: `/warehouse`,
                    state: { notificationMsg: 'WAREHOUSE_LINK_SAVED' }
                }

                redirect(location);
            })
            .catch(error => {
                HandleError(error, 'Update warehouse link');
            })
    }
)

export const fetchSparePartDetails = () => (
    dispatch => {
        noviAPI.sparePartDetails.fetchAll()
            .then(response => {
                dispatch({
                    type: 'FETCH_SPARE_PART_DETAILS',
                    payload: response.data
                });
            }).catch(error => {
                HandleError(error, 'Fetch spare part details');
            });
    }
);

export const takeWildSparePart = (id: number, amount: number, redirect: () => void) => (
    dispatch => {

        dispatch({
            type: `${types.TAKE_WORKCARD_SPARE_PARTS}_PENDING`
        });

        const params = new URLSearchParams({ amount: `${amount}` });
        
        noviAPI.workCardSpareParts.take(id, params)
            .then(_ => {
                dispatch({
                    type: `${types.TAKE_WORKCARD_SPARE_PARTS}_FULFILLED`,
                    payload: {
                        id,
                        amount
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Take wild spare part');
                
                dispatch({
                    type: `${types.TAKE_WORKCARD_SPARE_PARTS}_REJECTED`,
                    payload: error.response
                });
            })
    }
);

export const returnWildSparePart = (id: number, amount: number, redirect: () => void) => (
    dispatch => {

        dispatch({
            type: `${types.RETURN_WORKCARD_SPARE_PARTS}_PENDING`
        });

        const params = new URLSearchParams({ amount: `${amount}` });
        
        noviAPI.workCardSpareParts.return(id, params)
            .then(_ => {
                dispatch({
                    type: `${types.RETURN_WORKCARD_SPARE_PARTS}_FULFILLED`,
                    payload: {
                        id,
                        amount
                    }
                });

                redirect();
            })
            .catch(error => {
                HandleError(error, 'Return wild spare part');
                
                dispatch({
                    type: `${types.RETURN_WORKCARD_SPARE_PARTS}_REJECTED`,
                    payload: error
                });
            })
    }
);

export const resetCurrentWarehouseFilters = () => (
    dispatch => {
        dispatch({
            type: types.RESET_WAREHOUSE_CURRENTFILTERS
        })
    }
)

export const setPageSizeAction = payload => ({
    type: 'SET_WAREHOUSE_PAGE_SIZE',
    payload
});

export const setPageNumberAction = payload => ({
    type: 'SET_WAREHOUSE_PAGE_NUMBER',
    payload
});

export const setListLayoutAction = payload => ({
    type: 'SET_WAREHOUSE_LIST_LAYOUT',
    payload
});

export const setWarehouseSettings = payload => ({
    type: 'SET_WAREHOUSE_SETTINGS',
    payload
});

export const selectCurrentWarehouseAction = payload => ({
    type: 'SELECT_CURRENT_WAREHOUSE',
    payload
});

export const setFiltersAction = payload => ({
    type: 'SET_WAREHOUSE_FILTERS',
    payload
});

export const clearFiltersAction = () => ({
    type: 'CLEAR_WAREHOUSE_FILTERS'
});
