import React from 'react';
import i18n from '../../../translations/i18n';
import ScannerContainer from '../../../components/scanners/ScannerContainer';
import { ComponentTypes } from '../../../constants/dashboard/componentTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

const DashboardItem = (props: LinkItemProps | ButtonItemProps | SearchItemProps) => {
    const { componentTypeId, label } = props;

    switch (componentTypeId) {
        case ComponentTypes.Link:
        case ComponentTypes.SmallLink:
            const { unitLabel, count, loadingStatus = true } = props;
            return <div className="info-section">
                <div className="info-title">
                    {i18n.t(label)}
                </div>
                <div className="info-details">
                    {loadingStatus === false ? <p>{!isNaN(count) && count}</p> : <FontAwesomeIcon icon={faSpinner} size="lg" pulse={true} />}
                    {(loadingStatus === false && !isNaN(count)) && i18n.t(unitLabel).toLowerCase()}
                </div>
            </div>
        case ComponentTypes.Button:
            const { eventKey, openScannerWrapper } = props;
            return (
                <div className="dashboard-button-container">
                    <ScannerContainer 
                        eventKey={eventKey}
                        type="QR"
                        buttonLabel={i18n.t(label)}
                        openScannerWrapper={openScannerWrapper}
                    />
                </div>
            );
        case ComponentTypes.Search:
            const { property, openSearch, dashboardItem } = props;
            return <div className="dashboard-button-container">
                <Button onClick={() => openSearch?.(componentTypeId, property, dashboardItem.itemTypeId, dashboardItem.itemValue)} className="action input-row-button novi-default-btn-color ">
                    <div className="flex-inline">
                        <span className="qr-button-label" >{i18n.t(label)}</span>
                        <FontAwesomeIcon icon={faSearch} size="2x" />
                    </div>
                </Button>
            </div>
        default: 
            return <></>
    }
}

interface BaseDashboardItemProps {
    componentTypeId: ComponentTypes;
    label: string;
}

interface LinkItemProps extends BaseDashboardItemProps {
    componentTypeId: ComponentTypes.Link | ComponentTypes.SmallLink;
    unitLabel: string;
    count: number;
    loadingStatus: boolean;
    property?: string;
}

interface ButtonItemProps extends BaseDashboardItemProps {
    eventKey: number | string;
    componentTypeId: ComponentTypes.Button;
    openScannerWrapper: (fn: () => void) => void;
}

interface SearchItemProps extends BaseDashboardItemProps {
    componentTypeId: ComponentTypes.Search;
    openSearch: Function;
    property: string;
    dashboardItem: any;
}

export default DashboardItem;
