import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlusSquare,
    faThLarge,
    faList,
    faTrashAlt,
    faPen,
    faPlusCircle,
    faSitemap,
    faEye,
    faEyeSlash,
    faSignature,
    faFileAlt,
    faChartBar,
    faBox,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faSave,
    faTruckLoading,
    faTable,
    faClipboardList,
    faMarker,
    faDolly,
    faSearch,
    faCopy,
    faCheckCircle,
    faArrowsAlt,
    faTimes,
    faBan
} from '@fortawesome/free-solid-svg-icons';
import { actionIcons } from '../constants/icons';

const ActionIcon = (props: IProps) => {
    switch (props.iconType) {
        case 'add-square':
            return <FontAwesomeIcon icon={faPlusSquare} className="icon-primary" size="lg" />;
        case 'task':
            return <FontAwesomeIcon icon={faClipboardList} className="icon-fault" size="lg" />;
        case 'cards':
            return <FontAwesomeIcon icon={faThLarge} size="lg" />;
        case 'table':
            return <FontAwesomeIcon icon={faTable} size="lg" />;
        case 'list':
            return <FontAwesomeIcon icon={faList} size="lg" />;
        case 'tree':
            return <FontAwesomeIcon icon={faSitemap} size="lg" />;
        case 'delete':
            return <FontAwesomeIcon icon={faTrashAlt} className="icon-danger" size="lg" />;
        case 'edit':
            return <FontAwesomeIcon icon={faPen} size="lg" className="icon-edit" />;
        case 'add-circle':
            return <FontAwesomeIcon icon={faPlusCircle} className="icon-success" size="lg" />;
        case 'show':
            return <FontAwesomeIcon icon={faEye} size="lg" />;
        case 'hide':
            return <FontAwesomeIcon icon={faEyeSlash} size="lg" />;
        case 'signature':
            return <FontAwesomeIcon icon={faSignature} size="lg" />;
        case 'view':
            return <FontAwesomeIcon icon={faFileAlt} size="lg" className="icon-interaction" />;
        case 'chart-bar':
            return <FontAwesomeIcon icon={faChartBar} size="lg" />;
        case 'take':
            return <span><FontAwesomeIcon icon={faBox} /> <FontAwesomeIcon icon={faLongArrowAltDown} /></span>;
        case 'return':
            return <span><FontAwesomeIcon icon={faBox} /> <FontAwesomeIcon icon={faLongArrowAltUp} /></span>;
        case 'arrive':
            return <FontAwesomeIcon icon={faTruckLoading} size="lg" />;
        case 'change':
            return <FontAwesomeIcon icon={faMarker} size="lg" />;
        case 'transfer':
            return <FontAwesomeIcon icon={faDolly} size="lg" />;
        case 'save':
            return <FontAwesomeIcon icon={faSave} size="lg" className="icon-primary" />;
        case 'search':
            return <FontAwesomeIcon icon={faSearch} size="lg" />;
        case 'copy':
            return <FontAwesomeIcon icon={faCopy} size="lg" />;
        case 'next-status':
            return <FontAwesomeIcon icon={faCheckCircle} size="lg" />;
        case 'move':
            return <FontAwesomeIcon icon={faArrowsAlt} size="lg" className="icon-primary" />;
        case 'close':
            return <FontAwesomeIcon icon={faTimes} size="lg" className="icon-primary" />;
        case 'cancel':
            return <FontAwesomeIcon icon={faBan} size="lg" className="icon-warning" />;
        default:
            return null;
    }
}

export type ActionIconType = typeof actionIcons[keyof typeof actionIcons];

interface IProps { iconType: ActionIconType; }

export default ActionIcon;
