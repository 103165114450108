import React from 'react';
import i18n from 'translations/i18n';
import FormElement from './FormElement';
import RouteMaintenanceWorkCardPhaseList from './RouteMaintenanceWorkCardPhaseList';
import { containsAny } from 'components/HelperFunctions';
import { Container } from 'react-bootstrap';

const RouteMaintenanceWorkCardPhaseForm = (props: IProps) => {

    const filterWorkerOptions = (workerOpts) => {
        if (props.routeWcState.workerGroups && props.routeWcState.workerGroups.length > 0) {
            const workerGroupIds = props.routeWcState.workerGroups.map(workerGroup => workerGroup.id);
            return workerOpts.filter(worker => containsAny(worker.personGroupIds, workerGroupIds));
        }

        return workerOpts;
    }

    const className = (fieldName: string) => props.routeWcState.invalidFields.includes(fieldName) ? 'invalid-field' : '';

    return (
        <React.Fragment>
            <Container>
                <div className="form-table-container v-margins-15">
                    <form>
                        <div className={className('workerGroups')} data-cy={'workergroup_field'}>
                            <FormElement
                                name={'workerGroups'}
                                type={'worker'}
                                label={i18n.t('WORKER_GROUP')}
                                value={props.routeWcState.workerGroups}
                                onChange={props.handleSelect}
                                options={props.workerGroupOptions}
                            />
                        </div>
                        <div className={className('workers')} data-cy={'worker_field'}>
                            <FormElement
                                name={'workers'}
                                type={'worker'}
                                label={i18n.t('WORKER')}
                                value={props.routeWcState.workers}
                                onChange={props.handleSelect}
                                options={filterWorkerOptions(props.workerOptions)}
                                required
                            />
                        </div>
                    </form>
                </div>
            </Container>
            <RouteMaintenanceWorkCardPhaseList
                phases={props.phases}
                routeWcState={props.routeWcState}
                handleInputChange={props.handleInputChange}
                handleDatetime={props.handleDatetime}
                handleSelect={props.handleSelect}
                hourCardFields={props.hourCardFields}
            />
        </React.Fragment>
    )
}

interface IProps {
    routeWcState;
    workerGroupOptions;
    workerOptions;
    handleInputChange;
    handleDatetime;
    handleSelect;
    hourCardFields;
    phases: IRouteMaintenancePhase[];
}

export default RouteMaintenanceWorkCardPhaseForm;