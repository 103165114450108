import { SortTypes } from "constants/quickSearches/SortTypes";

export const addSortingParams = (searchParams: URLSearchParams, sortType) => {

    const orderSettingId = sortType?.id ?? -1;
    const types = SortTypes.safetyNotices;

    // Ordering parameters
    switch (orderSettingId) {
        
        case types.NEWEST.id:
            searchParams.append('OrderDescending', 'true');
            searchParams.append('OrderBy', "OrderDate");
            break;

        case types.OLDEST.id:
            searchParams.append('OrderDescending', 'false');
            searchParams.append('OrderBy', "OrderDate");
            break;

        default:
            searchParams.append('OrderDescending', 'true');
            searchParams.append('OrderBy', "OrderDate");
            break;
    }
}