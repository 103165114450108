import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import noviAPI from '../../../../api/noviAPI';
import NumberField from '../../../../components/form/NumberField';
import TextField from '../../../../components/form/TextField';
import { HasRight, Toaster } from '../../../../components/HelperFunctions';
import { Loader } from '../../../../components/Loader';
import PanelRow from '../../../../components/panels/PanelRow';
import PanelText from '../../../../components/panels/PanelText';
import settingsAPI from '../../../../config/settingsAPI';
import i18n from '../../../../translations/i18n';
import NavigationBar from '../../../navigation';
import { useSelector } from 'react-redux';
import { UserRights } from 'constants/userRights';

const moduleSettings = settingsAPI.moduleData.counters;

const Counters = () => {
    let history = useHistory();
    let location = useLocation();

    const [counters, setCounters] = useState<ICounter[]>([]);
    const [showLoader, setShowLoader] = useState(true);
    const searchParams = new URLSearchParams(location.search);

    const initFields = { name: '', value: '' };
    const [fields, setFields] = useState(initFields);

    const { userRights } = useSelector((state: State) => state.settings);
    const hasEditRight = HasRight(UserRights.CountersEdit, userRights);

    useEffect(() => {
        const getCounters = async () => {
            const results = await fetchCounters();
            setCounters(results);
            setFields(initFields);
            setShowLoader(false);
        }

        if (searchParams.get('machineIds')) {
            getCounters();
        }

        if (searchParams.get('id')) {
            const id = Number(searchParams.get('id'));
            const counter = counters.find(i => i.id === id);
            setFields({ name: counter.name, value: `${counter.value}` })
        }
    }, [location.search]);

    const fetchCounters = async () => {
        try {
            const response = await noviAPI.counters.search(searchParams);
            return response.data.results;
        } catch (e) {
            return [];
        }
    }

    const save = async () => {
        const id = Number(searchParams.get('id'));
        const name = fields.name;
        const value = Number(fields.value);

        if (!Number.isInteger(value) || value <= 0) {
            Toaster({ msg: 'CHECK_FIELDS' })
            return;
        }

        setShowLoader(true);

        try {
            await noviAPI.counters.patch(id, { name, value });
        } catch (e) {
            Toaster({ msg: 'ERROR', type: 'error' })
        }
        history.goBack();
    }

    const createRow = ({ id, name, value }) => (
        <PanelRow 
            key={id} 
            editItem={hasEditRight ? () => history.push(`?id=${id}`) : null}
        >
            <PanelText>
                {name}
            </PanelText>
            <PanelText small>
                {i18n.t('COUNTERID')}: {id}
            </PanelText>
            <PanelText small>
                {i18n.t('VALUE')}: {value}
            </PanelText>
        </PanelRow>
    )

    const list = counters.map(createRow);

    const form = (
        <div className="form-table-container margin-top-15">
            <TextField
                label={i18n.t('COUNTERID')}
                name="id"
                value={searchParams.get('id')}
                disabled
            />
            <TextField
                label={i18n.t('NAME')}
                name="name"
                value={fields.name}
                onChange={(e) => setFields({
                    ...fields,
                    name: e.target.value
                })}
            />
            <NumberField
                label={i18n.t('VALUE')}
                name="value"
                min={0}
                value={fields.value}
                onChange={(e) => {
                    setFields({
                        ...fields,
                        value: e.target.value
                    })
                }}
                required
            />
        </div>
    )

    const sceneData = {
        view: moduleSettings.name,
        title: i18n.t('COUNTERS_HEADER'),
        location: location,
        history: history,
        itemColors: []
    }

    let popoverData = {
        popoverClass: 'menu-popover',
        popoverActions: []
    }

    const viewAction = (searchParams.has('id') && hasEditRight)
        ? {
            icon: 'save',
            label: '',
            clickFn: save,
            isActionFn: true,
            paClass: 'start-phase'
        }
        : '';

    return (
        <React.Fragment>
            <NavigationBar
                currentView={sceneData}
                viewAction={viewAction}
                popoverData={popoverData}
            />
            <Container className="bottom-margin-100">
                {showLoader
                    ? <Loader status="pending" />
                    : searchParams.has('machineIds')
                        ? list
                        : form
                    }
            </Container>
        </React.Fragment>
    )
}

export default Counters;