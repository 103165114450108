import React, { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import WarehouseLinkForm from './WarehouseLinkForm';
import NavigationBar from '../../navigation';
import i18n from '../../../translations/i18n';
import { GetTranslationKeyByProp } from '../../../components/HelperFunctions';

const WarehouseLinkEdit = () => {
	let history = useHistory();
	let location = useLocation();
	const { warehouseLinkId } = useParams<UrlParams>();
	const [submit, setSubmit] = useState(false);
	const [hasChanged, setHasChanged] = useState(false);

	const sceneData = {
		view: 'warehouselink',
		title: i18n.t(GetTranslationKeyByProp('warehouselinkedit')),
		location: location,
		history: history,
		backAction: { action: history.goBack, params: {mainEditView: true} },
		hasChanged: hasChanged
	}

	const viewAction = {
		icon: 'save',
		label: '',
		clickFn: () => setSubmit(true),
		isActionFn: true,
		paClass: 'start-phase',
	}

	return (
		<div>
			<NavigationBar
				currentView={sceneData}
				viewAction={viewAction}
			/>
			<WarehouseLinkForm
				warehouseLinkId={parseInt(warehouseLinkId, 10)}
				submit={submit}
				setHasChanged={setHasChanged}
			/>
		</div>	
	);
}

type UrlParams = { warehouseLinkId: string; };

export default WarehouseLinkEdit;
