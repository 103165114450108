import React from 'react';
import WorkcardCardComponent from './WorkcardCardComponent';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { HasRight } from '../../../../components/HelperFunctions';
import WorkCardTable from './WorkCardTable';

/** Work list */
interface IProps1 { cardList; showPhases: boolean; cardLayout: boolean; showAllPhases; togglePhases; }

/** Machine, Fault notice, etc.  */
interface IProps2 { cardList; showPhases: false; cardLayout: boolean; showAllPhases?; togglePhases?; }

const WorkCards = ({ cardList, showPhases, cardLayout, showAllPhases = {}, togglePhases }: IProps1 | IProps2) => {
    const tableSettings = useSelector((state: State) => state.settings.userSettings.filter(i => i.group === 'workschedulegrid'));
    const settings = useSelector((state: State) => state.settings);

    if (!cardLayout && HasRight('workschedulegrid', settings.userRights)) {
        return (
            <WorkCardTable
                tableSettings={tableSettings}
                workCards={cardList}
            />
        )
    } else {
        return (
            <Row>
                {cardList.map(item => (
                    <WorkcardCardComponent
                        key={item.workCard.id}
                        cardData={item}
                        showPhases={showPhases}
                        showAllPhases={showAllPhases}
                        togglePhases={togglePhases}
                    />
                ))}
            </Row>
        )
    }
}

export default WorkCards;
