import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router';
import noviAPI from '../../api/noviAPI';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import i18n from '../../translations/i18n';
import NavigationBar from '../navigation';
import '../../styles/global.scss';
import { Loader } from '../../components/Loader';
import { HandleError } from '../../components/HelperFunctions';
import settingsAPI from '../../config/settingsAPI';
import PanelContainer from '../work-schedule/work-card/components/PanelContainer';
import { getDate } from 'utils';
import SafetyCardsPopover from './Components/SafetyCardsPopover';
import { searchSafetyCards } from './actions';
import CardComponent from 'components/CardComponent';
import PaginationContainer from 'components/PaginationContainer';
import SearchResultInfo from 'components/SearchResultInfo';
import { SET_SAFETY_SETTINGS } from 'config/actionTypes';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

type LocationState = { notificationMsg?: string; };
type UrlParams = { id: string; };

const moduleSettings = settingsAPI.moduleData.safety;

const SafetyCards = () => {
    const { id } = useParams<UrlParams>();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<LocationState>();

    if (location.state?.notificationMsg) {
        toast.success(i18n.t(location.state.notificationMsg), {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });

        delete location.state.notificationMsg;
        history.replace({ state: location.state });
    }

    const { safetyNotices, settings: displaySettings, displayInfo, status } = useSelector((state: State) => state.safetycards);
    const [dialogOpen, toggleDialog] = useReducer((prevValue, newValue?) => newValue ?? !prevValue, false);

    const navProps = { 
        currentView: {
            history,
            location,
            title: i18n.t(`SAFETY_CARDS_HEADER${id}`),
            view: moduleSettings.name,
            subPhaseLabel: '',
            backAction: {
                action: history.goBack
            }
        },
        viewAction: "settings",
        popover: <SafetyCardsPopover 
            safetyTypeId={id}
            dialogOpen={dialogOpen} 
            toggleDialog={toggleDialog}
        />,
    };

    useEffect(() => {
        dispatch(searchSafetyCards(id));
    }, [displaySettings, id]);

    const setPage = page => {
        dispatch({
            type: SET_SAFETY_SETTINGS,
            payload: { pageNumber: page }
        })
    }

    return (
        <div>
            <NavigationBar {...navProps} />
            <Container className="margin-top-15 margin-bottom-70 v-paddings-15">
                <SearchResultInfo
                    pageSize={displaySettings.pageSize}
                    pageNumber={displaySettings.pageNumber}
                    totalResultCount={displayInfo.totalResultCount}
                />
                <Loader status={status} />
                <Row>
                    {displayInfo.totalResultCount === 0 && status === 'fulfilled' && <div className="no-results"><p>{i18n.t('NO_RESULTS')}</p></div>}
                    {displayInfo.totalResultCount > 0 && (
                        <>
                            <PaginationContainer
                                currentPage={displaySettings.pageNumber}
                                setCurrentPage={setPage}
                                pageCount={displayInfo.totalPageCount}
                            />

                            {displaySettings.cardLayout && safetyNotices?.map(safetyNotice => {
                                const { orderDate, ordererText, machine, code, responsiblePerson, status } = safetyNotice;

                                return (
                                    <CardComponent
                                        extraHeadingContainer={null}
                                        itemColors={[]}
                                        title={
                                            <Row>
                                                <Col xs={12} className="v-paddings-5">
                                                    <span className="bold flex">
                                                        <span className="left-margin-5">#{code}</span>
                                                    </span>
                                                </Col>
                                            </Row>
                                        }
                                        body={
                                            <div onClick={() => console.log(`[ TODO: Open safety notice ]`, safetyNotice)}>
                                                {/* TODO: Replace hardcoded safetycard with dynamic based on viewSettings */}
                                                <Row className="margin-bottom-5">
                                                    <Col xs={4} className="sub-header-light break-word">{i18n.t('TILAUSPVM')}</Col>
                                                    <Col xs={8} className="break-word">{getDate(orderDate)}</Col>
                                                </Row>
                                                <Row className="margin-bottom-5">
                                                    <Col xs={4} className="sub-header-light break-word">{i18n.t('SAFETY_CARDS_ORDERER')}</Col>
                                                    <Col xs={8} className="break-word">{ordererText}</Col>
                                                </Row>
                                                <Row className="margin-bottom-5">
                                                    <Col xs={4} className="sub-header-light break-word">{i18n.t('MACHINE')}</Col>
                                                    <Col xs={8} className="break-word">{machine ? `${machine?.code ?? ''} / ${machine?.name ?? ''}` : ''}</Col>
                                                </Row>
                                                <Row className="margin-bottom-5">
                                                    <Col xs={4} className="sub-header-light break-word">{i18n.t('STATUS')}</Col>
                                                    <Col xs={8} className="break-word">{i18n.t(`SAFETY_CARD_STATUS${status}`)}</Col>
                                                </Row>
                                                <hr />
                                                <Col xs={12}>
                                                    <div className="sub-header-light flex">
                                                        <FontAwesomeIcon icon={faUser} />
                                                        {responsiblePerson
                                                            ? <span className="left-margin-5">{responsiblePerson.name}</span>
                                                            : <span className="left-margin-5 cursive">{i18n.t('NO_RESPONSIBLE_PERSON')}</span>}
                                                    </div>
                                                </Col>
                                            </div>
                                        }
                                    />
                                )})
                            }

                            <PaginationContainer
                                currentPage={displaySettings.pageNumber}
                                setCurrentPage={setPage}
                                pageCount={displayInfo.totalPageCount}
                            />
                        </>
                    )}
                </Row>
            </Container>
        </div>
    );
}

export default SafetyCards;