import React from 'react';
import '../../styles/global.scss';
import './styles/machines.scss';
import { Container, Row } from 'react-bootstrap';
import NavigationBar from '../navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../translations/i18n';
import MachineTree from './components/MachineTree';
import { sortHierarchyItems } from '../../components/HelperFunctions';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import settingsAPI from '../../config/settingsAPI';
import { IMachinesProps, PropsFromRedux } from '.';
import MachinesSettingsMenu from './components/MachinesSettingsMenu';
import MachineTable from './components/MachineTable';
import MachineCardComponent from './components/MachineCardComponent';
import ListLayout from 'components/ListLayout';
import { countActiveMachineFilters } from './utils';

type IProps = IMachinesProps & PropsFromRedux;

interface IState {
    listFilter: number;
    deviceSearchValue: string;
    currentMachines: any[];
    listScrollPos: number;
    dialogOpen: boolean;
}

const moduleSettings = settingsAPI.moduleData.machinelist;

class Machines extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            listFilter: 0,
            deviceSearchValue: '',
            currentMachines: [],
            listScrollPos: 0,
            dialogOpen: false
        };
    }

    componentDidMount = () => {
        const { location, history, displaySettings } = this.props;


        if (location && location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }

            // Set scroll position if returning from machine view
            if (prevState.heightPos) {
                this.setState({ listScrollPos: prevState.heightPos });
                delete prevState.heightPos;
                history.replace({ state: prevState });
            }
        }
        if (displaySettings.layoutType !== 'treelayout') {
            if (this.props.displaySettings.useDefaultSearch === true) {
                this.props.machineDefaultSearch({
                    pageSize: displaySettings.pageSize
                });
            } else {
                this.callFetchMachines()
            }
        } else {
            this.callFetchMachines()
        }
        this.props.fetchMachineOptions();

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });

        this.scrollBreadCrumbPath();
    }

    componentDidUpdate(prevProps: IProps) {
        const {
            match,
            displaySettings,
            machineSelect,
            location,
        } = this.props;

        let currentM;
        let prevM;

        //const { quickSearch } = prevProps.currentFilters
        // Define current and previous machine (for fetching hierarchy machines)
        if (machineSelect) {
            currentM = !match.url.includes('machine')
                ? match.params.machineId
                : match.params.mId;
            prevM = !match.url.includes('machine')
                ? prevProps.match.params.machineId
                : prevProps.match.params.mId;
        } else {
            currentM = match.params.machineId;
            prevM = prevProps.match.params.machineId;
        }

        // If machine (in hierarchy) is different, fetchMachines is called
        if ((currentM && currentM !== prevM) || (!currentM && prevM)) {
            this.callFetchMachines();
        }
        // If layout settings has been changed, reset index and page number and call fetchMachines
        else if ((prevProps.displaySettings.pageSize !== displaySettings.pageSize)
            || (prevProps.displaySettings.pageNumber !== displaySettings.pageNumber)
            || (prevProps.displaySettings.layoutType !== displaySettings.layoutType)
            || (prevProps.displaySettings.useDefaultSearch !== displaySettings.useDefaultSearch)
        ) {
            if (displaySettings.layoutType === 'treelayout') {
                this.props.fetchHierarchyMachines();
            } else if (displaySettings.useDefaultSearch) {
                this.props.machineDefaultSearch({
                    pageNumber: 0,
                    pageSize: displaySettings.pageSize
                });
            }
            else {
                this.callFetchMachines()
            }
        }

        if (prevProps.currentFilters !== this.props.currentFilters) {
            this.callFetchMachines()
        }

        // Setting up hierarchy breadcrumb
        if ((location && location.state && location.state.hierarchyItem)
            && (!prevProps.location.state || prevProps.location.state
                && prevProps.location.state.hierarchyItem
                && prevProps.location.state.hierarchyItem.id !== location.state.hierarchyItem.id)
        ) {
            this.props.fetchHierarchyBreadCrumb(location.state.hierarchyItem);
        }

        this.scrollBreadCrumbPath();
    }

    callFetchMachines = (hierarchySearch = '') => {
        const { displaySettings, match, machineSelect } = this.props;
        if (!machineSelect && displaySettings.layoutType !== 'treelayout') {
            this.props.fetchMachines();
        } else {
            const hierarchyLevel = machineSelect
                ? !match.url.includes('machine')
                    ? match.params.machineId
                    : match.params.mId
                : match.params.machineId;
            this.props.fetchHierarchyMachines(hierarchyLevel, hierarchySearch);
        }
    }

    setUseDefaultSearch = (value: boolean) => {
        this.props.setMachinesSettings({ useDefaultSearch: value });
        this.props.resetCurrentFilters();
    }

    handleClick = (id, model) => {
        const url = typeof model !== 'undefined' && '/machine/' + id;
        this.props.history.push(url, true);
    }

    handleInputChange = (e) => {
        const value = e.target.value;
        this.setState({
            deviceSearchValue: value
        });

        if (value.length >= 3 || value === '') {
            this.callFetchMachines(value);
        }
    }

    addMachine = () => {
        this.props.history.push('/machine/new/');
    }

    listMachines = (machines) => {
        this.setState({ currentMachines: machines })
    }

    // Scroll to last selection (end of the breadcrumb)
    scrollBreadCrumbPath = () => {
        const elem = document.getElementById('breadcrumb');
        if (elem) {
            elem.scrollLeft += 200;
        }
    }

    toggleSettingsMenuDialog = () => {
        this.setState(state => ({
            dialogOpen: !state.dialogOpen
        }));
    }

    getBreadcrumb = () => {
        let breadCrumbItems = [];
        if (this.props.location?.state?.hierarchyItem && this.props.hierarchyItems) {
            breadCrumbItems = sortHierarchyItems(this.props.hierarchyItems);
        }
        const lastItem = breadCrumbItems[breadCrumbItems.length - 1];
        
        return (
            <div id="breadcrumb" className="breadcrumb-container">
                <div>
                    <Link to={{ pathname: '/machines/', state: null }}>
                        <FontAwesomeIcon icon={faHome} size="lg" />
                    </Link>
                    {breadCrumbItems.length > 0
                        ? <span className="h-margins-15"><FontAwesomeIcon icon={faChevronRight} /></span>
                        : <></>
                    }
                </div>
                {breadCrumbItems.length > 1 && <div className="breadcrumb-middle-section">
                    {breadCrumbItems
                        .filter(item => breadCrumbItems.indexOf(item) < breadCrumbItems.length - 1)
                        .map(item => (
                            <div key={item.id}>
                                <Link to={{ pathname: '/machines/' + item.id, state: { hierarchyItem: item } }}>
                                    {item.name}
                                </Link>
                                <span className="h-margins-15"><FontAwesomeIcon icon={faChevronRight} /></span>
                            </div>
                        ))
                    }
                </div>}
                <div>
                    {lastItem && <Link className="bold"
                        to={{ pathname: '/machines/' + lastItem.id, state: { hierarchyItem: lastItem } }}>
                        {lastItem.name}
                    </Link>}
                </div>
            </div>
        )
    }

    getSearchBox = () => (
        <div className="search-filter-bar v-margins-15">
            <div className="placeholder-icon">
                <FontAwesomeIcon icon={faSearch} size="lg" />
            </div>
            <input
                placeholder={i18n.t('SEARCH')}
                className="input-field"
                type="text"
                value={this.state.deviceSearchValue}
                onChange={this.handleInputChange}
            />
        </div>
    )

    listLayoutProps = () => ({
        pageNumber: this.props.displaySettings.pageNumber,
        pageSize: this.props.displaySettings.pageSize,
        displayInfo: this.props.machinesDisplayInfo,
        status: this.props.status,
        setPageNumber: this.props.setPageNumber
    })

    getTableLayout = () => (
        <ListLayout {...this.listLayoutProps()}>
            <MachineTable
                tableSettings={this.props.viewSettings}
                machines={this.props.machines}
            />
        </ListLayout>
    )
    
    getCardLayout = () => (
        <ListLayout {...this.listLayoutProps()}>
            <Row className="machines-cardlayout">
                {this.props.machines.map(machine => (
                    <MachineCardComponent
                        key={machine.id}
                        machine={machine}
                        viewSettings={this.props.viewSettings}
                    />
                ))}
            </Row>
        </ListLayout>
    )

    getTreeLayout = () => (
        <React.Fragment>
            {!this.props.machineSelect && this.getBreadcrumb()}
            {this.getSearchBox()}
            <MachineTree
                machines={this.props.hierarchyMachines}
                machineSelect={this.props.machineSelect}
                onMachineSelect={this.props.onMachineSelect}
                itemStatus={this.props.itemStatus}
                params={this.props.match?.params}
                referrer={this.props.referrer}
                status={this.props.status}
            />
        </React.Fragment>
    )

    getContent = () => {
        if (this.props.displaySettings.layoutType === 'treelayout' || this.props.machineSelect) {
            return this.getTreeLayout();
        }
        if (this.props.displaySettings.layoutType === 'tablelayout') {
            return this.getTableLayout();
        }
        if (this.props.displaySettings.layoutType === 'cardlayout') {
            return this.getCardLayout();
        }
        return (<></>);
    }

    render() {
        const {
            location,
            history,
            machineSelect,
            currentFilters
        } = this.props;

        const { dialogOpen } = this.state;

        const sceneData = {
            view: moduleSettings.name,
            title: null,
            filtersCount: countActiveMachineFilters(currentFilters),
            location: location,
            history: history,
            currentFilters: currentFilters,
            noHierarchySelect: true,
            dialogOpen: dialogOpen,
            closeDialog: () => this.setState({ dialogOpen: false })
        }

        return (
            <div id="machines" className="machines-container">
                {!machineSelect && <NavigationBar
                    fetchData={'fetchMachines'}
                    currentView={sceneData}
                    quickSearchOptions={[]}
                    filters={false}
                    viewAction={"settings"}
                    popover={!machineSelect && <MachinesSettingsMenu
                        closeDialog={() => this.setState({ dialogOpen: false })}
                        dialogOpen={dialogOpen}
                        toggleDialog={this.toggleSettingsMenuDialog}
                    />}
                />}
                <Container className="bottom-margin-100" fluid>
                    <div id="machines-panel-group" className="novi-panel">
                        {this.getContent()}
                    </div>
                </Container>
            </div>
        )
    }
}

export default Machines;
