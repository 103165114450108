import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import '../../../styles/global.scss';
import NavigationBar from '../../navigation';
import 'react-toastify/dist/ReactToastify.css';
import noviAPI from '../../../api/noviAPI';
import TextTruncate from 'react-text-truncate';
import { HandleError } from '../../../components/HelperFunctions';
import { Flex, Box } from 'reflexbox'

const getValue = obj => {

    let valueKey;
    let value;

    if (obj.measurementQuestion.type == "INTEGER")
        valueKey = "intValue";
    else if (obj.measurementQuestion.type == "BOOLEAN")  {
        value = ( <input type="checkbox" checked={obj.booleanValue} />);
    }
    else if (obj.measurementQuestion.type == "LABEL")  {
        value = obj.measurementQuestion.caption;
    }
    else
        valueKey = obj.measurementQuestion.type.toLowerCase() + "Value";

    if (valueKey)
        value = obj[valueKey];

    return value;
}


const MeasurementView = (props) => {

        const {
            location,
            history,
        } = props;

        const [measurementValues, setMeasurementValues] = useState([]);
        const [columnData, setColumnData] = useState(null);
        const [rowAmount, setRowAmount] = useState(0);

        useEffect(() => {
            if (measurementValues.length == 0) {
                noviAPI.measurementValues.fetchAllByGroupAndWorkCard(measurementId, workCardId)
                    .then(response => {
                        setMeasurementValues(response.data);
                    })
                    .catch(error => {
                        console.log("[Error]:", error);
                        HandleError(error, "Fetch measurement values");
                    })
            }
            else if (!columnData) {

                // Divide the measurementValues into column arrays
                let columns = [
                    [], [], [], [], [], [], [], []
                ];

                // Sort the values into correct row order (ascending)
                measurementValues.sort((a, b) => {
                    // Calculate and compare row indices
                    const rowA = a.measurementQuestion.rowNumber % 100;
                    const rowB = b.measurementQuestion.rowNumber % 100;
                    return rowA - rowB;
                });

                // Push all the values into their corresponding column arrays
                measurementValues.forEach(measValue => {

                     const val = measValue.measurementQuestion.rowNumber;

                     // rowNumber value equals (columnIndex * 100) + rowIndex
                     // We can parse the columnIndex by subtracting the rowIndex and dividing the rest with 100
                     // F.e 604 => 604 - 4 = 600 => 600 / 100 => columnIndex = 6
                     const column = (val - (val % 100)) / 100;

                     columns[column].push(measValue);
                });

                // Filter empty columns
                columns = columns.filter(arr => arr.length > 0);
                setColumnData(columns);

                // Calculate and set the amount of rows needed to display the measurementValues
                const _rowAmount = columns.reduce((max, curr) => curr.length > max ? curr.length : max, 0);
                setRowAmount(_rowAmount);
            }

        });

        let { machineId, measurementId, workCardId } = (location.state ?? {});
        
        if (!Number.isInteger(machineId))
            machineId = props.match.params.machineId;
        if (!Number.isInteger(measurementId))
            measurementId = props.match.params.measurementId;
        if (!Number.isInteger(workCardId))
            workCardId = props.match.params.workCardId;


        const measurement = useSelector((state : any) => {
            return state.machines.measurements.find(meas => {
                return meas.workCardId == workCardId && meas.measurementGroupId == measurementId;
            })
        });

        const sceneData = {
            view: "measurement",
            title: measurement ? measurement.measurementGroup.name : "",
            thumbnail: null,
            location: location,
            history: history,
            backAction: { action: history.goBack }
        }

        return (
            <div>

                <NavigationBar
                    currentView={sceneData}
                    viewAction={null}
                />

                <Container style={{ marginBottom: 70 }} className="margin-top-15">

                    <div className="machine-details">

                        {columnData != null && (new Array(rowAmount).fill(null)).map((_, index) => (

                            <Flex flexWrap="wrap">

                                {columnData.map((column) => {

                                    const measurement = column?.[index];

                                    return (
                                        !measurement ? null : (
                                            <>
                                                <Box
                                                    width={[1/2, 1/4, 1/8]}
                                                    p={1}
                                                    Reflex
                                                >
                                                        {measurement.measurementQuestion.type !== "LABEL" && <div className="sub-header">
                                                            <TextTruncate
                                                                line={2}
                                                                truncateText="..."
                                                                text={measurement.measurementQuestion.caption}
                                                            />
                                                        </div>}
                                                        <div className="white word-break">
                                                            {getValue(measurement)}
                                                        </div>
                                                </Box>
                                            </>
                                        )
                                    );
                                })}
                            </Flex>
                        ))}
                    </div>
                </Container>
            </div>
        );

};


export default MeasurementView;