import React from 'react';
import { Button, Card } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode, faBars, faCheck, faQrcode, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ComponentTypes } from '../../../constants/dashboard/componentTypes';
import { Box } from 'reflexbox';

const DashboardElement = ({ id = null, type = null, content = null, toggleActions, onDragStart, onDragOver, isDraggable }) => {
    return (
        <Box width={[1, 1 / 2]} p={2}
            data-id={id}
            data-component-type={type}
            onDragOver={onDragOver}
        >{ /* TODO: convert types to be own components: <DashboardComponent/> */}
            {type === ComponentTypes.Link || type === ComponentTypes.SmallLink
                ? <div
                    id={id}
                    draggable={isDraggable}
                    onDragStart={onDragStart}
                    onDragOver={onDragOver}
                    className={(isDraggable ? 'drop-area-highlight' : '')}
                >
                    <Card className="dashboard-panel narrow-container">
                        <div id={'menu' + id} className="component-tools-button" onClick={() => toggleActions(id)}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                        <div className="info-section">
                            <div className="info-title">
                                {content.label ? i18n.t(content.label) : content.itemName ? content.itemName : i18n.t('ITEM')}
                            </div>
                            <div className="info-details">
                                <p>{id}</p>
                                {i18n.t('PCS').toLowerCase()}
                            </div>
                        </div>
                    </Card>
                </div>
                : type === ComponentTypes.Button
                    ? < div
                        id={id}
                        draggable={isDraggable}
                        onDragStart={onDragStart}
                        onDragOver={onDragOver}
                        className={(isDraggable ? 'drop-area-highlight' : '')}
                    >
                        <Card className="dashboard-button-panel">
                            <div id={'menu' + id} className="component-tools-button" onClick={() => toggleActions(id)}>
                                <FontAwesomeIcon icon={faBars} />
                            </div>
                            <div className="dashboard-button-container">
                                <Button className="action input-row-button novi-default-btn-color">
                                    <div className="flex-center">
                                        <span className="button-label">{content.itemName ? content.itemName : content.label ? i18n.t(content.label) : i18n.t('ITEM')}</span>
                                        <FontAwesomeIcon icon={faQrcode} size="2x" />
                                    </div>
                                </Button>
                            </div>
                        </Card>
                    </div>
                    : type === ComponentTypes.Search
                        ? < div
                            id={id}
                            draggable={isDraggable}
                            onDragStart={onDragStart}
                            onDragOver={onDragOver}
                            className={(isDraggable ? 'drop-area-highlight' : '')}
                        >
                            <Card className="dashboard-button-panel">
                                <div id={'menu' + id} className="component-tools-button" onClick={() => toggleActions(id)}>
                                    <FontAwesomeIcon icon={faBars} />
                                </div>
                                <div className="dashboard-button-container">
                                    <Button className="action input-row-button novi-default-btn-color">
                                        <div className="flex-center">
                                            <span className="button-label">{content.itemName ? content.itemName : content.label ? i18n.t(content.label) : i18n.t('ITEM')}</span>
                                            <FontAwesomeIcon icon={faSearch} size="2x" />
                                        </div>
                                    </Button>
                                </div>
                            </Card>
                        </div>
                            : <></>
            }
        </Box>    
    )
}

export default DashboardElement;
