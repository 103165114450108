import Maintenances from './Maintenances';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Props } from './Maintenances';
import { fetchMaintenanceItems } from './actions';

type MapState = (state: State, ownProps: Props) => {
    maintenances: IMaintenanceState[],
    status: string,
    error: any,
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMaintenanceItems
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Maintenances);