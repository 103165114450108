import noviAPI from "../../../api/noviAPI";
import { DEFAULT_QUICK_SEARCH_TYPES } from "../../../constants/quickSearches";
import moment from 'moment';
import { SortTypes } from "constants/quickSearches/SortTypes";
import { initInputDateTime, parsePayloadDateTime } from "utils";

/**
 * Returns default quick search parameters
 * 
 * @param quickSearchId
 * @param settings
 * @param workCardSettings
 * @param workListSettings
 * @param countOnly
 */
export const defineQuickSearchParams = (quickSearchId: number, settings: ISettingsState, workCardSettings: IWorkcardsSettings, workListSettings: IWorkListDisplaySettings = null, countOnly = false, sortType) => {
    const { machineGroupId, noviConfigs, userId, userAdNameDetails, userDetails, workerGroups } = settings;
    const workerId = userAdNameDetails?.id ? userAdNameDetails.id : userId;
    const { WorkStatusNoticed } = noviConfigs;
    const personGroupIds = userAdNameDetails?.id ? userAdNameDetails.personGroupIds : userDetails.personGroupIds;

    // Define the search params
    let searchParams = new URLSearchParams();

    if (workListSettings !== null) {
        searchParams.append('PageNumber', `${workListSettings.pageNumber - 1}`);
        searchParams.append('PageSize', `${workListSettings.pageSize}`);
    }

    if (countOnly) {
        searchParams.append('CountOnly', 'True');
    }

    const includeOwn = () => searchParams.append('WorkerIds', `${workerId}`);
    const includeDelayed = () => searchParams.append('IsDelayed', 'true');
    const includeUnassigned = () => searchParams.append('NoWorkers', 'true');
    const includeOwnWorkerGroups = () => personGroupIds
        .filter(pG => (workerGroups.find(wG => wG.id === pG)))
        .forEach(i => searchParams.append('WorkerGroupIds', `${i}`));

    const appendWorkStatus = (value) => searchParams.append('WorkStatuses', `${value}`);
    const appendEndDate = (value) => searchParams.append('EndDate', `${value}`);

    const includeOpen = () => workCardSettings.reportedWorkStatuses.forEach(i => appendWorkStatus(i));
    const includeStarted = () => workCardSettings.reportedWorkStatuses
        .filter(i => i !== Number(WorkStatusNoticed))
        .forEach(i => appendWorkStatus(i));

    const includeUntilNextDay = () => {
        const date = parsePayloadDateTime(moment(), date => date.add(1, 'days'));
        appendEndDate(date);
    }
    const includeUntilNextWeek = () => {
        const date = parsePayloadDateTime(moment(), date => date.add(7, 'days'));
        appendEndDate(date);
    }

    // Add machine group id to search params
    searchParams.append('MachineGroupId', `${machineGroupId}`);

    const q = DEFAULT_QUICK_SEARCH_TYPES;
    // Add search params based on quickSearchId
    if (quickSearchId === q.OPEN) {
        includeOpen();
        includeUntilNextDay();
    }
    else if (quickSearchId === q.OWN_OPEN) {
        includeOwn();
        includeOpen();
        includeUntilNextDay();
    }
    else if (quickSearchId === q.STARTED) {
        includeStarted();
    }
    else if (quickSearchId === q.OWN_STARTED) {
        includeOwn();
        includeStarted();
    }
    else if (quickSearchId === q.DELAYED) {
        includeDelayed();
    }
    else if (quickSearchId === q.OWN_DELAYED) {
        includeOwn();
        includeDelayed();
    }
    else if (quickSearchId === q.UNASSIGNED) {
        includeUnassigned();
        includeUntilNextWeek();
    }
    else if (quickSearchId === q.OWN_WORKERGROUPS_OPEN) {
        includeOpen();
        includeOwnWorkerGroups();
    }

    addSortingParams(searchParams, sortType);

    return searchParams;
}

export const getColors = async () => {
    const response = await noviAPI.colorSettings.fetchColors();
    return response.data;
}

export const getColorSettings = async () => {
    const response = await noviAPI.colorSettings.fetchSettings();
    return response.data;
}

export const getColorConfiguration = async (colorSetting: IColorSettingLite) => {
    const response = await noviAPI.colorSettings.fetchConfigs(colorSetting.id);
    return response.data;
}

/**
 * Returns true if the amount of planned materials is greater than the amount of materials taken
 * @param materials
 */
export const plannedMaterialsNotUsed = (materials: IMaterial[]) => {
    let plannedMaterialsNotUsed = false;
    materials.forEach(i => {
        if (i.amount > 0 && i.amount > i.takeAmount) {
            plannedMaterialsNotUsed = true;
        }
    });
    return plannedMaterialsNotUsed;
}

/**
 * Returns the date that is calculated by adding @param dateDiff to the current date.
 * For example -7 returns the date from a week ago
 * @param dateDiff
 */
export const dateDiffToDate = (dateDiff: number): string => {
    return initInputDateTime(date => date.add(dateDiff, 'days'));
}

export const addSortingParams = (searchParams: URLSearchParams, sortType) => {

    const orderSettingId = sortType?.id ?? -1;
    const types = SortTypes.worklist;

    // Ordering parameters
    switch (orderSettingId) {
        
        case types.NEWEST.id:
            searchParams.append('OrderDescending', 'true');
            searchParams.append('OrderBy', "OrderDate");
            break;

        case types.OLDEST.id:
            searchParams.append('OrderDescending', 'false');
            searchParams.append('OrderBy', "OrderDate");
            break;

        case types.FAULT_NEWEST.id:
            searchParams.append('OrderDescending', 'true');
            searchParams.append('OrderBy', "FaultBegin");
            break;

        case types.FAULT_OLDEST.id:
            searchParams.append('OrderDescending', 'false');
            searchParams.append('OrderBy', "FaultBegin");
            break;

        case types.WORKBEGIN_NEWEST.id:
            searchParams.append('OrderDescending', 'true');
            searchParams.append('OrderBy', "WorkCanBegin");
            break;

        case types.WORKBEGIN_OLDEST.id:
            searchParams.append('OrderDescending', 'false');
            searchParams.append('OrderBy', "WorkCanBegin");
            break;

        default:
            searchParams.append('OrderDescending', 'true');
            searchParams.append('OrderBy', "OrderDate");
            break;
    }
}

/**
 * Returns number of days between current time and given date
 * @param dateString
 */
export const dateStringToDateDiff = (dateString: string): number => {
    const date = moment(dateString);
    const dateDiffPrecise = date.diff(moment(), 'days', true);
    const dateDiff = Math.round(dateDiffPrecise);
    return dateDiff;
}

const emptyWorkListSearchParams: IWorkListSearchParams = {
    searchString: '',
    startDate: '',
    endDate: '',
    daysToStartDate: null,
    daysToEndDate: null,
    detailIds: [],
    workCardCode: '',
    machineCode: '',
    ordererIds: [],
    workerIds: [],
    workerGroupIds: [],
    workStatuses: [],
    workCardTypes: [],
    machineIds: [],
    hierarchyItemIds: [],
    urgencies: [],
    machineHalt: null,
    extraDatas: {},
}

export const filtersToSearchParams = (filter: IWorkListFilters): IWorkListSearchParams => {
    const { startDate, endDate, detailIds, ordererIds, workerIds, workerGroupIds, workStatuses, workCardTypes, urgencies, machineHalt, ...rest} = filter;
    const searchParams = {
        ...emptyWorkListSearchParams,
        ...rest,
        daysToStartDate: startDate ? dateStringToDateDiff(startDate) : null,
        daysToEndDate: endDate ? dateStringToDateDiff(endDate) : null,
        detailIds: detailIds.map(i => Number.parseInt(i.value)),
        ordererIds: ordererIds.map(i => i.value),
        workerIds: workerIds.map(i => i.value),
        workerGroupIds: workerGroupIds.map(i => i.value),
        workStatuses: workStatuses.map(i => i.value),
        workCardTypes: workCardTypes.map(i => i.value),
        urgencies: urgencies.map(i => i.value),
        machineHalt: machineHalt ? (machineHalt.value.toLowerCase() === 'true') : null,
    }
    return searchParams;
}

/**
 * Compares two arrays. Returns true if length and values match. Use strict if order of values matters.
 * @param arr1 
 * @param arr2 
 * @param strict 
 */
export const compareArrays = (arr1, arr2, strict = false) => {
    let array1 = arr1;
    let array2 = arr2;
    if (!strict) {
        array1 = [...arr1].sort();
        array2 = [...arr2].sort();
    }
    return array1.length === array2.length && array1.every((el, i) => el === array2[i])

}