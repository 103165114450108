import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setScanResult, closeScanner, openScanner, clearScanResult } from './actions';
import BarcodeReaderContainer from './BarcodeReaderContainer';
import QrReaderContainer from './QrReaderContainer';

interface IProps {
    eventKey?: number | string;
    type: string;
    buttonLabel?: string;
    customClass?: string;
    openScannerWrapper?: (fn: () => void) => void;
}

const ScannerContainer = ({ eventKey = null, type, buttonLabel, customClass, openScannerWrapper }: IProps) => {
    const dispatch = useDispatch();
    const ref = useRef(null);
    /*
     * ScannerContainer clears the scan result when the component unmounts but you may want 
     * to clear the result by dispatching clearScanResult inside the parent component of 
     * ScannerContainer after parent is done handling the result. E.g. if the barcode reader 
     * is used to read multiple barcodes you may want to clear the result between each read.
     * */
    useEffect(() => {
        return function clearResult() {
            dispatch(clearScanResult(type));
            dispatch(closeScanner(type));
        }
    }, [dispatch, type]);

    const handleClickOutside = useCallback(e => {
        if (ref.current && !ref.current.contains(e.target)) {
            dispatch(closeScanner(type));
        }
    }, [dispatch, type]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, [handleClickOutside]);

    const handleClick = useCallback((e) => {
        e.preventDefault();
        if (openScannerWrapper !== undefined) {
            openScannerWrapper(() => dispatch(openScanner(type, eventKey)))
        } else {
            dispatch(openScanner(type));
        }
    }, [dispatch, openScannerWrapper, type, eventKey]);

    const handleScan = useCallback(scanResult => {
        if (scanResult) {
            dispatch(setScanResult(scanResult, type));
            dispatch(closeScanner(type));
        }
    }, [dispatch, type]);

    const getScannerByType = useCallback((type) => {
        const components = {
            barcode: <BarcodeReaderContainer
                ref={ref}
                handleClick={handleClick}
                handleScan={handleScan}
            />,
            QR: <QrReaderContainer
                ref={ref}
                eventKey={eventKey}
                handleClick={handleClick}
                handleScan={handleScan}
                buttonLabel={buttonLabel}
                customClass={customClass}
            />
        }
        return components[type] || <></>;
    }, [ref, eventKey, handleClick, handleScan, buttonLabel, customClass]);

    return getScannerByType(type);
}

export default ScannerContainer;