import { isArray } from "util";
import React from 'react';
import TextTruncate from 'react-text-truncate';

const FormList = ({ list, listItemName = null, listSeparator = null, listingStyle = null }) => {
    if (!list) list = '';

    const separator = listSeparator || ', ';
    list = isArray(list) ? list : [list];
    
    if (listingStyle === 'listed') {
        return (<ul className="ul-list">
            {list.map((labelVal, i) =>
                <li key={i}>
                    <TextTruncate
                        line={3}
                        truncateText="..."
                        text={labelVal.name}
                    />
                </li>
            )}
        </ul>)
    } else {
        return list.map(item => listItemName ? item[listItemName] : item).join(separator);
    }
}

export default FormList;