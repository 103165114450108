import React, { useReducer, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import '../../styles/global.scss';
import NavigationBar from '../navigation';
import { ScrollToTop } from '../../components/ScrollToTop';
import i18n from '../../translations/i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmDialogComponent from '../../components/ConfirmDialogComponent';
import { getCostPoolTranslationKey } from '../../components/HelperFunctions';
import moment from 'moment';
import settingsAPI from '../../config/settingsAPI';
import { actionIcons } from '../../constants/icons';
import { useSelector } from 'react-redux';
import Form from './components/Form';
import MaterialsList from './components/MaterialsList';
import { isOrderable } from './utils';
import { PropsFromRedux } from '.';
import { RouteChildrenProps } from 'react-router-dom';
import { PurchaseOrderOriginEnums } from '../../constants/purchaseOrders';
import { parsePayloadDateTime } from 'utils';
import { Loader } from 'components/Loader';

type MatchParams = { workCardId: string; }
type LocationState = { noPreviousPage: boolean; scrollPosition: number; }
type Props = PropsFromRedux & RouteChildrenProps<MatchParams, LocationState>;

const Purchases = (props: Props) => {

    const { location, history, match } = props;
    const moduleSettings = settingsAPI.moduleData.purchases;

    const wcId = Number(match.params.workCardId);

    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const warehouse = useSelector((state : State) => state.warehouse?.currentWarehouse);
    const materials = useSelector((state : State) => state?.workcards?.materials?.filter(isOrderable)) ?? [];
    const viewSettings = useSelector((state : State) => state.workcards.viewSettings.workcardmaterialorder);
    const workCard = useSelector((state : State) => state.workcards.workcards?.find(wc => wc.id == wcId));
    const costpools = useSelector((state: State) => state.costpools);

    useEffect(() => {
        props.fetchWorkCard(wcId);
        props.fetchWorkCardMaterials(wcId);
        props.fetchWarehouses();
        props.fetchCostPools();
        props.fetchViewSettings(["WorkCardMaterialOrderGrid"], "FETCH_MATERIAL_ORDER_GRID");

        reset(true);
    }, []);

    const costpoolIds = workCard ? viewSettings.filter(v => v.type == "costpoolgroup").reduce((ids, c) => {
        const tKey = getCostPoolTranslationKey(workCard['costPools'], c);
        const id = costpools.costPools.results.find(cp => cp.translationKey == tKey)?.id;
        return id ? ids.concat(id) : ids;
    }, []) : [];

    const inputFields = [
        { key: "warehouse", label: "WAREHOUSE", type: "select" }, 
        { key: "supplier", label: "SUPPLIER", type: "select" }, 
        { key: "seperateorders", label: "MULTIPLE_PURCHASE_ORDERS", type: "singleline-checkbox" }
    ];

    const initialOrderData = {
        warehouse: (warehouse?.id && warehouse.id != -1) ? warehouse : { id: -1, label: "NO_SELECTION" },
        supplier: { id: -1, label: "NO_SELECTION" },
        seperateorders: false,
        comments: {}
    };

    const [orderData, updateOrderData] = useReducer((data, newData) => {
        return Object.assign({}, data, newData);
    }, initialOrderData);


    const materialReducer = mode => materials.reduce((a, m) => Object.assign({}, a, { [m.id]: isOrderable(m) ? mode : false }), {});

    const [selectedMaterials, toggleMaterial] = useReducer((prev, selectedMaterial) => {
        
        if (Object.keys(selectedMaterial)[0] === "all") {
            return materialReducer(selectedMaterial.all);
        }
        return Object.assign({}, prev, selectedMaterial);

    }, materialReducer(false));

    const [prevToggle, toggleAllMaterials] = useReducer((prev, newToggle) => {
        toggleMaterial({ all: newToggle });
        return newToggle === true || newToggle === false ? newToggle : !prev;
    }, false);


    const [showDialog, toggleDialog] = useReducer(prev => !prev, false);
    const [loading, toggleLoader] = useState(false);

    const reset = (fetchItems = false) => {

        updateOrderData(initialOrderData);
        toggleAllMaterials(false);
        toggleLoader(false);
        
        if (fetchItems) {
            const params = new URLSearchParams();
            materials.forEach(mat => {
                if (mat.purchaseOrderItem) {
                    params.append("ids", `${mat.purchaseOrderItem.id}`);
                }
            });

            if (params.getAll("ids").length > 0) {
                props.fetchPurchaseOrderItems(params);
            }
        }
    }


    const addPurchaseOrder = async () => {

        toggleLoader(true);
        
        const orderParams = {
            purchaseOrderWarehouseId: orderData.warehouse.id,
            ordererId: props.settings.userDetails.id,
            statusId: "1",
            orderDate: parsePayloadDateTime(moment()),
            supplierId: orderData.supplier.id,
            purchaseOrderOriginId: PurchaseOrderOriginEnums.WorkCard,
            originDocumentId: wcId
        };

        if (orderData.seperateorders) {

            const sparepartIds = Object.entries(selectedMaterials).reduce((ids, [id, value]) => {
                if (value !== false) {
                    return ids.concat(id);
                }
                else
                    return ids;
            }, []);

            const addRequests = sparepartIds.map(async (sPId) => {

                const sp = materials.find(x => x.id == sPId);

                return new Promise(function(resolve, reject) {

                    props.addPurchaseOrder(machineGroupId, orderParams, async orderId => {
                        const searchParams = {
                            Amount: sp.amount,
                            sparePartId: null,
                            WorkCardSparePartId: sp.id,
                            UnitPrice: sp.price,
                            Type: sp.type,
                            WorkCardId: sp.workCard.id,
                            Comment: orderData.comments[sPId],
                            Unit: sp.unit ?? "",
                            NetPrice: `${(sp.price ?? 0) * (sp.amount ?? 0)}`,
                            PurchaseOrderId: orderId,
                            CostPoolIds: costpoolIds
                        };
                        await props.addPurchaseOrderItem(searchParams);
                        resolve("Added item");
                    });
                });

            });

            await Promise.allSettled(addRequests);
            reset();
            redirectBackToWc();

        } else {
            props.addPurchaseOrder(machineGroupId, orderParams, (orderId) => addPurchaseOrderItems(orderId));
        }

    };

    const addPurchaseOrderItems = async (orderId) => {

        const sparepartIds = Object.entries(selectedMaterials).reduce((ids, [id, value]) => {
            if (value !== false) {
                return ids.concat(id);
            }
            else
                return ids;
        }, []);

        // Add PurchaseOrderItem for each sparePart
        const addRequests = sparepartIds.map(async (sPId) => {
            return new Promise(async (resolve, reject) => {
                const sp = materials.find(x => {
                    return x.id == sPId;
                });

                const searchParams = {
                    Amount: sp.amount,
                    sparePartId: null,
                    WorkCardSparePartId: sp.id,
                    UnitPrice: sp.price,
                    Type: sp.type,
                    WorkCardId: sp.workCard.id,
                    Comment: orderData.comments[sPId],
                    Unit: sp.unit ?? "",
                    NetPrice: `${(sp.price ?? 0) * (sp.amount ?? 0)}`,
                    PurchaseOrderId: orderId,
                    CostPoolIds: costpoolIds
                };

                await props.addPurchaseOrderItem(searchParams)
                resolve("Added item");
            })

        });

        await Promise.allSettled(addRequests)
        reset();
        redirectBackToWc();

    };

    const redirectBackToWc = (msg? : string) => {
        props.history.push("/workcard/" + wcId, {
            notificationMsg: i18n.t(msg ?? "MATERIAL_SAVED")
        })
    }

    const validateData = () => {
        return new Promise((resolve, reject) => {
            if (orderData.warehouse.id === -1) {
                reject("CHOOSE_WAREHOUSE");
            }
            if (orderData.supplier.id === -1) {
                reject("CHOOSE_SUPPLIER");
            }
            if (Object.values(selectedMaterials).every(x => x == false)) {
                reject("AT_LEAST_ONE_SPARE_PART_MUST_BE_SELECTED");
            }
            else {
                resolve("Validated.");
            }
        });
    }

    const viewAction = 'settings';

    let popoverData = {
        popoverClass: 'menu-popover',
        popoverActions: [
            {
                icon: actionIcons.SAVE,
                label: i18n.t('CREATE_PURCHASE_ORDER'),
                clickFn: toggleDialog,
                isActionFn: true,
                paClass: 'start-phase'
            }
        ]
    };

    const sceneData = {
        view: moduleSettings.name,
        title: i18n.t("MATERIAL_FOR_PURCHASE_ORDER"),
        location: location,
        history: history,
        itemColors: [],
        backAction: location.state == undefined || location.state?.noPreviousPage === true
            ? {
                action: history.goBack,
                params: {
                    path: '/worklist',
                    state: { heightPos: location.state?.scrollPosition ?? 0 }
                }
            }
            : { action: history.goBack }
    };

    return (
        <div>
            <ScrollToTop />
            {showDialog && <ConfirmDialogComponent
                dialogContent={{
                    title: i18n.t("CONFIRM"),
                    body: i18n.t("CREATE_PURCHASE_ORDER")
                }}
                callBack={() => {
                    toggleDialog();
                    validateData()
                        .then(addPurchaseOrder)
                        .catch(errorMsg => {
                            toast.error(i18n.t(errorMsg), { hideProgressBar: true, position: "top-center" });
                        });
                }}
                cancelDialog={toggleDialog}
            />}
            <NavigationBar
                currentView={sceneData}
                popoverData={popoverData}
                viewAction={viewAction}
            />
            <div>
                <Container>
                    <Loader ready={!loading} loadingText={i18n.t('SAVING')} />
                    <Form
                        inputFields={inputFields}
                        formData={orderData}
                        onChange={updateOrderData}
                        selectAll={{ val: prevToggle, fn: toggleAllMaterials }}
                    />
                    <MaterialsList 
                        materials={materials}
                        toggleMaterial={toggleMaterial}
                        selectedMaterials={selectedMaterials}
                        orderData={orderData}
                        viewSettings={viewSettings}
                        workCard={workCard}
                    />
                </Container>
            </div>
        </div>
    );
}

export default Purchases;