export const DEFAULT_QUICK_SEARCH_TYPES = {
    // open
    OPEN: 1,
    // own open
    OWN_OPEN: 2,
    // started
    STARTED: 8,
    // own started
    OWN_STARTED: 3,
    // delayed
    DELAYED: 6,
    // own delayed
    OWN_DELAYED: 4,
    // unassigned
    UNASSIGNED: 7,
    // own workergroups open 
    OWN_WORKERGROUPS_OPEN: 10
} as const;