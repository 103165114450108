import * as types from '../config/actionTypes';

export const getEmptyMachineFilters = (): IMachineFilters => ({
    searchString: '',
    code: '',
    machineType: [],
    model: '',
    productNumber: '',
    yearOfManufacture: '',
    deliveryNumber: '',
    machineRequirements: [],
    extraLocation: '',
    weight: '',
    dimensions: '',
    url: '',
    memo: '',
    thirdParties: {},
    details: {},
    costPools: {},
    extraData: {}
})

const initialState: IMachineState = {
    machines: [],
    hierarchyMachines: [],
    storedMachineHierarchies: {},
    storedMachines: [],
    levels: [],
    status: '',
    machineStatus: '',
    error: null,
    fetchBreadcrumbStatus: 'fulfilled',
    hierarchyItems: [],
    machineExtraData: [],
    workCardsByMachine: [],
    workCardsByChildMachines: [],
    machineSpareParts: [],
    machineSparePartLinks: [],
    documents: [],
    viewSettings: { machine: [] },
    currentFilters: getEmptyMachineFilters(),
    settings: {
        layoutType: 'treelayout',
        pageNumber: 1,
        pageSize: 5,
        useDefaultSearch: false
    },
    machinesDisplayInfo: {
        totalPageCount: 0,
        totalResultCount: 0
    },
    options: {
        machineTypeOptions: [],
        thirdPartyOptions: [],
        machineRequirementOptions: [],
        ratingOptions: [],
        personOptions: []
    },
    machineDetails: {
        byGroup: {},
        allGroups: []
    },
    workHistoryCount: 0,
    measurements: [],
    counters: []
}

const machineReducer = (state = initialState, action): IMachineState => {
    let storedMachines = [];
    let machines = [];

    function pushToArray(arr, obj) {
        const index = arr.findIndex((e) => e.id === obj.id);

        if (index === -1) {
            arr.push(obj);
        } else {
            arr[index] = obj;
        }

        return arr;
    }

    switch (action.type) {
        case `${types.FETCH_MACHINE}_PENDING`:
            return {
                ...state,
                machineStatus: 'pending',
                error: null
            }
        case `${types.FETCH_MACHINE}_FULFILLED`:
            storedMachines = [...state.storedMachines] || [];
            machines = [...state.machines] || [];

            if (action.payload.machine) {
                machines = pushToArray(machines, action.payload.machine);
                storedMachines = pushToArray(storedMachines, action.payload.machine);
            }

            return {
                ...state,
                machines: machines,
                storedMachines: storedMachines,
                machineStatus: 'fulfilled'
            }
        case types.FETCH_MACHINE_LEVELS:
            return {
                ...state,
                levels: action.payload
            }
        case `${types.FETCH_MACHINES}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_MACHINES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machines: action.payload.results,
                machinesDisplayInfo: {
                    totalResultCount: action.payload.totalResultCount,
                    totalPageCount: action.payload.totalPageCount
                }
            }
        case `${types.FETCH_MACHINES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_HIERARCHY_MACHINES}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_HIERARCHY_MACHINES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                hierarchyMachines: action.payload.results,
                machinesDisplayInfo: {
                    totalResultCount: action.payload.totalResultCount,
                    totalPageCount: action.payload.totalPageCount
                }
            }
        case `${types.FETCH_HIERARCHY_MACHINES}_REJECTED`:
            return {
                ...state,
                status: 'fulfilled',
                error: action.payload
            }
        case types.DELETE_MACHINE:
            let childIds = [];
            const deletedMachine = state.machines.find(({ id }) => id === action.payload.id);
            const deletedStoredMachine = state.storedMachines.find(({ id }) => id === action.payload.id);
            const { childrenIds } = deletedMachine;
            const storedChildrenIds = deletedStoredMachine.childrenIds;

            const getChildIds = (childrenIds) => {
                if (childrenIds.length <= 0) { return }

                let newChildren = state.machines.filter(({ id }) => childrenIds.includes(id));

                newChildren.forEach(m => {
                    childIds.push(m.id);
                    getChildIds(m.childrenIds);
                })
            }

            getChildIds(childrenIds);
            const machinesDeleted = state.machines.filter(({ id }) => ![action.payload.id, ...childIds].includes(id));

            childIds = [];
            getChildIds(storedChildrenIds);
            const storedMachinesDeleted = state.storedMachines.filter(({ id }) => ![action.payload.id, ...childIds].includes(id));

            return {
                ...state,
                machines: machinesDeleted,
                storedMachines: storedMachinesDeleted
            }
        case types.ADD_MACHINE:
            const updatedParentMachine = state.machines.map(m => {
                if (m.id !== action.payload.parentId) return m;

                return { ...m, childrenIds: [...m.childrenIds, action.payload.id] }
            });

            const updatedStoredParentMachine = state.storedMachines.map(m => {
                if (m.id !== action.payload.parentId) return m;

                return { ...m, childrenIds: [...m.childrenIds, action.payload.id] }
            });

            return {
                ...state,
                machines: [
                    ...updatedParentMachine,
                    action.payload
                ],
                storedMachines: [
                    ...updatedStoredParentMachine,
                    action.payload
                ],
            }
        case types.UPDATE_MACHINE:
            const updatedItems = state.machines.map(m => {
                if (m.id === action.payload.parentId) {
                    return { ...m, childrenIds: [...m.childrenIds, action.payload.id] }
                }
                if (m.childrenIds && m.childrenIds.includes(action.payload.id)) {
                    return { ...m, childrenIds: m.childrenIds.filter(c => c !== action.payload.id) }
                }

                if (m.id === action.payload.id) {
                    return { ...m, ...action.payload }
                }
                return m;
            });

            const updatedStoredItems = state.storedMachines.map(m => {
                if (m.id === action.payload.parentId) {
                    return { ...m, childrenIds: [...m.childrenIds, action.payload.id] }
                }
                if (m.childrenIds && m.childrenIds.includes(action.payload.id)) {
                    return { ...m, childrenIds: m.childrenIds.filter(c => c !== action.payload.id) }
                }

                if (m.id === action.payload.id) {
                    return { ...m, ...action.payload }
                }
                return m;
            });

            return {
                ...state,
                machines: updatedItems,
                storedMachines: updatedStoredItems
            }
        case types.FETCH_MACHINE_OPTIONS:
            return {
                ...state,
                options: action.payload
            }
        case types.FETCH_MACHINE_DETAILS: {
            const allGroups = action.payload.reduce((arr, item) =>
                !arr.includes(item.group)
                    ? arr.concat(item.group)
                    : arr,
                []
            );
            const byGroup = allGroups.reduce((obj, group) =>
                Object.assign(
                    obj,
                    { [group]: action.payload.filter(detail => detail.group === group) }
                ),
                {}
            );
            return {
                ...state,
                machineDetails: {
                    byGroup,
                    allGroups
                }
            };
        }
        case `${types.FETCH_MACHINE_SPARE_PARTS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MACHINE_SPARE_PARTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machineSpareParts: action.payload
            }
        case `${types.UPDATE_MACHINE_SPARE_PART}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machineSpareParts: state.machineSpareParts.map(msp => {
                    if (msp.id === action.payload.id) {
                        return {
                            ...msp,
                            ...action.payload
                        };
                    }
                    return msp;
                })
            }
        case `${types.FETCH_MACHINE_SPARE_PARTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_MACHINE_SPARE_PART_LINKS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MACHINE_SPARE_PART_LINKS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machineSparePartLinks: action.payload
            }
        case `${types.FETCH_MACHINE_SPARE_PART_LINKS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_MACHINE_EXTRA_DATA}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MACHINE_EXTRA_DATA}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machineExtraData: action.payload
            }
        case `${types.FETCH_MACHINE_EXTRA_DATA}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_MACHINE_VIEW_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MACHINE_VIEW_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type[0]]: action.payload.viewSettings['machine'],
                }
            }
        case `${types.FETCH_MACHINE_VIEW_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_MACHINELIST_GRID_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MACHINELIST_GRID_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'pending',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type]: action.payload.gridSettings,
                }
            }
        case `${types.FETCH_MACHINELIST_GRID_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_HIERARCHY_BREADCRUMB}_PENDING`:
            return {
                ...state,
                fetchBreadcrumbStatus: 'pending'
            }
        case `${types.FETCH_HIERARCHY_BREADCRUMB}_FULFILLED`:
            return {
                ...state,
                fetchBreadcrumbStatus: 'fulfilled',
                hierarchyItems: action.payload
            }
        case `${types.FETCH_HIERARCHY_BREADCRUMB}_REJECTED`:
            return {
                ...state,
                fetchBreadcrumbStatus: 'error',
                error: action.payload
            }
        case types.FETCH_WORK_HISTORY:
            return {
                ...state,
                workHistoryCount: action.payload
            }
        case `${types.FETCH_WORK_HISTORY}_REJECTED`:
            return {
                ...state,
                workHistoryCount: initialState.workHistoryCount
            }
        case types.CLEAR_CACHE:
            return initialState;
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
            return {
                ...initialState,
                settings: {
                    // TODO: after pagination refactor set pageNumber to initial state
                    ...state.settings
                }
            }
        case types.STATUS_FULFILLED:
            return {
                ...state,
                status: 'fulfilled',
            }
        case types.RESET_MACHINE_CURRENTFILTERS:
            return {
                ...state,
                currentFilters: initialState.currentFilters
            }
        case types.FETCH_MACHINE_MEASUREMENTS:
            return {
                ...state,
                measurements: action.payload
            }
        case `${types.FETCH_MACHINE_WORKCARDS}_FULFILLED`:
            return {
                ...state,
                ...action.payload
            }
        case types.REMOVE_MACHINE_FROM_LIST:
            return {
                ...state,
                machines: state.machines.filter(i => i.id !== action.payload.id)
            }
        case types.ADD_M_EXTRA_DATA:
            return {
                ...state,
                machines: state.machines.map(m => {
                    if (m.id !== parseInt(action.payload.machineId, 10)) {
                        return m;
                    }

                    return {
                        ...m,
                        extraData: m.extraData.concat([action.payload.data])
                    }
                })
            }
        case types.UPDATE_M_EXTRA_DATA:
            const updatedMachs = state.machines.map(m => {
                if (m.id !== parseInt(action.payload.machineId, 10)) return m;

                const extraDatas = m.extraData.map(mExtraData => {
                    if (mExtraData.id !== action.payload.data.id) return mExtraData;

                    return {
                        ...mExtraData,
                        ...action.payload.data
                    }
                })

                return {
                    ...m,
                    extraData: extraDatas
                };
            });

            return {
                ...state,
                machines: updatedMachs
            }
        case 'SET_MACHINE_FILTERS':
            return {
                ...state,
                currentFilters: action.payload
            }
        case 'CLEAR_MACHINE_FILTERS':
            return {
                ...state,
                currentFilters: getEmptyMachineFilters()
            }
        case 'SET_MACHINES_SETTINGS':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload
                }
            }
        case types.FETCH_MACHINE_COUNTERS:
            return {
                ...state,
                counters: action.payload
            }
        default:
            return state;
    }
}

export default machineReducer;
