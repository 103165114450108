import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import translations from './translations';
//import customerTranslations from './customerTranslations';

//const getCombinedTranslationFiles = function () {
//    const result = {};

//    Object.keys(customerTranslations)
//        .forEach(key => {
//            const custTranslationList = customerTranslations[key].translation;
//            const defaultTranslationList = translations[key].translation;

//            Object.keys(custTranslationList).forEach(tKey => {
//                defaultTranslationList[tKey] = custTranslationList[tKey];
//            });

//            result[key] = { translation: defaultTranslationList };
//        });

//    return result;
//};

i18n
    .use(initReactI18next)
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        //lng: 'Fi',
        debug: false,
        resources: {}
    });

export default i18n;
