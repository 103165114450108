import React from 'react';
import i18n from '../translations/i18n';

const ListTruncate = (props: IProps) => {
    const truncateText = props.truncateText || `...${props.children.length - 2 + i18n.t('PCS').toLowerCase()}`

    if (props.children.length < 1) {
        return null;
    }
    else if (props.children.length > 1) {
        return (
            <>
                <div>
                    {props.children[0]}{props.children.length === 2 && ','}
                </div>
                {props.children.length > 2 && <div className='pcs-in-btwn'>
                    {truncateText}
                </div>}
                <div>
                    {props.children[props.children.length - 1]}
                </div>
            </>
        );
    }
    else {
        return (
            <div>
                {props.children[0]}
            </div>
        );
    }
}

interface IProps {
    children: React.ReactNode[];
    truncateText?: string;
}

export default ListTruncate;