import axios from 'axios';
import qs from 'qs';

const apiUrl = window['runConfig'].apiURL;
const authGwUrl = window['runConfig'].authGatewayURL;
const customClientId = window['runConfig'].clientId;
const baseUrl = typeof authGwUrl === 'undefined' ? apiUrl : authGwUrl;

const request = axios.create({
    baseURL: baseUrl.startsWith('http://') ? baseUrl : 'https://' + baseUrl
});

const clientId = customClientId ?? (typeof authGwUrl === 'undefined' ? "FrontEnd" : "novi-mobile");
const clientSecret = typeof window['runConfig'].clientSecret === 'undefined' ? 'secretFrontEndPassword' : window['runConfig'].clientSecret;

const authAPI = {
    userLogin(params) {
        const data = {
            client_secret: clientSecret,
            client_id: clientId,
            username: params.username,
            password: params.password,
            grant_type: 'password'
        };
        return request.post('/connect/token', qs.stringify(data));
    },
    refreshToken() {
        const data = {
            client_secret: clientSecret,
            client_id: clientId,
            refresh_token: authAPI.getRefreshToken(),
            grant_type: 'refresh_token'
        };

        if (data.refresh_token) {
            return request.post('/connect/token',
                qs.stringify(data),
                {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    timeout: 4000
                }
            );
        }
        
        return null;
    },
    setRefreshLoop() {
        const expiresIn = this.getExpiresIn();
        (function() {
            const delta = Math.min(600, Math.max(5, expiresIn - 5)) * 1000
            setInterval(function() {
                try {
                    if ( document.hidden ) {
                        return;
                    }
                    
                    if (authAPI.getRefreshToken()) {
                        (async () => {
                            try {
                                const { data } = await authAPI.refreshToken()
                                const { access_token, refresh_token } = data;
                                authAPI.setAccessToken(access_token);
                                authAPI.setRefreshToken(refresh_token);
                            } catch (error) {
                                console.error(error)
                            }
                        })()
                    }
                } catch (error) {
                    console.error(error)
                }
            }, delta);
        })();  
    },
    getAccessToken() {
        return window.localStorage.getItem('access_token' + apiUrl);
    },
    setAccessToken(accessToken) {
        window.localStorage.setItem('access_token' + apiUrl, accessToken);
    },
    removeAccessToken() {
        window.localStorage.removeItem('access_token' + apiUrl);
    },
    getRefreshToken() {
        return window.localStorage.getItem('refresh_token' + apiUrl);
    },
    setRefreshToken(refreshToken) {
        window.localStorage.setItem('refresh_token' + apiUrl, refreshToken);
    },
    removeRefreshToken() {
        window.localStorage.removeItem('refresh_token' + apiUrl);
    },
    getExpiresIn() {
        return window.localStorage.getItem('expires_in' + apiUrl);
    },
    setExpiresIn(expiresIn) {
        window.localStorage.setItem('expires_in' + apiUrl, expiresIn);
    },
    removeExpiresIn() {
        window.localStorage.removeItem('expires_in' + apiUrl);
    },
}

export default authAPI;