import { AxiosRequestConfig } from 'axios';
import { documentAdditionToFormData } from 'utils';
import request from './interceptors';

const qs = require('qs');

const noviAPI = {
    // ApplicationSettings
    applicationSettings: {
        /** Gets application setting with specified name. */
        fetchByName: async (name: string) => await request.get<IApplicationSetting>(`/api/ApplicationSettings/${name}`),
        /** Gets all application settings. */
        fetchAll: async () => await request.get<IApplicationSetting[]>(`/api/ApplicationSettings`)
    },
    // Color Settings
    colorSettings: {
        /** Gets all color settings. */
        fetchSettings: async () => await request.get<IColorSettingLite[]>(`/api/ColorSettings`),
        /** Gets all color setting color configurations. */
        fetchConfigs: async (id: number) => await request.get<IColorSetting>(`/api/ColorSettings/${id}/configurations`),
        /** Gets all colors. */
        fetchColors: async () => await request.get<IColor[]>(`/api/Colors`),
        /** Gets all color types. */
        fetchColorTypes: async (id: number) => await request.get<IColorType[]>(`/api/Colors/types`)
    },
    // CostPoolGroups
    costPoolGroups: {
        /** Gets cost pool group with specified ID. */
        fetch: async (id: number) => await request.get<ICostPoolGroup>(`/api/CostPoolGroups/${id}`),
        /** Updates cost pool group with specified ID. */
        update: async (id: number, costPoolGroup: ICostPoolGroupUpdate) => await request.put(`/api/CostPoolGroups/${id}`, costPoolGroup),
        /** Patched cost pool group with specified ID. */
        patch: async (id: number, costPoolGroup: ICostPoolGroupUpdate) => await request.patch(`/api/CostPoolGroups/${id}`, costPoolGroup),
        /** Deletes cost pool group with specified ID. */
        delete: async (id: number) => await request.delete(`/api/CostPoolGroups/${id}`),
        /** Gets all cost pool groups. */
        fetchAll: async () => await request.get<ISearchData<ICostPoolGroup>>(`/api/CostPoolGroups`),
        /** Adds specified cost pool group. */
        add: async (costPoolGroup: ICostPoolGroupAddition) => await request.post(`/api/CostPoolGroups`, costPoolGroup)
    },
    // CostPools
    costPools: {
        /** Gets costpool with specified ID. */
        fetch: async (machineGroupId: number, id: number) => await request.get<ICostPool>(`/api/MachineGroups/${machineGroupId}/CostPools/${id}`),
        /** Updates costpool with specified ID. */
        update: async (machineGroupId: number, id: number, costPool/*: ICostPoolUpdate*/) => await request.put(`/api/MachineGroups/${machineGroupId}/CostPools/${id}`, costPool),
        /** Deletes costpool with specified ID. */
        delete: async (machineGroupId: number, id: number) => await request.delete(`/api/MachineGroups/${machineGroupId}/CostPools/${id}`),
        /** Gets all cost pools. */
        fetchAll: async (machineGroupId: number) => await request.get<ISearchData<ICostPool>>(`/api/MachineGroups/${machineGroupId}/CostPools`),
        /** Adds specified costpool. */
        add: async (machineGroupId: number, costPool/*: ICostPoolAddition*/) => await request.post(`/api/MachineGroups/${machineGroupId}/CostPools`, costPool)
    },
    // CostPoolTypes
    costPoolTypes: {
        /** Gets all cost pool types (from application settings). */
        fetchAll: async () => await request.get(`api/CostPoolTypes`),
        /** Updates cost pool types (to application settings). */
        update: async (costPoolTypes: string[]) => await request.put(`/api/CostPoolTypes`, costPoolTypes)
    },
    // Counters
    counters: {
        /** Gets counter with specified ID. */
        fetch: async (id: number) => await request.get<ICounter>(`api/Counters/${id}`),
        /** Patches counter with specified ID. */
        patch: async (id: number, counter: ICounterUpdate) => await request.patch(`api/Counters/${id}`, counter),
        /** Deletes counter with specified ID. */
        delete: async (id: number) => await request.delete(`api/Counters/${id}`),
        /** Gets all counters. */
        search: async (params) => await request.get<ISearchData<ICounter>>(`api/Counters/search`, { params }),
        /** Adds specified counter. */
        add: async (counter) => await request.post(`api/Counters`, counter),
        /** Adds specified value to counter with specified ID. */
        addValue: async (id: number, value: number) => await request.post(`api/Counters/${id}/value/${value}`)
    },
    // Dashboards
    dashboards: {
        fetch: async (id: number) => await request.get(`/api/Dashboards/${id}`),
        fetchAll: async (machineGroupId: number, personId: number) => await request.get<IDashboard[]>(`/api/MachineGroup/${machineGroupId}/Dashboards/${personId}`),
        fetchDashboardItemTypes: async () => await request.get('/api/Dashboards/ItemTypes'),
        fetchDashboardTypeOptions: async () => await request.get('/api/Dashboards/TypeOptions'),
        add: async (dashboardData: IDashboardAddition) => await request.post(`/api/Dashboards`, dashboardData),
        delete: async (id: number) => await request.delete(`/api/Dashboards/${id}`),
        addItem: async (dashboardItem) => await request.post(`/api/Dashboards/Items`, dashboardItem),
        updateItem: async (id: number, dashboardItem) => await request.put(`/api/Dashboards/Items/${id}`, dashboardItem),
        deleteItem: async (id: number) => await request.delete(`/api/Dashboards/Items/${id}`),
        updateDashboard: async (id: number, dashboard) => await request.put(`/api/Dashboards/${id}`, dashboard)
    },
    // DashboardStatistics
    dashboardStatistics: {
        /** Gets workcard counters for a specified dashboard. */
        fetch: async (machineGroupId: number, params) => await request.get(`/api/v2/MachineGroups/${machineGroupId}/Dashboard/counters`, { params })
    },
    // Documents
    documents: {
        /** Adds specified document. */
        add: async (document: IDocumentAddition) => await request.post(`/api/Documents`, documentAdditionToFormData(document)),
        /** Gets document with specified ID. */
        fetch: async (id: number) => await request.get(`api/Documents/${id}`),
        /** Updates document with specified ID. */
        update: async (id: number, document/*: IDocumentUpdate*/) => await request.put(`/api/Documents/${id}`, document),
        /** Deletes document with specified ID. */
        delete: async (id: number) => await request.delete(`/api/Documents/${id}`),
        /** Downloads document from specified path. */
        download: async (id: number, config: AxiosRequestConfig) => await request.get(`/api/Documents/${id}/download`, config),
        /** Downloads document from specified path. */
        downloadWithLink: async (downloadLink: string, config: AxiosRequestConfig) => await request.get(downloadLink, config),
        /** Gets all document types. */
        fetchTypes: async () => await request.get(`/api/Documents/types`),
        /** Links given document to given target by type and ID. */
        link: async (params: URLSearchParams) => await request.post('/api/Documents/Link?' + params),
        /** Uninks given document from given target by type and ID. */
        unlink: async (params: URLSearchParams) => await request.delete('/api/Documents/Unlink?' + params),
        /** Gets document requirements */
        getDocumentRequirements: async () => await request.get('/api/Documents/requirements'),
        /** Gets document metadatas */
        getDocumentMetadataGroups: async () => await request.get<IDocumentMetaDataGroup[]>('/api/Documents/metadatagroups'),

    },
    // ExtraDataCaptions
    extraDataCaptions: {
        /** Gets extra data caption with specified ID. */
        fetch: async (id: number) => await request.get(`/api/ExtraDatas/Captions/${id}`),
        /** Updates extra data caption with specified ID. */
        update: async (id: number, extraDataCaption/*: IExtraDataCaptionUpdate*/) => await request.put(`/api/ExtraDatas/Captions/${id}`, extraDataCaption),
        /** Deletes extra data caption with specified ID. */
        delete: async (id: number) => await request.delete(`/api/ExtraDatas/Captions/${id}`),
        /** Gets all extra data captions. */
        fetchAll: async () => await request.get(`/api/ExtraDatas/Captions`),
        /** Adds specified extra data caption. */
        add: async (extraDataCaption/*: IExtraDataCaptionAddition*/) => await request.post(`/api/ExtraDatas/Captions`, extraDataCaption)
    },
    // ExtraDatas
    extraDatas: {
        /** Gets extra data with specified ID. */
        fetch: async (id: number) => await request.get(`/api/ExtraDatas/${id}`),
        /** Updates extra data with specified ID. */
        update: async (id: number, extraData/*: IExtraDataUpdate*/) => await request.put(`/api/ExtraDatas/${id}`, extraData),
        /** Deletes extra data with specified ID. */
        delete: async (id: number) => await request.delete(`/api/ExtraDatas/${id}`),
        /** Gets all extra datas. */
        fetchAll: async () => await request.get(`/api/ExtraDatas`),
        /** Adds specified extra data. */
        add: async (extraData/*: IExtraDataAddition*/) => await request.post(`/api/ExtraDatas`, extraData)
    },
    globalSettings: {
        getByGroup: async (group: string) => await request.get<IGlobalSetting[]>(`/api/GlobalSettings/${group}`)
    },
    // HierarchyItems
    hierarchyItems: {
        /** Get child hierarchy items with specified parent hierarchy item. */
        fetch: async (machineGroupId: number, params) => await request.get<ISearchData<IHierarchyItem>>(`/api/MachineGroups/${machineGroupId}/HierarchyItems`, { params }),
        /** Searches child hierarchy machines with specified search parameters. */
        search: async (machineGroupId: number, params) => await request.get<ISearchData<IHierarchyItem>>(`/api/MachineGroups/${machineGroupId}/HierarchyItems/Search`, { params }),
        /** Get parent hierarchy items (recursively) from specified hierarchy item. */
        fetchParents: async (machineGroupId: number, id: number) => await request.get<IHierarchyItem[]>(`/api/MachineGroups/${machineGroupId}/HierarchyItems/${id}/route`)
    },
    // HourCards
    hourCards: {
        /** Gets hour card with specified ID. */
        fetch: async (id: number) => await request.get<IHourCard>(`/api/HourCards/${id}`),
        /** Updates hour card with specified ID. */
        update: async (id: number, hourCard/*: IHourCardUpdate*/) => await request.put(`/api/HourCards/${id}`, hourCard),
        /** Deletes hour card with specified ID. */
        delete: async (id: number) => await request.delete(`/api/HourCards/${id}`),
        /** Searches hour cards with specified search parameters. */
        search: async (params) => await request.get<ISearchData<IHourCard>>(`/api/HourCards/search`, { params }),
        // Adds specified hour card.
        add: async (hourCard/*: IHourCardAddition*/) => await request.post(`/api/HourCards`, hourCard),
        // Copies a specified hour card.
        copy: async (id: number, params: IHourCardCopyParams) => await request.post(`/api/HourCards/copy/${id}`, params)
    },
    // HourCardStatusLogs
    hourCardStatusLogs: {
        fetchAll: async () => await request.get<ISearchData<IHourCardStatusLog>>(`/api/HourCardStatusLogs`),
        fetchByHourCard: async (id: number) => await request.get<IHourCardStatusLog[]>(`/api/HourCard/${id}/StatusLogs`)
    },
    hourCardDetails: {
        // Gets all hour card details
        fetchByMachineGroup: async (id: number) => await request.get<ISearchData<IHourCardDetail>>(`/api/MachineGroup/${id}/HourCardDetails`)
    },
    // Levels
    levels: {
        /** Gets level with specified ID. */
        fetch: async (id: number) => await request.get(`/api/Levels/${id}`),
        /** Gets all levels. */
        fetchAll: async () => await request.get(`/api/Levels`)
    },
    // MachineGroups
    machineGroups: {
        /** Gets machine group (factory) with specified ID. */
        fetch: async (id: number) => await request.get<IMachineGroup>(`/api/MachineGroups/${id}`),
        /** Gets all machine groups (factories) with current user. */
        fetchAll: async () => await request.get<IMachineGroup[]>(`/api/MachineGroups`)
    },
    // MachineGroupSettings
    machineGroupSettings: {
        /** Gets settings with specified group. */
        fetch: async (id: number, group: string) => await request.get<IViewSetting[]>(`/api/MachineGroups/${id}/settings/${group}`),
        /** Gets settings for specified groups. */
        fetchByGroups: async (id: number, groups) => await request.get<IViewSetting[]>(`/api/MachineGroups/${id}/settings/groups`, { params: groups }),
        /** Gets all settings. */
        fetchAll: async (id: number) => await request.get<IViewSetting[]>(`/api/MachineGroups/${id}/settings`)
    },
    // Machines
    machines: {
        /** Gets machine with specified ID. */
        fetch: async (id: number) => await request.get<IMachine>(`/api/Machines/${id}`),
        /** Gets machine lite with specified ID. */
        fetchMachineLite: async (id: number) => await request.get<IMachineLite>(`/api/Machines/${id}/lite`),
        /** Updates machine with specified ID. */
        update: async (id: number, machine: IMachineUpdate) => await request.put(`/api/Machines/${id}`, machine),
        /** Deletes machine with specified ID. */
        delete: async (id: number) => await request.delete(`/api/Machines/${id}`),
        /** Gets machine with specified code. */
        fetchByCode: async (code: string) => await request.get(`/api/Machines/code/${code}`),
        /** Gets machine lite with specified ID. */
        fetchMachineLiteByCode: async (code: string) => await request.get<IMachineLite>(`/api/Machines/code/${code}/lite`),
        /** Adds specified machine. */
        add: async (machine: IMachineAddition) => await request.post(`/api/Machines`, machine),
        /** Searches machines with specified search parameters. */
        search: async (params) => await request.get<ISearchData<IMachine>>(`/api/Machines/search`, { params }),
        /** Searches machines with saved search parameters. */
        defaultSearch: async (params) => await request.get<ISearchData<IMachine>>(`/api/Machines/defaultSearch`, { params }),
        /** Gets all machine details. */
        fetchDetails: async () => await request.get<IMachineDetail[]>(`/api/Machines/details`),
        /** Gets all machine types. */
        fetchTypes: async (id: number) => await request.get<IdName[]>(`/api/Machines/typesById/${id}`),
        /** Gets extra data of specified machine. */
        fetchExtraData: async (id: number) => await request.get<IMachineExtraData[]>(`/api/Machines/${id}/extraData`),
        /** Gets spare parts of specified machine. */
        fetchSpareParts: async (id: number) => await request.get(`/api/v2/Machines/${id}/SpareParts`),
        /** Gets documents of specified machine. */
        fetchDocuments: async (id: number) => await request.get<IDocument[]>(`/api/v2/Machines/${id}/documents`),
        /** Gets all documents of specified machines child machines. */
        fetchChildMachineDocuments: async (id: number, params) => await request.get<ISearchData<IMachineDocumentLink>>(`/api/Machines/${id}/Machines/documents`, { params }),
        /** Gets all documents of specified machines work cards grouped by work card. */
        fetchWorkCardDocuments: async (id: number, params) => await request.get<ISearchData<IMachineDocumentLink>>(`/api/Machines/${id}/WorkCards/documents`, { params }),
        /** Patches document with specified ID. */
        patchDocument: async (id: number, document) => await request.patch(`/api/Machines/documents/${id}`, document),
        /** Deletes document with specified ID. */
        deleteDocument: async (id: number) => await request.delete(`/api/Machines/documents/${id}`),
        /** Gets all machine ratings. */
        fetchMachineRatings: async (machineGroupId: number) => await request.get(`api/Machines/GetRatings/${machineGroupId}`),
        /** Gets all measurements linked to machines. */
        fetchMeasurements: async (machineId: number) => await request.get<IMeasurementGroupWorkCard[]>(`/api/Machines/${machineId}/Measurements`)
    },
    // MachineSpareParts
    machineSpareParts: {
        /** Gets machine spare part with specified ID. */
        fetch: async (id: number) => await request.get(`/api/Machines/SpareParts/${id}`),
        /** Gets machine spare part links with specified spare part ID. */
        fetchMachineSparePartLinks: async (sparePartId: number) => await request.get(`/api/Machines/SparePartLinks/${sparePartId}`),
        /** Updates machine spare part with specified ID. */
        update: async (id: number, machineSparePart: IMachineSparePartUpdate) => await request.put(`/api/Machines/SpareParts/${id}`, machineSparePart),
        /** Deletes machine spare part with specified ID. */
        delete: async (id: number) => await request.delete(`/api/Machines/SpareParts/${id}`),
        /** Adds specified machine spare part. */
        add: async (machineSparePart: IMachineSparePartAddition) => await request.post(`/api/Machines/SpareParts`, machineSparePart)
    },
    // MachineExtraDatas
    machineExtraDatas: {
        /** Gets all machine extra datas of specified machine. */
        fetch: async (machineId: number) => await request.get(`/api/Machines/${machineId}/ExtraData`),
        /** Adds specified machine extra data. */
        add: async (machineId: number, extraData: IMachineExtraData) => await request.post(`/api/Machines/${machineId}/ExtraDatas`, extraData),
        /** Updates machExtraData with specified ID. */
        update: async (machineId: number, extraDataId: number, extraData: IMachineExtraData) => await request.put(`/api/Machines/${machineId}/ExtraDatas/${extraDataId}`, extraData),
        /** Deletes machExtraData with specified ID. */
        delete: async (machineId: number, extraDataId: number) => await request.delete(`/api/Machines/${machineId}/ExtraDatas/${extraDataId}`)
    },
    // OperatorMaintenances
    operatorMaintenances: {
        /** Gets operator maintenance with specified ID. */
        fetch: async (id: number) => await request.get<IOperatorMaintenance>(`/api/OperatorMaintenances/${id}`),
        /** Updates operator maintenance with specified ID. */
        update: async (id: number, operatorMaintenance/*: IOperatorMaintenanceUpdate*/) => await request.put(`/api/OperatorMaintenances/${id}`, operatorMaintenance),
        /** Gets operator maintenances of specified machine. */
        fetchByMachineId: async (id: number) => await request.get<IOperatorMaintenanceLite[]>(`/api/Machines/${id}/OperatorMaintenances`),
        /** Gets all operator maintenance machines. */
        fetchMachines: async (machineGroupId: number) => await request.get<IMachineLite[]>(`/api/OperatorMaintenances/${machineGroupId}/Machines`),
        /** Gets all operator maintenance procedure data types. */
        fetchDataTypes: async () => await request.get(`/api/OperatorMaintenances/dataTypes`),
        /** Gets documents of specified operator maintenance. */
        fetchDocuments: async (id: number) => await request.get<IOperatorMaintenanceDocuments>(`/api/OperatorMaintenances/${id}/documents`),
        /** Patches document with specified ID. */
        patchDocument: async (id: number, document) => await request.patch(`/api/OperatorMaintenances/documents/${id}`, document),
        /** Deletes document with specified ID. */
        deleteDocument: async (id: number) => await request.delete(`/api/OperatorMaintenances/documents/${id}`)
    },
    // Orderers
    orderers: {
        /** Gets orderer with specified ID from specified machine group (factory). */
        fetch: async (machineGroupId: number, id: number) => await request.get(`/api/MachineGroups/${machineGroupId}/Orderers/${id}`),
        /** Deletes orderer with specified ID from specified machine groups (factory) worker list. */
        delete: async (machineGroupId: number, id: number) => await request.delete(`/api/MachineGroups/${machineGroupId}/Orderers/${id}`),
        /** Gets all orderers from specified machine group (factory). */
        fetchAll: async (machineGroupId: number) => await request.get(`/api/MachineGroups/${machineGroupId}/Orderers`),
        /** Adds specified person to specified machine group (factory) as orderer. */
        add: async (orderer/*: IOrdererAddition*/) => await request.post(`/api/Orderers`, orderer)
    },
    // People
    people: {
        /** Gets person with specified ID. */
        fetch: async (id: number) => await request.get<IPerson>(`/api/People/${id}`),
        /** Gets person with specified ID. */
        fetchByAdName: async (adName: string) => await request.get<IPerson>(`/api/People/adName/${adName}`),
        /** Gets all people. */
        fetchAll: async () => await request.get<IPerson[]>(`/api/People`)
    },
    // PersonGroups
    personGroups: {
        // Gets all person groups.
        fetchAll: async (machineGroupId: number) => await request.get<ISearchData<IPersonGroup[]>>(`/api/MachineGroups/${machineGroupId}/PersonGroups`),
        // Gets all person groups of signed in user.
        fetchAllForUser: async (machineGroupId: number) => await request.get<IPersonGroup[]>(`/api/MachineGroups/${machineGroupId}/PersonGroups/user`),
        // Gets collection of person groups and people that belongs to them.
        fetchPeopleByGroup: async (personGroupIds: number[]) => await request.get<IPersonGroupPeople[]>('/api/PersonGroups/People/', {
            params: { personGroupIds: personGroupIds },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' })
            }
        }),
    },
    // PersonGroupSettings
    personGroupSettings: {
        /** Gets all person group settings with current user. */
        fetchAll: async () => await request.get(`/api/PersonGroups/settings`),
        /** Gets all person group settings (userRights) with current user and machineGroup. */
        fetchUserRights: async (machineGroupId) => await request.get(`api/PersonGroups/settings/${machineGroupId}`)
    },
    // PersonMachineGroupSettings
    personMachineGroupSettings: {
        /** Gets all person machine group settings for current user. */
        getAll: async () => await request.get(`/api/PersonMachineGroup/settings`),
        /** Gets all specific person machine group settings for current user by machineGroup and group. */
        getByGroup: async (machineGroupId: number, group: string) => await request.get(`/api/PersonMachineGroup/settings/${machineGroupId}/${group}`),
        /** Update a person machine group setting value. */
        update: async (machineGroupId: number, group: string, setting) => await request.post(`/api/PersonMachineGroup/settings/${machineGroupId}/${group}`, setting),
    },
    // PersonSettings
    personSettings: {
        /** Gets all settings with specified group. */
        fetch: async (userId: number, group: string) => await request.get<IUserSetting[]>(`api/People/${userId}/settings/${group}`),
        /** Gets all settings with specified person. */
        fetchAll: async (userId: number) => await request.get<IUserSetting[]>(`api/People/${userId}/settings`)
    },
    // Projects
    projects: {
        fetchAll: async (machineGroupId: number) => await request.get(`/api/MachineGroups/${machineGroupId}/ProjectsAll`),
        fetchAllOperational: async (machineGroupId: number) => await request.get(`/api/Projects/${machineGroupId}/Operational`),

    },
    // PurchaseOrderItems
    purchaseOrderItems: {
        /** Gets purchase order item with specified ID. */
        fetch: async (id: number) => await request.get<IPurchaseOrderItem>(`/api/PurchaseOrderItems/${id}`),
        /** Gets purchase order items with specified IDs. */
        search: async (ids) => await request.get<IPurchaseOrderItem[]>(`/api/PurchaseOrderItems/multiple`, { params: ids }),
        /** Updates purchase order item with specified ID. */
        update: async (id: number, purchaseOrderItem/*: IPurchaseOrderItemUpdate*/) => await request.put(`/api/PurchaseOrderItems/${id}`, purchaseOrderItem),
        /** Deletes purchase order item with specified ID. */
        delete: async (id: number) => await request.delete(`/api/PurchaseOrderItems/${id}`),
        /** Gets purchase order items of specified spare part. */
        fetchBySparePartId: async (sparePartId: number) => await request.get<IPurchaseOrderItemLite[]>(`/api/SpareParts/${sparePartId}/PurchaseOrderItems`),
        /** Adds specified purchase order item. */
        add: async (purchaseOrderItem/*: IPurchaseOrderItemAddition*/) => await request.post(`/api/PurchaseOrderItems`, purchaseOrderItem),
        addByWSparePart: async (purchaseOrderItem: IPurchaseOrderItemAddition) => await request.post(`/api/PurchaseOrderItems/ByWorkCardSparePart`, purchaseOrderItem),

        arrive: async (warehouseId, sparepartId, params: IPurchaseOrderItemArrival) => await request.post(`/api/PurchaseOrderItems/arrive/${warehouseId}/${sparepartId}`, params)
    },
    // PurchaseOrders
    purchaseOrders: {
        /** Gets purchase order with specified ID. */
        fetch: async (machineGroupId: number, id: number) => await request.get<IPurchaseOrderSearchResult>(`/api/PurchaseOrders/MachineGroup/${machineGroupId}/${id}`),
        /** Updates purchase order with specified ID. */
        update: async (id: number, purchaseOrder/*: IPurchaseOrderUpdate*/) => await request.put(`/api/PurchaseOrders/${id}`, purchaseOrder),
        /** Deletes purchase order with specified ID. */
        delete: async (id: number) => await request.delete(`/api/PurchaseOrders/${id}`),
        /** Gets all purchase orders. */
        fetchAll: async () => await request.get(`/api/PurchaseOrders`),
        /** Adds specified purchase order. */
        add: async (machineGroupId: number, purchaseOrder: IPurchaseOrderAddition) => await request.post(`/api/PurchaseOrders/MachineGroup/${machineGroupId}`, purchaseOrder),
        /** Searches purchase orders with specified search parameters. */
        search: async (params) => await request.get<ISearchData<IPurchaseOrderSearchResult>>(`/api/PurchaseOrders/search`, { params })
    },
    // QuickSearches
    quickSearches: {
        /** Gets all quick searches with current user. */
        fetchAll: async (machineGroupId: number) => await request.get<IQuickSearch[]>(`/api/MachineGroups/${machineGroupId}/QuickSearches`),
        /** Gets all quick searches with specified group and current user. */
        fetchAllByGroup: async (machineGroupId: number, group: string) => await request.get<IQuickSearch[]>(`/api/MachineGroups/${machineGroupId}/QuickSearches/group/${group}`),
        /** Gets results from specified quick search. */
        fetchResults: async <T>(machineGroupId: number, id: number, params) => await request.get<ISearchData<T>>(`/api/MachineGroups/${machineGroupId}/QuickSearches/${id}`, { params }),
        /** Gets card model results from specified quick search. */
        fetchCardModelResults: async <T> (machineGroupId: number, id: number, params) => await request.get<ISearchData<T>>(`/api/MachineGroups/${machineGroupId}/QuickSearches/Cards/${id}`, { params }),
    },
    // ResponsiblePeople
    responsiblePeople: {
        /** Gets responsible person with specified ID and category. */
        fetch: async (machineGroupId: number, category: string, personId: number) => await request.get(`/api/MachineGroups/${machineGroupId}/ResponsiblePeople/${category}/${personId}`),
        /** Removes specified person from person group(s) with specified responsible person category. */
        delete: async (machineGroupId: number, category: string, personId: number) => await request.delete(`/api/MachineGroups/${machineGroupId}/ResponsiblePeople/${category}/${personId}`),
        /** Gets all responsible people with specified category. */
        fetchAll: async (machineGroupId: number, category: string) => await request.get<IPerson[]>(`/api/MachineGroups/${machineGroupId}/ResponsiblePeople/${category}`),
        /** Gets all responsible people categories. */
        fetchCategories: async () => await request.get(`/api/ResponsiblePeople/categories`),
        /** Gets all person groups with specified responsible person group category. */
        fetchPersonGroups: async (machineGroupId: number, category: string) => await request.get(`/api/MachineGroups/${machineGroupId}/ResponsiblePeople/${category}/PersonGroups`),
        /** Adds specified person to person group with specified responsible person category. */
        add: async (responsiblePerson/*: IResponsiblePersonAddition*/) => await request.post(`/api/ResponsiblePeople`, responsiblePerson)
    },
    // RouteMaintenanceWorkCards
    routeMaintenanceWorkCards: {
        /** Gets route maintenance work card with specified ID. */
        fetch: async (id: number) => await request.get(`/api/WorkCards/routeMaintenances/${id}`),
        /** Updates route maintenance work card with specified ID. */
        update: async (id: number, routeMaintenanceWorkCard/*: IRouteMaintenanceWorkCardUpdate*/) => await request.put(`/api/WorkCards/routeMaintenances/${id}`, routeMaintenanceWorkCard),
        /** Deletes route maintenance work card with specified ID. */
        delete: async (id: number) => await request.delete(`/api/WorkCards/routeMaintenances/${id}`),
        /** Searches route maintenance work cards with specified search parameters. */
        search: async (params) => await request.get(`/api/WorkCards/routeMaintenances/search`, { params }),
        /** Gets documents of specified route maintenance work card. */
        documents: async (id: number) => await request.get(`/api/WorkCards/routeMaintenances/${id}/documents`),
        /** Patches document with specified ID. */
        patchDocument: async (id: number, document) => await request.patch(`/api/WorkCards/routeMaintenances/documents/${id}`, document),
        /** Deletes document with specified ID. */
        deleteDocument: async (id: number) => await request.delete(`/api/WorkCards/routeMaintenances/documents/${id}`)
    },
    // SafetyNotices
    safetyNotices: {
        /** Searches safety notices with specified search parameters. */
        search: async (params) => await request.get(`/api/SafetyNotices/search`, { params }),
        /** Gets safety notice with specified ID. */
        fetch: async (id: number) => await request.get(`/api/SafetyNotices/${id}`),
        /** Adds a new safety notice. */
        add: async (safetyNoticeAddition: ISafetyNoticeAddition) => await request.post(`/api/SafetyNotices`, safetyNoticeAddition),
        /** Add detail value to safety notice. */
        addDetailValue: async (id: number, safetyDetailAddition: ISafetyDetailAddition) => await request.post(`/api/SafetyNotices/${id}/details`, safetyDetailAddition),
    },
    // SafetyTypes
    safetyTypes: {
        fetchAll: async (machineGroupId) => await request.get<ISafetyType[]>(`/api/MachineGroups/${machineGroupId}/SafetyTypes`),
    },
    // SafetyTypeDetails
    safetyTypeDetails: {
        fetchAll: async (safetyTypeId) => await request.get<ISearchData<ISafetyDetail>>(`/api/SafetyTypes/${safetyTypeId}/SafetyDetails`),
    },
    // SparePartDetails
    sparePartDetails: {
        /** Gets spare part detail with specified ID. */
        fetch: async (id: number) => await request.get<ISparePartDetail>(`api/SpareParts/details/${id}`),
        /** Updates spare part detail with specified ID. */
        update: async (id: number, sparePartDetail: ISparePartDetailUpdate) => await request.put(`api/SpareParts/details/${id}`, sparePartDetail),
        /** Deletes spare part detail with specified ID. */
        delete: async (id: number) => await request.delete(`api/SpareParts/details/${id}`),
        /** Gets all spare part details. */
        fetchAll: async () => await request.get<ISearchData<ISparePartDetail>>(`api/SpareParts/details`),
        /** Adds specified spare part detail. */
        add: async (sparePartDetail: ISparePartDetailAddition) => await request.post(`api/SpareParts/details`, sparePartDetail),
    },
    // SpareParts
    spareParts: {
        /** Gets spare part with specified ID. */
        fetch: async (id: number) => await request.get<ISparePart>(`/api/SpareParts/${id}`),
        /** Gets spare part lite with specified ID. */
        fetchLite: async (id: number) => await request.get<ISparePartLite>(`/api/SpareParts/${id}/lite`),
        /** Updates spare part with specified ID. */
        update: async (id: number, sparePart: ISparePartUpdate) => await request.put(`/api/SpareParts/${id}`, sparePart),
        /** Deletes spare part with specified ID. */
        delete: async (id: number) => await request.delete(`/api/SpareParts/${id}`),
        /** Gets spare part with specified code. */
        fetchByCode: async (code: string) => await request.get<ISparePart>(`/api/SpareParts/code/${code}`),
        /** Gets spare part lite with specified code. */
        fetchByCodeWithSpecialRights: async (code: string, rights: string) => await request.get<ISparePartLite>(`/api/SpareParts/code/${code}/rights/${rights}`),
        /** Gets all spare parts. */
        fetchAll: async () => await request.get<ISparePart[]>(`/api/SpareParts`),
        /** Adds specified spare part. */
        add: async (sparePart: ISparePartAddition) => await request.post(`/api/SpareParts`, sparePart),
        /** Searches spare parts with specified search parameters. */
        search: async (params) => await request.get<ISearchData<ISparePart>>(`/api/SpareParts/search`, { params }),
        /** Searches spare part lites with specified search parameters. */
        searchLite: async (params) => await request.get<ISearchData<ISparePartLite>>(`/api/SpareParts/search/lite`, { params }),
        /** Gets machines of specified spare part. */
        fetchMachines: async (id: number) => await request.get<IMachineLite[]>(`/api/SpareParts/${id}/Machines`),
        /** Gets documents of specified spare part. */
        fetchDocuments: async (id: number) => await request.get(`/api/SpareParts/${id}/Documents`),
        /** Gets freeAmount value for sparePart */
        fetchFreeAmount: async (id: number) => await request.get(`/api/SpareParts/${id}/freeAmount`),
    },
    // SparePartReservationItems
    SparePartReservationItems: {
        /* Gets a reservation item for given warehouse/sparepart */
        fetchByWarehouseAndSparepart: async (warehouseId: number, sparePartId: number) => await request.get(`/api/SparePartReservationItems/Warehouses/${warehouseId}/SpareParts/${sparePartId}`),
    },
    // SparePartExtraDataCaptions
    sparePartExtraDataCaptions: {
        /** Gets spare part extra data caption with specified ID. */
        fetch: async (id: number) => await request.get<ISparePartExtraDataCaption>(`/api/SpareParts/ExtraDatas/Captions/${id}`),
        /** Patches spare part extra data caption with specified ID. */
        patch: async (id: number, sparePartExtraDataCaption: ISparePartExtraDataCaptionPatch) => await request.patch(`/api/SpareParts/ExtraDatas/Captions/${id}`, sparePartExtraDataCaption),
        /** Deletes spare part extra data caption with specified ID. */
        delete: async (id: number) => await request.delete(`/api/SpareParts/ExtraDatas/Captions/${id}`),
        /** Gets all spare part extra data captions. */
        fetchAll: async (params?) => await request.get<ISearchData<ISparePartExtraDataCaption>>(`/api/SpareParts/ExtraDatas/Captions`, { params }),
        /** Adds specified spare part extra data caption. */
        add: async (sparePartExtraDataCaption: ISparePartExtraDataCaptionAddition) => await request.post(`/api/SpareParts/ExtraDatas/Captions`, sparePartExtraDataCaption)
    },
    // SparePartExtraDatas
    sparePartExtraDatas: {
        fetch: async (sparePartId: number) => await request.get(`/api/SpareParts/${sparePartId}/ExtraDatas`),
        add: async (sparePartId: number, sparePartExtraData) => await request.post(`/api/SpareParts/${sparePartId}/ExtraDatas`, sparePartExtraData),
        delete: async (sparePartId: number, extraDataId: number) => await request.delete(`/api/SpareParts/${sparePartId}/ExtraDatas/${extraDataId}`),
        patch: async (extraDataId: number, sparePartExtraData) => await request.patch(`/api/SpareParts/ExtraDatas/${extraDataId}`, sparePartExtraData)
    },
    // ThirdParties
    thirdParties: {
        /** Gets third party with specified ID. */
        fetch: async (id: number) => await request.get(`/api/ThirdParties/${id}`),
        /** Gets third parties that match any of the given type IDs. */
        fetchByTypes: async (ids) => await request.get(`/api/ThirdParties/getByTypes`, { params: ids }),
        /** Updates third party with specified ID. */
        update: async (id: number, thirdParty) => await request.put(`/api/ThirdParties/${id}`, thirdParty),
        /** Gets all third parties. */
        fetchAll: async () => await request.get<IThirdPartyLite[]>(`/api/v2/ThirdParties`),
        /** Adds new third party. */
        add: async (thirdParty) => await request.post(`/api/ThirdParties`, thirdParty),
        /** Validate new third party. */
        validate: async (thirdParty) => await request.post(`/api/ThirdParties/Validate`, thirdParty)
    },
    thirdPartyDetails: {
        fetchAll: async () => await request.get(`/api/ThirdParties/Details`)
    },
    // ThirdPartyTypes
    thirdPartyTypes: {
        /** Gets all third party types. */
        fetchAll: async () => await request.get<IThirdPartyType[]>(`/api/ThirdParties/types`),
        /** Gets all third parties of specified third party type. */
        fetchThirdPartiesByTypeId: async (id: number) => await request.get<IThirdPartyLite[]>(`/api/ThirdParties/types/${id}/thirdParties`)
    },
    // Translations
    translations: {
        /** Gets all translations that are available without authorization. */
        fetchAllOpenTranslations: async () => await request.get<ITranslation[]>(`/api/OpenTranslations`),
        /** Gets all translations with specified language that are available without authorization. */
        fetchAllOpenTranslationsByLang: async (lang: string) => await request.get<ITranslation[]>(`/api/OpenTranslations/${lang}`),
        /** Gets all translations */
        fetchAll: async () => await request.get<ITranslation[]>(`/api/Translations`),
        /** Gets all translations with specified language. */
        fetchAllByLang: async (lang: string) => await request.get<ITranslation[]>(`/api/Translations/${lang}`),
        /** Gets all languages. */
        fetchLanguages: async () => await request.get<Language[]>(`/api/Translations/languages`),
        /** Gets default system language available without authorization. */
        openLanguage: async () => await request.get<Language>(`/api/OpenLanguage`)
    },
    // Version
    version: {
        /** Gets API version. */
        fetch: async () => await request.get<APIVersion>(`/api/version`)
    },
    // WarehouseLogs
    warehouseLogs: {
        /** Gets warehouse log with specified ID. */
        fetch: async (id: number) => await request.get(`/api/WarehouseLogs/${id}`),
        /** Searches warehouse logs with specified search parameters. */
        search: async (params) => await request.get(`/api/WarehouseLogs/search`, { params }),
        /** Gets all warehouse log types. */
        fetchTypes: async () => await request.get(`/api/WarehouseLogs/types`)
    },
    // WarehouseOperations
    warehouseOperations: {
        /** Takes specified spare part from warehouse with specified ID. */
        take: async (warehouseId: number, sparePartId: number, sparePart: ISparePartTake) => await request.post(`/api/Warehouses/${warehouseId}/SpareParts/${sparePartId}/take`, sparePart),
        /** Returns specified spare part to warehouse with specified ID. */
        return: async (warehouseId: number, sparePartId: number, sparePart: ISparePartReturn) => await request.post(`/api/Warehouses/${warehouseId}/SpareParts/${sparePartId}/return`, sparePart),
        /** Invents specified spare part to warehouse with specified ID. */
        invent: async (warehouseId: number, sparePartId: number, sparePart: ISparePartInventAmount) => await request.post(`/api/Warehouses/${warehouseId}/SpareParts/${sparePartId}/invent`, sparePart),
        /** Arrives specified spare part to warehouse with specified ID. */
        arrive: async (warehouseId: number, sparePartId: number, sparePart: ISparePartArrive) => await request.post(`/api/Warehouses/${warehouseId}/SpareParts/${sparePartId}/arrive`, sparePart),
        /** Transfers specified spare part to warehouse with specified ID. */
        transfer: async (warehouseId: number, sparePartId: number, sparePart: ISparePartTransfer) => await request.put(`/api/Warehouses/${warehouseId}/SpareParts/${sparePartId}/transfer`, sparePart),
        /** Changes price of specified spare part to warehouse with specified ID. */
        price: async (warehouseId: number, sparePartId: number, sparePart: ISparePartChange) => await request.put(`/api/Warehouses/${warehouseId}/SpareParts/${sparePartId}/price`, sparePart),
        /** After price of warehouse log with specified ID. */
        afterPrice: async (warehouseLogId: number, afterPrice/*: IAfterPrice*/) => await request.put(`/api/WarehouseLogs/${warehouseLogId}/afterPrice`, afterPrice),
        /** Runs spare part batch deprecations. */
        runSparePartBatchDeprecations: async () => await request.post(`/api/SparePartBatches/deprecation`),
        /**  Gets SparePart annual consumption with SparePart ID.*/
        fetchAnnualSparePartConsumption: async (sparePartId: number) => await request.get(`/api/Warehouses/SpareParts/${sparePartId}/annualConsumption`)
    },
    // Warehouses
    warehouses: {
        /** Gets all warehouses */
        fetchAll: async () => await request.get<IWarehouseLite[]>(`/api/Warehouses`),
        /** Adds specified warehouse. */
        add: async (warehouse) => await request.post(`/api/Warehouses`, warehouse),
        /** Gets warehouses of specified machine group (factory). */
        fetchAllByMachineGroup: async (machineGroupId: number) => await request.get<IWarehouseLite[]>(`/api/MachineGroups/${machineGroupId}/Warehouses`),
        /** Updates warehouse with specified ID. */
        update: async (id: number, warehouse) => await request.put(`/api/Warehouses/${id}`, warehouse),
        /** Deletes warehouse with specified ID. */
        delete: async (id: number) => await request.delete(`/api/Warehouses/${id}`),
        /** Searches warehouses with specified search parameters. */
        search: async (params) => await request.get<ISearchData<IWarehouseLite>>(`/api/Warehouses/search`, { params }),
    },
    // WarehouseSparePartLinks
    warehouseSparePartLinks: {
        /** Gets warehouse spare part link with specified ID. */
        fetch: async (id: number) => await request.get<IWarehouseSparePartLink>(`/api/Warehouses/SparePartLinks/${id}`),
        /** Gets warehouse spare part link by given warehouse ID and specified link id. */
        fetchByWarehouseId: async (warehouseId: number, id: number) => await request.get<IWarehouseSparePartLink>(`/api/Warehouses/${warehouseId}/SpareParts/${id}`),
        /** Updates warehouse spare part link with specified ID. */
        update: async (id: number, sparePartLink: IWarehouseSparePartLinkUpdate) => await request.put(`/api/Warehouses/SparePartLinks/${id}`, sparePartLink),
        /** Deletes warehouse spare part link with specified ID. */
        delete: async (id: number) => await request.delete(`/api/Warehouses/SparePartLinks/${id}`),
        /** Adds specified warehouse spare part link. */
        add: async (sparePartLink: IWarehouseSparePartLinkAddition) => await request.post(`/api/v2/Warehouses/SparePartLinks/search`, sparePartLink),
        /** Searches warehouse spare part links with specified search parameters. */
        search: async (params) => await request.get<ISearchData<IWarehouseSparePartLinkSearchResult>>(`/api/v2/Warehouses/SparePartLinks/search`, { params }),
        /** Searches warehouse spare part links and sorts them accordingly for warehouse invent. */
        fetchForInvent: async (params) => await request.get<IWarehouseSparePartLink[]>(`/api/v2/Warehouses/SparePartLinks/search/invent`, { params }),
        /** Gets spare part's warehouse data. */
        fetchSparePartWarehouseData: async (sparePartId: number) => await request.get<IWarehouseSparePartLink[]>(`/api/Spareparts/${sparePartId}/Warehouses`),
        /** Searches warehouse spare parts by using users default serach parameters saved in database */
        defaultSearch: async (params) => await request.get<ISearchData<IWarehouseSparePartLinkSearchResult>>(`/api/Warehouses/SparePartLinks/defaultSearch`, { params })
    },
    // WorkCardDetails
    workCardDetails: {
        /** Gets work card detail with specified ID. */
        fetch: async (id: number) => await request.get<IWorkCardDetail>(`/api/WorkCards/details/${id}`),
        /** Updates work card detail with specified ID. */
        update: async (id: number, workCardDetail/*: IWorkCardDetailUpdate*/) => await request.put(`/api/WorkCards/details/${id}`, workCardDetail),
        /** Deletes work card detail with specified ID. */
        delete: async (id: number) => await request.delete(`/api/WorkCards/details/${id}`),
        /** Gets all work card details with specific machine group (factory). */
        fetchAll: async (machineGroupId: number) => await request.get<IWorkCardDetail[]>(`/api/MachineGroups/${machineGroupId}/WorkCards/details`),
        /** Gets all work card details with specific machine group (factory) and group. */
        fetchAllByGroup: async (machineGroupId: number, group: string) => await request.get<IWorkCardDetail[]>(`/api/MachineGroups/${machineGroupId}/WorkCards/details/${group}`),
        /** Adds specified work card detail. */
        add: async (workCardDetail/*: IWorkCardDetailAddition*/) => await request.post(`/api/WorkCards/details`, workCardDetail)
    },
    // WorkPhaseDetails
    workPhaseDetails: {
        /** Gets work card detail with specified ID. */
        fetch: async (id: number) => await request.get(`/api/WorkCards/phases/details/${id}`),
        /** Updates work card detail with specified ID. */
        update: async (id: number, workPhaseDetail/*: IWorkCardDetailUpdate*/) => await request.put(`/api/WorkCards/phases/details/${id}`, workPhaseDetail),
        /** Deletes work card detail with specified ID. */
        delete: async (id: number) => await request.delete(`/api/WorkCards/phases/details/${id}`),
        /** Gets all work card details with specific machine group (factory). */
        fetchAll: async (machineGroupId: number) => await request.get(`/api/MachineGroups/${machineGroupId}/WorkCards/phases/details`),
        /** Gets all work card details with specific machine group (factory) and group. */
        fetchAllByGroup: async (machineGroupId: number, group: string) => await request.get(`/api/MachineGroups/${machineGroupId}/WorkCards/phases/details/${group}`),
        /** Adds specified work card detail. */
        add: async (workPhaseDetail/*: IWorkCardDetailAddition*/) => await request.post(`/api/WorkCards/phases/details`, workPhaseDetail)
    },
    // WorkCardExtraDatas
    workCardExtraDatas: {
        /** Gets all work card extra datas of specified work card. */
        fetch: async (workCardId: number) => await request.get(`/api/WorkCards/${workCardId}/ExtraDatas`),
        /** Adds specified work card extra data. */
        add: async (workCardId: number, extraData: IWorkCardExtraData) => await request.post(`/api/WorkCards/${workCardId}/ExtraDatas`, extraData),
        /** Updates work card extra data with specified ID. */
        update: async (workCardId: number, extraDataId: number, extraData: IWorkCardExtraData) => await request.put(`/api/WorkCards/${workCardId}/ExtraDatas/${extraDataId}`, extraData),
        /** Deletes work card extra data with specified ID. */
        delete: async (workCardId: number, extraDataId: number) => await request.delete(`/api/WorkCards/${workCardId}/ExtraDatas/${extraDataId}`)
    },
    // WorkCardPhases
    workCardPhases: {
        /** Gets work card phase with specified ID. */
        fetch: async (id: number) => await request.get(`/api/WorkCards/phases/${id}`),
        /** Updates work card phase with specified ID. */
        update: async (id: number, workCardPhase/*: IWorkCardPhaseUpdate*/) => await request.post(`/api/WorkCards/phases/${id}`, workCardPhase),
        /** Deletes work card phase with specified ID. */
        delete: async (id: number) => await request.delete(`/api/WorkCards/phases/${id}`),
        /** Adds specified work card phase to specified work card. */
        add: async (workCardId: number, workCardPhase/*: IWorkCardPhaseAddition*/) => await request.post(`/api/WorkCards/${workCardId}/phases`, workCardPhase)
    },
    // WorkCardPhaseExtraDatas
    workCardPhaseExtraDatas: {
        /** Gets all work card phase extra datas of specified work card phase. */
        fetch: async (wcPhaseId: number) => await request.get(`/api/WorkCardPhases/${wcPhaseId}/ExtraDatas`),
        /** Adds specified work card phase extra data. */
        add: async (wcPhaseId: number, extraData/*: IExtraDataAddition*/) => await request.post(`/api/WorkCardPhases/${wcPhaseId}/ExtraDatas`, extraData),
        /** Updates work card phase extra data with specified ID. */
        update: async (wcPhaseId: number, extraDataId: number, extraData/*: IExtraDataUpdate*/) => await request.put(`/api/WorkCardPhases/${wcPhaseId}/ExtraDatas/${extraDataId}`, extraData),
        /** Deletes work card phase extra data with specified ID. */
        delete: async (wcPhaseId: number, extraDataId: number) => await request.delete(`/api/WorkCardPhases/${wcPhaseId}/ExtraDatas/${extraDataId}`)
    },
    // WorkCards
    workCards: {
        /** Gets work card with specified ID. */
        fetch: async (id: number) => await request.get<IWorkCard>(`/api/WorkCards/${id}`),
        /** Gets complete work card with specified ID. */
        fetchComplete: async (id: number) => await request.get<IWorkCardComplete>(`/api/WorkCards/${id}/complete`),
        /** Updates work card with specified ID. */
        update: async (id: number, workCard/*: IWorkCardUpdate*/) => await request.put(`/api/WorkCards/${id}`, workCard),
        /** Deletes work card with specified ID. */
        delete: async (id: number) => await request.delete(`/api/WorkCards/${id}`),
        /** Gets work card with specified code. */
        fetchByCode: async (code: string) => await request.get<IWorkCard>(`/api/WorkCards/code/${code}`),
        /** Adds specified work card. */
        add: async (workCard/*: IWorkCardAddition*/) => await request.post(`/api/WorkCards`, workCard),
        /** Searches work cards with specified search parameters. */
        search: async (params) => await request.get<ISearchData<IWorkCard>>(`/api/WorkCards/search`, { params }),
        /** Searches work cards and route maintenance work cards with specified search parameters. */
        searchCombined: async (params) => await request.get<ISearchData<IWorkCard>>(`/api/v2/WorkCards/search/combined`, { params }),
        /** Searches and return card models of the work cards and route maintenance work cards with specified search parameters*/
        searchWorkCardCardsCombined: async (params) => await request.get<ISearchData<any>>(`/api/WorkCardCards/search`, { params }),
        /** Gets work permits of specified work card. */
        fetchWorkPermits: async (id: number) => await request.get<IWorkPermit[]>(`/api/WorkCards/${id}/workPermits`),
        /** Gets spare parts of specified work card. */
        fetchSpareParts: async (id: number) => await request.get<IWorkCardSparePart[]>(`/api/WorkCards/${id}/spareParts`),
        /** Gets documents of specified work card. */
        fetchDocuments: async (id: number) => await request.get<IWorkcardDocuments>(`/api/WorkCards/${id}/documents`),
        /** Patches document with specified ID. */
        patchDocument: async (id: number, document) => await request.patch(`/api/WorkCards/documents/${id}`, document),
        /** Deletes document with specified ID. */
        deleteDocument: async (id: number) => await request.delete(`/api/WorkCards/documents/${id}`),
        /** Searches work cards by using default search parameters saved in database. */
        defaultSearch: async (params) => await request.get<ISearchData<IWorkCard>>(`/api/WorkCards/defaultSearch`, { params }),
        /** Searches work cards by using default search parameters saved in database. */
        defaultCardModelSearch: async (params) => await request.get<ISearchData<any>>(`/api/WorkCards/defaultWorkCardModelSearch`, { params }),
        /** Returns count only **/
        getCount: async (params) => await request.get<ISearchData<IWorkCard>>(`/api/WorkCards/getCount`, { params }),
        /** Gets work cards of specified operator maintenance with limited amount of rows. */
        fetchOperatorMaintenanceWorkCards: async (params) => await request.get<ISearchData<IWorkCard>>(`/api/WorkCards/OperatorMaintenances/search`, { params }),
        /** Searches lite version of work cards, route maintenance work cards and timed operator maintenance work cards with specified search parameters */
        fetchMachineOpenWorkCards: async (params) => await request.get<ISearchData<IWorkCardLiteExtended>>(`/api/WorkCards/ExtendedLite/search`, { params })
    },
    // WorkCardSettings
    workCardSettings: {
        /** Gets all work card settings and default values. */
        fetchAll: async () => await request.get<IWorkcardsSettings>(`/api/WorkCards/settings`),
        /** Gets all work statuses. */
        fetchWorkStatuses: async () => await request.get<IWorkStatus[]>(`/api/WorkStatuses`),
        /** Gets all urgencies. */
        fetchUrgencies: async () => await request.get<IUrgency[]>(`/api/Urgencies`),
        /** Gets default faultnotice text. */
        getDefaultFaultNoticeText: async () => await request.get<IGlobalSetting>(`/api/FaultNoticeText`)
    },
    // WorkCardSpareParts
    workCardSpareParts: {
        /** Gets work card spare part link with the given ID. */
        fetch: async (id: number) => await request.get<IWorkCardSparePart>(`/api/WorkCards/spareParts/${id}`),
        /** Updates work card spare part with specified ID. */
        update: async (id: number, workCardSparePart: IWorkCardSparePartUpdate) => await request.put(`/api/WorkCards/spareParts/${id}`, workCardSparePart),
        /** Deletes work card spare part link with specified ID. */
        delete: async (id: number) => await request.delete(`/api/WorkCards/spareParts/${id}`),
        /** Adds specified work card spare part. */
        add: async (workCardSparePart: IWorkCardSparePartAddition) => await request.post(`/api/WorkCards/spareParts`, workCardSparePart),
        /** Adds specified work card spare part. */
        addWithSpecialRights: async (workCardSparePart: IWorkCardSparePartAddition, rights: string) => await request.post(`/api/WorkCards/spareParts/rights/${rights}`, workCardSparePart),
        take: async (id: number, amount: URLSearchParams) => await request.put(`/api/WorkCards/spareParts/${id}/take/`, amount),
        return: async (id: number, amount: URLSearchParams) => await request.put(`/api/WorkCards/spareParts/${id}/return/`, amount)
    },
    // WorkerGroups
    workerGroups: {
        /** Gets worker group (PersonGroup with WorkerGroup == true) with specified ID. */
        fetch: async (id: number) => await request.get(`/api/WorkerGroups/${id}`),
        /** Gets all worker groups (PersonGroup with WorkerGroup == true). */
        fetchAll: async (machineGroupId: number) => await request.get(`/api/MachineGroups/${machineGroupId}/WorkerGroups`),
        /** Gets all worker groups (PersonGroup with WorkerGroup == true) of specified person. */
        fetchAllByPerson: async (id: number) => await request.get(`/api/People/${id}/WorkerGroups`),
        /** Gets people of specified worker group (PersonGroup with WorkerGroup == true). */
        fetchPeople: async (id: number) => await request.get(`/api/WorkerGroups/${id}/People`)
    },
    // Workers
    workers: {
        /** Gets worker with specified ID from specified machine group (factory). */
        fetch: async (machineGroupId: number, id: number) => await request.get(`/api/MachineGroups/${machineGroupId}/Workers/${id}`),
        /** Deletes worker with specified ID from specified machine groups (factory) worker list. */
        delete: async (machineGroupId: number, id: number) => await request.delete(`/api/MachineGroups/${machineGroupId}/Workers/${id}`),
        /** Gets all workers from specified machine group (factory). */
        fetchAll: async (machineGroupId: number) => await request.get(`/api/MachineGroups/${machineGroupId}/Workers`),
        /** Adds specified person to specified machine group (factory) as worker. */
        add: async (machineGroupId: number, worker/*: IWorkerAddition*/) => await request.post(`/api/MachineGroups/${machineGroupId}/Workers`, worker)
    },
    // WorkPermits
    workPermits: {
        /** Gets work permit with specified ID. */
        fetch: async (id: number) => await request.get(`/api/WorkPermits/${id}`),
        /** Gets basic information of specified work permit. */
        fetchLite: async (id: number) => await request.get(`/api/WorkPermits/${id}/lite`),
        /** Gets details of specified work permit. */
        fetchDetails: async (id: number) => await request.get(`/api/WorkPermits/${id}/details`),
        /** Gets extra data of specified work permit. */
        fetchExtraData: async (id: number) => await request.get(`/api/WorkPermits/${id}/extraData`),
        /** Gets all available workpermit forms */
        fetchForms: async (machineGroupId: number) => await request.get(`/api/MachineGroups/${machineGroupId}/WorkPermitGroups`),
        /** Gets fields with values of specified work permit. */
        fetchFields: async (id: number) => await request.get(`/api/WorkPermits/${id}/fields`),
        /** Gets only fields of specified work permit. */
        fetchOnlyFields: async (id: number) => await request.get(`/api/WorkPermits/${id}/onlyFields`),
        /** Gets update view fields and values for a work permit */
        fetchUpdate: async (id: number) => await request.get(`/api/WorkPermits/${id}/edit`),
        /** Adds a new work permit */
        add: async (data: any) => await request.post(`/api/WorkPermits`, data),
        /** Update existing work permit */
        update: async (id: number, data: any) => await request.put(`/api/WorkPermits/${id}`, data),
    },
    // NotificationTargets
    notificationTargets: {
        /** Gets notification targets for workCard. */
        fetch: async (workCardId: number) => await request.get(`/api/NotificationTargets/${workCardId}`),
        /** Gets all notification targets. */
        fetchAll: async () => await request.get(`/api/NotificationTargets`),
        /** Adds new notification target for workCard. */
        add: async (notificationTarget: INotificationTarget) => await request.post(`/api/NotificationTargets`, notificationTarget),
        /** Updates notification target. */
        patch: async (id: number, value: string) => await request.patch(`/api/NotificationTargets/${id}`, value),
        /** Delets notification target. */
        delete: async (id: number) => request.delete(`/api/NotificationTargets/${id}`)
    },
    // MachineRequirements
    machineRequirements: {
        /** Gets specified machine requirement. */
        fetch: async (machineRequirementId: number) => await request.get(`/api/MachineRequirements/${machineRequirementId}`),
        /** Gets all machine requirements. */
        fetchAll: async () => await request.get<ISearchData<IMachineRequirement>>(`/api/MachineRequirements`)
    },
    // SalaryCategories
    salaryCategories: {
        /** Gets all salary categories. */
        fetchAll: async () => await request.get<ISalaryCategoryLite[]>(`/api/SalaryCategories`),
        /** Gets all salary categories by user. */
        fetchAllByUser: async (id: number, machineGroupId: number) => await request.get<ISalaryCategoryLite[]>(`/api/MachineGroup/${machineGroupId}/People/${id}/SalaryCategories`),
        /** Gets all salary categories by person group. */
        fetchAllByPersonGroup: async (id: number) => await request.get<ISalaryCategoryLite[]>(`/api/PersonGroup/${id}/SalaryCategories`)
    },
    // InvestmentCodes
    investmentCodes: {
        fetchAll: async (machineGroupId: number) => await request.get(`api/MachineGroups/${machineGroupId}/InvestmentCodes`)
    },
    // PersonGroupAppSettings
    personGroupAppSettings: {
        /** Gets all PersonGroupAppSettings with specified group and current user. */
        fetchAllByUserAndGroup: async (group: string, machineGroupId: number) => await request.get<IPersonGroupAppSettingLite[]>(`/api/MachineGroup/${machineGroupId}/PersonGroupAppSettings/${group}`),
        fetchMainMenuItems: async (machineGroupId: number, field: string) => await request.get(`/api/MachineGroup/${machineGroupId}/PersonGroupAppSettings/MainMenuItems/${field}`)
    },
    // Searches
    searches: {
        /** Gets search with specified ID. */
        fetch: async (id: number) => await request.get<ISearch>(`/api/Searches/${id}`),
        /** Adds specified search */
        addSearch: async (searchData: ISearchAddition) => await request.post(`/api/Searches`, searchData),
        /** Updates search with specified ID. */
        updateSearch: async (searchId: number, searchData: ISearchUpdate) => await request.put(`/api/Searches/${searchId}`, searchData),
        /** Deletes search with specified ID. */
        deleteSearch: async (searchId: number) => await request.delete(`/api/Searches/${searchId}`),
        /** Gets searches with matching parameters */
        fetchSearches: async (params) => await request.get<ISearch[]>('/api/Searches/', params)
    },
    // MeasurementGroups
    measurementGroups: {
        /** Gets all measurement groups by machine group id. */
        fetchAll: async (machineGroupId) => await request.get<IMeasurementGroup[]>(`/api/Measurements/Groups/MachineGroup/${machineGroupId}`),
        /** Links measurement group with work card. */
        addWorkCardLink: async (measurementGroupWorkCard: IMeasurementGroupWorkCardAddition) => await request.post(`/api/Measurement/Groups/WorkCard`, measurementGroupWorkCard)
    },
    // MeasurementGroupWorkCards
    measurementGroupWorkCards: {
        /** Gets measurements of the work card. */
        fetchAllByWorkCard: async (workCardId: number) => await request.get<IMeasurementGroupWorkCard[]>(`/api/Measurements/WorkCards/${workCardId}`),
        /** Adds measurement group work card. */
        add: async (measurementGroupWorkCard: IMeasurementGroupWorkCardAddition) => await request.post(`/api/Measurements/WorkCards/`, measurementGroupWorkCard),
        /** Deletes measurement group work card. */
        delete: async (measurementGroupId: number, workCardId: number) => await request.delete(`/api/Measurements/${measurementGroupId}/WorkCards/${workCardId}`)
    },
    // MeasurementQuestions
    measurementQuestions: {
        getByGroup: async (measurementGroupId: number) => await request.get<IMeasurementQuestion[]>(`/api/Measurements/Groups/${measurementGroupId}/Questions`)
    },
    // MeasurementValues
    measurementValues: {
        /** Gets all measurement values by measurement group and work card */
        fetchAllByGroupAndWorkCard: async (measurementGroupId: number, workCardId: number) => await request.get<IMeasurementValue[]>(`/api/Measurements/Groups/${measurementGroupId}/WorkCards/${workCardId}`),
        /** Adds measurement value. */ 
        add: async (measurementValue: IMeasurementValueAddition) => await request.post(`/api/Measurements/Values`, measurementValue),
        /** Updates measurement value. */
        update: async (id: number, measurementValue: IMeasurementValueUpdate) => await request.patch(`/api/Measurements/Values/${id}`, measurementValue)
    }
}

export default noviAPI;
