import React from 'react';
import LinkPanelContainer from "./LinkPanelContainer";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const MachineTreeSingleMachine = ({ machine, machineSelect, onMachineSelect, targetData }) => {

    let linkToChildren;

    const itemId = machine.machineId;

    if (machineSelect) {
        const { target, action, targetId, itemStatus } = targetData;

        linkToChildren = action === 'new'
            ? '/' + target + '/' + action + '/' + (target == "workcard" ? ((itemStatus == 'newWorkCard' ? 'workcard' : 'faultnotice') + '/') : '') + machine.id
            : '/' + target + '/' + targetId + '/' + action + '/' + machine.id;
    } else {
        linkToChildren = '/machines/' + machine.id;
    }

    const hasChildren = machine.childrenIds && machine.childrenIds.length > 0 ? true : false

    let customLinkToChildren;
    if (hasChildren && machineSelect) {
        const locationState = targetData.itemStatus
            ? {
                referrer: targetData.referrer,
                cardType: targetData.itemStatus
            }
            : { referrer: targetData.referrer }

        customLinkToChildren = hasChildren && machineSelect
            ? (
                <Link
                    to={{
                        pathname: linkToChildren,
                        state: locationState
                    }}
                    style={{ color: '#fff' }}
                    replace
                >
                    <span className="panel-arrow float-right">
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                </Link>
            )
            : null;
    }

    return (
        <LinkPanelContainer
            title={machine.machineCode ? machine.machineCode + ' / ' + machine.name : machine.name}
            hasChildren={hasChildren}
            hasView={machine.machineCode}
            handleClick={onMachineSelect}
            linkTo={machineSelect || machine.hasOwnProperty('parent_id') ? null : itemId && '/machine/' + itemId}
            customLinkToChildren={customLinkToChildren}
            linkToChildren={linkToChildren}
            item={machine}
            linkState={{ hierarchyItem: machine }}
        />
    );
}

export default MachineTreeSingleMachine;