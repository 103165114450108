import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';
import '../../../styles/global.scss';
import './../styles/navigation.scss';
import NavTitle from './NavTitle';
import QuickSearch from './QuickSearch';
import Search from './Search';
import WorkCardFiltering from '../../work-schedule/work-list/components/WorkCardFiltering';
import MachineFiltering from '../../machines/components/MachineFiltering';
import SparePartFiltering from '../../warehouse/components/SparePartFiltering';
import { DetermineBackgroundColor, DefineParametersByQuickSearch, TruncateString } from '../../../components/HelperFunctions';
import { fetchMachines, setMachinesSettings } from '../../machines/actions';
import { fetchOperatorMaintenances } from '../../operator-maintenance-list/actions';
import TreeMenu, { ItemComponent } from 'react-simple-tree-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareRight, faCaretSquareDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import HourCardFiltering from '../../hour-cards/components/HourCardFiltering';
import { setQuickSearch } from '../../../commonActions/actions';
import i18n from 'translations/i18n';
import { SortMenu } from './SortMenu';
import { SortTypes } from "constants/quickSearches/SortTypes";
import { StorageProps, sortTypeKey, withStorage } from 'hooks/useLocalStorage';

type SceneData = {
    quickSearchData: QuickSearchData;
    titles: Titles;
    filters: Filters;
    location;
}

type QuickSearchData = {
    filters: boolean;
    fetchData: string;
    quickSearchOptions: any[];
    quickSearch;
    handleQuickSearch: (value) => void;
}

type Titles = {
    title?: string;
    thumbnail?: string;
    label?: string;
    subPhase?: string;
    subPhaseLabel?: string;
    itemColors?: IColor[];
    onThumbnailClick?: () => void;
}

type Filters = {
    current;
    count: number;
    noFilters: boolean;
}

type IProps = StorageProps & PropsFromRedux & {
    updateSortType: (newType: IOptionType) => void;
    sceneData: SceneData;
}

interface IState {
    dialogOpen: boolean;
    quickSearchHierarchyOpen: boolean;
    savingPromptOpen: boolean;
    sortTypes: object;
    wrapperRef: any;
    quickSearchMenuOpen: boolean;
}

class NavHeader extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            quickSearchHierarchyOpen: false,
            savingPromptOpen: false,
            sortTypes: this.props.getStorageSetting(sortTypeKey),
            wrapperRef: null,
            quickSearchMenuOpen: false
        }
    }

    setWrapperRef = node => {
        this.setState({ wrapperRef: node });
    }

    getSortOptions = () => {
        const screen = this.props.sceneData.location.pathname.substring(1);
        return SortTypes[screen];
    }

    getSortType = () => {
        const screen = this.props.sceneData.location.pathname.substring(1);
        const defaultValue = { ...SortTypes.worklist.NEWEST, value: SortTypes.worklist.NEWEST.label };
        return this.state.sortTypes?.[screen] ?? defaultValue;
    }

    updateSortType = (newType) => {
        const screen = this.props.sceneData.location.pathname.substring(1);
        const newTypes = {
            ...this.state.sortTypes,
            [screen]: newType
        };
        this.props.setStorageSetting(sortTypeKey, newType, screen);
        this.setState({ sortTypes: newTypes });
        this.props.updateSortType?.(newType);
    }

    handleQuickSearch = (selectedQuickSearch) => {
        if (selectedQuickSearch.value < 1) {
            return;
        }
        this.setState({ quickSearchHierarchyOpen: false });
        const location = this.props.sceneData.location.pathname.substring(1);

        this.props.setQuickSearch(selectedQuickSearch, location);
    }

    machineQuickSearch = (type) => {
        if (type.value < 1) { return; }

        const { quickSearchData, filters } = this.props.sceneData;
        if (!quickSearchData.fetchData) { return; }

        this.toggleHierarchySelect(true);
        let params = {};

        if (!type.isSqlSearch) {
            const quickSearchValue = type.value !== undefined ? type.value : type.id;

            params = DefineParametersByQuickSearch(
                'machine',
                quickSearchValue,
                null
            );
        }
        params['quickSearch'] = type.isSqlSearch ? { value: type.value, label: type.label } : type;
        params['pageNumber'] = 0;
        params['startIndex'] = 0;
        params['endIndex'] = 3;
        params['isSqlSearch'] = type.isSqlSearch ? true : false;
        if (!params['pageSize']) params['pageSize'] = filters.current.pageSize;

        this.props.setMachinesSettings({ useDefaultSearch: false });
        this.props[quickSearchData.fetchData](params);
    }

    toggleFiltersDialog = () => {
        this.setState(state => ({ dialogOpen: !state.dialogOpen }));
    }

    closeFiltersDialog = () => {
        this.setState({ dialogOpen: false });
    }

    toggleHierarchySelect = (close = false) => {
        this.setState(state => ({ quickSearchHierarchyOpen: close ? false : !state.quickSearchHierarchyOpen }))
    }

    toggleNode = (props) => {
        props.toggleNode();
    }

    getFilteringComponent = (viewType, closeFiltersDialog, filters, dialogOpen) => {
        switch (viewType) {
            case 'worklist':
                return (
                    <WorkCardFiltering
                        closeDialog={closeFiltersDialog}
                        filters={filters}
                        dialogOpen={dialogOpen}
                    />
                );
            case 'machines':
                return (
                    <MachineFiltering
                        closeDialog={closeFiltersDialog}
                        dialogOpen={dialogOpen}
                    />
                );
            case 'warehouse':
                return (
                    <SparePartFiltering
                        closeDialog={closeFiltersDialog}
                        dialogOpen={dialogOpen}
                    />
                );
            case 'hourcard':
                return (
                    <HourCardFiltering
                        closeDialog={closeFiltersDialog}
                        dialogOpen={dialogOpen}
                    />
                )
            default:
                return null;
        }
    }


    //TODO: refactoring / general component
    getHierarchyDataStructure = (currentQuickSearch) => {
        const { quickSearchData } = this.props.sceneData;
        let hierarchyData = {};

        if (quickSearchData.quickSearchOptions) {
            quickSearchData.quickSearchOptions.forEach((qSearchGroup, i) => {
                let activeChildOption = false;

                const getChildNodes = (data) => {
                    let childNodes = {};

                    if (!data.options || (data.options && data.options.length === 0)) { return childNodes }

                    data.options.forEach((option, i) => {
                        const qSearchValue = option.value;
                        const qSearchLabel = option.label;

                        if ((currentQuickSearch.value === qSearchValue && currentQuickSearch.label === qSearchLabel)
                            || (currentQuickSearch.isSavedSearch && currentQuickSearch.value === qSearchValue && option.searchParameters)
                            || (currentQuickSearch.isSqlSearch && currentQuickSearch.value === qSearchValue && option.isSqlSearch)
                        ) {
                            activeChildOption = true;
                        }

                        childNodes['option-' + i] = {
                            label: qSearchLabel,
                            index: i,
                            nodes: getChildNodes(option),
                            active: true,
                            value: qSearchValue,
                            isSqlSearch: option.isSqlSearch,
                            isSavedSearch: option.searchParameters ? true : false,
                            activeChildOption: option.options && option.options.length > 0 ? activeChildOption : false
                        }
                    })

                    return childNodes;
                }

                hierarchyData['search-group-' + i] = {
                    label: qSearchGroup.label,
                    index: i,
                    nodes: getChildNodes(qSearchGroup),
                    value: qSearchGroup.value,
                    isSqlSearch: qSearchGroup.isSqlSearch,
                    isSavedSearch: qSearchGroup.isSavedSearch,
                    activeChildOption: activeChildOption
                };
            });
        }

        return hierarchyData;
    }

    isItemActive = (propsData, currentQuickSearch) => {
        return (propsData.value === currentQuickSearch.value && propsData.label === currentQuickSearch.label)
            || propsData.activeChildOption
            || (currentQuickSearch.isSavedSearch && currentQuickSearch.value === propsData.value && propsData.isSavedSearch)
            || (currentQuickSearch.isSqlSearch && currentQuickSearch.value === propsData.value && propsData.isSqlSearch);
    }

    toggleMenu = (closeMenu = false) => {
        this.setState(state => ({ quickSearchMenuOpen: closeMenu === true ? false : !state.quickSearchMenuOpen }))
    }

    handleSelect = (value) => {
        const { location } = this.props.sceneData;
        if (location.pathname === '/warehouse') {
            this.props.sceneData.quickSearchData.handleQuickSearch(value)
        } else if (location.pathname === '/worklist') {
            this.handleQuickSearch(value)
        } else {
            this.machineQuickSearch(value)
        }

        this.setState({
            quickSearchMenuOpen: false
        })
    }

    render() {
        const { quickSearchData, titles, filters, location } = this.props.sceneData;
        const { dialogOpen, quickSearchHierarchyOpen, quickSearchMenuOpen } = this.state;
        const itemColor = DetermineBackgroundColor(titles.itemColors);

        const currentQuickSearch = quickSearchData.quickSearch !== null ? quickSearchData.quickSearch : -999;
        const isWorkList = location.pathname === "/worklist";
        const hasExtendedSearch = filters.current && !Array.isArray(filters.current) && !filters.noFilters;

        return (
            <div className="nav-header" style={{ borderTopColor: itemColor }}>
                <Container>
                    <Row className="header-container">
                        <Col xs={quickSearchData && quickSearchData.filters ? 3 : hasExtendedSearch ? 10 : 12}>
                            <NavTitle titleProps={titles} />
                        </Col>
                        {quickSearchData.filters && <Col xs={((!filters.current || filters.noFilters) ? 9 : 7) - (isWorkList ? 2 : 0)}>
                            <QuickSearch
                                data={quickSearchData}
                                filters={filters}
                                toggleHierarchySelect={this.toggleHierarchySelect}
                                handleSelect={this.handleSelect}
                                toggleMenu={this.toggleMenu}
                                setWrapperRef={this.setWrapperRef}
                                menuOpen={quickSearchMenuOpen}
                            />
                        </Col>}
                        {filters.current && isWorkList &&
                            <Col xs={2}>
                                <SortMenu
                                    setWrapperRef={this.setWrapperRef}
                                    sortType={this.getSortType()}
                                    options={this.getSortOptions()}
                                    onChange={this.updateSortType}
                                />
                            </Col>
                        }
                        {hasExtendedSearch && <Col xs={2}>
                            <Search
                                filters={filters}
                                toggleFiltersDialog={this.toggleFiltersDialog}
                            />
                        </Col>}
                    </Row>
                    {/* Quicksearches hierarchy part - todo: own component? */}
                    {quickSearchHierarchyOpen && <div>
                        <div className="overlay-canvas" onClick={() => this.toggleHierarchySelect(true)} ></div>
                        <div className="tree-hierarchy-container bordered-field" ref={this.setWrapperRef}>
                            <TreeMenu
                                data={this.getHierarchyDataStructure(currentQuickSearch)}
                                onClickItem={this.handleQuickSearch}
                            >
                                {({ search, items }) => (
                                    <div>
                                        <ul className="tree-list">
                                            {items.map(({ key, ...props }) => {
                                                const propsData = { ...props };

                                                if (props.label === i18n.t("WAREHOUSES")) {
                                                    return null;
                                                }

                                                // truncate quicksearch label
                                                props.label = TruncateString(props.label, 35);

                                                const isActive = this.isItemActive(propsData, currentQuickSearch);

                                                return <div key={key} className="tree-item" style={{ marginLeft: propsData.level > 0 ? (propsData.level + '0px') : '0' }}>
                                                    <FontAwesomeIcon
                                                        icon={propsData.isOpen ? faCaretSquareDown : faCaretSquareRight}
                                                        size="lg"
                                                        className={'icon-primary ' + (propsData.hasNodes ? '' : 'invisible')}
                                                        onClick={() => this.toggleNode(propsData)}
                                                    />
                                                    <div className="option-label-row">
                                                        {((propsData.value === currentQuickSearch.value && propsData.label === currentQuickSearch.label)
                                                            || propsData.value === currentQuickSearch.value && (currentQuickSearch.isSqlSearch || currentQuickSearch.isSavedSearch)
                                                        ) && <FontAwesomeIcon
                                                                icon={faCaretRight}
                                                                className={'icon-primary'}
                                                            />}
                                                        <ItemComponent
                                                            key={key}
                                                            {...props}
                                                            active={isActive}
                                                        />
                                                    </div>
                                                </div>
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </TreeMenu>
                        </div>
                    </div>}
                </Container>
                {filters.current && <div className="no-paddings">
                    {this.getFilteringComponent(titles.label, this.closeFiltersDialog, filters.current, dialogOpen)}
                </div>}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMachines,
    fetchOperatorMaintenances,
    setMachinesSettings,
    setQuickSearch
}, dispatch);

const connector = connect(null, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStorage(NavHeader));
