import React from 'react';
import { Alert } from 'react-bootstrap';
import i18n from 'translations/i18n';

const ErrorMessage = (props: IProps) => {
    if (props.show === false) {
        return (<React.Fragment></React.Fragment>)
    }

    return (
        <Alert variant="danger">
            {i18n.t('SOMETHING_WENT_WRONG')}
        </Alert>
    )
}

interface IProps {
    show?: boolean;
}

export default ErrorMessage;