import React from 'react';
import FieldLabel from './FieldLabel';

const NumberField = (props: IProps) => {
    const getFieldLabel = () => {
        return (
            <FieldLabel required={props.required}>
                {props.label}
            </FieldLabel>
        );
    }

    return (
        <React.Fragment>
            {props.label && getFieldLabel()}
            <input
                type="number"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                min={props.min ?? ''}
                disabled={props.disabled || false}
            />
        </React.Fragment>
    )
}

interface IProps {
    name: string;
    value: React.ReactText;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    label?: string;
    min?: React.ReactText;
    required?: boolean;
}

export default NumberField;