import * as types from '../../../config/actionTypes';
import { HandleError, isNullOrWhitespace } from '../../../components/HelperFunctions';
import noviAPI from '../../../api/noviAPI';
import { dateDiffToDate, defineQuickSearchParams, addSortingParams } from './utils';

/**
 * Action for fetching workcards and route workcards
 * */
export const getWorkCards = (sortType) => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${types.FETCH_WORK_CARDS}_PENDING`
        });

        let data: ISearchData<IWorkCard>;
        const filters = getState().workcards.currentFilters;
        const workListSettings = getState().workcards.settings;
        const machineGroupId = getState().settings.machineGroupId;
        const details = getState().workcards.details;

        // Define the search params that are used always
        let searchParams = new URLSearchParams();
        searchParams.append('PageNumber', `${workListSettings.pageNumber - 1}`);
        searchParams.append('PageSize', `${workListSettings.pageSize}`);
        searchParams.append('MachineGroupId', `${machineGroupId}`);

        // Ordering parameters
        addSortingParams(searchParams, sortType);

        try {
            if (workListSettings.useDefaultSearch) {
                if (workListSettings.cardlayout) {
                    const response = await noviAPI.workCards.defaultCardModelSearch(searchParams);
                    data = response.data;
                } else {
                    const response = await noviAPI.workCards.defaultSearch(searchParams);
                    data = response.data;
                }
            }
            else {
                const filterValues = ([_key, value]) => _key == 'extraDatas' || (value !== null && (value.length > 0 || value.hasOwnProperty('value')));

                // Set more url search params based on current filters
                Object.entries(filters)
                    // Filter valid values
                    .filter(filterValues)
                    .forEach(([key, value]) => {
                        // Filters that can have many values
                        if (Array.isArray(value)) {
                            const array = value;
                            /* Check if the array has objects with property 'value' because
                             * visible input fields in extended search save filters from multiselect 
                             * inputs as an array of objects like so [{ value, label },...]
                             * */
                            if (array.every(i => typeof i === 'object') && array.every(i => i.hasOwnProperty('value'))) {
                                array.forEach(i => {

                                    if (key.includes("machine_costpoolgroup")) {
                                        searchParams.append("MachineCostpoolIds", i.value.toString())
                                    }
                                    else if (key.includes("costpoolgroup")) {
                                        searchParams.append("WorkCardCostpoolIds", i.value.toString())
                                    }
                                    else if (details.find(x => x.group == key)) {
                                        searchParams.append("DetailIds", i.value.toString())
                                    }
                                    else {
                                        searchParams.append(key, i.value.toString())
                                    }

                                });
                            }
                            /* Check if the array is an array of numbers because the filters 
                             * like machineIds (used for work history for example) do not
                             * have a visible input fields in extended search which means 
                             * that the IDs can be saved in array of number instead of objects.
                             */
                            else if (array.every(i => typeof i === 'number')) {
                                array.forEach(i => {
                                    searchParams.append(key, `${i}`)
                                });
                            }
                        }
                        // Filters with date values
                        else if (key === 'startDate' || key === 'endDate') {
                            searchParams.append(key, value);
                        }
                        // Filters with single object
                        else if (value.hasOwnProperty('value')) {
                            searchParams.append(key, `${value.value}`);
                        }
                        else if (key == "extraDatas") {
                            var extraDatasObj = Object.entries(value).reduce((a, [_key, _value]) => {
                                return isNullOrWhitespace(`${_value}`) ? a : Object.assign({}, a, { [_key]: [_value] })
                            }, {});
                            if (Object.keys(extraDatasObj).length > 0)
                                searchParams.append("ExtraDatasWithGroup", JSON.stringify(extraDatasObj))
                        }
                        // The rest of the filters with string value
                        else {
                            searchParams.append(key, value);
                        }
                    });

                const routeMaintenanceEnabled = getState().settings.noviConfigs.RouteMaintenancesEnabled;

                if (workListSettings.cardlayout) {
                    const response = await noviAPI.workCards.searchWorkCardCardsCombined(searchParams);
                    data = response.data;
                } else {
                    if (routeMaintenanceEnabled === 'True') {
                        const response = await noviAPI.workCards.searchCombined(searchParams);
                        data = response.data;
                    } else {
                        const response = await noviAPI.workCards.search(searchParams);
                        data = response.data;
                    }
                }
            }

            dispatch(fetchWorkCardsFulfilled(data));
        } catch (error) {
            console.error(error);
            HandleError(error, 'Fetch work cards');

            dispatch({
                type: `${types.FETCH_WORK_CARDS}_REJECTED`,
            })
        }
    }
);

/**
 * Action for fetching workcards by quick search
 * */
export const quickSearchWorkCards = (sortType) => (
    async (dispatch, getState: () => State) => {
        dispatch({ type: `${types.FETCH_WORK_CARDS}_PENDING` });

        try {
            const { settings } = getState();
            const { machineGroupId, searches } = settings;
            const { settings: workListSettings } = getState().workcards;
            const { worklist: worklistSearch } = getState().navigation.activeQuickSearches;
            const details = getState().workcards.details;
            const quickSearchId = worklistSearch.value;
            const isSqlSearch = worklistSearch.isSqlSearch;
            const isSavedSearch = worklistSearch.isSavedSearch;
            const routeMaintenanceEnabled = settings.noviConfigs.RouteMaintenancesEnabled;
            let data: ISearchData<IWorkCard>;

            // Define the search params
            let searchParams = new URLSearchParams();
            searchParams.append('PageNumber', `${workListSettings.pageNumber - 1}`);
            searchParams.append('PageSize', `${workListSettings.pageSize}`);
            
            // Ordering parameters
            addSortingParams(searchParams, sortType);

            if (workListSettings.useDefaultSearch) {
                if (workListSettings.cardlayout) {
                    const response = await noviAPI.workCards.defaultCardModelSearch(searchParams);
                    data = response.data;
                } else {
                    const response = await noviAPI.workCards.defaultSearch(searchParams);
                    data = response.data;
                }
            }
            // Search by quick search id (these quick searches are saved in db as sql queries)
            else if (isSqlSearch) {
                if (workListSettings.cardlayout) {
                    const response = await noviAPI.quickSearches.fetchCardModelResults<IWorkCard>(machineGroupId, quickSearchId, searchParams);
                    data = response.data;
                } else {
                    const response = await noviAPI.quickSearches.fetchResults<IWorkCard>(machineGroupId, quickSearchId, searchParams);
                    data = response.data;
                }
            }
            else if (isSavedSearch) {
                const currentSearch = searches.workCardSearches.find(search => search.id === quickSearchId);
                const filterParams: IWorkListSearchParams = JSON.parse(currentSearch.searchParameters);

                Object.keys(filterParams)
                    .forEach(key => {
                        const filterParam = filterParams[key];
                        if (Array.isArray(filterParam)) {
                            filterParam.forEach(i => {
                                if (key.includes("machine_costpoolgroup")) {
                                    searchParams.append("MachineCostpoolIds", i.value.toString())
                                }
                                else if (key.includes("costpoolgroup")) {
                                    searchParams.append("WorkCardCostpoolIds", i.value.toString())
                                }
                                else if (details.find(x => x.group == key)) {
                                    searchParams.append("DetailIds", i.value.toString())
                                }
                                else if (typeof i === 'number') {
                                    searchParams.append(key, i.toString())
                                }
                                else {
                                    searchParams.append(key, i.value.toString())
                                }
                            });
                        } else {
                            let value = filterParam;
                            if (key == "extraDatas") {
                                var extraDatasObj = Object.entries(value).reduce((a, [_key, _value]) => {
                                    return isNullOrWhitespace(`${_value}`) ? a : Object.assign({}, a, { [_key]: [_value] })
                                }, {});
                                if (Object.keys(extraDatasObj).length > 0)
                                    searchParams.append("ExtraDatasWithGroup", JSON.stringify(extraDatasObj))
                            }
                            else if (!isNullOrWhitespace(value)) {
                                if (key === 'startDate' || key === 'endDate') {
                                    value = dateDiffToDate(value);
                                }
                                searchParams.append(key, `${value}`);
                            }
                        }
                    });

                // Add machine group id to search params
                searchParams.append('MachineGroupId', `${machineGroupId}`);

                if (workListSettings.cardlayout) {
                    const response = await noviAPI.workCards.searchWorkCardCardsCombined(searchParams);
                    data = response.data;
                } else {
                    if (routeMaintenanceEnabled === 'True') {
                        const response = await noviAPI.workCards.searchCombined(searchParams);
                        data = response.data;
                    } else {
                        const response = await noviAPI.workCards.search(searchParams);
                        data = response.data;
                    }
                }
            }
            // Search by a default quick search
            else {
                let workCardSettings = getState().workcards.workcardsSettings;

                if (Object.keys(workCardSettings).length === 0) {
                    const response = await noviAPI.workCardSettings.fetchAll();
                    workCardSettings = response.data;
                    dispatch(setWorkCardSettings(workCardSettings))
                }

                // Define functions to append search params
                const quickSearchParams = defineQuickSearchParams(quickSearchId, settings, workCardSettings, workListSettings, false, sortType);

                if (workListSettings.cardlayout) {
                    const response = await noviAPI.workCards.searchWorkCardCardsCombined(quickSearchParams);
                    data = response.data;
                } else {
                    if (routeMaintenanceEnabled === 'True') {
                        const response = await noviAPI.workCards.searchCombined(quickSearchParams);
                        data = response.data;
                    } else {
                        const response = await noviAPI.workCards.search(quickSearchParams);
                        data = response.data;
                    }
                }
            }

            dispatch(fetchWorkCardsFulfilled(data));
        } catch (error) {
            HandleError(error, 'Quicksearch work cards');

            dispatch({
                type: `${types.FETCH_WORK_CARDS}_REJECTED`,
                payload: error
            });
        }
    }
);


type DefaultSearchParams = {
    pageNumber?: number;
    pageSize?: number;
    startIndex?: number;
    endIndex?: number;
}

export type DefaultSearch = (params: DefaultSearchParams, sortType: any) => void;
export const defaultSearch: DefaultSearch = (params = null, sortType) => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_WORKCARDS}_PENDING`
        });
        try {
            const pageNumber = params?.pageNumber !== undefined ? params.pageNumber : getState().workcards.pageNumber;
            const pageSize = params?.pageSize !== undefined ? params.pageSize : getState().workcards.settings.itemsPerPage;
            const machineGroupId = getState().settings.machineGroupId.toString();

            const defaultSearchParams = new URLSearchParams();
            defaultSearchParams.append('PageNumber', pageNumber.toString());
            defaultSearchParams.append('PageSize', pageSize.toString());
            defaultSearchParams.append('MachineGroupId', machineGroupId);

            addSortingParams(defaultSearchParams, sortType);

            const { data } = await noviAPI.workCards.defaultSearch(defaultSearchParams);

            dispatch({
                type: `${types.FETCH_WORKCARDS}_FULFILLED`,
                payload: {
                    payloadData: data,
                    pageNumber: pageNumber,
                    startIndex: params?.startIndex !== undefined ? params.startIndex : getState().workcards.currentFilters.startIndex,
                    endIndex: params?.endIndex !== undefined ? params.endIndex : getState().workcards.currentFilters.endIndex,
                    filters: getState().workcards.currentFilters,
                    user: getState().settings.userId,
                    userGroups: getState().settings.userGroups,
                    isSqlSearch: getState().workcards.currentFilters.isSqlSearch
                }
            });
        } catch (error) {
            HandleError(error, 'Default search workcards');

            dispatch({
                type: `${types.FETCH_WORKCARDS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchWorkCardsFulfilled = (payload: ISearchData<IWorkCard>) => ({
    type: `${types.FETCH_WORK_CARDS}_FULFILLED`,
    payload
});

export const setFiltersAction = payload => ({
    type: types.SET_WORK_LIST_FILTERS,
    payload
});

export const resetCurrentFilters = () => (
    dispatch => {
        dispatch({
            type: types.RESET_WORKCARD_CURRENTFILTERS
        })
    }
)

export const setWorkCardDetails = (workCardDetails: IWorkCardDetail[]) => ({
    type: types.SET_WORK_CARD_DETAILS,
    payload: workCardDetails
})

/**
 * Action for fetching workcard details
 */
export const fetchWorkCardDetails = () => (
    async (dispatch, getState) => {
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;
            const { data } = await noviAPI.workCardDetails.fetchAll(machineGroupId)

            dispatch(setWorkCardDetails(data));

        } catch (error) {
            HandleError(error, 'Fetch workcard details');
        }
    }
);

/**
 * Action for fetching workphase details
 *
 * url: /api/MachineGroups/{id}/WorkCards/details
 */
export const fetchWorkPhaseDetails = () => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_WORKPHASE_DETAILS}_PENDING`
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            const { data } = await noviAPI.workPhaseDetails.fetchAll(machineGroupId);

            dispatch({
                type: `${types.FETCH_WORKPHASE_DETAILS}_FULFILLED`,
                payload: data
            })

        } catch (error) {
            HandleError(error, 'Fetch workphase details');
        }
    }
);

export const setWorkCardSettings = (workCardSettings: IWorkcardsSettings) => ({
    type: types.SET_WORK_CARD_SETTINGS,
    payload: workCardSettings
})

/**
 * Action for saving work list settings 
 *
 * @param payload
 */
export const saveWorkListSettings = (payload) => ({
    type: types.SAVE_WORK_LIST_SETTINGS,
    payload
});

/**
 * Action for setting a new page number for the pagination 
 *
 * @param payload
 */
export const setPageNumber = (pageNumber: number) => (
    dispatch => {
        dispatch({
            type: types.SET_WORK_LIST_PAGE_NUMBER,
            payload: pageNumber
        })
    }
);

/**
 * Action for resetting work history 
 */
export const resetWorkHistory = () => (
    dispatch => {
        dispatch({ type: types.RESET_WORK_HISTORY });
    }
);
