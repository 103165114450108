import React from 'react';
import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../translations/i18n';
import { DetermineBackgroundColor } from '../../../components/HelperFunctions';
import PopoverAction from 'components/PopoverAction';

interface Props {
    title: any;
    linkTo?: string;
    linkToChildren?: string;
    property?: string;
    hasChildren?: boolean;
    hasView?: boolean;
    handleClick?: any;
    itemCount?: React.ReactNode;
    customLinkToChildren?: JSX.Element;
    item?: any;
    itemDetails?: any[any];
    linkState?: any;
    popOverActions?: any[];
    handlePopoverClick?: any;
}

class LinkPanelContainer extends React.Component<Props> {
    render() {
        const {
            title,
            hasChildren,
            hasView,
            linkTo = null,
            linkToChildren,
            customLinkToChildren = null,
            property,
            itemCount = 0,
            handleClick,
            item,
            itemDetails,
            linkState,
            popOverActions = [],
            handlePopoverClick,
        } = this.props;

        let linkToChildrenElement;
        if (hasChildren && customLinkToChildren === null) {
            linkToChildrenElement =
                <Link to={{ pathname: linkToChildren, state: linkState }}>
                    <span className="panel-arrow float-right">
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                </Link>
        } else if (hasChildren && customLinkToChildren !== null) {
            linkToChildrenElement = customLinkToChildren;
        }

        const isLevel = item && item.levelId ? true : false;
        const definedColor = item && item.colors ? DetermineBackgroundColor(item.colors) : null;

        const getMenu = (item) => {
            const children = popOverActions.map((action, index) => {
                return <div key={index}>
                    <PopoverAction
                        icon={action.icon}
                        label={action.label}
                        paClassName={''}
                        onClick={() => handlePopoverClick(action.action, item)}
                    />
                    {popOverActions.length > index + 1 && <hr />}
                </div>
            });
            return (<Popover id="settings-menu-popover" className="panel-menu-popover">
                {React.Children.map(children, (child, i) => (
                    <div className="sub-section" key={`setting_${i}`}>
                        <div className="setting-option">
                            {child}
                        </div>
                    </div>
                ))}
            </Popover>);
        }

        const getPopOverMenu = () => (
            <div className="options-container link-panel-popover-button" >
                <OverlayTrigger
                    trigger="click"
                    placement="left"
                    overlay={getMenu(item)}
                    rootClose={true}
                >
                    <span className="options-button" >
                        <FontAwesomeIcon icon={faEllipsisV} size="lg" />
                    </span>
                </OverlayTrigger>
            </div>
        );

        const heading =
            (
                <Card.Header className={itemDetails ? 'thick-link-panel' : ''}>
                    <Card.Title style={
                        hasChildren
                            ? {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: 'calc(100% - 42px)',
                            }
                            : {
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        onClick={() => handleClick && handleClick(item, isLevel)}
                    >
                        {linkTo !== null
                            ? <Link className="link-panel" to={{ pathname: linkTo, state: linkState }}>
                                <div style={{ display: 'grid'}}>
                                    <span>{i18n.t(title)}</span>
                                    {itemDetails && <div style={{ display: 'grid' }}>
                                        {itemDetails.map((detail, i) => (
                                            <span key={i} className="small">{detail.label}: {detail.value}</span>)
                                        )}
                                    </div>}
                                </div>
                            </Link>
                            : title
                        }
                        {property && !linkTo.includes('workphase') && <span className="number-badge">
                            {itemCount}
                        </span>}
                    </Card.Title>
                    {linkToChildrenElement}
                    {popOverActions.length > 0 && getPopOverMenu()}
                </Card.Header>
            )

        return (
            <Card
                key={title}
                className={!hasView
                    ? 'panel-no-children'
                    : definedColor
                        ? 'link-border'
                        : 'link-border-blue'
                }
                style={definedColor && { color: definedColor }}
            >
                {heading}
            </Card>
        );
    }
}

export default LinkPanelContainer;