import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../../translations/i18n';
import { DetermineBackgroundColor, GetTypeTranslationKey, DefineColors } from '../../../../components/HelperFunctions';
import TextTruncate from 'react-text-truncate';
import { connect } from 'react-redux';
import { SwipeableCard } from 'components/SwipeableCard';

const mapColorData = (colors, colorSettings, colorConfig) => {
    return {
        colors,
        settings: colorSettings,
        config: colorConfig['FillPhaseTags']
    }
}

const PhaseComponent = ({ phase, viewSettings, settings = null, workCardId, swipeRef, history, ...props }) => {
    // phase.colors comes from WorkList so if work cards are shown somewhere else define colors here.
    const colorDataMap = mapColorData(props.colors, props.colorSettings, props.colorConfig);
    const colors = phase.colors || DefineColors(phase, colorDataMap)
    const itemColor = DetermineBackgroundColor(colors);

    return (
        <Col className="card phaselayout" key={phase.id}>
            <Card className="workphase-container margin-top-15 no-margin-bottom" style={{ borderTopColor: itemColor }}>
                {viewSettings.find(fieldItem => fieldItem.field === 'procedure')
                    && <Card.Header>
                        <p>{phase.procedure || i18n.t('WORKPHASE') + ' #' + phase.id}</p>
                    </Card.Header>
                }
                <SwipeableCard 
                    mainComponent={
                        <Card.Body>
                            {viewSettings.find(fieldItem => fieldItem.field === 'workstatus') && settings && <Row className="margin-bottom-5">
                                <Col xs={4}><span className="sub-header-light">{i18n.t('WORK_STATUS')}</span></Col>
                                <Col xs={8}>{phase.statusLabel ? i18n.t(phase.statusLabel) : ''}</Col>
                            </Row>}
                            {viewSettings.find(fieldItem => fieldItem.field === 'instructions') && <Row className="margin-bottom-5">
                            <Col xs={4}><span className="sub-header-light">{i18n.t('INSTRUCTIONS')}</span></Col>
                                <Col xs={8}>
                                    <TextTruncate line={3}
                                        truncateText="…"
                                        text={phase.instructions}
                                    />
                                </Col>
                            </Row>}
                            {viewSettings.find(fieldItem => fieldItem.field === 'workergroup') && <div>
                                <hr />
                                <Row className="margin-bottom-5">
                                    <Col xs={12}><FontAwesomeIcon icon={faUsers} />
                                        {phase.workerGroups && phase.workerGroups.length > 0
                                            ? phase.workerGroups.length > 1
                                                ? <span className="sub-header-light left-margin-5">{phase.workerGroups[0]?.name ? phase.workerGroups[0].name : phase.workerGroups[0]} {i18n.t('AND')} {phase.workerGroups.length - 1} {i18n.t('OTHER_WORKER_GROUPS')}</span>
                                                : <span className="sub-header-light left-margin-5">{phase.workerGroups[0]?.name ? phase.workerGroups[0].name : phase.workerGroups[0]}</span>
                                            : <span className="sub-header-light left-margin-5 cursive">{i18n.t('NO_WORKER_GROUPS')}</span>}
                                    </Col>
                                </Row>
                            </div>}
                            {viewSettings.find(fieldItem => fieldItem.field === 'worker') && <div>
                                <Row className="margin-bottom-5">
                                    <Col xs={12}><FontAwesomeIcon icon={faUser} />
                                        {phase.workers && phase.workers.length > 0
                                            ? phase.workers.length > 1
                                                ? <span className="sub-header-light left-margin-5">{phase.workers[0]?.name ? phase.workers[0].name : phase.workers[0]} {i18n.t('AND')} {phase.workers.length - 1} {i18n.t('OTHER_WORKERS')}</span>
                                                : <span className="sub-header-light left-margin-5">{phase.workers[0]?.name ? phase.workers[0].name : phase.workers[0]}</span>
                                            : <span className="sub-header-light left-margin-5 cursive">{i18n.t('NO_WORKERS')}</span>}
                                    </Col>
                                </Row>
                            </div>}
                        </Card.Body>
                    }
                    
                    leftActions={[]}
                    rightActions={[{
                        callback: () => { 
                            history.push(`/workcard/${workCardId}/workphase/${phase?.id}/edit`);
                        },
                        icon: faPen,
                        label: i18n.t("EDIT")
                    }]}
                    itemRef={swipeRef}
                    customStyle={{
                        marginRight: 0
                    }}
                    config={{
                        fullSwipe: true,
                        threshold: 0.7
                    }}
                />
            </Card>
        </Col>
    );
}

const mapStateToProps = (state: State) => ({
    colors: state.settings.colors,
    colorSettings: state.settings.colorSettings,
    colorConfig: state.settings.colorConfig
})

export default connect(mapStateToProps)(PhaseComponent);