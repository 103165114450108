import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchMachineWorkCards,
    deleteMachine,
    fetchMachine,
    fetchMachineDocuments,
    fetchMachineSparePartWarehouseLinks,
    addMachineDocument,
    deleteMachineDocument,
    fetchMachineSpareParts,
    fetchMachineExtraData,
    fetchMachineDetails,
    fetchWorkHistoryCount,
    fetchMeasurements,
    setWorkHistory,
    fetchMachines,
    removeMachineFromList,
    fetchCounters
} from '../actions';
import { fetchViewSettings, setMachineGroupId, getWorkCardColors, fetchDocumentMetadatas, updateImagePath } from '../../../commonActions/actions';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import '../../../styles/global.scss';
import '../styles/machines.scss';
import './../../work-schedule/work-list/styles/worklist.scss';
import NavigationBar from '../../navigation';
import i18n from '../../../translations/i18n';
import moment from 'moment';
import ConfirmDialogComponent from '../../../components/ConfirmDialogComponent';
import WorkCards from '../../work-schedule/work-list/components/WorkCards';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HasRight, GetFormDataFromViewSettings, InitDialogBodyContent, GetSimplifiedHierarchyPathString, drawLink, HandleError, isNullOrWhitespace } from '../../../components/HelperFunctions';
import FileInput from '../../../components/FileInput';
import { Loader } from '../../../components/Loader';
import MachineSpareParts from './MachineSpareParts';
import { InfoContainer } from '../../../components/InfoContainer';
import TextTruncate from 'react-text-truncate';
import PaginationContainer from '../../../components/PaginationContainer';
import noviAPI from '../../../api/noviAPI';
import settingsAPI from '../../../config/settingsAPI';
import { deleteWorkCardDocument } from '../../work-schedule/work-card/actions';
import FormList from '../../../components/FormList';
import MachineActionMenu from './MachineActionMenu';
import { RouteChildrenProps } from 'react-router-dom';
import SearchResultInfo from '../../../components/SearchResultInfo';
import { setWorkCardSettings } from './../../work-schedule/work-list/actions';
import WorkListSettingsMenu from './../../work-schedule/work-list/components/WorkListSettingsMenu';
import MachineDetails from './MachineDetails';
import { emptySearchData, getInputDateTime } from 'utils';
import { UserRights } from 'constants/userRights';
import ImageModal from 'components/image-modal/ImageModal';
import { DocumentSource } from 'constants/documents/documentSource';

type MatchParams = { machineId; }
type LocationState = { notificationMsg; viewProperty; scrollPosition; noPreviousPage; }
type functions = {
    fetchMachine: (id: number) => Promise<IMachine>;
}
type Props = functions & PropsFromRedux & RouteChildrenProps<MatchParams, LocationState>;

interface IState {
    showDialog: boolean;
    dialogType: number;
    dialogItem: any;
    tabGroupActiveKey: string;
    showFileInput: boolean;
    workListPage: number;
    childMachineDocs: ISearchData<IMachineDocumentLink>;
    machineWcDocs: ISearchData<IMachineDocumentLink>;
    counts: {
        machineOpenWorkCards: number;
        childMachineOpenWorkCards: number;
    };
    showImageModal: boolean;
    loadingMachineImage: boolean;
    machineImageUrl: string;
    dialogOpen: any
    docRequirements: IGlobalSetting[];
    invalidFields: boolean;
    missingMetaDatas: number[];
    showAllPhases: object
}

const moduleSettings = settingsAPI.moduleData.machine;

const dialogTypes = {
    deleteMachine: 1,
    deleteDocument: 2
}

class Machine extends React.Component<Props, IState> {
    docRef = React.createRef<FileInput>();

    constructor(props) {
        super(props);

        const mId = localStorage.getItem('mId');
        if (parseInt(this.props.match.params.machineId, 10) !== parseInt(mId, 10)) {
            localStorage.removeItem('activeMachineTab');
        }

        const storedTabKey = localStorage.getItem('activeMachineTab');

        this.state = {
            showDialog: false,
            dialogType: null,
            dialogItem: null,
            tabGroupActiveKey: storedTabKey || "1",
            showFileInput: false,
            workListPage: 1,
            childMachineDocs: emptySearchData(),
            machineWcDocs: emptySearchData(),
            counts: {
                machineOpenWorkCards: null,
                childMachineOpenWorkCards: null
            },
            showImageModal: false,
            loadingMachineImage: false,
            machineImageUrl: '',
            docRequirements: null,
            invalidFields: false,
            missingMetaDatas: [],
            dialogOpen: true,
            showAllPhases: {}
        }
    }

    componentDidMount() {
        const { match, settings, history, location, countersEnabled } = this.props;

        if (location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }

        }

        const machineId = match.params.machineId;

        this.props.fetchMachine(machineId)
            .then(machine => {
                if (machine) {
                    const mGroupId = machine?.machineGroupId;

                    if ((!location.state || location.state.noPreviousPage) && settings.machineGroupId !== mGroupId) {
                        this.props.setMachineGroupId(mGroupId);
                    }

                    moduleSettings.viewSettings.forEach(viewSetting => {
                        this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType, mGroupId);
                    });

                }
            })
            .catch(error => {
                HandleError(error, "Fetch machine");
            })
        this.props.fetchMachineSpareParts(machineId);
        this.props.fetchMachineSparePartWarehouseLinks(machineId);
        this.props.fetchMachineDetails();
        //TODO: fetch all spare parts linked to machine's workcards (Työkorttien varaosat)
        this.props.fetchMachineExtraData(machineId);

        this.fetchChildMachineDocuments(machineId, 0);
        this.fetchMachineWorkCardDocuments(machineId, 0);
        this.props.fetchMeasurements(machineId);
        
        this.props.fetchWorkHistoryCount(machineId);
        this.fetchChildMachineWorkCardCounts(machineId);

        if (countersEnabled) {
            this.props.fetchCounters(machineId);
        }

        noviAPI.documents.getDocumentRequirements()
            .then(({ data }) => {
                this.setState({ docRequirements: data })
            })

        this.props.fetchDocumentMetadatas();
    }

    componentDidUpdate(prevProps: Props) {
        const { settings, match, location } = this.props;
        const machineId = match.params.machineId;

        if (settings.machineGroupId && settings.machineGroupId != prevProps.settings.machineGroupId) {
            this.props.fetchWorkHistoryCount(match.params.machineId)
        }

        if (this.state.workListPage > 1) {
            const isViewingPage = location.state?.viewProperty ?? false;
            if (!isViewingPage) {
                this.setWorkListPage(1);
            }
        }

        if (this.props.machine && (this.props.machine.imagePath !== prevProps.machine?.imagePath)) {
            this.getMachineImage();
            this.props.fetchMachineDocuments(match.params.machineId);
        }
        if (this.props.countersEnabled && !prevProps.countersEnabled) {
            this.props.fetchCounters(machineId);
        }
    }

    componentWillUnmount() {
        if (this.state.machineImageUrl) {
            URL.revokeObjectURL(this.state.machineImageUrl);
        }
        this.props.removeMachineFromList(Number.parseInt(this.props.match.params.machineId));
    }
    
    getMachineImage = async () => {
        const { machine } = this.props;
        if (machine.imagePath) {
            this.setState({ loadingMachineImage: true });
            try {
                const response = await noviAPI.documents.downloadWithLink(machine.imagePath, { responseType: 'blob', });
                const url = URL.createObjectURL(response.data);
                this.setState({
                    loadingMachineImage: false,
                    machineImageUrl: url
                });
            } catch (error) {
                this.setState({ 
                    machineImageUrl: '',
                    loadingMachineImage: false
                });
            }
        } else {
            this.setState({
                machineImageUrl: '',
                loadingMachineImage: false
            });
        }
    } 

    fetchChildMachineWorkCardCounts = async (mId) => {
        const { settings } = this.props;

        if (!settings.machineGroupId) {
            return;
        }

        this.setState({
            counts: {
                machineOpenWorkCards: 0,
                childMachineOpenWorkCards: 0
            }
        });

        let openWorkCardStatuses = [...this.props.openWorkCardStatuses];

        if (openWorkCardStatuses.length == 0) {
            const { data } = await noviAPI.workCardSettings.fetchAll()
            openWorkCardStatuses = data.reportedWorkStatuses;
            this.props.setWorkCardSettings(data);
        }

        let searchParams = new URLSearchParams();
        searchParams.append('machineGroupId', `${settings.machineGroupId}`);
        searchParams.append("hierarchyPaths", `;${mId},`);
        searchParams.append('pageNumber', `0`);
        searchParams.append('pageSize', `0`);

        openWorkCardStatuses.forEach(x => {
            searchParams.append("workStatuses", `${x}`);
        });

        // Use same query logic but with the machine id added to get the current machine workcards
        let machineSearchParams = new URLSearchParams(searchParams);
        machineSearchParams.append("machineIds", mId);

        const allOpenCount : number = await noviAPI.workCards.searchCombined(searchParams)
            .then(({ data }) => {
                return data.totalResultCount;
            })
            .catch(err => {
                console.log("[ERROR]", err);
                HandleError(err, "Fetch open workcards");
                return 0;
            })

        const machinesOpenCount : number = await noviAPI.workCards.searchCombined(machineSearchParams)
            .then(({ data }) => {
                return data.totalResultCount;
            })
            .catch(err => {
                console.log("[ERROR]", err);
                HandleError(err, "Fetch open workcards");
                return 0;
            })

        const subMachineCount = allOpenCount - machinesOpenCount;

        this.setState({
            counts: {
                machineOpenWorkCards: machinesOpenCount,
                childMachineOpenWorkCards: subMachineCount
            }
        });

        this.fetchChildMachineWorkCards(mId);
    }

    fetchChildMachineWorkCards = (mId) => {

        const { settings } = this.props;

        let searchParams = new URLSearchParams();
        searchParams.append('machineGroupId', `${settings.machineGroupId}`);
        searchParams.append("hierarchyPaths", `;${mId},`);

        this.props.openWorkCardStatuses.forEach(x => {
            searchParams.append("workStatuses", `${x}`);
        })

        this.props.fetchMachineWorkCards(mId, searchParams);
        this.props.getWorkCardColors();

    }

    formList(items) {
        let itemList = [];
        items.forEach(function (item) {
            itemList.push(item.name);
        });

        return itemList.join(', ');
    }

    calculateDaysLate = (workCanBegin) => {
        let now = moment();
        let date = getInputDateTime(workCanBegin);
        return moment(date).diff(now, 'days');
    }

    compare = (key, order = 'asc') => (
        (a, b) => {
            const varA = a[key];
            const varB = b[key];

            return order === 'desc'
                ? varB - varA
                : varA - varB;
        }
    )

    byDaysLate = wcs => {
        const workCards = wcs.map(workCard => {
            const daysLate = this.calculateDaysLate(workCard.workCanBegin);
            return { workCard, daysLate };
        }, {});

        const sortedWcs = workCards.sort(this.compare('daysLate', 'asc'));

        return sortedWcs;
    }

    setWorkListPage = (pageNumber: number) => {
        this.setState({
            workListPage: pageNumber
        });
    }

    getWorkCards = (byCurrentMachine = false) => {
        const { workListPage, counts, showAllPhases } = this.state;
        
        const { pageSize, cardlayout, displayphases } = this.props.workCardPageSettings;

        const { machineWorkCards, childMachineWorkCards } = this.props;
        let workcards = byCurrentMachine ? machineWorkCards : childMachineWorkCards;

        const cardList = this.byDaysLate(workcards);

        const totalResultCount = counts[byCurrentMachine ? 'machineOpenWorkCards' : 'childMachineOpenWorkCards'];
        const totalActualCount = cardList.length;
        const totalPageCount = Math.ceil(totalResultCount / pageSize);

        const upToDate = totalResultCount == totalActualCount;

        return (
            <div className="work-card-container">
                <Container className="bottom-margin-100" fluid>
                    <div className="v-paddings-15">
                        <SearchResultInfo
                            pageSize={pageSize}
                            pageNumber={workListPage}
                            totalResultCount={totalResultCount}
                        />
                    </div>
                    {upToDate ? (
                        <>
                            {totalActualCount === 0 && <div className="no-results"><p>{i18n.t('NO_RESULTS')}</p></div>}
                            {totalActualCount > 0 && (
                                <>
                                    <PaginationContainer
                                        currentPage={workListPage}
                                        setCurrentPage={this.setWorkListPage}
                                        pageCount={totalPageCount}
                                    />
                                    <WorkCards
                                        cardList={cardList.slice(pageSize * (workListPage - 1), pageSize * workListPage)}
                                        showPhases={displayphases}
                                        showAllPhases={showAllPhases}
                                        togglePhases={this.toggleCardPhases}
                                        cardLayout={cardlayout}
                                    />
                                    <PaginationContainer
                                        currentPage={workListPage}
                                        setCurrentPage={this.setWorkListPage}
                                        pageCount={totalPageCount}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <Loader status={'pending'} />
                    )}
                </Container>
            </div>
        );
    }

    toggleDialog = (spId = null) => {
        this.setState(prevState => ({
            ...prevState,
            dialogOpen: {
                ...prevState.dialogOpen,
                [spId]: prevState.dialogOpen ? !prevState.dialogOpen[spId] : true
            }
        }))
    }

    closeDialog = () => {
        this.setState(prevState => ({ ...prevState, ['dialogOpen']: false }))
    }

    getSpareParts = () => {
        const sparePartsWithLinks = this.props.machineSpareParts.map(mSP => {
            const linkMatch = this.props.spareParts.find(({ sparePart }) => sparePart.id === mSP.sparePart?.id);

            if (linkMatch) {
                return Object.assign({}, { warehouseSparePartLinks: linkMatch.warehouseSparePartLinks }, mSP);
            }
            else return mSP;

        });

        return <MachineSpareParts
            spareParts={sparePartsWithLinks}
            toggleDialog={this.toggleDialog}
            dialogOpen={this.state.dialogOpen}
            closeDialog={this.closeDialog}
        />;
    }

    navigateBackToMachinelist = (params) => {
        this.props.history.replace(params.path, params.state);
    }

    getSceneData = (machine) => {
        const { history, location } = this.props;
        const { showFileInput } = this.state;
        let navTitle = showFileInput ? i18n.t('ADD_DOCUMENT') : machine ? machine.code : '';
        let mSubPhaseLabel = '';
        if (location?.state?.viewProperty) {
            const navTitles = {
                'openworkcards': 'OPEN_WORK_CARDS',
                'cm-openworkcards': 'SUB_MACHINE_OPEN_WORK_CARDS',
                'spareparts': 'SPARE_PARTS'
            }
            navTitle = i18n.t(navTitles[location.state.viewProperty]);
            mSubPhaseLabel = machine?.code
        }

        return {
            view: moduleSettings.name,
            title: navTitle,
            thumbnail: this.state.machineImageUrl,
            subPhaseLabel: mSubPhaseLabel,
            location: location,
            history: history,
            backAction: location.state && location.state.scrollPosition
                ? {
                    action: this.navigateBackToMachinelist,
                    params: {
                        path: '/machines',
                        state: { heightPos: location.state.scrollPosition || 0 }
                    }
                }
                : this.state.showFileInput
                    ? { action: this.closeDocumentForm }
                    : { action: history.goBack },
            onThumbnailClick: () => this.setState({ showImageModal: true })
        };
    }

    openDocumentForm = () => {
        this.setState({ showFileInput: true })
    }

    closeDocumentForm = () => {
        this.setState({
            showFileInput: false,
            invalidFields: false,
            missingMetaDatas: []
        });
    }

    addDocument = () => {
        const { docRequirements } = this.state;
        const { linkToDocument, description, type, file, metaData } = this.docRef.current.state;
        const machineId = parseInt(this.props.match.params.machineId, 10);

        if ((file === null && linkToDocument === '') || (file !== null && linkToDocument !== '')) {
            toast.info(i18n.t('SELECT_FILE_OR_LINK'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            return;
        }
        const missingMetaDatas = this.props.documentMetadatas.filter(field => {
            const val = metaData[field.label];
            return (field.required && (isNullOrWhitespace(val) || val?.id == -1));
        });

        if (missingMetaDatas.length || (docRequirements?.find(req => req.field === 'description') && !description)) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ 
                invalidFields: true,
                missingMetaDatas: missingMetaDatas.map(x => x.id)
            });
            return;
        }

        const isUrl = linkToDocument ? true : false;

        const { addMachineDocument } = this.props;
        const documentMetaDataIds = Object.values(metaData)
            .map(data => data?.id ?? 0)
            .filter(id => id > 0);

        addMachineDocument({
            machineId,
            description,
            isUrl,
            type,
            documentMetaDataIds,
            ...(file === null && linkToDocument !== '' && { linkToDocument }),
            ...(file !== null && linkToDocument === '' && { file })
        })

        this.closeDocumentForm();
    }

    addMaterial = () => {
        this.props.history.push(`/machine/${this.props.match.params.machineId}/sparepart/new/`);
    }

    showDeleteMachineDialog = () => {
        this.setState({
            showDialog: true,
            dialogType: dialogTypes.deleteMachine
        });
    }

    showDeleteDocumentDialog = (document: IDocument) => {
        this.setState({
            showDialog: true,
            dialogType: dialogTypes.deleteDocument,
            dialogItem: document
        });
    }

    editMachine = () => {
        this.props.history.push(`/machine/${this.props.match.params.machineId}/edit`);
    }

    addFaultNotice = () => {
        const { ...machineState } = this.state;
        this.props.history.push(`/workcard/new/faultnotice`, {
            'machine': this.props.machine,
            'machineState': machineState,
            'cardType': 'newFaultNotice'
        });
    }

    openNewWorkCard = () => {
        const { ...machineState } = this.state;
        this.props.history.push(`/workcard/new/workcard`, {
            'machine': this.props.machine,
            'machineState': machineState,
            'cardType': 'newWorkCard'
        });
    }

    getDialogContent = (dialogType: number) => {
        if (dialogType === dialogTypes.deleteMachine) {
            return {
                title: i18n.t('DELETE_MACHINE'),
                body: InitDialogBodyContent('MACHINE', this.props.machine.code + ' / ' + this.props.machine.name),
                type: 'delete'
            }
        }
        else if (dialogType === dialogTypes.deleteDocument) {
            return {
                title: i18n.t('DELETE_DOCUMENT'),
                body: InitDialogBodyContent('DOCUMENT', this.state.dialogItem.caption),
                type: 'delete'
            }
        } else {
            return {
                title: '',
                body: '',
                type: ''
            }
        }
    }

    handleTabGroup = (value) => {
        this.setState({ tabGroupActiveKey: value });
        localStorage.setItem('activeMachineTab', value)
        localStorage.setItem('mId', this.props.match.params.machineId)
    }

    confirmDialog = () => {
        const { dialogType } = this.state;
        const machineId = Number.parseInt(this.props.match.params.machineId);
        this.resetDialogProps();
        if (dialogType === dialogTypes.deleteMachine) {
            this.props.deleteMachine(machineId);
            this.props.history.push('/machines/', { notificationMsg: i18n.t('MACHINE_DELETED') });
        }
        if (dialogType === dialogTypes.deleteDocument) {
            const { dialogItem: document } = this.state;
            this.props.deleteMachineDocument(document.id, machineId);
        }
    }

    resetDialogProps = () => {
        this.setState({
            showDialog: false,
            dialogType: null,
            dialogItem: null
        });
    }

    setDocumentPanelPage = (pageNumber: number, panel: 'wc-documents' | 'cm-documents') => {
        const { match } = this.props;
        if (panel === 'wc-documents') {
            this.fetchMachineWorkCardDocuments(match.params.machineId, pageNumber)
        } else {
            this.fetchChildMachineDocuments(match.params.machineId, pageNumber)
        }
    }

    //TODO: consider separate service file for API calls and data handling
    fetchChildMachineDocuments = (mId, pageNumber) => {
        const pageNumberParam = pageNumber ? pageNumber - 1 : 0

        let queryParams = new URLSearchParams();
        queryParams.append('PageNumber', pageNumber.toString());
        queryParams.append('PageSize', '10');
        queryParams.append('groupByEntity', 'false');

        noviAPI.machines.fetchChildMachineDocuments(mId, queryParams)
            .then(({ data }) => {
                this.setState({ childMachineDocs: data })
            })
            .catch(error => {
                HandleError(error, "fetchChildMachineDocuments");
            })
    }

    //TODO: consider separate service file for API calls and data handling
    fetchMachineWorkCardDocuments = (mId, pageNumber) => {
        const pageNumberParam = pageNumber ? pageNumber - 1 : 0;

        let queryParams = new URLSearchParams();
        queryParams.append('PageNumber', pageNumberParam.toString());
        queryParams.append('PageSize', '10');
        queryParams.append('groupByEntity', 'false');

        noviAPI.machines.fetchWorkCardDocuments(mId, queryParams)
            .then(({ data }) => {
                this.setState({ machineWcDocs: data })
            })
            .catch(error => {
                HandleError(error, "fetchMachineWorkCardDocuments ");
            })
    }

    getValueFromFieldObject = field => {
        if (Array.isArray(field.value)) {
            return FormList({ list: field.value, listItemName: 'value' })
        } else {
            const value = field.value
                ? field.value.id
                    ? field.value.name || field.value.label || field.value.description
                    : field.field !== 'hierarchypath'
                        ? field.value
                        : GetSimplifiedHierarchyPathString(field.value)
                : field.type === 'number'
                    ? field.value
                    : '';

            if (field.field === 'url' && value !== '') {
                return drawLink(value.includes('//') ? value : `http://${value}`, value);
            } else {
                return value;
            }
        }
    }

    toggleCardPhases = wcId => {
        this.setState({
            showAllPhases: {
                ...this.state.showAllPhases, 
                [wcId]: this.state.showAllPhases[wcId] ? false : true
            }
        });
    }

    render() {
        const {
            machine,
            viewSettings,
            settings,
            location,
            status,
            machineStatus,
            machExtraDatas,
            measurements,
            counters,
            countersEnabled
        } = this.props;

        const {
            showFileInput,
            showDialog
        } = this.state;

        let machineViewSettings = [];

        if (machine?.id) {
            let key, keys = Object.keys(machine);
            let n = keys.length;
            let mObj = { extradatas: [] };
            while (n--) {
                key = keys[n];
                mObj[key.toLowerCase()] = machine[key];
            }

            if (viewSettings) {
                machineViewSettings = GetFormDataFromViewSettings([...viewSettings], mObj, null, settings, mObj.extradatas);
            }
        }

        const relationViews = ['openworkcards', 'cm-openworkcards', 'spareparts'];
        const isWorkCardView = ['openworkcards', 'cm-openworkcards'].includes(location?.state?.viewProperty);
        const enabledPhases = settings['noviConfigs'] && settings['noviConfigs'].EnablePhases;

        const viewAction = showFileInput
            ? {
                icon: 'save',
                label: '',
                clickFn: this.addDocument,
                isActionFn: true,
                paClass: 'start-phase'
            } : relationViews.find(view => view === location?.state?.viewProperty)
                ? isWorkCardView ? 'settings' : ''
                : 'settings';

        const informationDiv = (
            <div className="machine-details margin-bottom-15">
                {typeof machine !== 'undefined'
                    ? machineViewSettings.map((item, i) => {
                        return item && <Row key={i}>
                            <Col xs={4} className="sub-header">
                                {i18n.t(item.translationKey)}
                            </Col>
                            <Col xs={8} className="white word-break" data-cy={item.field}>
                                {this.getValueFromFieldObject(item)}
                            </Col>
                        </Row>

                    })
                    : <div>Machine not found...</div>
                }
            </div>
        )

        const extraDataDiv = (
            <div className="machine-extra">
                <h2>{i18n.t('TYYPPIKOHTAISET_LISATIEDOT')}</h2>
                {machExtraDatas.map(info => (
                    <Row key={info.id} className="margin-bottom-5">
                        <Col xs={4} className="sub-header">
                            <TextTruncate
                                line={2}
                                truncateText="..."
                                text={i18n.t(info.caption.caption)}
                            />
                        </Col>
                        <Col xs={8} className="white word-break">{info.value}</Col>
                    </Row>
                ))}
            </div>
        )

        const linkPanelCounts = {
            openworkcards: this.state.counts.machineOpenWorkCards,
            cmopenworkcards: this.state.counts.childMachineOpenWorkCards,
            workhistory: this.props.workHistoryCount,
            spareparts: this.props.machineSpareParts.length
        }

        const documents = {
            machineDocuments: this.props.machineDocuments,
            workCardDocuments: this.state.machineWcDocs,
            childMachineDocuments: this.state.childMachineDocs
        }

        let numberOfDetails = 0;
        numberOfDetails += linkPanelCounts.openworkcards;
        numberOfDetails += linkPanelCounts.cmopenworkcards;
        numberOfDetails += linkPanelCounts.workhistory;
        numberOfDetails += linkPanelCounts.spareparts;
        numberOfDetails += documents.machineDocuments.length;
        numberOfDetails += documents.workCardDocuments.totalResultCount;
        numberOfDetails += documents.childMachineDocuments.totalResultCount;
        numberOfDetails += measurements.length;
        numberOfDetails += countersEnabled ? counters.length : 0;

        return (
            <div>
                {showDialog && <ConfirmDialogComponent
                    dialogContent={this.getDialogContent(this.state.dialogType)}
                    callBack={this.confirmDialog}
                    cancelDialog={this.resetDialogProps}
                />}
                <NavigationBar
                    currentView={this.getSceneData(machine)}
                    navHistory={this.props}
                    viewAction={viewAction}
                    popover={
                        isWorkCardView ? 
                            <WorkListSettingsMenu
                                enabledPhases={enabledPhases?.toLowerCase() === "true"}
                                onlyShowPaginationSettings={true}
                            />
                        :
                            <MachineActionMenu
                                deleteMachine={this.showDeleteMachineDialog}
                                openMachineEdit={this.editMachine}
                                openNewMaterial={this.addMaterial}
                                openNewDocument={this.openDocumentForm}
                                openFaultNotice={this.addFaultNotice}
                                openNewWorkCard={this.openNewWorkCard}
                            /> 
                    }
                />
                <Loader status={status} />
                {machine && viewSettings && status !== 'pending' && <div className="machine-view">
                    {(!location.state || !location.state.viewProperty) && <div>
                        {this.state.showFileInput
                            ? <FileInput 
                                ref={this.docRef} 
                                docRequirements={this.state.docRequirements} 
                                invalidFields={this.state.invalidFields} 
                                missingMetaDatas={this.state.missingMetaDatas}
                                docMetadata={this.props.documentMetadatas}
                            />
                            : machine && machine.id
                                ? <Tabs
                                    id="machine"
                                    activeKey={this.state.tabGroupActiveKey}
                                    onSelect={this.handleTabGroup}
                                    defaultActiveKey={1}
                                    className="novi-nav-tabs"
                                >
                                    <Tab
                                        eventKey={1}
                                        title={i18n.t('INFORMATION').toUpperCase()}
                                    >
                                        <Container className="margin-top-15">
                                            {informationDiv}
                                            {machExtraDatas && extraDataDiv}
                                        </Container>
                                    </Tab>
                                    <Tab
                                        eventKey={2}
                                        title={
                                            <span>
                                                {i18n.t('DETAILS').toUpperCase()}
                                                <span className="number-badge">{numberOfDetails}</span>
                                            </span>
                                        }
                                    >
                                        <MachineDetails
                                            machine={this.props.machine}
                                            linkPanelCounts={linkPanelCounts}
                                            documents={documents}
                                            measurements={measurements}
                                            counters={countersEnabled ? counters : []}
                                            setPageNumber={this.setDocumentPanelPage}
                                            deleteDocument={(document) => this.showDeleteDocumentDialog(document)}
                                            machineRegistryEdit={HasRight(UserRights.MachineRegistryEdit, settings.userRights)}
                                        />
                                    </Tab>
                                </Tabs> 
                                : <div>{machineStatus === 'fulfilled' && !machine.id && <InfoContainer type={'notfound'} content={'MACHINE_NOT_FOUND'} />}</div>}
                        </div>}
                    {location.state?.viewProperty && (
                        <div className="work-card-container">
                            <Container className="bottom-margin-100">
                                <div className="v-paddings-15"></div>
                                {location.state.viewProperty === 'openworkcards' && this.getWorkCards(true)}
                                {location.state.viewProperty === 'cm-openworkcards' && this.getWorkCards()}
                                {location.state.viewProperty === 'spareparts' && this.getSpareParts()}
                            </Container>
                        </div>
                    )}
                </div>}
                <ImageModal
                    target={{ 
                        documentId: this.props.machine?.imagePath ? Number.parseInt(this.props.machine.imagePath.split('/')[3]) : null,
                        targetType: DocumentSource.Machine,
                        targetId: this.props.machine?.id
                    }}
                    loading={this.state.loadingMachineImage}
                    imageUrl={this.state.machineImageUrl}
                    showModal={this.state.showImageModal}
                    closeModal={() => this.setState({ showImageModal: false })}
                    onSaved={documentId => this.props.updateImagePath(documentId, this.props.machine?.id, DocumentSource.Machine)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: State, ownProps) => {
    const isOffline = !state.offline.online;
    const machines = !isOffline ? state.machines.machines : state.machines.storedMachines;
    const workCards = !isOffline ? state.workcards.workcards : state.workcards.storedWorkCards;
    const { machine: machineDocs } = state.documents;

    const openWorkCardStatuses = state.workcards?.workcardsSettings?.reportedWorkStatuses ?? [];

    const mId = parseInt(ownProps.match.params.machineId, 10);
    const machine = machines.find(m => m.id === mId);

    const machineWorkCards = state.machines.workCardsByMachine ?? [];
    const childMachineWorkCards = state.machines.workCardsByChildMachines ?? [];

    const { userRights } = state.settings;
    const countersEnabled = HasRight(UserRights.CountersView, userRights);

    return {
        machine: machine,
        machExtraDatas: state.machines.machineExtraData,
        machineWorkCards: machineWorkCards,
        childMachineWorkCards: childMachineWorkCards,
        workCards: workCards,
        viewSettings: state.machines.viewSettings ? state.machines.viewSettings.machine : null,
        machineDocuments: machineDocs.documents || [],
        machineSpareParts: state.machines.machineSpareParts || [],
        settings: state.settings,
        status: state.machines.status,
        machineStatus: state.machines.machineStatus,
        workHistoryCount: state.machines.workHistoryCount,
        measurements: state.machines.measurements,
        spareParts: state.machines.machineSparePartLinks ?? [],
        openWorkCardStatuses,
        workCardPageSettings: state.workcards.settings,
        documentMetadatas: state.documents.metadatas,
        counters: state.machines.counters,
        countersEnabled: countersEnabled,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchDocumentMetadatas,
    fetchMachineDocuments,
    fetchMachineWorkCards,
    fetchMachine,
    fetchViewSettings,
    fetchMachineExtraData,
    fetchMachineSpareParts,
    fetchMachineSparePartWarehouseLinks,
    addMachineDocument,
    deleteMachineDocument,
    deleteMachine,
    fetchMachineDetails,
    deleteWorkCardDocument,
    setMachineGroupId,
    fetchWorkHistoryCount,
    setWorkHistory,
    fetchMeasurements,
    fetchMachines,
    setWorkCardSettings,
    getWorkCardColors,
    removeMachineFromList,
    updateImagePath,
    fetchCounters
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Machine);