import React from 'react';
import i18n from 'translations/i18n';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faClipboardList, faRuler } from '@fortawesome/free-solid-svg-icons';
import RoutePhaseForm from './RoutePhaseForm';
import LargeYellowButton from 'components/buttons/LargeYellowButton';
import { HasRight } from 'components/HelperFunctions';

type UrlParams = { workCardId: string; };

const RouteMaintenanceWorkCardPhase = (props: IProps) => {
    const { workCardId } = useParams<UrlParams>();
    let history = useHistory();
    const { noviConfigs, userRights } = useSelector((state: State) => state.settings);

    const getRoutePhaseButtons = (phase: IRouteMaintenancePhase) => {
        return (
            <div className="route-phase-buttons">
                {noviConfigs.Measurements?.toLowerCase() === 'true' && phase.measurementGroupId && !(phase.isComplete || phase.skipped) && (
                    getMeasurementBtn(phase)
                )}
                {noviConfigs.RTM?.toLowerCase() === 'true' && (
                    getCountersBtn(phase)
                )}
                {HasRight('faultnotice', userRights) && (
                    getFaultNoticeBtn(phase)
                )}
            </div>
        )
    }

    const getMeasurementBtn = (phase: IRouteMaintenancePhase) => {
        const path = `/routeworkcard/measurement/edit?rmwcid=${workCardId}&rmwcpid=${phase.id}&measurementGroupId=${phase.measurementGroupId}`;
        return (
            <LargeYellowButton handleClick={() => history.push(path, { routeWcState: props.routeWcState, rowId: phase.id })}>
                <FontAwesomeIcon icon={faRuler} size="lg" /> {i18n.t('MEASUREMENT')}
            </LargeYellowButton>
        )
    }

    const getCountersBtn = (phase: IRouteMaintenancePhase) => (
        <LargeYellowButton handleClick={() => {
            history.replace({
                state: {
                    routeWcState: {
                        ...props.routeWcState
                    }
                }
            })
            history.push(`/counters?machineIds=${phase.machine.id}`);
        }}>
            <FontAwesomeIcon icon={faCalculator} size="lg" /> {i18n.t('ROUTEMAINTENANCE_COUNTERS')}
        </LargeYellowButton>
    )

    const getFaultNoticeBtn = (phase: IRouteMaintenancePhase) => (
        <LargeYellowButton handleClick={() => addFaultNotice(phase)}>
            <FontAwesomeIcon icon={faClipboardList} size="lg" /> {i18n.t('ROUTEMAINTENANCE_FAULT_NOTICE')}
        </LargeYellowButton>
    )

    const addFaultNotice = (phase: IRouteMaintenancePhase) => {
        history.replace('/workcard/new/faultnotice', {
            'machine': phase.machine,
            'routeWcState': props.routeWcState,
            'routeWc': workCardId,
            'rowId': phase.id,
            'cardType': 'newFaultNotice'
        });
    }

    return (
        <Container className="no-paddings">
            <Row className={"v-margins-15 " + ((!props.phase.isComplete && !props.phase.skipped) ? '' : 'disabled-element')}>
                <Col xs={4}>
                    <span>{i18n.t('DESCRIPTION')}</span>
                </Col>
                <Col xs={8}>
                    {props.phase.task
                        ? <span>{props.phase.task}</span>
                        : <span className="cursive">{i18n.t('NO_DESCRIPTION')}</span>
                    }
                </Col>
            </Row>
            <div className="v-margins-15">
                <div className="form-table-container">
                    <form>
                        <RoutePhaseForm
                            onChange={props.handleInputChange}
                            onDateChange={props.handleDatetime}
                            handleSelect={props.handleSelect}
                            routePhase={props.phase}
                            stateData={props.routeWcState}
                            hourCardFields={props.hourCardFields}
                        />
                    </form>
                </div>
            </div>
            {getRoutePhaseButtons(props.phase)}
        </Container>
    )
}

interface IProps {
    phase: IRouteMaintenancePhase;
    routeWcState;
    handleInputChange;
    handleDatetime;
    handleSelect;
    hourCardFields;
}

export default RouteMaintenanceWorkCardPhase;