import React from 'react';
import { Popover } from 'react-bootstrap';

const PopoverContainer = ({ headings = null, children, popoverclass = null, ...position }) => {
    return (
        <Popover id="settings-menu-popover" {...position} className={popoverclass}>
            {React.Children.map(children, (child, i) => (
                <div className="sub-section" key={`setting_${i}`}>
                    {headings !== null &&
                        headings[i] !== null &&
                        <div className="setting-header">
                            {headings[i]}
                        </div>
                    }
                    <div className="setting-option">
                        {child}
                    </div>
                </div>
            ))}
        </Popover>
    );
}

export default PopoverContainer;