import authAPI from 'api/authAPI';
import * as types from '../config/actionTypes';
import { LOADING_USER, USER_FOUND, SILENT_RENEW_ERROR } from 'redux-oidc';

const initialState: ISettingsState = {
    userId: null,
    machineGroupId: null,
    machineGroups: [],
    userRights: [],
    defaultLanguage: '',
    colorConfig: [],
    colors: [],
    colorSettings: [],
    workerGroups: [],
    userGroups: [],
    personGroups: [],
    statusTypes: [],
    userDetails: {
        id: null,
        name: null,
        role: null,
        adName: null,
        identityNumber: null,
        email: null,
        hourPrice: null,
        phone: null,
        personGroupIds: []
    },
    userAdNameDetails: {
        adName: null,
        email: null,
        hourPrice: null,
        id: null,
        identityNumber: null,
        name: null,
        personGroupIds: [],
        phone: null,
        role: null
    },
    userSettings: [],
    personMachineGroupSettings: [],
    thirdPartyTypes: [],
    thirdParties: {
        byType: {},
        allTypes: []
    },
    thirdPartyDetails: {},
    extraDatas: [],
    urgencyTypes: [],
    noviConfigs: {},
    sqlQuickSearches: {},
    allQuickSearches: [],
    responsiblePeopleCategories: [],
    responsiblePeopleByCategory: [],
    searches: {
        workCardSearches: [],
        machineSearches: [],
        sparePartSearches: []
    },
    SSOAccessToken: null
}

const settingsReducer = (state = initialState, action): ISettingsState => {
    switch (action.type) {
        case types.SET_MACHINE_GROUPS:
            return {
                ...state,
                machineGroups: action.payload.map(i => ({ value: i.id, label: i.name }))
            }
        case `${LOADING_USER}_FULFILLED`:
            return {
                ...state,
                SSOAccessToken: action.payload
            }
        case `${types.LOGIN}_FULFILLED`:
            return {
                ...state,
                userId: action.payload?.user
            }
        case `${types.LOGOUT}_FULFILLED`:
            return {
                ...state,
                userId: action.payload,
                SSOAccessToken: null
            }
        case types.SET_USER_DETAILS:
            return {
                ...state,
                userId: action.payload.id,
                userDetails: action.payload
            }
        case `${types.FETCH_USER_DETAILS_BY_ADNAME}_FULFILLED`:
            return {
                ...state,
                userAdNameDetails: action.payload
            }
        case types.SET_USER_SETTINGS:
            return {
                ...state,
                userSettings: action.payload
            }
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
            return {
                ...state,
                machineGroupId: action.payload.id
            }
        case `${types.FETCH_USER_RIGHTS}_FULFILLED`:
            return {
                ...state,
                userRights: action.payload
            }
        case types.SET_COLOR_CONFIGURATION:
            return {
                ...state,
                colorConfig: {
                    ...state.colorConfig,
                    [action.payload.settingName]: action.payload.data
                }
            }
        case types.SET_COLORS:
            return {
                ...state,
                colors: action.payload
            }
        case types.SET_COLOR_SETTINGS:
            return {
                ...state,
                colorSettings: action.payload
            }
        case `${types.FETCH_WORKER_GROUPS}_FULFILLED`:
            return {
                ...state,
                workerGroups: action.payload
            }
        case `${types.FETCH_USER_GROUPS}_FULFILLED`:
            return {
                ...state,
                userGroups: action.payload
            }
        case types.SET_APPLICATION_SETTINGS:
            return {
                ...state,
                noviConfigs: action.payload
            }
        case types.SET_WORK_STATUSES:
            return {
                ...state,
                statusTypes: action.payload
            }
        case `${types.FETCH_THIRD_PARTY_TYPES}_FULFILLED`:
            return {
                ...state,
                thirdPartyTypes: action.payload
            }
        case types.FETCH_THIRD_PARTIES: {
            return {
                ...state,
                thirdParties: {
                    byType: action.thirdParties,
                    allTypes: action.thirdPartyTypes
                }
            }
        }
        case types.SET_URGENCIES:
            return {
                ...state,
                urgencyTypes: action.payload
            }
        case types.SET_QUICK_SEARCHES:
            return {
                ...state,
                sqlQuickSearches: {
                    ...state.sqlQuickSearches,
                    [action.payload.group]: action.payload.data
                }
            }
        case types.FETCH_RESPONSIBLE_PEOPLE_CATEGORIES:
            return {
                ...state,
                responsiblePeopleCategories: action.payload
            }
        case types.FETCH_RESPONSIBLE_PEOPLE_BY_CATEGORY:
            return {
                ...state,
                responsiblePeopleByCategory: action.payload
            }
        case `${types.FETCH_ALL_QUICK_SEARCHES}_FULFILLED`:
            return {
                ...state,
                allQuickSearches: action.payload
            }
        case types.FETCH_SEARCHES:
            return {
                ...state,
                searches: {
                    ...state.searches,
                    [action.payload.type]: action.payload.data
                }
            }
        case types.CLEAR_CACHE:
            return initialState
        case types.CLEAR_USER_CACHE:
            return {
                ...state,
                userId: null,
                userDetails: initialState.userDetails,
                userAdNameDetails: initialState.userAdNameDetails,
                userRights: [],
                userSettings: [],
                personMachineGroupSettings: [],
                userGroups: [],
                machineGroupId: null,
                SSOAccessToken: null
            }
        case `${types.FETCH_PERSON_GROUPS}_FULFILLED`:
            return {
                ...state,
                personGroups: action.payload
            }

        case USER_FOUND:
            const { access_token, refresh_token } = action.payload;
            authAPI.setAccessToken(access_token);
            authAPI.setRefreshToken(refresh_token);
            return state;

        // case SILENT_RENEW_ERROR:
        //     // TODO: Log out/clear cache?
        //     // Some views don't fully reload after silent_renew_error => refresh view
        //     return state;
        
        case `${types.FETCH_THIRD_PARTY_DETAILS}`:
            return {
                ...state,
                thirdPartyDetails: {
                    ...state.thirdPartyDetails,
                    ...action.payload
                }
            }

        case `${types.SET_PERSON_MACHINE_GROUP_SETTINGS}`:
            return {
                ...state,
                personMachineGroupSettings: action.payload
            }
        
        default:
            return state;
    }
}

export default settingsReducer;