import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchMaintenanceItems } from './actions';
import LinkPanelContainer from './../machines/components/LinkPanelContainer';
import NavigationBar from '../navigation';
import PopoverContainer from '../../components/PopoverContainer';
import PopoverAction from '../../components/PopoverAction';
import { Container, CardGroup } from 'react-bootstrap';

const addTypes = {
    routes: 'reittihuolto',
    operators: 'operaattorihuolto',
    plans: 'suunnitelma',
    RTM: 'käyttöaikahuolto'
}

const viewTypes = {
    routes: 'routeLevels',
    operators: 'operatorLevels',
    routeMachines: 'routeMachines',
    operatorMachines: 'operatorMachines',
    routeMaintenances: 'routeMaintenances',
    operatorMaintenances: 'operatorMaintenances'
}

export interface Props {
    fetchMaintenanceItems: any;
    location: any;
    history: any;
    maintenanceData: any;
}

class Maintenances extends React.Component<Props, any> {

    fetchByQuickSearch = (filter) => {
        //this.props.fetchFilteredMaintenances(filter);
    }

    checkReduxData = (viewType, data) => {

        //TODO: refactor to shorter check
        switch (viewType) {
            case 'operators':
                return data.operatorLevels && data.operatorLevels.length >= 0;
            case 'routes':
                return data.routeLevels && data.routeLevels.length >= 0;
            case 'operatorMachines':
                return data.operatorMachines && data.operatorMachines.length >= 0;
            case 'routeMachines':
                return data.routeMachines && data.routeMachines.length >= 0;
            case 'operatorMaintenances':
                return data.operatorMaintenances && data.operatorMaintenances.length >= 0;
            case 'routeMaintenances':
                return data.routeMaintenances && data.routeMaintenances.length >= 0;
            default:
                return true;
        }
    }

    // Items could be maintenance levels, machines and maintenances
    getMaintenanceItems = (maintenanceType) => {
        if (!this.checkReduxData(maintenanceType, this.props.maintenanceData)) {
            this.props.fetchMaintenanceItems({ level: true, type: viewTypes[maintenanceType] });
        }

        return this.props.maintenanceData[viewTypes[maintenanceType]];
    }

    render() {
        const maintenanceType = this.props.location.state;
        const maintenanceItems = this.getMaintenanceItems(maintenanceType);

        const navPopover = (
            <PopoverContainer headings={['']} popoverclass="menu-popover">
                <div>
                    <PopoverAction
                        icon="add-circle"
                        label={'Lisää ' + addTypes[maintenanceType]}
                    />
                </div>
            </PopoverContainer>
        );

        let sceneData = {
            view: maintenanceType,
            title: null,
            location: this.props.location,
            history: this.props.history
        }

        return (
            <div>
                <NavigationBar
                    currentView={sceneData}
                    navHistory={this.props}
                    viewAction="settings"
                    popover={navPopover}
                />
                <Container>
                    <CardGroup
                        id="maintenances-panel-group"
                        className="novi-panel"
                    >
                        {maintenanceItems && maintenanceItems.length > 0 && <div>
                            {Object.keys(maintenanceItems).map(key => (
                                <LinkPanelContainer
                                    key={maintenanceItems[key].id}
                                    title={maintenanceItems[key].name}
                                    hasChildren={true}
                                    hasView={false}
                                    linkTo={'/'}
                                    linkToChildren={'/'}
                                />
                            ))}
                        </div>}
                    </CardGroup>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        maintenanceData: state.maintenances
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMaintenanceItems,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Maintenances);