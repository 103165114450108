import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import noviAPI from '../api/noviAPI';

const useHourCard = (hourCardId: number) => {
    const [hourCard, setHourCard] = useState<IHourCard>(null);
    const [isUserSupervisor, setIsUserSupervisor] = useState(false);
    const [isUserCustomerApprover, setIsUserCustomerApprover] = useState(false);
    const [isUserPaymentSupervisor, setIsUserPaymentSupervisor] = useState(false);

    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);

    useEffect(() => {
        async function fetchHourCard() {
            try {
                const response = await noviAPI.hourCards.fetch(hourCardId);
                setHourCard(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        if (hourCardId > 0) {
            fetchHourCard();
        }
    }, [hourCardId]);

    // Check if user is supervisor
    useEffect(() => {
        async function fetchSupervisedGroups() {
            try {
                const response = await noviAPI.personGroupAppSettings.fetchAllByUserAndGroup('hourcardsupervisedgroup', machineGroupId);
                const supervisedGroups = response.data;
                const isHourCardPersonSupervisedByCurrentUser = supervisedGroups.some(i => hourCard.person.personGroupIds.includes(Number(i.field))); // Field contains the person group id of the supervised group
                setIsUserSupervisor(isHourCardPersonSupervisedByCurrentUser);
            } catch (error) {
                console.log(error);
            }
        }
        if (hourCard !== null) {
            fetchSupervisedGroups();
        }
    }, [hourCard, machineGroupId])

    // Check if user is customer approver
    useEffect(() => {
        async function fetchCustomerGroups() {
            try {
                const response = await noviAPI.personGroupAppSettings.fetchAllByUserAndGroup('hourcardcustomergroup', machineGroupId);
                const customerApproverGroups = response.data;
                const isCurrentUserCustomerApprover = customerApproverGroups.some(i => hourCard.person.personGroupIds.includes(Number(i.field))); // Field contains the person group id of the supervised group
                setIsUserCustomerApprover(isCurrentUserCustomerApprover);
            } catch (error) {
                console.log(error);
            }
        }
        if (hourCard !== null) {
            fetchCustomerGroups();
        }
    }, [hourCard, machineGroupId])

    // Check if user is payment supervisor
    useEffect(() => {
        async function fetchPaymentSupervisedGroups() {
            try {
                const response = await noviAPI.personGroupAppSettings.fetchAllByUserAndGroup('hourcardpaymentsupervisedgroup', machineGroupId);
                const paymentSupervisedGroups = response.data;
                const isCurrentUserPaymentSupervisor = paymentSupervisedGroups.some(i => hourCard.person.personGroupIds.includes(Number(i.field))); // Field contains the person group id of the supervised group
                setIsUserPaymentSupervisor(isCurrentUserPaymentSupervisor);
            } catch (error) {
                console.log(error);
            }
        }
        if (hourCard !== null) {
            fetchPaymentSupervisedGroups();
        }
    }, [hourCard, machineGroupId])

    return {
        hourCard, setHourCard,
        isUserSupervisor, setIsUserSupervisor,
        isUserCustomerApprover, setIsUserCustomerApprover,
        isUserPaymentSupervisor, setIsUserPaymentSupervisor
    };
}

export default useHourCard;