export enum HourCardStatusLabels {
    Reported = 'HOURCARD_STATUS1',
    Approved = 'HOURCARD_STATUS2',
    Invoiced = 'HOURCARD_STATUS3',
    Paid = 'HOURCARD_STATUS4'
}

export enum HourCardStatusEnum {
    Reported = 1,
    Approved = 2,
    Invoiced = 3,
    Paid = 4
}

export enum WeekDays {
    'sunday' = 0,
    'monday' = 1,
    'tuesday' = 2,
    'wednesday' = 3,
    'thursday' = 4,
    'friday' = 5,
    'saturday' = 6
}