import React from 'react';
import request from '../../../../api/interceptors';
import NavigationBar from '../../../navigation';
import { Container, Tabs, Tab, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from "react-redux";
import {
    addFaultNotice,
    addWorkcard,
    updateWorkCard,
    fetchWorkcardOptions,
    fetchWorkCard,
    fetchWorkCardLimitedWorkStatuses,
    fetchWorkCardMaterials
} from '../actions';
import Machines from '../../../machines';
import moment from 'moment';
import i18n from '../../../../translations/i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    fetchViewSettings,
    fetchWorkerGroups,
    fetchCostPools,
    fetchResponsiblePeopleByCategory,
    fetchUrgencies,
    setApiCallFulfilled,
    getWorkCardColors,
    setWorkStatuses,
    fetchDocumentMetadatas,
    fetchFaultNoticeDefaultText
} from '../../../../commonActions/actions';
import FileInput from './../../../../components/FileInput';
import { ScrollToTop } from '../../../../components/ScrollToTop';
import { fetchMachine } from '../../../machines/actions';
import { withRouter } from 'react-router';
import {
    ValidateForm,
    DefineColors,
    GetDetail,
    GetWorkCardPhasesData,
    GetOptionsDataByField,
    GetFormInputValueByField,
    GetProperty,
    GetType,
    PropertyNamesToCamelCase,
    GetOnChangeType,
    HandleSelectOption,
    containsAny,
    filterValuesByParentSelection,
    filterDetailOptions,
    restrictRenderingByParameters,
    isNullOrWhitespace,
    GetExtraDataValue,
    HandleError,
    filterValuesByHardCodedParent
} from '../../../../components/HelperFunctions';
import WorkPhasesAdd from './WorkPhasesAdd';
import { fetchWorkCardDetails, fetchWorkPhaseDetails } from '../../work-list/actions';
import noviAPI from '../../../../api/noviAPI';
import WorkCards from '../../work-list/components/WorkCards';
import settingsAPI from '../../../../config/settingsAPI';
import { Loader } from '../../../../components/Loader';
import { checkRequired, filterArchivedCostPools } from '../utils';
import { mapOldOptionTypesToOptionsType } from '../../../machines/utils';
import { plannedMaterialsNotUsed } from '../../work-list/utils';
import WorkCardForm from './WorkCardForm';
import ListWithButtonAndRemoveItem from '../../../../components/lists/ListWithButtonAndRemoveItem';
import MaterialForm from '../../../../components/MaterialForm';
import { getDateTimeBase, handleDateTimeInput, parsePayloadDateTime } from 'utils';
import { fetchSparePartDetails } from 'scenes/warehouse/actions';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps extends PropsFromRedux {
    history: any;
    location: any;
    match: any;
    fetchWorkcardOptions: () => Promise<void>;
}

interface IState {
    [name: string]: any;
    edit: boolean;
    cardType: string;
    invalidFields: boolean;
    missingMetaDatas: number[];
    documents: any[];
    showFileInput: boolean;
    wcStatus: string;
    qrReading: boolean;
    showMachines: boolean;
    invalidWorkCardForm: boolean;
    invalidPhaseForm: boolean;
    isSubmitted: boolean;
    notificationTargets: INotificationTarget[];
    removedNotificationTargets: number[];
    workPhases: any[];
    workPhasesFormData: any[];
    showMaterialAddition: boolean;
    materials: any[];
    materialFormTrigger: boolean;
    fetchingMachine: boolean;
    optionsLoaded: any;
    docRequirements: IGlobalSetting[];
    addPhaseDisabled: boolean;
    defaultTextExpanded: boolean;
    loading: boolean;
    hasChanged: boolean;
    tabGroupActiveKey: string; // "1" | "2"
}

const moduleSettings = settingsAPI.moduleData.editworkcard;
const storageKey = "workCardValues";

class EditWorkCard extends React.Component<IProps, IState> {
    docRef = React.createRef<FileInput>();
    constructor(props) {
        super(props);

        const { wcData, costpools, cardType, edit, location } = this.props;

        let stateProps = {
            invalidFields: false,
            missingMetaDatas: [],
            edit,
            cardType,
            documents: [],
            showFileInput: false,
            wcStatus: cardType,
            qrReading: false,
            showMachines: false,
            invalidWorkCardForm: false,
            invalidPhaseForm: false,
            openWorkCards: [],
            isSubmitted: false,
            notificationTargets: [],
            removedNotificationTargets: [],
            workPhases: [],
            workPhasesFormData: [],
            showMaterialAddition: false,
            materials: [],
            materialFormTrigger: false,
            fetchingMachine: false,
            optionsLoaded: { workStatusesLoaded: false },
            docRequirements: null,
            addPhaseDisabled: true,
            defaultTextExpanded: true,
            loading: edit,
            hasChanged: false,
            tabGroupActiveKey: edit ? "2" : "1"
        };

        const uniqueId = location.key;
        const savedValues = (JSON.parse(localStorage.getItem(storageKey)) ?? {})?.[uniqueId];

        if (!savedValues) {
            localStorage.setItem(storageKey, JSON.stringify({ [uniqueId]: wcData }));
        }

        const wcValues = savedValues ?? wcData;

        Object.keys(wcValues).forEach(key => {
            stateProps[key] = key === 'id' ? wcValues[key] : wcValues[key].value;
        });

        // If we're making a new workcard, import the machine costpools
        if (!stateProps.edit && costpools.length > 0) {

            costpools.forEach(costpool => {
                if (costpool != null) {
                    const key = "costpoolgroup_" + costpool.groupId;
                    stateProps[key] = {
                        id: costpool.id,
                        label: costpool.translationKey
                    };
                }
            });

        }
        this.state = stateProps;
    }

    componentDidMount() {
        const { workCardId } = this.props.match.params;

        if (workCardId) {
            this.props.fetchWorkCard(workCardId)
                ['then']((wc: IWorkCard) => {
                    if (wc?.machine?.id) {
                        this.props.fetchMachine(wc.machine.id);
                    }

                    const wcValues = {
                        loading: false
                    };

                    Object.entries(wc).forEach(([key, value]) => {

                        if (key === 'costPools') {
                            value?.forEach((costpool: ICostPoolLite) => {
                                wcValues[`costpoolgroup_${costpool.groupId}`] = {
                                    ...costpool,
                                    label: i18n.t(costpool.translationKey)
                                }
                            });
                        }
                        else if (['workers', 'workergroups', 'workbegin', 'workcanbegin', 'workended', 'workcanend'].includes(key.toLowerCase())) {
                            wcValues[key.toLowerCase()] = value;
                        }
                        else if (key === 'notificationTargets') {
                            wcValues['notificationtarget'] = value;
                        }
                    });

                    this.setState({ ...wcValues });
                    this.fetchOptions();
                })
                .catch(err => {
                    console.log(err);
                    HandleError(err, "Fetch workcard");
                });
        }
        else {
            this.fetchOptions();
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { status, cardType, machines, firstPhaseRequired, wpData } = this.props;

        // Add workphase form if first phase is required, work phases list is empty and all necessary data is loaded
        if (firstPhaseRequired &&
            this.state.workPhases.length === 0 
            && this.state.optionsLoaded?.workStatusesLoaded
            && status === 'fulfilled' && wpData && Object.keys(wpData).length > 1
            && !this.state.addPhaseDisabled
        ) {
            this.addPhase();
        }

        if (!this.state.edit && (this.state.wcStatus === "newWorkCard" || cardType === 'newFaultNotice') && this.state.machine?.id !== undefined) {
            if (this.state.machine?.id !== prevState.machine?.id || machines?.length !== prevProps.machines?.length) {

                const machine = machines.find(m => m.id == this.state.machine?.id);

                if (machine) {
                    const newState = {};

                    // When selecting different machine, reset all the costpools and fill them with costpools from new machine
                    const oldStateCostpools : string[] = Object.keys(this.state).filter(key => key.includes("costpoolgroup_"));
                    oldStateCostpools.forEach(key => newState[key] = null);

                    machine.costPools.forEach(costpool => {
                        if (costpool != null) {
                            const key = "costpoolgroup_" + costpool.groupId;
                            newState[key] = {
                                id: costpool.id,
                                label: costpool.translationKey
                            };
                        }
                    });

                    this.setState(newState);
                }
                else {
                    this.props.fetchMachine(this.state.machine.id);
                }

            }
        }

        if (cardType === 'newFaultNotice' && !this.state.fetchingMachine) {
            const machineField = this.props.formFieldSets.faultnotice.find(setting => setting.field === 'machine')
            if (machineField?.defaultValue && !this.state.machine) {
                const costpoolStates = {};
                this.setState({
                    fetchingMachine: true
                }, () => {
                    noviAPI.machines.fetch(machineField?.defaultValue)
                        .then(({ data }) => {
                            data.costPools.forEach(costpool => {
                                if (costpool != null) {
                                    const key = "costpoolgroup_" + costpool.groupId;
                                    costpoolStates[key] = {
                                        id: costpool.id,
                                        label: costpool.translationKey
                                    };
                                }
                            });
                            this.setState(costpoolStates);
                            this.setState({
                                machine: { id: data.id, code: data.code, name: data.name },
                                fetchingMachine: false
                            }, () => {
                                this.getOpenWorkCards(this.state.machine.id)
                                    .then(openWorkCards => {
                                        this.setState({ openWorkCards })
                                    });
                            });
                        }).catch(e => {
                            console.log(e);
                        });
                });
            }
        }

        if (status === 'error' && this.state.isSubmitted) {
            this.setState({ isSubmitted: false });

            this.props.setApiCallFulfilled();
        }

        if (prevProps !== this.props && !this.state.showMachines) {
            const { wcData, wpData } = this.props;

            Object.keys(wcData).forEach(key => {
                // Init possible DefaultValue for empty worker -field
                if (wcData[key].value && key === 'workers' && this.state[key]?.length === 0) {
                    this.setState({ [key]: wcData[key].value });
                } else if (!this.state[key]) {
                    if (this.state.wcStatus !== 'editWorkCard' && key === 'workcanend' && wcData.hasOwnProperty('workcanbegin')) {
                        this.setState({
                            workcanend: this.getWorkCanEnd(wcData.workcanbegin.value)
                        });
                    }
                    else if (!key.includes("costpoolgroup_")) {
                        this.setState({ [key]: key === 'id' ? wcData[key] : wcData[key].value });
                    }
                }
            });

            if (this.props.firstPhaseRequired && this.state.workPhases.length === 1) {
                let phases = [...this.state.workPhases];

                let phase = { ...phases[0] };
                Object.keys(wpData).forEach(key => {
                    if (key === 'workcanend_wp' && wpData.hasOwnProperty('workcanbegin_wp')) {
                        phase[key] = this.getWorkCanEnd(wpData.workcanbegin_wp.value);
                    } else {
                        phase[key] = key === 'id'
                            ? wpData[key]
                            : key === 'workstatus_wp' && wpData[key].value === ""
                                ? wpData.workstatus_wp.options[0]
                                : phase[key] === '' || phase[key] === undefined || phase[key] === null ? wpData[key].value : phase[key];
                    }
                });

                phases[0] = phase;
                this.setState(prevState => ({
                    ...prevState,
                    workPhases: phases
                }))
            }
        }

        if (this.props.autoCalcIdleTime) {
            if (prevState.machinehalt !== this.state.machinehalt
                || prevState.faultbegin !== this.state.faultbegin
                || prevState.workbegin !== this.state.workbegin
                || prevState.workended !== this.state.workended) {
                this.calculateIdleTime();
            }
        }

        if (this.state.showMaterialAddition !== prevState.showMaterialAddition) {
            if (this.state.showMaterialAddition){
                window.scrollTo(0,0);
            } else {
                document.getElementById("materials").scrollIntoView();
            }
        }

    }

    fetchOptions = () => {

        const { workCardId } = this.props.match.params;

        this.props.fetchWorkCardDetails();
        this.props.fetchWorkPhaseDetails();
        this.props.fetchUrgencies();
        this.props.fetchWorkerGroups();
        this.props.fetchCostPools();
        this.props.getWorkCardColors();
        this.props.fetchDocumentMetadatas();

        if (this.state.cardType === 'newWorkCard')
            this.props.fetchSparePartDetails();


        this.props.fetchWorkcardOptions()
            .finally(() => {
                this.setState({ addPhaseDisabled: false });
            });


        noviAPI.workCardSettings.fetchWorkStatuses()
            .then(({ data }) => { 
                this.props.setWorkStatuses(data); 

                // Set load status to local state for addPhase to be called only if statuses are loaded 
                this.setState(state => ({
                    ...state,
                    optionsLoaded: {
                        ...state.optionsLoaded,
                        workStatusesLoaded: true
                    }
                }));
            });

        noviAPI.documents.getDocumentRequirements()
            .then(({ data }) => {
                this.setState({ docRequirements: data })
            })

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });

        this.props.fetchResponsiblePeopleByCategory(moduleSettings.additionalParams.responsiblePerson);

        if (this.state.wcStatus != 'newFaultNotice' && this.props.wcData.workstatus) {
            if (this.props.firstPhaseRequired && this.state.workPhases.length === 1) {
                const workCanEndWp = this.state.workcanend_wp
                    ? this.state.workcanend_wp
                    : this.props.wcData.workcanbegin_wp && this.getWorkCanEnd(this.props.wcData.workcanbegin_wp.value);
                let phases = [...this.state.workPhases];

                let phase = { ...phases[0] };
                phase['workcanend_wp'] = workCanEndWp;
                phases[0] = phase;

                this.setState(prevState => ({
                    ...prevState,
                    workPhases: phases
                }))
            }

            this.setState({
                workcanend: this.state.workcanend
                    ? this.state.workcanend
                    : this.getWorkCanEnd(this.props.wcData.workcanbegin?.value)
            })
        }

        if (this.props.noviConfigs.LimitedWorkStatuses?.toLowerCase() === 'true') {
            this.props.fetchWorkCardLimitedWorkStatuses(this.props.settings.machineGroupId, 'allowedworkstatus')
        }

        if (this.state.wcStatus === 'editWorkCard' && this.props.noviConfigs.WarnPlannedMaterialsNotUsed?.toLowerCase() === 'true') {
            this.props.fetchWorkCardMaterials(workCardId);
        }
        
        if (this.state.wcStatus === 'newFaultNotice' && this.props.defaultText === null) {
            this.props.fetchFaultNoticeDefaultText();
        }

    }

    // Modify/add new value to wcData saved in localStorage
    saveValueToStorage = (key, value) => {

        const { location } = this.props;
        const uniqueId = location.key;
        const savedValues = (JSON.parse(localStorage.getItem(storageKey)) ?? {})?.[uniqueId] ?? {};

        localStorage.setItem(storageKey, JSON.stringify({
            [uniqueId]: {
                ...savedValues,
                [key]: {
                    ...savedValues[key],
                    value
                }
            }
        }));
    }

    cancelSubmit = () => {
        this.setState({ isSubmitted: false });
    }
    
    preFillWorkPhase = workphase => {
        workphase['machinehalt'] = false;
        workphase['workstatus'] = 1;

        return workphase;
    }

    getOpenWorkCards = (machineId) => {
        let params = new URLSearchParams();
        params.append('MachineIds', `${machineId}`);
        return noviAPI.workCards.search(params)
            .then(response => {
                const finishedWorkStatuses = this.props.workcardsSettings.finishedWorkStatuses || [];
                return response.data.results
                    .filter(workCard => !finishedWorkStatuses.includes(workCard.workStatus));
            });
    }

    handleSubmit = () => {
        const {
            wcData,
            wpData,
            addWorkcard,
            updateWorkCard,
            addFaultNotice,
            location,
            preventIfUnfinishedPhases,
            workcardPhases,
            statusTypes,
            noDateValidation,
            alternativeDateValidation,
            match,
            wcExtraDatas,
            history
        } = this.props;

        const {
            invalidWorkCardForm,
            invalidPhaseForm,
            invalidFields,
            edit,
            qrReading,
            showFileInput,
            documents,
            showMachines,
            id,
            code,
            wcStatus,
            tabGroupActiveKey,
            colors,
            isSubmitted,
            workPhases,
            workPhasesFormData,
            openWorkCards,
            materials,
            ...rest
        } = this.state;

        const workCardState = { ...rest };

        if (isSubmitted) {
            return;
        }

        if (this.state.wcStatus === 'editWorkCard' && this.props.settings.noviConfigs.WarnPlannedMaterialsNotUsed?.toLowerCase() === 'true') {
            if (this.props.workcardsSettings?.finishedWorkStatuses.includes(this.state.workstatus?.id) && plannedMaterialsNotUsed(this.props.materials)) {
                toast.error(i18n.t('PLANNED_MATERIALS_NOT_USED'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                return;
            }
        }

        if (this.invalidForm('report') || this.invalidForm('notice') || this.invalidForm('phase')) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ invalidFields: true, isSubmitted: false });
            return;
        } else {
            this.setState({ invalidFields: false })
        }



        let dateValidationMsg = '';

        const { faultbegin, workcanbegin, workbegin, workended, workcanend } = this.state;

        if ((workcanbegin && workcanend && workcanbegin > workcanend)) {
            dateValidationMsg = i18n.t('WORK_CAN_END_LESS_THAN_OR_EQUAL_WITH_WORK_CAN_BEGIN') + ' ';
        }

        if (!noDateValidation) {

            if (faultbegin && workbegin && faultbegin > workbegin) {
                dateValidationMsg = dateValidationMsg + i18n.t('WORKBEGIN_LESS_THAN_FAULTBEGIN') + ' ';
            }

            if (workended && workbegin && workbegin > workended) {
                dateValidationMsg = dateValidationMsg + i18n.t('WORKENDED_LESS_THAN_WORKBEGIN') + ' ';
            }
            if (!alternativeDateValidation){
    
                if (faultbegin && workcanbegin && faultbegin > workcanbegin) {
                    dateValidationMsg = dateValidationMsg + i18n.t('WORKCANBEGIN_LESS_THAN_FAULTBEGIN') + ' ';
                }

                if (workbegin && workcanbegin && workcanbegin > workbegin) {
                    dateValidationMsg = dateValidationMsg + i18n.t('WORKBEGIN_LESS_THAN_WORKCANBEGIN') + ' ';
                }
                
                workPhases.forEach((wp, i) => {
                    if (wp.workbegin_wp && wp.workended_wp && wp.workbegin_wp > wp.workended_wp) {
                        dateValidationMsg = dateValidationMsg + i18n.t('WORKENDED_LESS_THAN_WORKBEGIN') + ' (' + i18n.t('WORKPHASE') + ': ' + (wp.procedure_wp || '#' + i) + ') ';
                    }
                    if (wp.workbegin_wp && workcanbegin && workcanbegin > wp.workbegin_wp) {
                        dateValidationMsg = dateValidationMsg + i18n.t('WORKBEGIN_LESS_THAN_WORKCANBEGIN') + ' (' + i18n.t('WORKPHASE') + ': ' + (wp.procedure_wp || '#' + i) + ') ';
                    }
                });
            }}

        if (dateValidationMsg) {
            toast.error(dateValidationMsg, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ isSubmitted: false });

            return;
        }

        localStorage.removeItem(storageKey);

        let workCardData = { phases: [], details: [], costPools: [] };
        let wcDetails = [];
        let extraDatas = [];
        let targets = [];

        Object.keys(workCardState).forEach(key => {
            if (key.startsWith('costpoolgroup') && workCardState[key] && (wcData[key] || !this.state.edit)) {
                workCardData.costPools.push(workCardState[key])
            } else if (wcData[key] && wcData[key].type.includes('extradata')) {
                const existingItem = wcExtraDatas ? wcExtraDatas.find(extraData => extraData.group === key) : null;

                let extraData = {
                    value: wcData[key].type === 'extradatanumber' ? null : workCardState[key] || null,
                    valueNumber: wcData[key].type === 'extradata' ? null : workCardState[key] || null,
                    group: existingItem ? existingItem.group : key
                }
                if (existingItem) {
                    extraData['id'] = existingItem.id
                }
                extraDatas.push(extraData)
            } else if (wcData[key] && key === 'notificationtarget' && workCardState[key].length > 0) {
                workCardState[key].forEach(target => {
                    targets.push({ id: target.id, value: target.value, workCardId: target.workCardId })
                })
            } else if (wcData[key] && (wcData[key].type === 'detail' || wcData[key].type === 'multidetail' || key === 'worktype') && workCardState[key]) {
                if (Array.isArray(workCardState[key]) && workCardState[key].length > 0) {
                    workCardState[key].forEach(detail => {
                        wcDetails.push({ id: detail.id, value: detail.label, group: key, parentId: detail.parentId });
                    });
                } else if (workCardState[key].id >= 0 || workCardState[key].value >= 0) {
                    wcDetails.push({ id: workCardState[key].id || workCardState[key].value, value: workCardState[key].label, group: key, parentId: workCardState[key].parentId });
                }
            } else if (!key.includes('costpoolgroup')) {
                // Set workcard data from state values
                workCardData[key] = workCardState[key];
            }
        });

        let workPhaseDataObjs = [];
        let workPhasesExtraDatas = [];

        workPhases.forEach(wPhase => {
            let workPhaseData = {
                details: []
            };
            let wpDetails = [];
            let extraDatas = [];

            Object.keys(wPhase).forEach(key => {
                if (wPhase[key] && wPhase[key].type === 'extradata') {
                    let extraData = {
                        value: wPhase[key] || '',
                        group: key
                    }

                    extraDatas.push(extraData)

                } else if (wpData[key] && wpData[key].type === 'phasedetail' && wPhase[key]) {
                    if (Array.isArray(wPhase[key]) && wPhase[key].length > 0) {
                        wPhase[key].forEach(detail => {
                            wpDetails.push({ id: detail.id || detail.value, value: detail.label, group: key, parentId: detail.parentId });
                        });
                    } else if (wPhase[key].id >= 0 || wPhase[key].value >= 0) {
                        wpDetails.push({ id: wPhase[key].id || wPhase[key].value, value: wPhase[key].label, group: key, parentId: wPhase[key].parentId });
                    }
                } else {
                    const wpKey = key.replace('_wp', '');
                    workPhaseData[wpKey] = wPhase[key];
                }
            });

            workPhaseData.details = wpDetails;

            workPhaseDataObjs.push(workPhaseData);
            workPhasesExtraDatas.push({ wpId: wPhase.id, extraDatas: extraDatas });
        });

        workCardData.details = wcDetails;

        const completedStatus = statusTypes.find(sType => sType.statusValue === 4);

        // If workcard marked as done/completed and PreventWorkStatusFinishedIfAllPhasesNotFinished setting is on, 
        // then check if workcard has unfinished work card phases
        if (preventIfUnfinishedPhases
            && workCardData['workstatus'] && workCardData['workstatus'].id === completedStatus?.id
            // Check from existing phases (edit scene)
            && (workcardPhases?.find(existingPhase => existingPhase.workStatus !== completedStatus?.id)
            // CHeck from new phases (add scene)
            || workPhaseDataObjs?.find(newPhase => newPhase.workstatus?.id !== completedStatus?.id))
        ) {
            toast.warn(i18n.t('UNFINISHED_WORKPHASES_ON_WORKCARD'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ isSubmitted: false });
            return;
        }

        workCardData = PropertyNamesToCamelCase(workCardData);

        Object.keys(workCardData).forEach(key => {
            if (workCardData[key] !== '' &&
                (key === 'orderDate'
                    || key === 'workBegin'
                    || key === 'faultBegin'
                    || key === 'workCanBegin'
                    || key === 'workCanEnd'
                    || key === 'workEnded')
            ) {
                workCardData[key] = parsePayloadDateTime(workCardData[key]);
            }
        });

        if (workPhaseDataObjs?.length > 0) {
            workPhaseDataObjs.map(wPhase => (PropertyNamesToCamelCase(wPhase)));

            workPhaseDataObjs.forEach(phase => {
                Object.keys(phase).forEach(key => {
                    if (phase[key] !== '' &&
                        (key === 'orderDate'
                            || key === 'workBegin'
                            || key === 'workCanBegin'
                            || key === 'workCanEnd'
                            || key === 'workEnded')
                    ) {
                        phase[key] = parsePayloadDateTime(phase[key]);
                    }
                });

                workCardData.phases.push(phase);
            });
        }

        //TODO: work phases' extra datas
        this.setState({ isSubmitted: true });
        if (this.state.cardType === 'editWorkCard') {
            workCardData.phases = workcardPhases;
            updateWorkCard(match.params.workCardId, workCardData, extraDatas, targets, this.state.removedNotificationTargets, history.replace, this.cancelSubmit);
        } else {
            if (location.pathname.includes('/workcard/new')) {
                if (this.state.cardType === 'newFaultNotice') {
                    workCardData['workCardType'] = 1;
                } else if (this.state.cardType === 'preventiveMaintenance') {
                    workCardData['workCardType'] = 2;
                } else {
                    workCardData['workCardType'] = 3;
                }

                delete workCardData['code'];
            }

            if (this.state.cardType === 'newFaultNotice') {
                const newLocation = location.state.routeWcState
                    ? {
                        pathname: '/routeworkcard/' + location.state.routeWc,
                        state: {
                            routeWcState: location.state.routeWcState,
                            rowId: location.state.rowId,
                            notificationMsg: 'FAULT_NOTICE_ADDED'
                        }
                    }
                    : location.state.opmState
                        ? {
                            pathname: '/operatormaintenance/' + location.state.opmId,
                            state: {
                                notificationMsg: 'FAULT_NOTICE_ADDED',
                                ...location.state
                            }
                        }
                        : location.state.machineState
                            ? {
                                pathname: '/machine/' + location.state.machine.id,
                                state: {
                                    notificationMsg: 'FAULT_NOTICE_ADDED',
                                    ...location.state
                                }
                            }
                            : {
                                pathname: '/dashboard',
                                state: { notificationMsg: 'FAULT_NOTICE_ADDED' }
                            };
                addFaultNotice(workCardData, documents, extraDatas, targets, () => history.replace(newLocation));
            } else if (this.state.cardType === 'newWorkCard') {
                addWorkcard(workCardData, extraDatas, targets, documents, materials, history.replace);
            }
        }
    }

    handleInputChange = (e, index = null) => {
        const name = e.target.name;
        let value = e.target.value;

        if (index !== null) {
            let phases = [...this.state.workPhases];
            let phase = { ...phases[index] };
            phase[name] = value;
            phases[index] = phase;

            this.setState(prevState => ({
                ...prevState,
                workPhases: phases,
                hasChanged: true
            }))
        } else {
            this.setState({
                [name]: value,
                hasChanged: true
            });
        }
    }

    handleCreateOption = (inputValue: any) => {
        const newOption = { id: null, value: inputValue, workCardId: parseInt(this.props.match.params.workCardId) }

        this.setState({ notificationtarget: [...this.state.notificationtarget, newOption] })
    }

    handleSelect = (value, actionMeta) => {
        const key = actionMeta.name;
        const selectedValue = HandleSelectOption(key, value);
        const { wcData } = this.props;
        this.setState({ hasChanged: true })

        const getChildDetails = (group: string, newValue: any) => {
            
            const previousValue = this.state[group];
            // Edit workcard => { id, label } select value => { value, label }
            const oldIdValue = previousValue?.value ?? previousValue?.id;
            const newIdValue = newValue?.value ?? newValue?.id;

            return Object.keys(wcData).filter(fieldName => {
                const val = wcData[fieldName];
                return val?.type === 'detail' && val?.options?.find(x => {
                    return (x.parentId === newIdValue || (oldIdValue > 0 && x.parentId === oldIdValue));
                });
            });
        }

        const childDetails = getChildDetails(key, selectedValue);

        if (key === 'notificationtarget') {
            switch (actionMeta.action) {
                case 'remove-value': {
                    let array = [...this.state.removedNotificationTargets];

                    if (actionMeta.removedValue.id) {
                        array.push(actionMeta.removedValue.id);
                    }
                    this.setState({ removedNotificationTargets: array })
                    break;
                }
                case 'clear': {
                    let array = [];
                    this.state.notificationtarget.forEach(target => {
                        if (target.id) {
                            array.push(target.id);
                        }
                    });
                    this.setState({ removedNotificationTargets: array });
                    break;
                }
                default: {
                    let array = [...this.state.removedNotificationTargets];
                    this.setState({ notificationtarget: value });

                    value.forEach(i => {
                        if (array.includes(i.id)) {
                            array.splice(i, 1);
                        }
                    });
                    this.setState({ removedNotificationTargets: array });
                    break;
                }
            }
        }

        if (key === 'faulttype') {
            let { faultreason, faultlocation, cause, avoidoffault } = this.state;

            this.setState({
                [key]: selectedValue,
                'faultreason': filterValuesByHardCodedParent(wcData['faultreason'], faultreason, selectedValue),
                'faultlocation': filterValuesByHardCodedParent(wcData['faultlocation'], faultlocation, selectedValue),
                'cause': filterValuesByHardCodedParent(wcData['cause'], cause, selectedValue),
                'avoidoffault': filterValuesByHardCodedParent(wcData['avoidoffault'], avoidoffault, selectedValue)
            });
        } else if (key === 'workcard_tyotyyppi') {
            let { workcard_tyokokonaisuus } = this.state;

            this.setState({
                [key]: selectedValue,
                'workcard_tyokokonaisuus': filterValuesByHardCodedParent(wcData['workcard_tyokokonaisuus'], workcard_tyokokonaisuus, selectedValue)
            })
        } else if (key === 'workergroups' && selectedValue && selectedValue.length > 0) {
            let workerGroupIds = selectedValue.map(wGroup => wGroup.id);
            let options = wcData.workers?.options || [];
            let workerOpts = options.filter(opt => containsAny(opt.personGroupIds, workerGroupIds));

            if (key === 'workergroups') {
                let workerSelects = this.state.workers?.filter(w => workerOpts.find(wOpt => wOpt.id === w.id));

                this.setState({
                    [key]: selectedValue,
                    'workers': workerSelects
                });
            }
        } 

        else if (childDetails.length > 0) {
            const obj = { [key]: selectedValue };

            // Recursively loop through all child details (any depth) and clear the value if necessary
            // TODO: Maybe implement a smarter solution, using recursion is risky and should be avoided
            const handleRecursiveChildDetails = (subDetails: string[], selectedValue: any) => {

                subDetails.forEach((field: string) => {
                    const oldValue = this.state[field];
                    const newValue = filterValuesByParentSelection(wcData[field], this.state[field], selectedValue);

                    // Edit workcard => { id, label } select value => { value, label }
                    const oldIdValue = oldValue?.value ?? oldValue?.id;
                    const newIdValue = newValue?.value ?? newValue?.id;

                    Object.assign(obj, { [field]: newValue });

                    if (newIdValue !== oldIdValue) {
                        handleRecursiveChildDetails(getChildDetails(field, null), newValue);
                    }
                });
            }

            handleRecursiveChildDetails(childDetails, selectedValue);

            this.setState(obj);
        }
        else {
            this.setState({ [key]: selectedValue });
        }
    }

    handleWorkPhaseSelect = (value, actionMeta, index = null) => {
        const key = actionMeta.name;
        const selectedValue = HandleSelectOption(key, value);
        const { wcData } = this.props;

        if (key === 'workergroups_wp' && selectedValue && selectedValue.length > 0) {
            let workerGroupIds = selectedValue.map(wGroup => wGroup.id);
            let options = wcData.workers?.options || [];
            let workerOpts = options.filter(opt => containsAny(opt.personGroupIds, workerGroupIds));
            let phases = [...this.state.workPhases];
            let phase = { ...phases[index] };

            if (Array.isArray(this.state.workers_wp)) {
                let workerSelects = this.state.workers_wp?.filter(w => workerOpts.find(wOpt => wOpt.id === w.id));

                phase[key] = selectedValue;
                phase['workers_wp'] = workerSelects;
                phases[index] = phase;

                this.setState(prevState => ({
                    ...prevState,
                    workPhases: phases
                }));

            } else {
                let workerSelect = workerOpts.find(wOpt => wOpt.id === this.state.workers_wp?.id)

                phase[key] = selectedValue;
                phase['workers_wp'] = workerSelect || null;
                phases[index] = phase;

                this.setState(prevState => ({
                    ...prevState,
                    workPhases: phases
                }));
            }
        } else {
            if (index >= 0) {
                let phases = [...this.state.workPhases];
                let phase = { ...phases[index] };

                phase[key] = selectedValue;
                phases[index] = phase;

                this.setState(prevState => ({
                    ...prevState,
                    workPhases: phases
                }));
            }
        }
    }

    handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string, index = null) => {
        const dateInput = handleDateTimeInput(date, name);
        this.setState({ hasChanged: true })

        if (index !== null) {
            let phases = [...this.state.workPhases];
            let phase = { ...phases[index] };
            phase[dateInput.name] = dateInput.value;
            phases[index] = phase;

            this.setState(prevState => ({
                ...prevState,
                workPhases: phases
            }))
        } else {
            this.setState({
                [dateInput.name]: dateInput.value
            });

            this.saveValueToStorage(dateInput.name, dateInput.value);

            if (dateInput.name == 'workcanbegin' && dateInput.value) {
                const value = this.getWorkCanEnd(dateInput.value);
                this.setState({ workcanend: value });
                this.saveValueToStorage("workcanend", value);
            }
        }
    }

    getWorkCanEnd = (value: string) => {
        if (isNullOrWhitespace(value)) {
            return value;
        }
        return getDateTimeBase(value).add(1, 'hours').format(moment.HTML5_FMT.DATETIME_LOCAL);
    }

    openDocumentForm = () => {
        this.setState({ showFileInput: true })
    }

    closeDocumentForm = () => {
        this.setState({
            showFileInput: false,
            invalidFields: false,
            missingMetaDatas: []
        })
    }

    setShowMaterialAddition = (value: boolean) => this.setState({ showMaterialAddition: value });
    
    documentList = (): string[] => this.state.documents.map(doc =>
        doc.file?.name || doc.description || doc.linkToDocument
    )

    materialList = (): string[] => this.state.materials.map(mat =>
        mat.wildSparePart || mat.spareParts[0].label
    )

    addDocument = () => {
        const { docRequirements } = this.state;
        const { linkToDocument, description, type, file, metaData } = this.docRef.current.state;

        if ((file === null && linkToDocument === '') || (file !== null && linkToDocument !== '')) {
            toast.info(i18n.t('SELECT_FILE_OR_LINK'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            return;
        }

        const missingMetaDatas = this.props.documentMetadatas.filter(field => {
            const val = metaData[field.label];
            return (field.required && (isNullOrWhitespace(val) || val?.id == -1));
        });

        if (missingMetaDatas.length || docRequirements?.find(req => req.field === 'description') && !description) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ 
                invalidFields: true,
                missingMetaDatas: missingMetaDatas.map(x => x.id)
            });
            return;
        }

        const documentMetaDataIds = Object.values(metaData)
            .map(data => data?.id ?? 0)
            .filter(id => id > 0)

        const document = {
            linkToDocument,
            description,
            type,
            file,
            documentMetaDataIds
        }

        this.setState(state => ({
            documents: Array.isArray(state.documents) ? state.documents.concat(document) : [document]
        }));
        this.closeDocumentForm();
    }

    addMaterial = (material) => {
        if (material == null) {
            this.setState({ 
                materialFormTrigger: false
            });
        } else {
            this.setState(state => ({ 
                materials: state.materials.concat([material]),
                materialFormTrigger: false
            }));
            this.setShowMaterialAddition(false);
        }
    }

    removeDocument = (index: number) => {
        let documents = this.state.documents.slice(0);
        documents.splice(index, 1);
        this.setState({ documents });
    }

    removeMaterial = (index: number) => {
        let materials = this.state.materials.slice(0);
        materials.splice(index, 1);
        this.setState({ materials: materials });
    }

    toggleMachines = () => {
        const { showMachines } = this.state;
        this.setState({
            showMachines: !showMachines
        })
    }

    toggleQrReader = (value) => {
        this.setState({ qrReading: !value ? false : value })
    }

    cancelFaultNotice = (params) => {
        this.props.history.replace(params.path, params.state);
    }

    navigateBackMachineSelect = (params) => {
        const referrer = [...params.referrer];
        this.props.history.replace(referrer.pop(), { referrer, cardType: params.cardType });
    }

    handleTabGroup = (value) => {
        this.setState({ tabGroupActiveKey: value });
        localStorage.setItem('activeRouteWcTab', value)
        localStorage.setItem('routeWcId', this.props.match.params.workCardId)
    }

    handleMachineSelect = (selection, isLevel) => {
        if (isLevel) return;

        const machine = this.props.hierarchyMachines.find(m => m.machineId === selection.machineId);

        if (!machine) return;
        const { machineId: id, name, machineCode: code } = machine;

        this.setState({
            machine: { id: id, code: code, name: name }
        }, () => {
            if (this.state.cardType === 'newFaultNotice') {
                this.getOpenWorkCards(this.state.machine.id)
                    .then(openWorkCards => {
                        this.setState({ openWorkCards })
                    })
            }
        });

        const cardType = this.state.cardType;

        const location = {
            pathname: '/workcard/new/',
            state: { cardType }
        }
        if (cardType === 'newFaultNotice') {
            location.pathname += 'faultnotice';
            this.props.history.replace(location);
        }
        if (cardType === 'newWorkCard') {
            location.pathname += 'workcard';
            this.props.history.replace(location);
        }
        if (cardType === 'editWorkCard') {
            const { id } = this.props.wcData;
            this.props.history.replace('/workcard/' + id + '/edit', { cardType });
        }
        this.toggleMachines();
    }

    handleScan = id => {
        request.get('/api/Machines/' + id)
            .then(({ data }) => {
                this.setState({
                    machine: { id: data.id, code: data.code, name: data.name, model: data.model }
                })
            }).catch(error => {
                toast.warn('MACHINE_NOT_FOUND', {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            })
    }

    handleError = err => {
        // error handling
    }

    getRequiredValue = (prop, settingKey, isDisabled) => {
        if (prop.type === 'label' || prop.type === 'textarealabel' || isDisabled) {
            return false;
        }
        const { formFieldSets } = this.props;
        const { cardType, worktype } = this.state;

        const settingsType = prop.column === 'notice'
            ? cardType === 'newFaultNotice'
                ? 'faultnotice'
                : 'workcard'
            : prop.column === 'report'
                ? 'workcardreport'
                : prop.column === 'workphase'
                    ? 'workcardphase'
                    : '';
        if (settingsType && settingKey) {
            let viewSetting = null;
            let statusVal = null;
            let workTypeVal = null;

            if (settingsType === 'workcardphase') {
                viewSetting = formFieldSets.workphase && formFieldSets.workphase.find(type => type.field === settingKey);
                statusVal = this.state.workstatus_wp;
            } else {
                viewSetting = formFieldSets[settingsType] && formFieldSets[settingsType].find(type => type.field === settingKey)
                statusVal = this.state.workstatus;
                workTypeVal = worktype && {
                    label: worktype.label,
                    value: (worktype.value ?? worktype.id)
                };
            }

            if (viewSetting) {
                return checkRequired(viewSetting, statusVal, workTypeVal);
            }
        }
        return false;
    }

    isInvalid = (val, key) => {
        const { invalidFields, invalidWorkCardForm, invalidPhaseForm } = this.state;

        if (invalidFields) {
            const stateVal = ((Array.isArray(val) && (val.length === 0 || (val.length === 1 && val[0].id === -1))) || !val);

            if (stateVal && key.includes('_wp') && !invalidPhaseForm) {
                this.setState({ invalidPhaseForm: true });
            } else if (stateVal && !invalidWorkCardForm) {
                this.setState({ invalidWorkCardForm: true });
            }

            return stateVal;
        } else if (invalidWorkCardForm || invalidPhaseForm) {
            this.setState({
                invalidWorkCardForm: false,
                invalidPhaseForm: false,
            });
        }

        return false;
    }

    invalidForm = (formType) => {
        const { wcData, wpData } = this.props;
        if (formType === 'notice') {
            const noticeProps = Object.keys(wcData)
                .filter(key => wcData[key].column == 'notice')
                .reduce((obj, key) => {
                    obj[key] = wcData[key];
                    return obj;
                }, {});

            Object.keys(noticeProps).map(key => (key !== 'id' && key !== 'code'
                ? noticeProps[key].required = this.getRequiredValue(noticeProps[key], noticeProps[key].settingKey, noticeProps[key].isDisabled)
                : key
            ));
            return !ValidateForm(noticeProps, this.state);

        } else if (formType === 'report') {
            const reportProps = Object.keys(wcData)
                .filter(key => wcData[key].column == 'report')
                .reduce((obj, key) => {
                    obj[key] = wcData[key];
                    return obj;
                }, {});

            Object.keys(reportProps).map(key => (key !== 'id' && key !== 'code'
                ? reportProps[key].required = this.getRequiredValue(reportProps[key], reportProps[key].settingKey, reportProps[key].isDisabled)
                : key
            ));
            return !ValidateForm(reportProps, this.state);
        } else if (formType === 'phase') {
            return !ValidateForm(wpData, this.state);
        }
    }

    getDetailOptions = (key, detailOpts) => {

        const { details } = this.props;
        const parent = this.state['faulttype']
            && (key === 'faultreason'
                || key === 'faultlocation'
                || key === 'cause'
                || key === 'avoidoffault')
            ? 'faulttype'
            : this.state['workcard_tyotyyppi'] && key === 'workcard_tyokokonaisuus'
                ? 'workcard_tyotyyppi'
                : '';

        if (key === 'worktype') {
            return mapOldOptionTypesToOptionsType(detailOpts);
        }

        if (parent === '' && detailOpts.some(opt => opt.parentId > 0)) {

            const parentIds = detailOpts.map(x => x.parentId);
            const parentGroup = details.find(d => parentIds.includes(d.id))?.group;
            const parentValue = this.state[parentGroup];
            const parentIdValue = parentValue?.value ?? parentValue?.id;

            if (parentGroup && parentValue) {
                return detailOpts.filter(d => d.parentId === parentIdValue);
            }
        }

        return filterDetailOptions(detailOpts, this.state[parent]);
    }

    filterWorkerOptions = (workerOpts, parentGroupKey = 'workergroups') => {
        if (this.state[parentGroupKey]?.length > 0) {
            const workerGroupIds = this.state[parentGroupKey]?.map(workerGroup => workerGroup?.id);
            return workerOpts.filter(worker => containsAny(worker.personGroupIds, workerGroupIds));
        }

        return workerOpts || [];
    }

    addPhase = () => {
        const { wpData } = this.props;
        const { workPhases } = this.state;
        let workPhase = {};

        let maxId = 0;

        for (const wPhase of workPhases) {
            if (wPhase && wPhase.id > maxId) maxId = wPhase.id;
        }

        Object.keys(wpData).forEach(key => {
            workPhase[key] = key === 'id'
                ? maxId + 1
                : key === 'workstatus' && wpData[key].value === ""
                    ? wpData.workstatus_wp.options[0]
                    : wpData[key].value;
        });

        this.setState(prevState => ({
            workPhases: [...prevState.workPhases, workPhase],
            workPhasesFormData: [...prevState.workPhasesFormData, wpData],
        }));
    }

    removePhase = (phaseIndex) => {
        let phases =  [...this.state.workPhases];
        let phasesData = [...this.state.workPhasesFormData];
        phases.splice(phaseIndex, 1);
        phasesData.splice(phaseIndex, 1);

        this.setState({
            workPhases: phases,
            workPhasesFormData: phasesData
        });
    }

    calculateIdleTime = () => {
        if (!this.props.autoCalcIdleTime) return;

        const isUndefinedOrNull = v => typeof v === 'undefined' || v === null || v === '' ? true : false;
        const idleTimeEnd = this.state.machinehalt?.id ? this.state.faultbegin : this.state.workbegin;

        if (!isUndefinedOrNull(this.state.workended) && !isUndefinedOrNull(idleTimeEnd)) {
            const idleTimeMilliseconds = new Date(this.state.workended).getTime() - new Date(idleTimeEnd).getTime();
            this.setState({ idletime: (idleTimeMilliseconds / (1000 * 60 * 60)).toFixed(2) });
        }
    }

    getTitle = () => {
        let str = '';
        if (this.state.showMachines) {
            str = 'CHOOSE_MACHINE';
        } else if (this.state.showFileInput) {
            str = 'ADD_DOCUMENT';
        } else if (this.state.showMaterialAddition) {
            str = 'ADD_MATERIAL';
        } else if (this.state.qrReading) {
            str = 'SCAN_MACHINE';
        } else if (this.props.location?.state?.routeWc) {
            str = 'RM_NEW_FAULTNOTICE';
        } else if (this.props.location?.state?.ompId) {
            str = 'OPM_NEW_FAULTNOTICE';
        } else if (!this.props.match.path.includes('/workcard/new')) {
            str = 'EDIT_WORKCARD';
        } else if (this.state.cardType === 'newFaultNotice') {
            str = 'NEW_FAULT_NOTICE';
        } else if (this.state.cardType === 'newWorkCard') {
            str = 'NEW_WORKCARD';
        }
        return i18n.t(str);
    }

    getActionButtonConfig = (): { label:string; isActionFn: boolean; icon: string; clickFn: Function; params?: any[]; status?: string } => {
        if (this.state.showMachines) {
            return null;
        } else if (this.state.showFileInput) {
            return { 
                label: '',
                isActionFn: true,
                icon: 'confirm',
                clickFn: this.addDocument
            };
        } else if (this.state.showMaterialAddition) {
            return {
                label: '',
                isActionFn: true,
                icon: 'confirm',
                clickFn: () => this.setState({ materialFormTrigger: true })
            }
        } else {
            return {
                label: '',
                isActionFn: true,
                icon: this.props.status === 'pending' ? 'spinner' : 'save',
                clickFn: this.props.status === 'pending' ? () => { return; } : this.handleSubmit,
                status: this.props.status
            };
        }
    }

    getBackButtonConfig = (): { action: (params?) => void, params?: { path?: string; state?; referrer?: string[]; cardType?: string; mainEditView?: boolean; } } => {
        if (this.state.showFileInput) {
            return { action: this.closeDocumentForm };
        } else if (this.state.showMaterialAddition) {
            return { action: () => this.setShowMaterialAddition(false) };
        } else if (this.state.showMachines) {
            if (this.getMachineViewReferrer().length > 1) {
                return { 
                    action: this.navigateBackMachineSelect,
                    params: {
                        referrer: this.props.history.location.state.referrer,
                        cardType: this.state.wcStatus
                    }
                }
            } else {
                return { action: this.toggleMachines };
            }
        } else if (this.state.qrReading) {
            return { action: this.toggleQrReader };
        } else if (this.props.location.state?.opmState) {
            return {
                action: this.cancelFaultNotice,
                params: {
                    path: `/operatormaintenance/${this.props.location.state.opmId}`,
                    state: { ...this.props.location.state }
                }
            }
        } else if (this.props.location.state?.routeWcState) {
            return {
                action: this.cancelFaultNotice,
                params: {
                    path: `/routeworkcard/${this.props.location.state.routeWc}`,
                    state: { ...this.props.location.state }
                }
            }
        } else if (this.props.location.state?.machineState) {
            return {
                action: this.cancelFaultNotice,
                params: {
                    path: `/machine/${this.props.location.state.machine.id}`,
                    state: { ...this.props.location.state }
                }
            }
        }
        return { action: this.props.history.goBack, params: {mainEditView: true}};
    }

    getMachineViewReferrer = (): string[] => {
        const { history, location } = this.props;
        let newReferrer = [];
        if (typeof history.location.state !== 'undefined') {
            if (!history.location.state.referrer) {
                newReferrer = [].concat(location.pathname);
            } else {
                newReferrer = [...history.location.state.referrer].concat(location.pathname);
            }
        } else {
            newReferrer = [].concat(location.pathname);
        }
        return newReferrer;
    }

    render() {
        const {
            wcData,
            history,
            location,
            match,
            firstPhaseRequired,
            wcColors,
            phasesEnabled,
            defaultText
        } = this.props;

        const {
            showMachines,
            showFileInput,
            wcStatus,
            invalidFields,
            isSubmitted,
            edit,
            workPhases,
            workPhasesFormData,
            cardType,
            showMaterialAddition,
            defaultTextExpanded
        } = this.state;

        const sceneData = {
            view: !(match.path.includes('/workcard/new'))
                ? 'workcard'
                : location.state && cardType === 'newFaultNotice'
                    ? 'newfaultnotice'
                    : 'newWorkCard',
            title: this.getTitle(),
            location: location,
            history: history,
            itemColors: wcColors,
            backAction: this.getBackButtonConfig(),
            hasChanged: this.state.hasChanged,
        }

        const wcDataLoaded = Object.keys(wcData).length > 1 && !this.state.loading;

        return (
            <div>
                <ScrollToTop />
                <NavigationBar
                    currentView={sceneData}
                    navHistory={history}
                    viewAction={this.getActionButtonConfig()}
                    popoverData={''}
                />
                {showMachines &&
                    <Machines
                    machineSelect
                    itemStatus={wcStatus}
                    onMachineSelect={this.handleMachineSelect}
                    referrer={this.getMachineViewReferrer()}
                    />
                }
                {showFileInput && <FileInput 
                    ref={this.docRef} 
                    docRequirements={this.state.docRequirements} 
                    invalidFields={this.state.invalidFields} 
                    missingMetaDatas={this.state.missingMetaDatas}
                    docMetadata={this.props.documentMetadatas} 
                />}
                {showMaterialAddition && (
                    <MaterialForm
                        submit={this.state.materialFormTrigger}
                        onSubmit={this.addMaterial}
                        workScheduleRights="addition"
                        setHasChanged={val => this.setState({ hasChanged: val ?? true })}
                    />
                )}
                {!showMachines && !showFileInput && !showMaterialAddition && <div className="work-card-view">
                    {wcStatus === 'newFaultNotice' && <Container>
                        <Loader status={wcDataLoaded ? 'fulfilled' : 'pending'} ready={!isSubmitted} loadingText={wcDataLoaded && i18n.t('SAVING')} />
                        {wcDataLoaded && <div className="form-table-container bottom-nav-space">

                            {!isNullOrWhitespace(defaultText) && <div className="sub-header">
                                {/* React automatically escapes html within strings, so in order to allow <br> functionality we have to avoid html escaping */}
                                {defaultTextExpanded && <div dangerouslySetInnerHTML={{__html: defaultText }} />}
                                <div className="center" onClick={() => this.setState({ defaultTextExpanded: !defaultTextExpanded })}>
                                    <FontAwesomeIcon icon={defaultTextExpanded ? faArrowUp : faArrowDown} size="lg" color="#5e5e5e" />
                                </div>
                            </div>}
                            {defaultText === null && <Loader ready={false} />}

                            <form>
                                <WorkCardForm
                                    wcData={wcData}
                                    wcState={this.state}
                                    column={'notice'}
                                    match={match}
                                    handleInputChange={this.handleInputChange}
                                    handleDateChange={this.handleDateTime}
                                    handleSelect={this.handleSelect}
                                    handleScan={this.handleScan}
                                    handleCreateOption={this.handleCreateOption}
                                    toggleMachines={this.toggleMachines}
                                    getRequiredValue={this.getRequiredValue}
                                    isInvalid={this.isInvalid}
                                    getDetailOptions={this.getDetailOptions}
                                    filterWorkerOptions={this.filterWorkerOptions}
                                    notificationTargets={this.props.notificationTargets}
                                    filterArchivedCostPools={filterArchivedCostPools}
                                    toggleQrReader={this.toggleQrReader}
                                    qrReading={this.state.qrReading}
                                />
                            </form>
                            <hr />
                            <ListWithButtonAndRemoveItem
                                title={i18n.t('DOCUMENTS').toUpperCase()}
                                buttonText={i18n.t('ADD_DOCUMENT')}
                                handleBtnClick={this.openDocumentForm}
                                itemList={this.documentList()}
                                removeItem={this.removeDocument}
                                noItemsText={i18n.t('NO_DOCUMENTS')}
                            />
                            {this.state.openWorkCards.length > 0 && <>
                                <hr />
                                <h2>{i18n.t('OPEN_WORK_CARDS').toUpperCase()}</h2>
                                <WorkCards
                                    cardList={this.state.openWorkCards.map(workCard => ({ workCard }))}
                                    showPhases={false}
                                    cardLayout={true}
                                />
                            </>}
                        </div>}
                    </Container>}
                    {wcStatus !== 'newFaultNotice' && <Tabs activeKey={this.state.tabGroupActiveKey}
                        onSelect={this.handleTabGroup}
                        id="workCardViewTabs"
                        className="novi-nav-tabs"
                    >
                        <Tab eventKey={1}
                            title={wcStatus === 'editWorkCard' ? i18n.t('NOTICE').toUpperCase() : i18n.t('WORKCARD').toUpperCase()}
                            tabClassName={invalidFields && this.invalidForm('notice') ? 'invalid-tab-content' : ''}
                        >
                            <Container>
                                <Loader status={wcDataLoaded ? 'fulfilled' : 'pending'} ready={!isSubmitted} loadingText={wcDataLoaded && i18n.t('SAVING')} />
                                {wcDataLoaded && <div className="form-table-container bottom-nav-space">
                                    <form>
                                        {wcStatus !== 'editWorkCard' && <h2>{i18n.t('NOTICE').toUpperCase()}</h2>}
                                        <WorkCardForm
                                            wcData={wcData}
                                            wcState={this.state}
                                            column={'notice'}
                                            match={match}
                                            handleInputChange={this.handleInputChange}
                                            handleDateChange={this.handleDateTime}
                                            handleSelect={this.handleSelect}
                                            handleScan={this.handleScan}
                                            handleCreateOption={this.handleCreateOption}
                                            toggleMachines={this.toggleMachines}
                                            getRequiredValue={this.getRequiredValue}
                                            isInvalid={this.isInvalid}
                                            getDetailOptions={this.getDetailOptions}
                                            filterWorkerOptions={this.filterWorkerOptions}
                                            notificationTargets={this.props.notificationTargets}
                                            filterArchivedCostPools={filterArchivedCostPools}
                                            toggleQrReader={this.toggleQrReader}
                                            qrReading={this.state.qrReading}
                                        />
                                        {wcStatus === 'newWorkCard' && <h2>{i18n.t('REPORTAGE').toUpperCase()}</h2>}
                                        {wcStatus === 'newWorkCard'
                                            && <WorkCardForm
                                                wcData={wcData}
                                                wcState={this.state}
                                                column={'report'}
                                                match={match}
                                                handleInputChange={this.handleInputChange}
                                                handleDateChange={this.handleDateTime}
                                                handleSelect={this.handleSelect}
                                                handleScan={this.handleScan}
                                                handleCreateOption={this.handleCreateOption}
                                                toggleMachines={this.toggleMachines}
                                                getRequiredValue={this.getRequiredValue}
                                                isInvalid={this.isInvalid}
                                                getDetailOptions={this.getDetailOptions}
                                                filterWorkerOptions={this.filterWorkerOptions}
                                                notificationTargets={this.props.notificationTargets}
                                                filterArchivedCostPools={filterArchivedCostPools}
                                                toggleQrReader={this.toggleQrReader}
                                                qrReading={this.state.qrReading}
                                            />
                                        }
                                    </form>
                                    {wcStatus === 'newWorkCard' && <hr />}
                                    {wcStatus === 'newWorkCard' && <div id="documents">
                                        <ListWithButtonAndRemoveItem
                                            title={i18n.t('DOCUMENTS').toUpperCase()}
                                            buttonText={i18n.t('ADD_DOCUMENT')}
                                            handleBtnClick={this.openDocumentForm}
                                            itemList={this.documentList()}
                                            removeItem={this.removeDocument}
                                            noItemsText={i18n.t('NO_DOCUMENTS')}
                                        />
                                    </div>}
                                    {wcStatus === 'newWorkCard' && <hr />}
                                    {wcStatus === 'newWorkCard' && <div id="materials">
                                        <ListWithButtonAndRemoveItem
                                            title={i18n.t('MATERIALS').toUpperCase()}
                                            buttonText={i18n.t('ADD_MATERIAL')}
                                            handleBtnClick={() => this.setShowMaterialAddition(true)}
                                            itemList={this.materialList()}
                                            removeItem={this.removeMaterial}
                                            noItemsText={i18n.t('NO_MATERIALS')}
                                        />
                                    </div>}
                                </div>}
                            </Container>
                        </Tab>
                        {/* Display only if workphases are in use */}
                        {phasesEnabled && !edit && cardType === 'newWorkCard' && <Tab eventKey={2}
                            title={i18n.t('WORKPHASE').toUpperCase()}
                            tabClassName={invalidFields && this.invalidForm('phase') ? 'invalid-tab-content' : ''}
                        >
                            <WorkPhasesAdd
                                filterDetailOptions={this.getDetailOptions}
                                phases={workPhasesFormData}
                                workPhasesState={workPhases}
                                handleInputChange={this.handleInputChange}
                                handleDateChange={this.handleDateTime}
                                handleSelect={this.handleWorkPhaseSelect}
                                getRequiredValue={this.getRequiredValue}
                                isInvalid={this.isInvalid}
                                filterWorkerOptions={this.filterWorkerOptions}
                                addPhase={this.addPhase}
                                removePhase={this.removePhase}
                                disabled={this.state.addPhaseDisabled}
                            />
                        </Tab>}
                        {wcStatus === 'editWorkCard' && <Tab eventKey={2}
                            title={i18n.t('REPORTAGE').toUpperCase()}
                            tabClassName={invalidFields && this.invalidForm('report') ? 'invalid-tab-content' : ''}
                        >
                            <Container>
                                <div className="form-table-container bottom-nav-space">
                                    <Loader ready={!isSubmitted} loadingText={i18n.t('SAVING')} />
                                    <form>
                                        <WorkCardForm
                                            wcData={wcData}
                                            wcState={this.state}
                                            column={'report'}
                                            match={match}
                                            handleInputChange={this.handleInputChange}
                                            handleDateChange={this.handleDateTime}
                                            handleSelect={this.handleSelect}
                                            handleScan={this.handleScan}
                                            handleCreateOption={this.handleCreateOption}
                                            toggleMachines={this.toggleMachines}
                                            getRequiredValue={this.getRequiredValue}
                                            isInvalid={this.isInvalid}
                                            getDetailOptions={this.getDetailOptions}
                                            filterWorkerOptions={this.filterWorkerOptions}
                                            notificationTargets={this.props.notificationTargets}
                                            filterArchivedCostPools={filterArchivedCostPools}
                                            toggleQrReader={this.toggleQrReader}
                                            qrReading={this.state.qrReading}
                                        />
                                    </form>
                                </div>
                            </Container>
                        </Tab>}
                    </Tabs>}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const isOffline = !state.offline.online;
    let wc = !isOffline
        ? state.workcards.workcards.find(wc => wc && wc.id === parseInt(ownProps.match.params.workCardId, 10))
        : state.workcards.storedWorkCards.find(wc => wc && wc.id === parseInt(ownProps.match.params.workCardId, 10));

    let edit = false;
    let cardType;

    // Use a cardType from location state if one is given, otherwise use type from URL
    if (ownProps.location?.state?.cardType) {
        cardType = ownProps.location.state.cardType;
    }
    else {
        cardType = { faultnotice: "newFaultNotice", workcard: "newWorkCard" }[ownProps.match.params.type] ?? "newWorkCard";
    }

    if (ownProps.match.url.match(/\/edit/)) {
        cardType = "editWorkCard";
        edit = true;
    }

    let machine = null;
    const machineId = ownProps.match.params.machineId;

    // Use a machine if one is given in location state, otherwise if machineId is given in URL then fetch that
    if (ownProps.location?.state?.machine) {
        machine = ownProps.location.state.machine;
    }
    else if (machineId) {
        const machines = state.machines.machines;
        machine = machines.find(x => {
            return x.id == machineId;
        });
    }

    const isFaultNotice = cardType === 'newFaultNotice' ? true : false;
    const reportReady = ownProps.location.state?.reportReady;
    const wpFields = state.workcards.viewSettings && state.workcards.viewSettings.workcardphase;
    const {
        AllowOneWorkerPerPhase,
        AutomaticFirstPhase,
        NoDateValidation,
        AlternativeDateValidation,
        PreventWorkStatusFinishedIfAllPhasesNotFinished,
        EnablePhases,
        WorkCardPhaseCalculationType
    } = state.settings.noviConfigs;

    let fields = {
        faultnotice: state.workcards.viewSettings['faultnotice'],
        workcard: state.workcards.viewSettings['workcard'],
        workcardreport: state.workcards.viewSettings['workcardreport'],
        workcardadd: state.workcards.viewSettings['workcardadd'],
        workcardaddreport: state.workcards.viewSettings['workcardaddreport']
    };

    const workerGroups = state.settings.workerGroups.map(wGroup => ({ id: wGroup.id, label: wGroup.name }));
    const responsiblePeople = state.settings.responsiblePeopleByCategory.map(resPerson => ({ id: resPerson.id, label: resPerson.name }))
    const userId = state.settings.userAdNameDetails?.id || state.settings.userId;
    const { ordererOptions, workerOptions } = state.workcards.options;
    const userBelongsToOrderers: boolean = ordererOptions && ordererOptions.some(orderer => orderer.id === parseInt(userId, 10));
    const { statusTypes, urgencyTypes, userDetails, userAdNameDetails } = state.settings;
    const currentUser = userAdNameDetails?.id ? userAdNameDetails : userDetails;
    const finishedWorkStasuses = state.workcards.workcardsSettings.finishedWorkStatuses ?? [];
    let statusTypeOptions = [];
    let wcData: any = {
        id: typeof wc !== 'undefined'
            ? wc.id
            : -1//TODO: temp ID -value for offline use?
    };
    const wcMachine = wc?.id ? state.machines.machines.find(m => m.id === wc?.machine?.id) : null;

    statusTypes.forEach(statusType => {
        let sType = { ...statusType };

        if (wc && wc.phases && wc.phases.find(wcPhase => !finishedWorkStasuses.includes(wcPhase.workStatus))
            && PreventWorkStatusFinishedIfAllPhasesNotFinished === 'True'
        ) {
            if (sType.statusValue === 4) {
                sType.isDisabled = true;
            }
        }
        statusTypeOptions.push(sType);
    })

    let optionLists = { ...state.workcards.options };
    optionLists.urgencyOptions = urgencyTypes;
    optionLists.workerGroups = workerGroups;
    optionLists.statusOptions = statusTypeOptions;
    optionLists.ordererOptions = ordererOptions;
    optionLists.workerOptions = workerOptions;
    optionLists.responsiblePersonOptions = responsiblePeople;
    optionLists.costPoolOptions = state.costpools

    if (state.settings.noviConfigs.LimitedWorkStatuses === 'True') {
        let statusValues = []
        state.workcards.limitedWorkStatuses?.allowedworkstatus?.forEach(status => {
            statusValues.push(parseInt(status.field))
        });;

        optionLists.statusOptions = optionLists.statusOptions.filter(i => statusValues?.includes(i.id))
    }

    // Init workcard form for new workcard
    if (fields && !wc) {
        // TODO: Initialize new work card properly with all properties and initial values?
        wc = {};
        wc.workCardDetailIds = [];
        wc.orderer = null;
        wc.orderertext = null;
    }

    const phasesData = wc?.phases ? GetWorkCardPhasesData(wc.phases) : null;

    let tempWc = {};

    Object.keys(wc).forEach(key => {
        reportReady && key === 'workEnded' && (!wc[key] || wc[key] === null)
            ? tempWc[key.toLowerCase()] = new Date()
            : tempWc[key.toLowerCase()] = wc[key];
    });

    let tempWp = {};
    if (wpFields) {
        wpFields.forEach(fieldItem => {
            tempWp[fieldItem.field] = '';
        })
    }

    const getOrdererValue = function (type, property) {
        switch (type) {
            // Type orderer mean that the field is select component.
            case 'orderer':
            case 'label':
                if (ownProps.match.path.includes('/workcard/new') === true && userBelongsToOrderers) {
                    return currentUser !== null ? currentUser : null
                } else {
                    return property['orderer'] !== null ? property['orderer'] 
                    : (property['orderertext'] !== null && property['orderertext'] !== '') ? ordererOptions.find(orderer => orderer.label === property['orderertext'])
                        : null 
                }
            case 'text': 
                if (ownProps.match.path.includes('/workcard/new') === true && userBelongsToOrderers) {
                    return currentUser !== null ? ordererOptions.find(orderer => orderer.label === currentUser.name).label : ''
                } else {
                    return (property['orderertext'] !== null && property['orderertext'] !== '') ? property['orderertext']
                    : property['orderer'] !== null ? property['orderer'].name
                        : ''
                }
            default:
                return '';
        }
    }

    const generateFormElementData = function (fields, column = '', item, dataPrototype, dataType) {
        if (!fields || fields?.length === 0) return [];
        const fieldsData = [...fields];

        fieldsData.sort((a, b) => a['tabOrder'] - b['tabOrder']);

        const details = state.workcards.details?.length > 0 ? [...state.workcards.details] : [];
        const phaseDetails = state.workcards.phaseDetails?.length > 0 ? [...state.workcards.phaseDetails] : [];

        // Filter details by viewFilter value | TODO: add enum mapper file for viewFilters instead hard coding here
        const wcDetails = details.filter(detail => (detail.viewFilter === null || detail.viewFilter === undefined)
            || (isFaultNotice && detail.viewFilter?.includes('1'))
            || (!isFaultNotice && cardType === 'newWorkCard' && detail.viewFilter?.includes('4'))
            || (cardType === 'editWorkCard' && detail.viewFilter?.includes('5'))
        );

        const machine = state?.machines?.machines?.find(m => m?.id == wc?.machine?.id);

        const detailOpts = dataType === 'workphase' ? phaseDetails : wcDetails;

        fieldsData.forEach(fieldItem => {
            let { field, type, translationKey, values } = fieldItem;
            const relationData = type === 'costpoolgroup' || field === 'machinetype' ? wcMachine : phasesData;

            if (field && type) {
                const fieldProp = GetProperty(field, type);
                const key = dataType === 'workphase'
                    ? fieldProp + '_wp'
                    : fieldProp === 'costpools'
                        ? field
                        : fieldProp;

                // Dealing with multiple translationKeys in a single string would require special checks and handling
                // Instead of doing that everywhere, do it here once and set the translationKey as the already translated value
                if (type === "costpoolgroup" && translationKey?.match) {
                    // COSTPOOL_ON_<Target> (eg. MACHINE) + <Space or _> + <translationKey>
                    const matches = translationKey.match(/^(COSTPOOL_ON_[A-Z0-9]*)[ |_](.*)$/i);

                    if (matches && matches[1] && matches[2]) {
                        translationKey = `${i18n.t(matches[1])} ${i18n.t(matches[2])}`;
                    }
                }

                const isFieldMultiSelect = type === 'multidetail';

                item[key] = {
                    label: i18n.t(translationKey),
                    value: field === 'workcardid' && cardType === 'editWorkCard'
                        ? wcData.id
                        : field === 'machineparent' && wcMachine?.parent
                            ? wcMachine.parent.machineCode ? wcMachine.parent.machineCode + ' / ' + wcMachine.parent.name : wcMachine.parent.name
                            : field === 'orderer'
                                ? getOrdererValue(type, dataPrototype)
                                : field === 'machinerequirements'
                                    ? wcMachine?.machineRequirements
                                    : field === 'machinecostpool'
                                        ? wcMachine?.costpool
                                        : type === 'detail' || type === 'multidetail' || type === 'phasedetail'
                                            ? GetDetail(wc.details, field, isFieldMultiSelect, fieldItem.defaultValue, wcDetails, cardType)
                                            : type === 'machinedetail'
                                                ? machine?.machineDetails?.find(d => d.group === field)?.value ?? ""
                                                : type === 'thirdparty'
                                                    ? []
                                                    : type === 'machinethirdparty'
                                                        ? ""
                                                        : field === 'machinerating'
                                                            ? machine?.rating?.value ?? ""
                                                                : type === 'machineextradata'
                                                                    ? GetExtraDataValue(field, machine?.extraDatas)
                                                                    : GetFormInputValueByField(
                                                                        dataPrototype[fieldProp],
                                                                        type,
                                                                        field,
                                                                        { ...ownProps.location, state: { machine, cardType, ...(ownProps.location.state ?? {}) }},
                                                                        state.settings.noviConfigs,
                                                                        optionLists,
                                                                        userId,
                                                                        relationData,
                                                                        'workcard',
                                                                        wc.extraDatas,
                                                                        isOffline,
                                                                        wc.notificationTargets,
                                                                        fieldItem.defaultValue,
                                                                        cardType
                                                                    ),
                    options: GetOptionsDataByField(field, type, values, optionLists, detailOpts, dataType, isFieldMultiSelect),
                    type: type === 'worker' && AllowOneWorkerPerPhase === 'True' && dataType === 'workphase'
                        ? 'select'
                        : GetType(field, type),
                    onChangeType: isFieldMultiSelect
                        ? 'select'
                        : GetOnChangeType(type, key),
                    column: column,
                    settingKey: field,
                    isDisabled: (field === 'orderer' && type === 'label') ||
                        field === 'machinerequirements' ||
                        restrictRenderingByParameters(field, state.settings.userRights)
                        ? true
                        : dataType === 'workphase' && WorkCardPhaseCalculationType === '1' && field === 'workbegin'
                            ? true
                            : dataType === 'workphase' && WorkCardPhaseCalculationType === '2' && field === 'workended'
                                ? true
                                : false
                }

                if (type === "machinethirdparty" && machine?.thirdPartiesByTypes) {

                    const tParty = machine.thirdPartiesByTypes.find(tP => tP.type.id == parseInt(item[key].settingKey, 10));

                    if (tParty) {
                        item[key].value = tParty.thirdParties.map(x => x.name).join(", ");
                    }
                }
            }
        });

        return fieldsData;
    }

    let workphaseData: any = {};
    workphaseData = {
        id: wc?.phases?.length > 0
            ? Math.max(...wc.phases.map(wp => wp.id)) + 1
            : 1
    };
    
    const generateFormElementsByWorkCardType = (cardType: string, viewFields, column: string) => {
        /* Generate different fields when editing existing work card and when creating a new one. 
            The idea behind this is that the addition view should be simpler than edit. */
        if (cardType === 'newWorkCard') {
            // Handle fields for work card addition.
            if (column === 'notice') {
                return viewFields.workcardadd ? generateFormElementData(viewFields.workcardadd, 'notice', wcData, tempWc, 'workcard') : null
            } else if (column === 'report') {
                return viewFields.workcardaddreport ? generateFormElementData(viewFields.workcardaddreport, 'report', wcData, tempWc, 'workcard') : null
            } else {
                return null;
            }
        }
        else if (cardType === 'editWorkCard') {
            // Handle fields for work card edit.
                const hideLabels = state.settings.noviConfigs?.MobileHideLabelsInWorkCardEdit == "True";

                const shouldBeVisible = field => !field.field.match(/machine_costpoolgroup_/i) && !field.type.match(/label|thirdparty|machine(extradata|detail|costpool|extralocation|parent)/i);
            if (column === 'notice') {
                    const fields = hideLabels 
                    ? viewFields.workcard.filter(shouldBeVisible) 
                    : viewFields.workcard;
                    return viewFields.workcard ? generateFormElementData(fields, 'notice', wcData, tempWc, 'workcard') : null
            } else if (column === 'report') {
                     const fields = hideLabels 
                    ? viewFields.workcardreport.filter(shouldBeVisible) 
                    : viewFields.workcardreport;
                    return viewFields.workcardreport ? generateFormElementData(fields, 'report', wcData, tempWc, 'workcard') : null
            }
        }
        else {
            return null;
        }
    }
    const formFieldSets = {
        faultnotice: isFaultNotice && fields.faultnotice ? generateFormElementData(fields.faultnotice, 'notice', wcData, tempWc, 'workcard') : null,
        workcard: !isFaultNotice && generateFormElementsByWorkCardType(cardType, fields, 'notice'),
        workcardreport: !isFaultNotice && generateFormElementsByWorkCardType(cardType, fields, 'report'),
        workphase: wpFields ? generateFormElementData(wpFields, 'workphase', workphaseData, tempWp, 'workphase') : null
    }

    const colorConfigs = {
        colors: state.settings.colors,
        settings: state.settings.colorSettings,
        btn: state.settings.colorConfig['FillTags']
    }

    const machines = !isOffline ? state.machines.machines : state.machines.storedMachines;

    return {
        wcData: wcData,
        wpData: workphaseData,
        wcExtraDatas: wc.extraDatas,
        wcColors: wc && wc.id ? DefineColors(wc, colorConfigs) : null,
        workcardPhases: wc?.phases || [],
        phasesEnabled: EnablePhases?.toLowerCase() === 'true' ? true : false,
        firstPhaseRequired: AutomaticFirstPhase !== 'False' && ownProps.location.pathname.includes('/workcard/new'),
        preventIfUnfinishedPhases: PreventWorkStatusFinishedIfAllPhasesNotFinished === 'True' ? true : false,
        machines: machines,
        machine: machine,
        noDateValidation: NoDateValidation === 'True',
        alternativeDateValidation: AlternativeDateValidation === 'True',
        hierarchyMachines: state.machines.hierarchyMachines,
        location: ownProps.location,
        history: ownProps.history,
        statusTypes: statusTypeOptions,
        formFieldSets: formFieldSets,
        isOffline: isOffline,
        autoCalcIdleTime: state.settings.noviConfigs.AutoCalcIdleTime === 'True',
        workcardsSettings: state.workcards.workcardsSettings,
        status: state.workcards.status,
        notificationTargets: wc.notificationTargets,
        noviConfigs: state.settings.noviConfigs,
        settings: state.settings,
        costpools: ownProps.location.state?.machine?.costPools ?? [],
        materials: state.workcards.materials,
        cardType: cardType,
        edit: edit,
        documentMetadatas: state.documents.metadatas,
        defaultText: state.workcards.faultNoticeDefaultText,
        details: state.workcards.details?.length > 0 ? [...state.workcards.details] : []
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    addFaultNotice,
    addWorkcard,
    updateWorkCard,
    fetchWorkCard,
    fetchWorkCardDetails,
    fetchWorkPhaseDetails,
    fetchViewSettings,
    fetchWorkcardOptions,
    fetchWorkerGroups,
    fetchMachine,
    fetchResponsiblePeopleByCategory,
    fetchUrgencies,
    fetchCostPools,
    setApiCallFulfilled,
    fetchWorkCardLimitedWorkStatuses,
    getWorkCardColors,
    fetchWorkCardMaterials,
    setWorkStatuses,
    fetchDocumentMetadatas,
    fetchSparePartDetails,
    fetchFaultNoticeDefaultText
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>; 

export default withRouter(connector(EditWorkCard));
