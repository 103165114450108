import React from 'react';

const SortElemenetsByRowNumber = (props: IProps) => {
    const elements = React.Children
        .map(props.children, child => child && React.cloneElement(child))
        .sort((a, b) => a.props.rowNumber - b.props.rowNumber);
    return (
        <React.Fragment>
            {elements.map((element, index) => (
                <React.Fragment key={index}>
                    {element}
                    {props.addHrAfterEachElement && elements.length > index + 1 && <hr />}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}

interface IProps {
    children;
    addHrAfterEachElement?: boolean
}

export default SortElemenetsByRowNumber;