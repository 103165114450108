import React, { useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import noviAPI from '../../api/noviAPI'
import NavigationBar from '../navigation'
import settingsAPI from '../../config/settingsAPI'
import i18n from '../../translations/i18n'
import { Loader } from '../../components/Loader'
import { ScrollToTop } from '../../components/ScrollToTop'
import { HandleError, Toaster, isNullOrWhitespace } from '../../components/HelperFunctions';
import { Container, Toast } from 'react-bootstrap';
import FormElement from 'scenes/work-schedule/work-card/components/FormElement';
import useViewSettings from 'hooks/useViewSettings';
import { DATE_FORMAT, handleDateTimeInput } from 'utils';
import DialogModal from 'components/dialogs/DialogModal';
import DialogFooter from 'components/dialogs/DialogFooter';
import DialogBody from 'components/dialogs/DialogBody';
import SearchModalButtonContainer from 'components/search-modal/SearchModalButtonContainer';
import Machines from 'scenes/machines';
import { toast } from 'react-toastify';
import moment, { HTML5_FMT } from 'moment';
import FileInput from 'components/FileInput';
import ListWithButtonAndRemoveItem from 'components/lists/ListWithButtonAndRemoveItem';
import { fetchDocumentMetadatas } from 'commonActions/actions';
import { addSafetyNoticeDocument } from './actions'; 

const moduleSettings = settingsAPI.moduleData.safety;

type LocationState = { workCardId?: number; measurementGroupId?: number; referrer: any[]; };
type Values = { [name: string]: any; };

type UrlParams = {
    id: string;
    action: 'edit' | 'add';
};

const EditSafetyNotice = () => {
    const { userDetails, machineGroupId } = useSelector((state: State) => state.settings);
    const { hierarchyMachines } = useSelector((state: State) => state.machines);
    const { metadatas } = useSelector((state: State) => state.documents);

    const dispatch = useDispatch();
    const { id, action } = useParams<UrlParams>();
    let location = useLocation<LocationState>();
    let history = useHistory<any>();

    const docRef = useRef<FileInput>(null);

    const [showFileInput, setShowFileInput] = useState(false);
    const [docRequirements, setDocRequirements] = useState([]);
    const [missingMetaDatas, setMissingMetaDatas]= useState([]);
    const [invalidDocumentFields, setInvalidDocumentFields] = useState(false);
    const [documents, setDocuments] = useState([]);

    const [showMachines, toggleMachines] = useReducer(prevVal => {
        // Remove machineIds path from url if pressing back button, TODO: Refactor
        if (prevVal) history.replace(`/safetynotices/${id}/new`);
        return !prevVal;
    }, false);
    const [detailOptions, setDetailOptions] = useReducer((prevDetails, newDetails) => {
        return Object.assign({}, prevDetails, newDetails);
    }, {});

    const [viewSettings] = useViewSettings(`safetycard${id}`, `FETCH_SAFETYCARD_VIEW_SETTINGS`);
    const [safetyDetails, setSafetyDetails] = useState<ISafetyDetail[]>([]);

    const [loadingItems, setLoading] = useReducer((oldValues, newValue) => {
        return Object.assign({}, oldValues, newValue);
    }, {
        viewSettings: true,
        details: true,
        project: true,
        responsibleperson: true,
    });
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        noviAPI.safetyTypeDetails.fetchAll(id)
            .then(({ data }) => {
                const sortedResults = data.results.sort((a, b) => a.rowNumber - b.rowNumber);
                setSafetyDetails(sortedResults);
                setLoading({ details: false });

                noviAPI.documents.getDocumentRequirements()
                    .then(({ data }) => {
                        setDocRequirements(data);
                    })
                dispatch(fetchDocumentMetadatas());
            })
            .catch(error => {
                console.log("[ ERROR FETCHING SAFETY DETAILS ]", error);
                HandleError(error, "Fetch safety details");
            })
    }, []);

    useEffect(() => {
        setLoading({ viewSettings: false });

        if (viewSettings?.find(viewSetting => viewSetting.type === 'responsibleperson')) {
            noviAPI.responsiblePeople.fetchAll(machineGroupId, 'safety')
                .then(({ data }) => {
                    setDetailOptions({ responsibleperson: data });
                    setLoading({ responsibleperson: false });
                })
                .catch(error => {
                    console.log("[ ERROR FETCHING RESPONSIBLE PEOPLE ]", error);
                    HandleError(error, "Fetch responsible people");
                })
        }
        if (viewSettings?.find(viewSetting => viewSetting.type === 'project')) {
            noviAPI.projects.fetchAllOperational(machineGroupId)
                .then(({ data }) => {
                    setDetailOptions({ project: data });
                    setLoading({ project: false });
                })
                .catch(error => {
                    console.log("[ ERROR FETCHING PROJECTS ]", error);
                    HandleError(error, "Fetch machine group projects");
                })

        }
    }, [viewSettings]);


    const [invalidFields, setInvalidFields] = useState([]);

    const [values, setValue] = useReducer((prevState, newValues) => {
        if (!hasChanged && !newValues['orderer']) setHasChanged(true);
        return {
            ...prevState,
            ...newValues
        }
    }, {
        status: i18n.t("WORKSTATUS1"),
        orderdate: moment(Date.now()).format(HTML5_FMT.DATETIME_LOCAL),
    });

    const [detailValues, setDetailValue] = useReducer((prevState, newValues) => {
        if (!hasChanged) setHasChanged(true);
        return {
            ...prevState,
            ...newValues
        }
    }, {});

    useEffect(() => {
        setValue({
            orderer: {
                label: userDetails.name,
                value: userDetails.id
            }
        });
    }, [userDetails]);

    const getBackButtonConfig = () => { 
        if (showMachines) {
            return { action: toggleMachines };
        }
        if (showFileInput) {
            return { action: () => setShowFileInput(false) };
        }
        else if (hasChanged === true) {
            return { action: history.goBack, params: { mainEditView: true }};
        }
        return null;
    }

    const getMachineViewReferrer = (): string[] => {
        let newReferrer = [];
        if (typeof history.location.state !== 'undefined') {
            if (!history.location.state.referrer) {
                newReferrer = [].concat(location.pathname);
            } else {
                newReferrer = [...history.location.state.referrer].concat(location.pathname);
            }
        } else {
            newReferrer = [].concat(location.pathname);
        }
        return newReferrer;
    }

    const handleMachineSelect = (selection, isLevel) => {
        if (isLevel) return;

        const machine = hierarchyMachines.find(m => m.machineId === selection.machineId);

        if (!machine) return;
        const { machineId, name, machineCode: code } = machine;

        const newMachine = { 
            id: machineId,
            code,
            name, 
            label: `${code} / ${name}`
        };
        setValue({ machine: newMachine });
        toggleMachines();
        history.replace(`/safetynotices/${id}/new`)
    }

    const sceneData = {
        view: moduleSettings.name,
        title: i18n.t(`SAFETY_CARD${id}`),
        subPhase: 'LISAYS',
        location: location,
        history: history,
        backAction: getBackButtonConfig(),
        hasChanged: hasChanged
    }

    const isValidValue = value => {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        else if (typeof value === 'object') {
            if (value.hasOwnProperty('id')) return value?.id > 0;
            if (value.hasOwnProperty('value')) return value?.value > 0;
            if (value.hasOwnProperty('text')) return !isNullOrWhitespace(value.text);
        }   
        else if (typeof value === 'boolean') {
            return value;
        }
        else {
            return !isNullOrWhitespace(value);
        }
    }

    const validateFields = () => {

        const _invalidFields = [];

        viewSettings.forEach(viewSetting => {
            if (viewSetting.required && !isValidValue(values[viewSetting.field])) {
                _invalidFields.push(viewSetting.field);
            }
        });

        safetyDetails.forEach(detail => {
            if (detail.required && !isValidValue(detailValues[detail.id])) {
                _invalidFields.push(`detail-${detail.id}`);
            }
        });

        setInvalidFields(_invalidFields);
        return _invalidFields.length == 0;
    }

    const handleSubmit = async () => {
        if (!validateFields()) {
            toast.error(i18n.t("INVALID_FIELDS"), {
                hideProgressBar: true,
                position: "top-center"
            })
            return;
        }

        const params: ISafetyNoticeAddition = {
            machineGroupId: machineGroupId,
            safetyTypeId: parseInt(id),
            orderDate: values.orderdate,
            ordererId: values.orderer.value,
            machineId: values.machine?.id,
            responsiblePersonId: values.responsibleperson?.value,
            projectId: values.project?.value,
            eventTime: values.eventtime,
            groupOrderer: values.grouporderer,
            ordererText: values.orderertext,
            documentIds: [],
            status: 1,
        };

        noviAPI.safetyNotices.add(params)
            .then(({ data: safetyNoticeId }) => {

                Object.entries(detailValues).forEach((valueObj: [string, any]) => {
                    const [ key, value ] = valueObj;

                    const detail = safetyDetails.find(d => d.id == parseInt(key));
                    const isCheckBoxTextBox = detail.type === 'checkboxtextbox';
                    const mappedValue = isCheckBoxTextBox 
                        ? value.text 
                        : detail.type === 'checkbox'
                            ? value ? 'True' : 'False'
                            : value?.value ?? value;

                    if (isCheckBoxTextBox) {
                        addDetailValue(safetyNoticeId, detail, value.enabled ? 'True' : 'False');
                    }
                    if (!isNullOrWhitespace(mappedValue)) {
                        addDetailValue(safetyNoticeId, detail, mappedValue);
                    }

                });

                // Handle documents
                if (documents.length > 0) {
                    documents.forEach(document => {
                        document.safetyNoticeId = safetyNoticeId;
                        dispatch(addSafetyNoticeDocument(document, false))
                    });
                }

                history.replace({
                    pathname: `/safety/${id}`,
                    state: { notificationMsg: 'SAFETY_NOTICE_ADDED' }
                });
            })
            .catch(err => {
                console.log("[ FAILED TO ADD SAFETY NOTICE ]", err, params);
                HandleError(err, "Add safety notice");
            })
    }

    const addDetailValue = (safetyNoticeId: number, detail: ISafetyDetail, value: any) => {
        const params = {
            detailId: detail.id,
            rowNumber: detail.rowNumber,
            value: value
        }

        noviAPI.safetyNotices.addDetailValue(safetyNoticeId, params)
            .catch(err => {
                console.log("[ FAILED TO ADD DETAIL ]", err, params);
                HandleError(err, "Add safety notice detail");
            })
    }

    const addDocument = () => {
        const { linkToDocument, description, type, file, metaData } = docRef.current.state;

        if ((file === null && linkToDocument === '') || (file !== null && linkToDocument !== '')) {
            toast.info(i18n.t('SELECT_FILE_OR_LINK'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            return;
        }

        const missingMetaDatas = metadatas.filter(field => {
            const val = metaData[field.label];
            return (field.required && (isNullOrWhitespace(val) || val?.id == -1));
        });

        if (missingMetaDatas.length || docRequirements?.find(req => req.field === 'description') && !description) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            setInvalidDocumentFields(true);
            setMissingMetaDatas(missingMetaDatas.map(x => x.id));
            return;
        }

        const documentMetaDataIds = Object.values(metaData)
            .map(data => data?.id ?? 0)
            .filter(id => id > 0)

        const document = {
            linkToDocument,
            description,
            type: type?.label ?? '',
            file,
            documentMetaDataIds
        }

        setDocuments(Array.isArray(documents) ? documents.concat(document) : [document]);
        setShowFileInput(false);
    }

    const removeDocument = (index: number) => {
        let docs = documents.slice(0);
        docs.splice(index, 1);
        setDocuments(docs.splice(index, 1));
    }

    const getActionButtonConfig = (): { label:string; isActionFn: boolean; icon: string; clickFn: Function; params?: any[]; status?: string } => {
        if (showFileInput) {
            return { 
                label: '',
                isActionFn: true,
                icon: 'confirm',
                clickFn: addDocument
            };
        } else {
            return {
                icon: 'save',
                label: '',
                clickFn: () => handleSubmit(),
                isActionFn: true,
            };
        }
    }

    const documentList = (): string[] => documents.map(doc =>
        doc.file?.name || doc.description || doc.linkToDocument
    )

    return (
        <div className="bottom-nav-space">
            <ScrollToTop />
            <NavigationBar
                currentView={sceneData}
                viewAction={getActionButtonConfig()}
            />
            <Loader ready={!loadingItems.viewSettings && !loadingItems.details} />

            <Container className="form-table-container">
                {showFileInput && <FileInput 
                    ref={docRef} 
                    docRequirements={docRequirements} 
                    invalidFields={invalidDocumentFields} 
                    missingMetaDatas={missingMetaDatas}
                    docMetadata={metadatas} 
                />}
                {!showFileInput && <>
                    {showMachines && <Machines
                        machineSelect
                        itemStatus={'newSafetyNotice'}
                        onMachineSelect={handleMachineSelect}
                        referrer={getMachineViewReferrer()}
                    />}
                    {!showMachines && <form onSubmit={null}>
                            
                        {viewSettings.map(vs => {
                            const { field, group, id, translationKey, type, required, requirements, defaultValue } = vs;
                            const value = values[field] ?? defaultValue;
                            const options = detailOptions[field] ?? [];
                            const typesMap = {
                                orderer: 'label',
                                project: 'detail',
                                responsibleperson: 'detail',
                                status: 'label',
                                orderdate: 'label',
                            };
                            const fixedType = typesMap[field] ?? type;
                            const fixedValue = (typeof value === 'object' && !['detail', 'machine'].includes(fixedType)) ? value.label : value

                            return (
                                <div key={id} className={invalidFields.includes(field) ? 'invalid-field' : ''}>
                                    <FormElement 
                                        name={field}
                                        type={fixedType}
                                        label={i18n.t(translationKey)}
                                        options={options}
                                        value={fixedValue}
                                        required={required}
                                        loading={loadingItems[field]}
                                        onChange={(e) => {
                                            if (type === 'machine') {
                                                toggleMachines();
                                                return;
                                            }
                                            if (fixedType === 'boolean') {
                                                setValue({ [field]: !value });
                                            }
                                            else if (fixedType === 'datetime' && e?._isAMomentObject) {
                                                const { value } = handleDateTimeInput(e, field);
                                                setValue({ [field]: value });
                                            }
                                            else {
                                                const value = fixedType === 'detail' ? e : e.target?.value;
                                                setValue({ [field]: value });
                                            }
                                        }}
                                    />
                                </div>
                            );
                        })}
                        {safetyDetails.map(detail => {

                            const { caption, detailCount, id, rowNumber, safetyDetailValues, safetyType, type, required } = detail;
                            const value = detailValues[id];

                            const typesMap = {
                                textbox: 'textarea',
                                selectbox: 'detail',
                                checkboxtextbox: 'textarea'
                            };

                            const isCheckBoxTextBox = type === 'checkboxtextbox';
                            const fixedType = typesMap[type] ?? type;

                            return (
                                <div key={id} className={invalidFields.includes(`detail-${id}`) ? 'invalid-field' : ''}>
                                    {isCheckBoxTextBox && 
                                        <FormElement 
                                            name={id}
                                            type={'singleline-checkbox'}
                                            label={i18n.t(caption)}
                                            options={[]}
                                            value={value?.enabled}
                                            required={required}
                                            onChange={() => {
                                                setDetailValue({ 
                                                    [id]: {
                                                        text: value?.text ?? "",
                                                        enabled: !value?.enabled
                                                    }
                                                });
                                            }}
                                        />
                                    }
                                    <FormElement 
                                        name={id}
                                        type={fixedType}
                                        label={isCheckBoxTextBox ? null : i18n.t(caption)}
                                        options={safetyDetailValues}
                                        value={isCheckBoxTextBox ? value?.text : value}
                                        required={required}
                                        onChange={(e) => {
                                            let newValue = e?.target?.value;

                                            if (fixedType === 'detail') {
                                                newValue = e;
                                            }
                                            else if (fixedType === 'checkbox') {
                                                newValue = !value;
                                            }
                                            else if (isCheckBoxTextBox) {
                                                newValue = {
                                                    enabled: value?.enabled ?? false,
                                                    text: newValue
                                                }
                                            }

                                            setDetailValue({ [id]: newValue });
                                        }}
                                    />
                                </div>
                            );
                        })}
                        <ListWithButtonAndRemoveItem
                            title={i18n.t('DOCUMENTS').toUpperCase()}
                            buttonText={i18n.t('ADD_DOCUMENT')}
                            handleBtnClick={() => setShowFileInput(true)}
                            itemList={documentList()}
                            removeItem={removeDocument}
                            noItemsText={i18n.t('NO_DOCUMENTS')}
                        />
                    </form>}
                </>}
            </Container>
        </div>
    );
}

export default EditSafetyNotice;
