import * as types from '../config/actionTypes';
import settingsAPI from '../config/settingsAPI';
import axios from 'axios';
import request from '../api/interceptors';
import { isArray } from 'util';
import { CountFilters as countActiveFilters, HandleError } from '../components/HelperFunctions';
import noviAPI from '../api/noviAPI';
import { resetCurrentFilters as resetWorkCardFilters, saveWorkListSettings } from '../scenes/work-schedule/work-list/actions';
import i18n from '../translations/i18n';
import { toast } from 'react-toastify';
import { filtersToSearchParams, getColorConfiguration, getColors, getColorSettings } from '../scenes/work-schedule/work-list/utils';
import { mapApplicationSettings, searchTypeSearchesTypeParamMap } from '../utils';
import { clearFiltersAction, resetCurrentWarehouseFilters } from 'scenes/warehouse/actions';
import { DocumentSource } from 'constants/documents/documentSource';
import { DefaultQuickSearches } from 'constants/quickSearches/DefaultQuickSearches';

export const clearCacheAction = () => ({
    type: types.CLEAR_CACHE
});

export const setMachineGroups = (machineGroups: IMachineGroup[]) => ({
    type: types.SET_MACHINE_GROUPS,
    payload: machineGroups
});

export const setUserDetails = (userDetails: IPerson) => ({
    type: types.SET_USER_DETAILS,
    payload: userDetails
})

export const fetchUserDetailsByAdName = (adName: string) => (
    dispatch => {
        dispatch({
            type: `${types.FETCH_USER_DETAILS_BY_ADNAME}_PENDING`
        })

        return noviAPI.people.fetchByAdName(adName)
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_USER_DETAILS_BY_ADNAME}_FULFILLED`,
                    payload: data
                })
                dispatch({
                    type: `${types.SET_USER_DETAILS}`,
                    payload: data
                })
                return data;
            }).catch((error) => {
                if (error?.response?.status !== 401) {
                    HandleError(error, 'Fetch user details by adname');
                }

                dispatch({
                    type: `${types.FETCH_USER_DETAILS_BY_ADNAME}_REJECTED`,
                    payload: error
                })
            })
    }
);

export const setUserSettings = (userSettings: IUserSetting[]) => ({
    type: types.SET_USER_SETTINGS,
    payload: userSettings
})

export const fetchGridSettings = (groupType, actionName) => (
    async dispatch => {
        dispatch({
            type: `${actionName}_PENDING`,
        });
        try {
            //TODO: 
            //const url = '/api/PersonSettings/' + groupType;(groupType = PersonSettings.Group)
            //const { data } = await request.get(url);
            const data = [];
            const gridSettingsFields = (!data || data.length === 0) ? settingsAPI[groupType] : data;

            dispatch({
                type: `${actionName}_FULFILLED`,
                payload: {
                    gridSettings: gridSettingsFields,
                    type: groupType
                }
            })
        } catch (error) {
            HandleError(error, 'Fetch grid settings');

            dispatch({
                type: `${actionName}_REJECTED`,
                payload: error
            })
        }
    }
);

export type IFetchViewSettings = (groupType: string | string[], actionName: string, machineGroupId?: number) => void;

export const fetchViewSettings: IFetchViewSettings = (groupType, actionName, machineGroupId = null) => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${actionName}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;
            if (!isArray(groupType)) {
                groupType = [groupType]
            }

            const data = await axios.all(groupType.map(type => {
                return noviAPI.machineGroupSettings.fetch(machineGroupId, type)
                    .then(res => {
                        res.data = res.data.length > 0 ? res.data : settingsAPI[type];
                        return res;
                    });
            })).then(responses => {
                return responses.map(response => {
                    return response['data'];
                });
            });

            let viewSettingsData = {};
            groupType.forEach(type => {
                viewSettingsData[type] = data[groupType.indexOf(type)];
            });

            dispatch({
                type: `${actionName}_FULFILLED`,
                payload: {
                    viewSettings: viewSettingsData,
                    type: groupType
                }
            })
        } catch (error) {
            HandleError(error, 'Fetch ' + groupType + ' view settings');

            dispatch({
                type: `${actionName}_REJECTED`,
                payload: error
            })
        }
    }
);

export type IFetchViewSettingsByGroups = (groups: string[]) => void;

export const fetchViewSettingsByGroups: IFetchViewSettingsByGroups = (groups) => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${types.FETCH_VIEW_SETTINGS_BY_GROUPS}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId || groups.length == 0) return;

            const searchParams = new URLSearchParams();
            groups.forEach(group => {
                searchParams.append("groups", group);
            })

            return noviAPI.machineGroupSettings.fetchByGroups(machineGroupId, searchParams)
                .then(({ data }) => {

                    let viewSettingsData = { };

                    groups.forEach(group => {
                        viewSettingsData[group] = data.filter(viewSetting => viewSetting.group === group);
                    })

                    dispatch({
                        type: `${types.FETCH_VIEW_SETTINGS_BY_GROUPS}_FULFILLED`,
                        payload: {
                            viewSettings: viewSettingsData
                        }
                    })
                })
            }
            catch (err) {
                console.log("[ ERROR ]", err);
                HandleError(err, "Fetch view settings by groups");
                dispatch({
                    type: `${types.FETCH_VIEW_SETTINGS_BY_GROUPS}_REJECTED`,
                    payload: err
                })
            }


    }
);

export const setApplicationSettings = (applicationSettings: IApplicationSetting[]) => ({
    type: types.SET_APPLICATION_SETTINGS,
    payload: mapApplicationSettings(applicationSettings)
})

export type IFetchUserRights = () => void;

export const fetchUserRights: IFetchUserRights = () => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_USER_RIGHTS}_PENDING`
        });

        const { machineGroupId } = getState().settings;

        if (machineGroupId) {
            noviAPI.personGroupSettings.fetchUserRights(machineGroupId)
                .then(({ data }) => {

                    dispatch({
                        type: `${types.FETCH_USER_RIGHTS}_FULFILLED`,
                        payload: data.map(right => ({ [right.settingId]: right.settingValue }))
                    })
                })
                .catch((error) => {
                    HandleError(error, 'Fetch user rights');

                    dispatch({
                        type: `${types.FETCH_USER_RIGHTS}_REJECTED`,
                        payload: error
                    })
                });
        }
    }
);

export const fetchWorkerGroups = () => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_WORKER_GROUPS}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;
            const url = '/api/MachineGroups/' + machineGroupId + '/WorkerGroups';
            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_WORKER_GROUPS}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch worker groups');

            dispatch({
                type: `${types.FETCH_WORKER_GROUPS}_REJECTED`,
                payload: error
            })
        }
    }
);

export type IFetchUserGroups = (userId: number) => void;

export const fetchUserGroups: IFetchUserGroups = userId => (
    async dispatch => {
        if (userId != null) {
            dispatch({
                type: `${types.FETCH_USER_GROUPS}_PENDING`,
            });

            noviAPI.workerGroups.fetchAllByPerson(userId)
                .then(({ data }) => {
                    dispatch({
                        type: `${types.FETCH_USER_GROUPS}_FULFILLED`,
                        payload: data
                    })
                })
                .catch(error => {
                    HandleError(error, 'Fetch user groups');
                    dispatch({
                        type: `${types.FETCH_USER_GROUPS}_REJECTED`,
                        payload: error
                    });
                })
        }
    }
);

export const fetchPersonGroups = () => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_PERSON_GROUPS}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;

            if (!machineGroupId) return;

            noviAPI.personGroups.fetchAll(machineGroupId)
                .then(({ data }) => {
                    const { results } = data;

                    dispatch({
                        type: `${types.FETCH_PERSON_GROUPS}_FULFILLED`,
                        payload: results
                    });
                })

        } catch (error) {
            HandleError(error, 'Fetch person groups');

            dispatch({
                type: `${types.FETCH_PERSON_GROUPS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchUsersPersonGroups = () => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_PERSON_GROUPS}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;

            if (!machineGroupId) return;

            noviAPI.personGroups.fetchAllForUser(machineGroupId)
                .then(({ data }) => {

                    dispatch({
                        type: `${types.FETCH_PERSON_GROUPS}_FULFILLED`,
                        payload: data
                    });
                })

        } catch (error) {
            HandleError(error, 'Fetch person groups');

            dispatch({
                type: `${types.FETCH_PERSON_GROUPS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const setMachineGroupId = (id: number) => {
    localStorage.setItem("machineGroupId", `${id}`);
    return {
        type: types.UPDATE_CURRENT_MACHINE_GROUP_ID,
        payload: { id }
    }
}

export const getEnabledModules = () => (
    settingsAPI['novimodules']
);

export const getWorkCardColors = () => (
    async (dispatch, getState: () => State) => {
        try {
            const { settings } = getState();

            if (settings.colors.length === 0) {
                const colors = await getColors();
                dispatch(setColors(colors));
            }

            let colorSettings = settings.colorSettings;
            if (colorSettings.length === 0) {
                colorSettings = await getColorSettings();
                dispatch(setColorSettings(colorSettings));
            }

            if (settings.colorConfig.length === 0) {
                const colorConfigurations = await Promise.all(colorSettings.map(getColorConfiguration));
                colorConfigurations.forEach((i, index) => {
                    dispatch(setColorConfiguration(i, colorSettings[index]))
                })
            }
        } catch (e) { console.log(e); }
    }
)

const setColors = (colors: IColor[]) => ({
    type: types.SET_COLORS,
    payload: colors
})

const setColorSettings = (colorSettings: IColorSettingLite[]) => ({
    type: types.SET_COLOR_SETTINGS,
    payload: colorSettings
})

const setColorConfiguration = (colorConfiguration: IColorSetting, colorSetting: IColorSettingLite) => ({
    type: types.SET_COLOR_CONFIGURATION,
    payload: {
        data: colorConfiguration,
        settingName: colorSetting.name
    }
})

export const downloadDocument = id => {
    return dispatch => {
        request.get('/api/Documents/' + id + '/download', { responseType: 'blob' })
            .then(({ data }) => {
                const url = window.URL.createObjectURL(data);

                dispatch({
                    type: `${types.GET_DOCUMENT}_FULFILLED`,
                    payload: {
                        document: data,
                        url
                    }
                })
            }).catch(error => {
                HandleError(error, 'Download document');
            });
    }
}

export const fetchDocumentMetadatas = () => (
    async (dispatch) => {
        dispatch({
            type: `${types.FETCH_DOCUMENT_METADATA_GROUPS}_PENDING`,
        })
        return noviAPI.documents.getDocumentMetadataGroups()
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_DOCUMENT_METADATA_GROUPS}_FULFILLED`,
                    payload: { 
                        data
                    }
                })
            })
            .catch(err => {
                HandleError(err, "Fetch document metadatas");
                dispatch({
                    type: `${types.FETCH_DOCUMENT_METADATA_GROUPS}_REJECTED`,
                })
            })
    }
);

export const setWorkStatuses = (workStatuses: IWorkStatus[]) => ({
    type: types.SET_WORK_STATUSES,
    payload: workStatuses
})

export const setUrgencies = (urgencies: IUrgency[]) => ({
    type: types.SET_URGENCIES,
    payload: urgencies
})

export const fetchUrgencies = () => (
    async dispatch => {
        try {
            const response = await noviAPI.workCardSettings.fetchUrgencies();
            dispatch(setUrgencies(response.data))
        } catch (error) {
            HandleError(error, 'Fetch urgencies');
        }
    }
)

export const setDefaultLanguage = (lang: Language) => ({
    type: types.SET_DEFAULT_LANGUAGE,
    payload: lang
})

export const fetchThirdPartyTypes = () => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_THIRD_PARTY_TYPES}_PENDING`,
        });
        try {
            const { data } = await noviAPI.thirdPartyTypes.fetchAll();

            dispatch({
                type: `${types.FETCH_THIRD_PARTY_TYPES}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch third party types');

            dispatch({
                type: `${types.FETCH_THIRD_PARTY_TYPES}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchThirdParties = () => (
    (dispatch, getState) => {

        const typesFromState = getState().settings.thirdPartyTypes;

        const getThirdParties = (thirdPartyTypes) => {
            const params = new URLSearchParams();
            thirdPartyTypes.forEach(type => params.append("ids", `${type.typeId}`));

            noviAPI.thirdParties.fetchByTypes(params)
                .then(({ data }) => {
                    dispatch({
                        type: types.FETCH_THIRD_PARTIES,
                        thirdParties: data.reduce((acc, cur, i) => {
                            return Object.assign(acc, { [thirdPartyTypes[i].typeId]: cur })
                        }, {}),
                        thirdPartyTypes
                    });
                })
                .catch(err => {
                    console.log(err);
                })
        }

        if (typesFromState.length > 0) {
            getThirdParties(typesFromState);
        }
        else {
            noviAPI.thirdPartyTypes.fetchAll()
                .then(response => response.data)
                .then(getThirdParties);
        }

    }
);

export const fetchAllQuickSearches = () => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${types.FETCH_ALL_QUICK_SEARCHES}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            const { data } = await noviAPI.quickSearches.fetchAll(machineGroupId);

            dispatch({
                type: `${types.FETCH_ALL_QUICK_SEARCHES}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch all quick searches');

            dispatch({
                type: `${types.FETCH_ALL_QUICK_SEARCHES}_REJECTED`,
                payload: error
            })
        }
    }  
);

export const setQuickSearches = (quickSearches: IQuickSearch[], group: string = null) => ({
    type: types.SET_QUICK_SEARCHES,
    payload: {
        group: group ?? 'allQuickSearches',
        data: quickSearches
    }
})

export const setQuickSearch: SetQuickSearch = (quickSearch, location) => (
    (dispatch, getState: () => State) => {

        const isDefaultSearchSelection = !quickSearch.isSavedSearch && !quickSearch.isSqlSearch && quickSearch.value == DefaultQuickSearches.OWN_DEFAULT_SEARCH;
        if (location === 'worklist') {

            const workCardFilters = getState().workcards.currentFilters;

            if (countActiveFilters(workCardFilters) > 0) {
                dispatch(resetWorkCardFilters());
            }
            if (isDefaultSearchSelection) {

                dispatch(resetQuickSearch('worklist'));

                dispatch(saveWorkListSettings({
                    useDefaultSearch: true,
                    pageNumber: 1
                }));

            } else {
                dispatch(saveWorkListSettings({
                    useDefaultSearch: false,
                    pageNumber: 1
                }));
            }
        }

        if (location === 'warehouse') {
            const warehouseFilters = getState().warehouse.currentFilters;
            if (countActiveFilters(warehouseFilters) > 0) {
                dispatch(resetCurrentWarehouseFilters());
            }
            dispatch(clearFiltersAction());
        }

        //TODO:
        //if (location === 'machines') {
        //    if (countActiveFilters(machineFilters) > 0) {
        //        resetMachineFilters();
        //    }
        //    dispatch(saveMachineListSettings({ 
        //        useDefaultSearch: false,
        //        pageNumber: 1
        //    }))
        //}

        dispatch({
            type: types.SET_ACTIVE_QUICK_SEARCH,
            payload: { [location]: isDefaultSearchSelection ? null : quickSearch }
        });
    }
);
type SetQuickSearch = (quickSearch, location: keyof State["navigation"]["activeQuickSearches"]) => void;

export const resetQuickSearch: ResetQuickSearch = (location) => (
    dispatch => {
        dispatch({
            type: types.RESET_QUICK_SEARCH,
            payload: location
        });
    }
);
export type ResetQuickSearch = (location: keyof State["navigation"]["activeQuickSearches"]) => void;

export const fetchResponsiblePeopleCategories = () => (
    async dispatch => {
        try {
            const url = '/api/ResponsiblePeople/categories';

            const { data } = await request.get(url);

            dispatch({
                type: types.FETCH_RESPONSIBLE_PEOPLE_CATEGORIES,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch responsible people categories');

            dispatch({
                type: types.FETCH_RESPONSIBLE_PEOPLE_CATEGORIES,
                payload: error
            })
        }
    }
);

export const fetchResponsiblePeopleByCategory = (category: string) => (
    async (dispatch, getState: () => State) => {
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            const { data } = await noviAPI.responsiblePeople.fetchAll(machineGroupId, category);

            dispatch({
                type: types.FETCH_RESPONSIBLE_PEOPLE_BY_CATEGORY,
                payload: data
            });
        } catch (error) {
            HandleError(error, 'Fetch responsible people by category');

            dispatch({
                type: types.FETCH_RESPONSIBLE_PEOPLE_BY_CATEGORY,
                payload: []
            });
        }
    }
);

export type FetchCostPools = () => void;
export const fetchCostPools: FetchCostPools = () => (
    async (dispatch, getState: () => State) => {
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            const { data } = await noviAPI.costPools.fetchAll(machineGroupId);

            dispatch({
                type: types.FETCH_COST_POOLS,
                payload: data
            });
        } catch (error) {
            HandleError(error, 'Fetch cost pools');
            dispatch({ type: `${types.FETCH_COST_POOLS}_REJECTED` });
        }
    }
);

export type FetchCostPoolTypes = () => void;
export const fetchCostPoolTypes: FetchCostPoolTypes = () => (
    async dispatch => {
        try {
            const { data } = await noviAPI.costPoolTypes.fetchAll();

            dispatch({
                type: types.FETCH_COST_POOL_TYPES,
                payload: data
            });
        } catch (error) {
            HandleError(error, 'Fetch cost pool types');
            dispatch({ type: `${types.FETCH_COST_POOL_TYPES}_REJECTED` });
        }
    }
);

export const addSearch = (
    filters: IWorkListFilters, 
    searchName: string,
    mgSpecificChecked: boolean,
    type: string
) => (
    async (dispatch, getState: () => State) => {
        const { machineGroupId, userDetails, userAdNameDetails } = getState().settings;

        const searchData: ISearchAddition = {
            name: searchName,
            searchParameters: JSON.stringify(filtersToSearchParams(filters)),
            type,
            machineGroupId,
            personId: userAdNameDetails?.id ? userAdNameDetails.id : userDetails.id
        }

        if (mgSpecificChecked) {
            searchData.personId = null;
        }

        noviAPI.searches.addSearch(searchData)
            .then(() => {
                toast.success(i18n.t('SEARCH_ADDED'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                dispatch(fetchSearches(searchTypeSearchesTypeParamMap[type]));
            })
            .catch(response => {
                HandleError(response, 'Add search');
            });
    }
);

export const updateSearch = (
    searchId: number,
    filters: IWorkListFilters,
    searchName: string,
    mgSpecificChecked: boolean,
    type: string
) => (
    async (dispatch, getState: () => State) => {
        const { machineGroupId, userDetails, userAdNameDetails } = getState().settings;

        const searchData: ISearchUpdate = {
            name: searchName,
            searchParameters: JSON.stringify(filtersToSearchParams(filters)),
            type,
            machineGroupId,
            personId: userAdNameDetails?.id ? userAdNameDetails.id : userDetails.id
        }
        
        if (mgSpecificChecked) {
            searchData.personId = null;
        }

        noviAPI.searches.updateSearch(searchId, searchData)
            .then(() => {
                toast.success(i18n.t('SEARCH_UPDATED'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                dispatch(fetchSearches(searchTypeSearchesTypeParamMap[type]));
            })
            .catch(response => {
                HandleError(response, 'Update search');
            });
    }
);

export const deleteSearch = (searchId: number, type: string) => (
    dispatch => {
        noviAPI.searches.deleteSearch(searchId)
            .then(() => {
                toast.success(i18n.t('SEARCH_DELETED'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                dispatch(fetchSearches(searchTypeSearchesTypeParamMap[type]));
            })
            .catch(response => {
                HandleError(response, 'Delete search');
            });
    }
);

export const fetchSearches = (type: string) => (
    async (dispatch, getState: () => State) => {
        const { machineGroupId, userId } = getState().settings;

        if (machineGroupId === 0 || !userId) return;

        const params = new URLSearchParams();
        params.append('PersonId', userId.toString());
        if (machineGroupId) { 
            params.append('MachineGroupId', machineGroupId.toString())
        }
        params.append('Type', type);
        params.append('IncludeMachineGroupSpecific', true.toString());

        noviAPI.searches.fetchSearches({ params })
            .then(({ data }) => {
                dispatch({
                    type: types.FETCH_SEARCHES,
                    payload: {
                        data,
                        type
                    }
                });
            })
            .catch(error => {
                HandleError(error, 'Fetch searches')
            });
    }  
);

export const setStatusesFulfilled = () => (
    dispatch => {
        dispatch({
            type: types.STATUS_FULFILLED
        });
    }
);

export const setApiCallFulfilled = () => (
    dispatch => {
        dispatch({
            type: types.API_CALL_ERROR_RESET
        });
    }
);

export const showHierarchyTree = (show: boolean) => (
    dispatch => {
        const action = { type: show ? types.OPEN_HIERARCHY_TREE : types.CLOSE_HIERARCHY_TREE };
        dispatch(action);
    }
);

export const setHierarchyParentId = (payload) => (
    dispatch => {
        const action = { type: types.SET_HIERARCHY_TREE_PARENT_ID, payload };
        dispatch(action);
    }
);

export const goBackInHierarchyTree = () => (
    dispatch => {
        const action = { type: types.SET_HIERARCHY_TREE_PARENT_ID_TO_PREVIOUS };
        dispatch(action);
    }
);
    
export const setHierarchyBreadcrumb = (payload) => (
    dispatch => {
        const action = { type: types.SET_HIERARCHY_TREE_ROUTE, payload };
        dispatch(action);
    }
);

export const setUserId = (userId) => (
    async dispatch => {
        dispatch({
            type: `LOGIN_FULFILLED`,
            payload: {
                user: userId
            }
        });
    }
);

export const updateImagePath = (documentId: number, targetId: number, targetType: number) => ({
    type: `UPDATE_${DocumentSource[targetType].toUpperCase()}`,
    payload: {
        id: targetId,
        imagePath: documentId ? `/api/Documents/${documentId}/download` : null
    }
})

export const fetchFaultNoticeDefaultText = () => (
    async dispatch => {

        noviAPI.workCardSettings.getDefaultFaultNoticeText()
            .then(({ data }) => {
                const text = data.label;
                dispatch({
                    type: types.FETCH_FAULT_NOTICE_DEFAULT_TEXT,
                    payload: { text: text }
                })
            })
            .catch(err => {
                HandleError(err, "Fetch faultnotice default info");
                dispatch({
                    type: types.FETCH_FAULT_NOTICE_DEFAULT_TEXT,
                    payload: { text: "" }
                })
            })
    }
)

export const fetchSafetyTypes = () => (
    async (dispatch, getState) => {

        const { machineGroupId } = getState().settings;

        noviAPI.safetyTypes.fetchAll(machineGroupId)
            .then(({ data }) => {
                const sortedTypes = data?.sort((a, b) => a.id - b.id) ?? data;
                dispatch({
                    type: types.FETCH_SAFETY_TYPES,
                    payload: sortedTypes
                })
            })
            .catch(err => {
                HandleError(err, "Fetch safety types");
                dispatch({
                    type: types.FETCH_SAFETY_TYPES,
                    payload: []
                })
            })
    }
)

export const fetchAllPersonMachineGroupSettings = () => (

    async (dispatch) => {

        noviAPI.personMachineGroupSettings.getAll()
            .then(({ data }) => {
                dispatch({
                    type: types.SET_PERSON_MACHINE_GROUP_SETTINGS,
                    payload: data
                })
            })
            .catch(err => {
                HandleError(err, `Fetch all person machine group settings`);
                dispatch({
                    type: types.SET_PERSON_MACHINE_GROUP_SETTINGS,
                    payload: []
                })
            })
    }
)

// TODO: Template for fetch by group, actually not in use yet
export const fetchPersonMachineGroupSetting = (group) => (
    async (dispatch, getState) => {

        const { machineGroupId } = getState().settings;

        if (machineGroupId > 0) {

            noviAPI.personMachineGroupSettings.getByGroup(machineGroupId, group)
                .then(({ data }) => {
                    // ...
                })
                .catch(err => {
                    HandleError(err, `Fetch person machine group settings ${group}`);
                })
        }
    }
)