import React from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import '../../styles/global.scss';
import NavigationBar from '../navigation';
import i18n from '../../translations/i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HasRight, GetFormDataFromViewSettings, isNullOrWhitespace } from '../../components/HelperFunctions';
import { Loader } from '../../components/Loader';
import { InfoContainer } from '../../components/InfoContainer';
import noviAPI from '../../api/noviAPI';
import SparePartPanelGroup from './components/SparePartPanelGroup';
import TextTruncate from 'react-text-truncate';
import ConfirmDialogComponent from '../../components/ConfirmDialogComponent';
import settingsAPI from '../../config/settingsAPI';
import { PropsFromRedux } from '.';
import { RouteChildrenProps } from 'react-router-dom';
import { actionIcons } from "../../constants/icons";
import FileInput from './../../components/FileInput';
import ImageModal from 'components/image-modal/ImageModal';
import { DocumentSource } from 'constants/documents/documentSource';

interface MatchParams {
    sparePartId: string;
}

interface LocationState {
    notificationMsg?: string;
}

type IProps = PropsFromRedux & RouteChildrenProps<MatchParams, LocationState>;

interface IState {
    [name: string]: any;
    sparePart: ISparePart;
    showDialog: boolean;
    dialogType: string;
    personSettings: IUserSetting[];
    machines: ISparePartMachine[];
    status: string;
    showDocumentInput: boolean;
    tabGroupActiveKey: string;
    docRequirements: IGlobalSetting[];
    invalidFields: boolean;
    missingMetaDatas: number[];
    loadingSparePartImage: boolean;
    sparePartImageUrl: string;
    showImageModal: boolean;
}

const moduleSettings = settingsAPI.moduleData.sparepart;

class SparePartView extends React.Component<IProps, IState> {
    docToAdd = React.createRef<FileInput>();
    constructor(props) {
        super(props);

        const spId = localStorage.getItem('spId');
        if (parseInt(this.props.match.params.sparePartId, 10) !== parseInt(spId, 10)) {
            localStorage.removeItem('activeSparePartTab');
        }

        const storedTabKey = localStorage.getItem('activeSparePartTab');

        this.state = {
            tabGroupActiveKey: storedTabKey || "1",
            showDialog: false,
            dialogType: '',
            sparePart: null,
            personSettings: [],
            machines: [],
            status: 'pending',
            showDocumentInput: false,
            docRequirements: null,
            invalidFields: false,
            missingMetaDatas: [],
            loadingSparePartImage: false,
            sparePartImageUrl: '',
            showImageModal: false
        }
    }

    componentDidMount() {
        const { location, history } = this.props;
        if (location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }
        }

        this.fetchSparePart();
        this.fetchPersonSettings();
        this.fetchMachines();
        this.props.fetchSparePartWarehouseData(Number(this.props.match.params.sparePartId));

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });
        this.props.fetchSparePartExtraDatas(Number(this.props.match.params.sparePartId));
        this.props.fetchDocuments(this.props.match.params.sparePartId);
        this.props.fetchAnnualSparePartConsumption(Number(this.props.match.params.sparePartId));

        this.props.fetchDocumentMetadatas();

        noviAPI.documents.getDocumentRequirements()
            .then(({ data }) => {
                this.setState({ docRequirements: data })
            })
    }

    componentDidUpdate(_prevProps, prevState: IState): void {
        if (prevState.sparePart !== null && (prevState.sparePart.imagePath !== this.state.sparePart.imagePath)) {
            this.fetchSparePartImage(this.state.sparePart)
            this.props.fetchDocuments(this.props.match.params.sparePartId);
        }
    }

    componentWillUnmount() {
        if (this.state.sparePartImageUrl) {
            URL.revokeObjectURL(this.state.sparePartImageUrl);
        }
    }

    fetchSparePart = () => {
        noviAPI.spareParts.fetch(parseInt(this.props.match.params.sparePartId, 10))
            .then(({ data }) => {
                this.setState({ sparePart: data });

                const freeAmountField = this.props.viewSettings?.find(setting => setting?.field == "freeamount");
                if (freeAmountField && data?.id) {
                    noviAPI.spareParts.fetchFreeAmount(data.id)
                        .then(({ data: freeAmount }) => {
                            this.setState(prevState => {
                                return {
                                    sparePart: {
                                        ...prevState.sparePart,
                                        freeAmount: freeAmount
                                    }
                                };
                            });
                        })
                }

                this.setState({ status: 'fulfilled' });

                this.fetchSparePartImage(data);
            })
            .catch(e => {
                console.log(e)
                this.setState({ status: 'error' })
            })
    }

    fetchSparePartImage = async (sparePart: ISparePart) => {
        if (sparePart.imagePath) {
            this.setState({ loadingSparePartImage: true });
            try {
                const response = await noviAPI.documents.downloadWithLink(sparePart.imagePath, { responseType: 'blob' });
                const url = URL.createObjectURL(response.data);
                this.setState({ sparePartImageUrl: url });
            } catch (error) {
                this.setState({ sparePartImageUrl: '' });
            }
            this.setState({  loadingSparePartImage: false });
        } else {
            this.setState({ sparePartImageUrl: '' })
        }
    }

    fetchPersonSettings = () => {
        noviAPI.personSettings.fetchAll(this.props.settings.userDetails.id)
            .then(response => {
                this.setState({
                    personSettings: response.data
                })
            })
            .catch(e => {
                console.log(e);
            })
    }

    fetchMachines = () => {
        noviAPI.machineSpareParts.fetchMachineSparePartLinks(parseInt(this.props.match.params.sparePartId, 10))
            .then(response => {
                this.setState({ machines: response.data })
            })
            .catch(e => {
                console.log(e);
            })
    }

    addDocument = () => {
        const { docRequirements } = this.state;

        const { linkToDocument, description, type, file, metaData } = this.docToAdd.current.state;
        const sparePartId = parseInt(this.props.match.params.sparePartId, 10);

        if ((file === null && linkToDocument === '') || (file !== null && linkToDocument !== '')) {
            toast.info(i18n.t('SELECT_FILE_OR_LINK'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            return;
        }

        const missingMetaDatas = this.props.documentMetadatas.filter(field => {
            const val = metaData[field.label];
            return (field.required && (isNullOrWhitespace(val) || val?.id == -1));
        });


        if (missingMetaDatas.length || docRequirements?.find(req => req.field === 'description') && !description) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ 
                invalidFields: true,
                missingMetaDatas: missingMetaDatas.map(x => x.id)
            });
            return;
        }

        const documentMetaDataIds = Object.values(metaData)
            .map(data => data?.id ?? 0)
            .filter(id => id > 0);

        const docData = {
            caption: file
                ? file.name
                : linkToDocument
                    ? linkToDocument.replace(/(^\w+:|^)\/\//, '')
                    : null,
            LinkToDocument: linkToDocument,
            Description: description,
            Type: type.label,
            File: file,
            documentMetaDataIds,
            SparePartId: sparePartId,
        }

        this.props.addDocument(docData);
        this.closeDocumentForm();
    }

    openDocumentForm = () => {
        this.setState({ showDocumentInput: true })
    }

    closeDocumentForm = () => {
        this.setState({
            showDocumentInput: false,
            invalidFields: false,
            missingMetaDatas: []
        });
    }

    setImagePath = (documentId: number) => {
        this.setState(state => ({ 
            sparePart: { 
                ...state.sparePart,
                imagePath: documentId ? `/api/Documents/${documentId}/download`: null
            }
        }))
    }

    handleTabGroup = (value) => {
        this.setState({ tabGroupActiveKey: value });
        localStorage.setItem('activeSparePartTab', value)
        localStorage.setItem('spId', this.props.match.params.sparePartId)
    }

    goto = (actionName = null, id = null) => {
        if (actionName === 'invent') {
            let url = '/warehouseinvent/' + id;
            this.props.history.push({
                pathname: url,
                state: {
                    warehouseId: null
                }
            });
        } else if (actionName !== 'delete') {
            ///warehouseoperations/:sparePartId?/:operation
            let url = '/warehouseoperations/' + id + '/' + actionName;
            if (actionName === 'edit') {
                url = '/sparepart/' + id + '/' + actionName;
            } else if (actionName === 'arrive') {
                url = '/warehouse/arrive/' + id;
            }
            this.props.history.push(url);
        } else {
            //TODO: delete dialog and delete action
        }
    }

    getDialogContent = contentType => {
        if (!contentType) return {
            title: '',
            body: '',
            type: '',
            params: null
        }
        const { sparePart, machines, document } = this.state;
        const { documents, sparePartWarehouseData } = this.props

        const dialogInformation = () => {
            return <div>
                <span>{i18n.t('LINKED')}:</span>
                <br />
                <span>{i18n.t('WORKCARDS')} {sparePart.linkedWorkCardCount}</span>
                <br />
                <span>{i18n.t('MACHINES')} {machines.length}</span>
                <br />
                <span> {i18n.t('WAREHOUSES')} {sparePartWarehouseData.length}</span>
                <br />
                <span> {i18n.t('DOCUMENTS')} {documents.length}</span>
            </div>
        }

        const dialogContents = {
            'delete': {
                title: i18n.t('DELETE_SPARE_PART') + ` ${sparePart.name}`,
                body: dialogInformation(),
                type: 'delete',
                params: { spId: sparePart.id, name: 'sparepart' }
            },
            'deleteDocument': {
                title: i18n.t('DELETE_SPARE_PART_DOCUMENT'),
                body: `${document?.caption}`,
                type: 'deleteDocument',
                params: {}
            }
        }

        return dialogContents[contentType];
    }

    setDialogProperties = (item, type) => {
        const { popoverRef } = this.state;
        if (item.type !== 'sparepart') {
            popoverRef[item.id].hide();
        }
        this.setState({
            showDialog: true,
            dialogType: type + '_' + item.type,
            targetItem: item,
        });
    }

    getCallbackFn = () => {
        const { dialogType } = this.state;
        if (dialogType === 'delete') return this.deleteSparePart;
        if (dialogType === 'deleteDocument') return this.deleteSparePartDocument
    }

    delete = (type, e) => {
        if (type === 'deleteDocument') {
            this.setState({ document: e })
        }
        this.setState({
            showDialog: true,
            dialogType: type
        });
    }

    deleteSparePart = (params) => {
        this.props.deleteSparePart(params.spId, this.props.history.replace)
        this.resetDialogProps();
    }

    deleteSparePartDocument = (params) => {
        const { document } = this.state

        this.props.deleteDocument(document.id)
        this.resetDialogProps();
    }

    resetDialogProps = () => {
        this.setState({
            showDialog: false,
            dialogType: null,
            targetItem: null
        });
    }

    render() {
        const {
            viewSettings,
            settings,
            location,
            history,
            extraDatas,
            childData,
            documents,
            sparePartWarehouseData
        } = this.props;

        const {
            sparePart,
            status,
            personSettings,
            machines,
            showDialog,
            dialogType,
            showDocumentInput
        } = this.state;

        const spId = sparePart ? sparePart.id : null;
        const actions = [];

        let sparePartViewSettings = [];

        if (sparePart?.id) {
            let key, keys = Object.keys(sparePart);
            let n = keys.length;
            let sparePartObj = {};
            while (n--) {
                key = keys[n];
                sparePartObj[key.toLowerCase()] = sparePart[key];
            }

             /* GetFormDataFromViewSettings can return <FormList> that contains <TextTruncate>
             ** TextTruncate will not show the text after changing tabs if TextTruncate is not updated inbetween
             ** Updating sparePartViewSettings when tab is changed solves the problem */
            if (this.state.tabGroupActiveKey === "1" && viewSettings) {
                sparePartViewSettings = GetFormDataFromViewSettings([...viewSettings], sparePartObj, childData, settings, extraDatas, false, sparePartWarehouseData);
            }
        }

        if (HasRight('warehouseedit', settings.userRights) || HasRight('sparepartregistryedit', settings.userRights)) {
            actions.push({
                icon: "add-circle",
                label: i18n.t('ADD_DOCUMENT'),
                clickFn: this.openDocumentForm,
                isActionFn: true,
                params: [true],
                paClass: ''
            });
            actions.push({
                icon: 'edit',
                label: i18n.t('EDIT'),
                clickFn: this.goto,
                isActionFn: true,
                params: ['edit', spId],
                paClass: ''
            });
        }
        if (HasRight('warehouseoperationtake', settings.userRights)) {
            actions.push({
                icon: 'take',
                label: i18n.t('TAKE'),
                clickFn: this.goto,
                isActionFn: true,
                params: ['take', spId]
            });
        }
        if (HasRight('warehouseoperationreturn', settings.userRights)) {
            actions.push({
                icon: 'return',
                label: i18n.t('RETURN'),
                clickFn: this.goto,
                isActionFn: true,
                params: ['return', spId]
            });
        }
        if (HasRight('warehouseoperationinvent', settings.userRights)) {
            actions.push({
                icon: 'chart-bar',
                label: i18n.t('INVENT'),
                clickFn: this.goto,
                isActionFn: true,
                params: ['invent', spId]
            });
        }
        if (HasRight('warehouseoperationarrive', settings.userRights)) {
            actions.push({
                icon: 'arrive',
                label: i18n.t('ARRIVE'),
                clickFn: this.goto,
                isActionFn: true,
                params: ['arrive', spId]
            })
        }
        if (HasRight('warehouseoperationchange', settings.userRights)) {
            actions.push({
                icon: 'change',
                label: i18n.t('WAREHOUSE_VALUE_CHANGE'),
                clickFn: this.goto,
                isActionFn: true,
                params: ['change', spId]
            })
        }
        if (HasRight('warehouseoperationtransfer', settings.userRights)) {
            actions.push({
                icon: 'transfer',
                label: i18n.t('TRANSFER'),
                clickFn: this.goto,
                isActionFn: true,
                params: ['transfer', spId]
            })
        }
        if (HasRight('warehousedele', settings.userRights) || HasRight('sparepartregistrydele', settings.userRights)) {
            actions.push({
                icon: 'delete',
                label: i18n.t('REMOVE'),
                clickFn: (e) => this.delete('delete', e),
                isActionFn: true,
                params: [spId],
                paClass: ''
            });
        }

        const popoverData = {
            popoverClass: 'menu-popover',
            popoverActions: actions
        };

        const sceneData = {
            view: moduleSettings.name,
            title: showDocumentInput ? i18n.t("ADD_DOCUMENT") : sparePart ? sparePart.name : '',
            thumbnail: this.state.sparePartImageUrl,
            location: location,
            history: history,
            backAction: showDocumentInput
                ? { action: this.closeDocumentForm }
                : { action: history.goBack },
            onThumbnailClick: () => this.setState({ showImageModal: true })
        }

        return (
            <div>
                {showDialog && <ConfirmDialogComponent
                    dialogContent={this.getDialogContent(dialogType)}
                    callBack={this.getCallbackFn()}
                    cancelDialog={this.resetDialogProps}
                />}
                <NavigationBar
                    fetchData={'fetchSparePart'}
                    currentView={sceneData}
                    viewAction={showDocumentInput ? {
                        icon: actionIcons.SAVE,
                        label: '',
                        clickFn: this.addDocument,
                        isActionFn: true,
                        paClass: 'start-phase'
                    } : "settings"}
                    popoverData={popoverData}
                />
                <Loader status={status} />
                {showDocumentInput ? <FileInput 
                    ref={this.docToAdd} 
                    docRequirements={this.state.docRequirements} 
                    invalidFields={this.state.invalidFields} 
                    missingMetaDatas={this.state.missingMetaDatas}
                    docMetadata={this.props.documentMetadatas}
                /> : 

                (sparePart && sparePart.id ? <Tabs
                    className="novi-nav-tabs"
                    activeKey={this.state.tabGroupActiveKey}
                    onSelect={this.handleTabGroup}
                    defaultActiveKey={1}
                >
                    <Tab
                        eventKey="1"
                        title={i18n.t('INFORMATION').toUpperCase()}
                    >
                        <Container style={{ marginBottom: 70 }} className="margin-top-15">
                            <div className="machine-details">
                                {typeof sparePart !== 'undefined'
                                    && status === 'fulfilled'
                                    && sparePartViewSettings.map((item, i) => {
                                        const { value, translationKey } = item;
                                        return item && <Row key={i} className="margin-bottom-5">
                                            <Col xs={4} className="sub-header">
                                                {this.state.tabGroupActiveKey === "1" && <TextTruncate
                                                    line={2}
                                                    truncateText="..."
                                                    text={i18n.t(translationKey)}
                                                />}
                                            </Col>
                                            <Col xs={8} className="white word-break">
                                                {value
                                                    ? value.id
                                                        ? value.name || value.label || value.description
                                                        : value
                                                    : ''
                                                }
                                            </Col>
                                        </Row>
                                    })}
                            </div>
                        </Container>
                    </Tab>
                    <Tab
                        eventKey="2"
                        title={
                            <span>
                                {i18n.t('DETAILS').toUpperCase()}
                                <span className="number-badge">
                                    {machines.length + documents.length + sparePartWarehouseData.length}
                                </span>
                            </span>
                        }
                    >
                        <div style={{ marginBottom: 70 }} className="margin-top-15">
                            <SparePartPanelGroup
                                personSettings={personSettings}
                                machines={machines}
                                sparePartWarehouseData={sparePartWarehouseData}
                                documents={documents}
                                onClick={e => this.delete('deleteDocument', e)}
                            />
                        </div>
                    </Tab>
                </Tabs> : <div> {status === 'error' && <InfoContainer type={'notfound'} content={'SPARE_PART_NOT_FOUND'} />}</div>)
                }
                <ImageModal
                    target={{ 
                        documentId: this.state.sparePart?.imagePath ? Number.parseInt(this.state.sparePart.imagePath.split('/')[3]) : null,
                        targetType: DocumentSource.SparePart,
                        targetId: Number.parseInt(this.props.match.params.sparePartId)
                    }}
                    loading={this.state.loadingSparePartImage}
                    imageUrl={this.state.sparePartImageUrl}
                    showModal={this.state.showImageModal}
                    closeModal={() => this.setState({ showImageModal: false })}
                    onSaved={this.setImagePath}
                />
            </div>
        );
    }
}

export default SparePartView;
