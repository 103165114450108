import React from 'react';
import { connect } from 'react-redux';
import '../../../styles/global.scss';
import { Container } from 'react-bootstrap';
import SparePartsRow from '../../warehouse/components/SparePartsRow';

export interface Props {
    spareParts: any[];
    gotoSparePart: (sparePartId) => void;
    location: any;
    history: any;
    viewSettings: any;
    settings: any;
    match: any;
    popoverActions: any[];
    currentWarehouseId: number;
    currentWarehouse: {
        id: number;
        label: string;
    };
    machineSparePart?: boolean;
    warehousesEnabled: boolean;
    toggleDialog?: (spId: number) => void;
    dialogOpen?: any;
    closeDialog?: () => void;
}

const THIRD_PARTY_TYPES = {
    supplier: 1,
    manufacturer: 2
}

const SpareParts = (props: Props) => {
    const getThirdPartiesByType = (thirdPartiesByTypes, type) => {
        let thirdPartiesByType = [];
        if (thirdPartiesByTypes) {
            for (const element of thirdPartiesByTypes) {
                if (THIRD_PARTY_TYPES[type] === element.type.id) {
                    thirdPartiesByType = element.thirdParties;
                }
            }
        }
        return thirdPartiesByType;
    }

    const { spareParts: machineSpareParts, warehousesEnabled } = props;

    return (
        <Container className="no-paddings">
            {machineSpareParts.map((machineSparePart, i) => (
                <SparePartsRow
                    key={i}
                    amountInMachine={machineSparePart.amountInMachine}
                    id={machineSparePart.sparePart?.id || null}
                    code={machineSparePart.sparePart?.code || ''}
                    name={machineSparePart.sparePart?.name || machineSparePart.wildSparePart}
                    amount={machineSparePart.amountInMachine || 0}
                    manufacturers={machineSparePart.sparePart !== null ? getThirdPartiesByType(machineSparePart.sparePart.thirdPartiesByTypes, 'manufacturer') : []}
                    suppliers={machineSparePart.sparePart !== null ? getThirdPartiesByType(machineSparePart.sparePart.thirdPartiesByTypes, 'supplier') : []}
                    shelfLocation={machineSparePart.sparePart?.shelfLocation || ''}
                    editPath={`/machinelink/${machineSparePart.id}/edit`}
                    bgColor={i % 2 === 0 ? '#272727' : '#2f2f2f'}
                    disabled={machineSparePart.wildSparePart ? true : false}
                    sparePartWarehouseData={machineSparePart.warehouseSparePartLinks}
                    warehousesEnabled={warehousesEnabled}
                    toggleDialog={props.toggleDialog}
                    dialogOpen={props.dialogOpen}
                    closeDialog={props.closeDialog}
                />
            ))}
        </Container>
    );
}

const mapStateToProps = (state, ownProps) => {
    const whEnabled = state.settings.noviConfigs.WarehousesEnabled;
    return {
        spareParts: ownProps.spareParts,
        settings: ownProps.settings,
        history: ownProps.history,
        currentWarehouseId: state.warehouse.currentFilters.quickSearch ? state.warehouse.currentFilters.quickSearch.id : null,
        currentWarehouse: state.warehouse.currentFilters.quickSearch ? state.warehouse.currentFilters.quickSearch : null,
        warehousesEnabled: (whEnabled === "True" || whEnabled === true)
    };
};

export default connect(mapStateToProps, null)(SpareParts);
