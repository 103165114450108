import React from 'react'
import { useSelector } from 'react-redux'
import { ToggleButton, Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import i18n from '../../../translations/i18n'
import TextTruncate from 'react-text-truncate';
import { ScrollToTop } from '../../../components/ScrollToTop';
import ConfirmDialogComponent from '../../../components/ConfirmDialogComponent';
import NavigationBar from '../../navigation';
import { settingsAPI } from '../../../config/settingsAPI';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { LocationState } from 'history';
import { useState, useEffect } from 'react';
import noviAPI from '../../../api/noviAPI';
import { getCostPoolTranslationKey, GetFormDataFromViewSettings, InitDialogBodyContent, Toaster } from '../../../components/HelperFunctions';
import useViewSettings from '../../../hooks/useViewSettings';
import { FETCH_HOURCARD_VIEW_SETTINGS } from '../../../config/actionTypes';
import HourCardPanelGroup from './HourCardsPanelGroup';
import { Loader } from '../../../components/Loader';
import { HourCardStatusEnum, WeekDays } from '../../../constants/hourCards';
import { getStatusLabel } from '../utils';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import useHourCard from '../../../hooks/useHourCard';
import { getDateTimeAndDayName, getInputDateTime, handleDateTimeInput } from 'utils';

const moduleSettings = settingsAPI.moduleData.hourcards;

type UrlParams = { id: string; };

const HourCardView = () => {
    const { id } = useParams<UrlParams>(); // Hour card ID from the URL using useParams.
    const settings = useSelector((state: State) => state.settings);
    const [showDialog, setShowDialog] = useState(false);
    const { hourCard, setHourCard, isUserSupervisor, isUserCustomerApprover, isUserPaymentSupervisor } = useHourCard(Number(id));
    const [workCard, setWorkCard] = useState<IWorkCard>(null);
    const [viewSettings] = useViewSettings('hourcard', FETCH_HOURCARD_VIEW_SETTINGS);
    const [dialogType, setDialogType] = useState('');
    const [daysToCopy, setDaysToCopy] = useState([]);
    const [userComment, setUserComment] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    let location = useLocation<LocationState>();
    let history = useHistory();
    let formFields = [];

    useEffect(() => {
        if (viewSettings.some(i => i.type === 'workcardfaultdescription')) {
            if (hourCard !== null && hourCard.workCard !== null) {
                noviAPI.workCards.fetchByCode(hourCard.workCard.code)
                    .then(response => {
                        setWorkCard(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }, [viewSettings, hourCard]);

    if (viewSettings && hourCard && settings) {
        formFields = GetFormDataFromViewSettings([...viewSettings], hourCard, null, settings, null);
    }

    const sceneData = {
        view: moduleSettings.name,
        title: i18n.t('HOUR_CARD'),
        location: location,
        history: history,
        itemColors: []
    }

    const getNextHourCardStatusLabel = (currentStatus: number) => {
        let newStatusLabel = '';
        if (currentStatus === HourCardStatusEnum.Reported) {
            newStatusLabel = 'ACCEPT';
        }
        if (currentStatus === HourCardStatusEnum.Approved) {
            newStatusLabel = 'BILLING_ACCEPT';
        }
        if (currentStatus === HourCardStatusEnum.Invoiced) {
            newStatusLabel = 'PAYMENT_ACCEPT';
        }
        return newStatusLabel;
    }

    const updateHourCardStatus = () => {
        const userId = Number(settings.userId);
        const newStatus = hourCard.status + 1;

        let hourCardUpdate: IHourCardUpdate = {};
        hourCardUpdate.status = newStatus;

        if (newStatus === HourCardStatusEnum.Approved) {
            hourCardUpdate.approverId = userId;
        }
        if (newStatus === HourCardStatusEnum.Invoiced) {
            hourCardUpdate.billingApproverId = userId;
        }
        if (newStatus === HourCardStatusEnum.Paid) {
            hourCardUpdate.paymentApproverId = userId;
        }

        // Update hour card (status & approver)
        noviAPI.hourCards.update(hourCard.id, hourCardUpdate)
            .then(_response => {
                // fetch updated hour card
                noviAPI.hourCards.fetch(hourCard.id)
                    .then(response => {
                        // set hour card with new values
                        setHourCard(response.data);
                    })
                    .catch(error => {
                        // hour card fetch failed
                        console.log(error);
                    });
                Toaster({ msg: i18n.t('HOUR_CARD_STATUS_CHANGE_SUCCESS'), type: 'success' });
            })
            .catch(_error => {
                // hour card  update failed
                Toaster({ msg: 'HOUR_CARD_STATUS_CHANGE_FAILED', type: 'error' });
            });

        setShowDialog(false);
    }

    const deleteHourCard = async (id: number) => {
        await noviAPI.hourCards.delete(id)
            .then(response => {
                if (response.status === 200) {
                    history.goBack();
                    Toaster({ msg: 'HOUR_CARD_DELETED', type: 'success' });
                }
            }, reason => {
                Toaster({ msg: 'HOUR_CARD_DELETE_FAILED', type: 'error' });
            });
        setShowDialog(false);
    }

    const copyHourCard = async (id: number, params: IHourCardCopyParams) => {
        await noviAPI.hourCards.copy(id, params)
            .then(response => {
                if (response.status === 200) {
                    Toaster({ msg: 'HOUR_CARD_COPY_SUCCESS', type: 'success' });
                    history.push('/hourcards')
                }
            }, reason => {
                Toaster({ msg: 'HOUR_CARD_COPY_FAILED', type: 'error' });
            });
        setShowDialog(false);
    }

    const getDialogType = (type) => {
        setShowDialog(true);
        setDialogType(type);
    }

    const canUserEditHourCard = () => {
        let userIsAllowedToEdit = true;
        const userId = Number(settings.userId);

        // User is not the creator -> false
        if(hourCard.person.id !== userId) {
            userIsAllowedToEdit = false;
        }

        // User is creator but status is greater than 1 -> false
        if (hourCard.person.id === userId && hourCard.status > HourCardStatusEnum.Reported) {
            userIsAllowedToEdit = false;
        }

        //User belongs to supervisor group of card creator and card not yet customer approved (invoiced) -> true
        if (isUserSupervisor && hourCard.status < HourCardStatusEnum.Invoiced) {
            userIsAllowedToEdit = true;
        }

        // User is supervisor but can not accept own hours
        if (hourCard.person.id === userId && settings.noviConfigs.CanAcceptOwnHourCards === 'False' && hourCard.status > HourCardStatusEnum.Reported) {
            userIsAllowedToEdit = false;
        }

        return userIsAllowedToEdit;
    }

    const getPopoverData = () => {
        let popoverData = {
            popoverClass: 'menu-popover',
            popoverActions: []
        }

        if (hourCard !== null) {
            if (hourCard.status < HourCardStatusEnum.Approved) {
                popoverData.popoverActions.push({
                    icon: 'delete',
                    label: i18n.t('DELETE'),
                    clickFn: () => getDialogType('delete'),
                    isActionFn: true,
                    params: [hourCard?.id],
                    paClass: ''
                });
            }

            if (canUserEditHourCard()) {
                popoverData.popoverActions.push({
                    icon: 'copy',
                    label: i18n.t('COPY'),
                    clickFn: () => getDialogType('copy'),
                    isActionFn: true,
                    params: [hourCard?.id],
                    paClass: ''
                });

                popoverData.popoverActions.push({
                    icon: 'edit',
                    label: i18n.t('EDIT'),
                    clickFn: () => history.push('/hourcard/edit/' + id),
                    isActionFn: true,
                    params: [hourCard?.id],
                    paClass: ''
                });
            }

            let canEditStatus = false;
            if (hourCard.status === HourCardStatusEnum.Reported && isUserSupervisor) {
                const isOwnHourCard = hourCard.person.id === Number(settings.userId);
                if (!isOwnHourCard || (isOwnHourCard && settings.noviConfigs.CanAcceptOwnHourCards === 'True')) {
                    canEditStatus = true;
                }
            }
            if (hourCard.status === HourCardStatusEnum.Approved && isUserCustomerApprover) {
                canEditStatus = true;
            }
            if (hourCard.status === HourCardStatusEnum.Invoiced && isUserPaymentSupervisor) {
                canEditStatus = true;
            }
            if (canEditStatus) {
                popoverData.popoverActions.push({
                    icon: 'next-status',
                    label: i18n.t(getNextHourCardStatusLabel(hourCard.status)),
                    clickFn: () => getDialogType('change_status'),
                    isActionFn: true,
                    params: [hourCard?.status],
                    paClass: ''
                });
            };
        }

        return popoverData;
    }

    const mapHourCardValues = (setting: IViewSetting) => {
        if (setting.type === 'detail' || setting.type === 'multidetail') {
            const detail = hourCard.details?.filter(i => i?.group === setting.field)?.map(i => i?.value)?.join(", ");
            return detail ? detail : '';
        }

        if (setting.type === 'extradata') {
            const extraData = hourCard.extraDatas.find(i => i.group === setting.field);
            return extraData ? extraData.value : '';
        }

        if (setting.type === 'costpoolgroup') {
            return getCostPoolTranslationKey(hourCard.costPools, setting);
        }

        if (setting.type === 'workcardfaultdescription' && workCard !== null) {
            return workCard.faultDescription;
        }

        switch (setting.field) {
            case 'inputdate':
                return `${getDateTimeAndDayName(hourCard['inputDate'])}`;
            case 'creator':
                return hourCard['person'].name;
            case 'creatorcomment':
                return hourCard['creatorComment'] || '';
            case 'supervisorcomment':
                return hourCard['supervisorComment'] || '';
            case 'customercomment':
                return hourCard['customerComment'] || '';
            case 'payment_approver':
                return hourCard['paymentApprover']?.name || '';
            case 'billing_approver':
                return hourCard['billingApprover']?.name || '';
            case 'workcard':
                return hourCard.workCard ? <Link to={`/workcard/${hourCard.workCard.id}`}>{hourCard.workCard.code}</Link> : '';
            case 'approver':
                return hourCard[setting.field]?.name || '';
            case 'salarycategory':
                return hourCard['salaryCategory'].name || '';
            case 'status':
                return getStatusLabel(hourCard['status']);
            case 'workcardproject':
                return hourCard['workcardproject'];
            default:
                return hourCard[setting.field];
        };
    }

    const selectWeekDays = (dayId) => {
        if (daysToCopy.includes(parseInt(dayId))) {
            setDaysToCopy(daysToCopy.filter(i => i !== parseInt(dayId)));
        } else {
            setDaysToCopy([...daysToCopy, parseInt(dayId)]);
        }
    }

    const isChecked = (value) => daysToCopy.includes(value);

    const handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const { value } = handleDateTimeInput(date, name);
        if (name === 'startDate') { setStartDate(value); }
        if (name === 'endDate') { setEndDate(value); }
    }

    const copyDialogContent = () => {
        let weekDays = Object.keys(WeekDays).filter(key => isNaN(Number(key)));
        weekDays.push(weekDays.shift()); // Move sunday to last place in the array

        return (
            <div>
                <div>
                    <FormElement name={'startDate'} type={'datetime'} label={i18n.t('START_DATE')} onChange={handleDateTime} value={startDate} />
                    <FormElement name={'endDate'} type={'datetime'} label={i18n.t('END_DATE')} onChange={handleDateTime} value={endDate} />
                    <FormElement name={'comment'} type={'text'} label={i18n.t('CREATOR_COMMENT')} onChange={(e) => setUserComment(e.target.value)} value={userComment} />
                </div>
                <hr />
                <div>
                    {weekDays.map((weekDay) =>
                        <ToggleButton
                            id={WeekDays[weekDay]}
                            type="checkbox"
                            variant="outline-primary"
                            checked={isChecked(WeekDays[weekDay])}
                            value={WeekDays[weekDay]}
                            onChange={() => selectWeekDays(WeekDays[weekDay]) }
                        >
                            {i18n.t(weekDay.toUpperCase())}
                        </ToggleButton>
                    )}
                </div>
            </div>
        )
    }

    const getDialogContent = (type) => {
        const dialogContents = {
            'delete': {
                title: i18n.t('DELETE') + ' ' + i18n.t('HOUR_CARD').toLowerCase(),
                body: InitDialogBodyContent('HOUR_CARD', hourCard?.id),
                type: 'delete',
                params: hourCard?.id
            },
            'change_status': {
                title: i18n.t(getNextHourCardStatusLabel(hourCard?.status)),
                body: i18n.t('CONFIRM_HOUR_CARD_STATUS_CHANGE'),
                type: 'change_status',
                params: []
            },
            'copy': {
                title: i18n.t('COPY') + '?',
                body: copyDialogContent(),
                type: 'copy',
                params: hourCard?.id
            }
        }
        return dialogContents[type];
    }

    const getDialogCallBack = (params) => {
        switch (dialogType) {
            case 'delete': {
                return deleteHourCard(params);
            }
            case 'change_status': {
                return updateHourCardStatus()
            }
            case 'copy': {
                const copyParams = {
                    startDate: getInputDateTime(startDate),
                    endDate: getInputDateTime(endDate),
                    daysOfWeek: daysToCopy,
                    userComment: userComment
                };

                if (copyParams.daysOfWeek.length > 0) {
                    return copyHourCard(hourCard?.id, copyParams);
                }
                return Toaster({ msg: 'DAYS MISSING', type: 'warning' });
            }
            default:
                break;
        };
    }

    return (
        <div>
            <ScrollToTop />
            {showDialog && <ConfirmDialogComponent
                dialogContent={getDialogContent(dialogType)}
                callBack={getDialogCallBack}
                cancelDialog={() => setShowDialog(false)}
            />}
            <NavigationBar
                currentView={sceneData}
                popoverData={getPopoverData()}
                viewAction={'settings'}
            />
            {
                <Tabs
                    id="hourCardViewTabs"
                    className="novi-nav-tabs"
                    unmountOnExit={true}
                >
                    <Tab
                        eventKey={1}
                        title={i18n.t('DETAILS').toUpperCase()}
                    >
                        <Container fluid={false}>
                            <div className="hourCardDetails">
                                {formFields.filter(item => item.field !== 'inputdayofweek').map((item, i) => {
                                    return (
                                        <Row key={i} className="margin-bottom-5">
                                            <Col xs={4} className="sub-header">
                                                {<TextTruncate
                                                    line={2}
                                                    truncateText="..."
                                                    text={item.field === 'hours' ? i18n.t('AMOUNT') : i18n.t(item.translationKey)}
                                                />}
                                            </Col>
                                            <Col xs={8} className="white word-break">
                                                {mapHourCardValues(item)}
                                            </Col>
                                        </Row>
                                    )
                                })
                                }
                            </div>
                            {<Loader status={hourCard === null ? 'pending' : 'fulfilled'} />}
                        </Container>
                    </Tab>
                    <Tab
                        eventKey={2}
                        title={i18n.t('HOUR_CARD_STATUS_LOG'.toUpperCase())}
                    >
                        <div style={{ marginBottom: 70 }} className="margin-top-15">
                            <HourCardPanelGroup />
                        </div>
                    </Tab>
                </Tabs>
            }
        </div>
    )
}
export default HourCardView
