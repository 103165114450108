import React from 'react';
import TextField from 'components/form/TextField';
import SelectField from 'components/form/SelectField';
import i18n from '../../../translations/i18n';
import { useSelector } from 'react-redux';

const MachineFilters = (props: IProps) => {
    let fieldSettings = [
        { name: 'searchString', label: 'SEARCH', type: 'text', value: props.fields.searchString, visible: true, tabOrder: 1 },
        { name: 'code', label: 'MSP_CODE', type: 'text', value: props.fields.code, visible: false, tabOrder: 2 },
        { name: 'machineType', label: 'MSP_MACHINE_TYPE', type: 'select', value: props.fields.machineType, visible: false, tabOrder: 3 },
        { name: 'model', label: 'MSP_MODEL', type: 'text', value: props.fields.model, visible: false, tabOrder: 4 },
        { name: 'productNumber', label: 'MSP_PRODUCT_NUMBER', type: 'text', value: props.fields.productNumber, visible: false, tabOrder: 5 },
        { name: 'yearOfManufacture', label: 'MSP_YEAR_OF_MANUFACTURE', type: 'text', value: props.fields.yearOfManufacture, visible: false, tabOrder: 6 },
        { name: 'deliveryNumber', label: 'MSP_DELIVERY_NUMBER', type: 'text', value: props.fields.deliveryNumber, visible: false, tabOrder: 7 },
        { name: 'machineRequirements', label: 'MACHINE_REQUIREMENTS', type: 'select', value: props.fields.machineRequirements, visible: false, tabOrder: 8 },
        { name: 'extraLocation', label: 'MSP_MACHINE_EXTRA_LOCATION', type: 'text', value: props.fields.extraLocation, visible: false, tabOrder: 9 },
        { name: 'weight', label: 'MSP_WEIGHT', type: 'text', value: props.fields.weight, visible: false, tabOrder: 10 },
        { name: 'dimensions', label: 'MSP_DIMENSIONS', type: 'text', value: props.fields.dimensions, visible: false, tabOrder: 11 },
        { name: 'url', label: 'MSP_URL', type: 'text', value: props.fields.url, visible: false, tabOrder: 12 },
        { name: 'memo', label: 'MSP_MEMO', type: 'text', value: props.fields.memo, visible: false, tabOrder: 13 }
    ]

    props.fields.thirdParties.allTypes.forEach(i => {
        fieldSettings.push({ name: `${i}`, label: i18n.t(props.optionGroups[i].label), type: 'select', value: props.fields.thirdParties.byType[i] || [], visible: false, tabOrder: 100 })
    })

    props.fields.details.allGroups.forEach(i => {
        fieldSettings.push({ name: i, label: i18n.t(props.optionGroups[i].label), type: 'select', value: props.fields.details.byGroup[i] || [], visible: false, tabOrder: 200 })
    })

    props.fields.costPools.allGroups.forEach(i => {
        fieldSettings.push({ name: i, label: i18n.t(props.optionGroups[i].label ?? ''), type: 'select', value: props.fields.costPools.byGroup[i] || [], visible: false, tabOrder: 300 })
    })

    const viewSettings = useSelector((state: State) => state.machines.viewSettings.machine);

    viewSettings.filter(s => s.type === 'extradata').forEach(s => {
        fieldSettings.push({ name: s.field, label: i18n.t(s.translationKey), type: s.type, value: props.fields.extraData.byGroup[s.field] || '', visible: false, tabOrder: 400 })
    })

    fieldSettings = fieldSettings
        .map(i => {
            const setting = viewSettings.find(s => i.name.toLowerCase() === s.field.toLowerCase()) ?? null;
            if (setting !== null) {
                if (i.tabOrder === 100 || i.tabOrder === 200 || i.tabOrder === 300 || i.tabOrder === 400) {
                    // thirdParties || details || costPools || extraData
                    return { ...i, visible: true, tabOrder: i.tabOrder + setting.tabOrder }
                }
                return { ...i, visible: true }
            }
            return i;
        })
        .sort((a, b) => a.tabOrder - b.tabOrder);

    return (
        <React.Fragment>
            {fieldSettings.map(field => {
                if (!field.visible) return (<React.Fragment key={field.name}></React.Fragment>)
                if (field.name === 'searchString') {
                    return (
                        <React.Fragment key={field.name}>
                            <TextField name={field.name} label={i18n.t(field.label)} value={field.value as string} onChange={props.handleChange} />
                            <hr/>
                        </React.Fragment>
                    )
                }
                if (field.type === 'text') {
                    return <TextField key={field.name} name={field.name} label={i18n.t(field.label)} value={field.value as string} onChange={props.handleChange} />
                }
                if (field.type === 'extradata') {
                    return <TextField key={field.name} name={field.name} label={i18n.t(field.label)} value={field.value as string} onChange={props.handleExtraData} />
                }
                if (field.type === 'select') {
                    return <SelectField key={field.name} name={field.name} label={i18n.t(field.label)} value={field.value as IOptionType[]} options={props.optionGroups[field.name]?.options || []} onChange={props.handleSelect} isMulti />
                }
                return (<React.Fragment key={field.name}></React.Fragment>)
            })}
        </React.Fragment>
    )
}

interface IProps {
    handleChange: (e) => void;
    handleSelect: (value, actionMeta) => void;
    handleExtraData: (e) => void
    fields: {[x:string]:any};
    optionGroups: { [name: string]: { label: string; options: IOptionType[]; } }
}

export default MachineFilters;