import * as types from '../../../config/actionTypes';
import i18n from '../../../translations/i18n';
import axios from 'axios';
import request from '../../../api/interceptors';
import { HandleError, Toaster } from '../../../components/HelperFunctions';
import 'react-toastify/dist/ReactToastify.css';
import { isArray } from 'util';
import noviAPI from '../../../api/noviAPI';
import { toast } from 'react-toastify';
import { IRedirect } from '../../machines/actions';
import { mapMaterialAddition } from './utils';

export const fetchWorkCard = id => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_WORKCARD}_PENDING`,
        });

        return noviAPI.workCards.fetch(id)
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_WORKCARD}_FULFILLED`,
                    payload: {
                        data,
                        id: parseInt(id, 10)
                    }
                });
                return Promise.resolve(data);
            }).catch(error => {
                dispatch({
                    type: `${types.FETCH_WORKCARD}_REJECTED`,
                    payload: error
                });
                return Promise.reject(error);
            })
    }
);

export const setWorkCardStatusPending = () => ({
    type: `${types.FETCH_WORKCARD}_PENDING`
})

export const storeWorkCard = (workCard: IWorkCard) => (
    dispatch => {
        dispatch({
            type: `${types.FETCH_WORKCARD}_FULFILLED`,
            payload: {
                data: workCard,
                id: workCard.id
            }
        });
    }
);

export const fetchRouteWorkCard = id => (
    async (dispatch, getState) => {
        try {
            const url = '/api/WorkCards/routeMaintenances/' + id;
            const { data } = await request.get(url);
            const wcId = parseInt(id, 10);
            const isStored = getState().workcards.workcards.filter(wc => wc.id === wcId).length > 0 ? true : false;
            const type = isStored ? types.UPDATE_WORKCARD : types.ADD_WORKCARD;

            dispatch({
                type: type,
                payload: {
                    data,
                    id: wcId
                }
            });
        } catch (error) {
            //HandleError(error, 'Fetch workcard');
        }
    }
);

export const addWorkcard = (workCard, extraData: IWorkCardExtraData[], notificationTargets = [], documents: (IWorkCardFileDocument | IWorkCardLinkDocument)[] = [], materials = [], redirect: IRedirect) => {
    return (dispatch, getState) => {
        const url = '/api/WorkCards';
        const { machineGroupId, userDetails, userAdNameDetails } = getState().settings;
        const { workcards, storedWorkCards } = getState().workcards;
        const { online } = getState().offline;
        const currentUser = userAdNameDetails?.id ? userAdNameDetails : userDetails;

        workCard.phasesPayload = [];
        workCard.phasesRequest = [];

        // create temporary id
        const tempId = getTempId(online ? workcards : storedWorkCards);
        const wcArray = online ? workcards : storedWorkCards;
        const tempWpId = getTempWpId(wcArray, tempId);

        workCard.phases.forEach((phase, i) => {
            workCard.phasesPayload.push(workCardPayloadData(phase, [], { machineGroupId, id: tempWpId }, initWorkPhase()));
            delete phase.id;
            workCard.phasesRequest.push(workCardRequestData(phase, { machineGroupId }));
        });

        // data for offline use
        const payload = workCardPayloadData(workCard, extraData, { id: tempId, machineGroupId }, initWorkCard());
        // data for API request
        
        let targetList = [];
        if (notificationTargets.length > 0) {
            targetList = fillTargetlist(targetList, notificationTargets);
        }

        let requestData = workCardRequestData(workCard, { machineGroupId, targetList });

        delete requestData.openWorkCards;
        delete requestData.phasesPayload;

        dispatch({ type: types.SET_WORK_CARDS_STATUS_PENDING });

        if (!online) {
            setTimeout(() => dispatch({ type: types.SET_WORK_CARDS_STATUS_FULFILLED }), 250);
        }

        // Set current logged in user as orderer if there is no value yet
        if (!requestData.ordererId && !requestData.ordererName) requestData.ordererName = currentUser.name;
        if (!requestData.ordererId) requestData.ordererId = currentUser.id;

        dispatch({
            type: types.ADD_WORKCARD,
            payload: { data: payload },
            meta: {
                offline: {
                    // the network action to execute
                    effect: { method: 'post', url, data: requestData },
                    // action to dispatch when effect succeeds
                    commit: { type: `${types.ADD_WORKCARD}_COMMIT`, meta: { tempId, tempWpId } },
                    // action to dispatch if network action fails permanently
                    rollback: { type: `${types.ADD_WORKCARD}_ROLLBACK`, meta: { tempId, tempWpId } }
                }
            }
        }).then(response => {
            const { data: wcId } = response;

            if (extraData.length > 0) {
                dispatch(updateExtraDatas(extraData, wcId));
            }            

            if (documents.length > 0) {
                for (const document of documents) {
                    document.workCardId = wcId;
                    dispatch(addWorkCardDocument(document, false))
                }
            }

            if (materials.length > 0) {
                for (const material of materials) {
                    const { spareParts, ...rest } = material;
                    if (spareParts.length > 0) {
                        noviAPI.workCardSpareParts.addWithSpecialRights(
                            mapMaterialAddition({ ...rest, sparePart: spareParts[0] }, wcId),
                            'addition'
                        )
                    } else {
                        noviAPI.workCardSpareParts.addWithSpecialRights(
                            mapMaterialAddition(rest, wcId),
                            'addition'
                        )
                    }
                }
            }

            const location = {
                pathname: '/worklist',
                state: { notificationMsg: 'NEW_WORKCARD_ADDED' }
            }

            redirect(location);

        }).catch(error => {
            if (error.response) {
                // Request made and server responded
                if (error.response.data.errors && isArray(error.response.data.errors)) {
                    error.response.data.errors.forEach(err =>
                        Toaster({ msg: err.reason, type: 'error' })
                    )
                } else {
                    Toaster({ msg: error.response.statusText || error.response, type: 'error' })
                }
            } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request)
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log(error.message)
            }

            dispatch({
                type: types.API_CALL_ERROR
            });
        })
    }
};

//TODO: Error handle
export const addFaultNotice = (faultNotice, documents, extraData = [], notificationTargets = [], redirect: () => void) => {
    return (dispatch, getState) => {
        faultNotice.phasesPayload = [];
        faultNotice.phasesRequest = [];
        
        const url = '/api/WorkCards';
        const { workcards } = getState().workcards;
        const { machineGroupId } = getState().settings;
        const { online } = getState().offline;

        // create temporary id for new work card
        const tempId = getTempId(workcards);
        const tempWpId = getTempWpId(workcards, tempId);

        faultNotice.phases.map((phase, i) => {
            faultNotice.phasesPayload.push(workCardPayloadData(phase, [], { machineGroupId, id: tempWpId }, initWorkPhase()));
            delete phase.id;
            faultNotice.phasesRequest.push(workCardRequestData(phase, { machineGroupId }));
        });

        let targetList = []; 
        if (notificationTargets.length > 0) {
            targetList = fillTargetlist(targetList, notificationTargets);
        }

        // data for API request
        let requestData = workCardRequestData(faultNotice, { machineGroupId, targetList });

        delete requestData.openWorkCards;
        delete requestData.phasesPayload;

        dispatch({ type: types.SET_WORK_CARDS_STATUS_PENDING });

        if (!online) {
            setTimeout(() => dispatch({ type: types.SET_WORK_CARDS_STATUS_FULFILLED }), 250);
        }

        request.post(url, requestData)
            .then((res) => {
                const { data: workCardId } = res;

                // data for payload
                let payload = workCardPayloadData(faultNotice, extraData, { machineGroupId, id: workCardId }, initWorkCard());

                dispatch({
                    type: types.ADD_WORKCARD,
                    payload: { data: payload }
                });

                // Handle extra datas
                if (extraData.length > 0) {
                    dispatch(updateExtraDatas(extraData, workCardId));
                }

                // Handle documents
                if (documents.length > 0) {
                    documents.forEach(document => {
                        document.workCardId = workCardId;
                        dispatch(addWorkCardDocument(document, false))
                    });
                }

                redirect();
            }).catch(error => {
                HandleError(error, 'Add fault notice');
            })
    }
}

export function documentPayload(document, tempId, obj = {}) {
    Object.assign(obj, {
        id: tempId,
        caption: document.file
            ? document.file.name
            : document.linkToDocument
                ? document.linkToDocument.replace(/(^\w+:|^)\/\//, '')
                : null,
        documentLink: document.linkToDocument ? document.linkToDocument : null,
        createDate: Date.now(),
        description: document.description,
        type: document.type?.label ?? '',
        isUrl: document.isUrl,
        ...(document.workCardId && { workCardId: document.workCardId }),
        ...(document.machineId && { machineId: document.machineId }),
    });
    return obj;
}

export const updateWorkCard = (id, workCard, extraData = [], notificationTargets = [], removedNotificationTargets = [], redirect = null, cancelSubmit) => (
    async (dispatch, getState: () => State) => {
        const { statusTypes } = getState().settings;
        const isOffline = !getState().offline.online;
        const url = '/api/WorkCards/' + id;

        const payload = workCardPayloadData(workCard, extraData);
        let data = workCardRequestData(workCard);
        let isOfflineArchived = false;

        statusTypes.forEach(sType => {
            if (sType.statusValue === payload.workStatus && workCard.workCardType === 5) {
                isOfflineArchived = true;
            }
        });

        if (isOffline) {
            dispatch({
                type: types.UPDATE_WORKCARD,
                id,
                payload: {
                    data: payload,
                    id
                },
                isOfflineArchived,
                meta: {
                    offline: {
                        // the network action to execute
                        effect: { method: 'put', url, data },
                        // action to dispatch when effect succeeds
                        commit: { type: `${types.UPDATE_WORKCARD}_COMMIT`, meta: {} },
                        // action to dispatch if network action fails permanently
                        rollback: { type: `${types.UPDATE_WORKCARD}_ROLLBACK`, meta: {} }
                    }
                }
            })
            if (extraData.length > 0) {
                dispatch(updateExtraDatas(extraData, id));
            }
            if (notificationTargets.length > 0) {
                dispatch(updateNotificationTargets(notificationTargets, id));
            }
            if (removedNotificationTargets.length > 0) {
                dispatch(deleteNotificationTargets(removedNotificationTargets))
            }
            if (redirect !== null) {
                const location = {
                    pathname: '/workcard/' + id,
                    state: {
                        notificationMsg: 'WORKCARD_SAVED'
                    }
                }
                redirect(location);
            }
        } else {
            dispatch({
                type: types.UPDATE_WORKCARD,
                payload: {
                    data: payload,
                    id
                }
            });
            noviAPI.workCards.update(id, data)
                .then(response => {
                    if (extraData.length > 0) {
                        dispatch(updateExtraDatas(extraData, id));
                    }
                    if (notificationTargets.length > 0) {
                        dispatch(updateNotificationTargets(notificationTargets, id));
                    }
                    if (removedNotificationTargets.length > 0) {
                        dispatch(deleteNotificationTargets(removedNotificationTargets))
                    }
                    if (redirect !== null) {
                        const location = {
                            pathname: '/workcard/' + id,
                            state: {
                                notificationMsg: 'WORKCARD_SAVED'
                            }
                        }
                        redirect(location);
                    }
                })
                .catch(response => {
                    cancelSubmit?.();
                    HandleError(response, 'Update workcard');
                });
        }
    }
);

export const deleteWorkcard = id => (
    async dispatch => {
        try {
            const url = '/api/WorkCards/' + id;
            await request.delete(url);

            dispatch({
                type: types.DELETE_WORKCARD,
                id: id
            })
        } catch (error) {
            HandleError(error, 'Delete workcard');
        }
    }
);

export const updateExtraDatas = (extraDatas, id, isWorkCard = true) => {
    return async (dispatch, getState) => {
        extraDatas.forEach(extraData => {
            try {
                if (extraData.id) {
                    if (isWorkCard) {
                        noviAPI.workCardExtraDatas.update(id, extraData.id, extraData);
                        dispatch({
                            type: types.UPDATE_WC_EXTRA_DATA,
                            payload: {
                                data: extraData,
                                workCardId: id
                            }
                        });
                    } else {
                        noviAPI.workCardPhaseExtraDatas.update(id, extraData.id, extraData)
                        dispatch({
                            type: types.UPDATE_WP_EXTRA_DATA,
                            payload: {
                                data: extraData,
                                workCardPhaseId: id
                            }
                        });
                    }

                } else if (extraData.value || extraData.valueNumber) {
                    if (isWorkCard) {
                        noviAPI.workCardExtraDatas.add(id, extraData);
                        dispatch({
                            type: types.ADD_WC_EXTRA_DATA,
                            payload: {
                                data: extraData,
                                workCardId: id
                            }
                        });
                    } else {
                        noviAPI.workCardPhaseExtraDatas.add(id, extraData);
                        dispatch({
                            type: types.ADD_WP_EXTRA_DATA,
                            payload: {
                                data: extraData,
                                workCardPhaseId: id
                            }
                        });
                    }
                }
            } catch (error) {
                HandleError(error, 'Update work card extra datas');
            }
        });
    }
}

export const deleteNotificationTargets = (notificationTargetIds: number[]) => (
    dispatch => {
        notificationTargetIds.forEach(target => {
            noviAPI.notificationTargets.delete(target)
                .then(response => {
                    dispatch({
                        type: types.DELETE_WC_NOTIFICATION_TARGET,
                        payload: notificationTargetIds
                    })
                }).catch(error => {
                    HandleError(error, 'Delete notification target')
                })
        })
    }
);

export type UpdateNotificationTargets = (notificationTargets: INotificationTarget[], wcId: number) => void;

export const updateNotificationTargets: UpdateNotificationTargets = (notificationTargets, wcId) => {
    return async (dispatch, getState) => {
        notificationTargets.forEach(notificationTarget => {
            try {
                let url = '';

                if (notificationTarget.id === null || notificationTarget.id === undefined) {
                    url = '/api/NotificationTargets/';

                    const targets = getState().workcards.workcards
                        .reduce((arr, w) => arr.concat(w.notificationTargets), []);
                    const tempId = getTempId(targets);
                    notificationTarget['id'] = tempId;

                    dispatch({
                        type: types.ADD_WC_NOTIFICATION_TARGET,
                        payload: { ...notificationTarget, workCardId: wcId },
                        meta: {
                            offline: {
                                // the network action to execute
                                effect: { method: 'post', url, data: { ...notificationTarget, workCardId: wcId } },
                                // action to dispatch when effect succeeds
                                commit: { type: `${types.ADD_WC_NOTIFICATION_TARGET}_COMMIT`, meta: { wcId, tempId } },
                                // action to dispatch if network action fails permanently
                                rollback: { type: `${types.ADD_WC_NOTIFICATION_TARGET}_ROLLBACK`, meta: { wcId, tempId } }
                            }
                        }
                    })
                }
            } catch (error) {
                HandleError(error, 'Update workcard notification targets');
            }
        });
    }
}

export const addWorkPhase = (workPhase, automaticWorkphase = false, extraDatas = [], redirect, noRedirect: boolean = false) => {
    return async (dispatch, getState) => {
        try {
            const { workCardId } = workPhase;
            const url = '/api/WorkCards/' + workCardId + '/phases';
            const { workcards, storedWorkCards } = getState().workcards;
            const { online } = getState().offline;

            // create a temporary id for new work phase
            let maxId = 0;
            const wcArray = online ? workcards : storedWorkCards;

            wcArray.forEach(function (wc) {
                if (parseInt(wc.id, 10) === parseInt(workCardId, 10)) {
                    wc.phases.forEach(function (wp) {
                        if (wp.id > maxId) maxId = wp.id;
                    })
                }
            });

            const tempId = maxId + 1;
            const { machineGroupId } = getState().settings;

            // data for offline use
            const payload = workCardPayloadData(workPhase, extraDatas, { machineGroupId, id: tempId }, initWorkPhase());

            // data for API request
            const data = workCardRequestData(workPhase, { machineGroupId });

            dispatch({
                type: types.ADD_WORKPHASE,
                payload,
                meta: {
                    offline: {
                        // the network action to execute
                        effect: { method: 'post', url, data },
                        // action to dispatch when effect succeeds
                        commit: { type: `${types.ADD_WORKPHASE}_COMMIT`, meta: { workCardId, tempId } },
                        // action to dispatch if network action fails permanently
                        rollback: { type: `${types.ADD_WORKPHASE}_ROLLBACK`, meta: { workCardId, tempId } }
                    }
                }
            }).then((response) => {
                if (extraDatas.length > 0) {
                    const { data: wpId } = response;
                    dispatch(updateExtraDatas(extraDatas, wpId, false));
                }

                if (!automaticWorkphase && !noRedirect) {
                    const location = {
                        pathname: '/workcard/' + payload.workCardId
                    }
                    redirect(location);
                }

                toast.success(i18n.t('NEW_WORKPHASE_ADDED'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            }).catch(error => {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.data.errors) {
                        error.response.data.errors.forEach(err =>
                            Toaster({ msg: err.reason, type: 'error' })
                        )
                    } else {
                        Toaster({ msg: error.response.data.reason, type: 'error' })
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log(error.message)
                }

                dispatch({
                    type: types.API_CALL_ERROR
                });
            })
        } catch (error) {
            HandleError(error, 'Add workcard phase');
        }
    }
};

export const updateWorkPhase = (id, workPhase, extraDatas = [], redirect, noRedirect: boolean = false) => {
    return async dispatch => {
        try {
            const url = '/api/WorkCards/phases/' + id;

            const payload = workCardPayloadData(workPhase, extraDatas);
            const requestData = workCardRequestData(workPhase);

            request.put(url, requestData)
                .then(() => {
                    if (extraDatas.length > 0) {
                        dispatch(updateExtraDatas(extraDatas, id, false));
                    }

                    if (!noRedirect) {
                        const location = {
                            pathname: '/workcard/' + payload.workCardId
                        }
                        redirect(location);
                    }

                    toast.success(i18n.t('WORKPHASE_SAVED'), {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true
                    });

                }).catch(error => {
                    HandleError(error, 'Update workcard phase');
                });

            dispatch({
                type: types.UPDATE_WORKPHASE,
                payload,
                id
            });
        } catch (error) {
            HandleError(error, 'Update workcard phase');
        }
    }
};

export const deleteWorkPhase = (wcId: number, phaseId: number, redirect: IRedirect) => {
    return async dispatch => {
        try {
            noviAPI.workCardPhases.delete(phaseId)
                .then(res => {
                    dispatch({
                        type: types.DELETE_WORKPHASE,
                        wcId: wcId,
                        phaseId: phaseId
                    });

                    Toaster({
                        msg: "WORKPHASE_DELETED",
                        hideProgressBar: true,
                        type: "success"
                    });

                    const location = {
                        pathname: '/workcard/' + wcId
                    }

                    redirect(location);
                });
        } catch (error) {
            HandleError(error, 'Delete workcard phase');
        }
    }
};

export const updateRouteWorkCard = (routeWc, payloadData, redirect) => (
    dispatch => {
        const url = '/api/WorkCards/routeMaintenances/' + routeWc.id;

        routeWc.workers = payloadData.workers.map(worker => ({ id: worker.id, name: worker.label }));
        routeWc.workerGroups = payloadData.workerGroups.map(wGroup => ({ id: wGroup.id, name: wGroup.label }));

        let donePhasesCount = 0;// includes skipped and completed phases

        routeWc.phases.map(rPhase => {
            const modifiedPhase = payloadData.phases.find(phaseData => parseInt(phaseData.id, 10) === parseInt(rPhase.id, 10));
            rPhase.comment = modifiedPhase.comment;
            rPhase.doneDate = modifiedPhase.doneDate;
            rPhase.isComplete = modifiedPhase.isComplete;
            rPhase.skipped = modifiedPhase.skipped;
            rPhase.workHours = modifiedPhase.workHours;
            rPhase.routeMaintenanceHourCard = modifiedPhase.routeMaintenanceHourCard;

            if ((rPhase.isComplete && !rPhase.skipped) || (rPhase.skipped && !rPhase.isComplete)) {
                donePhasesCount++;
            }

            return rPhase;
        });

        let isOfflineArchived = false;
        if (donePhasesCount === routeWc.phases.length) {
            isOfflineArchived = true;
        }

        const payload = routeWorkCardPayloadData(routeWc);
        const data = routeWorkCardRequestData(payloadData);

        dispatch({
            type: types.UPDATE_ROUTE_WORKCARD,
            rWcId: routeWc.id,
            payload: payload,
            isOfflineArchived,
            meta: {
                offline: {
                    // the network action to execute
                    effect: { method: 'put', url, data },
                    // action to dispatch when effect succeeds
                    commit: { type: `${types.UPDATE_ROUTE_WORKCARD}_COMMIT`, meta: {} },
                    // action to dispatch if network action fails permanently
                    rollback: { type: `${types.UPDATE_ROUTE_WORKCARD}_ROLLBACK`, meta: {} }
                }
            }
        }).then(() => {
            const location = {
                pathname: '/worklist',
                state: { notificationMsg: 'ROUTE_MAINTENANCE_SAVED' }
            }

            redirect(location);
        }).catch(error => {
            HandleError(error, 'Update route workcard');
        });
    }
);

export type DeleteRouteWorkCard = (id: number, redirect: IRedirect) => void;

export const deleteRouteWorkCard: DeleteRouteWorkCard = (id, redirect) => (
    dispatch => noviAPI.routeMaintenanceWorkCards.delete(id)
        .then(() => {
            dispatch({ type: types.DELETE_WORKCARD, id });
            const location = {
                pathname: '/worklist',
                state: { notificationMsg: i18n.t('WORKCARD_DELETED') }
            }
            redirect(location);
        })
        .catch(error => {
            Toaster({ msg: error.response.data.reason, type: 'error' })
        })
);

export const addMaterial = (material: IWorkCardSparePartAddition, redirect?: IRedirect) => {
    return () => {
        noviAPI.workCardSpareParts.add(material)
            .then(() => {
                const location = {
                    pathname: '/workcard/' + material.workCardId,
                    state: { notificationMsg: 'MATERIAL_SAVED' }
                }

                if (redirect) {
                    redirect(location);
                }
            }).catch(error => {
                HandleError(error, 'Update workcard material');
            });
    }
}

export const updateMaterial = (id: number, updateParams: IWorkCardSparePartUpdate, redirect: IRedirect) => {
    return () => {
        const { workCardId } = updateParams;

        noviAPI.workCardSpareParts.update(id, updateParams)
            .then(() => {
                const location = {
                    pathname: '/workcard/' + workCardId,
                    state: { notificationMsg: 'MATERIAL_SAVED' }
                }

                redirect(location);
            }).catch(error => {
                HandleError(error, 'Update workcard material');
            });
    }
}

export type IDeleteMaterial = (id: number) => void;

export const deleteMaterial: IDeleteMaterial = id => {
    return async dispatch => {
        try {
            noviAPI.workCardSpareParts.delete(id)
                .then(() => {
                    toast.success(i18n.t('MATERIAL_DELETED'), {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true
                    });

                    dispatch({
                        type: types.DELETE_MATERIAL,
                        payload: { id },
                    })
                }).catch(error => {
                    HandleError(error, 'Delete workcard material');
                });
        } catch (error) {
            HandleError(error, 'Delete material');
        }
    }
}

export const addPermit = (permit: IPermitAddition, wcId, redirect?: IRedirect) => {
    return () => {
        noviAPI.workPermits.add(permit)
            .then(() => {
                const location = {
                    pathname: '/workcard/' + wcId,
                    state: { notificationMsg: 'PERMIT_SAVED' }
                }

                if (redirect) {
                    redirect(location);
                }
            }).catch(error => {
                HandleError(error, 'Error in adding work permit');
            });
    }
}

export const updatePermit = (permit: IPermitUpdate, wcId, id, redirect?: IRedirect) => {
    return () => {
        noviAPI.workPermits.update(id, permit)
            .then(() => {
                const location = {
                    pathname: '/workcard/' + wcId,
                    state: { notificationMsg: 'PERMIT_SAVED' }
                }

                if (redirect) {
                    redirect(location);
                }
            }).catch(error => {
                HandleError(error, 'Error in adding work permit');
            });
    }
}

export type IFetchWorkCardDocuments = (workCardId: number) => void;

export const fetchWorkcardDocuments: IFetchWorkCardDocuments = workCardId => (
    dispatch => {
        dispatch({
            type: `${types.FETCH_WORKCARD_DOCUMENTS}_PENDING`,
        });
        noviAPI.workCards.fetchDocuments(workCardId)
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_WORKCARD_DOCUMENTS}_FULFILLED`,
                    payload: {
                        documents: data,
                        wcId: workCardId
                    }
                })
            })
            .catch(error => {
                HandleError(error, 'Fetch workcard documents');

                dispatch({
                    type: `${types.FETCH_WORKCARD_DOCUMENTS}_REJECTED`,
                    payload: error
                })
            })
    }
);

export type IFetchRouteMaintenanceDocuments = (routeWcId: number) => void;

export const fetchRouteMaintenanceDocuments: IFetchRouteMaintenanceDocuments = routeWcId => (
    dispatch => {
        dispatch({
            type: `${types.FETCH_ROUTE_WORKCARD_DOCUMENTS}_PENDING`,
        });

        noviAPI.routeMaintenanceWorkCards.documents(routeWcId)
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_ROUTE_WORKCARD_DOCUMENTS}_FULFILLED`,
                    payload: {
                        documents: data,
                        wcId: routeWcId
                    }
                })
            })
            .catch(error => {
                HandleError(error, 'Fetch route maintenance documents');

                dispatch({
                    type: `${types.FETCH_ROUTE_WORKCARD_DOCUMENTS}_REJECTED`,
                    payload: error
                })
            })
    }
);

export const downloadDocument = id => {
    return async dispatch => {
        try {
            const { data } = await request.get('/api/Documents/' + id + '/download', { responseType: 'blob' });

            const url = window.URL.createObjectURL(data);

            dispatch({
                type: `${types.GET_DOCUMENT}_FULFILLED`,
                payload: {
                    document: data,
                    url
                }
            })
        } catch (error) {
            HandleError(error, 'Download document');
        }
    }
}

export const fetchWorkCardMaterials = (workCardId: number, callback?: () => void) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_WORKCARD_MATERIALS}_PENDING`,
        });
        try {
            const { data } = await noviAPI.workCards.fetchSpareParts(workCardId);

            dispatch({
                type: `${types.FETCH_WORKCARD_MATERIALS}_FULFILLED`,
                payload: data
            })

            if (callback) {
                callback();
            }
        } catch (error) {
            HandleError(error, 'Fetch workcard materials');

            dispatch({
                type: `${types.FETCH_WORKCARD_MATERIALS}_REJECTED`,
                payload: error
            })
        }
    }
)

export const fetchWorkcardPermits = (wcId) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_WORKCARD_PERMITS}_PENDING`,
        });
        try {

            request.get('/api/WorkCards/' + wcId + '/workPermits')
                .then(response => {
                    dispatch({
                        type: `${types.FETCH_WORKCARD_PERMITS}_FULFILLED`,
                        payload: response.data
                    })
                }).catch(error => {
                    HandleError(error, 'Fetch workcard permits');
                })
        } catch (error) {
            HandleError(error, 'Fetch workcard permits');

            dispatch({
                type: `${types.FETCH_WORKCARD_PERMITS}_REJECTED`,
                payload: error
            })
        }
    }
);

export type IFetchWorkcardOptions = (...args: string[]) => void;

export const fetchWorkcardOptions: IFetchWorkcardOptions = (...args) => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_WORKCARD_OPTIONS}_PENDING`,
        });
        try {
            const { settings } = getState();
            const details = getState().workcards.details;

            if (!settings.machineGroupId) return;

            let workerOptions = [];
            let ordererOptions = [];
            let workTypeOptions = [];
            let workCardTypeOptions = [
                { id: 1, label: 'WORK_CARD_TYPE1' },
                { id: 2, label: 'WORK_CARD_TYPE2' },
                { id: 3, label: 'WORK_CARD_TYPE3' },
            ];
            let investmentCodeOptions = [];
            let requests = [];
            let wTypes = [];
            let payload = {};

            if (settings.noviConfigs?.OperatorMaintenances === 'True') {
                workCardTypeOptions.push({ id: 4, label: 'WORK_CARD_TYPE4' });
            }

            if (settings.noviConfigs?.RouteMaintenancesEnabled === 'True') {
                workCardTypeOptions.push({ id: 5, label: 'WORK_CARD_TYPE5' });
            }

            if (settings.machineGroupId) {
                if (args.length === 0 || args.includes('workers')) {
                    requests.push(noviAPI.workers.fetchAll(settings.machineGroupId))
                    wTypes.push('workers')
                }

                if (args.length === 0 || args.includes('orderers')) {
                    requests.push(noviAPI.orderers.fetchAll(settings.machineGroupId))
                    wTypes.push('orderers')
                }

                if (args.length === 0 || args.includes('workTypes')) {
                    if (details.length > 0) {
                        requests.push(new Promise((res, rej) => {
                            res({ data: details });
                        }))
                    } else {
                        requests.push(noviAPI.workCardDetails.fetchAll(settings.machineGroupId))
                    }
                    wTypes.push('workTypes')
                }

                if (args.length === 0 || args.includes('investmentcodes')) {
                    requests.push(noviAPI.investmentCodes.fetchAll(settings.machineGroupId))
                    wTypes.push('investmentCodes')
                }
            }

            if (args.length === 0
                || args.includes('workers')
                || args.includes('orderers')
                || args.includes('workTypes')
                || args.includes('investmentcodes')
            ) {
                await axios.all(requests).then(responses => {
                    let results = {};
                    wTypes.map((type, i) => (
                        results = Object.assign(results, { [type]: responses[i] })
                    ))

                    if (args.length === 0 || args.includes('workers')) {
                        results['workers'].data.forEach(function (option) {
                            workerOptions.push(
                                { id: option.id, label: option.name, personGroupIds: option.personGroupIds }
                            )
                        });
                        payload = Object.assign(payload, { workerOptions })
                    }
                    if (args.length === 0 || args.includes('orderers')) {
                        results['orderers'].data.forEach(function (option) {
                            ordererOptions.push(
                                { id: option.id, label: option.name }
                            )
                        });
                        payload = Object.assign(payload, { ordererOptions })
                    }

                    payload = Object.assign(payload, { workCardTypeOptions });

                    if (args.length === 0 || args.includes('workTypes')) {
                        workTypeOptions = results['workTypes'].data
                            .filter(data => data.group === 'worktype')
                            .map(option => ({ id: option.id, label: option.value }))

                        if (workTypeOptions.some(obj => obj.hasOwnProperty('rowNumber'))) {
                            workTypeOptions = workTypeOptions.sort((a, b) => a.rowNumber - b.rowNumber);
                        }

                        payload = Object.assign(payload, { workTypeOptions })
                    }

                    if (args.length === 0 || args.includes('investmentcodes')) {
                        results['investmentCodes'].data.forEach(function (option) {
                            investmentCodeOptions.push(
                                { id: option.id, label: option.value }
                            )
                        });
                        payload = Object.assign(payload, { investmentCodeOptions })
                    }
                }).catch(error => {
                    HandleError(error, 'Fetch workcard options');
                })
            }

            dispatch({
                type: `${types.FETCH_WORKCARD_OPTIONS}_FULFILLED`,
                payload
            })
        } catch (error) {
            HandleError(error, 'Fetch workcard options');

            dispatch({
                type: `${types.FETCH_WORKCARD_OPTIONS}_REJECTED`,
                payload: error
            })
        }
    }
)

export type IAddWorkCardDocument = (
    document: IWorkCardLinkDocument | IWorkCardFileDocument,
    addToasterMsg?: boolean
) => void;

export const addWorkCardDocument: IAddWorkCardDocument = (document, addToasterMsg = true) => {
    return async (dispatch, getState: () => State) => {
        try {
            const url = '/api/Documents';
            const { documents: wcDocuments } = getState().documents.workCard.workcardDocuments;
            const { workCardId } = document;

            // create temporary id for new document
            const tempId = getTempId(wcDocuments);

            const payload = documentPayload(document, tempId);

            dispatch({
                type: types.ADD_WORKCARD_DOCUMENT,
                payload,
                meta: {
                    offline: {
                        // the network action to execute
                        effect: {
                            method: 'post',
                            url,
                            data: document,
                            transformRequest: documentTransformRequest.concat(request.defaults.transformRequest),
                            headers: { 'Content-Type': 'multipart/form-data' }
                        },
                        // action to dispatch when effect succeeds
                        commit: { type: `${types.ADD_WORKCARD_DOCUMENT}_COMMIT`, meta: { workCardId, tempId } },
                        // action to dispatch if network action fails permanently
                        rollback: { type: `${types.ADD_WORKCARD_DOCUMENT}_ROLLBACK`, meta: { workCardId, tempId } }
                    }
                }
            });

            if (addToasterMsg) {
                Toaster({ msg: 'DOCUMENT_ADDED', type: 'success' });
            }
        } catch (error) {
            HandleError(error, 'Add document');
        }
    }
}

export type IAddRouteMaintenanceDocument = (
    document: IRouteMaintenanceLinkDocument | IRouteMaintenanceLinkDocument,
    addToasterMsg?: boolean
) => void;

export const addRouteMaintenanceDocument: IAddRouteMaintenanceDocument = (document, addToasterMsg = true) => {
    return async (dispatch, getState: () => State) => {
        try {
            const url = '/api/Documents';
            const { documents: wcDocuments } = getState().documents.workCard.workcardDocuments;
            const { routeMaintenanceWorkCardId } = document;

            // create temporary id for new document
            const tempId = getTempId(wcDocuments);

            const payload = documentPayload(document, tempId);

            dispatch({
                type: types.ADD_ROUTE_WORKCARD_DOCUMENT,
                payload,
                meta: {
                    offline: {
                        // the network action to execute
                        effect: {
                            method: 'post',
                            url,
                            data: document,
                            transformRequest: documentTransformRequest.concat(request.defaults.transformRequest),
                            headers: { 'Content-Type': 'multipart/form-data' }
                        },
                        // action to dispatch when effect succeeds
                        commit: { type: `${types.ADD_ROUTE_WORKCARD_DOCUMENT}_COMMIT`, meta: { routeMaintenanceWorkCardId, tempId } },
                        // action to dispatch if network action fails permanently
                        rollback: { type: `${types.ADD_ROUTE_WORKCARD_DOCUMENT}_ROLLBACK`, meta: { routeMaintenanceWorkCardId, tempId } }
                    }
                }
            });

            if (addToasterMsg) {
                Toaster({ msg: 'DOCUMENT_ADDED', type: 'success' });
            }
        } catch (error) {
            HandleError(error, 'Add route work card document');
        }
    }
}

export const deleteWorkCardDocument = (id, wcId, documentGroup = '') => {
    return (dispatch, getState: () => State) => {
        if (getState().offline.online) {
            noviAPI.workCards.deleteDocument(id)
                .then(response => {
                    dispatch({
                        type: types.DELETE_WORKCARD_DOCUMENT,
                        payload: { id, wcId, wcDocumentGroup: documentGroup }
                    })

                    toast.success(i18n.t('DOCUMENT_DELETED'), {
                        position: toast.POSITION.TOP_CENTER,
                        hideProgressBar: true
                    });
                })
                .catch(error => {
                    HandleError(error, 'Delete workcard document');

                    dispatch({
                        type: `${types.DELETE_WORKCARD_DOCUMENT}_REJECTED`,
                        payload: error
                    })
                });
        } else {
            const url = `/api/WorkCards/documents/${id}`;
            dispatch({
                type: types.DELETE_WORKCARD_DOCUMENT,
                payload: { id, wcId, wcDocumentGroup: documentGroup },
                meta: {
                    offline: {
                        effect: { method: 'delete', url }
                    }
                }
            });
        }
    }
}

export const deleteRouteWorkCardDocument = (id, routeWcId, documentGroup = '') => {
    return (dispatch, getState: () => State) => {
        if (getState().offline.online) {
            noviAPI.routeMaintenanceWorkCards.deleteDocument(id)
                .then(response => {
                    dispatch({
                        type: types.DELETE_ROUTE_WORKCARD_DOCUMENT,
                        payload: { id, routeWcId, routeWcDocumentGroup: documentGroup }
                    })
                })
                .catch(error => {
                    HandleError(error, 'Delete route workcard document');

                    dispatch({
                        type: `${types.DELETE_ROUTE_WORKCARD_DOCUMENT}_REJECTED`,
                        payload: error
                    })
                });
        } else {
            const url = `/api/WorkCards/routeMaintenances/documents/${id}`;
            dispatch({
                type: types.DELETE_ROUTE_WORKCARD_DOCUMENT,
                payload: { id, routeWcId, routeWcDocumentGroup: documentGroup },
                meta: {
                    offline: {
                        effect: { method: 'delete', url }
                    }
                }
            });
        }
    }
}

/**
 * Action for fetching workcard extra datas
 *
 * url: /api/WorkCards/{id}/ExtraDatas
 */
export const fetchWorkcardExtraDatas = (wcId) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_WORKCARD_EXTRA_DATAS}_PENDING`,
        });
        try {
            const url = '/api/WorkCards/' + wcId + '/ExtraDatas';
            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_WORKCARD_EXTRA_DATAS}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch workcard extra datas');

            dispatch({
                type: `${types.FETCH_WORKCARD_EXTRA_DATAS}_REJECTED`,
                payload: error
            })
        }
    }
)

/**
 * Action for fetching permit extra datas
 *
 * url: /api/WorkPermits/{id}/extraData
 */
export const fetchPermitExtraDatas = (permitId) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_PERMIT_EXTRA_DATAS}_PENDING`,
        });
        try {
            const url = '/api/WorkPermits/' + permitId + '/extraData';
            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_PERMIT_EXTRA_DATAS}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch permit extra datas');

            dispatch({
                type: `${types.FETCH_PERMIT_EXTRA_DATAS}_REJECTED`,
                payload: error
            })
        }
    }
)

/**
 * Action for fetching permit details
 *
 * url: /api/WorkPermits/{id}/details
 */
export const fetchPermitDetails = (permitId) => (
    async (dispatch, getState) => {
        try {
            const url = '/api/WorkPermits/' + permitId + '/details';
            const { data } = await request.get(url);

            dispatch({
                type: types.FETCH_PERMIT_DETAILS,
                payload: data
            })

        } catch (error) {
            HandleError(error, 'Fetch permit details');
        }
    }
)

export const fetchWorkCardHourCards = (workCardId: number, mgId?: number) => (
    async (dispatch, getState: () => State) => {
        const { machineGroupId, userId } = getState().settings;
        try {
            let searchParams = new URLSearchParams();
            const mGroupId = mgId || machineGroupId;
            searchParams.append('MachineGroupId', `${mGroupId}`);
            searchParams.append('WorkCardIds', `${workCardId}`);
            // User is only allowed to see their own hour cards on the work card
            searchParams.append('PersonIds', `${userId}`);

            const response = await noviAPI.hourCards.search(searchParams);

            dispatch({
                type: `${types.FETCH_WORK_CARD_HOUR_CARDS}`,
                payload: response.data.results
            })
        } catch (error) {
            HandleError(error, 'Fetch hour cards');
        }
    }
);

export const fetchWorkCardLimitedWorkStatuses = (machineGroupId: number, group: string) => (
    dispatch => {
        dispatch({
            type: `${types.FETCH_WORK_CARD_LIMITED_WORKSTATUSES}_PENDING`,
        });
        noviAPI.personGroupAppSettings.fetchAllByUserAndGroup(group, machineGroupId)
            .then(response => {
                dispatch({
                    type: `${types.FETCH_WORK_CARD_LIMITED_WORKSTATUSES}_FULFILLED`,
                    payload: response.data,
                    group: group
                })
            })
            .catch(error => {
                HandleError(error, 'Fetch workcard limited work statuses');

                dispatch({
                    type: `${types.FETCH_WORK_CARD_LIMITED_WORKSTATUSES}_REJECTED`,
                    payload: error
                })
            })
    }
);

export const fetchMeasurementsByWorkCard = (workCardId: number) => (
    async dispatch => {
        try {
            const { data } = await noviAPI.measurementGroupWorkCards.fetchAllByWorkCard(workCardId);
            dispatch({
                type: types.FETCH_WORK_CARD_MEASUREMENTS,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch measurements by work card');
        }
    }
);

export const deleteMeasurementGroupWorkCard = (measurement: IMeasurementGroupWorkCard) => (
    async dispatch => {
        try {
            await noviAPI.measurementGroupWorkCards.delete(measurement.measurementGroupId, measurement.workCardId);
            dispatch({
                type: types.DELETE_WORK_CARD_MEASUREMENT,
                payload: measurement
            })
        } catch (error) {
            HandleError(error, 'Fetch measurements by work card');
        }
    }
);

export const setWorkCardInformation = (data: IWorkCardComplete) => (
    async dispatch => {
        dispatch({
            type: types.SET_WORKCARD_INFORMATION,
            payload: {
                hourCards: data.hourCards,
                workCardMeasurements: data.measurements,
                materials: data.spareParts,
                workPermits: data.workPermits
            }
        });
        dispatch({
            type: `${types.FETCH_MACHINE}_FULFILLED`,
            payload: {
                machine: data.machine
            }
        })
        dispatch({
            type: `${types.FETCH_WORKCARD_DOCUMENTS}_FULFILLED`,
            payload: {
                documents: data.documents,
                wcId: data.id
            }
        })
    }
)

export function workCardPayloadData(workCard, extraDatas, obj = {}, init = {}) {
    // Deconstruct values that need transforming
    const {
        workers = [],
        workStatus = null,
        machine = null,
        machineHalt = false,
        urgency = null,
        orderer = null,
        workerGroups = null,
        idleTime = 0,
        responsiblePerson = null,
        phases,
        phasesPayload = [],
        ...rest
    } = workCard;

    // Initialize payload data if work card is new and add values that doesn't need transforming
    const payload = { ...init, ...rest, ...obj };

    [{ workers }, { workStatus }, { machine }, { machineHalt }, { urgency }, { orderer }, { workerGroups }, { extraDatas }, { idleTime }, { responsiblePerson }, { phases: phases || phasesPayload }]
        // Add deconstructed values to payload if they were submitted (=field exists in view settings) 
        // and transform their data into format required by the API
        .forEach(function (obj) {
            const newObj = transformWorkCardValue(obj);
            if (newObj !== null) Object.assign(payload, newObj);
        });

    return payload;
}

export function fillTargetlist(targetList, notificationTargets) {
    notificationTargets.forEach(notificationTarget => {
        try {

            if (notificationTarget.id === null || notificationTarget.id === undefined) {

                targetList.push(notificationTarget.value)
            }
        } catch (error) {
            HandleError(error, 'Update workcard notification targets');
        }
    });
    return targetList;
}

export function workCardRequestData(workCard, obj = {}) {
    // Deconstruct values that need transforming
    const {
        workers = [],
        workStatus = null,
        machine = null,
        machineHalt = false,
        urgency = null,
        orderer = null,
        ordererText = null,
        workerGroups = null,
        details = null,
        responsiblePerson = null,
        workerName,
        idleTime,
        phasesRequest,
        faultDescription,
        costPools = [],
        investmentCode,
        ...rest
    } = workCard;

    // Add values that doesn't need transforming into payload
    const payload = { ...rest, ...obj };
    // Rename keys if they differ from their API counterparts: { newKey: oldKey }
    [
        { workerIds: workers },
        { workStatus },
        { machineId: machine },
        { responsiblePersonId: responsiblePerson },
        { machineHalt },
        { urgency },
        { ordererId: orderer?.id || null },
        { ordererName: ordererText || orderer?.name },
        { workerGroupIds: workerGroups },
        { detailIds: details },
        { workerName },
        { idleTime },
        { phases: phasesRequest },
        { faultDescription: faultDescription },
        { costPoolIds: costPools },
        { investmentCodeId: investmentCode }
    ]
        // Add deconstructed values to payload if they were submitted (=field exists in view settings) 
        // and transform their data into format required by the API
        .forEach(function (obj) {
            const newObj = transformWorkCardValue(obj);
            if (newObj !== null) Object.assign(payload, newObj);
        });
    return payload;
}

function routeWorkCardPayloadData(routeWorkCard, obj = {}, init = {}) {
    // Deconstruct values that need transforming
    const {
        workers = null,
        routeMachines = null,
        orderer = null,
        ordererText = null,
        workerGroups = null,
        ...rest
    } = routeWorkCard;

    // Initialize payload data if work card is new and add values that doesn't need transforming
    const payload = { ...init, ...rest, ...obj };

    [{ workers }, { routeMachines }, { orderer }, { workerGroups }, { ordererName: ordererText }]
        // Add deconstructed values to payload if they were submitted (=field exists in view settings) 
        // and transform their data into format required by the API
        .forEach(function (obj) {
            const newObj = transformWorkCardValue(obj);
            if (newObj !== null) Object.assign(payload, newObj);
        });

    return payload;
}

function routeWorkCardRequestData(routeWc, obj = {}) {
    // Deconstruct values that need transforming
    const {
        workers = null,
        workerGroups = null,
        ...rest
    } = routeWc;

    // Add values that doesn't need transforming into payload
    const payload = { ...rest, ...obj };
    // Rename keys if they differ from their API counterparts: { newKey: oldKey }
    [
        { workerIds: workers },
        { workerGroupIds: workerGroups }
    ]
        // Add deconstructed values to payload if they were submitted (=field exists in view settings) 
        // and transform their data into format required by the API
        .forEach(function (obj) {
            const newObj = transformWorkCardValue(obj);
            if (newObj !== null) Object.assign(payload, newObj);
        });

    return payload;
}

function transformWorkCardValue(obj) {
    const key = Object.keys(obj)[0];
    let value = obj[key];

    // Return null if value was not submitted
    if (value === null) return { [key]: value };

    if (key === 'workers' || key === 'workerGroups') {
        if (!isArray(value)) { value = [value] }
        return { [key]: value.map(item => ({ id: item.id, name: item.label || item.name })) }
    }
    else if (key === 'workerGroupIds' || key === 'workerIds') {
        if (!Array.isArray(value)) { value = [value] }
        return { [key]: value.map(item => (item.id)) }
    }
    else if (key === 'machineHalt') { return { [key]: value.id ? true : false } }
    else if (key === 'machine') { return { [key]: value } }
    else if (key === 'idleTime') { return { [key]: (value === '' || value === null) ? null : parseFloat(value)}}
    else if (key === 'ordererId' && value?.id) { return { [key]: value.id } }
    else if (key === 'investmentCodeId' && value?.id) { return { [key]: value.id } }
    else if (key === 'ordererName') { return { [key]: value?.id ? null : value } }
    else if (key === 'responsiblePersonId') { return { [key]: value.id } }
    else if (key === 'responsiblePerson') { return { [key]: value?.id ? { id: value.id, name: value.label } : null } }
    else if (key === 'orderer') { return { [key]: value.id ? { id: value.id, name: value.label || value.name } : value } }
    else if (
        key === 'machineId'
        || key === 'urgency'
        || key === 'workStatus'
    ) {
        return { [key]: value.id ? value.id : value }
    } else if (key === 'detailIds') {
        return { [key]: value.map(item => (Number(item.id))) }
    } else if (key === 'costPoolIds') {
        return { [key]: value.map(item => (item.id)) }
    } else {
        return { [key]: value }
    }
}

export const documentTransformRequest = [(data, _headers) => {
    const formData = new FormData();

    for (const key in data) {
        if (key === 'type') {
            formData.append(key, data[key].label)
        }
        else if (Array.isArray(data[key])) {
            data[key].forEach(val => {
                formData.append(key, val);
            })
        } else {
            formData.append(key, data[key])
        }
    }

    return formData;
}]

export function getTempId(array: Array<{ id: number } & { [key: string]: any }>): number {
    let maxId = 0;
    for (const element of array) {
        if (element && element.id > maxId) maxId = element.id;
    }
    return maxId + 1;
}

export function getTempWpId(array: any, parentId: number) {
    // create a temporary id for new work phase
    let maxId = 0;

    array.forEach(function (wc) {
        if (parseInt(wc.id, 10) === parentId) {
            wc.phases.forEach(function (wp) {
                if (wp.id > maxId) maxId = wp.id;
            })
        }
    });

    return maxId + 1;
}

function initWorkCard() {
    return {
        agreedDoneDate: null,
        code: null,
        comment: null,
        counter: null,
        documentIds: [],
        extraDate1: null,
        faultBegin: null,
        faultDescription: null,
        haltReason: null,
        hourEstimate: null,
        id: null,
        idleTime: 0,
        instructions: null,
        isRouteMaintenanceWorkCard: false,
        lastModified: null,
        machineGroupId: null,
        machineHalt: false,
        machine: [],
        orderDate: null,
        orderer: null,
        otherCosts: null,
        procedure: null,
        referenceId: null,
        responsiblePerson: null,
        urgency: null,
        workBegin: null,
        workCanBegin: null,
        workCanEnd: null,
        workCaption: null,
        workCardDetailIds: [],
        phases: [],
        workCardType: null,
        workEnded: null,
        workHours: null,
        workStatus: 1,
        workerGroup: [],
        workerName: null,
        workers: [],
        extraDatas: [],
        hourCards: []
    }
}

function initWorkPhase() {
    return {
        comment: null,
        hourEstimate: null,
        id: null,
        instructions: null,
        machineHalt: false,
        orderNumber: null,
        procedure: null,
        responsiblePersonId: null,
        workBegin: null,
        workCanBegin: null,
        workCanEnd: null,
        workCardId: null,
        workEnded: null,
        workHours: null,
        workStatus: null,
        workers: []
    }
}

function initRouteWorkCard() {
    return {
        id: null,
        faultDescription: null,
        isRouteMaintenanceWorkCard: true,
        orderDate: null,
        orderer: null,
        phases: [],
        routeMachines: [],
        rowNumber: null,
        urgency: null,
        workCanBegin: null,
        workCanEnd: [],
        workCardDetailIds: [],
        workerGroups: [],
        workers: [],
    }
}
