import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import i18n from '../../../translations/i18n';
import noviAPI from '../../../api/noviAPI';
import { updateWarehouseLink } from '../actions';
import { useHistory } from 'react-router-dom';

interface IProps {
	warehouseLinkId: number;
	submit: boolean;
	setHasChanged: (changed: boolean) => void;
}

const initForm = {
	alertLimit: '',
	criticality: '',
	memo: '',
	orderAmount: '',
	shelfLocation: ''
}

const WarehouseLinkForm = (props: IProps) => {
	let history = useHistory();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(initForm);
	const [sparePart, setSparePart] = useState({
		id: null,
		warehouseId: null
	});

	const handleSubmit = useCallback(() => {
		dispatch(updateWarehouseLink(
			props.warehouseLinkId,
			{
				...formData,
				orderAmount: formData.orderAmount.length ? parseInt(formData.orderAmount, 10) : null,
				alertLimit: formData.alertLimit.length ? parseInt(formData.alertLimit, 10) : null,
				warehouseId: sparePart.warehouseId,
				sparePartId: sparePart.id
			},
			history.replace
		));
	}, [dispatch, props.warehouseLinkId, formData, sparePart, history.replace]);

	useEffect(() => {
		if (props.submit) {
			handleSubmit();
		}
	}, [props.submit, handleSubmit]);

	useEffect(() => {
		// fetch warehouse spare part link by ID
		noviAPI.warehouseSparePartLinks.fetch(props.warehouseLinkId)
			.then(response => {
				/**
				 * obj takes properties from the response which match
				 * properties of formData and aren't null
				 * */
				const obj = Object.entries(response.data).filter(
					([key, value]) => Object.keys(initForm).includes(key)
						&& value !== null
				).reduce((result, currentArray) => {
					result[currentArray[0]] = currentArray[1];
					return result;
				}, {});
				// update formData with properties form the response
				setFormData(f => ({
					...f,
					...obj
				}));
				// set sparePart.id and sparePart.warehouseId for sending update request
				setSparePart({
					id: response.data.sparePart.id,
					warehouseId: response.data.warehouse.id
				});
			})
	}, [props.warehouseLinkId]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		setFormData(f => ({ ...f, [name]: value }));
		props.setHasChanged(true);
	}

	return (
		<Container>
			<div className="form-table-container margin-top-15">
				<form>
					<FormElement
						name="alertLimit"
						type="number"
						label={i18n.t('ALERT_LIMIT')}
						value={formData.alertLimit}
						onChange={handleChange} />
					<FormElement
						name="criticality"
						type="text"
						label={i18n.t('CRITICALITY')}
						value={formData.criticality}
						onChange={handleChange} />
					<FormElement
						name="memo"
						type="text"
						label={i18n.t('MEMO')}
						value={formData.memo}
						onChange={handleChange} />
					<FormElement
						name="orderAmount"
						type="number"
						label={i18n.t('ORDER_AMOUNT')}
						value={formData.orderAmount}
						onChange={handleChange} />
					<FormElement
						name="shelfLocation"
						type="text"
						label={i18n.t('SHELF_LOCATION')}
						value={formData.shelfLocation}
						onChange={handleChange} />
				</form>
			</div>
		</Container>
	);
}

export default WarehouseLinkForm;
