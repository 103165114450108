import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function HierarchyItem({ title, isLevel, isChildren = false, onItemClick = null, onArrowClick = null, number = 0 }) {
    return (
        <div className="hierarchy-item">
            <div
                className={isLevel ? 'hierarchy-item-title' : 'hierarchy-item-title hierarchy-item-left-border'}
                onClick={onItemClick}
            >
                {title}
                {Boolean(number) && <span className="number-badge">{number}</span>}
            </div>
            {isChildren && <div
                className="hierarchy-item-arrow"
                onClick={onArrowClick}
            >
                <span>
                    <FontAwesomeIcon icon={faAngleRight} />
                </span>
            </div>}
        </div>
    );
}

export default HierarchyItem;