import React from 'react';
import { Table } from 'react-bootstrap';
import TableRowComponent from './TableRowComponent';
import { toKeyFormat } from './HelperFunctions';

const TableComponent = ({ headings = null, rows = null, children = null, handleClick = null }) => {
    const tbody = children === null
        ? rows.map((row, i) => (
            <TableRowComponent
                key={i}
                keyId={i}
                row={row}
                handleClick={handleClick}
            />))
        : children;

    return (
        <Table responsive className="table-list">
            {headings && <thead>
                <tr>
                    {headings.map((heading, i) => (
                        <th key={i} style={{ minWidth: heading.width }}>{toKeyFormat(heading.label)}</th>
                    ))}
                </tr>
            </thead>}
            <tbody>
                {tbody}
            </tbody>
        </Table>
    );
}

export default TableComponent;