import React from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { ScrollToTop } from '../../../components/ScrollToTop'
import useViewSettings from '../../../hooks/useViewSettings'
import { FETCH_HOURCARD_VIEW_SETTINGS } from '../../../config/actionTypes'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import NavigationBar from '../../navigation'
import settingsAPI from '../../../config/settingsAPI'
import i18n from '../../../translations/i18n'
import FormElement from '../../work-schedule/work-card/components/FormElement'
import { Loader } from '../../../components/Loader'
import { useEffect } from 'react'
import noviAPI from '../../../api/noviAPI'
import { HourCardStatusLabels } from '../../../constants/hourCards'
import {
    getAllowedStatusOptions,
    getSalaryCategoriesByPerson,
    getStatusLabel,
    initValues,
    mapCostPoolsToCostPoolOptions,
    mapEnumToOptions,
    mapHourCardDetailsToHourCardDetailOptions,
    mapSalaryCategoriesToSalaryCategoryOptions,
    mapValuesToHourCardAddition,
    mapValuesToHourCardUpdate,
    OptionGroup,
    validateField
} from '../utils'
import SelectField from '../../../components/form/SelectField'
import { isNullOrWhitespace, Toaster } from '../../../components/HelperFunctions'
import useHourCard from '../../../hooks/useHourCard'
import { handleDateTimeInput } from 'utils'

const moduleSettings = settingsAPI.moduleData.hourcards;

type LocationState = { workCardId?: number; workCardCode?: number; costPools?: ICostPoolLite[] };
type UrlParams = { id: string; };
type Values = { [name: string]: any; };

const EditHourCard = () => {
    const settings = useSelector((state: State) => state.settings);
    const [viewSettings] = useViewSettings('hourcard', FETCH_HOURCARD_VIEW_SETTINGS);

    const [values, setValues] = useState<Values>({});

    const { id } = useParams<UrlParams>();
    const { hourCard, isUserSupervisor } = useHourCard(Number(id));
    const [showLoader, setShowLoader] = useState('pending')
    const [salaryCategoryOptions, setSalaryCategoryOptions] = useState<IOptionType[]>([]);
    const [costPools, setCostPools] = useState<OptionGroup>({});
    const [hourCardDetailOptions, setHourCardDetailOptions] = useState<OptionGroup>({});
    const [invalidFields, setInvalidFields] = useState<String[]>([]);
    const [hasChanged, setHasChanged] = useState(false);

    let location = useLocation<LocationState>();
    let history = useHistory();

    useEffect(() => {
        let hourCardValues: Values = initValues(viewSettings);
        if (id) {
            // Edit hour card
            if (hourCard === null) return;

            const keysOfValues = Object.keys(hourCardValues);

            // Assign a value from hour card when hour card property and values property matches
            Object.keys(hourCard).forEach(key => {
                try {
                    const lowerCaseKey = key.toLowerCase();
                    let value = hourCard[key];
                    if (keysOfValues.includes(lowerCaseKey)) {
                        // Set exceptions here
                        if (lowerCaseKey === 'status') {
                            value = { value: `${value}`, label: getStatusLabel(value) };
                        }
                        if (lowerCaseKey === 'creator') {
                            value = hourCard.person;
                        }
                        if (lowerCaseKey === 'salarycategory') {
                            value = { value: `${value.id}`, label: value.name };
                        }
                        if (lowerCaseKey === 'workcard') {
                            value = hourCard.workCard?.code || '';
                        }
                        // Make it easier to validate 0
                        if (lowerCaseKey === 'hours' && value !== null) {
                            value = hourCard.hours.toString();
                        }
                        Object.assign(hourCardValues, {
                            [lowerCaseKey]: value
                        });
                    }
                    // Set exceptions
                    if (lowerCaseKey === 'person') {
                        Object.assign(hourCardValues, {
                            creator: hourCard.person
                        });
                    }
                    if (lowerCaseKey === 'costpools') {
                        for (const costPool of hourCard.costPools) {
                            Object.assign(hourCardValues, {
                                [`costpoolgroup_${costPool.groupId}`]: { value: `${costPool.id}`, label: costPool.translationKey }
                            })
                        }
                    }
                    if (lowerCaseKey === 'details' || lowerCaseKey === "multidetails") {
                        for (const detail of hourCard.details) {
                            Object.assign(hourCardValues, {
                                [detail.group]: [
                                    ...(hourCardValues[detail.group] ?? []),
                                    { value: `${detail.id}`, label: detail.value }
                                ]
                            })
                        }
                    }
                    if (lowerCaseKey === 'extradatas') {
                        for (const extraData of hourCard.extraDatas) {
                            Object.assign(hourCardValues, {
                                [extraData.group]: extraData
                            })
                        }
                    }
                }
                catch (error) {
                    console.log(error);
                }
            });
        }
        else {
            // New hour card
            try {
                // Set status
                hourCardValues.status = mapEnumToOptions(HourCardStatusLabels)[0];

                // Set work card
                if (location.state?.workCardCode) {
                    hourCardValues.workcard = location.state.workCardCode;
                }
                // Set creator (SSO user)
                if (settings.userAdNameDetails.id) {
                    hourCardValues.creator = {
                        id: settings.userAdNameDetails.id,
                        name: settings.userAdNameDetails.name
                    };
                }
                // Set creator (user)
                if (settings.userDetails.id) {
                    hourCardValues.creator = {
                        id: settings.userDetails.id,
                        name: settings.userDetails.name
                    };
                }
                // Set default cost pools from work card
                if (location.state?.costPools?.length > 0) {
                    for (const costPool of location.state.costPools) {      
                        if (costPools[costPool.groupId]?.find(cp => parseInt(cp.value, 10) === costPool.id)) {
                            Object.assign(hourCardValues, {
                                [`costpoolgroup_${costPool.groupId}`]: { value: `${costPool.id}`, label: costPool.translationKey }
                            })
                        }
                    }
                }

            } catch (error) {
                console.log(error);
            }
        }

        setValues(prevState => ({
            ...prevState,
            ...hourCardValues
        }));
    }, [id, hourCard, viewSettings, location.state, settings.userAdNameDetails, settings.userDetails, costPools]);

    useEffect(() => {
        const fetchSalaryCategories = async () => {
            let userId = Number(settings.userId);
            if (hourCard !== null) {
                userId = hourCard.person.id;
            }
            const salaryCategories = await getSalaryCategoriesByPerson(userId, settings.machineGroupId);
            setSalaryCategoryOptions(mapSalaryCategoriesToSalaryCategoryOptions(salaryCategories));
        }
        fetchSalaryCategories();
    }, [hourCard, settings.userId, settings.machineGroupId]);

    useEffect(() => {
        const getCostPools = async () => {
            try {
                const response = await noviAPI.costPools.fetchAll(settings.machineGroupId);
                const results = response.data.results;

                let hourCardCostPools: ICostPoolLite[] = [];
                if (hourCard) {
                    hourCardCostPools = hourCard.costPools;
                }

                setCostPools(mapCostPoolsToCostPoolOptions(results, hourCardCostPools));
            } catch (error) {
                console.log(error);
            }
        }
        getCostPools();
    }, [settings.machineGroupId, hourCard]);

    useEffect(() => {
        const getHourCardDetails = async () => {
            try {
                const response = await noviAPI.hourCardDetails.fetchByMachineGroup(settings.machineGroupId);
                const results = response.data.results;
                setHourCardDetailOptions(mapHourCardDetailsToHourCardDetailOptions(results))
            } catch (error) {
                console.log(error)
            }
        }

        getHourCardDetails();
        setShowLoader('fulfilled');
    }, [settings.machineGroupId])

    const sceneData = {
        view: moduleSettings.name,
        title: i18n.t('HOUR_CARD'),
        location: location,
        history: history,
        itemColors: [],
        hasChanged: hasChanged,
        backAction: { action: history.goBack, params: {mainEditView: true} }
    }

    const viewAction = {
        icon: 'save',
        label: '',
        clickFn: () => handleSubmit(),
        isActionFn: true,
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues(prevState => ({ ...prevState, [name]: value }))
        setHasChanged(true);
    }

    const handleExtraData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name], // Keeps the ID if user is editing an existing extra data value
                group: name, // Important when the extra data doesn't exist yet
                value: value
            }
        }))
        setHasChanged(true)
    }

    const handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        setValues(prevState => ({
            ...prevState,
            [dateInput.name]: dateInput.value
        }));
        setHasChanged(true)
    };

    const handleSelect = (value, actionMeta) => {
        setValues(prevState => ({
            ...prevState,
            [actionMeta.name]: value
        }))
        setHasChanged(true)
    }

    const getWorkCardIdByCode = (code) => {
        const data = noviAPI.workCards.fetchByCode(code)
            .then(response => {
                if (response.status === 200) {
                    return response.data.id
                }
            });
        return data;
    }

    const handleSubmit = async () => {
        const hourCardValues = Object.assign({}, values);

        // Check if required field is empty or value is null (checks for the "no selection" are separate from this)
        let requiredEmptyFields: IViewSetting[] = [];

        for (const key in hourCardValues) {
            try {
                let setting = viewSettings.find(i => i.field === key);
                if (setting) {
                    let isRequired = setting.required || ['hours', 'salarycategory'].includes(setting.field);
                    if (isRequired && !validateField(hourCardValues[key])) {
                        requiredEmptyFields.push(setting);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }

        setInvalidFields(requiredEmptyFields.map(setting => setting.field));

        // Return if the form has empty but required fields
        if (requiredEmptyFields.length > 0) {
            Toaster({ msg: `INVALID_FIELDS`, type: "error" });
            return;
        }

        // Check work card validity. The value must be empty or valid.
        let isWorkCardValid = true;
        try {
            if (!isNullOrWhitespace(hourCardValues.workcard)) {
                hourCardValues.workcard = await getWorkCardIdByCode(hourCardValues.workcard);
            }
        } catch (e) {
            isWorkCardValid = false;
        }

        // Return if work card is not valid
        if (!isWorkCardValid) {
            Toaster({ msg: 'ENTER_VALID_WC_CODE_OR_EMPTY', type: 'error' });
            return;
        }

        if (id) {
            // Update the hour card here
            let hourCardUpdate = mapValuesToHourCardUpdate(hourCardValues, viewSettings);

            noviAPI.hourCards.update(Number(id), hourCardUpdate)
                .then(response => {
                    if (response.status === 200) {
                        Toaster({ msg: 'UPDATE_HOUR_CARD_SUCCESS', type: 'success' });
                        history.push('/hourcard/' + id)
                    }
                }, reason => {
                    Toaster({ msg: 'UPDATE_HOUR_CARD_FAILURE', type: 'error' });
                });
        }
        else {
            // Add a new hour card here
            let newHourCard = mapValuesToHourCardAddition(hourCardValues, viewSettings, settings.machineGroupId);

            noviAPI.hourCards.add(newHourCard)
                .then(response => {
                    if (response.status === 200) {
                        Toaster({ msg: 'ADD_HOUR_CARD_SUCCESS', type: 'success' });
                        if (location.state?.workCardId) {
                            history.push(`/workcard/${location.state?.workCardId}`);
                        } else {
                            history.push('/hourcards');
                        }
                    }
                }, reason => {
                    Toaster({ msg: 'ADD_HOUR_CARD_FAILURE', type: 'error' });
                });
        }
    }

    const getStatusOptions = () => {
        return hourCard ? getAllowedStatusOptions(hourCard, settings, isUserSupervisor) : [];
    }

    const getViewSettingComponent = (viewSetting, i) => {
        if (viewSetting.field === 'salarycategory') {
            return (
                <SelectField
                    key={i}
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={values[viewSetting.field]}
                    options={salaryCategoryOptions || []}
                    onChange={handleSelect}
                    required
                    noDefaultOption
                />
            );
        }
        if (viewSetting.field === 'workcard') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={'text'}
                label={i18n.t(viewSetting.translationKey)}
                value={values[viewSetting.field] || ''}
                onChange={handleChange}
                required={viewSetting.required}
            />
        }
        if (viewSetting.field === 'status') {
            if (id) {
                return <SelectField
                    key={i}
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={values[viewSetting.field]}
                    options={getStatusOptions()}
                    onChange={handleSelect}
                    required={viewSetting.required}
                    noDefaultOption
                />
            }
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={'label'}
                label={i18n.t(viewSetting.translationKey)}
                value={values[viewSetting.field].label}
                onChange={() => null}
                required={viewSetting.required}
            />
        }
        if (viewSetting.field === 'hourcardcostpool') {
            return (
                <SelectField
                    key={i}
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={values[viewSetting.field]}
                    options={hourCardDetailOptions[viewSetting.field] || []}
                    onChange={handleSelect}
                    required={viewSetting.required}
                />
            );
        }
        if (viewSetting.type === 'extradata') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={'text'}
                label={i18n.t(viewSetting.translationKey)}
                value={values[viewSetting.field]?.value || ''}
                onChange={handleExtraData}
                required={viewSetting.required}
            />
        }
        if (viewSetting.type === 'detail' || viewSetting.type === 'multidetail') {
            return (
                <SelectField
                    key={i}
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={values[viewSetting.field]}
                    options={hourCardDetailOptions[viewSetting.field] || []}
                    onChange={handleSelect}
                    required={viewSetting.required}
                    isMulti={viewSetting.type === 'multidetail'}
                />
            );
        }
        if (viewSetting.type === 'textarea') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={'textarea'}
                label={i18n.t(viewSetting.translationKey)}
                value={values[viewSetting.field] || ''}
                onChange={handleChange}
                required={viewSetting.required}
            />
        }
        if (viewSetting.type === 'label' && viewSetting.field !== 'inputdayofweek') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={'label'}
                label={i18n.t(viewSetting.translationKey)}
                value={values[viewSetting.field]?.name || ''}
                onChange={''}
                required={viewSetting.required}
            />
        }
        if (viewSetting.type === 'creator') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={'label'}
                label={i18n.t(viewSetting.translationKey)}
                value={values[viewSetting.field]?.name}
                onChange={''}
                required={viewSetting.required}
            />
        }
        if (viewSetting.field === 'creatorcomment' || viewSetting.field === 'supervisorcomment' || viewSetting.field === 'customercomment') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={viewSetting.type}
                value={values[viewSetting.field] || ''}
                label={i18n.t(viewSetting.translationKey)}
                onChange={handleChange}
                required={viewSetting.required}
            />
        }
        if (viewSetting.field === 'hours') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type='number'
                value={values.hours}
                label={i18n.t('AMOUNT')}
                onChange={handleChange}
                required
            />
        }
        if (viewSetting.type === 'costpoolgroup') {
            let options: IOptionType[] = [];
            try {
                const costPoolGroup = viewSetting.field.split('costpoolgroup_')[1];
                options = costPools[costPoolGroup] || [];
            } catch (e) {
                console.log(e);
            }
            return (
                <SelectField
                    key={i}
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={values[viewSetting.field]}
                    options={options}
                    onChange={handleSelect}
                    required={viewSetting.required}
                    noDefaultOption={viewSetting.required}
                />
            );
        }
        if (viewSetting.field === 'workcardproject') {
            return <FormElement
                key={i}
                name={viewSetting.field}
                type={'label'}
                value={values.project ? values.project.code + ' / ' + values.project.name : null}
                label={viewSetting.translationKey}
                onChange={handleSelect}
                required={viewSetting.required} 
                options={[]}
            />
        }
        return <></>
    }

    // TODO: Refactor the render structure to be more generic / unify same type of elements to be handled with same logic.
    return (
        <div>
            <ScrollToTop />
            <NavigationBar
                currentView={sceneData}
                popoverData={''}
                viewAction={viewAction}
            />
            {<Loader status={showLoader} />}
            {<Container>
                <div className="form-table-container bottom-nav-space v-margins-15">
                    {viewSettings.filter(viewSetting => viewSetting.field !== 'inputdayofweek').sort((a, b) => a.tabOrder - b.tabOrder).map((viewSetting, i) => (
                        <div key={viewSetting.id} className={invalidFields.includes(viewSetting.field) ? 'invalid-field' : ''}>
                            {getViewSettingComponent(viewSetting, i)}     
                        </div>
                    ))}
                </div>
            </Container>}
        </div>
    )
}

export default EditHourCard
