import { faFileAlt, faFolderOpen, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noviAPI from 'api/noviAPI';
import ActionButton from 'components/buttons/ActionButton';
import DialogBody from 'components/dialogs/DialogBody';
import DialogFooter from 'components/dialogs/DialogFooter';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogModal from 'components/dialogs/DialogModal';
import ErrorMessage from 'components/ErrorMessage';
import { Loader } from 'components/Loader';
import React from 'react';
import { Alert, Col, Container, Image, Row } from 'react-bootstrap';
import i18n from 'translations/i18n';
import { documentAddition, documentUnlinkToUrlParams } from 'utils';

const ImageModal = (props: IProps) => {
    let inputRef = React.useRef(null);
    const [previewImageUrl, setPreviewImageUrl] = React.useState('');
    const [previewImageIsCurrentImage, setPreviewImageIsCurrentImage] = React.useState(false);
    const [file, setFile] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    const initImage = React.useCallback(() => {
        if (props.imageUrl) {
            setPreviewImageUrl(props.imageUrl);
            setPreviewImageIsCurrentImage(true);
        }
    }, [props.imageUrl])

    React.useEffect(() => {
        initImage();
    }, [initImage])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        const inputFile = files.item(0);

        if (inputFile) {
            setShowError(false);
            setPreviewImageIsCurrentImage(false);
            setPreviewImageUrl(URL.createObjectURL(inputFile));
            setFile(inputFile);
        }
    }

    const removePreviewImage = () => {
        inputRef.current.value = '';
        setFile(null);
        setPreviewImageUrl('');
        setPreviewImageIsCurrentImage(false);
    }

    const clearPreview = () => {
        removePreviewImage();
        setShowError(false);
    }
    
    const closeModal = () => {
        if (!saving) {
            clearPreview();
            props.closeModal();
        }
    }

    const saveImage = async () => {
        setSaving(true);
        setPreviewImageUrl('');
        let newDocumentId = null;
        try {
            if (props.target.documentId) {
                // unlink old document
                const documentLink = {
                    documentId: props.target.documentId,
                    targetType: props.target.targetType,
                    targetId: props.target.targetId
                }
                await noviAPI.documents.unlink(documentUnlinkToUrlParams(documentLink));
                URL.revokeObjectURL(props.imageUrl);
            }

            if (file) {
                // add document
                const document = documentAddition({
                    file: file,
                    linkToDocument: '',
                    description: '',
                    type: '',
                    isPreviewPicture: true
                }, props.target.targetType, props.target.targetId);
                const response = await noviAPI.documents.add(document);
                newDocumentId = response.data;
            }
            props.onSaved(newDocumentId);
        } catch (error) {
            removePreviewImage();
            setShowError(true);
        }
        setSaving(false);
    }

    const imageOnLoad = () => {
        if (!previewImageIsCurrentImage) {
            URL.revokeObjectURL(previewImageUrl)
        }
    }
    
    const noFileText = (
        <p>{i18n.t('NO_FILE')}</p>
    )

    const errorMessage = <ErrorMessage />;

    const mainContent = (
        <React.Fragment>
            <Loader ready={!(props.loading || saving)} />
            <Image src={previewImageUrl} onLoad={imageOnLoad} />
            {!(previewImageIsCurrentImage || file || props.loading || saving) && noFileText}
        </React.Fragment>
    )

    const removeButton = (
        <ActionButton disabled={!(previewImageIsCurrentImage || file || showError) || props.loading || saving} handleClick={clearPreview}>
            <FontAwesomeIcon icon={faTrash} size="lg" />
        </ActionButton>
    )

    const openButton = (
        <ActionButton disabled={!previewImageIsCurrentImage || props.loading || saving} handleClick={() => window.open(previewImageUrl, '_blank')}>
            <FontAwesomeIcon icon={faFileAlt} size="lg" />
        </ActionButton>
    )

    const selectFileButton = (
        <React.Fragment>
            <ActionButton disabled={props.loading || saving} handleClick={() => inputRef.current.click()}>
                <FontAwesomeIcon icon={faFolderOpen} />
            </ActionButton>
            <input ref={inputRef} type="file" accept="image/*" style={{ display: "none" }} onChange={handleChange} />
        </React.Fragment>
    )
    
    const saveButton = (
        <ActionButton disabled={previewImageIsCurrentImage || (!file && !props.target.documentId) || props.loading || saving || showError} handleClick={saveImage}>
            <FontAwesomeIcon icon={faSave} size="lg" />
        </ActionButton>
    )

    const buttonRow = (
        <Row>
            <Col>{removeButton}</Col>
            <Col>{openButton}</Col>
            <Col>{selectFileButton}</Col>
            <Col>{saveButton}</Col>
        </Row>
    )

    return (
        <DialogModal
            showDialog={props.showModal}
            closeDialog={closeModal}
            className="image-modal"
            onShow={initImage}
        >
            <DialogHeader>{i18n.t('ADD_PICTURE')}</DialogHeader>
            <DialogBody>
                {showError ? errorMessage : mainContent}
            </DialogBody>
            <DialogFooter>
                <Container>
                    {buttonRow}
                </Container>
            </DialogFooter>
        </DialogModal>
    )
}

interface IProps {
    target: Target;
    loading: boolean;
    imageUrl: string;
    caption?: string;
    showModal: boolean;
    closeModal: () => void;
    onSaved: (documentId: number) => void;
}

type Target = {
    documentId: number;
    targetType: number;
    targetId: number;
}

export default ImageModal;