import { isNullOrWhitespace } from "../../components/HelperFunctions";
import { VIEW_FILTER } from "../../constants/costPools";

export const defaultFields: IViewSetting[] = [
    {
        id: 1,
        field: 'warehouse',
        translationKey: 'WAREHOUSE',
        type: 'select',
        tabOrder: 120,
        required: true,
        values: [],
        requirements: [],
        options: []
    },
    {
        id: 2,
        field: 'amount',
        translationKey: 'AMOUNT',
        type: 'number',
        tabOrder: 130,
        required: true,
        values: [],
        requirements: [],
        options: []
    },
    {
        id: 3,
        field: 'price',
        translationKey: 'PRICE',
        type: 'number',
        tabOrder: 140,
        required: true,
        values: [],
        requirements: [],
        options: []
    },
    {
        id: 4,
        field: 'shelfLocation',
        translationKey: 'SHELF_LOCATION',
        type: 'text',
        tabOrder: 150,
        required: false,
        values: [],
        requirements: [],
        options: []
    },
    {
        id: 5,
        field: 'purchaseOrder',
        translationKey: 'PURCHASE_ORDER',
        type: 'select',
        tabOrder: 160,
        required: true,
        values: [],
        requirements: [],
        options: []
    },
    {
        id: 6,
        field: 'purchaseOrderOpenAmount',
        translationKey: 'PURCHASE_ORDER_OPEN_AMOUNT',
        type: 'label',
        tabOrder: 170,
        required: true,
        values: [],
        requirements: [],
        options: []
    }
];

export const getArriveViewSettingsWithFixedTypes = (settings: IViewSetting[]) => {
    return settings.map(s => {
        if (s.field === 'warehouse' || s.field === 'purchaseOrder') {
            return { ...s, type: 'select' }
        }
        else if (s.field === 'amount' || s.field === 'price') {
            return { ...s, type: 'number' }
        }
        else if (s.field === 'purchaseOrderOpenAmount') {
            return { ...s, type: 'label' }
        }
        return s
    })
}

export const machineField = {
    name: 'machine',
    label: 'MACHINE',
    type: 'machine',
    tabOrder: 113,
    required: true,
    operationTypes: ['take', 'return']
}

export const workCardField = {
    name: 'workCard',
    label: 'WORKCARD',
    type: 'text',
    tabOrder: 113,
    required: true,
    operationTypes: ['take', 'return']
}

export const reservationField = {
    name: "reservation",
    label: "SPAREPARTRESERVATION",
    type: 'select',
    tabOrder: 113,
    required: true,
    operationTypes: ['take']
}

/**
 * Maps cost pools to cost pool options
 * 
 * @param costPools
 */
export const mapCostPoolsToCostPoolOptions = (costPools: ICostPool[]) => {
    return costPools
        .filter(i => warehouseCostPoolViewFilter(i) && !i.archived)
        .map(i => ({ ...i, label: i.translationKey }))
}

/**
 * Returns true if the cost pool is restricted to warehouse view or it is not restricted to any view.
 * 
 * @param costPool
 */
export const warehouseCostPoolViewFilter = (costPool: ICostPool) => {
    return costPool.viewFilter.length < 1 || costPool.viewFilter.includes(VIEW_FILTER.WAREHOUSE)
}



/**
 * Takes IWarehouseFilters object and returns the number of active filters
 * @param filters
 */
 export const countActiveWarehouseFilters = (filters: IWarehouseFilters) => {
    let count = 0;
    Object.keys(filters).forEach(key => {
        try {
            const filterValue = filters[key];
            if (isNullOrWhitespace(filterValue)) {
                return;
            }
            if (filters[key].hasOwnProperty('min') && filters[key].hasOwnProperty('max')) {
                if (isNullOrWhitespace(filters[key].min) && isNullOrWhitespace(filters[key].max)) {
                    return;
                }
            }
            if (key === 'thirdParties') {
                const keys = Object.keys(filters.thirdParties);
                for (const _key in keys) {
                    count++;
                }
                return;
            }
            if (key === 'details') {
                const keys = Object.keys(filters.details);
                for (const _key in keys) {
                    count++;
                }
                return;
            }
            count++;
        } catch (error) {
            console.log(error)
        }
    });
    return count;
}


// Used for mapping thirdPartyType into update parameter for api
export const thirdPartyTypeMap = {
    "1": 'supplierIds', 
    "2": 'manufacturerIds', 
    "3": 'contractorIds'
}