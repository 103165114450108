import React from 'react';
import '../../styles/global.scss';
import NavigationBar from '../navigation';
import { Container, Button } from 'react-bootstrap';
import i18n from '../../translations/i18n';
import FormElement from '../work-schedule/work-card/components/FormElement';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';
import RedirectToDesktopButton from '../../components/RedirectToDesktopButton';
import noviAPI from '../../api/noviAPI';
import authAPI from '../../api/authAPI';
import { parseJwt, redirectToChangePasswordPage } from './utils';
import axios from 'axios';
import { HandleError, Toaster } from '../../components/HelperFunctions';
import { getDateTime } from 'utils';

class Login extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            errorMessage: ''
        }
    }

    validateForm = () => {
        const { username, password } = this.state;

        if (!username || !password) {
            return false;
        }

        return true;
    }

    handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value
        })
    }

    submitLoginKey = e => {
        if (e.key === 'Enter') {
            this.submitLogin()
        }
    }
    
    submitLogin = () => {
        if (!this.validateForm()) {
            toast.error(i18n.t('INVALID_LOGIN_VALUES'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });

            this.setState({ invalidFields: true })
            return;
        } else {
            this.setState({ invalidFields: false })
        }

        this.login();
    }

    login = async () => {
        try {
            const { username, password } = this.state;
            const { data } = await authAPI.userLogin({ username, password });
            const { access_token, refresh_token, expires_in } = data;

            const jwtDecoded = parseJwt(access_token);
            const userId = jwtDecoded.sub;

            authAPI.setAccessToken(access_token);
            authAPI.setRefreshToken(refresh_token);
            authAPI.setExpiresIn(expires_in);
            authAPI.setRefreshLoop();

            const userDetails = await this.getUserDetails(userId);
            this.props.setUserDetails(userDetails);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response) {
                    // Request made and server responded
                    if (e.response.status === 400) {
                        const errorDesc = e.response.data.error_description;

                        if (errorDesc == "USER_LOCKED_OUT_MOBILE") {
                            const date = getDateTime(e.response.data.lockedUntil);

                            const errorMessage = i18n.t(errorDesc, {
                                user: this.state.username,
                                date: date
                            });

                            this.setState({ errorMessage });
                        }
                        else if (errorDesc === 'password_expired') {
                            redirectToChangePasswordPage();
                        } else {
                            Toaster({ msg: 'INVALID_LOGIN_VALUES', type: 'error' });
                        }
                    } else {
                        HandleError(e, 'Login');
                    }
                } else if (e.request) {
                    // The request was made but no response was received
                    Toaster({ msg: e.request, type: 'error' });
                } else if (e.message) {
                    // Something happened in setting up the request that triggered an Error
                    Toaster({ msg: e.message, type: 'error' });
                }
            }
            else {
                console.error(e);
                HandleError(e, "Unknown login error");
            }
        }
    }

    getUserDetails = async (id: number) => {
        try {
            const response = await noviAPI.people.fetch(id);
            return response.data;
        } catch (e) { console.log(); }
    }

    render() {
        const { username, password, invalidFields } = this.state;
        const { userId, locationState } = this.props;

        const sceneData = {
            view: 'login',
            title: null,
            location: this.props.location,
            history: this.props.history
        }

        if ((userId && authAPI.getAccessToken()) || window['runConfig'].authGatewayURL) {
            const lastUserId = localStorage.getItem('lastUser');

            // Redirect only if logged in user is the same as previous user
            if ((!lastUserId || lastUserId === userId?.toString()) && locationState?.location?.pathname) {
                const location = {
                    pathname: locationState.location.pathname,
                    state: { originalPath: locationState.location.pathname, noPreviousPage: true }
                }

                this.props.history.push(location);
            } else {
                return <Redirect push to={{ pathname: '/dashboard', state: true }} />
            }
        }

        return (
            <div>
                <NavigationBar
                    currentView={sceneData}
                    viewAction={null}
                />
                <Container className="main-content-box flex flex-flow-column login-container" onKeyDown={this.submitLoginKey}>
                    {!window['runConfig'].authGatewayURL && <div>
                        <p style={{color: "red"}}>{this.state.errorMessage}</p>
                        <div className="form-table-container margin-top-15">
                            <form>
                                <div className={!username && invalidFields ? 'invalid-field' : ''}>
                                    <FormElement
                                        name={'username'}
                                        type={'input'}
                                        label={i18n.t('USERNAME', 'Username')}
                                        value={username}
                                        options={[]}
                                        onChange={this.handleInputChange}
                                        required={true}
                                    />
                                </div>
                                <div className={!password && invalidFields ? 'invalid-field' : ''}>
                                    <FormElement
                                        name={'password'}
                                        type={'passwordinput'}
                                        label={i18n.t('PASSWORD', 'Password')}
                                        value={password}
                                        options={[]}
                                        onChange={this.handleInputChange}
                                        required={true}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="v-paddings-15 button-contents-centered">
                            <Button id="submitLogin" onClick={this.submitLogin} className="action action-button novi-default-btn-color" variant="small">
                                <div>
                                    <span>{i18n.t('LOGIN')}</span>
                                </div>
                            </Button>
                        </div>
                        <div className="login-bottom-container">
                            <RedirectToDesktopButton />
                        </div>
                    </div>}
                </Container>
            </div>
        );
    }
}

export default Login;
