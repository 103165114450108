import React from 'react';
import PopoverAction from '../../../components/PopoverAction';
import i18n from '../../../translations/i18n';
import { HasRight } from '../../../components/HelperFunctions';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { PAGE_SIZES } from '../../../constants/displaySettings';
import { useDispatch, useSelector } from 'react-redux';
import { clearFiltersAction, setListLayoutAction, setPageSizeAction, setWarehouseSettings } from '../actions';
import { useHistory } from 'react-router-dom';
import { countActiveWarehouseFilters } from '../utils';
import { resetQuickSearch } from 'commonActions/actions';

interface IProps {
    closeDialog?: () => void;
    dialogOpen?: boolean;
    toggleDialog?: () => void;
}

const WarehouseMenu = (props: IProps) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const displaySettings = useSelector((state: State) => state.warehouse.settings);
    const userRights = useSelector((state: State) => state.settings.userRights);
    const filters = useSelector((state: State) => state.warehouse.currentFilters);

    const setPageSize = (value) => {
        dispatch(setPageSizeAction(value));
    }

    const setListLayout = (value) => {
        dispatch(setListLayoutAction(value));
    }

    const toggleUseDefaultSearch = () => {
        // Reset filters if there are any active filters
        if (countActiveWarehouseFilters(filters) > 0) {
            dispatch(clearFiltersAction());
        }

        dispatch(resetQuickSearch('warehouse'));
        
        // Set default search on/off and set page to 1
        dispatch(setWarehouseSettings({
            useDefaultSearch: !displaySettings.useDefaultSearch,
            pageNumber: 1
        }));
    }

    let settingsMenuHeadings = [];
    settingsMenuHeadings.push(i18n.t('LISTING_TYPE'));
    settingsMenuHeadings.push(i18n.t('DISPLAY_AMOUNT'));
    let settingsMenuContents = [];

    settingsMenuContents.push(
        <div>
            <PopoverAction
                icon="list"
                label={i18n.t('LAYOUT_LIST')}
                active={displaySettings.listLayout}
                onClick={() => setListLayout(true)}
                inline
            />
            <PopoverAction
                icon="table"
                label={i18n.t('LAYOUT_TABLE')}
                active={!displaySettings.listLayout}
                onClick={() => setListLayout(false)}
                inline
            />
            <hr />
        </div>
    );

    settingsMenuContents.push(
        <div>
            <div className="small-options-list">
                {Object.values(PAGE_SIZES).map(size => (
                    <PopoverAction
                        key={size}
                        label={size.toString()}
                        active={displaySettings.pageSize === size}
                        onClick={() => setPageSize(size)}
                        inline
                    />
                ))}
            </div>
            <hr />
        </div>
    );

    settingsMenuContents.push(<div key={settingsMenuContents.length}>
        <PopoverAction
            icon="search"
            label={i18n.t('RESET_SEARCH')}
            active={displaySettings.useDefaultSearch}
            onClick={toggleUseDefaultSearch}
        />
        <hr />
    </div>);

    HasRight('warehouseoperationinvent', userRights) && settingsMenuContents.push(
        <div>
            <PopoverAction
                icon="chart-bar"
                label={i18n.t('INVENT')}
                onClick={() => history.push('/warehouseinvent', true)}
            />
        </div>
    );

    return (
        <div>
            {props.dialogOpen && <div className="overlay-canvas" onClick={() => props.closeDialog()} ></div>}
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={
                    <Popover id="settings-menu-popover" className="menu-popover">
                        {React.Children.map(settingsMenuContents, (child, i) => (
                            <div className="sub-section" key={`setting_${i}`}>
                                {settingsMenuHeadings !== null &&
                                    settingsMenuHeadings?.length > 0
                                    && settingsMenuHeadings[i] !== null &&
                                    <div className="setting-header">
                                        {settingsMenuHeadings[i]}
                                    </div>
                                }
                                <div className="setting-option">
                                    {child}
                                </div>
                            </div>
                        ))}
                    </Popover>
                }
            >
                <Button
                    id="footerMenuButton"
                    variant="primary"
                    className="action action-button novi-default-btn-color"
                    size="lg"
                    onClick={() => props.toggleDialog()}
                >
                    <div>
                        <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
                    </div>
                </Button>
            </OverlayTrigger>
        </div>
    );
}

export default WarehouseMenu;