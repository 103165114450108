import { clearCacheAction } from 'commonActions/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout, logoutSSO } from 'scenes/login/actions';

const CacheBuster = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        clearCacheAndLogout();
    }, [clearCacheAndLogout])

    const getLatestVersion = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.PUBLIC_URL}/meta.json?` + new Date().getTime());
            const json = await response.json();
            return json.version;
        } catch (error) { }
    }, [])

    const clearCacheAndLogout = useCallback(async () => {
        const latestVersion = await getLatestVersion();
        const currentVersion = localStorage.getItem('frontendversion');
        
        if (currentVersion === null) {
            localStorage.setItem('frontendversion', latestVersion);
            return;
        }

        if (currentVersion === latestVersion) {
            return;
        }

        localStorage.setItem('frontendversion', latestVersion);
        
        if (window['runConfig'].authGatewayURL) {
            dispatch(logoutSSO());
        } else {
            dispatch(logout());
        }
        
        dispatch(clearCacheAction());

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
                for (let name of names) caches.delete(name);
            });
        }

    }, [dispatch, getLatestVersion])

    return null;
}

export default CacheBuster;