import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetQuickSearch } from '../../../commonActions/actions';
import { CountFilters as countActiveFilters, HasRight } from '../../../components/HelperFunctions';
import PopoverAction from '../../../components/PopoverAction';
import { PAGE_SIZES } from '../../../constants/displaySettings';
import i18n from '../../../translations/i18n';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { resetCurrentFilters, setMachinesSettings } from '../actions';

interface IProps {
    closeDialog?: () => void;
    dialogOpen?: boolean;
    toggleDialog?: () => void;
}

const MachinesSettingsMenu = (props: IProps) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const displaySettings = useSelector((state: State) => state.machines.settings);
    const userRights = useSelector((state: State) => state.settings.userRights);
    const filters = useSelector((state: State) => state.machines.currentFilters);
    const isQuickSearchActive = useSelector((state: State) => state.navigation.activeQuickSearches.worklist) !== null;

    const toggleLayout = (value: "treelayout" | "cardlayout" | "tablelayout") => {
        if (displaySettings.layoutType !== value) {
            dispatch(setMachinesSettings({ layoutType: value }));
        }
    }

    const setPageSize = (value: number) => {
        if (displaySettings.pageSize !== value) {
            dispatch(setMachinesSettings({ pageSize: value }));
        }
    }

    const setUseDefaultSearch = (value: boolean) => {
        if (displaySettings.useDefaultSearch !== value) {
            // Reset filters if there are any active filters
            if (countActiveFilters(filters) > 0) {
                dispatch(resetCurrentFilters());
            }
            // Reset quick search if there is a quick search selected
            if (isQuickSearchActive) {
                dispatch(resetQuickSearch('worklist'));
            }
            // Set default search on/off and set page to 1
            dispatch(setMachinesSettings({
                useDefaultSearch: value
            }));
        }
    }

    const addMachine = () => {
        history.push('/machine/new');
    }

    let settingsMenuHeadings = [i18n.t('LISTING_TYPE'), i18n.t('DISPLAY_AMOUNT')];
    let settingsMenuContents = [];

    settingsMenuContents.push(
        <div>
            <PopoverAction
                icon="cards"
                label={i18n.t('LAYOUT_CARDS')}
                active={displaySettings.layoutType === 'cardlayout'}
                onClick={() => toggleLayout('cardlayout')}
                inline
            />
            <PopoverAction
                icon="table"
                label={i18n.t('LAYOUT_TABLE')}
                active={displaySettings.layoutType === 'tablelayout'}
                onClick={() => toggleLayout('tablelayout')}
                inline
            />
            {(!filters || countActiveFilters(filters) === 0) && <PopoverAction
                icon="tree"
                label={i18n.t('LAYOUT_TREE')}
                active={displaySettings.layoutType === 'treelayout'}
                onClick={() => toggleLayout('treelayout')}
                inline
            />}
            <hr />
        </div>
    );

    settingsMenuContents.push(
        <div>
            <div className="small-options-list">
                <PopoverAction
                    label={PAGE_SIZES.OPT_1.toString()}
                    active={displaySettings.pageSize === PAGE_SIZES.OPT_1}
                    onClick={() => setPageSize(PAGE_SIZES.OPT_1)}
                    inline
                />
                <PopoverAction
                    label={PAGE_SIZES.OPT_2.toString()}
                    active={displaySettings.pageSize === PAGE_SIZES.OPT_2}
                    onClick={() => setPageSize(PAGE_SIZES.OPT_2)}
                    inline
                />
                <PopoverAction
                    label={PAGE_SIZES.OPT_3.toString()}
                    active={displaySettings.pageSize === PAGE_SIZES.OPT_3}
                    onClick={() => setPageSize(PAGE_SIZES.OPT_3)}
                    inline
                />
                <PopoverAction
                    label={PAGE_SIZES.OPT_4.toString()}
                    active={displaySettings.pageSize === PAGE_SIZES.OPT_4}
                    onClick={() => setPageSize(PAGE_SIZES.OPT_4)}
                    inline
                />
            </div>
            <hr />
        </div>
    );

    settingsMenuContents.push(
        <div>
            <PopoverAction
                icon='search'
                label={i18n.t('RESET_SEARCH')}
                active={displaySettings.useDefaultSearch}
                onClick={() => setUseDefaultSearch(!displaySettings.useDefaultSearch)}
            />
            <hr />
        </div>
    );

    settingsMenuContents.push(
        <div data-cy={'new_machine'}>
            {HasRight('machineregistryedit', userRights) && <PopoverAction
                icon="add-circle"
                label={i18n.t('ADD_MACHINE')}
                onClick={addMachine}
            />}
        </div>  
    );

    return (
        <div>
            {props.dialogOpen && <div className="overlay-canvas" onClick={() => props.closeDialog()} ></div>}
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={
                    <Popover id="settings-menu-popover" className="menu-popover">
                        {React.Children.map(settingsMenuContents, (child, i) => (
                            <div className="sub-section" key={`setting_${i}`}>
                                {settingsMenuHeadings !== null &&
                                    settingsMenuHeadings?.length > 0
                                    && settingsMenuHeadings[i] !== null &&
                                    <div className="setting-header">
                                        {settingsMenuHeadings[i]}
                                    </div>
                                }
                                <div className="setting-option">
                                    {child}
                                </div>
                            </div>
                        ))}
                    </Popover>
                }
            >
                <Button
                    id="footerMenuButton"
                    variant="primary"
                    className="action action-button novi-default-btn-color"
                    size="lg"
                >
                    <div onClick={() => props.toggleDialog()}>
                        <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
                    </div>
                </Button>
            </OverlayTrigger>
        </div>
    );
}

export default MachinesSettingsMenu;
