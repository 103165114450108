import { parsePayloadDateTime } from "utils";

const mapValueToMeasurementValueAddition = (q: IMeasurementQuestion, value) => {
    let measurementValue: IMeasurementValueAddition = {
        stringValue: null,
        intValue: null,
        datetimeValue: null,
        booleanValue: null,
        decimalValue: null,
        measurementQuestionId: null,
        workCardId: null,
        routeMaintenanceWorkCardPhaseId: null
    }
    
    if ((q.type === 'STRING' || q.type === 'CODE') && value) {
        if (q.useOptions) {
            measurementValue.stringValue = value.value;
        } else {
            measurementValue.stringValue = value;
        }
    } else if (q.type === 'INTEGER' && value) {
        if (q.useOptions) {
            measurementValue.intValue = value.value;
        } else {
            measurementValue.intValue = value;
        }
    } else if (q.type === 'DATETIME' && value) {
        measurementValue.datetimeValue = parsePayloadDateTime(value);
    } else if (q.type === 'BOOLEAN') {
        measurementValue.booleanValue = value;
    } else if (q.type === 'DECIMAL' && value) {
        if (q.useOptions) {
            measurementValue.decimalValue = value.value;
        } else {
            measurementValue.decimalValue = value;
        }
    }
    measurementValue.measurementQuestionId = q.id
    return measurementValue;
}
  
const questionTypes = {
    DECIMAL: 'decimalValue',
    INTEGER: 'intValue',
    BOOLEAN: 'booleanValue',
    STRING: 'stringValue',
    CODE: 'stringValue',
    DATETIME: 'datetimeValue'
}

const elementTypes = {
    DECIMAL: 'float-input',
    INTEGER: 'number-input',
    BOOLEAN: 'singleline-checkbox',
    STRING: 'input',
    CODE: 'input',
    LABEL: 'label',
    DATETIME: 'datetime',
};

const measurementUtils = {
    mapValueToMeasurementValueAddition,
    questionTypes,
    elementTypes
}

export default measurementUtils;