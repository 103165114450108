import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { DetermineBackgroundColor } from './HelperFunctions';

const CardComponent = ({ title, body = null, extraHeadingContainer = null, children = null, itemColors = [] }) => {
    const panelBody = children === null ? body : children

    const itemColor = DetermineBackgroundColor(itemColors);

    return (
        <Col className="cardlayout" xs={12} sm={6} lg={4}>
            <Card className="card-container">
                {extraHeadingContainer}
                <Card.Header className="card-header" style={{ borderTopColor: itemColor }}>
                    <Card.Title>
                        {title}
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {panelBody}
                </Card.Body>
            </Card>
        </Col>
    );
}

export default CardComponent;