import React from "react";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router-dom";
import { CallbackComponent, LOADING_USER } from "redux-oidc";
import authAPI from "../../api/authAPI";
import { HandleError } from "../../components/HelperFunctions";
import userManager from "../../config/userManager";
import { Profile, User } from "oidc-client";

interface IProps extends RouteChildrenProps {
    dispatch: any;
}

interface IProfile extends Profile {
    name?: string;
    preferredname?: string;
    appid?: string;
    email?: string;
    preferred_username?: string;
    unique_name?: string;
}

interface IAuthenticationResponse extends User {
    profile: IProfile;
}

class Callback extends React.Component<IProps> {
    constructor(props) {
        super(props);
    }

    render() {
        userManager.clearStaleState();

        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={(res: IAuthenticationResponse) => {
                    const { access_token, refresh_token, profile, id_token } = res;

                    // No token => 'undefined' saved to localStorage
                    authAPI.setAccessToken(access_token ?? 'undefined');
                    authAPI.setRefreshToken(refresh_token ?? 'undefined');

                    // Save SSO access_token in redux so our app updates and starts to initialize settings
                    this.props.dispatch({
                        type: `${LOADING_USER}_FULFILLED`,
                        payload: access_token
                    });
                    
                    this.props.history.replace(window.localStorage.getItem('redirect-path') ?? '/');
                }}
                errorCallback={error => {
                    HandleError(error, 'OIDC callback');

                    this.props.history.replace(window.localStorage.getItem('redirect-path') ?? '/');
                }}
            >
                <p>Redirecting...</p>
            </CallbackComponent>
        );
    }
}

export default connect()(Callback);
