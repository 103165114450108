import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Toaster } from '../../components/HelperFunctions';
import NavigationBar from '../navigation';
import { useSelector, useDispatch } from 'react-redux';
import HourCardMenu from './components/HourCardMenu';
import { setPageSizeAction, setPageNumberAction, setListLayoutAction } from './actions';
import settingsAPI from '../../config/settingsAPI';
import i18n from '../../translations/i18n';
import { Container } from 'react-bootstrap';
import useHourCards from '../../hooks/useHourCards';
import { Loader } from '../../components/Loader';
import PaginationContainer from '../../components/PaginationContainer';
import HourCardComponent from './components/HourCardComponent';
import { FETCH_HOURCARD_VIEW_SETTINGS } from '../../config/actionTypes';
import useViewSettings from '../../hooks/useViewSettings';
import TableComponent from '../../components/TableComponent';
import { countActiveHourCardFilters, generateTableHeadersAndFields, generateTableRows } from './utils';
import { useState } from 'react'

const getHourCardMenu = (layoutType, setListLayout, pageSize, setPageSize, userRights, goTo, toggleDialog, closeDialog, dialogOpen) => {
    return (
        <HourCardMenu
            layoutType={layoutType}
            setListLayout={setListLayout}
            pageSize={pageSize}
            setPageSize={setPageSize}
            userRights={userRights}
            goTo={goTo}
            closeDialog={closeDialog}
            dialogOpen={dialogOpen}
            toggleDialog={toggleDialog}
        />
    );
}

type LocationState = {
    notificationMsg: string
}

type Values = { [name: string]: any; };

const moduleSettings = settingsAPI.moduleData.hourcards;

const HourCardsList = () => {
    const dispatch = useDispatch();
    const currentFilters = useSelector((state: State) => state.hourcards.currentFilters);
    const pageNumber = useSelector((state: State) => state.hourcards.settings.pageNumber);
    const pageSize = useSelector((state: State) => state.hourcards.settings.pageSize || 5);
    const layoutType = useSelector((state: State) => state.hourcards.settings.layoutType || 'cardLayout');
    const userRights = useSelector((state: State) => state.settings.userRights);
    const userSettings = useSelector((state: State) => state.settings.userSettings);

    let location = useLocation<LocationState>();
    let history = useHistory();

    const [values, setValues] = useState<Values>({});

    // hooks
    const [hourCards] = useHourCards();
    const [viewSettings] = useViewSettings('hourcard', FETCH_HOURCARD_VIEW_SETTINGS);

    useEffect(() => {
        if (location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                Toaster({ msg: prevState.notificationMsg, type: 'success' });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }
        }
    }, []);

    const results = (pageNumber, pageSize, totalResultCount) => {
        const firstItemCount = (pageNumber - 1) * pageSize + 1;
        const lastItemCount = pageNumber * pageSize;
        const results = totalResultCount > 0
            ? `${firstItemCount} - ${lastItemCount <= totalResultCount ? lastItemCount : totalResultCount} / ${totalResultCount}`
            : '0';

        return (
            <span className="sub-header">
                {i18n.t('RESULT')}: {results}
            </span>
        );
    }

    const tableStructure = generateTableHeadersAndFields(userSettings);
    const tableRows = generateTableRows(hourCards, tableStructure.fields);

    const navigationProps = () => {
        return {
            currentView: {
                view: moduleSettings.name,
                title: i18n.t('HOUR_CARDS'),
                location,
                history,
                filtersCount: countActiveHourCardFilters(currentFilters),
                currentFilters: {
                    ...currentFilters,
                    quickSearch: 1
                },
                noHierarchySelect: true,
                //handleQuickSearch: 1
            },
            viewAction: 'settings',
            popover: getHourCardMenu(layoutType, setListLayout, pageSize, setPageSize, userRights, goTo, toggleDialog, closeDialog, values.dialogOpen),
            filters: true
        }
    }

    const setPageSize = (value) => {
        dispatch(setPageNumberAction(1));
        dispatch(setPageSizeAction(value));
    }

    const setPageNumber = (value) => {
        dispatch(setPageNumberAction(value));
    }

    const setListLayout = (value) => {
        dispatch(setListLayoutAction(value));
    }

    const goTo = (path, state = null) => {
        history.push(path, state);
    }

    const handleClick = (id) => {
        if (id) {
            history.push('/hourcard/' + id, true);
        }
    }

    const toggleDialog = () => {
        setValues(prevState => ({
            ...prevState,
            dialogOpen: prevState.dialogOpen ? !prevState.dialogOpen : true
        }))
    }

    const closeDialog = () => {
        setValues(prevState => ({ ...prevState, dialogOpen: false }))
    }

    return (
        <div>
            <NavigationBar {...navigationProps()} />
            {hourCards?.results.length > 0 && hourCards?.status === 'fulfilled' ?
                <div className="work-card-container">
                    <Container className="bottom-margin-100" fluid>
                        <div className="v-paddings-15">
                            {results(pageNumber, pageSize, hourCards.totalResultCount)}
                        </div>
                        {hourCards.results.length > 0 && <PaginationContainer
                            currentPage={pageNumber}
                            setCurrentPage={setPageNumber}
                            pageCount={hourCards.totalPageCount}
                        />}
                        {layoutType === 'cardLayout' ?
                                hourCards.results.map(hourCard => {
                                    return (
                                        <HourCardComponent
                                            key={hourCard.id}
                                            hourcard={hourCard}
                                            history={history}
                                            viewSettings={viewSettings}
                                        />
                                    )
                                })
                            : <TableComponent
                                headings={tableStructure?.headers}
                                rows={tableRows}
                                handleClick={handleClick}
                            />
                        }
                    </Container>
                    {hourCards?.results.length > 0 && <PaginationContainer
                        currentPage={pageNumber}
                        setCurrentPage={setPageNumber}
                        pageCount={hourCards.totalPageCount}
                    />}
                </div>
                : (hourCards.status === 'fulfilled' && hourCards?.results.length === 0) ? <div className="no-results"><p>{i18n.t('NO_RESULTS')}</p></div>
                    : <Loader status={hourCards?.status} />}
        </div>

    )
}

export default HourCardsList;

