import React from "react";
import i18n from "../translations/i18n";

export class InfoContainer extends React.Component<any, any> {
    render() {
        const { type, content } = this.props;

        return (
            <div>
                {type === 'notfound'
                    ? <div className="not-found-container">{i18n.t(content)}</div>
                    : <div></div>
                }
            </div>
        )
    }
}