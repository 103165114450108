import React from 'react';
import NavigationBar from '../../../navigation';
import '../../../../styles/global.scss';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from '../../../../translations/i18n';
import { fetchViewSettings } from '../../../../commonActions/actions';
import { getKeyByValue, GetTranslationKeyByProp, HandleError, InvalidFields } from '../../../../components/HelperFunctions';
import FormElement from '../../../work-schedule/work-card/components/FormElement';
import { Container } from 'react-bootstrap';
import {
    takeWarehouseSparePart,
    returnWarehouseSparePart,
    arriveWarehouseSparePart,
    arrivePurchaseOrderItem,
    changeWarehouseSparePart,
    transferWarehouseSparePart,
    fetchWarehousesWithParams,
    takeWildSparePart,
    returnWildSparePart
} from '../../actions';
import settingsAPI from '../../../../config/settingsAPI';
import Machines from '../../../machines';
import { RouteChildrenProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import noviAPI from '../../../../api/noviAPI';
import { Loader } from '../../../../components/Loader';
import { machineField, workCardField, reservationField, mapCostPoolsToCostPoolOptions } from '../../utils';

type MatchParams = {
    sparePartId: string;
    operation: "arrive" | "change" | "return" | "take" | "transfer";
    machineId?: string;
}
type LocationState = {
    warehouse?: { id: number; label: string; };
    costpool?: string;
    costpooltype?: string;
    machineId?: number;
    workCardId?: number;
    referrer?: string[];
    wild?: boolean;
    purchaseOrder?: any;
    wSparePartId: number;
}

type IProps = PropsFromRedux & RouteChildrenProps<MatchParams, LocationState>;

interface IState {
    sparePartName: string;
    warehouse: { id: number; label: string; };
    amount: string;
    costPoolGroups: { [name: string]: { value: number; label: string; } | '' };
    costPoolGroupSettings: ICostPoolGroup[];
    costpoolType: { id: number; label: string; };
    machine: { id: number; code: string; name: string; } | '';
    workCard: string;
    price: string;
    shelfLocation: string;
    amountInWarehouse: string;
    showMachines: boolean;
    costpoolTypeOptions: { id: number; label: string; }[];
    costPoolOptions: ICostPool[];
    allWarehouses: { id: number, label: string }[];
    fromWarehouse: { id: number; label: string; };
    toWarehouse: { id: number; label: string; };
    fields: typeof settingsAPI.warehouseOperationFields;
    invalidFields: string[];
    isWild: boolean;
    wildSparePart: any;
    takeAmount?: number;
    plannedAmount?: number;
    purchaseOrder?: string;
    orderedAmount?: number;
    arrivedAmount?: number;
    purchaseOrderItem?: any;
    loading: {
        costpoolTypeOptions: boolean;
        costpoolOptions: boolean;
        purchaseOrderOptions: boolean;
        amountInWarehouse: boolean;
        reservationOptions: boolean;
    };
    reservation: any;
    reservationOptions: any[];
}

const COSTPOOL_TYPE_MAP = {
    [-1]: 'NO_SELECTION', 
    1: 'COSTPOOL',
    2: 'MACHINE',
    3: 'WORKCARD',
    4: 'SPAREPARTRESERVATION'
}

type KeysOfOperationsWithPrefills = Exclude<MatchParams['operation'], "arrive" | "change" | "return">;
type KeysOfSparePartLink = keyof IWarehouseSparePartLink;
const operationsWithPrefilledFields: { [K in KeysOfOperationsWithPrefills]: KeysOfSparePartLink | KeysOfSparePartLink[]  } = { take: 'amount', transfer: 'amount' };

const moduleSettings = settingsAPI.moduleData.workcard;


class WarehouseOperations extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        const { location, match } = this.props;
        const isWild = !!(location?.state?.wild);

        const fields = settingsAPI[location.state?.purchaseOrder ? 'warehousePurchaseOrderOperationFields' : isWild ? 'warehouseWildOperationFields' : 'warehouseOperationFields']; 
        const selectedWarehouse = location.state?.warehouse ?? null;

        this.state = {
            sparePartName: '',
            warehouse: selectedWarehouse,
            amount: '',
            costPoolGroupSettings: [],
            costPoolGroups: {},
            machine: '',
            workCard: '',
            costpoolType: { id: -1, label: 'NO_SELECTION' },
            price: '',
            shelfLocation: '',
            amountInWarehouse: '',
            showMachines: false,
            costpoolTypeOptions: [],
            costPoolOptions: [],
            allWarehouses: [],
            fromWarehouse: selectedWarehouse,
            toWarehouse: selectedWarehouse,
            fields: fields.filter(field => this.hasField(match.params.operation, field)),
            invalidFields: [],
            isWild: isWild,
            wildSparePart: {},
            takeAmount: null,
            plannedAmount: null,
            purchaseOrder: '',
            orderedAmount: null,
            arrivedAmount: null,
            purchaseOrderItem: null,
            reservation: null,
            reservationOptions: [],
            loading: {
                costpoolTypeOptions: true,
                costpoolOptions: true,
                purchaseOrderOptions: true,
                amountInWarehouse: true,
                reservationOptions: true
            }
        }
    }

    async componentDidMount() {
        const { match, location, machineGroupId, reservationEnabled } = this.props;
        const { operation: operationType } = match.params;
        this.getSparePartName();
        const types = await this.getCostpoolTypes();

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });

        if (operationType === 'take' && reservationEnabled)
            this.getReservationOptions();

        if (location.state?.purchaseOrder) {

            noviAPI.purchaseOrderItems.fetch(location.state.purchaseOrder.id)
                .then(({ data }) => {
                    const wSparePart = data.workCardSpareParts.find(wsp => wsp.purchaseOrderItem?.id == data.id);

                    if (this.state.isWild) {

                        noviAPI.workCardSpareParts.fetch(wSparePart?.id)
                            .then((response) => {
                                const wildSparePart = response.data;

                                this.setState({ 
                                    sparePartName: wildSparePart.wildSparePart,
                                    price: `${wildSparePart.price ?? 0}`,
                                    wildSparePart: wildSparePart,
                                    amount: `${data.amount - (wildSparePart.purchaseOrderItem.arrivedAmount ?? 0)}`,

                                    purchaseOrder: data.purchaseOrder.orderNumber,
                                    orderedAmount: data.amount,
                                    arrivedAmount: wildSparePart.purchaseOrderItem.arrivedAmount ?? 0,
                                    shelfLocation: "",

                                    purchaseOrderItem: data
                                });
                            })
                            .catch(err => {
                                console.log(err);
                            });

                    }
                    else {

                        this.setState({
                            purchaseOrder: data.purchaseOrder.orderNumber,
                            sparePartName: `${data.sparePart?.code ?? "-"} / ${data.sparePart?.name ?? "-"}`,
                            orderedAmount: data.amount,
                            arrivedAmount: data.arrivedAmount,
                            amount: `${data.amount - (data.arrivedAmount ?? 0)}`,
                            price: `${wSparePart.price ?? 0}`,
                            shelfLocation: data.sparePart?.shelfLocation ?? "",
                            purchaseOrderItem: data
                        });
                    }

                    noviAPI.purchaseOrders.fetch(machineGroupId, data.purchaseOrder.id)
                        .then(({ data }) => {

                            if (data.warehouses?.length > 0) {

                                const warehouse = {
                                    id: data.warehouses[0]?.id,
                                    label: data.warehouses[0]?.name
                                };

                                this.setState({
                                    toWarehouse: warehouse
                                });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                })
                .catch(err => {
                    HandleError(err, "Fetching purchaseOrderItems");
                    console.log(err);
                });
        }
        
        if (this.state.warehouse == null) {
            this.setState(prevState => ({
                loading: { ...prevState.loading, purchaseOrderOptions: false, amountInWarehouse: false }
            }));
        }
        if ('transfer' === operationType) {
            this.getAllWarehouses();
        }
        if ('arrive' === operationType) {
            this.getAllWarehouses();
        }
        if ('take' === operationType || 'return' === operationType) {
            try {
                const costPoolGroupSettings = (await noviAPI.costPoolGroups.fetchAll()).data.results;
                this.setState({ costPoolGroupSettings });
            } catch (e) { console.log(e); }
        }

        this.props.fetchWarehousesWithParams({ sparePartId: match.params.sparePartId });

        if (operationType !== 'arrive' && operationType !== 'change' && operationType !== 'transfer')
            this.getDefaultCostpoolType();

        if (this.state.isWild) {
            this.fetchWildSparePart();
        }
        else {

            if (this.state.warehouse == null) {
                this.setState(prevState => ({
                    loading: { ...prevState.loading, purchaseOrderOptions: false, amountInWarehouse: false }
                }));
            }
            if ('transfer' === operationType) {
                this.getAllWarehouses();
            }
            if ('take' === operationType || 'return' === operationType) {
                try {
                    const costPoolGroupSettings = (await noviAPI.costPoolGroups.fetchAll()).data.results;
                    this.setState({ costPoolGroupSettings });
                } catch (e) { console.log(e); }
            }

            this.props.fetchWarehousesWithParams({ sparePartId: match.params.sparePartId });
            if (operationType !== 'change' && operationType !== 'transfer') {
                this.getDefaultCostpoolType();
            }
            if (Object.keys(operationsWithPrefilledFields).includes(operationType)) {
                if (location.state?.warehouse) {
                    this.fillInSpecifiedFieldsAutomatically(
                        location.state.warehouse.id,
                        operationsWithPrefilledFields[operationType]
                    );
                }
            }

        }
    }

    componentDidUpdate(_prevProps: IProps, prevState: IState) {
        const { warehouse, fromWarehouse } = this.state;
        const { operation: operationType } = this.props.match.params;
        const { reservationEnabled } = this.props;

        if (prevState.warehouse?.id !== warehouse?.id && operationType === 'take' && reservationEnabled) {
            this.getReservationOptions();
        }

        if (Object.keys(operationsWithPrefilledFields).includes(operationType)) {
            if (prevState.warehouse !== warehouse) {
                this.fillInSpecifiedFieldsAutomatically(
                    warehouse.id,
                    operationsWithPrefilledFields[operationType]
                );
            }
            if (prevState.fromWarehouse !== fromWarehouse) {
                this.fillInSpecifiedFieldsAutomatically(
                    fromWarehouse.id,
                    operationsWithPrefilledFields[operationType]
                );
            }
        }

        if (!this.state.isWild && (prevState.toWarehouse?.id ?? -1) !== (this.state.toWarehouse?.id ?? -1)) {
            const spId = this.state.purchaseOrderItem?.sparePart?.id ?? this.props.match.params.sparePartId;

            noviAPI.warehouseSparePartLinks.fetchByWarehouseId(this.state.toWarehouse.id, parseInt(spId))
                .then(({ data }) => {
                    this.setState({ shelfLocation: data.shelfLocation });
                })
                .catch(err => {
                    // No link found
                    this.setState({ shelfLocation: '' });
                });
        }

        // Set new cost pool field(s) and empty the value(s) of the previous cost pool field(s) when the cost pool type is changed
        if (prevState.costpoolType !== this.state.costpoolType) {
            const oldCostPoolType = COSTPOOL_TYPE_MAP[prevState.costpoolType?.id ?? -1];
            const newCostPoolType = COSTPOOL_TYPE_MAP[this.state.costpoolType?.id ?? -1];
            if ('COSTPOOL' === oldCostPoolType) {
                this.setState(state => ({
                    costPoolGroups: Object.keys(state.costPoolGroups).reduce((_obj, key) => {
                        return { [key]: '' }
                    }, {}),
                    invalidFields: state.invalidFields.filter(i => !i.startsWith('costpoolgroup'))
                }));
            }
            if ('MACHINE' === oldCostPoolType) {
                this.setState(state => ({
                    machine: '',
                    invalidFields: state.invalidFields.filter(i => i !== 'machine')
                }));
            }
            if ('WORKCARD' === oldCostPoolType) {
                this.setState(state => ({
                    workCard: '',
                    invalidFields: state.invalidFields.filter(i => i !== 'workCard')
                }));
            }
            if ('SPAREPARTRESERVATION' === oldCostPoolType) {
                this.setState(state => ({
                    reservation: '',
                    invalidFields: state.invalidFields.filter(i => i !== 'reservation')
                }));
            }
            const initCostPoolField = 'NO_SELECTION' === oldCostPoolType ? true : false;

            this.setCostPoolField(newCostPoolType, initCostPoolField);
            this.autoSetCostPool(newCostPoolType);
            this.setState(state => ({ invalidFields: state.invalidFields.filter(i => i !== 'costpool') }));
        }
    }

    fetchWildSparePart() {
        noviAPI.workCardSpareParts.fetch(parseInt(this.props.match.params.sparePartId))
            .then(({ data }) => {
                this.setState({ 
                    sparePartName: data.wildSparePart,
                    plannedAmount: data.amount,
                    price: `${data.price ?? 0}`,
                    takeAmount: data.takeAmount ?? 0,
                    wildSparePart: data,
                    amount: ""
                });
            })
            .catch(err => {
                console.log('[ ERROR ]', err);
            });
    }

    fillInSpecifiedFieldsAutomatically = async <K extends keyof IWarehouseSparePartLink>(warehouseId: number, field: K | K[]) => {
        if (this.isOrIncludesSpecificField(field, 'amount') && this.state.warehouse !== null) {
            this.setLoading('amountInWarehouse', true);
            this.setState({ amountInWarehouse: '' });
        }
        const value = await this.getSparePartLinkPropertyByPropertyName(warehouseId, field);
        if (Array.isArray(field)) {
            field.forEach((f, index) => {
                const propName = f.toString() === 'amount' ? 'amountInWarehouse' : f;
                this.setState(prevState => ({ ...prevState, [propName]: value[index] !== null ? value[index] : '' }));
            })
        } else {
            const propName = field.toString() === 'amount' ? 'amountInWarehouse' : field;
            this.setState(prevState => ({ ...prevState, [propName]: value !== null ? value : '' }));
        }
        if (this.isOrIncludesSpecificField(field, 'amount')) {
            this.setLoading('amountInWarehouse', false);
        }
    }

    getSparePartLinkPropertyByPropertyName = async <K extends keyof IWarehouseSparePartLink>(warehouseId: number, propName: K | K[]): Promise<IWarehouseSparePartLink[K] | IWarehouseSparePartLink[K][]> => {
        return noviAPI.warehouseSparePartLinks.fetchByWarehouseId(warehouseId, parseInt(this.props.match.params.sparePartId, 10))
            .then(response => {
                if (Array.isArray(propName)) {
                    return propName.map(n => response.data[n]);
                } else {
                    return response.data[propName];
                }
            })
            .catch(error => {
                if (Array.isArray(propName)) {
                    return propName.map(n => null);
                } else {
                    return null;
                }
            });
    }

    isOrIncludesSpecificField = <K extends keyof IWarehouseSparePartLink>(field: K | K[], str: string): boolean => {
        return (Array.isArray(field) && field.some(i => i === str)) || field === str;
    }

    setLoading = (prop: string, value: boolean) => {
        this.setState(prevState => ({
            loading: {
                ...prevState.loading,
                [prop]: value
            }
        }));
    }

    getAllWarehouses = () => {
        noviAPI.warehouses.fetchAllByMachineGroup(this.props.machineGroupId)
            .then(response => {
                this.setState({
                    allWarehouses: response.data
                        .map(warehouse => ({ id: warehouse.id, label: warehouse.name }))
                });
            })
            .catch(error => {
                console.log(error)
            });
    }

    getDefaultCostpoolType = () => {

        const { location } = this.props;

        if (location?.state?.costpooltype) {
            const cptLabel = i18n.t(location.state.costpooltype);
            const newType = this.state.costpoolTypeOptions.find(cpT => cpT.label === cptLabel);
            if (newType) {
                this.setState({ costpoolType: newType });
                return;
            }
        }

        noviAPI.applicationSettings.fetchByName("DefaultWithdrawCostpoolType")
            .then(response => {
                const cptLabel = i18n.t(response.data.value);
                const newType = this.state.costpoolTypeOptions.find(cpT => cpT.label === cptLabel);
                if (newType) {
                    this.setState({ costpoolType: newType });
                }
            })
            .catch(error => { console.log(error); })
    }

    getSparePartName = () => {

        if (!this.state.isWild) {
            noviAPI.spareParts.fetch(parseInt(this.props.match.params.sparePartId, 10))
                .then(response => { this.setState({ sparePartName: response.data.name }) })
                .catch(error => { console.log(error); });
        }
    }

    autoSetCostPoolType = (types: string[] = null) => {
        const { location } = this.props;
        const costPoolTypes = types || this.state.costpoolTypeOptions.map(i => i.label);

        let costPoolType = COSTPOOL_TYPE_MAP[-1];

        if (costPoolTypes.length === 1) {
            costPoolType = costPoolTypes[0];
        } else if (location.state && location.state.costpooltype) {
            if (costPoolTypes.includes(location.state.costpooltype)) {
                costPoolType = location.state.costpooltype;
            } else {
                costPoolType = COSTPOOL_TYPE_MAP[-1];
            }
        }

        this.setState({
            costpoolType: {
                id: Number(getKeyByValue(COSTPOOL_TYPE_MAP, costPoolType)),
                label: i18n.t(costPoolType)
            }
        }, () => {
            this.setCostPoolField(costPoolType, true);
            this.autoSetCostPool(costPoolType);
        });

    }

    autoSetCostPool = (costPoolType: string) => {
        if (costPoolType === 'COSTPOOL' && this.state.costPoolOptions.length < 1) {
            this.getCostPoolOptions();
        }
        const { location } = this.props;
        // set cost pool field empty or set a new value by cost pool type and location.state
        if (costPoolType === 'MACHINE' && location.state?.costpooltype === 'MACHINE') {
            noviAPI.machines.fetch(location.state.machineId)
                .then(response => {
                    this.setState({
                        machine: {
                            id: response.data.id,
                            code: response.data.code,
                            name: response.data.name
                        }
                    });
                });
        }
        if (costPoolType === 'WORKCARD' && location.state?.costpooltype === 'WORKCARD') {
            this.setState({ workCard: location.state.costpool });
        }
    }

    setCostPoolField = (costPoolType: string, init: boolean = false) => {
        let fieldsList = this.state.fields.slice(0);
        let indexOfCostPoolField = fieldsList.findIndex(i => i.name.toLowerCase() === 'costpooltype') + 1;
        let deleteCount = 1;
        if (init) {
            deleteCount = 0;
        }
        else if (fieldsList.some(i => i.name.toLowerCase().startsWith('costpoolgroup'))) {
            deleteCount = this.state.costPoolGroupSettings.length;
        }

        if ('WORKCARD' === costPoolType) {
            fieldsList.splice(indexOfCostPoolField, deleteCount, workCardField);
        }
        if ('MACHINE' === costPoolType) {
            fieldsList.splice(indexOfCostPoolField, deleteCount, machineField);
        }
        if ('COSTPOOL' === costPoolType) {
            this.state.costPoolGroupSettings.slice(0)
                .sort((a, b) => a.rowNumber - b.rowNumber)
                .forEach((costPoolGroup, i) => {
                    fieldsList.splice(indexOfCostPoolField + i, i === 0 ? deleteCount : 0, {
                        name: `costpoolgroup_${costPoolGroup.id}`,
                        label: costPoolGroup.translationKey,
                        type: 'select',
                        tabOrder: 113,
                        required: true,
                        operationTypes: ['take', 'return']
                    });
            })
        }
        if ("SPAREPARTRESERVATION" === costPoolType) {
            fieldsList.splice(indexOfCostPoolField, deleteCount, reservationField);
        }
        this.setState({ fields: fieldsList });
    }

    getCostPoolOptions = () => {
        noviAPI.costPools.fetchAll(this.props.machineGroupId)
            .then(response => {
                const results = response.data.results;
                const costPoolOptions = mapCostPoolsToCostPoolOptions(results)
                    .sort((a, b) => a.tabOrder - b.tabOrder);

                this.setState(prevState => ({
                    costPoolOptions,
                    loading: { ...prevState.loading, costpoolOptions: false }
                }))
            })
            .catch(error => {
                HandleError(error, 'Fetch costpools');
            })
    }

    getReservationOptions = () => {
        const wh = this.state.warehouse?.id;
        const sp = parseInt(this.props.match.params.sparePartId, 10);

        this.setState(prevState => {
            return { 
                reservation: null,
                loading: { ...prevState.loading, reservationOptions: true }
            };
        });

        if (wh && wh != -1 && sp) {
            noviAPI.SparePartReservationItems.fetchByWarehouseAndSparepart(wh, sp)
                .then(({ data }) => {

                    const options = data.map(reservationItem => {
                        return {
                            id: reservationItem.id,
                            label: reservationItem.sparePartReservation.code
                        };
                    })
                    this.setState(prevState => {
                        return { 
                            reservationOptions: options,
                            reservation: options.length == 1 ? options[0] : null,
                            loading: { ...prevState.loading, reservationOptions: false }
                        };
                    });
                })
                .catch(err => {
                    HandleError(err, "Fetch reservation item");
                });
        }
        else {
            this.setState(prevState => {
                return { 
                    loading: { ...prevState.loading, reservationOptions: false }
                };
            });
        }
    }

    getCostpoolTypes = () => {
        return noviAPI.costPoolTypes.fetchAll()
            .then(response => {
                const types = Object.values(COSTPOOL_TYPE_MAP);
                this.setState(prevState => ({
                    costpoolTypeOptions: response.data
                        .map(type => ({ id: types.indexOf(type) + 1, label: i18n.t(type) })),
                    loading: { ...prevState.loading, costpoolTypeOptions: false }
                }));
                return response.data;
            }).catch(error => {
                HandleError(error, 'fetch costpool types')
                return [];
            })
    }

    getWorkCardId = async (code) => {
        try {
            const { data } = await noviAPI.workCards.fetchByCode(code);
            return data.id;
        } catch (error) { }
    }

    checkOperationSpecificFields = (invalidFields: string[]) => {
        const { operation: operationType } = this.props.match.params;
        if ('take' === operationType || 'transfer' === operationType) {
            const { amountInWarehouse, amount } = this.state;
            if (Number(amountInWarehouse) < 1) invalidFields.push('amountInWarehouse');
            else if (Number(amountInWarehouse) < Number(amount)) invalidFields.push('amount');
        }
    }

    createSparePartTakeAndReturnData = async <T,>(invalidFields: string[]): Promise<T> => {
        const { costpoolType, amount, workCard, machine, costPoolGroups, reservation } = this.state;
        const sparePartDataByCostpoolType = {
            NO_SELECTION: () => null,  // if costpool type is -1
            COSTPOOL: () => {
                let costPools = [];
                Object.keys(costPoolGroups).forEach(key => {
                    const costPoolGroup = costPoolGroups[key];
                    if (typeof costPoolGroup !== 'string') {
                        costPools.push(costPoolGroup.label);
                    }
                });
                return { amount, costpool: costPools.join(', ') }
            },
            MACHINE: () => ({ amount, machineId: typeof machine !== 'string' ? machine.id : null }),
            WORKCARD: () => this.getWorkCardId(workCard)
                .then(id => {
                    if (!id) invalidFields.push('workCard');
                    return { amount, workCardId: id ? id : null }
                }),
            SPAREPARTRESERVATION: () => {
                if (!reservation?.id)
                    invalidFields.push('reservation');
                return { amount, sparePartReservationItemId: reservation?.id }
            }
        }
        return await sparePartDataByCostpoolType[COSTPOOL_TYPE_MAP[costpoolType.id]]();
    }

    createSparePartChangeData = (): ISparePartChange => {
        const { price } = this.state;
        return { price: parseFloat(price) }
    }

    createSparePartTransferData = (): ISparePartTransfer => {
        const { amount, shelfLocation, toWarehouse } = this.state;
        return {
            newWarehouseId: toWarehouse ? toWarehouse.id : null,
            amount: parseInt(amount, 10),
            shelfLocation: shelfLocation
        }
    }

    toastError = (msg: string) => {
        toast.error(i18n.t(msg), {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    }

    invalidFieldsToaster = () => {
        toast.error(i18n.t('INVALID_FIELDS'), {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    }

    warehouseIdByOperationType = (operationType) => {
        // returns null if no warehouse is selected
        switch (operationType) {
            case 'transfer':
                return this.state.fromWarehouse?.id || null;

            default:
                return this.state.warehouse?.id || null
        }
    }

    validateAmount = (amount) => {
        return new Promise((resolve, reject) => {

            const { operation: operationType } = this.props.match?.params;

            if (amount.match(/\.|\,/)) {
                reject("INPUT_NEEDS_TO_BE_A_NUMBER");
            }

            const value = parseInt(amount);

            const orderedAmount = this.state.isWild ? this.state.plannedAmount : this.state.orderedAmount;
            const arrivedAmount = this.state.isWild ? this.state.takeAmount : this.state.arrivedAmount;

            if (Number.isInteger(value) && value !== 0) {

                if (value < 0) {
                    reject("AMOUNT_CANT_BE_LESS_THAN_ZERO");
                }
                else if (operationType === "return" && value > arrivedAmount) {
                    reject("RETURN_AMOUNT_CANNOT_BE_MORE_THAN_TAKEN_AMOUNT");
                }
                else if (operationType !== "return" && value > orderedAmount - arrivedAmount) {

                    if (operationType === "arrive" && this.state.purchaseOrderItem) {
                        this.props.allowOverArrival 
                            ? resolve("Valid amount.") 
                            : reject("ALERT_ARRIVAL_AMOUNT_MORE_THAN_ORDER_AMOUNT");
                    } 
                    else
                        reject("ALERT_ARRIVAL_AMOUNT_MORE_THAN_ORDER_AMOUNT");
                }
                else {
                    resolve("Valid amount.");
                }
            }
            else {
                reject("INVALID_FIELDS");
            }
            
        });
    }

    handleSubmit = async () => {
        const { match, location, history } = this.props;
        const { operation: operationType } = match.params;
        const { sparePartId } = this.props.match.params;
        const warehouseId = this.warehouseIdByOperationType(operationType);

        const formData = this.state.fields;
        if(!this.state.purchaseOrderItem && this.state.isWild) {
            this.validateAmount(this.state.amount)
                .then(() => {
                    const amount = parseInt(this.state.amount);
                    const wSP = this.state.wildSparePart;

                    const newLocation = {
                        pathname: `/workcard/${location.state.workCardId}`,
                        state: { notificationMsg: 'MATERIAL_SAVED' }
                    }
                    
                    if (operationType === "take") {
                        this.props.takeWildSparePart(wSP.id, amount, () => history.replace(newLocation));
                    }

                    else if (operationType === "return") {
                        
                        // Locally detect logic errors before sending requests to backend
                        const newTakeAmount = (parseInt(wSP.takeAmount ?? 0)) - amount;

                        if (newTakeAmount >= 0) {
                            this.props.returnWildSparePart(wSP.id, amount, () => history.replace(newLocation));
                        }
                        else {
                            this.toastError("RETURN_AMOUNT_CANNOT_BE_MORE_THAN_TAKEN_AMOUNT");
                        }
                    }
                

                })
                .catch(error => {
                    this.toastError(error);
                });
            
        } else {
            // InvalidFields checks if field is empty or value of select is -1
            let invalidFields: string[] = InvalidFields(this.state, formData.filter(i => !i.name.startsWith('costpoolgroup')), 'name');
            invalidFields = invalidFields.concat(InvalidFields(this.state.costPoolGroups, formData.filter(i => i.name.startsWith('costpoolgroup')), 'name'));

            let pathname = location.state?.costpooltype === 'WORKCARD'
                ? `/workcard/${location.state.workCardId}`
                : location.state?.costpooltype === 'MACHINE'
                    ? `/machine/${location.state.machineId}/spareparts`
                    : location.state?.warehouse
                        ? '/warehouse'
                        : null;
            if ('arrive' === operationType) {

                this.checkOperationSpecificFields(invalidFields);
                if (invalidFields.length > 0) {
                    this.setState({ invalidFields });
                    this.invalidFieldsToaster();
                    return;
                }

                const locationData = {
                    pathname: pathname || `/workcard/${history.location.state['workCardId']}`,
                    state: { notificationMsg: 'MATERIAL_SAVED' }
                }

                const data = this.state;
                const sparePartId = parseInt(this.props.match.params.sparePartId);
                const material = this.props.spareParts.find(sP => sP.sparePart?.id == sparePartId && sP.purchaseOrderItem?.id == this.state.purchaseOrderItem.id);
                const { amount, toWarehouse, shelfLocation } = data;

                this.validateAmount(amount)
                    .then(() => {
                        if (this.state.isWild) {

                            const wSP = this.state.wildSparePart;
                            const matPurchaseOrderItemId = wSP['purchaseOrderItem']?.id ?? -1;
                            const purchaseOrderItem = this.props.purchaseOrderItems.find(item => item.id == matPurchaseOrderItemId);

                            if (purchaseOrderItem) {

                                const data = {
                                    Amount: parseInt(amount),
                                    ShelfLocation: shelfLocation,
                                    Id: wSP.purchaseOrderItem.id,
                                    Price: parseFloat(this.state.price) || 0,
                                    CostPool: this.state.workCard,
                                    CostPoolType: "WORKCARD",
                                    WSparePartId: location.state.wSparePartId
                                };

                                this.props.arrivePurchaseOrderItem(toWarehouse.id, sparePartId, data, () => history.replace(locationData));
                            }

                        }
                        else {
                            const matPurchaseOrderItemId = material['purchaseOrderItem']?.id ?? -1;
                            const purchaseOrderItem = this.props.purchaseOrderItems.find(item => item.id == matPurchaseOrderItemId);

                            if (purchaseOrderItem) {

                                const data = {
                                    Amount: parseInt(amount),
                                    ShelfLocation: shelfLocation,
                                    Id: purchaseOrderItem.id,
                                    Price: parseFloat(this.state.price) || 0,
                                    CostPool: this.state.workCard,
                                    CostPoolType: "WORKCARD",
                                    WSparePartId: location.state.wSparePartId
                                };

                                this.props.arrivePurchaseOrderItem(toWarehouse.id, sparePartId, data, () => history.replace(locationData));
                            }
                        }

                    })
                    .catch(error => {
                        this.toastError(error);
                    });

            }
            const newLocation = {
                pathname: pathname || `/sparepart/${sparePartId}`,
                state: { notificationMsg: 'MATERIAL_SAVED' }
            }

            if ('take' === operationType || 'return' === operationType) {
                const sparePart = await this.createSparePartTakeAndReturnData<ISparePartTake | ISparePartReturn>(invalidFields);

                this.checkOperationSpecificFields(invalidFields);
                if (invalidFields.length > 0) {
                    this.setState({ invalidFields });
                    this.invalidFieldsToaster();
                    return;
                }
                if ('take' === operationType) {
                    sparePart.wSparePartId = location?.state?.wSparePartId;
                    this.props.takeWarehouseSparePart(warehouseId, parseInt(sparePartId, 10), sparePart, () => history.replace(newLocation));
                }
                if ('return' === operationType) {
                    sparePart.wSparePartId = location?.state?.wSparePartId;
                    this.props.returnWarehouseSparePart(warehouseId, parseInt(sparePartId, 10), sparePart, () => history.replace(newLocation));
                }
            } else {
                this.checkOperationSpecificFields(invalidFields);
                if (invalidFields.length > 0) {
                    this.setState({ invalidFields });
                    this.invalidFieldsToaster();
                    return;
                }
                if ('transfer' === operationType) {
                    const sparePart = this.createSparePartTransferData();
                    this.props.transferWarehouseSparePart(warehouseId, parseInt(sparePartId, 10), sparePart, () => history.replace(newLocation));
                }
                if ('change' === operationType) {
                    const sparePart = this.createSparePartChangeData();
                    this.props.changeWarehouseSparePart(warehouseId, parseInt(sparePartId, 10), sparePart, () => history.replace(newLocation));
                }
            }


        }

    }

    onInputChange = (e) => {
        const { value, name } = e.target;
        this.setState(prevState => ({ ...prevState, [name]: value }));
    }

    onNumberInputChange = (e) => {
        const { value, name } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [name]: parseFloat(value),
            invalidFields: prevState.invalidFields.filter(field => field !== name)
        }));
    }

    handleSelect = (value, actionMeta) => {
        this.setState(prevState => ({
            ...prevState,
            [actionMeta.name]: value,
            invalidFields: prevState.invalidFields.filter(field => field !== actionMeta.name)
        }))
    }

    handleCostPoolSelect = (value, actionMeta) => {
        this.setState(prevState => ({
            costPoolGroups: {
                ...prevState.costPoolGroups,
                [actionMeta.name]: value
            },
            invalidFields: prevState.invalidFields.filter(field => field !== actionMeta.name)
        }))
    }

    handleMachineSelect = (selection, isLevel) => {
        if (isLevel) return;

        this.setState(prevState => ({
            machine: {
                id: selection.machineId,
                code: selection.machineCode,
                name: selection.name
            },
            invalidFields: prevState.invalidFields.filter(field => field !== 'machine')
        }))

        this.props.history.replace(this.referrer()[0])

        this.toggleMachines();
    }

    toggleMachines = () => {
        this.setState(prevState => ({ showMachines: !prevState.showMachines }));
    }

    hasField = (operationType: MatchParams["operation"], field: typeof this.state.fields[number]): boolean => {
        return !!(field.operationTypes).find(opType => opType === operationType);
    }

    getOptions = (fieldName?: string) => {
        const options = {
            warehouse: this.props.warehouses,
            toWarehouse: this.state.allWarehouses,
            fromWarehouse: this.props.warehouses,
            costpoolType: this.state.costpoolTypeOptions,
            reservation: this.state.reservationOptions
        }

        return fieldName ? options[fieldName]: options;
    }

    getBasePropsForFormElement = (field: typeof this.state.fields[number], value, onChange) => {
        return {
            name: field.name,
            type: field.type,
            label: field.label,
            value: value,
            onChange: onChange,
            required: field.required
        }
    };

    createCostPoolGroupField = (field: typeof this.state.fields[number]) => {
        try {
            const groupId = Number(field.name.split('_')[1]);
            const value = this.state.costPoolGroups[field.name];

            return <FormElement
                {...this.getBasePropsForFormElement(field, value, this.handleCostPoolSelect)}
                options={this.state.costPoolOptions.filter(i => i.costPoolGroupId === groupId)}
                loading={this.state.loading.costpoolOptions}
            />
        } catch {
            return <></>;
        }
    }

    createInputField = (field: typeof this.state.fields[number], value) => {
        return <FormElement
            {...this.getBasePropsForFormElement(field, value, this.onInputChange)}
            {...Object.keys(this.state.loading).includes(field.name) && { loading: this.state.loading[field.name] }}
        />
    }

    createSelectField = (field: typeof this.state.fields[number], value) => {
        return <FormElement
            {...this.getBasePropsForFormElement(field, value, this.handleSelect)}
            options={this.getOptions(field.name)}
            {...Object.keys(this.state.loading).includes(`${field.name}Options`) && { loading: this.state.loading[`${field.name}Options`] }}
        />
    }

    createMachineField = (field: typeof this.state.fields[number]) => {
        return <FormElement
            {...this.getBasePropsForFormElement(field, this.state.machine, this.toggleMachines)}
        />
    }

    getFormField = (field: typeof this.state.fields[number]) => {
        if (field.name.startsWith('costpoolgroup')) {
            return this.createCostPoolGroupField(field)
        }
        if (field.name === 'machine') {
            return this.createMachineField(field);
        }
        const value = this.state[field.name];
        if (field.type === 'select' || field.type === 'simple-select' || field.type == 'select-choice') {
            return this.createSelectField(field, value);
        }
        return this.createInputField(field, value);
    }

    navigateBackMachineSelect = (params) => {
        const referrer = [...params.referrer];
        this.props.history.replace(referrer.pop(), { referrer });
    }

    referrer = () => {
        const { location } = this.props
        let newReferrer;
        if (typeof location.state !== 'undefined') {
            if (!location.state.referrer) {
                newReferrer = [].concat(location.pathname)
            } else {
                newReferrer = [...location.state.referrer].concat(location.pathname)
            }
        } else {
            newReferrer = [].concat(location.pathname)
        }
        return newReferrer;
    }

    backAction = () => {
        const { history, location } = this.props;

        const backAction =
            !this.state.showMachines
                ? { action: history.goBack }
                : this.referrer().length > 1
                    ? {
                        action: this.navigateBackMachineSelect,
                        params: {
                            referrer: location.state.referrer
                        }
                    }
                    : { action: this.toggleMachines }
        return backAction;
    }

    className = (fieldName: string) => {
        return this.state.invalidFields.includes(fieldName) ? 'invalid-field' : '';
    }

    render() {
        const { location, history, match, status } = this.props;
        const { operation: operationType } = match.params;

        const header = (!this.state.purchaseOrderItem && this.state.isWild) ? `WILD_SPARE_${operationType.toUpperCase()}_HEADER` : GetTranslationKeyByProp(operationType);

        const sceneData = {
            view: 'warehouse',
            title: this.state.sparePartName,
            subPhaseLabel: i18n.t(header).toLowerCase(),
            location: location,
            history: history,
            backAction: this.backAction()
        }

        const viewAction = {
            icon: 'save',
            label: '',
            clickFn: this.handleSubmit,
            isActionFn: true,
            paClass: 'start-phase',
        }

        return (
            <div>
                <NavigationBar
                    currentView={sceneData}
                    navHistory={this.props}
                    viewAction={viewAction}
                    popoverData={''}
                />

                <Loader status={status} />
                {this.state.showMachines &&
                    <Machines
                        machineSelect
                        onMachineSelect={this.handleMachineSelect}
                        referrer={this.referrer()}
                        itemStatus="warehouseoperation"
                    />
                }
                {status !== 'pending' && !this.state.showMachines &&
                <div className="work-card-view">
                    <Container>
                        <div className="form-table-container bottom-nav-space margin-top-15">
                            <form>
                                {this.state.fields.slice(0)
                                    .sort((a, b) => (a.tabOrder - b.tabOrder))
                                    .map((field, i) => (
                                        <div key={i} className={this.className(field.name)}>
                                            {this.getFormField(field)}
                                        </div>
                                    ))
                                }
                            </form>
                        </div>
                    </Container>
                    </div>}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchViewSettings,
    takeWarehouseSparePart,
    returnWarehouseSparePart,
    arriveWarehouseSparePart,
    arrivePurchaseOrderItem,
    changeWarehouseSparePart,
    transferWarehouseSparePart,
    fetchWarehousesWithParams,
    takeWildSparePart,
    returnWildSparePart
}, dispatch);

const mapStateToProps = (state: State) => {

    const { noviConfigs } = state.settings;

    const SparePartReservationEnabled = noviConfigs.SparePartReservationEnabled?.toLowerCase() === 'true';

    return {
        machineGroupId: state.settings.machineGroupId,
        status: state.warehouse.status,
        spareParts: state.workcards.materials,
        purchaseOrderItems: state.workcards.purchaseOrderItems,
        allowOverArrival: state.settings.noviConfigs.AllowOverArrival === "True",
        reservationEnabled: SparePartReservationEnabled,
        warehouses: state.warehouse.searchedWarehouses
            ? state.warehouse.searchedWarehouses.map(whouse => ({ id: whouse.id, label: whouse.name }))
            : []
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WarehouseOperations);
