import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noviAPI from '../api/noviAPI';
import { isNullOrWhitespace } from '../components/HelperFunctions';
import * as types from 'config/actionTypes';
import { thirdPartyTypeMap } from 'scenes/warehouse/utils';

const init = {
    results: [],
    totalPageCount: 0,
    totalResultCount: 0,
    status: 'fulfilled'
};

function useSparePartLinks() {
    const dispatch = useDispatch();
    const [sparePartLinks, setSparePartLinks] = useState(init);

    const pageNumber = useSelector((state: State) => state.warehouse.settings.pageNumber) - 1;
    const pageSize = useSelector((state: State) => state.warehouse.settings.pageSize);
    const useDefaultSearch = useSelector((state: State) => state.warehouse.settings.useDefaultSearch);
    const warehouses = useSelector((state: State) => state.warehouse.warehouses);
    const allWarehouseIds = useSelector((state: State) => state.warehouse.allWarehouseIds);
    const warehouse = useSelector((state: State) => state.warehouse.currentWarehouse);
    const currentFilters = useSelector((state: State) => state.warehouse.currentFilters);
    const activeWarehouseQuickSearch = useSelector((state: State) => state.navigation.activeQuickSearches.warehouse);
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const showFromAllMachineGroups = useSelector((state: State) => state.settings.noviConfigs.MobileShowWarehouseSpLinksFromAllMachineGroupWarehouses) == "True";

    useEffect(() => {
        if (showFromAllMachineGroups && allWarehouseIds.length == 0) {
            noviAPI.warehouses.fetchAll()
                .then(({ data }) => {
                    const ids = data.map(wh => wh.id);
                    dispatch({
                        type: `${types.FETCH_ALL_WAREHOUSES}`,
                        payload: ids
                    });
                });
        }
    }, []);

    useEffect(() => {

        if (showFromAllMachineGroups && allWarehouseIds.length == 0) {
            // If show warehouses from all machine groups setting is enabled 
            // we have load warehouses first before we can do a search query
            return;
        }

        if (activeWarehouseQuickSearch) {
            const isSql = activeWarehouseQuickSearch.isSqlSearch;
            if (isSql) {
                fetchSqlQuickSearchSpLinks();
            } else {
                // saved searches
            }
        }
        // Call fetchSparePartLinks only when warehouse is selected
        else if (Object.keys(warehouse).length > 0) {
            if (useDefaultSearch) {
                defaultSearch();
            } else {
                fetchSparePartLinks();
            }
        }

        function fetchSparePartLinks() {
            setSparePartLinks(s => ({ ...s, status: 'pending' }));
            let queryParams = new URLSearchParams();
            // Standard parameters
            queryParams.append('PageNumber', pageNumber > 0 ? `${pageNumber}` : '0');
            queryParams.append('PageSize', `${pageSize}` || '5');

            // Single warehouse selected
            if (warehouse.id !== -1 && warehouse.label !== "*") {
                queryParams.append('WarehouseIds', `${warehouse.id}`);
            }      
            // All warehouses "*" selected
            else {
                if (showFromAllMachineGroups) {
                    allWarehouseIds.forEach(id => {
                        queryParams.append('WarehouseIds', `${id}`);
                    });
                }
                else {
                    queryParams.append("MachineGroupId", `${machineGroupId}`);
                    warehouses.forEach(wh => {
                        queryParams.append('WarehouseIds', `${wh.id}`);
                    });
                }
            }

            const activeFilters = Object.keys(currentFilters);
            // Are there active filters
            if (activeFilters.length > 0) {
                // Append active filters
                activeFilters.forEach(filter => {
                    if (isNullOrWhitespace(currentFilters[filter])) {
                        return;
                    } else if ('details' === filter) {
                        Object.keys(currentFilters[filter]).forEach(detailGroup => {
                            currentFilters[filter][detailGroup].forEach(detail => {
                                queryParams.append('detailIds', detail.value);
                            });
                        });
                    } else if ('thirdParties' === filter) {
                        Object.keys(currentFilters[filter]).forEach(thirdPartyType => {
                            currentFilters[filter][thirdPartyType].forEach(thirdParty => {
                                const key = thirdPartyTypeMap[thirdPartyType?.toString()];
                                const value = thirdParty.id ?? thirdParty.value;
                                queryParams.append(key, value);
                            });
                        });
                    } else if (currentFilters[filter].hasOwnProperty('min') || currentFilters[filter].hasOwnProperty('max')) {
                        Object.keys(currentFilters[filter]).forEach(key => {
                            if (currentFilters[filter][key].length > 0) {
                                queryParams.append(`${filter}.${key.charAt(0).toUpperCase() + key.slice(1)}`, currentFilters[filter][key]);
                            }
                        })
                    } else {
                        queryParams.append(filter, currentFilters[filter]);
                    }
                });
            }
            // Fetch warehouse spare part links with specified search parameters
            noviAPI.warehouseSparePartLinks.search(queryParams)
                .then(response => {
                    setSparePartLinks({ ...response.data, status: 'fulfilled' });
                })
                .catch(error => {
                    setSparePartLinks(s => ({ ...s, status: 'error' }));
                });
        }

        function fetchSqlQuickSearchSpLinks(qSearch = null) {
            const quickSearch = qSearch || activeWarehouseQuickSearch;
            setSparePartLinks(s => ({ ...s, status: 'pending' }));
            let queryParams = new URLSearchParams();
            // Standard parameters
            queryParams.append('PageNumber', pageNumber > 0 ? `${pageNumber}` : '0');
            queryParams.append('PageSize', `${pageSize}` || '5');
            queryParams.append('warehouseId', `${warehouse?.id ?? -1}`);

            if (!showFromAllMachineGroups) {
                queryParams.append("MachineGroupId", `${machineGroupId}`);
            }

            noviAPI.quickSearches.fetchResults<IWarehouseSparePartLinkSearchResult>(machineGroupId, quickSearch.value, queryParams)
                .then(response => {
                    setSparePartLinks({ ...response.data, status: 'fulfilled' });
                })
                .catch(error => {
                    setSparePartLinks(s => ({ ...s, status: 'error' }));
                });
        }

        function defaultSearch() {
            setSparePartLinks(s => ({ ...s, status: 'pending' }));
            let queryParams = new URLSearchParams();
            // Standard parameters
            queryParams.append('PageNumber', pageNumber > 0 ? `${pageNumber}` : '0');
            queryParams.append('PageSize', `${pageSize}` || '5');

            // Single warehouse selected
            if (warehouse.id !== -1 && warehouse.label !== "*") {
                queryParams.append('warehouseId', `${warehouse.id}`);
            }      
            // All warehouses "*" selected
            else {
                if (showFromAllMachineGroups) {
                    allWarehouseIds.forEach(id => {
                        queryParams.append('WarehouseIds', `${id}`);
                    });
                }
                else {
                    queryParams.append("MachineGroupId", `${machineGroupId}`);
                    warehouses.forEach(wh => {
                        queryParams.append('WarehouseIds', `${wh.id}`);
                    });
                }
            }

            noviAPI.warehouseSparePartLinks.defaultSearch(queryParams)
                .then(response => {
                    setSparePartLinks({ ...response.data, status: 'fulfilled' });
                })
                .catch(error => {
                    setSparePartLinks(s => ({ ...s, status: 'error' }));
                });
        }
    }, [pageNumber, pageSize, warehouse, currentFilters, activeWarehouseQuickSearch, useDefaultSearch, machineGroupId, allWarehouseIds, showFromAllMachineGroups]);

    return [sparePartLinks];
}

export default useSparePartLinks;