import noviAPI from 'api/noviAPI';
import { HandleError, HasRight, Toaster } from 'components/HelperFunctions';
import { Loader } from 'components/Loader';
import { UserRights } from 'constants/userRights';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import NavigationBar from 'scenes/navigation';
import FormElement from 'scenes/work-schedule/work-card/components/FormElement';
import i18n from 'translations/i18n';

const EditCounter = (props) => {
    let history = useHistory();
    let location = useLocation();

    const [counter, setCounter] = useState(null);
    const [value, setValue] = useState(null);
    const [showLoader, setShowLoader] = useState(true);

    const { userRights } = useSelector((state: State) => state.settings);
    const editCounters = HasRight(UserRights.CountersEdit, userRights);

    const { id } = props.match.params;
    const machineId = counter?.machine?.id;

    useEffect(() => {

        setShowLoader(true);
        noviAPI.counters.fetch(id)
            .then(({ data }) => {
                setCounter(data);
            })
            .catch(err => {
                HandleError(err, "Fetch counter");
            })
            .finally(() => {
                setShowLoader(false);
            })

    }, [id]);

    useEffect(() => {
        setValue(counter?.value);
    }, [counter]);


    const handleSubmit = async () => {

        if (!value || parseInt(value) < 0) {
            Toaster({ msg: 'CHECK_FIELDS' });
            return;
        }

        setShowLoader(true);

        try {
            await noviAPI.counters.patch(id, { value });
            
            if (machineId) {
                history.push(`/machine/${machineId}`, {
                    notificationMsg: "MACHINE_SAVED"
                })
            } else {
                history.goBack();
            }
        } catch (e) {
            Toaster({ msg: 'ERROR', type: 'error' });
            setShowLoader(false);
        }
    }


    const sceneData = {
        view: 'counter',
        title: i18n.t('DO_EDIT'),
        subPhaseLabel: i18n.t('COUNTERS_HEADER'),
        location: location,
        history: history,
        itemColors: []
    }

    const viewAction = editCounters && {
        icon: 'save',
        label: '',
        clickFn: handleSubmit,
        isActionFn: true,
        paClass: 'start-phase'
    };

    return (
        <React.Fragment>
            <NavigationBar
                currentView={sceneData}
                viewAction={viewAction}
                popoverData={null}
            />
            <Loader ready={!showLoader} />
            {!showLoader && <Container className="bottom-margin-100">
                <div className="form-table-container margin-top-15">
                    <FormElement
                        label={i18n.t('NAME')}
                        name="name"
                        value={counter?.name}
                        type={"label"}
                        onChange={null}
                    />
                    <FormElement
                        label={i18n.t('COUNTER_ID')}
                        name="id"
                        value={counter?.id}
                        type="label"
                        onChange={null}
                    />
                    <FormElement
                        label={i18n.t('COUNTER_VALUE')}
                        name="value"
                        type="number"
                        value={value}
                        onChange={(e) => {
                            const { value } = e.target;
                            setValue(value);
                        }}
                    />
                </div>
            </Container>}
        </React.Fragment>
    )
}

export default EditCounter;