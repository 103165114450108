import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import FormElement from '../scenes/work-schedule/work-card/components/FormElement';
import i18n from '../translations/i18n';
import ConfirmDialogComponent from './ConfirmDialogComponent';
import { openScanner, clearScanResult } from './scanners/actions';
import ToggleButtonGroupContainer from './ToggleButtonGroupContainer';
import useViewSettings from '../hooks/useViewSettings';
import { FETCH_MATERIAL_VIEW_SETTINGS } from '../config/actionTypes';
import { toast } from 'react-toastify';
import noviAPI from 'api/noviAPI';

const tabs = {
    1: 'SPAREPART',
    2: 'WILD_SPAREPART'
};

type Props = {
    submit: boolean;
    onSubmit: (material, type) => void;
    workScheduleRights: 'addition' | 'editing';
    material?: IWorkCardSparePart;
    setHasChanged?: (hasChanged: boolean) => void;
}

const MaterialForm = (props: Props) => {
    let dispatch = useDispatch();
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const [viewSettings] = useViewSettings('workcardmaterialaddedit', FETCH_MATERIAL_VIEW_SETTINGS);
    const initActiveTab = typeof props.material !== 'undefined' && props.material.wildSparePart !== null ? 2 : 1;
    const [activeTab, setActiveTab] = useState(initActiveTab);
    
    const units = useSelector((state: State) => state.warehouse.sparePartDetails.byGroup.unit) ?? [];

    const unitOptions = units
        .filter(i => i.machineGroupId == machineGroupId)
        .map(option => ({ id: option.id, label: option.value }));

    const [dialog, setDialog] = useState({
        content: null,
        actions: { accept: null, cancel: null }
    });
    const [material, setMaterial] = useState({
        spareParts: [],
        wildSparePart: '',
        sparepartamount: '',
        spareparttype: '',
        sparepartmemo: '',
        sparepartprice: 0.0,
        sparepartcomment: '',
        sparepartunit: { id: -1, label: i18n.t('NO_SELECTION') }
    });
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    
    useEffect(() => {
        if (props.submit) {
            const type = tabs[activeTab].toLowerCase();
            if (!validateMaterial()) {
                toast.error(i18n.t('INVALID_FIELDS'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                props.onSubmit(null, null);
            } else {
                props.onSubmit(material, type);
            }
        }
    }, [props.submit, props.onSubmit, activeTab, material]);

    useEffect(() => {
        if (props.material) {
            const unitOption = props.material?.unit && unitOptions?.find(opt => opt.label && opt.label.toUpperCase() == props.material.unit?.toUpperCase());
            setMaterial({
                spareParts: props.material.sparePart ? [{
                    ...props.material.sparePart,
                    value: props.material.sparePart.id,
                    label: `${props.material.sparePart.code} // ${props.material.sparePart.name}`
                }] : [],
                wildSparePart: props.material.wildSparePart,
                sparepartamount: props.material.amount.toString(),
                spareparttype: props.material.type,
                sparepartmemo: props.material.memo,
                sparepartprice: props.material.price,
                sparepartcomment: props.material.comment,
                sparepartunit: unitOption ? { id: unitOption.id, label: unitOption.label } : { id: -1, label: i18n.t('NO_SELECTION') }
            })
        }
    }, [props.material]);

    const validateMaterial = () => {
        let isValid = true;
        let array = [];
        const type = tabs[activeTab].toLowerCase();
        
        viewSettings.forEach(({ field, required }) => {
            const value = material[field];

            if (required && (!value || (field === 'sparepartunit' && value?.id < 1))) {
                array.push(field);
                isValid = false;
            }
        });

        if (type === 'wild_sparepart' && !material.wildSparePart) {
            array.push('wildSparePart');
            isValid = false;
        } else if (type === 'sparepart' && material.spareParts.length === 0) {
            array.push('spareParts');
            isValid = false;
        }

        setInvalidFields(array);
        return isValid;
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setMaterial(m => ({ ...m, [name]: value }));
        setInvalidFields(i => i.filter(str => str !== name));
        props.setHasChanged(true);
    }

    const handleSelect = (value, { name }) => {
        setMaterial(m => ({ ...m, [name]: value }));
        setInvalidFields(i => i.filter(str => str !== name));
        props.setHasChanged(true);
    }

    const closeDialog = useCallback(() => {
        setDialog(d => ({ ...d, content: null }));
    }, []);

    const acceptScan = useCallback((scanResult: ISparePartLite, scannerType: string) => {
        const sparePart = sparePartToOption(scanResult);
        // Add the scan result to the list of spare parts if the list doesn't already include the scanned spare part
        setMaterial(m => {
            if (!m.spareParts.some(sp => sp.id === sparePart.id)) {
                return { ...m, spareParts: m.spareParts.concat([sparePart]) }
            }
        })
        dispatch(clearScanResult(scannerType));
        closeDialog();
    }, [dispatch, closeDialog]);

    const handleScannerDialog = useCallback((scanResult: ISparePartLite, scannerType: string) => {
        let content = { title: '', body: '' };
        let actions = { accept: null, cancel: null };

        // Check if scan was succesful and the scan result equals a spare part from spare part options list
        if (scanResult) {
            content.title = i18n.t('CONFIRM');
            content.body = `${scanResult.code} / ${scanResult.name}`;
            actions.accept = () => acceptScan(scanResult, scannerType);
        } else {
            content.title = i18n.t('SPARE_PART_NOT_FOUND');
            content.body = `${i18n.t('TRY_AGAIN')}?`;
            actions.accept = () => {
                dispatch(clearScanResult(scannerType));
                dispatch(openScanner(scannerType));
                closeDialog();
            }
        }
        actions.cancel = () => {
            dispatch(clearScanResult(scannerType));
            closeDialog();
        };

        setDialog({ content, actions });
    }, [dispatch, acceptScan, closeDialog]);

    const qrScanResult = useSelector((state: State) => state.scanners.QR.result);
    useEffect(() => {
        if (qrScanResult) {
            const result = qrScanResult.split('Id=')[1];
            const id = Number.parseInt(result);
            noviAPI.spareParts.fetchLite(id)
                .then(({data}) => handleScannerDialog(data, 'QR'))
                .catch(() => handleScannerDialog(null, 'QR'))
        }
    }, [qrScanResult, handleScannerDialog]);

    const barcodeScanResult = useSelector((state: State) => state.scanners.barcode.result);
    useEffect(() => {
        if (barcodeScanResult) {
            noviAPI.spareParts.fetchByCodeWithSpecialRights(barcodeScanResult, props.workScheduleRights)
                .then(({data}) => handleScannerDialog(data, 'barcode'))
                .catch(() => handleScannerDialog(null, 'barcode'))
        }
    }, [barcodeScanResult, handleScannerDialog]);

    const getSparePartsByInput = useCallback(async (inputValue: string) => {
        let spareParts: ISparePartLite[] = [];
        try {
            const sparePartSearchParams = new URLSearchParams([
                ['machineGroupId', `${machineGroupId}`],
                ['searchString', inputValue]
            ])
            const { data } = await noviAPI.spareParts.searchLite(sparePartSearchParams);
            spareParts = data.results;
        } catch (error) {
            console.log(error);
        }
        return spareParts;
    }, []);

    const [debounce, setDebounce] = useState(null);
    useEffect(() => {
        if (debounce !== null) {
            const { cb, delay } = debounce;
            const timeoutID = setTimeout(cb, delay);
            return () => clearTimeout(timeoutID); 
        }
    }, [debounce]);
    
    const loadOptions = (inputValue: string, callback: (options) => void) => {
        if (inputValue.length >= 3) {
            setDebounce({
                cb: () => getSparePartsByInput(inputValue)
                    .then((spareParts) => callback(spareParts.map(sparePartToOption))),
                delay: 500
            })
        } else {
            callback([])
        }
    }

    const sparePartToOption = useCallback((sparePart: ISparePartLite): IdLabel => (
        { id: sparePart.id, label: `${sparePart.code} // ${sparePart.name}` }
    ), [])

    return (
        <Container>
            <ToggleButtonGroupContainer
                label={i18n.t('MATERIAL_TYPE')}
                value={activeTab}
                onChange={setActiveTab}
                options={Object.keys(tabs).map(i => ({ id: +i, name: tabs[i] }))}
            />
            <div className="form-table-container bottom-nav-space margin-top-15">
                <form className="sparepart-form">
                    {tabs[activeTab].toLowerCase() === 'sparepart'
                        ? <div className={invalidFields.includes('spareParts') ? 'invalid-field' : ''}><FormElement
                            name='spareParts'
                            type='scannable-async-search-multiselect'
                            label={i18n.t('SPAREPART')}
                            value={material.spareParts}
                            options={loadOptions}
                            onChange={handleSelect}
                            extraFunctions={{ QR: true, BR: true }} />
                            </div>
                        : <div className={invalidFields.includes('wildSparePart') ? 'invalid-field' : ''}><FormElement
                            name='wildSparePart'
                            type='text'
                            label={i18n.t('WILD_SPAREPART')}
                            value={material.wildSparePart}
                            onChange={handleChange} /></div>}
                    <div className={invalidFields.includes('sparepartamount') ? 'invalid-field' : ''}>
                        <FormElement
                            name='sparepartamount'
                            type='number'
                            label={i18n.t('MAARA')}
                            value={material.sparepartamount}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {viewSettings.map(viewSetting => {
                        return <div key={viewSetting.id} className={invalidFields.includes(viewSetting.field) ? 'invalid-field' : ''}>
                            {viewSetting.field === 'spareparttype' &&
                                <FormElement
                                    name='spareparttype'
                                    type='text'
                                    label={i18n.t(viewSetting.translationKey)}
                                    value={material.spareparttype}
                                    onChange={handleChange}
                                    required={viewSetting.required}
                                />
                            }
                            {viewSetting.field === 'sparepartmemo' &&
                                <FormElement
                                    name='sparepartmemo'
                                    type='textarea'
                                    label={i18n.t(viewSetting.translationKey)}
                                    value={material.sparepartmemo}
                                    onChange={handleChange}
                                    required={viewSetting.required}
                                />
                            }
                            {viewSetting.field === 'sparepartprice' &&
                                <FormElement
                                    name='sparepartprice'
                                    type='number'
                                    label={i18n.t(viewSetting.translationKey)}
                                    value={material.sparepartprice}
                                    onChange={handleChange}
                                    required={viewSetting.required}
                                />
                            }
                            {viewSetting.field === 'sparepartcomment' &&
                                <FormElement
                                    name='sparepartcomment'
                                    type='textarea'
                                    label={i18n.t(viewSetting.translationKey)}
                                    value={material.sparepartcomment}
                                    onChange={handleChange}
                                    required={viewSetting.required}
                                />
                            }
                            {viewSetting.field === 'sparepartunit' &&
                                <FormElement
                                    name='sparepartunit'
                                    type='select'
                                    options={unitOptions}
                                    label={i18n.t(viewSetting.translationKey)}
                                    value={material.sparepartunit} 
                                    onChange={handleSelect}
                                    required={viewSetting.required}
                                />
                            }
                        </div>
                    })}
                </form>
            </div>
            <ConfirmDialogComponent
                dialogContent={dialog.content}
                callBack={dialog.actions.accept}
                cancelDialog={dialog.actions.cancel}
            />
        </Container>
    );
}

export default MaterialForm;
