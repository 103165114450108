import * as types from '../../config/actionTypes';
import { HandleError } from '../../components/HelperFunctions';
import noviAPI from '../../api/noviAPI';
import i18n from '../../translations/i18n';
import { toast } from 'react-toastify';
import { fetchAllPersonMachineGroupSettings } from 'commonActions/actions';

export const fetchDashboardItemTypes = () => (
    async (dispatch) => {
        dispatch({
            type: `${types.FETCH_DASHBOARD_ITEM_TYPES}_PENDING`,
        });
        try {
            noviAPI.dashboards.fetchDashboardItemTypes()
                .then(({ data }) => {
                    dispatch({
                        type: `${types.FETCH_DASHBOARD_ITEM_TYPES}_FULFILLED`,
                        payload: data
                    });
                })
        } catch (error) {
            HandleError(error, 'Fetch dashboard item types')

            dispatch({
                type: `${types.FETCH_DASHBOARD_ITEM_TYPES}_REJECTED`,
                payload: error
            })
        }
    }  
);

export const fetchDashboardTypeOptions = () => (
    async (dispatch) => {
        dispatch({
            type: `${types.FETCH_DASHBOARD_TYPE_OPTIONS}_PENDING`,
        });
        try {
            noviAPI.dashboards.fetchDashboardTypeOptions()
                .then(({ data }) => {
                    dispatch({
                        type: `${types.FETCH_DASHBOARD_TYPE_OPTIONS}_FULFILLED`,
                        payload: data
                    });
                })
        } catch (error) {
            HandleError(error, 'Fetch dashboard type options')

            dispatch({
                type: `${types.FETCH_DASHBOARD_TYPE_OPTIONS}_REJECTED`,
                payload: error
            })
        }
    }  
);

/*
 * Action to add, update and delete dashboard items
 * 
 * @param dashboardItems - items which are either new or existing ones
 * @param removedItems - items which were removed during dashboard edit
 * 
 */
export const updateDashboardItems = (dashboardItems = [], removedItems = []) => (
    async (dispatch) => {
        dispatch({
            type: `${types.UPDATE_DASHBOARD_ITEMS}_PENDING`,
        });
        try {
            let promises = [];
            dashboardItems.forEach(item => {
                if (item.id) {
                    promises.push(noviAPI.dashboards.updateItem(item.id, item));
                } else {
                    promises.push(noviAPI.dashboards.addItem(item));
                }
            });

            removedItems.forEach(item => {
                promises.push(noviAPI.dashboards.deleteItem(item.id));
            });

            Promise.all(promises).then(() => {
                dispatch({
                    type: `${types.UPDATE_DASHBOARD_ITEMS}_FULFILLED`,
                })
            });

        } 
        catch (error) {
            HandleError(error, 'Update dashboard items')

            dispatch({
                type: `${types.UPDATE_DASHBOARD_ITEMS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const updateDashboard = (dashboardId, dashboard) => (
    async (dispatch) => {
        dispatch({
            type: `${types.UPDATE_DASHBOARD}_PENDING`,
        });

        return noviAPI.dashboards.updateDashboard(dashboardId, dashboard)
            .then(response => {
                dispatch({
                    type: `${types.UPDATE_DASHBOARD}_FULFILLED`,
                });
            })
            .catch(error => {
                HandleError(error, 'Update dashboard name')
                dispatch({
                    type: `${types.UPDATE_DASHBOARD}_REJECTED`,
                    payload: error
                })
            });
    }
);

export const updateFavouriteDashboard = (dashboardId) => (
    async (dispatch, getState) => {

        const { machineGroupId } = getState().settings;

        return noviAPI.personMachineGroupSettings.update(machineGroupId, "defaultDashboard", { value: dashboardId })
            .then(() => {
                dispatch(fetchAllPersonMachineGroupSettings());
            })
            .catch(error => {
                HandleError(error, 'Update default dashboard')
            });
    }
);