import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CheckableHierarchyItem from './CheckableHierarchyItem';
import { Loader } from './Loader';

function CheckableHierarchy({ useHierarchy, selectedItemIds, setSelectedItemIds, parentsOfSelectedItems }) {
    const dispatch = useDispatch();
    const [hierarchyItems] = useHierarchy();

    const setParentId = payload => {
        dispatch({ type: 'SET_HIERARCHY_TREE_PARENT_ID', payload })
    };

    useEffect(() => {
        // componentWillUnmount
        return () => { dispatch({ type: 'RESET_HIERARCHY_TREE' }) }
    }, [dispatch]);

    const calculateSelectedChildren = (id: number) => {
        let count = 0;
        Object.values(parentsOfSelectedItems).forEach((array: any) => {
            if (array.some(i => i === id)) {
                count++;
            }
        })
        return count;
    }

    return (
        hierarchyItems === null
            ? <Loader status={'pending'} />
            : hierarchyItems.map((item, i) => {
                return (
                    <CheckableHierarchyItem
                        key={i}
                        item={item}
                        setParentId={setParentId}
                        selectedItemIds={selectedItemIds}
                        setSelectedItemIds={setSelectedItemIds}
                        lengthOfSelectedChildren={calculateSelectedChildren(item.id)}
                    />
				);
			})
	);
}

export default CheckableHierarchy;