import * as types from '../../config/actionTypes';
import { HandleError } from '../../components/HelperFunctions';
import noviAPI from '../../api/noviAPI';

export const fetchPurchaseOrderItems = (ids) => (

    async dispatch => {
        dispatch({
            type: `${types.FETCH_PURCHASE_ORDER_ITEMS}_PENDING`,
        });
        try {
            noviAPI.purchaseOrderItems.search(ids)
                .then(({ data }) => {
                    dispatch({
                        type: `${types.FETCH_PURCHASE_ORDER_ITEMS}_FULFILLED`,
                        payload: data
                    })
                })
        } catch (error) {
            HandleError(error, 'Fetch purchase order items.');

            dispatch({
                type: `${types.FETCH_PURCHASE_ORDER_ITEMS}_REJECTED`,
                payload: error
            })
        }
    }
);

export type IAddPurchaseOrder = (machineGroupId: number, params: IPurchaseOrderAddition, callback: Function) => void;
export const addPurchaseOrder : IAddPurchaseOrder = (machineGroupId: number, params: IPurchaseOrderAddition, callback: Function) => (

    async (dispatch) => {

        dispatch({
            type: `${types.ADD_PURCHASE_ORDER}_PENDING`
        });

        noviAPI.purchaseOrders.add(machineGroupId, params)
            .then(({data}) => {

                dispatch({
                    type: `${types.ADD_PURCHASE_ORDER}_FULFILLED`,
                    payload: data
                });

                callback(data);
            })
            .catch(err => {
                HandleError(err, 'Add purchase order');
                console.log("[ ERROR ADDING PURCHASE ]", err);
                dispatch({
                    type: `${types.ADD_PURCHASE_ORDER}_REJECTED`,
                    err
                });
            });


    }
);

export type IAddPurchaseOrderItem = (params: IPurchaseOrderItemAddition) => void;
export const addPurchaseOrderItem: IAddPurchaseOrderItem = (params: IPurchaseOrderItemAddition) => (

    async (dispatch) => {

        dispatch({
            type: `${types.ADD_PURCHASE_ORDER_ITEM}_PENDING`
        });

        return noviAPI.purchaseOrderItems.addByWSparePart(params)
            .then(data => {

                dispatch({
                    type: `${types.ADD_PURCHASE_ORDER_ITEM}_FULFILLED`,
                    payload: data
                });
                return data;
            })
            .catch(err => {
                HandleError(err, 'Add purchase order item');
                console.log("[ ERROR ADDING PURCHASE ITEM ]", err);
                dispatch({
                    type: `${types.ADD_PURCHASE_ORDER_ITEM}_REJECTED`
                });
                return err;
            });


    }
);