import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import { useSelector } from 'react-redux';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import PanelContainer from '../../work-schedule/work-card/components/PanelContainer';
import { GetFormDataFromViewSettings } from '../../../components/HelperFunctions';

export default function MaterialsList(props) {
    const { workCard, materials, selectedMaterials, viewSettings } = props;
    const settings = useSelector((state : State) => state.settings);


    function MaterialRow({ material, index }) {
        const selected = selectedMaterials[material.id];

        const handleSelect = () => {
            props.toggleMaterial({ [material.id]: !selected });
        };

        const title = material.wildSparePart ? `${material.wildSparePart}` : `${material.sparePart?.code} // ${material.sparePart?.name ?? material.memo}`;

        let formData = [...viewSettings];
        if (viewSettings && workCard && material && settings) {
            formData = GetFormDataFromViewSettings(viewSettings, workCard, material, settings, {});
        }

        return (
            <div key={index}>
                <PanelContainer
                    id={index}
                    title={title}
                    panelIcons={[{icon: selected ? "done" : "notdone"}]}
                    subTitle={""}
                >
                    <div className="no-paddings">
                        <div className="v-margins-15">
                            <div className="form-table-container">
                                <form>
                                    <Row style={{ display: 'block'}}>
                                        <Col xs={12}>
                                            <FormElement
                                                name={`${title}-${index}-in-order`}
                                                type={'singleline-checkbox'}
                                                label={'ADD_TO_ORDER'}
                                                value={selected}
                                                onChange={handleSelect}
                                            />
                                        </Col>
                                    </Row>
                                    {formData.map(setting => {
                                        const { field, translationKey, type, value } = setting;
                                        
                                        return !['addtopurchaseorder', 'sparepartcode'].includes(field) && (
                                            <Row>
                                                <Col xs={12}>
                                                    <FormElement
                                                        label={i18n.t(translationKey)}
                                                        name={`mat-${material.id}-${type}`}
                                                        value={value}
                                                        type={"label"}
                                                        onChange={null}
                                                        options={[]}
                                                    />
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </form>
                            </div>
                        </div>
                    </div>
                </PanelContainer>
                {index + 1 < materials.length && <hr className="v-margins-5" />}
            </div>
        );
    }
    
    return (
        <div>
            <Accordion
                id="purchase-order-materials"
                className="margin-top-15"
                style={{ width: "100%", marginBottom: "75px" }}
            >
                {materials.map((material, i) => <MaterialRow material={material} index={i}/>)} 
            </Accordion>
        </div>
    );
}