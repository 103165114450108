import React from 'react';
import { HandleError } from 'components/HelperFunctions';
import { useSelector } from 'react-redux';

declare interface ILocalStorage {
    [userId: number]: IUserLocalStorage
}

declare interface IUserLocalStorage {
    [machineGroupId: number]: ILocalStorageValues;
}

declare interface ILocalStorageValues {
    favWarehouse: ILocalStorageValue;
    sortType: {
        [view: string]: ILocalStorageValue;
    };
    defaultDashboard: ILocalStorageValue;
}

declare type ILocalStorageValue = {
    id: number;
    label: string;
    value?: string;
};

enum settingKeys {
    warehouse = "favWarehouse",
    sortType = "sortType",
    dashboard = "defaultDashboard"
}

export const warehouseKey = settingKeys.warehouse;
export const sortTypeKey = settingKeys.sortType;
export const dashboardKey = settingKeys.dashboard;

export type StorageProps = {
    getStorageSetting: (item: settingKeys, innerProperty?: string) => ILocalStorageValue;
    setStorageSetting: (item: settingKeys, value: ILocalStorageValue, innerProperty?: string) => void;
    removeStorageSetting: (item: settingKeys) => void;
}

export function useLocalStorage () {

    const userId = useSelector((state: State) => state.settings.userId);
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const localStorageKey = "userSettings";

    const getDefaultStorage = () : ILocalStorage => ({
        [userId]: getDefaultMachineGroupSettings()
    });

    const getDefaultMachineGroupSettings = () : IUserLocalStorage => ({
        [machineGroupId]: getDefaultStorageValues()
    });

    const getDefaultStorageValues = () : ILocalStorageValues => ({
        favWarehouse: null,
        sortType: {
            worklist: null
        },
        defaultDashboard: null
    });

    const getLocalStorage = () : ILocalStorage => {
        return JSON.parse(localStorage.getItem(localStorageKey)) ?? getDefaultStorage();
    }

    const getUserMachineGroupSettings = (storage: ILocalStorage) : IUserLocalStorage => {
        return storage?.[userId] ?? getDefaultMachineGroupSettings();
    }

    const getUserSettings = (storage: ILocalStorage) : ILocalStorageValues => {
        return storage?.[userId]?.[machineGroupId] ?? getDefaultStorageValues();
    }

    const getStorageSetting = (item: settingKeys, innerProperty = null) : ILocalStorageValue => {
        try {
            if (!userId || !machineGroupId)
                return null;

            const settingValue = getUserSettings(getLocalStorage())[item];
            return innerProperty ? settingValue[innerProperty] : settingValue;
        }
        catch (err) {
            console.log(err);
            HandleError(err, `Load user ${item}`);
        }
    }

    const setStorageSetting = (item: settingKeys, value: ILocalStorageValue, innerProperty = null) => {
        try {
            if (!userId || !machineGroupId)
                return null;

            const storage = getLocalStorage();

            const newStorage = {
                ...storage,
                [userId]: {
                    ...getUserMachineGroupSettings(storage),
                    [machineGroupId]: {
                        ...getUserSettings(storage),
                        [item]: innerProperty
                            ? { ...getUserSettings(storage)[item], [innerProperty]: value }
                            : value
                    }
                }
            };

            localStorage.setItem(localStorageKey, JSON.stringify(newStorage));
        }
        catch (err) {
            console.log(err);
            HandleError(err, `Save user ${item}`);
        }
    }

    const removeStorageSetting = (item: settingKeys) => {
        setStorageSetting(item, null);
    }

    return({
        getStorageSetting,
        setStorageSetting,
        removeStorageSetting
    });
};

export function withStorage(Component: any) {

    // Wrapper for class components to get access to storage
    return (props: any) => {

        const { getStorageSetting, setStorageSetting, removeStorageSetting } = useLocalStorage();

        return (
            <Component 
                {...props} 
                getStorageSetting={getStorageSetting} 
                setStorageSetting={setStorageSetting} 
                removeStorageSetting={removeStorageSetting} 
            />
        );
  };
}