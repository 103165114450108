import React from 'react';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import AsyncSelect from 'react-select/lib/Async';
import Datetime from 'react-datetime';
import i18n from '../../../../translations/i18n';
import QrReaderComponent from '../../../../components/scanners/QrReaderComponent';
import { isNull } from 'util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare, faTimes, faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';
import ScannerContainer from '../../../../components/scanners/ScannerContainer';
import { GetSimplifiedHierarchyPathString } from '../../../../components/HelperFunctions';
import moment from 'moment';
import { DATE_TIME_FORMAT, getDateTimeBase } from 'utils';

const FormElement = ({
    name,
    type,
    label = '',
    value,
    options = null,
    onChange,
    onCreate = null,
    extraFunctions = null,
    required = null,
    customClass = '',
    searchable = true,
    isDisabled = false,
    loading = false,
    onBlur = null,
    decimalNumberStep = '0.1',
    defaultOptions = [],
    getNoOptionsMessage = null
}) => {
    
    const getFormElement = () => {
        const colourStyles = {
            singleValue: (styles, { data }) => {
                return { ...styles, color: data && (data.value === -1 || data.id === -1) ? '#777!important' : '#fff', isDisabled: true }
            },
            option: (styles, { data }) => {
                return {
                    ...styles,
                    color: data && (data.value === -1 || data.id === -1 || data.isDisabled) ? '#777!important' : '#fff',
                    fontStyle: data && data.isDisabled ? 'italic' : ''

                };
            }
        };

        const highlightOpts = {
            option: (styles, { data }) => {
                return {
                    ...styles,
                    color: data && (data.id === 0 || data.id === -1 || data.value === 0 || data.value === -1) ? '#e30059!important' : '#fff'
                };
            }
        };

        const noSelection = { id: -1, label: i18n.t('NO_SELECTION') };
        const noOptionsMessage = ({ inputValue }) => {
            if (typeof getNoOptionsMessage === 'function') {
                return getNoOptionsMessage(inputValue);
            } else {
                return inputValue.length < 3
                    ? i18n.t('ENTER_MORE_CHARACTERS', { amount: 3 - inputValue.length })
                    : i18n.t('NO_MATCHES');
            }
        }
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        switch (type) {
            case 'label':
                let labelValue = value;
                if (moment(value, moment.HTML5_FMT.DATETIME_LOCAL, true).isValid()) {
                    labelValue = getDateTimeBase(value).format(DATE_TIME_FORMAT);
                }
                return (
                    <input
                        name={name}
                        className="disabled"
                        type="text"
                        value={labelValue}
                        readOnly
                    />
                );
            case 'textarealabel':
                let textAreaLabelValue = value;
                if (moment(value, moment.HTML5_FMT.DATETIME_LOCAL, true).isValid()) {
                    textAreaLabelValue = getDateTimeBase(value).format(DATE_TIME_FORMAT);
                } else if (name == "machinehierarchypath") {
                    textAreaLabelValue = GetSimplifiedHierarchyPathString(value);
                }
                return (
                    <textarea
                        name={name}
                        value={textAreaLabelValue}
                        className="disabled"
                        readOnly
                        style={{ minHeight: '80px', maxHeight: '100%' }}
                    />
                );
            case 'level':
                const levelValue = value ? value.machineCode ? value.machineCode + " / " + value.name : value.name : '';

                return (
                    <div>
                        <input
                            name={name}
                            type="text"
                            value={levelValue}
                            onClick={onChange}
                            disabled={isDisabled}
                        />
                    </div>
                );
            case 'machine':
                const machineValue = value ? value.code ? value.code + " / " + value.name : value.name : '';

                return (
                    <div className={extraFunctions ? 'inline-input-row' : ''}>
                        <input
                            name={name}
                            type="text"
                            value={machineValue}
                            onClick={onChange}
                            onChange={e => e.preventDefault()}
                            disabled={isDisabled}
                        />
                        {!isDisabled && extraFunctions && extraFunctions.QR && <QrReaderComponent
                            onChange={extraFunctions.QR.handleChange}
                            scanQrData={extraFunctions.QR.scanData}
                            readerState={extraFunctions.QR.state}
                        />}
                    </div>
                );
            case 'async-search-multiselect_QR':
                return (
                    <div className={extraFunctions ? 'inline-input-row' : ''}>
                        <div>
                            <AsyncSelect
                                placeholder={i18n.t('SEARCH_PH')}
                                name={name}
                                value={value}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                cacheOptions
                                loadOptions={options}
                                onChange={onChange}
                                noOptionsMessage={({ inputValue }) =>
                                    inputValue.length < 3
                                        ? i18n.t('ENTER_MORE_CHARACTERS', { amount: 3 - inputValue.length })
                                        : i18n.t('NO_MATCHES')}
                                isMulti
                            />
                        </div>
                        {extraFunctions && extraFunctions.QR && <QrReaderComponent
                            onChange={extraFunctions.QR.handleChange}
                            scanQrData={extraFunctions.QR.scanData}
                            readerState={extraFunctions.QR.state}
                        />}
                    </div>
                );
            //BR - barcode reader
            case 'scannable-async-search-multiselect':
                return (
                    <div className={extraFunctions ? 'inline-input-row' : ''}>
                        <div>
                            <div style={{ width: '100%' }}>
                                <AsyncSelect
                                    placeholder={i18n.t('SEARCH_PH')}
                                    name={name}
                                    value={value}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    cacheOptions
                                    loadOptions={options}
                                    onChange={onChange}
                                    noOptionsMessage={({ inputValue }) =>
                                        inputValue.length < 3
                                            ? i18n.t('ENTER_MORE_CHARACTERS', { amount: 3 - inputValue.length })
                                            : i18n.t('NO_MATCHES')}
                                    isMulti
                                />
                            {extraFunctions && <span style={{ float: 'right' }}>
                                {extraFunctions.QR && <ScannerContainer type="QR" />}
                                {extraFunctions.BR && <ScannerContainer type="barcode" />}
                            </span>}
                            </div>
                        </div>
                    </div>
                );
            case 'text':
                return (
                    <input
                        name={name}
                        value={value}
                        type="text"
                        onChange={onChange}
                        disabled={isDisabled}
                        onBlur={onBlur}
                    />
                );
            case 'workStatus':
            case 'workstatus':
                if (!value) value = { value: -1 };
                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={{ value: value.id, label: i18n.t(value.label) }}
                        options={
                            [{ id: -1, label: i18n.t('NO_SELECTION') }].concat(options).map(option => (
                                { value: option.id, label: i18n.t(option.label) }
                            ))
                        }
                        onChange={onChange}
                        disabled={isDisabled}
                        styles={colourStyles}
                    /> : <input disabled value={(value && value.label) || i18n.t('NO_SELECTION')} />
                );
            case 'machineHalt':
            case 'machinehalt':
                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={{ value: value.id, label: i18n.t(value.label) }}
                        options={
                            [{ value: -1, label: i18n.t('NO_SELECTION'), color: 'gray' }].concat(options).map(option => (
                                { value: option.value, label: i18n.t(option.label) }
                            ))
                        }
                        onChange={onChange}
                    /> : <input disabled value={(value && value.label) || i18n.t('NO_SELECTION')} />
                );
            case 'textarea':
                return (
                    <textarea
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        disabled={isDisabled}
                    />
                );
            case 'datetime':

                if (!isMobile && value) {
                    value = moment(value);
                }
                
                /* todo: 
                    min={'2019-10-15T00:00'}
                    max={'2019-10-25T00:00'}
                */
                return (
                    <div className="form-element-with-clear-action">
                        <div className="datepicker-container">
                            {!isMobile
                                ? <Datetime
                                    className="datepicker"
                                    value={value}
                                    inputProps={{ name: name, readOnly: true, disabled: isDisabled }}
                                    onChange={(e) => onChange(e, name)}
                                    locale={i18n.language}
                                />
                                : <input
                                    value={value}
                                    name={name}
                                    onChange={(e) => onChange(e, name)}
                                    type="datetime-local"
                                    disabled={isDisabled}
                                />
                            }
                        </div>
                        {!isDisabled && !isMobile && <div className="clear-field-icon" onClick={() => onChange(null, name)}>
                            <FontAwesomeIcon icon={faTimes} className="icon-clear" />
                        </div>}
                    </div>
                );
            case 'date':
            case 'date-input':

                if (!isMobile && value) {
                    value = moment(value);
                }
                return (
                    <div className="form-element-with-clear-action">
                        <div className="datepicker-container">
                            {!isMobile
                                ? <Datetime
                                    className="datepicker"
                                    value={value}
                                    inputProps={{ name: name, readOnly: true, disabled: isDisabled }}
                                    onChange={(e) => onChange(e, name)}
                                    locale={i18n.language}
                                    timeFormat={false}
                                />
                                : <input
                                    value={value}
                                    name={name}
                                    onChange={(e) => onChange(e, name)}
                                    type="date"
                                    disabled={isDisabled}
                                />
                            }
                        </div>
                        {!isDisabled && !isMobile && <div className="clear-field-icon" onClick={() => onChange(null, name)}>
                            <FontAwesomeIcon icon={faTimes} className="icon-clear" />
                        </div>}
                    </div>
                );
            case 'multi':
            case 'worker':
                if (!value) value = [];

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={
                            value?.map(val => (
                                { value: val.id || val.value, label: val.label || val.name }
                            ))
                        }
                        label={value?.map(val => (
                            val.label
                        ))}
                        options={
                            options?.map(option => (
                                { value: option.id || option.value, label: option.label || option.name }
                            ))
                        }
                        onChange={onChange}
                        isMulti
                        disabled={isDisabled}
                        menuShouldScrollIntoView={true}
                    /> : <input disabled value={value.length > 0 ? value.map(val => (' ' + val.label)) : i18n.t('NO_SELECTION')} />
                );
            case 'multi+user':
                if (!value) value = [];

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        isSearchable={true}
                        className="react-select-container first-red"
                        classNamePrefix="react-select"
                        value={
                            value?.map(val => (
                                { value: val.id || val.value, label: val.label || val.name }
                            ))
                        }
                        label={value?.map(val => (
                            val.label
                        ))}
                        options={
                            options?.map(option => (
                                { value: option.id ?? option.value, label: option.label || option.name }
                            ))
                        }
                        onChange={onChange}
                        isMulti
                        disabled={isDisabled}
                        menuShouldScrollIntoView={true}
                        styles={highlightOpts}
                    /> : <input disabled value={value.length > 0 ? value.map(val => (' ' + val.label)) : i18n.t('NO_SELECTION')} />
                );
            case 'machinerequirements':
                if (!value) value = [];

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={
                            value?.map(val => (
                                { value: val.id, label: val.value }
                            ))
                        }
                        label={value?.map(val => (
                            val.value
                        ))}
                        options={
                            options?.map(option => (
                                { value: option.id, label: option.label }
                            ))
                        }
                        onChange={onChange}
                        isMulti
                        disabled={isDisabled}
                        menuShouldScrollIntoView={true}
                    /> : <input disabled value={value.length > 0 ? value.map(val => (' ' + val.value)) : ''} />
                );
            case 'input':
                if (isNull(value)) value = undefined;
                return (
                    <input
                        name={name}
                        type="text"
                        value={value}
                        onChange={onChange}
                        disabled={isDisabled}
                    />
                );
            case 'passwordinput':
                if (isNull(value)) value = undefined;
                return (
                    <input
                        name={name}
                        type="password"
                        value={value}
                        onChange={onChange}
                    />
                );
            case 'extradatanumber':
            case 'float-input':
                return (
                    <input
                        style={{ textAlign: 'right' }}
                        name={name}
                        type="number"
                        step="0.1"
                        min={0}
                        value={value}
                        onChange={onChange}
                        onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                        disabled={isDisabled}
                    />
                );
            case 'number-input':
                return (
                    <input
                        style={{ textAlign: 'right' }}
                        name={name}
                        type="number"
                        step="1"
                        min={0}
                        value={value}
                        onChange={onChange}
                        onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                        disabled={isDisabled}
                    />
                );
            case 'workhours':
                return (
                    <input
                        name={name}
                        type="number"
                        step="0.1"
                        min={0}
                        value={value}
                        onChange={onChange}
                        onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                        disabled={isDisabled}
                    />
                );
            case 'checkbox':
            case 'singleline-checkbox':
                return (!isDisabled
                    ? <div className="checkbox" onClick={() => onChange(value, name)}>
                        <FontAwesomeIcon icon={value ? faCheckSquare : faSquare} size="3x" className={'icon-primary ' + customClass} />
                    </div>
                    : <div className="checkbox">
                        <FontAwesomeIcon icon={value ? faCheckSquare : faSquare} size="3x" className={'icon-primary ' + customClass} style={{ opacity: 0.7, color: 'gray'}} />
                    </div>);
            case 'singleline-select':
                if (!value) value = { id: -1, label: i18n.t('NO_SELECTION') };

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={value}
                        options={options}
                        onChange={onChange}
                        isSearchable={false}
                        styles={colourStyles}
                    /> : <input disabled value={(value && value.label)} />
                );
            case 'select':
                if (!value) value = { value: -1 };
                const selectLabelValue = value.label || value.name;

                options = options ? [{ id: -1, label: i18n.t('NO_SELECTION') }].concat(options.map(option => (
                    {
                        id: option.id,
                        label: i18n.t(option.label) || i18n.t(option.name) || i18n.t(option.value) || i18n.t(option.translationKey),
                        group: option.group,
                        parentId: option.parentId,
                        rowId: option.rowId
                    }
                ))) : []

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={{ id: value.id, label: i18n.t(selectLabelValue) }}
                        options={options}
                        onChange={onChange}
                        isLoading={loading}
                        isSearchable={false}
                        styles={colourStyles}
                    //menuIsOpen={true}
                    /> : <input disabled value={value && (value.label || value.name) || (typeof value === 'string' && value) || ''} />
                );
            case 'select-choice':
                if (!value) value = { id: -1, label: '' };
                const selectChoiceLabelValue = value.label || value.name;

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={{ id: value.id, label: i18n.t(selectChoiceLabelValue)}}
                        options={options}
                        onChange={onChange}
                        isLoading={loading}
                        isSearchable={false}
                        styles={colourStyles}
                    //menuIsOpen={true}
                    /> : <input disabled value={value && (value.label || value.name) || (typeof value === 'string' && value) || ''} />
                );
            case 'detail':
                const opts = options?.map(option => (
                    { value: option.id || option.value, label: option.label || option.name || option.value }
                )) ?? [];

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={value}
                        options={[{ id: -1, label: i18n.t('NO_SELECTION') }].concat(opts)}
                        onChange={onChange}
                        isSearchable={false}
                        disabled={isDisabled}
                        styles={colourStyles}
                    /> : <input disabled value={value.label} />
                );
            case 'sparepartdetail':
                value = value || { id: -1, label: '' };

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={value}
                        options={[noSelection].concat(options)}
                        onChange={onChange}
                        isSearchable={false}
                        disabled={isDisabled}
                        styles={colourStyles}
                    /> : <input disabled value={value.label} />
                );
            case 'phasedetail':
                value = value || { id: -1, label: '' };
                options = options ? [{ id: -1, label: i18n.t('NO_SELECTION') }].concat(options.map(option => (
                    { id: option.id, label: i18n.t(option.value), group: option.group, parentId: option.parentId, rowId: option.rowId }
                ))) : []

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={{ id: value.id, label: i18n.t(value.label), group: value.group, parentId: value.parentId, rowId: value.rowId }}
                        options={options}
                        onChange={onChange}
                        isSearchable={false}
                        disabled={isDisabled}
                        styles={colourStyles}
                    /> : <input disabled value={value.label} />
                );
            case 'multiselect':
                if (!value) value = [];

                options = options ? [{ id: -1, label: i18n.t('NO_SELECTION') }].concat(options.map(option => (
                    { id: option.id, label: i18n.t(option.value) || i18n.t(option.translationKey), group: option.group, parentId: option.parentId, rowId: option.rowId }
                ))) : []

                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        isSearchable={false}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={
                            value.map(val => (
                                { value: val.id, label: val.label }
                            ))
                        }
                        label={value.map(val => (
                            val.label
                        ))}
                        options={options}
                        onChange={onChange}
                        isMulti
                        disabled={isDisabled}
                    /> : <input disabled value={value.length > 0 ? value.map(val => (' ' + val.label)) : i18n.t('NO_SELECTION')} />
                );
            case 'async-search-select':
                value = value || { id: -1, label: '' };

                return (
                    <div>
                        <AsyncSelect
                            placeholder={i18n.t('SEARCH_PH')}
                            name={name}
                            value={{ value: value.id, label: value.label || value.name }}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            cacheOptions
                            isClearable
                            loadOptions={options}
                            onChange={onChange}
                            styles={colourStyles}
                            defaultOptions={defaultOptions}
                            noOptionsMessage={noOptionsMessage}
                        />
                    </div>
                );
            case 'async-search-multiselect':
                return (
                    <div>
                        <AsyncSelect
                            placeholder={i18n.t('SEARCH_PH')}
                            name={name}
                            value={
                                value ? value.map(val => (
                                    { value: val.id || val.value, label: val.label || val.name }
                                )) : ''
                            }
                            className="react-select-container"
                            classNamePrefix="react-select"
                            cacheOptions
                            defaultOptions
                            loadOptions={options}
                            onChange={onChange}
                            noOptionsMessage={({ inputValue }) =>
                                inputValue.length < 3
                                    ? i18n.t('ENTER_MORE_CHARACTERS', { amount: 3 - inputValue.length })
                                    : i18n.t('NO_MATCHES')
                            }
                            isMulti
                        />
                    </div>
                );
            case 'thirdparty':
                return (
                    <div>
                        <AsyncSelect
                            placeholder={i18n.t('SEARCH_PH')}
                            name={name}
                            value={
                                value ? value.map(val => (
                                    { value: val.id || val.value, label: val.label || val.name, ...(val.isLocal && { isLocal: true }) }
                                )) : ''
                            }
                            className="react-select-container"
                            classNamePrefix="react-select"
                            cacheOptions
                            defaultOptions
                            loadOptions={options}
                            onChange={onChange}
                            noOptionsMessage={({ inputValue }) =>
                                inputValue.length < 3
                                    ? i18n.t('ENTER_MORE_CHARACTERS', { amount: 3 - inputValue.length })
                                    : i18n.t('NO_MATCHES')
                            }
                            isMulti
                        />
                    </div>
                );
            case 'thirdparty+addition':
                return (
                    <div className='clearfix'>
                        <div style={{ float: "left", width: "90%" }}>
                            <AsyncSelect
                                placeholder={i18n.t('SEARCH_PH')}
                                name={name}
                                value={
                                    value ? value.map(val => (
                                        { value: val.id || val.value, label: val.label || val.name, ...(value.isLocal && { isLocal: true }) }
                                    )) : ''
                                }
                                className="react-select-container"
                                classNamePrefix="react-select"
                                cacheOptions
                                defaultOptions
                                loadOptions={options}
                                onChange={onChange}
                                noOptionsMessage={({ inputValue }) =>
                                    inputValue.length < 3
                                        ? i18n.t('ENTER_MORE_CHARACTERS', { amount: 3 - inputValue.length })
                                        : i18n.t('NO_MATCHES')
                                }
                                isMulti
                            />
                        </div>
                        <div style={{ float: 'right', width: "10%", paddingTop: "6px", paddingLeft: "2%", /* TODO: Fix style */ }}> 
                            <FontAwesomeIcon icon={faPlus} size="2x" onClick={() => extraFunctions?.()} />
                        </div>
                    </div>
                );
            case 'number':
                return (
                    <input
                        name={name}
                        type="number"
                        step={decimalNumberStep}
                        min={0}
                        value={value}
                        onChange={onChange}
                        onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                        disabled={isDisabled}
                    />
                );
            case 'signature':
                return (
                    <div onClick={onChange}>
                        <input
                            name={name}
                            disabled={true}
                            type="text"
                            value={value}
                            className={customClass}
                        />
                    </div>
                )
            case 'min-max':
                return (
                    <span className='min-max-input'>
                        <input
                            name={name}
                            placeholder="min"
                            type="number"
                            value={value.min}
                            onChange={onChange}
                            onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                            className={customClass}
                            data-type='min'
                        />
                        <span>-</span>
                        <input
                            name={name}
                            placeholder="max"
                            type="number"
                            value={value.max}
                            onChange={onChange}
                            onKeyDown={(evt) => evt.key.toLowerCase() === 'e' && evt.preventDefault()}
                            className={customClass}
                            data-type='max'
                        />
                    </span>
                )
            case 'tag-select':
                if (!value) value = [];
                return (
                    <div>
                        <AsyncCreatableSelect
                            className='react-select-container'
                            classNamePrefix='react-select'
                            onChange={onChange}
                            onCreateOption={onCreate}
                            placeholder={i18n.t(label)}
                            name={name}
                            value={value}
                            options={options}
                            getOptionLabel={opt => opt.value || opt.translationKey}
                            isClearable
                            isMulti
                        />
                    </div>
                )
            case 'simple-select':
                return (
                    <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={value}
                        options={options}
                        onChange={onChange}
                        isClearable
                        isLoading={loading}
                        isDispabled={isDisabled}
                    />
                );
            case 'machinerequirements':
                if (!value) value = [];
                return (
                    !isDisabled ? <Select
                        id={name}
                        name={name}
                        placeholder={i18n.t('CHOOSE')}
                        isSearchable={false}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={value.map(val => ({ value: val.id, label: val.label }))}
                        label={value.map(val => (val.label))}
                        options={options?.map(opt => ({ value: opt.id, label: opt.label })) || []}
                        onChange={onChange}
                        isMulti
                        disabled={isDisabled}
                    /> : <input disabled value={value.length > 0 ? value.map(val => (' ' + val.label)) : i18n.t('NO_SELECTION')} />
                );
            default:
                return (
                    <input
                        name={name}
                        type="text"
                        value={value}
                        onChange={onChange}
                        disabled={isDisabled}
                    />
                );
        }
    }

    return (
        <div className={type.includes('singleline') ? 'flat-row' : ''}>
            {label && <label style={{ overflowX: 'hidden' }}>
                {i18n.t(label)} {required && <span> *</span>} {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            </label>}
            {getFormElement()}
        </div>
    );
}

export default FormElement;
