import React from 'react';
import CardComponent from '../../../../components/CardComponent';
import WorkcardTitle from './WorkcardTitle';
import WorkcardBody from './WorkcardBody';
import { DefineColors, isNullOrWhitespace } from '../../../../components/HelperFunctions';
import { useSelector } from 'react-redux';

const mapColorData = (colors, colorSettings, colorConfig) => {
    return {
        colors,
        settings: colorSettings,
        config: colorConfig['FillTags']
    }
}

const WorkcardCardComponent = ({ cardData, showPhases, showAllPhases = {}, togglePhases = null, }) => {
    const colors = useSelector((state: State) => state.settings.colors);
    const colorSettings = useSelector((state: State) => state.settings.colorSettings);
    const colorConfig = useSelector((state: State) => state.settings.colorConfig);

    const { workCard } = cardData;
    const daysLate = !isNullOrWhitespace(workCard.daysLate) ? Number(workCard.daysLate) : workCard.daysLate;
    const workStatus = workCard.statusLabel?.split('WORKSTATUS')[1] ?? null;

    /**
     * warkCard.colors comes from WorkList so if work cards are shown somewhere else define colors here.
     * Notice that this does NOT affect colors of work phases. They are defined either in WorkList or in PhaseComponent.
     */
    const colorDataMap = mapColorData(colors, colorSettings, colorConfig);
    const statusColors = workCard.colors || DefineColors(workCard, colorDataMap);

    return (
        <CardComponent
            extraHeadingContainer={
                !isNullOrWhitespace(daysLate) && <div className={daysLate < 0 && workStatus === '1'
                    ? 'delay-index delay-index-warning'
                    : 'delay-index'}
                >
                    <b>{daysLate}</b>
                </div>
            }
            itemColors={statusColors}
            title={
                <WorkcardTitle
                    code={workCard.code !== 0 ? workCard.code : workCard.id}
                    isRoute={workCard.isRouteMaintenanceWorkCard}
                    isTimedOpmWc={workCard.isTimedOperatorMaintenanceWorkCard}
                    urgency={workCard.urgencyLabel || workCard.operatorMaintenance?.urgencyLabel}
                />
            }
            body={
                <WorkcardBody
                    cardData={workCard}
                    showPhases={showPhases}
                    showAllPhases={showAllPhases}
                    togglePhases={togglePhases}
                />
            }
        />
    );
}

export default WorkcardCardComponent;
