import React from 'react';
import { Row, Col } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import CardComponent from '../../../components/CardComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faUser } from '@fortawesome/free-solid-svg-icons';
import { getStatusLabel } from '../utils';
import { getDateTimeAndDayName } from 'utils';

const HourCard = ({ hourcard, viewSettings, history }) => {
    let linkTo = '/hourcard/' + hourcard.id;
    let locationState = {};
    const title = (
        <Row>
            <Col xs={12}>
                {<span className="bold flex">
                    <FontAwesomeIcon icon={faCreditCard} size="lg" />
                    <span className="left-margin-5">{i18n.t('HOUR_CARD')}</span>
                </span>}
            </Col>
        </Row>
    );

    const handleClick = (path) => {
        locationState['scrollPosition'] = window.pageYOffset;

        history.push({
            pathname: path,
            state: locationState
        });
    }

    const body = (
        <div onClick={() => handleClick(linkTo)}>
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('INPUT_DATE')}
                </Col>
                <Col xs={8} className="break-word">
                    {getDateTimeAndDayName(hourcard.inputDate)}
                </Col>
            </Row>}
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('SALARY_CATEGORY')}
                </Col>
                <Col xs={8} className="break-word">
                    {hourcard.salaryCategory.name}
                </Col>
            </Row>}
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('AMOUNT')}
                </Col>
                <Col xs={8} className="break-word">
                    {hourcard.hours}
                </Col>
            </Row>}
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('STATUS')}
                </Col>
                <Col xs={8} className="break-word">
                    {getStatusLabel(hourcard.status)}
                </Col>
            </Row>}
            <hr />
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={12}>
                    <div className="sub-header-light flex">
                        <FontAwesomeIcon icon={faUser} />
                        <span className="left-margin-5">{hourcard.person.name}</span>
                    </div>
                </Col>
            </Row>}
        </div>
    );

    return <CardComponent title={title} body={body} />;
}

export default HourCard;
