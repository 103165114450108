import * as types from '../config/actionTypes';

const initialState: ISafetyCardState = {
    safetyTypes: [],
    safetyNotices: [],
    settings: {
        cardLayout: true,
        useDefaultSearch: false,
        pageNumber: 1,
        pageSize: 5,
    },
    displayInfo: {
        totalPageCount: 0,
        totalResultCount: 0,
    },
    status: 'pending'
};

const safetyCardsReducer = (state = initialState, action): ISafetyCardState => {

    switch (action.type) {
        case `${types.FETCH_SAFETY_TYPES}`:
            return {
                ...state,
                safetyTypes: [...action.payload]
            }
        case `${types.FETCH_SAFETY_NOTICES}_PENDING`:
            return {
                ...state,
                status: 'pending',
                safetyNotices: [],
                displayInfo: {
                    totalPageCount: 0,
                    totalResultCount: 0,
                }
            }
        case `${types.FETCH_SAFETY_NOTICES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                safetyNotices: [...action.payload.results],
                displayInfo: {
                    totalResultCount: action.payload.totalResultCount,
                    totalPageCount: action.payload.totalPageCount
                }
            }
        case `${types.FETCH_SAFETY_NOTICES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                safetyNotices: [],
                displayInfo: {
                    totalPageCount: 0,
                    totalResultCount: 0,
                }
            }

        case types.SET_SAFETY_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload
                }
            }

        default: 
            return state;
    }
};

export default safetyCardsReducer;