import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import FormElement from '../scenes/work-schedule/work-card/components/FormElement';
import i18n from '../translations/i18n';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isNullOrWhitespace, GetDetail } from './HelperFunctions';
import '../styles/global.scss';

type Props = {
    submit: boolean;
    workCardId: number;
    onSubmit: (permits) => void;
    subpermit: any;
    permits: any[];
    workCard: IWorkCard;
    permitId: string;
}

const SubPermitForm = (props: Props) => {
    const user = useSelector((state: State) => state.settings.userDetails);
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    const [permits, setPermit] = useState(props.permits);
    
    useEffect(() => {
        if (props.submit) {
            if (!validatePermit()) {
                toast.error(i18n.t('INVALID_FIELDS'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            } else {
                props.onSubmit(permits)
            }
        }
    }, [props.submit, props.onSubmit, permits]); 

    const validatePermit = () => {
        let isValid = true;
        let array = [];
        
        permits.forEach(( permit ) => {

            let validField = true;
            // check for required fields that are empty (don't include separator and label fields)
            if (isNullOrWhitespace(permit.value) && permit.required === true && permit.field !== "" && permit.type !== "label2" && permit.type !== "label" && permit.parentWorkPermitFieldId === props.subpermit?.id){
                validField = false;
            }

            // separate check for required multidetail worker/detail selections, which can't be empty
            else if ((permit.typeOption === 'multi' || permit.type === 'worker') && permit.required === true && permit.value.length === 0 ) {
                validField = false;
            }

            // seperate check for required checkboxes, which can't be left unchecked
            else if (permit.typeOption === "checkbox" && permit.required === true && permit.value === false ){
                validField = false;
            }

            // seperate check for detail fields, if "no selection" is used in required field
            else if (permit.type === "detail" && permit.required === true && permit.value.id === -1 ){
                validField = false;
            }
            
            if (!validField){
                array.push(permit.field);
                isValid = false;
            }
        });
        setInvalidFields(array);
        return isValid;
    }

    const handleChange = e => {
        const { name, value } = e.target;

        setPermit(permits.map(permit =>
            permit.field === name && permit.parentWorkPermitFieldId === props.subpermit?.id
            ? {...permit, value : value}
            : permit))
        setInvalidFields(i => i.filter(str => str !== name));
    }

    const handleSelect = (value, { name }) => {
        setPermit(permits.map(permit =>
            permit.field === name && permit.parentWorkPermitFieldId === props.subpermit?.id
            ? {...permit, value : value}
            : permit))
        setInvalidFields(i => i.filter(str => str !== name));
        
    }

    const handleCheckbox = (value, name ) => {
        if (value === null){
            value = false;
        }
        else {
            value = !value;
        }
        setPermit(permits.map(permit =>
            permit.field === name && permit.parentWorkPermitFieldId === props.subpermit?.id
            ? {...permit, value : value}
            : permit))
    }

    const handleDatetime = (value, name) => {

        let datetime = null;
        if (moment.isMoment(value)) {
            datetime = value.format(moment.HTML5_FMT.DATETIME_LOCAL);
        }
        setPermit(permits.map(permit =>
            permit.field === name && permit.parentWorkPermitFieldId === props.subpermit?.id
            ? {...permit, value : datetime}
            : permit))
        setInvalidFields(i => i.filter(str => str !== name));
    }

    const handleDate = (e, name) => {
        let date = null;
        if (moment.isMoment(e)) {
            date = e.format(moment.HTML5_FMT.DATE);
        }
        else if (!isNullOrWhitespace(e)) {
            date = e.target.value;
        }

        setPermit(permits.map(permit =>
            permit.field === name && isNullOrWhitespace(permit.parentWorkPermitFieldId)
            ? {...permit, value : date}
            : permit))
        setInvalidFields(i => i.filter(str => str !== name));
    }

    const rowItem = (item) => {
        let reqString = "";
        if (item.field === ""){
            return;
        }
        if (item.required){
            // placeohlder for required field mark (fields don't have normal labels here for * to attach to)
            reqString = " *";
        }

        // exit if field isn't right subpermit field
        if (item.parentWorkPermitFieldId !== props.subpermit?.id){
            return;
        }

        // Add all the autofilled form data to data structure also 
        let autofill = "";
        if (item.type === "workcardvalue" && props.workCard !== null){
            if (item.field === "machine"){
                autofill = props.workCard.machine.code +" "+ props.workCard.machine.name;
            } else if (item.field === "code"){
                autofill = props.workCardId.toString();
            } else if (item.field === "faultdescription"){
                autofill = props.workCard.faultDescription;
            } else if (item.field === "procedure"){
                autofill = props.workCard.procedure;
            } else if (item.field === "tyolupa_lknumero"){
                autofill = props.workCard.machine.hierarchyPath;
            }
        } else if (user !== null && (item.field === 'grantor' || item.field === 'orderer')){
            autofill = user.name;
        } else if (item.field === 'status'){
            // TODO: status support in m1.21
            autofill = 'WORKPERMIT_STATUS0';
        }

        if (autofill !== "" && item.value === null){
            item.value = autofill;
        }

        let isMulti = false;
        if (item.typeOption === 'multi' || item.type === 'worker') isMulti = true;

        // set the values for a worker or detail field
        if (props.permitId && (item.type === 'detail' || item.type === 'worker') && item.values !== null && item.value === null) {
            item.value = GetDetail(item.values, item.field, isMulti, null, null, "workPermit");
        }

        return (
            <Row>
                {item.type === 'caption' 
                    ? <Col xs={4} className="header">{item.label}{reqString}</Col>
                    : <Col xs={4} className="sub-header break-word">{item.label}{reqString}</Col>
                }
                
                <Col xs={8}>
                    <div className={invalidFields.includes(item.field) ? 'invalid-field' : ''}>
                    {item.type === 'label' ||item.type === 'label2' || item.type === 'caption'
                        ? item.field === 'status'
                            ? i18n.t('WORKPERMIT_STATUS0')
                            : item.field === 'grantor' && user !== null
                                ? user.name
                                : item.value
                    : <FormElement
                        key={item.id} 
                        name={item.field}
                        type={item.typeOption && item.type === 'extradata'
                            ? item.typeOption
                            : item.type === 'workcardvalue'
                                ? 'text'
                                : item.typeOption === 'multi'
                                    ? item.typeOption
                                    : item.type }
                        value={item.value === null && item.typeOption === 'checkbox'
                            ? false
                            : item.field === 'status'
                                ? i18n.t('WORKPERMIT_STATUS0')
                                : item.field === 'grantor' && user !== null
                                    ? user.name
                                    : item.field === 'orderer' && user !== null
                                        ? user.name
                                        : item.field === 'orderer_tel' && user !== null
                                            ? user.phone
                                            : item.type === 'workcardvalue'
                                                ? autofill
                                                : item.value}
                        options={item.details}
                        onChange={item.typeOption === 'checkbox'
                            ? handleCheckbox
                            : item.type === 'detail' || item.type === 'worker' || item.type === 'status'
                                ? handleSelect
                                : item.typeOption === 'datetime'
                                    ? handleDatetime
                                    : item.typeOption === 'date'
                                        ? handleDate
                                        : handleChange}
                        required={item.required}
                        isDisabled={item.field === 'status' || item.field === 'grantor' || item.type === 'workcardvalue' || item.field === 'orderer'
                            ? true
                            : false}
                        customClass='float-right'
                    />}
                    </div>
                </Col>
            </Row>
        );
    }

    const createRow = (field) => {
        return rowItem(field);
    }

    return (
        <Container>
            <div className="form-table-container bottom-nav-space margin-top-15">
                <form className="workpermit-form">
                <header className="header">{props.subpermit?.label}</header>
                    {permits
                        .sort((a, b) => a.mobileTabOrder - b.mobileTabOrder)
                        .map(item => createRow(item))}
                </form>
            </div>
        </Container>
    );
}

export default SubPermitForm;