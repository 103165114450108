import React, { useState, useRef, useEffect } from 'react';
import HierarchyItem from './HierarchyItem';
import CustomCheckbox from './CustomCheckbox';

function CheckableHierarchyItem({
    item: { id, title, isLevel, isChildren },
    setParentId,
    selectedItemIds,
    setSelectedItemIds,
    lengthOfSelectedChildren = 0
}) {
    const [isChecked, setIsChecked] = useState(false);

    const isInitialRender = useRef(true);
    // selects or unselects the target depending on isChecked
    useEffect(() => {
        // dont run if it's first render
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }
        if (isChecked && !selectedItemIds.includes(id)) {
            setSelectedItemIds({ type: 'check', value: id });
        }
        else if (!isChecked && selectedItemIds.includes(id)) {
            setSelectedItemIds({ type: 'uncheck', value: id });
        }
    }, [isChecked]);

    // clears the checkbox when a new set of hierarchy items is rendered
    useEffect(() => {
        if (!selectedItemIds.includes(id)) {
            setIsChecked(false);
        } else {
            setIsChecked(true)
        }
    }, [selectedItemIds, id])

    return (
        <div className="hierarchy-item-container">
            <span className="hierarchy-item-checkbox">
                <CustomCheckbox
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                />
            </span>
            <HierarchyItem
                title={title}
                isLevel={isLevel}
                isChildren={isChildren}
                onItemClick={() => setIsChecked(!isChecked)}
                onArrowClick={() => setParentId(id)}
                number={lengthOfSelectedChildren}
            />
        </div>
    );
}

export default CheckableHierarchyItem;