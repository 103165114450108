import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import i18n from '../../../translations/i18n';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import FormList from '../../../components/FormList';
import PopoverAction from '../../../components/PopoverAction';
import { GetPopoverActions } from '../../../components/HelperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const SparePartsRow = ({
    id,
    code,
    name,
    amount,
    manufacturers,
    suppliers,
    shelfLocation,
    editPath = null,
    bgColor = '#2f2f2f',
    disabled = false,
    sparePartWarehouseData = [],
    warehousesEnabled = false,
    listWarehouses = false,
    amountInMachine,
    toggleDialog,
    dialogOpen,
    closeDialog
}: Props) => {
    const settings = useSelector((state: State) => state.settings);
    const currentWarehouse = useSelector((state: State) => state.warehouse.currentWarehouse);

    const [isExpanded, expandWarehouse] = useState(false);

    const totalAmount = sparePartWarehouseData?.reduce((a, c) => a + (c?.amount ?? 0), 0) ?? 0;

    let history = useHistory();
    let { machineId } = useParams<UrlParams>();
    const popoverActions = id ? GetPopoverActions(settings, 'sparepart') : GetPopoverActions(settings, 'wildsparepart');
    const handlePopoverClick = (actionName = null, spId = null) => {
        if (actionName === 'invent') {
            let url = '/warehouseinvent/' + spId;
            history.push({
                pathname: url,
                state: {
                    warehouseId: currentWarehouse.id
                }
            });
        } else {
            let location = { pathname: '', state: null }
            location.pathname = actionName === 'edit'
                ? editPath || `/sparepart/${spId}/${actionName}`
                : actionName === 'arrive'
                    ? `/warehouse/arrive/${spId}`
                    : `/warehouseoperations/${spId}/${actionName}`;
            location.state = machineId
                ? { costpooltype: 'MACHINE', machineId: machineId }
                : { warehouse: currentWarehouse, warehouseOperation: actionName};

            history.push(location);
        }
    }

    const getOverlayMenu = () => {
        const children = popoverActions.map((popover, index) => {
            if (listWarehouses && popover.action === 'edit') return <></>;

            return <div key={index}>
                <PopoverAction
                    icon={popover.icon}
                    label={popover.label}
                    paClassName={popover.paClass}
                    onClick={() => handlePopoverClick(popover.action, id)}
                />
                {index < popoverActions.length - 1 && <hr />}
            </div>
        });

        return (
            <OverlayTrigger
                trigger="click"
                rootClose={true}
                placement="left"
                overlay={<Popover id="settings-menu-popover" className="panel-menu-popover">
                    {React.Children.map(children, (child, i) => (
                        <div className="sub-section" key={`setting_${i}`}>
                            <div className="setting-option">
                                {child}
                            </div>
                        </div>
                    ))}
                </Popover>}
            >
                <div className="options-button" onClick={() => toggleDialog(id)}><FontAwesomeIcon icon={faEllipsisV} size="lg" /></div>
            </OverlayTrigger>
        );
    }

    return (
        <Row className="narrow-item-container" style={{ backgroundColor: bgColor }}>
            <Col xs={7} onClick={!disabled ? () => history.push(`/sparepart/${id}`) : null}>
                <div>
                    <p className="bold">{name}</p>
                    {machineId && 
                        <div className="sub-header-light">
                            <span className="right-margin-5">{i18n.t('MAARA_LAITTEESSA')}: </span>
                            <FormList list={`${amountInMachine ?? "-"}`} listItemName={null} />
                        </div>
                    }
                    {id && <>
                        <div className="sub-header-light">
                            <span className="right-margin-5">{i18n.t('MANUFACTURER')}: </span>
                            <FormList list={manufacturers} listItemName='name' />
                        </div>
                        <div className="sub-header-light">
                            <span className="right-margin-5">{i18n.t('SUPPLIER')}: </span>
                            <FormList list={suppliers} listItemName='name' />
                        </div>
                            {(warehousesEnabled || listWarehouses) && sparePartWarehouseData?.length > 0 ? (
                                <div>
                                    {warehousesEnabled ? (
                                        <>
                                            <div className="flex sub-header-light">
                                                <h6 className="no-margins" style={{ cursor: "pointer" }} onClick={(e) => { 
                                                    e.stopPropagation();
                                                    expandWarehouse(!isExpanded);
                                                }}>{i18n.t("WAREHOUSES")}
                                                    <FontAwesomeIcon icon={isExpanded ? faArrowUp : faArrowDown} size="1x" className="left-margin-5"/>
                                                </h6>
                                            </div>
                                            <div>
                                                {isExpanded && sparePartWarehouseData.map(x => {
                                                    return (
                                                        <div className="sub-header-light">
                                                            <div className="list-divider" />
                                                            <p>{`${i18n.t("WAREHOUSE")}: ${x?.warehouse.name ?? "-"}`}</p>
                                                            <p>{`${i18n.t("AMOUNT")}: ${x?.amount ?? "-"}`}</p>
                                                            <p>{`${i18n.t("SHELF_LOCATION")}: ${x?.shelfLocation ?? "-"}`}</p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>) : (
                                        <div>
                                            {sparePartWarehouseData.map(x => {
                                                const str = `${i18n.t("WAREHOUSE")}: ${x?.warehouse.name ?? "-"} / ${i18n.t("AMOUNT")}: ${x?.amount ?? "-"} / ${i18n.t("SHELF_LOCATION")}: ${x?.shelfLocation ?? "-"}`;
                                                return (
                                                    <div key={x.id} className="sub-header-light">
                                                        <p>{str}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}

                                </div>
                            ) : (
                                <div className="flex sub-header-light">
                                    <span className="right-margin-5">{i18n.t('SHELF_LOCATION')}: </span>
                                    <p className="no-margins">{shelfLocation}</p>
                                </div>
                            )}
                    </>}
                </div>
            </Col>
            <Col xs={3} className="no-margins no-paddings align-right">
                <div>
                    <p className="bold">{code}</p>
                    <div className="flex text-align-center">
                        <p className="large-number">{(warehousesEnabled || listWarehouses) ? totalAmount : amount}</p>
                        <p className="sub-header left-margin-5">{i18n.t('PCS').toLowerCase()}</p>
                    </div>
                </div>
            </Col>
            <Col xs={2}>
                <div className="options-container">
                    <span className="bar" />
                    {dialogOpen && dialogOpen[id] && <div className="overlay-canvas" onClick={() => closeDialog()} ></div>}
                    {getOverlayMenu()}
                </div>
            </Col>
        </Row>
    );
}

type Props = {
    id: number;
    code: string;
    name: string;
    amount: number;
    manufacturers: IThirdParty[];
    suppliers: IThirdParty[];
    shelfLocation: string;
    editPath?: string;
    bgColor?: string;
    sparePartWarehouseData?: IWarehouseSparePartLink[];
    disabled?: boolean;
    warehousesEnabled?: boolean;
    listWarehouses?: boolean;
    amountInMachine?: number;
    toggleDialog?: (spId: number) => void;
    dialogOpen?: any;
    closeDialog?: () => void;
};

type UrlParams = { machineId: string; };

export default SparePartsRow;
