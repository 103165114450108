import React, { useState, useEffect } from 'react';
import i18n from '../../../translations/i18n';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setFiltersAction, clearFiltersAction, fetchSparePartDetails, setWarehouseSettings } from '../actions';
import ScannerContainer from '../../../components/scanners/ScannerContainer';
import noviAPI from '../../../api/noviAPI';
import SparePartFilters from './SparePartFilters';
import settingsAPI from '../../../config/settingsAPI';
import { fetchThirdParties, fetchViewSettings, resetQuickSearch } from '../../../commonActions/actions';
import SearchModalHeader from '../../../components/search-modal/SearchModalHeader';
import DialogModal from '../../../components/dialogs/DialogModal';
import DialogBody from '../../../components/dialogs/DialogBody';
import DialogFooter from '../../../components/dialogs/DialogFooter';
import SearchModalButtons from '../../../components/search-modal/SearchModalButtons';
import SearchModalButtonContainer from '../../../components/search-modal/SearchModalButtonContainer';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import ActionButton from '../../../components/buttons/ActionButton';
import { useHistory } from 'react-router-dom';
import { warehouseFilters } from '../../../reducers/warehouseReducer';

const SparePartFilteringButtons = ({ applyFilters }) => (
    <div className="button-row">
        <span className="flex-0-3">
            <ScannerContainer type="QR" customClass={'modal-qr-wrapper'} />
        </span>
        <span className="flex-0-3">
            <ScannerContainer type="barcode" />
        </span>
        <span className="flex-0-3">
            <ActionButton handleClick={applyFilters}>
                {i18n.t('SEARCH')}
            </ActionButton>
        </span>
    </div>
);

const initFields = {
    searchString: '',
    code: '',
    productNumber: '',
    shelfLocation: '',
    orderNumber: '',
    alertLimit: {
        min: '',
        max: ''
    },
    price: {
        min: '',
        max: ''
    },
    amount: {
        min: '',
        max: ''
    },
    memo: '',
    name: '',
    warrantyEnded: { value: -1, label: 'NO_SELECTION' },
    thirdParties: {
        byType: {},
        allTypes: []
    },
    details: {
        byGroup: {},
        allGroups: []
    },
    orderAmount: {
        min: '',
        max: ''
    },
    criticality: '',
    sparePartType: '',
    material: '',
    catalogueNumber: '',
    warehouseSparePartMemo: ''
}

const { viewSettings } = settingsAPI.moduleData.sparepart;

const SparePartFiltering = ({ closeDialog, dialogOpen, callback = null }) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [optionGroups, setOptionGroups] = useState({
        warrantyEnded: {
            label: 'WARRANTY_ENDED',
            options: [
                { value: true, label: 'YES' },
                { value: false, label: 'NO' }
            ]
        }
    });
    const filters = useSelector((state: State) => state.warehouse.currentFilters)
    const [fields, setFields] = useState({
        ...initFields,
        ...Object.keys(initFields).reduce((acc, cur) => {
            return cur !== 'thirdParties' && cur !== 'details' && cur !== 'warrantyEnded'
                ? Object.assign(acc, { [cur]: filters[cur] || initFields[cur] })
                : acc
            }, {}),
        warrantyEnded: optionGroups.warrantyEnded.options.find(w => w.value === filters.warrantyEnded) || initFields.warrantyEnded
    });

    useEffect(() => {
        viewSettings.forEach(viewSetting => {
            dispatch(fetchViewSettings(viewSetting.groupType, viewSetting.actionType));
        });
        dispatch(fetchSparePartDetails());
        dispatch(fetchThirdParties());
    }, [dispatch]);

    // Set third party fields and third party options
    const thirdParties = useSelector((state: State) => state.settings.thirdParties);
    useEffect(() => {
        let byType = {};
        let thirdPartyOptions = {};
        thirdParties.allTypes.forEach(type => {
            Object.assign(byType, { [type.typeId]: filters.thirdParties?.[type.typeId] || [] });
            Object.assign(
                thirdPartyOptions,
                {
                    [type.typeId]: {
                        label: type.name,
                        options: thirdParties.byType?.[type.typeId]?.map(i => ({ value: i.id, label: i.name })) || []
                    }
                }
            );
        });

        setFields(f => ({
            ...f,
            thirdParties: {
                byType,
                allTypes: thirdParties.allTypes.map(type => type.typeId)
            }
        }));

        setOptionGroups(o => Object.assign({}, o, thirdPartyOptions));
    }, [thirdParties, filters]);

    // Set detail fields and detail options
    const details = useSelector((state: State) => state.warehouse.sparePartDetails);
    const sparePartSettings = useSelector((state: State) => state.warehouse.viewSettings.sparepart);
    useEffect(() => {
        let byGroup = {};
        let detailOptions = {};
        const findSparePartSettingByGroup = (group) => sparePartSettings.find(setting => setting.field === group);
        details.allGroups.forEach(group => {
            if (!findSparePartSettingByGroup(group)) return;
            Object.assign(byGroup, { [group]: filters.details?.[group] || [] });
            Object.assign(
                detailOptions,
                {
                    [group]: {
                        label: findSparePartSettingByGroup(group).translationKey,
                        options: details.byGroup[group].map(i => ({ value: i.id, name: i.value }))
                    }
                }
            );
        });

        setFields(f => ({
            ...f,
            details: {
                byGroup,
                allGroups: details.allGroups.filter(group => findSparePartSettingByGroup(group))
            }
        }));

        setOptionGroups(o => Object.assign({}, o, detailOptions));
    }, [details, sparePartSettings, filters]);

    const barcode = useSelector((state: State) => state.scanners.barcode);
    useEffect(() => {
        const signal = axios.CancelToken.source();
        if (barcode.result) {
            noviAPI.spareParts.fetchByCode(barcode.result)
                .then(response => goToSparePart(response.data.id));
        }

        return () => {
            signal.cancel();
        }
    }, [barcode.result]);

    const qr = useSelector((state: State) => state.scanners.QR);
    useEffect(() => {
        if (qr.result) {
            const id = qr.result.split('Id=')[1];
            goToSparePart(id);
        }
    }, [qr.result]);

    useEffect(() => {
        /** Reset fields if the state of filters in store are changed outside this component.
         * For example when default search is set on */
        if (JSON.stringify(filters) === JSON.stringify(warehouseFilters)) {
            resetFields();
        }
    }, [filters]);

    const goToSparePart = id => history.push(`/sparepart/${id}`);

    const callSetFiltersAction = () => {
        let newFilters = {};
        Object.keys(fields).forEach(field => {
            let value = null;
            if ('thirdParties' === field) {
                value = fields[field].allTypes
                    .filter(type => fields[field].byType[type].length)
                    .reduce((acc, cur) => Object.assign(acc, { [cur]: fields[field].byType[cur] }), {});
            } else if ('details' === field) {
                value = fields[field].allGroups
                    .filter(group => fields[field].byGroup[group].length)
                    .reduce((acc, cur) => Object.assign(acc, { [cur]: fields[field].byGroup[cur] }), {});
            } else if ('warrantyEnded' === field) {
                value = fields[field].value;
            } else {
                value = fields[field];
            }

            // add value to newFilters if conditions for the user input are true
            if (value !== undefined && value !== null && (
                // warrantyEnded
                value === true || value === false ||
                // text inputs (searchString, code, etc.)
                value.length ||
                // thirdParties & details
                (Object.keys(value).length && !fields[field].hasOwnProperty('min') && !fields[field].hasOwnProperty('max')) ||
                // fields with min and max values
                (fields[field].min?.length || fields[field].max?.length))
            ) {
                Object.assign(newFilters, { [field]: value });
            };
        });

        dispatch(setWarehouseSettings({ useDefaultSearch: false, pageNumber: 1 }));
        dispatch(setFiltersAction(newFilters));
    }

    const applyFilters = () => {
        dispatch(resetQuickSearch('warehouse'));
        callSetFiltersAction();
        closeDialog();
        callback?.();
    }

    const handleSelect = (value, actionMeta) => {
        // handle third party fields
        if (fields.thirdParties.allTypes.includes(actionMeta.name)) {
            setFields(f => ({
                ...f,
                thirdParties: {
                    ...f.thirdParties,
                    byType: {
                        ...f.thirdParties.byType,
                        [actionMeta.name]: value
                    }
                }
            }));
        // handle detail fields
        } else if (fields.details.allGroups.includes(actionMeta.name)) {
            setFields(f => ({
                ...f,
                details: {
                    ...f.details,
                    byGroup: {
                        ...f.details.byGroup,
                        [actionMeta.name]: value
                    }
                }
            }));
        // handle other select input fields
        } else {
            setFields(f => ({ ...f, [actionMeta.name]: value }));
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, dataset } = e.target;
        setFields(f => ({
            ...f,
            [name]: f[name].hasOwnProperty('min') && f[name].hasOwnProperty('max')
                ? { ...f[name], [dataset.type]: value }
                : value
        }));
    }

    const resetFields = () => {
        /**
         * Extra step is needed to clear the third party and the detail 
         * fields so the fields themselves don't get removed like they 
         * would if we were to use initFields alone to set empty fields.
         * */
         let byType = {};
         fields.thirdParties.allTypes.forEach(type => {
             Object.assign(byType, { [type]: [] });
         })
 
         let byGroup = {};
         fields.details.allGroups.forEach(group => {
             Object.assign(byGroup, { [group]: [] });
         })
 
         setFields(f => ({
             ...initFields,
             thirdParties: {
                 ...f.thirdParties,
                 byType
             },
             details: {
                 ...f.details,
                 byGroup
             }
         }));
    } 

    const clearFilters = () => {
        resetFields();
        dispatch(setWarehouseSettings({ useDefaultSearch: false, pageNumber: 1 }));
        dispatch(clearFiltersAction());
        dispatch(resetQuickSearch('warehouse'));
    }

    const { searchString, ...values } = fields;

    return (
        <DialogModal showDialog={dialogOpen} closeDialog={closeDialog}>
            <SearchModalHeader />
            <DialogBody>
                <FormElement name={'searchString'}
                    type={'text'}
                    label={i18n.t('SEARCH')}
                    value={searchString}
                    onChange={handleChange}
                    options={[]}
                />
                <SearchModalButtonContainer>
                    <SparePartFilteringButtons applyFilters={applyFilters} />
                </SearchModalButtonContainer>
                <SparePartFilters
                    values={values}
                    optionGroups={optionGroups}
                    onChange={handleChange}
                    handleSelect={handleSelect}
                />
            </DialogBody>
            <DialogFooter>
                <SearchModalButtonContainer>
                    <SearchModalButtons
                        applyFilters={applyFilters}
                        clearFilters={clearFilters}
                    />
                </SearchModalButtonContainer>
            </DialogFooter>
        </DialogModal>
    );
}

export default SparePartFiltering;