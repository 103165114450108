import React from 'react';
import { fetchWorkCardDetails, saveWorkListSettings, setFiltersAction, setWorkCardDetails } from '../../work-list/actions';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from "react-redux";
import i18n from '../../../../translations/i18n';
import {
    fetchWorkerGroups,
    showHierarchyTree,
    goBackInHierarchyTree,
    setHierarchyParentId,
    setHierarchyBreadcrumb,
    resetQuickSearch,
    addSearch,
    updateSearch,
    deleteSearch,
    fetchSearches,
    fetchViewSettings,
    fetchCostPools,
} from '../../../../commonActions/actions';
import { fetchWorkcardOptions } from '../../work-card/actions';
import { resetWorkHistory } from '../../work-list/actions';
import { CountFilters, HasRight, Toaster } from '../../../../components/HelperFunctions';
import WorkCardFilters from './WorkCardFilters';
import { Button, Col, Row } from 'react-bootstrap';
import CheckableHierarchy from '../../../../components/CheckableHierarchy';
import useHierarchyMachines from '../../../../hooks/useHierarchyMachines';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowLeftRotate, faCheckCircle, faHome, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import FormElement from '../../work-card/components/FormElement';
import DialogModal from '../../../../components/dialogs/DialogModal';
import SearchModalHeader from '../../../../components/search-modal/SearchModalHeader';
import DialogBody from '../../../../components/dialogs/DialogBody';
import DialogFooter from '../../../../components/dialogs/DialogFooter';
import SearchModalButtonContainer from '../../../../components/search-modal/SearchModalButtonContainer';
import SearchModalButtons from '../../../../components/search-modal/SearchModalButtons';
import LargeGreenButton from '../../../../components/buttons/LargeGreenButton';
import ActionButton from '../../../../components/buttons/ActionButton';
import { compareArrays, dateDiffToDate } from '../utils';
import CustomCheckbox from 'components/CustomCheckbox';
import { UserRights } from 'constants/userRights';
import ConfirmDialogComponent from 'components/ConfirmDialogComponent';
import noviAPI from 'api/noviAPI';
import { ItemTypes } from 'constants/dashboard/itemTypes';
import { mapIdValueToOptionType } from 'scenes/machines/utils';
import { handleDateTimeInput } from 'utils';

interface IProps extends PropsFromRedux {
    dialogOpen: boolean;
    closeDialog: () => void;
    callback?: () => void;
    limitToField?: string;
    filters: IWorkListFilters;
}

interface IState {
    filters: IWorkListFilters;
    selectedSearch: any;
    searchName: string;
    newSearch: boolean;
    saveButtonDisabled: boolean;
    showDeleteButton: boolean;
    reselectedSearch: boolean;
    mgSpecificChecked: boolean;
    showDeleteDialog: boolean;
    selectOptionsFetched: boolean;
    oldHierarchyItems: number[];
    parentsOfSelectedItems: { [id:number]: number[]; }
    parentsOfOldSelectedItems: { [id:number]: number[]; }
}

const initialFilters: IWorkListFilters = {
    searchString: '',
    workCardCode: '',
    machineCode: '',
    ordererIds: [],
    detailIds: [],
    workerIds: [],
    workerGroupIds: [],
    workCardTypes: [],
    startDate: '',
    endDate: '',
    workStatuses: [],
    machineIds: [],
    hierarchyItemIds: [],
    urgencies: [],
    machineHalt: null,
    extraDatas: {},
}

const initialState: IState = {
    filters: initialFilters,
    selectedSearch: null,
    newSearch: false,
    searchName: '',
    saveButtonDisabled: false,
    showDeleteButton: false,
    reselectedSearch: false,
    mgSpecificChecked: false,
    showDeleteDialog: false,
    selectOptionsFetched: false,
    oldHierarchyItems: [],
    parentsOfSelectedItems: {},
    parentsOfOldSelectedItems: {}
}

class WorkCardFiltering extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        const { filters } = this.props;

        this.state = {
            ...initialState,
            filters: {
                searchString: filters ? filters.searchString : '',
                workCardCode: filters ? filters.workCardCode : '',
                machineCode: filters ? filters.machineCode : '',
                detailIds: filters ? filters.detailIds : [],
                ordererIds: filters ? filters.ordererIds : [],
                workerIds: filters ? filters.workerIds : [],
                workerGroupIds: filters ? filters.workerGroupIds : [],
                workCardTypes: filters ? filters.workCardTypes : [],
                startDate: filters ? filters.startDate : '',
                endDate: filters ? filters.endDate : '',
                workStatuses: filters ? filters.workStatuses : [],
                machineIds: filters ? filters.machineIds : [],
                hierarchyItemIds: filters ? filters.hierarchyItemIds : [],
                urgencies: filters ? filters.urgencies : [],
                machineHalt: filters ? filters.machineHalt : null,
                extraDatas: filters ? filters.extraDatas : {},
            }
        };
    }

    componentDidMount() {

        this.props.fetchViewSettings(["workcard", "workcardreport"], "FETCH_WORKCARD_VIEW_SETTINGS");
        this.props.fetchViewSettings(["machine"], "FETCH_MACHINE_VIEW_SETTINGS");
        this.props.fetchCostPools();

        if (this.props.isQuickSearchActive) {
            this.setState({ selectedSearch: null });
        }
    }

    componentDidUpdate(prevProps: IProps) {
        const { dialogOpen, filters, searches, isQuickSearchActive } = this.props;

        const { selectedSearch, searchName, reselectedSearch } = this.state;

        // Set saved search as selected, but only if there wasn't previous selection and no quicksearch in use
        if (prevProps.searches !== searches && !selectedSearch && !isQuickSearchActive) {
            const savedSearch = searches.find(search => search.name === searchName) || null;
            this.setState({ selectedSearch: savedSearch });
        }

        // Updates selectedSearch with data of newly updated and saved search
        if (prevProps.searches !== searches && selectedSearch) {
            const savedSearch = searches.find(search => search.id === selectedSearch.id) || null;
            this.setState({ selectedSearch: savedSearch });
        }

        /* 
         * Clear saved search selection when quicksearch is detected as active, same way as the filters are cleared,
         * but with an exception when one of the saved searches is seleced
         */
        if (isQuickSearchActive && selectedSearch && !reselectedSearch) {
            this.setState({
                selectedSearch: null
            });
        }

        /* Set a new state if the state of filters in store are changed outside this component.
         * This can happen for many reasons. For example when default search is set on filters are cleared
         * or when work history is activated a the value of machineIds is changed */
        if (prevProps.filters !== filters) {
            /* Set new state for machineIds if props.filters.machineIds changes.
            * Before that we have to check if machineIds exists to avoid errors because
            * all the regular filters disappear from the store if any quick search is selected */
            if (filters.machineIds
                && prevProps.filters.machineIds?.length !== filters.machineIds.length) {
                this.setState({
                    filters: {
                        ...initialFilters,
                        machineIds: filters.machineIds
                    }
                });
            } else {
                this.setState({ filters });
            }
        }

        if (dialogOpen && dialogOpen !== prevProps.dialogOpen && !this.state.selectOptionsFetched) {
            this.props.fetchWorkcardOptions()
            this.props.fetchWorkerGroups()
            this.initWorkCardDetails();
            this.setState({ selectOptionsFetched: true })
        }
    }

    initWorkCardDetails = async () => {
        const response = await noviAPI.workCardDetails.fetchAll(this.props.machineGroupId);
        this.props.setWorkCardDetails(response.data);
    }

    handleSelect = (value, actionMeta) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [actionMeta.name]: value
            }
        }));
    }

    handleSearchSelect = (value) => {
        const { searches, options, userRights } = this.props;

        if (value?.id) {
            const currentSearch = searches.find(search => search.id === value.id);
            
            if (currentSearch?.searchParameters) {
                const filterParams: IWorkListSearchParams = JSON.parse(currentSearch.searchParameters)
                
                let detailIds = [];
                if (filterParams.detailIds?.length > 0) {
                    const workTypeDetails = options.workTypeOptions.filter(typeOpt => filterParams.detailIds.find(wType => wType === Number.parseInt(typeOpt.value)));
                    detailIds = detailIds.concat(workTypeDetails);
                }
                const ordererIds = filterParams.ordererIds?.length > 0
                    ? options.ordererOptions.filter(ordOpt => filterParams.ordererIds.includes(ordOpt.id))
                    : null;
                const workerIds = filterParams.workerIds?.length > 0 
                    ? options.workerOptions.filter(workerOpt => filterParams.workerIds.includes(workerOpt.id))
                    : [];
                const workerGroupIds = filterParams.workerGroupIds?.length > 0
                    ? options.workerGroups.filter(wGroupOpt => filterParams.workerGroupIds.includes(wGroupOpt.id))
                    : null;
                const workCardTypes = filterParams.workCardTypes?.length > 0
                    ? options.workCardTypeOptions.filter(wcTypeOpt => filterParams.workCardTypes.find(wcType => wcType === wcTypeOpt.id))
                        .map(opt => ({ id: opt.id, label: i18n.t(opt.label) }))
                    : null;
                const workStatuses = filterParams.workStatuses?.length > 0
                    ? options['statusTypes'].filter(statusOpt => filterParams.workStatuses.find(status => status === statusOpt.id))
                    : null;
                const urgencies = filterParams.urgencies?.length > 0
                    ? options.urgencyOptions.filter(option => filterParams.urgencies.includes(option.id))
                    : [];
                let startDate = '';
                if (filterParams.daysToStartDate !== null) {
                    startDate = dateDiffToDate(filterParams.daysToStartDate)
                } else if (filterParams.startDate) {
                    startDate = filterParams.startDate;
                }
                let endDate = '';
                if (filterParams.daysToEndDate !== null) {
                    endDate = dateDiffToDate(filterParams.daysToEndDate)
                } else if (filterParams.endDate) {
                    endDate = filterParams.endDate;
                }
                let machineHalt = null;
                if (filterParams.machineHalt === true) { machineHalt = { value: 'true', label: i18n.t('YES') } }
                if (filterParams.machineHalt === false) { machineHalt = { value: 'false', label: i18n.t('NO')  }  }

                this.setState({
                    selectedSearch: value,
                    searchName: currentSearch.name,
                    showDeleteButton: true,
                    reselectedSearch: true,
                    mgSpecificChecked: false,
                    filters: {
                        ...filterParams,
                        searchString: filterParams.searchString || '',
                        detailIds: detailIds ? detailIds.map(opt => ({ value: opt.value, label: opt.label })) : [],
                        ordererIds: ordererIds ? ordererIds.map(opt => ({ value: opt.id, label: opt.label })) : [],
                        workerIds: workerIds ? workerIds.map(opt => ({ value: opt.id, label: opt.label })) : [],
                        workerGroupIds: workerGroupIds ? workerGroupIds.map(opt => ({ value: opt.id, label: opt.label })) : [],
                        workCardTypes: workCardTypes ? workCardTypes.map(opt => ({ value: opt.id, label: opt.label })) : [],
                        workStatuses: workStatuses ? workStatuses.map(opt => ({ value: opt.id, label: opt.label })) : [],
                        startDate: startDate,
                        endDate: endDate,
                        urgencies: urgencies ? urgencies.map(opt => ({ value: opt.id, label: opt.label })) : [],
                        hierarchyItemIds: filterParams.hierarchyItemIds,
                        machineCode: filterParams.machineCode || '',
                        workCardCode: filterParams.workCardCode || '',
                        machineHalt: machineHalt,
                        machineIds: filterParams.machineIds || [],
                        extraDatas: filterParams.extraDatas || {},
                    }
                })

                if (currentSearch.personId === null && HasRight(UserRights.DashboardEnvironmentEdit, userRights)) {
                    this.setState({ mgSpecificChecked: true })
                }
            }
        } else {
            this.setState({ selectedSearch: value, searchName: '' })
        }
    }

    toggleNewSearch = () => {
        const { selectedSearch, newSearch } = this.state;

        if (selectedSearch) {
            this.setState(state => ({
                newSearch: !state.newSearch,
                searchName: '',
                selectedSearch: null,
                mgSpecificChecked: false,
                filters: initialFilters
            }))
        } else if (newSearch) {
            this.setState(state => ({
                newSearch: !state.newSearch,
                searchName: '',
                selectedSearch: null,
                mgSpecificChecked: false
            }))
        } else {
            this.setState(state => ({
                newSearch: !state.newSearch,
                selectedSearch: null,
                mgSpecificChecked: false
            }))
        }
    }

    handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [dateInput.name]: dateInput.value
            }
        }));
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'searchName') {
            this.setState(prevState => ({ ...prevState, [name]: value }));
        } 
        else if (name === 'machineIds') {
            this.setState(prevState => ({
                filters: { 
                    ...prevState.filters, 
                    machineIds: value ? [parseInt(value)] : []
                }
            }));
        }
        else {
            this.setState(prevState => ({
                filters: { ...prevState.filters, [name]: value }
            }));
        }
    }

    clearFilters = () => {
        this.setState(initialState, () => {
            this.applyFilters();
        });
    }

    applyFilters = () => {
        const { filters } = this.state;

        this.props.saveWorkListSettings({
            useDefaultSearch: false,
            pageNumber: 1
        });
        if (this.props.isQuickSearchActive) {
            this.props.resetQuickSearch('worklist');
        }
        if (Boolean(this.props.workHistory.machine)) {
            this.props.resetWorkHistory()
        }

        this.setState({ reselectedSearch: false })

        this.props.setFiltersAction(filters);
        this.props.callback?.();
    }

    hierarchyItemsReducer = (state, action) => {
        switch (action.type) {
            case 'check':
                return {
                    ...state,
                    filters: { 
                        ...state.filters,
                        hierarchyItemIds: state.filters.hierarchyItemIds.concat(action.id)
                    },
                    parentsOfSelectedItems: {
                        ...state.parentsOfSelectedItems,
                        [action.id]: action.parentIds
                    }
                }
            case 'uncheck':
                return {
                    filters: {
                        ...state.filters,
                        hierarchyItemIds: state.filters.hierarchyItemIds.filter(id => id !== action.id)
                    },
                    parentsOfSelectedItems: Object.keys(state.parentsOfSelectedItems).reduce((obj, key) => {
                        if (key === action.id.toString()) {
                            return obj;
                        }
                        return { ...obj, [key]: state.parentsOfSelectedItems[key] }
                    }, {})
                }
            case 'clear':
                return {
                    ...state,
                    filters: {
                        ...state.filters,
                        hierarchyItemIds: []
                    },
                    parentsOfSelectedItems: {}
                }
            case 'reset':
                return {
                    ...state,
                    filters: { 
                        ...state.filters,
                        hierarchyItemIds: state.oldHierarchyItems,
                    },
                    parentsOfSelectedItems: state.parentsOfOldSelectedItems
                }
            case 'apply':
                return {
                    ...state,
                    oldHierarchyItems: action.hierarchyItemIds,
                    parentsOfSelectedItems: action.parentsOfSelectedItems,
                    parentsOfOldSelectedItems: action.parentsOfSelectedItems
                }
            default:
                return state;
        }
    }

    openHierarchyTree = () => {
        const isEqual = compareArrays(
            this.state.filters.hierarchyItemIds,
            Object.keys(this.state.parentsOfSelectedItems).map(i => Number.parseInt(i))
        )
        // Get hierarchy parents when opening machine hierarchy for the first time after selecting a saved search.
        if (!isEqual) {
            this.getHierarchyItemsParents()
                .then(parentsOfSelectedItem => {
                    this.setHierarchyItems({
                        type: 'apply',
                        hierarchyItemIds: this.state.filters.hierarchyItemIds,
                        parentsOfSelectedItems: parentsOfSelectedItem
                    })
                })
        }
        this.props.showHierarchyTree(true);
    }

    closeHierarchyTree = () => {
        this.props.showHierarchyTree(false);
    }

    acceptHierarchyItems = () => {
        this.applyHierarchySettings();
        this.closeHierarchyTree();
    }

    goToHierarchyTreeRoot = () => {
        this.props.setHierarchyParentId(null);
        this.props.setHierarchyBreadcrumb([]);
    }

    setHierarchyItem = (action) => {
        const { type, value } = action;
        switch (type) {
            case 'check':
                this.checkHierarchyItem(value); break;
            case 'uncheck':
                this.uncheckHierarchyItem(value); break;
            default:
                break;
        }
    }

    setHierarchyItems = (action) => {
        this.setState(prevState => this.hierarchyItemsReducer(prevState, action))
    }

    checkHierarchyItem = (id: number) => {
        this.getHierarcyItemParents(id)
            .then(parentsOfSelectedItem => {
                this.setHierarchyItems({
                    type: 'check',
                    id: id,
                    parentIds: parentsOfSelectedItem.map(i => i.id)
                })
            })
    }

    uncheckHierarchyItem = (id: number) => {
        this.setHierarchyItems({ type: 'uncheck', id })
    }

    clearHierarchyItems = () => {
        this.setHierarchyItems({ type: 'clear' })
    }

    resetHierarchyItems = () => {
        this.setHierarchyItems({ type: 'reset' })
    }

    applyHierarchySettings = () => {
        this.setHierarchyItems({
            type: 'apply',
            hierarchyItemIds: this.state.filters.hierarchyItemIds,
            parentsOfSelectedItems: this.state.parentsOfSelectedItems
        });
    }

    getHierarchyItemsParents = async () => {
        let parentsOfSelectedItems = {}
        for (const id of this.state.filters.hierarchyItemIds) {
            const arr = await this.getHierarcyItemParents(id);
            parentsOfSelectedItems = Object.assign(
                parentsOfSelectedItems,
                { [id]: arr.map(i => i.id) }
            )
        }
        return parentsOfSelectedItems;
    }

    getHierarcyItemParents = async (id) => {
        try {
            return (await noviAPI.hierarchyItems.fetchParents(this.props.machineGroupId, id)).data;
        } catch (error) {
            return []
        }
    }

    getContent = (): JSX.Element => {
        const { filters } = this.state;

        if (this.props.hierarchytree.show) {
            return <CheckableHierarchy
                useHierarchy={useHierarchyMachines}
                selectedItemIds={filters.hierarchyItemIds}
                setSelectedItemIds={this.setHierarchyItem}
                parentsOfSelectedItems={this.state.parentsOfSelectedItems}
            />;
        }

        const { limitToField, viewSettings, machineViewSettings } = this.props;
        const combined = [...viewSettings.workcard, ...viewSettings.workcardreport];

        const visibleFields = [], details = [], extraDatas = [], costpools = [];

        combined.sort((a, b) => a.tabOrder - b.tabOrder).forEach(field => {
            if (field.type.includes('detail') && field.type !== 'machinedetail' && field.field !== 'worktype')
                details.push(field);
            else if (field.type.includes('extradata') && field.type !== 'machineextradata')
                extraDatas.push(field);
            else if (field.type == 'costpoolgroup' && !field.field.includes("machine_costpoolgroup"))
                // We can't include the machine costpools here yet, because they need to come from machine viewSettings
                costpools.push(field);
            else
                visibleFields.push(field);
        });


        machineViewSettings.sort((a, b) => a.tabOrder - b.tabOrder).forEach(field => {
            if (field.type == "costpoolgroup") {
                costpools.push(field);
            }
        });

        return (
            <React.Fragment>
                {limitToField == null && this.getBodyButtons()}
                {limitToField == null && <FormElement
                    name={'searchString'}
                    type={'text'}
                    label={i18n.t('SEARCH')}
                    value={filters.searchString}
                    onChange={this.handleChange}
                />}
                <WorkCardFilters
                    values={filters}
                    options={this.props.options}
                    handleChange={this.handleChange}
                    handleSelect={this.handleSelect}
                    handleDatetime={this.handleDateTime}
                    limitToField={limitToField}
                    visibleFields={visibleFields}
                    extraFields={{ details, extraDatas, costpools }}
                />
            </React.Fragment>
        );
    }

    getSearchesOptions = () => {
        let options = this.props.searches
            .map(opt => ({ id: opt.id, label: opt.name, ...opt }))
            .sort((a, b) => a.label.localeCompare(b.label));
        return [
            { 
                label: i18n.t('PERSONAL_SEARCHES'),
                options: options.filter(opt => opt.personId !== null)
            },
            { 
                label: i18n.t('MACHINE_GROUP_SPECIFIC_SEARCHES'),
                options: options.filter(opt => opt.personId === null)
            }
        ]
    }

    getBodyButtons = () => {
        const { newSearch, selectedSearch, searchName, mgSpecificChecked } = this.state;
        const { userRights, searches } = this.props;

        return (
            <div className="small-select-container">
                <div>
                    <Button
                        className={"action action-button button-shadow" + (newSearch ? "" : " novi-default-btn-color")}
                        size="sm"
                        variant={newSearch ? "warning" : ""}
                        onClick={this.toggleNewSearch}
                    >
                        <div>{newSearch ? i18n.t('CANCEL') : i18n.t('NEW')}</div>
                    </Button>
                    {!newSearch && searches && <FormElement
                        name={'search'}
                        type={'select-choice'}
                        label={'SAVED_SEARCHES'}
                        value={selectedSearch}
                        options={this.getSearchesOptions()}
                        onChange={this.handleSearchSelect}
                    />}
                    {(newSearch || (selectedSearch !== null && (selectedSearch.personId !== null || HasRight(UserRights.DashboardEnvironmentEdit, userRights)))) && (
                        <FormElement
                            name={'searchName'}
                            type={'text'}
                            label={selectedSearch ? 'CHANGE_SEARCH_NAME' : 'SEARCH_NAME'}
                            value={searchName}
                            onChange={this.handleChange}
                            required={true}
                        />
                    )}
                    {(newSearch || selectedSearch !== null) && HasRight(UserRights.DashboardEnvironmentEdit, userRights) && (
                        <span className="flex margin-top-5" onClick={() => this.setState(state => ({ mgSpecificChecked: !state.mgSpecificChecked }))}>
                            <CustomCheckbox
                                isChecked={mgSpecificChecked}
                                setIsChecked={(checked) => this.setState({ mgSpecificChecked: checked })}
                                className="factory-search-checkbox"
                            />
                            <label className="left-margin-5">
                                {i18n.t('MACHINE_GROUP_SPECIFIC_SEARCH')}
                            </label>
                        </span>
                    )}
                    {selectedSearch !== null && (selectedSearch.personId !== null || HasRight(UserRights.DashboardEnvironmentEdit, userRights)) && (
                        <Button
                            className="action action-button button-shadow margin-top-10"
                            size="sm"
                            variant="danger"
                            onClick={this.onDeleteSearchBtnClick}
                        >
                            <div className="flex-inline" style={{ lineHeight: '15px' }}>
                                <div className="right-margin-5">{i18n.t('DELETE_SEARCH')}</div>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </div>
                        </Button>
                    )}
                </div>
                <hr />
            </div>
        );
    }

    getFooterButtons = () => {
        if (this.props.hierarchytree.show) {
            return this.getHierarchyButtons();
        }

        // These buttons are sorted by rowNumber
        return (
            <SearchModalButtons
                applyFilters={() => {
                    this.applyFilters()
                    this.props.closeDialog()
                }}
                clearFilters={this.clearFilters}
                searchButtonRowNumber={2}
                clearButtonRowNumber={3}
                hideSearchButton={false}
                hideClearButton={this.props.limitToField != null}
            >
                {this.getSaveButton()}
                {this.props.limitToField == null && this.getMachineButton()}
            </SearchModalButtons>
        );
    }

    getHierarchyButtons = () => {
        const { filters, oldHierarchyItems } = this.state;
        const isRootMachine = !this.props.hierarchytree.parentId;
        const itemsSelected = filters?.hierarchyItemIds?.length > 0;

        const secondaryBtn = (fn, icon) => (
            <Button variant='' className='novi-default-btn-outline-color action-button' onClick={fn}>
                <FontAwesomeIcon icon={icon} />
            </Button>
        )

        const acceptBtn = (
            <ActionButton handleClick={this.acceptHierarchyItems}>
                <FontAwesomeIcon icon={faCheckCircle} size="lg" />
            </ActionButton>
        )

        const backBtn = secondaryBtn(this.props.goBackInHierarchyTree, faArrowLeft);
        const clearBtn = itemsSelected && secondaryBtn(this.clearHierarchyItems, faTrashAlt);
        const resetBtn = secondaryBtn(this.resetHierarchyItems, faArrowLeftRotate);
        const homeBtn = secondaryBtn(this.goToHierarchyTreeRoot, faHome);

        return (
            <React.Fragment>
                <Row>
                    <Col xs="3">{!isRootMachine && backBtn}</Col>
                    <Col xs="6">{acceptBtn}</Col>
                    <Col xs="3">
                        {isRootMachine 
                            ? (compareArrays(filters.hierarchyItemIds, oldHierarchyItems) 
                                ? clearBtn 
                                : resetBtn)
                            : homeBtn
                        }
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    getMachineButton = (): JSX.Element => {
        return (
            <LargeGreenButton handleClick={this.openHierarchyTree} rowNumber={1}>
                <span>{i18n.t('MACHINE')}</span>
                {this.state.filters.hierarchyItemIds.length > 0 && <span className="number-badge">
                    {this.state.filters.hierarchyItemIds.length}
                </span>}
            </LargeGreenButton>
        );
    }

    getSaveButton = (): JSX.Element => {
        const { selectedSearch, newSearch } = this.state;
        const { userRights } = this.props;
        if (newSearch || (selectedSearch !== null && (selectedSearch.personId !== null || HasRight(UserRights.DashboardEnvironmentEdit, userRights)))) {
            const filtersCount = CountFilters(this.state.filters);
            return (
                <LargeGreenButton
                    handleClick={this.saveSearch}
                    disabled={!this.state.searchName || filtersCount === 0 || this.state.saveButtonDisabled}
                    rowNumber={4}
                >
                    <div className="flex-inline">
                        <div className="right-margin-5">{i18n.t('SAVE_SEARCH')}</div>
                        <FontAwesomeIcon icon={faSave} size="lg" />
                    </div>
                </LargeGreenButton>
            );
        }
    }

    saveSearch = () => {
        const { 
            selectedSearch,
            searchName,
            mgSpecificChecked,
            filters
        } = this.state;

        this.setState({ saveButtonDisabled: true });

        if (selectedSearch?.id) {
            this.props.updateSearch(selectedSearch.id, filters, searchName, mgSpecificChecked, 'workcard');
        } else {
            this.props.addSearch(filters, searchName, mgSpecificChecked, 'workcard');
        }

        setTimeout(() => {
            this.setState({ saveButtonDisabled: false, newSearch: false });
        }, 2000);
    }

    onDeleteSearchBtnClick = async () => {
        const { selectedSearch } = this.state;

        try {
            const response = await noviAPI.dashboards.fetchAll(this.props.machineGroupId, this.props.userId);
            const dashboards = response.data;
    
            const workCardSearchIdsInDashboardItems = dashboards
                .flatMap(i => i.dashboardModules
                    .flatMap(i => i.dashboardItems
                        .filter(i => i.itemTypeId === ItemTypes.WorkCardSavedSearch)
                        .map(i => Number.parseInt(i.itemValue))
                    )
                )
                
            if (workCardSearchIdsInDashboardItems.some(id => id === selectedSearch.id)) {
                this.setState({ showDeleteDialog: true });
            } else {
                this.props.deleteSearch(selectedSearch.id, 'workcard');
                this.setState({ selectedSearch: null });
            }
        } catch (error) {
            Toaster({ msg: 'ERROR', type: 'error' });
        }
    }

    getDeleteDialog = () => {
        const message = (
            <React.Fragment>
                <p>{i18n.t('SEARCH_FOUND_ON_DASHBOARDS')}</p>
                <p>{i18n.t('CONFIRM_DELETE_SEARCH')}</p>
            </React.Fragment>
        )

        return(
            <ConfirmDialogComponent
                dialogContent={{
                    title: i18n.t('CONFIRM'),
                    body: message,
                    type: "delete"
                }}
                callBack={() => {
                    this.props.deleteSearch(this.state.selectedSearch.id, 'workcard');
                    this.setState({ 
                        showDeleteDialog: false,
                        selectedSearch: null 
                    })
                }}
                cancelDialog={() => this.setState({ showDeleteDialog: false })}
                onHide={() => this.setState({ showDeleteDialog: false })}
            />
        )
    }

    render() {
        if (this.state.showDeleteDialog) {
            return this.getDeleteDialog()
        }

        return (
            <DialogModal showDialog={this.props.dialogOpen} closeDialog={this.props.closeDialog}>
                <SearchModalHeader customTitle={this.props.limitToField} />
                <DialogBody>
                    {this.getContent()}
                </DialogBody>
                <DialogFooter>
                    <SearchModalButtonContainer>
                        {this.getFooterButtons()}
                    </SearchModalButtonContainer>
                </DialogFooter>
            </DialogModal>
        )
    }
}

const filterHidden = (detail: IWorkCardDetail) => !detail.hidden;
const filterDetailByGroup = (detail: IWorkCardDetail, group: string) => detail.group === group;
const sortByRowNumber = (a: IWorkCardDetail, b: IWorkCardDetail) => a.rowNumber - b.rowNumber;

const mapStateToProps = (state: State) => {
    return {
        options: {
            workTypeOptions: state.workcards.details.filter(i => filterDetailByGroup(i, 'worktype')).filter(filterHidden).sort(sortByRowNumber).map(i => mapIdValueToOptionType(i, true)),
            workCardTypeOptions: state.workcards.options.workCardTypeOptions, 
            urgencyOptions: state.settings.urgencyTypes.map(({id, label}) => { return { id, label: i18n.t(label)}; }),
            statusTypes: state.settings.statusTypes ? state.settings.statusTypes.map(sType => ({ id: sType.id, label: i18n.t(sType.label) })) : [],
            workerGroups: [
                {id: 0, label: i18n.t("LOGGED_IN_USERS_WORKER_GROUPS")}, 
                {id: -1, label: i18n.t("NO_WORKER_GROUPS")}, 
                ...state.settings.workerGroups.slice(0).sort((a, b) => a.name.localeCompare(b.name)).map(wGroup => ({ id: wGroup.id, label: wGroup.name }))
            ],
            ordererOptions: [
                {id: 0, label: i18n.t("LOGGED_IN_USER")}, 
                ...state.workcards.options.workerOptions.slice(0).sort((a, b) => a.label.localeCompare(b.label)).map(i => ({ id: i.id, label: i.label }))
            ],
            workerOptions: [
                {id: 0, label: i18n.t("LOGGED_IN_USER")}, 
                {id: -1, label: i18n.t('NO_WORKERS')},
                ...state.workcards.options.workerOptions.slice(0).sort((a, b) => a.label.localeCompare(b.label)).map(i => ({ id: i.id, label: i.label }))
            ],
            details: state.workcards.details,
            costpoolOptions: state.costpools.byGroup
        },
        hierarchytree: state.navigation.hierarchytree,
        workHistory: state.navigation.filtering.workHistory,
        isQuickSearchActive: state.navigation.activeQuickSearches.worklist !== null,
        searches: state.settings.searches?.workCardSearches || [],
        userRights: state.settings.userRights,
        userId: state.settings.userId,
        machineGroupId: state.settings.machineGroupId,
        viewSettings: state.workcards.viewSettings,
        machineViewSettings: state.machines.viewSettings.machine
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchWorkerGroups,
    fetchWorkcardOptions,
    fetchWorkCardDetails,
    saveWorkListSettings,
    showHierarchyTree,
    goBackInHierarchyTree,
    setHierarchyParentId,
    setHierarchyBreadcrumb,
    setFiltersAction,
    resetQuickSearch,
    resetWorkHistory,
    addSearch,
    updateSearch,
    deleteSearch,
    fetchSearches,
    setWorkCardDetails,
    fetchViewSettings,
    fetchCostPools
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WorkCardFiltering);