import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router';
import noviAPI from '../../../../api/noviAPI';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import i18n from '../../../../translations/i18n';
import NavigationBar from '../../../navigation';
import '../../../../styles/global.scss';
import { Loader } from '../../../../components/Loader';
import { HandleError } from '../../../../components/HelperFunctions';
import settingsAPI from '../../../../config/settingsAPI';
import PanelContainer from './PanelContainer';
import { getDate } from 'utils';

const moduleSettings = settingsAPI.moduleData.permit;

const WorkPermit = () => {
    const [fields, setFields] = useState([]);
    const [status, setStatus] = useState('pending');

    let history = useHistory();
    const location = useLocation();
    const { id } = useParams<UrlParams>();

    const permit = useSelector((state: State) => state.workcards.workPermits?.find(wP => wP.id == +id));

    const navProps = { 
        currentView: {
            history,
            location,
            title: `${i18n.t('WORK_PERMIT')} > ${permit?.name ?? id}`,
            view: moduleSettings.name,
            subPhaseLabel: '',
            backAction: {
                action: history.goBack
            }
        },
    };

    useEffect(() => {

         noviAPI.workPermits.fetchFields(parseInt(id, 10))
            .then(({ data }) => {
                setFields(data);
                setStatus('fulfilled');
            })
            .catch(error => {
                const errorCode = error?.response?.status;
                let errorText = ""
                if (errorCode === 422) {
                    errorText = "Selected work permit's format unsupported on mobile, use desktop";
                }
                else {
                    errorText = "Fetch work permit fields";
                }
                console.log("[ ERROR ]", error);
                HandleError(error, errorText);
                setStatus('error');
            })
    }, []);

    const rowItem = (item) => {
        const type = item.typeOption;
        return (
            <Row key={item.id}>
                <Col xs={6} className="sub-header">{item.label}</Col>
                <Col xs={6}>
                    {type === 'checkbox'
                        ? <input type="checkbox" checked={item.value?.toLowerCase() === "true"} />
                        : (type === 'datetime' || type === 'date')
                            ? getDate(item.value)
                            : item.type === 'status'
                                ? i18n.t(item.value)
                                : item.value}
                </Col>

            </Row>
        );
    }

    const createRow = (field) => {

        const isParentPermitField = field.type === 'workpermit';
        const isEnabled = field.value?.toLowerCase() === 'true';

        if (isParentPermitField && !isEnabled)
            return <></>;

         // TODO: make handling for the workpermitregister fields in m1.21 
         const registerFields = ["workpermitcreateddate", "workpermitmodifieddate", "workpermitstartdate", "workpermitclosedate", "responsibleperson", "executor", "issuer"]
         if (registerFields.includes(field.field)) {
            return <></>;
         }

        return isParentPermitField ? (
            <Accordion
                key={field.id}
                id="route-phase-panel"
                className="margin-top-15"
            >
                <PanelContainer
                    id={field.id}
                    title={i18n.t(field.label)}
                    key={field.id}
                    panelIcons={[{ icon: isEnabled ? 'done' : 'skipped' }]}
                    subTitle={''}
                >
                    {field.inverseParentWorkPermitField.map(rowItem)}
                </PanelContainer>
            </Accordion>

        ) : rowItem(field);
    }

    return (
        <div>
            <NavigationBar {...navProps} />
            <Container className="margin-top-15 margin-bottom-70">
                <Loader status={status} />
                {fields
                    .sort((a, b) => a.mobileTabOrder - b.mobileTabOrder)
                    .map(item => createRow(item))}
            </Container>
        </div>
    );
}

type UrlParams = { id: string; };

export default WorkPermit;