import React from 'react';
import FormElement from './FormElement';


const WorkPhaseForm = ({
    phase,
    workPhaseState,
    handleInputChange,
    handleDateChange,
    handleSelect,
    getRequiredValue,
    isInvalid,
    filterDetailOptions,
    isMultiSelectDetail = false,
    filterWorkerOptions
}) => {
    return (
        <div className="form-table-container v-margins-15 bottom-nav-space">
            <form>
                {Object.keys(phase).filter(key => key !== 'id').map(key => {
                    phase[key].required = getRequiredValue(phase[key], phase[key].settingKey)
                    return <div key={key} className={phase[key].required && isInvalid(workPhaseState[key], key) ? 'invalid-field' : ''} >
                        <FormElement
                            name={key}
                            type={phase[key].type === 'phasedetail'
                                ? isMultiSelectDetail
                                    ? 'worker'
                                    : 'detail'
                                : phase[key].type
                            }
                            label={phase[key].label}
                            value={workPhaseState[key]}
                            options={phase[key].type === 'phasedetail'
                                ? filterDetailOptions(key, phase[key].options)
                                : (key === 'workers_wp' || key === 'workers')
                                    ? filterWorkerOptions(phase[key].options, 'workergroups_wp')
                                    : phase[key].options
                            }
                            onChange={phase[key].onChangeType === 'date-input'
                                ? handleDateChange
                                : phase[key].onChangeType === 'select'
                                    ? handleSelect
                                    : handleInputChange
                            }
                            required={phase[key].required}
                            isDisabled={phase[key].isDisabled}
                        />
                    </div>
                })}
            </form>
        </div>
    );
}

export default WorkPhaseForm;