import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormList from '../components/FormList';
import noviAPI from 'api/noviAPI';

function useHierarchyMachines() {
    const [hierarchyMachines, setHierarchyMachines] = useState(null);
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const parentId = useSelector((state: State) => state.navigation.hierarchytree.parentId);
    const dispatch = useDispatch();

    useEffect(() => {
        function fetchHierarchyMachines() {
            noviAPI.hierarchyItems.fetch(machineGroupId, { parentId })
                .then(response => {
                    setHierarchyMachines(response.data.results.map(hierarchyItem => {
                        return {
                            id: hierarchyItem.id,
                            title: machineTitle(hierarchyItem),
                            isLevel: hierarchyItem.levelId ? true : false,
                            isChildren: hierarchyItem.childrenIds.length > 0 ? true : false
                        }
                    }))
                })
        }
        function fetchHierarchyRoute() {
            if (parentId !== null) {
                noviAPI.hierarchyItems.fetchParents(machineGroupId, parentId)
                    .then(response => {
                        dispatch({ type: 'SET_HIERARCHY_TREE_ROUTE', payload: response.data })
                    });
            }
            
        }
        fetchHierarchyMachines();
        fetchHierarchyRoute();
    }, [machineGroupId, parentId, setHierarchyMachines, dispatch])

    function machineTitle(hierarchyItem) {
        // create machine title from machine.name and possible machine.machineCode
        const list = [hierarchyItem.name];
        if (hierarchyItem.machineCode) list.unshift(hierarchyItem.machineCode)
        return <FormList list={list} listSeparator=" // " />;
    }

    return [hierarchyMachines];
}

export default useHierarchyMachines;