import React from 'react';
import NavigationBar from '../../navigation';
import '../../../styles/global.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import { GetTranslationKeyByProp, HandleError, isNullOrWhitespace, SortArray } from '../../../components/HelperFunctions';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import { withRouter, RouteComponentProps } from 'react-router';
import { ScrollToTop } from '../../../components/ScrollToTop';
import { fetchMachineOptions, IFetchMachineOptions } from '../../machines/actions';
import noviAPI from '../../../api/noviAPI';
import { Loader } from '../../../components/Loader';
import { InfoContainer } from '../../../components/InfoContainer';
import settingsAPI from '../../../config/settingsAPI';
import { IFetchViewSettings, fetchViewSettings } from '../../../commonActions/actions';
import { ValueType, ActionMeta } from 'react-select';
import FormList from '../../../components/FormList';
import { getInputDateTime, handleDateTimeInput, parsePayloadDateTime } from 'utils';
type HandleDetail = (value: ValueType<ISparePartDetail>, actionMeta: ActionMeta<ISparePartDetail>) => void;
type HandleThirdParties = (value: ValueType<IThirdParty>, actionMeta: ActionMeta<IThirdParty>) => void;

type DetailOptWithoutValue = Omit<ISparePartDetail, 'value'>
interface DetailOpt extends DetailOptWithoutValue {
    label: string;
}
interface ThirdPartyOpt {
    id: number;
    name: string;
    code: string;
}

interface MatchParams {
    sparePartId?: string;
    operation?: string;
}

interface Props extends RouteComponentProps<MatchParams> {
    viewSettings: IViewSetting[];
    thirdParties: IThirdParties;
    details: ISparePartDetails;
    childData: any;
    sparePartTakes: string;
    fetchMachineOptions: IFetchMachineOptions;
    fetchViewSettings: IFetchViewSettings;
    searchSparePartWarehouseLogs: (sparePartId: number, logType: number) => void;
}

interface IState {
    code?: string;
    productnumber?: string;
    shelflocation?: string;
    orderernumber?: string;
    alertlimit?: string;
    price?: string;
    amount?: string;
    memo?: string;
    name?: string;
    class?: string;
    type?: string;
    group?: string;
    warrantyends?: string;
    lastmodified?: string;
    orderprice?: string;
    extrainfo?: string;
    deprecationinterval?: string;
    deprecationintervalunit?: string;
    deprecationpercent?: string;
    deprecationstart?: string;
    deprecationstartunit?: string;
    deprecationtype?: string;
    iscollection?: string;
    externalkey?: string;
    externallastmodified?: string;
    externaltransferred?: string;
    extraData: {
        [caption: string]: { id?: number; value: string; }
    };
    extraDataCaptions: {
        byCaption: {
            [caption: string]: { id: number; dataType: string; }
        };
        allCaptions: string[];
    }
    sparePartDetails: {
        [group: string]: DetailOpt;
    };
    thirdParties: {
        [typeId: number]: ThirdPartyOpt[];
    };
    warehouseshelflocation: string;
    sparePartSubmitProperties: string[];
    status: string;
    consumption?: string;
    hasChanged: boolean;
}

const moduleSettings = settingsAPI.moduleData.editsparepart;

class EditSparePart extends React.Component<Props, IState> {
    constructor(props) {
        super(props);

        this.state = this.props.viewSettings
            .reduce(
                (prev, setting) => ({ ...prev, [setting.field]: '' }),
                {
                    sparePartSubmitProperties: [], // array to store camelCased property names that are used in update request
                    status: 'pending',
                    extraData: {},
                    extraDataCaptions: {
                        byCaption: {},
                        allCaptions: []
                    },
                    sparePartDetails: {},
                    thirdParties: {},
                    warehouseshelflocation: '',
                    hasChanged: false
                }
            );
    }

    componentDidMount() {
        // fetch spare part data if user is in editing view
        if (this.props.match.params.operation === 'edit') {
            this.fetchSparePart();
        }

        // fetch third party options
        this.props.fetchMachineOptions();

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });

        this.setState({ consumption: this.props.sparePartTakes ? this.props.sparePartTakes.toString() : '' })
    }

    fetchSparePart = () => {
        noviAPI.spareParts.fetch(Number(this.props.match.params.sparePartId))
            .then(async response => {
                const sparePart = response.data;

                try {
                    const extraDataCaptions = (await noviAPI.sparePartExtraDataCaptions.fetchAll()).data.results;

                    const isExtraDataCaptionInViewSettings: (extraDataCaption: ISparePartExtraDataCaption) => boolean = extraDataCaption =>
                        this.props.viewSettings.some(viewSetting => viewSetting.field === extraDataCaption.caption);

                    // set this.state.extraDataCaptions
                    this.setState({
                        extraDataCaptions: {
                            byCaption: extraDataCaptions
                                .filter(extraDataCaptions => isExtraDataCaptionInViewSettings(extraDataCaptions))
                                .reduce((prev, curr) =>
                                    Object.assign(
                                        prev,
                                        {
                                            [curr.caption]: {
                                                id: curr.id,
                                                dataType: curr.dataType
                                            }
                                        }),
                                    {}
                                ),
                            allCaptions: extraDataCaptions
                                .filter(extraDataCaption => isExtraDataCaptionInViewSettings(extraDataCaption))
                                .map(obj => obj.caption)
                        }
                    }, () => {
                        // set this.state.extraData
                        this.state.extraDataCaptions.allCaptions.forEach(caption => {
                            const extraData = sparePart.extraDatas.find(extraData => extraData.caption?.caption === caption) || null;
                            this.setState(prevState => ({
                                extraData: {
                                    ...prevState.extraData,
                                    [caption]: {
                                        id: extraData?.id || null,
                                        value: extraData?.value || ''
                                    }
                                }
                            }));
                        });
                    });
                } catch (e) {
                    console.log(e);
                }

                /**
                 * TODO: check if viewSettings includes sparepartwarehouses
                 * after viewSettings have been fetched
                 * */
                try {
                    const warehouseSparePartLinks = (await noviAPI.warehouseSparePartLinks.fetchSparePartWarehouseData(sparePart.id)).data;
                    const value = FormList({ list: warehouseSparePartLinks.map(i => `${i.warehouse.name} ${i.shelfLocation !== null ? i.shelfLocation : ''}`.trimEnd()) });
                    // set this.state.warehouseshelflocation
                    this.setState({ warehouseshelflocation: value });
                } catch (e) {
                    console.log(e);
                }

                // set this.state.sparePartDetails
                this.setState({
                    sparePartDetails: sparePart.details.reduce(
                        (obj, { value, ...detail }) => Object.assign(obj, { [detail.group]: { label: value, ...detail } }),
                        {}
                    )
                }, () => {
                    if (Object.keys(this.state.sparePartDetails).length) {
                        this.setState(prevState => ({
                            sparePartSubmitProperties: prevState.sparePartSubmitProperties.concat(['detailIds'])
                        }));
                    }
                });

                // set this.state.thirdParties
                this.setState({
                    thirdParties: sparePart.thirdPartiesByTypes.reduce(
                        (acc, cur) => Object.assign(acc, { [cur.type.typeId]: cur.thirdParties }),
                        {}
                    )
                }, () => {
                    if (Object.keys(this.state.thirdParties).length) {
                        this.setState(prevState => ({
                            sparePartSubmitProperties: prevState.sparePartSubmitProperties.concat(['thirdPartyIds'])
                        }));
                    }
                });

                // fields that are shown but not submitted
                const arrayOfPropertiesToNotInlcudeInSparePartSubmitProprties = ['warehouseTotal', 'lastarriveddate'];
                // map the rest of the spare part properties to this.state
                Object.entries(sparePart).forEach(([key, value]) => {
                    // set price to fixed decimals
                    if (key === 'price') value = value?.toFixed(2);
                    if (key === 'alertLimit') value = value?.toString();
                    if (key === 'warrantyEnds') value = getInputDateTime(value);
                    if (key === 'lastModified') value = getInputDateTime(value);
                    if (key === 'lastPurchaseDate') value = getInputDateTime(value);
                    if (key === 'lastArrivedDate') value = getInputDateTime(value);
                    this.setState(prevState => prevState.hasOwnProperty(key.toLowerCase()) && ({
                        ...prevState,
                        [key.toLowerCase()]: value || '',
                        ...(!arrayOfPropertiesToNotInlcudeInSparePartSubmitProprties.includes(key) && { sparePartSubmitProperties: prevState.sparePartSubmitProperties.concat([key]) })
                    }));
                });

                this.setState({ status: 'fulfilled' });
            })
            .catch(e => {
                console.log(e);
                this.setState({ status: 'error' })
            })
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name.toLowerCase();

        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            hasChanged: true
        }));
    }

    handleSelect = (value, action) => {
        this.setState(prevState => ({
            ...prevState,
            [action.name]: value,
            hasChanged: true
        }));
    }

    handleDate = (date: React.ChangeEvent<HTMLInputElement> | moment.Moment, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        this.setState(prevState => ({
            ...prevState,
            [dateInput.name]: dateInput.value,
            hasChanged: true
        }));
    }

    handleExtraData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            extraData: {
                ...prevState.extraData,
                [name]: {
                    ...prevState.extraData[name],
                    value
                }
            }
        }));
    }

    handleDetail: HandleDetail = (value, actionMeta) => {
        this.setState(prevState => ({
            sparePartDetails: {
                ...prevState.sparePartDetails,
                [actionMeta.name]: value
            }, hasChanged: true
        }));
    }

    handleThirdParties: HandleThirdParties = (value, actionMeta) => {
        const { sparePartSubmitProperties } = this.state;
        const submitProperties = sparePartSubmitProperties?.includes('thirdPartyIds')
            ? sparePartSubmitProperties
            : sparePartSubmitProperties.concat('thirdPartyIds');

        this.setState(prevState => ({
            thirdParties: {
                ...prevState.thirdParties,
                [actionMeta.name]: value,
            },
            hasChanged: true,
            sparePartSubmitProperties: submitProperties
        }));
    }

    filterThirdParties = (inputValue = '', type) => {
        if (inputValue.length < 3) return [];

        let thirdParties = this.props.thirdParties.byType[type]
            .filter(thirdParty => {
                const isSelected = this.state.thirdParties[type]?.map(thirdParty => thirdParty.id).includes(thirdParty.id) ?? false;
                const nameMatches = thirdParty.name.toLowerCase().includes(inputValue.toLowerCase());
                return !isSelected && nameMatches;
            })
            .map(thirdParty => {
                return { value: thirdParty.id, label: thirdParty.name }
            });

        const options = SortArray(thirdParties, 'label');

        return options;
    }

    promiseOptions = (filter, field) => {
        return (inputValue) =>
            new Promise(resolve => {
                resolve(filter(inputValue, field));
            });
    }

    handleSubmit = () => {
        if (this.props.match.params.operation === 'edit') {
            let sparePart = {};

            this.state.sparePartSubmitProperties.forEach(prop => {
                if ('detailIds' === prop) {
                    Object.assign(
                        sparePart,
                        { detailIds: Object.values(this.state.sparePartDetails).map(detail => detail.id).filter(id => id !== -1) }
                    );
                } else if ('thirdPartyIds' === prop) {
                    Object.assign(
                        sparePart,
                        {
                            thirdPartyIdsByTypeIds: Object.entries(this.state.thirdParties)
                                .map(([typeId, thirdPartyArray]) => {
                                    return {
                                        typeId: parseInt(typeId, 10),
                                        thirdPartyIds: thirdPartyArray.map(thirdParty => thirdParty.id ?? thirdParty['value'])
                                    }
                                })
                        }
                    );
                } else if (prop == "warrantyEnds") {
                    const value = this.state[prop.toLowerCase()];
                    Object.assign(sparePart, { [prop]: parsePayloadDateTime(value) });
                } else {
                    Object.assign(sparePart, { [prop]: this.state[prop.toLowerCase()] });
                }
            })

            const sparePartId = parseInt(this.props.match.params.sparePartId, 10);

            noviAPI.spareParts.update(sparePartId, sparePart)
                .then(response => {
                    this.state.extraDataCaptions.allCaptions.forEach(async caption => {
                        if (this.state.extraData[caption].id) {
                            await this.updateExtraData(
                                this.state.extraData[caption].id,
                                { value: this.state.extraData[caption].value }
                            );
                        } else {
                            await this.addExtraData(
                                sparePartId,
                                {
                                    value: this.state.extraData[caption].value,
                                    sparePartExtraDataCaptionId: this.state.extraDataCaptions.byCaption[caption].id
                                }
                            );
                        }
                    });

                    this.props.history.replace({
                        pathname: `/sparepart/${sparePartId}`,
                        state: { notificationMsg: 'SPARE_PART_SAVED' }
                    })
                })
                .catch(error => {
                    console.log(error.request)
                    HandleError(error, 'update spare part')
                })
        }
    }

    updateExtraData = async (extraDataId: number, sparePartExtraData: { value: string; }) => {
        try {
            return await noviAPI.sparePartExtraDatas.patch(extraDataId, sparePartExtraData);
        } catch (error) {
            console.log(error);
        }
    }

    addExtraData = async (sparePartId: number, sparePartExtraData: { value: string; sparePartExtraDataCaptionId: number }) => {
        try {
            return await noviAPI.sparePartExtraDatas.add(sparePartId, sparePartExtraData);
        } catch (error) {
            console.log(error);
        }
    }

    getSparePartDetailOptions = (group: string) => {
        const { byGroup } = this.props.details;
        let details = byGroup[group]?.map(({ value, ...detail }) => ({ ...detail, label: value })) || [];
        const options = SortArray(details, 'label');
        return options;
    }

    render() {
        const { location, history, viewSettings } = this.props;

        const sceneData = {
            view: moduleSettings.name,
            title: i18n.t(GetTranslationKeyByProp('editsparepart')).toLowerCase(),
            location: location,
            history: history,
            backAction: { action: history.goBack, params: {mainEditView: true} },
            hasChanged: this.state.hasChanged
        }

        const viewAction = {
            icon: 'save',
            label: '',
            clickFn: this.handleSubmit,
            isActionFn: true,
            paClass: 'start-phase',
        }

        return (
            <div>
                <ScrollToTop />
                <NavigationBar
                    currentView={sceneData}
                    navHistory={this.props}
                    viewAction={viewAction}
                    popoverData={''}
                />
                <div className="work-card-view">
                    <Loader status={this.state.status} />
                    {'fulfilled' === this.state.status && <Container>
                        <div className="form-table-container bottom-nav-space margin-top-15">
                            <form>
                                {viewSettings
                                    .filter(setting => Object.keys(this.state).includes(setting.field))
                                    .sort((a, b) => a.tabOrder - b.tabOrder)
                                    .map((viewSetting, i) => {
                                        if (viewSetting.type === 'extradata') {
                                            return <FormElement
                                                key={i}
                                                name={viewSetting.field}
                                                type={this.state.extraDataCaptions.byCaption[viewSetting.field]?.dataType}
                                                label={i18n.t(viewSetting.translationKey)}
                                                value={this.state.extraData[viewSetting.field]?.value || ''}
                                                onChange={this.handleExtraData}
                                                required={viewSetting.required}
                                                decimalNumberStep="1"
                                            />
                                        }
                                        if (viewSetting.type === 'sparepartdetail') {
                                            return <FormElement
                                                key={i}
                                                name={viewSetting.field}
                                                type={viewSetting.type}
                                                label={i18n.t(viewSetting.translationKey)}
                                                value={this.state.sparePartDetails[viewSetting.field] || ''}
                                                options={this.getSparePartDetailOptions(viewSetting.field)}
                                                onChange={this.handleDetail}
                                                required={viewSetting.required}
                                            />
                                        }
                                        if (viewSetting.type === 'thirdparty') {
                                            return <FormElement
                                                key={i}
                                                name={viewSetting.field}
                                                type={viewSetting.type}
                                                label={i18n.t(viewSetting.translationKey)}
                                                value={this.state.thirdParties[viewSetting.field] || ''}
                                                options={this.promiseOptions(this.filterThirdParties, viewSetting.field)}
                                                onChange={this.handleThirdParties}
                                                required={viewSetting.required}
                                            />
                                        }
                                        return <FormElement
                                            key={i}
                                            name={viewSetting.field}
                                            type={viewSetting.type}
                                            label={i18n.t(viewSetting.translationKey)}
                                            value={this.state[viewSetting.field]}
                                            onChange={
                                                viewSetting.type === 'datetime'
                                                    ? this.handleDate
                                                    : this.onChange
                                            }
                                            required={viewSetting.required}
                                        />
                                    })}
                            </form>
                        </div>
                    </Container>}
                    {'error' === this.state.status && <InfoContainer type={'notfound'} content={'SPARE_PART_NOT_FOUND'} />}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMachineOptions,
    fetchViewSettings
}, dispatch);

const mapStateToProps = (state: State) => {
    return {
        viewSettings: state.warehouse.viewSettings ? state.warehouse.viewSettings.sparepart.slice(0) : null,
        thirdParties: state.settings.thirdParties,
        details: state.warehouse.sparePartDetails,
        sparePartTakes: state.warehouse.sparePartConsumption ? state.warehouse.sparePartConsumption.totalConsumption.toString() : ''
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditSparePart));
