import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import { css } from '@emotion/core';
import { FadeLoader } from 'react-spinners';
import { ProcedureDataTypes } from '../ProcedureDataTypes';

const OperatorProcedureForm = ({
    onChange,
    procedurePhase,
    loadStatus,
    procedureFileData,
    isDoneValue,
    commentValue,
    workHoursValue,
    displayFullImage,
    phaseWorkHoursEnabled
}) => {
    const override = css`
            display: block;
            margin: 0 auto;
            border-color: red;
            margin-top: 50%;
        `;

    const isDone = isDoneValue === 'true'
        ? true
        : isDoneValue === 'false'
            ? false
            : '';

    const document = procedureFileData[procedurePhase?.id];
    return (
        <form>
            <Row style={{ display: 'block'}}>
                <Col xs={12}>
                    {procedurePhase.isDone?.type === ProcedureDataTypes.YesNo || procedurePhase.isDone?.type === ProcedureDataTypes.YesNoAndDescription
                        // select - dropdown
                        ? <FormElement
                            name={'isDone' + procedurePhase.id}
                            type={'singleline-select'}
                            label={'IS_DONE'}
                            value={isDoneValue}
                            onChange={onChange}
                            options={procedurePhase.isDone?.options}
                        />
                        : procedurePhase.isDone?.type === ProcedureDataTypes.OnlyCaption || procedurePhase.isDone?.type === ProcedureDataTypes.Description
                            // no option
                            ? <div></div>
                            // checkbox
                            : <FormElement
                                name={'isDone' + procedurePhase.id}
                                type={'singleline-checkbox'}
                                label={'IS_DONE'}
                                value={isDone}
                                onChange={onChange}
                            />
                    }
                </Col>
            </Row>
            {(procedurePhase.isDone?.type === ProcedureDataTypes.Description
                || procedurePhase.isDone?.type === ProcedureDataTypes.CBAndDescription
                || procedurePhase.isDone?.type === ProcedureDataTypes.YesNoAndDescription
            ) && <Row>
                <Col xs={12}>
                    <FormElement
                        name={'comment' + procedurePhase.id}
                        type={'textarea'}
                        label={'COMMENT'}
                        value={commentValue}
                        onChange={onChange}
                        options={[]}
                    />
                </Col>
            </Row>}
            <Row>
                {phaseWorkHoursEnabled && <Col xs={4}>
                    <FormElement
                        name={'hoursPlanned' + procedurePhase.id}
                        type={'float-input'}
                        label={'WORK_HOURS'}
                        value={workHoursValue}
                        onChange={onChange}
                        options={[]}
                    />
                </Col>}
                <Col xs={8}>
                    {loadStatus && document && <div className="img-container">
                        <label>{i18n.t('OPERATOR_MAINTENANCE_PROCEDURE_DOCUMENT')}</label>
                        {(loadStatus[procedurePhase.id] === 'pending' || loadStatus[procedurePhase.id] === undefined)
                            ? <div className="loading-container"><FadeLoader
                                css={override.styles}
                                height={10}
                                width={5}
                                radius={2}
                                margin={'2px'}
                                color={'#888888'}
                                loading={true}
                            /></div>
                            : loadStatus[procedurePhase.id] === 'fulfilled'
                            ? document.type.includes("image")
                                ? <Image onClick={() => displayFullImage(document.url)} src={document.url} />
                                : <a className="cursive" target="_blank" href={document.url}>{document.url}</a>
                            : <div className="cursive">{i18n.t('IMAGE_LOAD_FAILED')}</div>
                            
                        }
                    </div>}
                </Col>
            </Row>
        </form>
    );
}

export default OperatorProcedureForm;
