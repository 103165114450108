import React from 'react';
import NavigationBar from '../../../navigation';
import '../../../../styles/global.scss';
import '../styles/work-card.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    addWorkPhase,
    updateWorkPhase,
    fetchWorkcardOptions,
    fetchWorkCard
} from '../actions';
import {
    fetchViewSettings,
    setApiCallFulfilled,
    getWorkCardColors,
    fetchWorkerGroups
} from '../../../../commonActions/actions';
import i18n from '../../../../translations/i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    ValidateForm,
    DefineColors,
    GetDetail,
    GetProperty,
    GetOptionsDataByField,
    GetFormInputValueByField,
    GetType,
    PropertyNamesToCamelCase,
    HandleSelectOption,
    GetOnChangeType,
    containsAny,
    filterDetailOptions,
    HandleError
} from '../../../../components/HelperFunctions';
import settingsAPI from '../../../../config/settingsAPI';
import { Loader } from '../../../../components/Loader';
import { fetchWorkPhaseDetails } from '../../work-list/actions';
import { checkRequired } from '../utils';
import { Container } from 'react-bootstrap';
import WorkPhaseForm from './WorkPhaseForm';
import { handleDateTimeInput, parsePayloadDateTime } from 'utils';
import { actionIcons } from '../../../../constants/icons';

interface Props {
    wcCode: number;
    fetchWorkCard: (id: number) => Promise<IWorkCard>;
    addWorkPhase: (workPhase: object, automaticWorkphase: boolean, extraDatas: any[], redirect: Function, saveAndCopy: boolean) => void;
    updateWorkPhase: (id: number, workPhase: object, extraDatas: any[], redirect: Function, saveAndCopy: boolean) => void;
    fetchWorkcardOptions: (arg?: string, ...rest: string[]) => void;
    fetchWorkPhaseDetails: () => void;
    fetchWorkerGroups: () => void;
    setApiCallFulfilled: () => void;
    requiredFieldsData: any;
    data: any;
    match: any;
    history: any;
    location: any;
    workEnded: string;
    fetchViewSettings: (groupType: string, actionName: string) => void;
    viewSettings: any;
    wpColors: any;
    wpId: number;
    fields: any;
    noDateValidation: boolean;
    alternativeDateValidation: boolean;
    wcData: any;
    status: string;
    wpExtraDatas: any;
    getWorkCardColors;
    oneWorkerPerPhase: boolean;
}

interface State {
    [name: string]: any;
    edit: boolean;
    invalidFields: boolean;
    hasChanged: boolean;
}

const moduleSettings = settingsAPI.moduleData.editworkphase;

class EditWorkPhase extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        const loadInfo = props.match.params.workPhaseId ? true : false;

        let stateProps = {
            invalidFields: false,
            edit: false,
            isLoading: loadInfo,
            hasChanged: false
        };
        const { data } = this.props;

        Object.keys(data).forEach(key => {
            stateProps[key] = key === 'id' || Array.isArray(data[key])
                ? data[key]
                : data[key].value;
        });

        this.state = stateProps;
    }

    componentDidMount() {
        const { data, oneWorkerPerPhase } = this.props;
        const { workCardId, workPhaseId } = this.props.match.params;

        this.props.fetchWorkCard(workCardId)
            .then((wc) => {
                const phase = wc.phases?.find(p => p?.id == parseInt(workPhaseId));
                if (workPhaseId && phase) {
                    const { workerGroups, workers, workCanBegin, workCanEnd } = phase;
                    this.setState({
                        workers: (oneWorkerPerPhase && Array.isArray(workers)) ? workers[0] : workers,
                        workergroups: workerGroups.map(wG => ({ id: wG.id, label: wG.name })),
                        workcanbegin: workCanBegin,
                        workcanend: workCanEnd,
                        isLoading: false
                    })
                }
            })
            .catch(err => {
                HandleError(err, 'Fetch workcard');
            })

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });

        this.props.fetchWorkerGroups();
        this.props.fetchWorkPhaseDetails();
        this.props.fetchWorkcardOptions(moduleSettings.additionalParams.workers);
        this.props.getWorkCardColors();

        const defaultStatus = data.workstatus?.options?.length > 0 ? data.workstatus.options[0] : 1;
        this.setState({ workstatus: this.state.workstatus ? this.state.workstatus : defaultStatus })
    }

    componentDidUpdate(prevProps) {
        const { data, status } = this.props;
        const defaultStatus = data.workstatus?.options?.length > 0 ? data.workstatus.options[0] : 1;

        if (prevProps.data !== data) {
            Object.keys(data).forEach(key => {
                this.setState(state => ({
                    [key]: key === 'id'
                        ? data[key]
                        : key === 'workstatus' && !state[key]
                            ? defaultStatus
                            : state[key] ? state[key] : data[key].value 
                }));
            });
        }

        if (status === 'error' && this.state.isSubmitted) {
            this.setState({ isSubmitted: false });

            this.props.setApiCallFulfilled();
        }
    }


    toggleEdit = () => {
        this.setState(state => ({
            edit: !state.edit
        }))
    }


    handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value,
            hasChanged: true
        })
    }

    handleSelect = (value, actionMeta) => {
        const key = actionMeta.name;
        const selectedValue = HandleSelectOption(key, value);

        if (key === 'faulttype') {
            this.setState(state => ({
                [key]: selectedValue,
                'faultreason': null,
                'faultlocation': null,
                'cause': null,
                'avoidoffault': null,
                hasChanged: true
            }));
        } else if (key === 'workergroups' && selectedValue && selectedValue.length > 0) {
            const oneWorkerPerPhase = this.props.oneWorkerPerPhase;
            const workerGroupIds = selectedValue.map(selectedValue => selectedValue.id);
            const selectableWorkers = this.props.data.workers.options.filter(worker => containsAny(worker.personGroupIds, workerGroupIds));
            const selectableWorkerIds = selectableWorkers.map(selectedValue => selectedValue.id);
            let selectedWorkers = oneWorkerPerPhase ? null : [];

            const _w = this.state.workers;
            let workers = [];

            // Workers can be an array of Ids or a single object based on AllowOneWorkerPerPhase setting
            if (Array.isArray(_w)) {
                workers = _w;
            }
            else if (typeof _w === 'object' && _w !== null) {
                workers = [_w];
            }

            if (workers && selectableWorkerIds.length > 0) {
                if (oneWorkerPerPhase) {
                    selectedWorkers = workers.find(worker => selectableWorkerIds.includes(worker?.id));
                }
                else {
                    selectedWorkers = workers.filter(worker => selectableWorkerIds.includes(worker.id))
                }
            }

            this.setState({
                [key]: selectedValue,
                workers: selectedWorkers,
                hasChanged:true
            });
        } else {
            this.setState({
                [key]: selectedValue,
                hasChanged: true
            });
        }
    }

    handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        this.setState({
            [dateInput.name]: dateInput.value
        });
    }

    handleSubmit = (saveAndCopy: boolean = false) => {
        const { data, noDateValidation, wcData, wpExtraDatas, alternativeDateValidation } = this.props;
        const { isSubmitted } = this.state;

        if (isSubmitted) {
            return;
        }

        Object.keys(data).map(key => (key !== 'id'
            ? data[key].required = this.getRequiredValue(data[key], data[key].settingKey)
            : key
        ));

        if (!ValidateForm(data, this.state)) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });

            this.setState({ invalidFields: true })
            return;
        } else {
            this.setState({ invalidFields: false })
        }

        let dateValidationMsg = '';

        const workBegin = parsePayloadDateTime(this.state['workbegin']);
        const workEnded = parsePayloadDateTime(this.state['workended']);

        if (!noDateValidation && !alternativeDateValidation) {
            if (workEnded && workBegin && (workEnded < workBegin)) {
                dateValidationMsg = dateValidationMsg + i18n.t('WORKENDED_LESS_THAN_WORKBEGIN') + ' ';
            }

            if (wcData.workCanBegin && workBegin && (workBegin < wcData.workCanBegin)) {
                dateValidationMsg = dateValidationMsg + i18n.t('WORKBEGIN_LESS_THAN_WORKCANBEGIN') + ' (' + i18n.t('WORKCARD') + ') ' + ' ';
            }
        }

        if (dateValidationMsg) {
            toast.error(dateValidationMsg, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });

            return;
        }

        const wcId = parseInt(this.props.match.params.workCardId, 10);
        const { invalidFields, edit, id, ...rest } = this.state;
        const { wpId } = this.props;
        const workPhaseState = { ...rest };
        let workPhaseData = {};

        let wpDetails = [];
        let extraDatas = [];

        Object.keys(workPhaseState).forEach(key => {
            if (data[key]?.type === 'phasedetail') {
                if (Array.isArray(workPhaseState[key]) && workPhaseState[key].length > 0) {
                    workPhaseState[key].forEach(detail => {
                        wpDetails.push({ id: detail.id || detail.value, value: detail.label, group: key, parentId: detail.parentId });
                    });
                } else if (workPhaseState[key] && (workPhaseState[key].id >= 0 || workPhaseState[key].value >= 0)) {
                    wpDetails.push({ id: workPhaseState[key].id || workPhaseState[key].value, value: workPhaseState[key].label, group: key, parentId: workPhaseState[key].parentId });
                }
            } if (data[key]?.type === 'extradata') {
                const existingExtraData = wpExtraDatas?.find(extraData => extraData.group === key);
                let extraData = {
                    value: workPhaseState[key] || '',
                    group: existingExtraData ? existingExtraData.group : key
                }
                if (existingExtraData) {
                    extraData['id'] = existingExtraData.id
                }
                extraDatas.push(extraData)
            } else {
                workPhaseData[key] = (key === 'workers' && !Array.isArray(workPhaseState[key]) && workPhaseState[key])
                    ? [workPhaseState[key]]
                    : (key === 'workers' && workPhaseState['workers'] === null) ? []
                        : workPhaseState[key]
}
        });

        workPhaseData['details'] = wpDetails;

        workPhaseData = PropertyNamesToCamelCase(workPhaseData);
        Object.assign(workPhaseData, { workCardId: wcId });

        Object.keys(workPhaseData).forEach(key => {
            if (key === 'orderDate'
                || key === 'workBegin'
                || key === 'workCanBegin'
                || key === 'workCanEnd'
                || key === 'workEnded'
                || key === 'paused'
            ) {
                workPhaseData[key] = parsePayloadDateTime(workPhaseData[key]);
            }

        });

        this.setState({ isSubmitted: true });

        if (this.props.match.path === '/workcard/:workCardId/workphase/new') {
            this.props.addWorkPhase(workPhaseData, false, extraDatas, this.props.history.replace, saveAndCopy);
        } else if (this.props.match.path === '/workcard/:workCardId/workphase/:workPhaseId/edit') {
            this.props.updateWorkPhase(wpId, workPhaseData, extraDatas, this.props.history.replace, saveAndCopy);
        }

        // Reset workers and submit status if save and copy action was called
        if (saveAndCopy) {
            this.setState({
                workers: null,
                isSubmitted: false
            });
            if (this.props.match.path === '/workcard/:workCardId/workphase/:workPhaseId/edit') {
                this.props.history.replace(`/workcard/${wcId}/workphase/new`);
            }
        }
    }

    getRequiredValue = (prop, settingKey) => {
        const { fields } = this.props;

        if (settingKey) {
            const viewSetting = fields.find(type => type.field === settingKey)

            if (viewSetting && this.state.workstatus) {
                return checkRequired(viewSetting, this.state.workstatus);
            }
        }
        return false;
    }

    isInvalid = val => {
        if (this.state.invalidFields) {
            return (Array.isArray(val) && (val.length === 0 || val.length === 1 && val[0].id === -1) || !val)
        }
        return false;
    }

    getDetailOptions = (key, detailOpts) => {
        const parent = this.state['faulttype']
            && (key === 'faultreason'
                || key === 'faultlocation'
                || key === 'cause'
                || key === 'avoidoffault') ? 'faulttype'
            : this.state['workcard_tyotyyppi'] && key === 'workcard_tyokokonaisuus'
                ? 'workcard_tyotyyppi'
                : '';

        return filterDetailOptions(detailOpts, this.state[parent]);
    }

    filterWorkerOptions = workerOpts => {
        if (this.state.workergroups && this.state.workergroups.length > 0) {
            const workerGroupIds = this.state.workergroups.map(workerGroup => workerGroup.id);
            return workerOpts.filter(worker => containsAny(worker.personGroupIds, workerGroupIds));
        }

        return workerOpts;
    }

    saveAndCopy = () => {
        this.handleSubmit(true);
    }

    getBackButtonConfig = (): { action: (params?) => void, params?: { mainEditView?: boolean; } } => {
        return { action: this.props.history.goBack, params: {mainEditView: true}};
    }

    render() {
        const { wcCode, data, wpColors, wpId, location, history } = this.props;
        const { isSubmitted, isLoading } = this.state;

        const sceneData = {
            view: moduleSettings.name,
            title: wcCode + ' > ' + (wpId
                ? (data.procedure && data.procedure.value) || i18n.t('WORKPHASE') + ' #' + data.id
                : i18n.t('NEW_WORKPHASE')),
            location: location,
            history: history,
            itemColors: wpColors,
            backAction: this.getBackButtonConfig(),
            hasChanged: this.state.hasChanged,
            splitButtonMenu: [{
                icon: actionIcons.SAVE,
                label: i18n.t('SAVE_AND_COPY'),
                action: this.saveAndCopy
            }]
        }

        const viewAction = {
            icon: 'save',
            label: '',
            clickFn: this.handleSubmit,
            isActionFn: true,
            paClass: 'start-phase'
        }

        return (
            <div>
                <NavigationBar
                    currentView={sceneData}
                    navHistory={this.props}
                    viewAction={viewAction}
                    popoverData={''}
                />
                <Loader ready={!isLoading && !isSubmitted} loadingText={i18n.t(isLoading ? 'LOADING' : 'SAVING')} />
                <Container>
                    <WorkPhaseForm
                        phase={data}
                        workPhaseState={this.state}
                        handleInputChange={this.handleInputChange}
                        handleDateChange={this.handleDateTime}
                        handleSelect={this.handleSelect}
                        getRequiredValue={this.getRequiredValue}
                        isInvalid={this.isInvalid}
                        filterDetailOptions={this.getDetailOptions}
                        filterWorkerOptions={this.filterWorkerOptions}
                    />
                </Container>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    addWorkPhase,
    fetchWorkCard,
    updateWorkPhase,
    fetchViewSettings,
    fetchWorkcardOptions,
    setApiCallFulfilled,
    fetchWorkPhaseDetails,
    fetchWorkerGroups,
    getWorkCardColors
}, dispatch);

const mapStateToProps = (state, ownProps) => {
    const isOffline = !state.offline.online;
    const wcId = ownProps.match.params.workCardId
    const wpId = ownProps.match.params.workPhaseId
    const wc = !isOffline
        ? state.workcards.workcards.find(wc => wc.id === parseInt(wcId, 10))
        : state.workcards.storedWorkCards.find(wc => wc.id === parseInt(wcId, 10));
    const fields = state.workcards.viewSettings && state.workcards.viewSettings.workcardphase;
    const { workerOptions } = state.workcards.options;
    const reportReady = ownProps.location.state && ownProps.location.state.reportReady;
    const { AllowOneWorkerPerPhase, MultiSelectWorkCardDetail, WorkCardPhaseCalculationType } = state.settings && state.settings.noviConfigs;
    const { statusTypes } = state.settings;
    const workerGroups = state.settings.workerGroups.map(wGroup => ({ id: wGroup.id, label: wGroup.name }));
    const { userId } = state.settings;
    const phaseDetails = state.workcards.phaseDetails ? [...state.workcards.phaseDetails] : [];

    // Copy phase -> Use old values as default values
    const defaultValues = ownProps?.location?.state?.defaultValues ?? null;

    let wp;

    if (defaultValues) {
        wp = {};
        Object.entries(defaultValues).forEach(([ key, val ]) => {
            if (key !== "id")
                wp[key] = val;
        });

        wp.id = wc?.phases?.length > 0
            ? Math.max(...wc.phases.map(wp => wp.id)) + 1
            : 1;
        wp.workers = [];
    }
    else if (wc) {
        wp = wc.phases.find(wp => wp.id === parseInt(wpId, 10));
    }

    const isMultiSelectDetail = MultiSelectWorkCardDetail === 'True';
    const oneWorkerPerPhase = AllowOneWorkerPerPhase?.toLowerCase() === 'true';


    let statusTypeOptions = [];
    statusTypes.forEach(statusType => {
        let sType = { ...statusType };

        statusTypeOptions.push(sType);
    })

    let optionLists = { ...state.workcards.options };
    optionLists.workerGroups = workerGroups;
    optionLists.statusOptions = statusTypeOptions;
    optionLists.workerOptions = workerOptions;

    const { NoDateValidation, AlternativeDateValidation } = state.settings.noviConfigs;

    let workphaseData = {
        id: typeof wp !== 'undefined'
            ? wp.id
            : wc && wc.phases && wc.phases.length > 0
                ? Math.max(...wc.phases.map(wp => wp.id)) + 1
                : 1
    };

    if (fields && !wp) {
        wp = {};
        fields.forEach(fieldItem => {
            wp[fieldItem.field] = '';
        })
    }

    let tempWp = {};
    Object.keys(wp).forEach(key => {
        tempWp[key.toLowerCase()] = wp[key];
    });

    const generateFormElementData = function (fields, column = '', item, dataPrototype, dataType) {
        const fieldsData = [...fields];
        fieldsData.sort((a, b) => a['tabOrder'] - b['tabOrder']);

        fieldsData.forEach(fieldItem => {
            const { field, type, translationKey, values } = fieldItem;

            if (field && type) {
                const fieldProp = GetProperty(field);
                const key = fieldProp;
                const isFieldMultiSelect = type === 'multidetail';

                item[key] = {
                    label: i18n.t(translationKey),
                    value: type === 'phasedetail' || type === 'multidetail'
                        ? GetDetail(wp.details, field, isFieldMultiSelect)
                        : type === 'thirdparty'
                            ? []
                            : GetFormInputValueByField(
                                dataPrototype[fieldProp],
                                type,
                                field,
                                ownProps.location,
                                state.settings.noviConfigs,
                                optionLists,
                                userId,
                                null,
                                dataType,
                                wp.extraDatas,
                                false,
                                [],
                                null,
                                null
                            ),
                    options: GetOptionsDataByField(field, type, values, optionLists, phaseDetails, dataType, isMultiSelectDetail),
                    type: type === 'worker' && oneWorkerPerPhase && dataType === 'workphase'
                        ? 'select'
                        : GetType(field, type),
                    onChangeType: GetOnChangeType(type, key, isFieldMultiSelect),
                    column: column,
                    settingKey: field,
                    isDisabled: field === 'orderer' && type === 'label'
                        ? true
                        : WorkCardPhaseCalculationType === '1' && field === 'workbegin'
                            ? true
                            : WorkCardPhaseCalculationType === '2' && field === 'workended'
                                ? true
                                : false
                }
            }
        });
        return fieldsData;
    }

    const formFieldSets = {
        workphase: fields ? generateFormElementData(fields, 'workphase', workphaseData, tempWp, 'workphase') : null
    }

    const wpColorConfigs = {
        colors: state.settings.colors,
        settings: state.settings.colorSettings,
        config: state.settings.colorConfig['FillPhaseTags']
    }

    return {
        wcCode: wc && wc.code,
        wpExtraDatas: wp.extraDatas,
        data: workphaseData,
        noDateValidation: NoDateValidation === 'True',
        alternativeDateValidation: AlternativeDateValidation === 'True',
        wpId: (wp && wp.id) || null,
        wpColors: wp && wp.id ? DefineColors(wp, wpColorConfigs) : null,
        fields: formFieldSets.workphase,
        wcData: wc,
        status: state.workcards.status,
        oneWorkerPerPhase: oneWorkerPerPhase
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWorkPhase);