import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDown,
    faAngleUp,
    faTimesCircle,
    faCheckCircle,
    faCheck,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

interface State {
    open: any;
}

interface Props {
    id: number;
    title: any;
    panelIcons?: any;
    subTitle?: any;
    itemCount?: React.ReactNode;
    customClass?: string;
    noIconsInUse?: boolean;
}

// Icons map
const iconsList = {
    'check': <FontAwesomeIcon icon={faCheckCircle} size="lg" className="icon-success" />,
    'uncheck': <FontAwesomeIcon icon={faTimesCircle} size="lg" className="icon-warning" />,
    'done': <FontAwesomeIcon icon={faCheck} size="lg" className="icon-success" />,
    'skipped': <FontAwesomeIcon icon={faTimes} size="lg" className="icon-warning" />,
    'notdone': <></>,
    'disabled_done': <FontAwesomeIcon icon={faCheck} size="lg" className="icon-disabled" />,
    'disabled_skipped': <FontAwesomeIcon icon={faTimes} size="lg" className="icon-disabled" />
}

class PanelContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            open: []
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(i) {
        let newOpen = [...this.state.open];
        newOpen[i] = !this.state.open[i];
        this.setState({
            open: newOpen
        });
    }

    // Returns icons with given icon type and possible functionality.
    getIcons = (icons) => {
        return (
            <div>
                {Object.keys(icons).map(key => (
                    <span key={key}>{iconsList[icons[key].icon]}</span>
                ))}
            </div>
        );
    }

    render() {
        const { id, title, subTitle = '', children, panelIcons = [], itemCount = null, noIconsInUse = false } = this.props;
        const { open } = this.state;

        let angleIcon = [];

        open.map((open, i) => (
            angleIcon[i] = open
                ? <FontAwesomeIcon icon={faAngleUp} />
                : <FontAwesomeIcon icon={faAngleDown} />
        ));

        let heading = (
            <Accordion.Header>
                {panelIcons.length > 0
                    ? <Row className="route-workcard-panel-container" style={{ width: "100%" }}>
                        <Col xs={2} className="panel-short-actions v-align-center">
                            {/*TODO: panelIcon actions to outside of the <Panel.Toggle /> element*/}
                            {panelIcons.length > 0 && this.getIcons(panelIcons)}
                        </Col>
                        <Col xs={10}>
                            {subTitle
                                ? <div className="titles-container">
                                    <span className="title">{title}</span>
                                    <span className="sub-title">{subTitle}</span>
                                </div>
                                : <div style={{ whiteSpace: 'break-spaces' }}>{title}</div>
                            }
                        </Col>
                    </Row>
                    : <div className="accordion-title">
                        <div>{title}</div>
                        <div>
                            {itemCount !== null && itemCount >= 0 && <span className="number-badge">
                                {itemCount}
                            </span>}
                        </div>
                    </div>}
            </Accordion.Header>
        );

        return (
            <Accordion.Item
                eventKey={id.toString()}
                className={(panelIcons.length > 0 || noIconsInUse) ? 'unstyled-panel' : ''}
            >
                {heading}
                <Accordion.Body
                        onClick={() => this.handleToggle(id)}
                    >
                        <div className="padding0">
                            {children}
                        </div>
                </Accordion.Body>
            </Accordion.Item>
        );
    }
}

export default PanelContainer;