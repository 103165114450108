import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import i18n from '../../translations/i18n';
import PopoverAction from '../PopoverAction';

const PanelRow = (props: IProps) => {

    const contentLeft = (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )

    const menu = (
        <Popover id="settings-menu-popover" className="panel-menu-popover">
            {props.openItem && <div className="sub-section">
                <div className="setting-option">
                    <div>
                        <PopoverAction
                            icon="view"
                            label={i18n.t('OPEN')}
                            onClick={props.openItem}
                        />
                    </div>
                </div>
            </div>}
            {props.editItem && <div className="sub-section">
                <div className="setting-option">
                    <div>
                        <PopoverAction
                            icon="edit"
                            label={i18n.t('EDIT')}
                            onClick={props.editItem}
                        />
                    </div>
                </div>
            </div>}
        </Popover>
    )

    const menuContainer = (
        <div className="options-container">
            <span className="bar" />
            <OverlayTrigger trigger="click" placement="left" overlay={menu} rootClose>
                <span className="options-button" ><FontAwesomeIcon icon={faEllipsisV} size="lg" /></span>
            </OverlayTrigger>
        </div>
    )

    return (
        <Row className="sub-item-container">
            <Col xs={10}>
                {contentLeft}
            </Col>
            <Col xs={2}>
                {menuContainer}
            </Col>
        </Row>
    )
}



interface IProps {
    children: React.ReactNode;
    openItem?: () => void;
    editItem?: () => void;
}

export default PanelRow;