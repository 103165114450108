import React from 'react'
import { Accordion } from 'react-bootstrap'
import PanelContainer from '../../work-schedule/work-card/components/PanelContainer'
import { useEffect, useState } from 'react'
import noviAPI from '../../../api/noviAPI'
import i18n from '../../../translations/i18n'
import { getStatusLabel } from '../utils'
import { useParams } from 'react-router-dom'
import { getDateTime } from 'utils'

type UrlParams = { id: string; }

const HourCardPanelGroup = () => {
    const [logData, setLogData] = useState([]);
    const { id } = useParams<UrlParams>(); // We can take hour card id from the URL using useParams.
    const tableHeaders = ['DATE', 'PERSON', 'HC_STATUS_LOG_OLD_STATUS', 'HC_STATUS_LOG_NEW_STATUS'];

    useEffect(() => {
        noviAPI.hourCardStatusLogs.fetchByHourCard(Number(id))
            .then(response => {
                setLogData(response.data);
            });
    }, []);

    return (
        <div id="panel-group-wrapper">
            {/* Hour card status log  */}
            <Accordion
                id="panel-with-sub-panels"
                className="margin-top-15 novi-panel"
            >
                <PanelContainer
                    id={2}
                    title={i18n.t('HOUR_CARD_STATUS_LOG')}
                    panelIcons={[]}
                    subTitle=""
                    itemCount={logData.length}
                >
                    {logData.length > 0 && <table className={"spare-part-machines-table"}>
                        <thead>
                            <tr style={{ backgroundColor: '#181818' }}>
                                {tableHeaders.map((header, i) => (
                                    <th key={header}>{i18n.t(header)}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {logData.map((log, i) => (
                                <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '2f2f2f' : '#272727' }}>
                                    <td>{getDateTime(log.date)}</td>
                                    <td>{log.personName}</td>
                                    <td>{getStatusLabel(log.oldStatusId)}</td>
                                    <td>{getStatusLabel(log.newStatusId)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </PanelContainer>
            </Accordion>
        </div>
    )
}

export default HourCardPanelGroup
