import React from 'react';
import FormElement from './FormElement';

const WorkCardForm = ({
    wcData,
    wcState,
    column,
    match,
    handleInputChange,
    handleDateChange,
    handleSelect,
    handleScan,
    handleCreateOption,
    toggleMachines,
    getRequiredValue,
    isInvalid,
    getDetailOptions,
    filterWorkerOptions,
    notificationTargets,
    filterArchivedCostPools,
    toggleQrReader,
    qrReading
}) => {
    return (
        <div>
            {Object.keys(wcData)
                .filter(key => wcData[key].column === column && (key !== 'code' || (key === 'code' && !(match.path.includes('/workcard/new')))))
                .map(key => {
                    wcData[key].required = getRequiredValue(wcData[key], wcData[key].settingKey, wcData[key].isDisabled);
                    const isMulti = wcData[key].type === 'multidetail';

                    return (
                        <div key={key} data-cy={key} className={wcData[key].required && isInvalid(wcState[key], key) ? 'invalid-field' : ''}>
                            <FormElement
                                key={key}
                                name={key}
                                type={isMulti
                                    ? 'worker'
                                    : wcData[key].type === 'notificationtarget'
                                        ? 'tag-select'
                                        : wcData[key].settingKey === 'machinehalt'
                                            ? 'select-choice'
                                            : wcData[key].type === 'machinethirdparty'
                                                ? 'label'
                                                : wcData[key].type === 'extradatanumber'
                                                    ? 'number'
                                                    : wcData[key].type
                                }
                                label={wcData[key].label}
                                value={wcState[key]}
                                options={wcData[key].type === 'detail' || wcData[key].type === 'multidetail'
                                    ? getDetailOptions(key, wcData[key].options)
                                    : key === 'notificationtarget'
                                        ? notificationTargets
                                        : key === 'workers'
                                            ? filterWorkerOptions(wcData[key].options)
                                            : key.startsWith('costpoolgroup_')
                                                ? filterArchivedCostPools(wcData[key].options, wcData[key].value)
                                                : wcData[key].options
                                }
                                onChange={
                                    wcData[key].onChangeType === 'date-input'
                                        ? handleDateChange
                                        : (wcData[key].onChangeType === 'select' || wcData[key].onChangeType === 'tag-select')
                                            ? handleSelect
                                            : key === 'machine'
                                                ? toggleMachines
                                                : handleInputChange
                                }
                                onCreate={handleCreateOption}
                                extraFunctions={
                                    {
                                        QR: {
                                            enabled: true,
                                            handleChange: toggleQrReader,
                                            state: qrReading,
                                            scanData: handleScan
                                        }
                                    }
                                }
                                required={wcData[key].required}
                                isDisabled={wcData[key].isDisabled}
                            />
                        </div>
                    )
                })}
        </div>
    );
}

export default WorkCardForm;