import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import workcardsReducer from '../reducers/workcardsReducer';
import machineReducer from '../reducers/machineReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import maintenanceReducer from '../reducers/maintenanceReducer';
import settingsReducer from '../reducers/settingsReducer';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults/index';
import warehouseReducer from '../reducers/warehouseReducer';
import documentReducer from '../reducers/documentReducer';
import hourcardsReducer from '../reducers/hourcardsReducer';
import navigationReducer from '../reducers/navigationReducer';
import { effect, discard, queue } from './offlineConfig';
import scannersReducer from '../reducers/scannersReducer';
import costpoolsReducer from '../reducers/costpoolsReducer';
import safetyTypesReducer from '../reducers/safetyTypesReducer';
import { loadUser, reducer as oidcReducer } from 'redux-oidc';
import userManager from './userManager';

const persistConfig = {
    key: 'novi',
    storage
};

const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore
} = createOffline({
    ...offlineConfig,
    discard,
    effect,
    persist: false,
    queue,
    returnPromises: true
});

const rootReducer = combineReducers({
    workcards: workcardsReducer,
    machines: machineReducer,
    dashboard: dashboardReducer,
    maintenances: maintenanceReducer,
    settings: settingsReducer,
    warehouse: warehouseReducer,
    documents: documentReducer,
    scanners: scannersReducer,
    costpools: costpoolsReducer,
    hourcards: hourcardsReducer,
    navigation: navigationReducer,
    safetycards: safetyTypesReducer,
    oidc: oidcReducer
});

const persistedReducer = persistReducer(
    persistConfig,
    offlineEnhanceReducer(rootReducer)
);

const middleware = [
    thunk,
    promise(),
    offlineMiddleware
];

if (process.env.NODE_ENV !== `production` && process.env.NODE_ENV !== "test") {
    const createLogger = require('redux-logger').default;
    middleware.push(createLogger);
}

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

function configureStore() {
    const store = createStore(
        persistedReducer,
        composeEnhancers(
            offlineEnhanceStore,
            applyMiddleware(...middleware)
        )
    );
    const persistor = persistStore(store);
    loadUser(store, userManager);

    return { persistor, store };
}

const { store, persistor } = configureStore();

export { persistor, configureStore };
export default store;
