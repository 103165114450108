import * as types from '../config/actionTypes';
import { Toaster } from '../components/HelperFunctions';

export const workListFilters: IWorkListFilters = {
    searchString: '',
    startDate: '',
    endDate: '',
    detailIds: [],
    workCardCode: '',
    machineCode: '',
    ordererIds: [],
    workerIds: [],
    workerGroupIds: [],
    workStatuses: [],
    workCardTypes: [],
    machineIds: [],
    hierarchyItemIds: [],
    urgencies: [],
    machineHalt: null,
    extraDatas: {},
};

const initialState = {
    status: '',
    workcardsStatus: '',
    wcStatus: '',
    error: null,
    workcards: [],
    storedWorkCards: [],
    materials: [],
    hourCards: [],
    storedMaterials: [],
    workPermits: [],
    permits: [],
    storedPermits: [],
    settings: {
        cardlayout: true,
        displayphases: false,
        pageNumber: 1,
        pageSize: 5,
        useDefaultSearch: true
    },
    workListDisplayInfo: {
        totalResultCount: 0,
        totalPageCount: 0,
    },
    routephases: [],
    viewSettings: {
        faultnotice: [],
        workcard: [],
        workcardreport: [],
        workcardphase: [],
        workschedulegrid: [],
        material: [],
        workcardadd: [],
        workcardaddreport: [],
        workcardmaterialorder: []
    },
    requiredFields: {},
    currentFilters: workListFilters,
    options: {
        workStatusOptions: [],
        workTypeOptions: [],
        urgencyOptions: [],
        workerOptions: [],
        ordererOptions: [],
        workCardTypeOptions: [],
        investmentCodeOptions: []
    },
    // TODO: Initialize workcardsSettings properly with default values.
    workcardsSettings: {},
    details: [],
    phaseDetails: [],
    limitedWorkStatuses: {
        allowedworkstatus: [],
        allowededitworkstatus: []
    },
    workCardMeasurements: [],
    purchaseOrderItems: [],
    faultNoticeDefaultText: null
};

const workcardsReducer = (state: IWorkCardState = initialState, action)/*: IWorkCardState*/ => {
    let storedWcs = [];

    function pushToArray(arr, obj) {
        const index = arr.findIndex((e) => e.id === obj.id);

        if (index === -1) {
            arr.push(obj);
        } else {
            arr[index] = obj;
        }

        return arr;
    }

    function userIsWorker(item, userId, userGroups = null) {
        if ((item.workerGroups && userGroups && item.workerGroups
            .find(wGroup => userGroups
                .find(userGroup => parseInt(userGroup.id, 10) === parseInt(wGroup.id, 10))))
            || (item.workers && item.workers.find(w => parseInt(w.id, 10) === parseInt(userId, 10)))) {
            return true;
        }

        return false;
    }

    switch (action.type) {
        case `${types.FETCH_ROUTE_PHASES}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        //TODO: remove route phases fetch -> route phases can be accessed directly from routeworkcard [waiting implementation]
        case `${types.FETCH_ROUTE_PHASES}_FULFILLED`:
            let phases = [];

            if (action.payload.routecardphases) {
                action.payload.routecardphases.forEach(function (rPhase) {
                    const item = {
                        id: rPhase.id,
                        rmWorkCardId: action.payload.cardId,
                        machine: rPhase.machine,
                        machineId: rPhase.machineId,
                        description: rPhase.description,
                        isDone: rPhase.isDone.toString(),
                        comment: rPhase.comment,
                        doneDate: rPhase.doneDate,
                        workHours: rPhase.workHours,
                        dataType: rPhase.dataType
                    }
                    phases.push(item);
                });
            }

            return {
                ...state,
                status: 'fulfilled',
                routephases: phases
            }
        case `${types.FETCH_ROUTE_PHASES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_WORK_CARDS}_PENDING`:
        case `${types.FETCH_WORKCARDS}_PENDING`:
            return {
                ...state,
                workcardsStatus: 'pending'
            }
        case `${types.FETCH_WORKCARDS}_FULFILLED`:
            storedWcs = [...state.storedWorkCards] || [];

            /* 
               Workcard will be stored for offline use if: 
               current user id exists in workcard's workers 
               or user's workergroup matches with workcard's workergroup 
            */
            const workcards = action.payload.payloadData.results || [];

            workcards.forEach(workCard => {
                const isWorker = userIsWorker(workCard, action.payload.user, action.payload.userGroups);
                const isWorkerInWp = workCard.phases && workCard.phases.find(wcPhase => userIsWorker(wcPhase, action.payload.user))
                    ? true
                    : false;
                if (isWorker || isWorkerInWp) {
                    storedWcs = pushToArray(storedWcs, workCard);
                }
            });

            return {
                ...state,
                workcardsStatus: 'fulfilled',
                workcards: workcards,
                startIndex: action.payload.startIndex,
                endIndex: action.payload.endIndex,
                totalResultCount: action.payload.payloadData.totalResultCount,
                totalPageCount: action.payload.payloadData.totalPageCount,
                pageNumber: action.payload.pageNumber,
                isSqlSearch: action.payload.isSqlSearch,
                isSavedSearch: action.payload.isSavedSearch,
                storedWorkCards: storedWcs,
                currentFilters: action.payload.filters
            }
        case `${types.FETCH_WORK_CARDS}_REJECTED`:
        case `${types.FETCH_WORKCARDS}_REJECTED`:
            return {
                ...state,
                workcardsStatus: 'error',
                error: action.payload,
            }
        case `${types.FETCH_WORK_CARDS}_FULFILLED`:
            return {
                ...state,
                workcardsStatus: 'fulfilled',
                workcards: action.payload.results,
                workListDisplayInfo: {
                    totalResultCount: action.payload.totalResultCount,
                    totalPageCount: action.payload.totalPageCount
                }
            }
        case `${types.FETCH_WORKCARD}_PENDING`:
            return {
                ...state,
                wcStatus: 'pending'
            }
        case `${types.FETCH_WORKCARD}_FULFILLED`:
            if (state.workcards.some(wc => wc.id === action.payload.id)) {
                state.workcards = state.workcards.filter((wc) => {
                    return wc.id !== action.payload.id
                });
            }
            return {
                ...state,
                wcStatus: 'fulfilled',
                workcards: [...state.workcards, action.payload.data]
            }
        case `${types.FETCH_WORKCARD}_REJECTED`:
            return {
                ...state,
                wcStatus: 'error'
            }
        case `${types.FETCH_WORK_CARD_HOUR_CARDS}`:
            return {
                ...state,
                hourCards: action.payload
            }
        case types.SAVE_WORK_LIST_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload
                }
            }
        case types.SET_WORK_LIST_FILTERS:
            return {
                ...state,
                currentFilters: action.payload
            }
        case types.RESET_WORKCARD_CURRENTFILTERS: {
            return {
                ...state,
                currentFilters: initialState.currentFilters
            }
        }
        case types.ADD_WORKCARD:
            return {
                ...state,
                workcards: [
                    ...state.workcards,
                    action.payload.data
                ],
                storedWorkCards: [
                    ...state.storedWorkCards,
                    action.payload.data
                ]
            }
        case `${types.ADD_WORKCARD}_COMMIT`:
            return {
                ...state,
                status: 'fulfilled',
                workcards: state.workcards.map(wc => {
                    if (wc && wc.id === action.meta.tempId) {
                        return {
                            ...wc,
                            // Replace the temporary ID with the new ID from API response
                            id: action.payload.data
                        }
                    }
                    return wc;
                }),
                storedWorkCards: state.storedWorkCards.map(wc => {
                    if (wc.id === action.meta.tempId) {
                        return {
                            ...wc,
                            id: action.payload.data
                        }
                    }
                    return wc;
                })
            }
        case `${types.ADD_WORKCARD}_ROLLBACK`:
            return {
                ...state,
                status: 'fulfilled',
                workcards: state.workcards.filter(wc => wc.id !== action.meta.tempId),
                storedWorkCards: state.storedWorkCards.filter(wc => wc.id !== action.meta.tempId)
            }
        case types.UPDATE_WORKCARD:
            const wcId = parseInt(action.payload.id, 10);
            if (action.isOfflineArchived) {
                return {
                    ...state,
                    workcards: state.workcards.filter(({ id }) => id !== wcId),
                    storedWorkCards: state.storedWorkCards.filter(({ id }) => id !== wcId)
                }
            } else {
                const updatedWcs = state.workcards.map(wc => {
                    if (wc.id !== wcId) {
                        return wc
                    }

                    return {
                        ...wc,
                        ...action.payload.data
                    }
                });

                const updatedStoredWcs = state.storedWorkCards.map(storedWc => {
                    if (storedWc.id !== wcId) {
                        return storedWc
                    }
                    return {
                        ...storedWc,
                        ...action.payload.data
                    }
                });

                return {
                    ...state,
                    workcards: updatedWcs,
                    storedWorkCards: updatedStoredWcs
                }
            }
        case `${types.UPDATE_WORKCARD}_COMMIT`:
            Toaster({ msg: 'WORKCARD_UPDATED', type: 'success' });

            return {
                ...state,
                status: 'fulfilled',
                workcards: state.workcards.filter(({ id }) => id !== wcId),
            }
        case `${types.UPDATE_WORKCARD}_ROLLBACK`:
            return {
                ...state
            }
        case types.UPDATE_ROUTE_WORKCARD:
            if (action.isOfflineArchived) {
                return {
                    ...state,
                    workcards: state.workcards.filter(({ id }) => id !== action.rWcId),
                    storedWorkCards: state.storedWorkCards.filter(({ id }) => id !== action.rWcId)
                }
            } else {
                //TODO: update route wc in workcards list
                //const updatedRouteWcs = state.workcards.map(wc => {
                //    if (wc.id !== parseInt(action.id, 10)) {
                //        return wc
                //    }
                //    return {
                //        ...wc,
                //        ...action.payload
                //    }
                //});
            }

            return {
                ...state
            }
        case `${types.ADD_WORKCARD}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case types.DELETE_WORKCARD:
            return {
                ...state,
                workcards: state.workcards.filter(({ id }) => id !== action.id),
                storedWorkCards: state.storedWorkCards.filter(({ id }) => id !== action.id)
            }
        case types.ADD_WORKPHASE:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.payload.workCardId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        phases: wc.phases.concat([action.payload])
                    }
                }),
                storedWorkCards: state.storedWorkCards.map(wc => {
                    if (wc.id !== parseInt(action.payload.workCardId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        phases: wc.phases.concat([action.payload])
                    }
                })
            }
        case `${types.ADD_WORKPHASE}_COMMIT`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.meta.workCardId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        phases: wc.phases.map(wp => {
                            if (wp.id !== action.meta.tempId) {
                                return wp;
                            }
                            return {
                                ...wp,
                                // Replace the temporary ID with the new ID from API response
                                id: action.payload.data,
                                /* action.meta.workCardId is set in queue.dequeue configuration 
                                to match API response of the work card. */
                                workCardId: action.meta.workCardId
                            }
                        })
                    }
                }),
                storedWorkCards: state.storedWorkCards.map(wc => {
                    if (wc.id !== parseInt(action.meta.workCardId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        phases: wc.phases.map(wp => {
                            if (wp.id !== action.meta.tempId) {
                                return wp;
                            }
                            return {
                                ...wp,
                                id: action.payload.data,
                                workCardId: action.meta.workCardId
                            }
                        })
                    }
                })
            }
        case `${types.ADD_WORKPHASE}_ROLLBACK`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== action.meta.workCardId) {
                        return wc;
                    }

                    return {
                        ...wc,
                        phases: wc.phases.filter(wp => wp.id !== action.meta.tempId)
                    }
                }),
                storedWorkCards: state.storedWorkCards.map(wc => {
                    if (wc.id !== action.meta.workCardId) {
                        return wc;
                    }

                    return {
                        ...wc,
                        phases: wc.phases.filter(wp => wp.id !== action.meta.tempId)
                    }
                })
            }
        case types.UPDATE_WORKPHASE:
            const updatedWps = state.workcards.map(wc => {
                if (wc.id !== parseInt(action.payload.workCardId, 10)) return wc;

                const wps = wc.phases.map(wp => {
                    if (wp.id !== action.id) return wp;

                    return {
                        ...wp,
                        ...action.payload
                    }
                })

                return {
                    ...wc,
                    phases: wps
                };
            });
            const updatedStoredWps = state.storedWorkCards.map(storedWc => {
                if (storedWc.id !== parseInt(action.payload.workCardId, 10)) return storedWc;

                const wps = storedWc.phases.map(wp => {
                    if (wp.id !== action.id) return wp;

                    return {
                        ...wp,
                        ...action.payload
                    }
                })

                return {
                    ...storedWc,
                    phases: wps
                };
            });

            return {
                ...state,
                workcards: updatedWps,
                storedWorkCards: updatedStoredWps
            }
        case types.DELETE_WORKPHASE:
            const deleteWorkPhase = (wcId: number, phaseId: number, workCards: IWorkCard[]) => {
                return workCards.map(wc => {
                    if (wc.id !== wcId) {
                        return wc;
                    }

                    return {
                        ...wc,
                        phases: wc.phases.filter(({ id }) => id !== phaseId)
                    }
                })
            }

            return {
                ...state,
                workcards: deleteWorkPhase(action.wcId, action.phaseId, state.workcards),
                storedWorkCards: deleteWorkPhase(action.wcId, action.phaseId, state.storedWorkCards)
            }
        case types.ADD_MATERIAL:
            return {
                ...state,
                materials: [
                    ...state.materials,
                    action.payload
                ],
                storedMaterials: [
                    ...state.storedMaterials,
                    action.payload
                ]
            }
        case `${types.ADD_MATERIAL}_COMMIT`:
            return {
                ...state,
                materials: state.materials.map(material => {
                    if (material.id === action.meta.tempId) {
                        const { id, ...rest } = material;
                        return {
                            ...rest,
                            id: action.payload.data
                        }
                    }
                    return material;
                }),
                storedMaterials: state.storedMaterials.map(material => {
                    if (material.id === action.meta.tempId) {
                        const { id, ...rest } = material;
                        return {
                            ...rest,
                            id: action.payload.data
                        }
                    }
                    return material;
                })
            }
        case `${types.ADD_MATERIAL}_ROLLBACK`:
            return {
                ...state,
                materials: state.materials.filter(material => material.id !== action.meta.tempId),
                storedMaterials: state.storedMaterials.filter(material => material.id !== action.meta.tempId)
            }
        case types.UPDATE_MATERIAL:
            return {
                ...state,
                materials: state.materials.map(material => {
                    if (material.id === parseInt(action.payload.id, 10)) {
                        return {
                            ...material,
                            ...action.payload
                        }
                    }
                    return material;
                }),
                storedMaterials: state.storedMaterials.map(material => {
                    if (material.id === parseInt(action.payload.id, 10)) {
                        return {
                            ...material,
                            ...action.payload
                        }
                    }
                    return material;
                })
            }
        case `${types.FETCH_WORKCARD_MATERIALS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKCARD_MATERIALS}_FULFILLED`:
            return {
                ...state,
                materials: action.payload
            }
        case `${types.FETCH_WORKCARD_MATERIALS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                materials: []
            }
        case `${types.FETCH_WORKCARD_PERMITS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKCARD_PERMITS}_FULFILLED`:
            const storedPermits = [...state.storedPermits] || [];

            return {
                ...state,
                status: 'fulfilled',
                permits: action.payload,
                storedPermits: storedPermits.filter(permit => !action.payload.find(newPermit => permit.id === newPermit.id)).concat(action.payload)
            }
        case `${types.FETCH_WORKCARD_EXTRA_DATAS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKCARD_EXTRA_DATAS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                extraDatas: action.payload
            }
        case `${types.FETCH_PERMIT_EXTRA_DATAS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_PERMIT_EXTRA_DATAS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                permitExtraDatas: action.payload
            }
        case `${types.FETCH_WORKCARD_VIEW_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKCARD_VIEW_SETTINGS}_FULFILLED`:
            const viewSettings = { ...state.viewSettings };

            Object.keys(viewSettings).forEach(key => {
                const viewSettingType = action.payload.type.find(type => type === key);

                if (viewSettingType && action.payload.viewSettings[viewSettingType]) {
                    viewSettings[viewSettingType] = action.payload.viewSettings[viewSettingType];
                }
            });

            return {
                ...state,
                status: 'fulfilled',
                viewSettings: viewSettings
            }
        case `${types.FETCH_WORKCARD_VIEW_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_PERMIT_VIEW_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_PERMIT_VIEW_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type[0]]: action.payload.viewSettings[action.payload.type[0]],
                }
            }
        case `${types.FETCH_PERMIT_VIEW_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case types.EDIT_PERMIT:
            const updatedPermits = state.permits.map(permit => {
                if (permit.id !== parseInt(action.payload.id, 10)) return permit;

                return {
                    ...permit,
                    ...action.payload
                }
            });

            const updatedStoredPermits = state.storedPermits.map(storedPermit => {
                if (storedPermit.id !== action.id) {
                    return storedPermit
                }
                return {
                    ...storedPermit,
                    ...action.payload
                }
            });

            return {
                ...state,
                permits: updatedPermits,
                storedPermits: updatedStoredPermits
            }
        //TODO: ADD_PERMIT, DELETE_PERMIT
        case types.DELETE_MATERIAL:
            return {
                ...state,
                materials: state.materials.filter(({ id }) => id !== action.payload.id),
                storedMaterials: state.storedMaterials.filter(({ id }) => id !== action.payload.id),
            }
        case `${types.FETCH_WORKPHASE_VIEW_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKPHASE_VIEW_SETTINGS}_FULFILLED`:

            return {
                ...state,
                status: 'fulfilled',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type[0]]: action.payload.viewSettings['workcardphase'],
                }
            }
        case `${types.FETCH_WORKPHASE_VIEW_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.GET_DOCUMENT}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.GET_DOCUMENT}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                document: {
                    data: action.payload.document,
                    url: action.payload.url
                }
            }
        case `${types.FETCH_WORKCARD_OPTIONS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKCARD_OPTIONS}_FULFILLED`:
            const options = Object.assign({}, state.options);
            Object.keys(options)
                .filter(key => action.payload[key])
                .forEach(key => {
                    options[key] = action.payload[key];
                });

            return {
                ...state,
                status: 'fulfilled',
                options
            }
        case `${types.FETCH_WORKCARD_OPTIONS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case types.SET_WORK_CARD_DETAILS:
            return {
                ...state,
                details: action.payload
            }
        case `${types.FETCH_WORKPHASE_DETAILS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKPHASE_DETAILS}_FULFILLED`:
            return {
                ...state,
                phaseDetails: action.payload,
                status: 'fulfilled'
            }
        case types.FETCH_PERMIT_DETAILS:
            return {
                ...state,
                permitDetails: action.payload
            }
        case types.SET_WORK_CARD_SETTINGS:
            return {
                ...state,
                workcardsSettings: action.payload
            }
        case `${types.FETCH_WORKLIST_GRID_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKLIST_GRID_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'pending',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type]: action.payload.gridSettings,
                }
            }
        case `${types.FETCH_WORKLIST_GRID_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_MATERIAL_VIEW_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MATERIAL_VIEW_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'pending',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type[0]]: action.payload.viewSettings['material'],
                }
            }
        case `${types.FETCH_MATERIAL_VIEW_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case types.ADD_WORKCARD_DOCUMENT:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== (parseInt(action.payload.targetId, 10))) {
                        return wc
                    }

                    return {
                        ...wc,
                        documentIds: wc.documentIds.concat([action.payload.id])
                    }
                }),
                storedWorkCards: state.storedWorkCards.map(wc => {
                    if (wc.id !== (parseInt(action.payload.targetId, 10))) {
                        return wc
                    }

                    return {
                        ...wc,
                        documentIds: wc.documentIds.concat([action.payload.id])
                    }
                })
            }
        case `${types.ADD_WORKCARD_DOCUMENT}_COMMIT`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    /* action.meta.targetId is set in queue.dequeue configuration
                    to match API response of the work card. */
                    if (wc.id === action.meta.targetId) {
                        return {
                            ...wc,
                            // Replace the temporary ID with the new ID from the API response of the document
                            documentIds: wc.documentIds.filter(id => id !== action.meta.tempId).concat(action.payload.data)
                        }
                    }
                    return wc;
                }),
                storedWorkCards: state.storedWorkCards.map(wc => {
                    if (wc.id === action.meta.targetId) {
                        return {
                            ...wc,
                            documentIds: wc.documentIds.filter(id => id !== action.meta.tempId).concat(action.payload.data)
                        }
                    }
                    return wc;
                })
            };
        case `${types.ADD_WORKCARD_DOCUMENT}_ROLLBACK`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id === action.meta.targetId) {
                        return {
                            ...wc,
                            documentIds: wc.documentIds.filter(id => id !== action.meta.tempId)
                        }
                    }
                    return wc;
                }),
                storedWorkCards: state.storedWorkCards.map(wc => {
                    if (wc.id === action.meta.targetId) {
                        return {
                            ...wc,
                            documentIds: wc.documentIds.filter(id => id !== action.meta.tempId)
                        }
                    }
                    return wc;
                })
            }
        case `${types.UPDATE_WORKCARDS}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.UPDATE_WORKCARDS}_FULFILLED`:
            const workCardsData = [...state.workcards] || [];
            const storedWcsData = [...state.storedWorkCards] || [];

            return {
                ...state,
                workcards: workCardsData.filter(wc => !action.payload.data.find(newWc => wc.id === newWc.id)).concat(action.payload.data),
                storedWorkCards: storedWcsData.filter(wc => !action.payload.data.find(newWc => wc.id === newWc.id)).concat(action.payload.data),
            }
        case `${types.UPDATE_WORKCARDS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case types.ADD_WC_EXTRA_DATA:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.payload.workCardId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        extraDatas: wc.extraDatas.concat([action.payload.data])
                    }
                })
            }

        case types.ADD_WC_NOTIFICATION_TARGET:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.payload.workCardId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        notificationTargets: wc.notificationTargets.concat([action.payload])
                    }
                })
            }

        case `${types.ADD_WC_NOTIFICATION_TARGET}_COMMIT`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.meta.wcId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        notificationTargets: wc.notificationTargets.map(nt => {
                            if (nt.id !== action.meta.tempId) {
                                return nt;
                            }

                            return {
                                ...nt,
                                // Replace the temporary ID with the new ID from API response
                                id: action.payload.data
                            }
                        })
                    }
                })
            }

        case `${types.ADD_WC_NOTIFICATION_TARGET}_ROLLBACK`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.meta.wcId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        notificationTargets: wc.notificationTargets.filter(notificationTarget => notificationTarget.id !== action.meta.tempId)
                    }
                })
            }

        case `${types.ADD_WC_EXTRA_DATA}_COMMIT`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.meta.wcId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        extraDatas: wc.extraDatas.map(wcExtraData => {
                            if (wcExtraData.id !== action.meta.tempId) {
                                return wcExtraData;
                            }

                            return {
                                ...wcExtraData,
                                // Replace the temporary ID with the new ID from API response
                                id: action.payload.data
                            }
                        })
                    }
                })
            }

        case `${types.ADD_WC_EXTRA_DATA}_ROLLBACK`:
            return {
                ...state,
                workcards: state.workcards.map(wc => {
                    if (wc.id !== parseInt(action.meta.wcId, 10)) {
                        return wc;
                    }

                    return {
                        ...wc,
                        extraDatas: wc.extraDatas.filter(wcExtraData => wcExtraData.id !== action.meta.tempId)
                    }
                })
            }
        case types.UPDATE_WC_EXTRA_DATA:
            const updatedWcs = state.workcards.map(wc => {
                if (wc.id !== parseInt(action.payload.workCardId, 10)) return wc;

                const extraDatas = wc.extraDatas.map(wcExtraData => {
                    if (wcExtraData.id !== action.payload.data.id) return wcExtraData;

                    return {
                        ...wcExtraData,
                        ...action.payload.data
                    }
                })

                return {
                    ...wc,
                    extraDatas: extraDatas
                };
            });

            return {
                ...state,
                workcards: updatedWcs
            }

        case `${types.UPDATE_WC_EXTRA_DATA}_ROLLBACK`:
            return {
                ...state,
            }

        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
            return {
                ...initialState,
                settings: {
                    ...state.settings,
                    pageNumber: initialState.settings.pageNumber
                },
                workcardsSettings: {
                    ...state.workcardsSettings
                }
            }

        case types.SET_WORK_LIST_PAGE_NUMBER:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageNumber: action.payload
                }
            }

        case types.CLEAR_CACHE:
            return initialState
        case types.STATUS_FULFILLED:
            return {
                ...state,
                status: 'fulfilled',
            }
        case types.API_CALL_ERROR:
            return {
                ...state,
                status: 'error',
            }
        case types.API_CALL_ERROR_RESET:
            return {
                ...state,
                status: 'fulfilled',
            }
        case types.SET_WORK_CARDS_STATUS_PENDING:
            return {
                ...state,
                status: 'pending'
            }
        case types.SET_WORK_CARDS_STATUS_FULFILLED:
            return {
                ...state,
                status: 'fulfilled'
            }
        case `${types.FETCH_WORK_CARD_LIMITED_WORKSTATUSES}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORK_CARD_LIMITED_WORKSTATUSES}_FULFILLED`:
            return {
                ...state,
                limitedWorkStatuses: {
                    ...state.limitedWorkStatuses,
                    [action.group]: action.payload
                }
            }
        case `${types.FETCH_WORK_CARD_LIMITED_WORKSTATUSES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case types.FETCH_WORK_CARD_MEASUREMENTS:
            return {
                ...state,
                workCardMeasurements: action.payload,
                status: 'fulfilled',
            }
        case `${types.FETCH_PURCHASE_ORDER_ITEMS}_FULFILLED`:
            return {
                ...state,
                purchaseOrderItems: action.payload,
                status: 'fulfilled',
            }
        case `${types.FETCH_MATERIAL_ORDER_GRID}_FULFILLED`:

            const _viewSettings = { ...state.viewSettings };
            _viewSettings.workcardmaterialorder = action.payload?.viewSettings?.WorkCardMaterialOrderGrid ?? [];

            return {
                ...state,
                status: 'fulfilled',
                viewSettings: _viewSettings
            }
        case types.DELETE_WORK_CARD_MEASUREMENT:
            return {
                ...state,
                workCardMeasurements: state.workCardMeasurements.filter(i => i.measurementGroupId !== action.payload.measurementGroupId)
            }

        case types.SET_WORKCARD_INFORMATION:
            return {
                ...state,
                ...action.payload
            }
        case `${types.FETCH_VIEW_SETTINGS_BY_GROUPS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                viewSettings: {
                    ...state.viewSettings,
                    ...action.payload.viewSettings
                }
            }
        case `${types.FETCH_FAULT_NOTICE_DEFAULT_TEXT}`:
            return {
                ...state,
                faultNoticeDefaultText: action.payload.text
            }
        default:
            return state;
    }
};

export default workcardsReducer;
