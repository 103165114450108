import React, { useState } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeableListItemProps,
    SwipeAction,
    TrailingActions,
    Type as TypeList
} from 'react-swipeable-list';

import 'react-swipeable-list/dist/styles.css';

type IActionItem = {
    callback: any;
    icon: any;
    label: string;
}

type IProps = {
    mainComponent: any;
    leftActions: IActionItem[];
    rightActions: IActionItem[];
    config?: SwipeableListItemProps;
    customStyle?: any;
    itemRef: React.MutableRefObject<any>;
}

export type ISwipeableListItem = {
    isSwiping: () => boolean,
    playReturnAnimation: () => void
}


export const SwipeableCard = (props: IProps) => {

    const [swipeInProgress, setSwipeInProgress] = useState(false);
    const [swipeAmount, setSwipeAmount] = useState(0);
    const { mainComponent, leftActions, rightActions, config, customStyle, itemRef } = props;

    const fullSwipeEnabled = config?.fullSwipe ?? false;
    const activateActionThreshold = config?.threshold ?? 0.99;
    const startSwipeThreshold = config?.swipeStartThreshold ?? 20;
    
    const mapAction = (actionItem: IActionItem, actionsAmount, index) => {
        const { label, callback, icon } = actionItem;
        const isLastItem = index == (actionsAmount - 1);
        const highlight = fullSwipeEnabled && isLastItem && (swipeAmount > (activateActionThreshold * 100));

        const handleClick: any = (e) => { 
            e?.stopPropagation(); 
            callback(); 
            // If some swipe action doesn't redirect to another view add exclusion logic here
            window.scrollTo(0, 0);
        }

        return (
            // SwipeAction->onClick = swipe to end action
            <SwipeAction onClick={handleClick} key={`${label}-${index}`}>
                <div 
                    className={`swipeListAction ${highlight ? 'highlighted' : ""}`} 
                    onClick={handleClick}
                    style={customStyle}
                >
                    <div>
                        {icon && <FontAwesomeIcon icon={icon} size="2x" className={icon == faPen ? 'icon-edit' : 'icon-success'}/>}
                        <p style={{ fontSize: "14px", marginBottom: "5px" }}>{label}</p>
                    </div>
                </div>
            </SwipeAction>
        );
    }

    const onClickProp: any = {
        onClick: e => {
            if (swipeAmount > 0) {
                e?.stopPropagation();
            }
            setSwipeAmount(0)
        }
    }

    return (
        <SwipeableList 
            type={TypeList.IOS} 
            swipeStartThreshold={startSwipeThreshold}
            fullSwipe={fullSwipeEnabled}
            threshold={activateActionThreshold}
            className='swipeListWrapper'
            {...onClickProp}
        >
            <SwipeableListItem
                ref={itemRef}
                leadingActions={
                    <LeadingActions>
                        {leftActions.length == 0 ? null : leftActions.map((action, i) => (
                            mapAction(action, leftActions.length, i)
                        ))}
                    </LeadingActions>
                }
                trailingActions={
                    <TrailingActions>
                        {rightActions.length == 0 ? null : rightActions.map((action, i) => (
                            mapAction(action, rightActions.length, i)
                        ))}
                    </TrailingActions>
                }
                onSwipeStart={() => {
                    setSwipeInProgress(true);
                }}
                onSwipeProgress={(e) => {
                    setSwipeAmount(e);
                    if (!swipeInProgress) {
                        setSwipeInProgress(true);
                    }
                }}
                onSwipeEnd={() => {

                    if (swipeInProgress) {

                        const item = itemRef.current;
                        const isLeft = item.left > 0;

                        // Lowest swipe position Where the actions stay open == actionsWidth + 1
                        const targetFlick = isLeft 
                            ? item.leadingActionsWidth + 1 
                            : -(item.trailingActionsWidth + 1);
                        
                        // Minimal swipe amount to trigger the flick, currently 20% of fully opened width
                        const swipeMinimalAmount = Math.abs(item.left) > (Math.abs(targetFlick) / 5); 
                        const swipeBelowMax = isLeft ? (item.left < targetFlick) : (item.left > targetFlick);

                        if (swipeMinimalAmount && swipeBelowMax) {
                            item.left = targetFlick;
                            item.updatePosition();
                        }
                        setTimeout(() => setSwipeInProgress(false), 5);
                    }
                }}
                {...config}
                {...onClickProp}
            >
                <div 
                    className="contentWrapper" 
                    onClick={(e) => {
                        if (swipeInProgress) {
                            // Prevent browser/doubleclick problems when releasing swipe
                            e.preventDefault();
                            e.stopPropagation();
                        } 
                    }}
                >
                    {mainComponent}
                </div>
            </SwipeableListItem>
        </SwipeableList>
    );
}