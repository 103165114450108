import React from 'react';
import { Row, Col } from 'react-bootstrap';
import TextTruncate from 'react-text-truncate';
import i18n from '../../../../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faTools } from '@fortawesome/free-solid-svg-icons';
import { GetTypeTranslationKey } from '../../../../components/HelperFunctions';
import { useSelector } from 'react-redux';

const WorkcardTitle = ({ code, isRoute = false, urgency = null, isTimedOpmWc = false }) => {
    const viewSettings = useSelector((state: State) => state.workcards.viewSettings.workcard);
    return (
        <Row>
            {viewSettings && viewSettings.find(fieldItem => fieldItem.field === 'code') && <Col xs={(isRoute || isTimedOpmWc) ? 2 : 7}>
                {(!isRoute && !isTimedOpmWc) && <span className="bold">#{code}</span>}
                {isRoute && <span className="bold flex">
                    <FontAwesomeIcon icon={faTasks} size="lg" />
                    <span>{code}</span>
                </span>}
                {isTimedOpmWc && <span className="bold flex">
                    <FontAwesomeIcon icon={faTools} size="lg" />
                    <span className="left-margin-5">{code}</span>
                </span>}
            </Col>}
            {viewSettings && !viewSettings.find(fieldItem => fieldItem.field === 'code') && !isRoute && !isTimedOpmWc && <Col xs={7}>
            </Col>}
            {isRoute && <Col xs={5} className="no-paddings">
                <b style={{ whiteSpace: 'nowrap' }}>
                    <TextTruncate line={1}
                        truncateText="…"
                        text={i18n.t('ROUTE_MAINTENANCE_HEADER')}
                    />
                </b>
            </Col>}
            {isTimedOpmWc && <Col xs={5} className="no-paddings">
                <b style={{ whiteSpace: 'nowrap' }}>
                    <TextTruncate line={1}
                        truncateText="…"
                        text={i18n.t('OPERATOR_MAINTENANCE')}
                    />
                </b>
            </Col>}
            <Col className={(isRoute || isTimedOpmWc) ? 'col-xs-5' : 'col-default'}>
            {viewSettings && viewSettings.find(fieldItem => fieldItem.field === 'urgency') && <span>
                {urgency && <div className="priority-level">
                    {i18n.t(urgency)}
                </div>}
                {!urgency && <div className="priority-level">
                </div>}
            </span>}
            {viewSettings && !viewSettings.find(fieldItem => fieldItem.field === 'urgency') && <div className="priority-level"></div>}
            </Col>
        </Row>
    );
}

export default WorkcardTitle;
