import Login from './Login';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login } from './actions';
import { setUserDetails } from './../../commonActions/actions';

const mapStateToProps = (state: State, ownProps) => {
    return {
        userId: state.settings.userAdNameDetails?.id ? state.settings.userAdNameDetails.id : state.settings.userDetails.id,
        locationState: ownProps.location.state
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ login, setUserDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);