export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const redirectToChangePasswordPage = () => {
    // use desktop url from config if it's configured
    if (window['runConfig'].desktopURL) {
        let url = new URL(window['runConfig'].desktopURL);
        url.pathname = url.pathname + '/PasswordChange.aspx';
        window.location.href = url.href;
    } else {
        let url = new URL(window.location.href);

        if (url.hostname.indexOf('m.') === 0) {
            const array = url.href.split('m.');
            url.href = array[0] + array[1];
        }
        // pathname to desktop site
        url.pathname = '/desk/PasswordChange.aspx';
        window.location.href = url.href;
    }
}