import React from 'react';
import PopoverContainer from '../../../components/PopoverContainer';
import PopoverAction from '../../../components/PopoverAction';
import i18n from '../../../translations/i18n';
import { HasRight } from '../../../components/HelperFunctions';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';

const PAGE_SIZE_OPTIONS = [5, 20, 50, 100];

const HourCardMenu = ({ layoutType, setListLayout, pageSize, setPageSize, userRights, goTo, toggleDialog, closeDialog, dialogOpen }) => {
    let settingsMenuHeadings = [];

    settingsMenuHeadings.push(i18n.t('LISTING_TYPE'));
    settingsMenuHeadings.push(i18n.t('DISPLAY_AMOUNT'));
    let settingsMenuContents = [];

    settingsMenuContents.push(
        <div>
            <PopoverAction
                icon="cards"
                label={i18n.t('LAYOUT_CARDS')}
                active={layoutType === 'cardLayout'}
                onClick={() => setListLayout('cardLayout')}
                inline
            />
            <PopoverAction
                icon="table"
                label={i18n.t('LAYOUT_TABLE')}
                active={layoutType === 'tableLayout'}
                onClick={() => setListLayout('tableLayout')}
                inline
            />
            <hr />
        </div>
    );

    settingsMenuContents.push(
        <div>
            <div className="small-options-list">
                {PAGE_SIZE_OPTIONS.map(size => (
                    <PopoverAction
                        key={size}
                        label={size.toString()}
                        active={pageSize === size}
                        onClick={() => setPageSize(size)}
                        inline
                    />
                ))}
            </div>
            <hr />
        </div>
    );

    settingsMenuContents.push(
        <div>
            <PopoverAction
                icon="add-circle"
                label={i18n.t('ADD_HOURCARD')}
                onClick={() => goTo('/hourcard/new')}
            />
        </div>
    );

    return (
        <div>
            {dialogOpen && <div className="overlay-canvas" onClick={() => closeDialog()} ></div>}
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={
                    <Popover id="settings-menu-popover" className="menu-popover">
                        {React.Children.map(settingsMenuContents, (child, i) => (
                            <div className="sub-section" key={`setting_${i}`}>
                                {settingsMenuHeadings !== null &&
                                    settingsMenuHeadings?.length > 0
                                    && settingsMenuHeadings[i] !== null &&
                                    <div className="setting-header">
                                        {settingsMenuHeadings[i]}
                                    </div>
                                }
                                <div className="setting-option">
                                    {child}
                                </div>
                            </div>
                        ))}
                    </Popover>
                }
            >
                <Button
                    id="footerMenuButton"
                    variant="primary"
                    className="action action-button novi-default-btn-color"
                    size="lg"
                    onClick={() => toggleDialog()}
                >
                    <div>
                        <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
                    </div>
                </Button>
            </OverlayTrigger>
        </div>
    );
}

export default HourCardMenu;
