import * as types from '../config/actionTypes';
import { initialFilters } from '../scenes/hour-cards/utils';

const initialState: IHourCardState = {
    hourCards: [],
    currentFilters: initialFilters,
    settings: {
        pageNumber: 1,
        pageSize: 5,
        layoutType: 'cardLayout'
    },
    viewSettings: {}
}

const hourcardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${types.FETCH_HOURCARDS}`:
            return {
                ...state,
                status: 'fulfilled',
                hourCards: action.payload
            }
        case 'SET_HOURCARDS_PAGE_SIZE':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageSize: action.payload
                }
            }
        case 'SET_HOURCARDS_PAGE_NUMBER':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageNumber: action.payload
                }
            }
        case 'SET_HOURCARDS_LIST_LAYOUT':
            return {
                ...state,
                settings: {
                    ...state.settings,
                    layoutType: action.payload
                }
            }
        case `${types.FETCH_HOURCARD_VIEW_SETTINGS}`: {
            return {
                ...state,
                status: 'fulfilled',
                viewSettings: action.payload
            }
        }
        /* fetchViewSettings payload version to enable hourcard fields in older scenes like RouteWorkCardView */
        case `${types.FETCH_HOURCARD_VIEW_SETTINGS}_FULFILLED`: {
            return {
                ...state,
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type[0]]: action.payload.viewSettings['hourcard'],
                }
            }
        }
        case `SET_HOURCARDS_FILTERS`:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageNumber: initialState.settings.pageNumber
                },
                currentFilters: action.payload
            }
        case `CLEAR_HOURCARDS_FILTERS`:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageNumber: initialState.settings.pageNumber
                },
                currentFilters: initialState.currentFilters
            }
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
            return {
                ...initialState,
                settings: {
                    ...state.settings, // keep page size and layout
                    pageNumber: initialState.settings.pageNumber
                }
            }
        case types.CLEAR_CACHE:
            return initialState;
        default:
            return state;
    }
}

export default hourcardsReducer;
