import React from 'react';
import { Button } from 'react-bootstrap';
import '../../../styles/global.scss';
import './../styles/dashboardCustomizer.scss';
import i18n from '../../../translations/i18n';
import DashboardItems from './DashboardItems';
import DialogModal from 'components/dialogs/DialogModal';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogBody from 'components/dialogs/DialogBody';
import DialogFooter from 'components/dialogs/DialogFooter';

interface IProps {
    closeDialog: () => void;
    showDialog: boolean;
    addItem: (item) => void;
}

const ItemPickerDialog = (props: IProps) => {
    const headerContent = (
        <span style={{ maxWidth: '200px', overflow: 'hidden' }}>
            {i18n.t('SELECT_DASHBOARD_COMPONENT')}
        </span>
    )

    const footerContent = (
        <div className="filters-button-container">
            <Button
                variant="primary"
                className="action action-button button-shadow large"
                size="lg"
                onClick={props.closeDialog}
                >
                    {i18n.t('CLOSE')}
            </Button>
        </div>
    )

    return (
        <DialogModal
            showDialog={props.showDialog}
            closeDialog={props.closeDialog}
            className="filtering-modal"
        >
            <DialogHeader>
                {headerContent}
            </DialogHeader>
            <DialogBody>
                <DashboardItems selectComponent={props.addItem} />
            </DialogBody>
            <DialogFooter>
                {footerContent}
            </DialogFooter>
        </DialogModal>
    )
}

export default ItemPickerDialog;
