import i18n from "../translations/i18n";
import {
    FETCH_WORKPHASE_VIEW_SETTINGS,
    FETCH_MACHINE_VIEW_SETTINGS,
    FETCH_MACHINE_MEASUREMENTS,
    FETCH_OPMAINTENANCE_VIEW_SETTINGS,
    FETCH_WORKCARD_VIEW_SETTINGS,
    FETCH_SPARE_PART_VIEW_SETTINGS,
    FETCH_MATERIAL_VIEW_SETTINGS,
    FETCH_PERMIT_VIEW_SETTINGS,
    FETCH_HOURCARD_VIEW_SETTINGS,
    FETCH_WORK_CARD_MEASUREMENTS
} from "./actionTypes";

export const settingsAPI = {
    "propertyCasingMap": {
        'faultbegin': 'faultBegin',
        'faultdescription': 'faultDescription',
        'faultlocation': 'faultLocation',
        'faultreason': 'faultReason',
        'haltreason': 'haltReason',
        'faulttype': 'faultType',
        'worktype': 'workType',
        'machinehalt': 'machineHalt',
        'orderdate': 'orderDate',
        'workbegin': 'workBegin',
        'workcanbegin': 'workCanBegin',
        'workcanend': 'workCanEnd',
        'workended': 'workEnded',
        'workergroups': 'workerGroups',
        'workhours': 'workHours',
        'workstatus': 'workStatus',
        'othercosts': 'otherCosts',
        'idletime': 'idleTime',
        'hourestimate': 'hourEstimate',
        'windowofopportunity': 'windowOfOpportunity',
        'hierarchypath': 'hierarchyPath',
        'productnumber': 'productNumber',
        'yearofmanufacture': 'yearOfManufacture',
        'deliverynumber': 'deliveryNumber',
        'purchasedate': 'purchaseDate',
        'deliverydate': 'deliveryDate',
        'deploymentdate': 'deploymentDate',
        'deprecationperiod': 'deprecationPeriod',
        'hourprice': 'hourPrice',
        'rownumber': 'rowNumber',
        'warrantyend': 'warrantyEnd',
        'extralocation': 'extraLocation',
        'extralocationtext': 'extraLocationText',
        'costpool': 'costPool',
        'responsiblepersonid': 'responsiblePersonId',
        'responsibleperson': 'responsiblePerson',
        'responsibilityperson': 'responsibilityPerson',
        'responsibilitypersonid': 'responsibilityPersonId',
        'machineshiftid': 'machineShiftId',
        'machinetype': 'machineType',
        'workername': 'workerName',
        'operatorMaintenanceid': 'operatorMaintenanceId',
        'machineid': 'machineId',
        'orderertext': 'ordererText',
        'investmentcode': 'investmentCode',
        'workphasepaused': 'paused',
        'workphasecostestimate': 'costEstimate',
        'machinerequirements': 'machineRequirements'
    },
    "wcGridColSizes": {
        'machine': '250px',
        'orderer': '200px',
        'procedure': '200px',
        'maintenancesubplan': '200px',
        'workcardtype': '150px'
    },
    "machineGridColSizes": {

    },
    "spGridColSizes": {

    },
    "workStatusValues": [
        {
            id: 1,
            label: "WORKSTATUS1",
            statusValue: 1
        },
        {
            id: 2,
            label: "WORKSTATUS2",
            statusValue: 2
        },
        {
            id: 3,
            label: "WORKSTATUS3",
            statusValue: 3
        },
        {
            id: 4,
            label: "WORKSTATUS4",
            statusValue: 4
        },
    ],
    //TODO: move all possible data into database
    moduleData: {
        worklist: {
            name: 'worklist',
            quickSearchType: 'workschedulequicksearch',
            quickSearchParamType: 'workcard',
            defaultQuickSearchData: {
                value: -2, label: i18n.t('PIKAHAUT'), options: [
                    { value: 9, label: i18n.t('OWN_DEFAULT_SEARCH') },
                    { value: 1, label: i18n.t('OPEN_WORK_CARDS') },
                    { value: 2, label: i18n.t('OWN_OPEN_WORKCARDS') },
                    { value: 8, label: i18n.t('STARTED_WORKS') },
                    { value: 3, label: i18n.t('OWN_STARTED_WORKS') },
                    { value: 6, label: i18n.t('DELAYED_WORKS') },
                    { value: 4, label: i18n.t('OWN_DELAYED_WORKS') },
                    { value: 7, label: i18n.t('NO_WORKERS_DASHBOARD_HEADER') },
                    { value: 10, label: i18n.t('OWN_WORKER_GROUP_WORKCARDS') }
                ]
            },
            viewSettings: [
                { groupType: 'workcardphase', actionType: FETCH_WORKPHASE_VIEW_SETTINGS }, 
                { groupType: ['workcard', 'workcardreport'], actionType: FETCH_WORKCARD_VIEW_SETTINGS }
            ],
            additionalParams: {}
        },
        machinelist: {
            name: 'machines',
            quickSearchType: 'machine',
            quickSearchParamType: 'machine',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'machine', actionType: FETCH_MACHINE_VIEW_SETTINGS }],
            additionalParams: {}
        },
        warehousesparepartlist: {
            name: 'warehouse',
            quickSearchType: '',
            quickSearchParamType: 'warehouse',
            defaultQuickSearchData: {},
            viewSettings: [],
            additionalParams: {}
        },
        operatormaintenaces: {
            name: 'operatormaintenance',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'operatormaintenance', actionType: FETCH_OPMAINTENANCE_VIEW_SETTINGS }],
            additionalParams: {}
        },
        workcard: {
            name: 'workcard',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [
                { groupType: ['workcard', 'workcardreport'], actionType: FETCH_WORKCARD_VIEW_SETTINGS },
                { groupType: ['workcardphase'], actionType: FETCH_WORKPHASE_VIEW_SETTINGS }
            ],
            additionalParams: {}
        },
        workphase: {
            name: 'workphase',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'workcardphase', actionType: FETCH_WORKPHASE_VIEW_SETTINGS }],
            additionalParams: {}
        },
        machine: {
            name: 'machine',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [
                { groupType: 'machine', actionType: FETCH_MACHINE_VIEW_SETTINGS },
                { groupType: ['workcard', 'workcardreport'], actionType: FETCH_WORKCARD_VIEW_SETTINGS },
                { groupType: 'workcardphase', actionType: FETCH_WORKPHASE_VIEW_SETTINGS }
            ],
            additionalParams: {}
        },
        measurement: {
            name: "measurement",
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [],
            additionalParams: {}
        },
        sparepart: {
            name: 'sparepart',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'sparepart', actionType: FETCH_SPARE_PART_VIEW_SETTINGS }],
            additionalParams: {}
        },
        routeworkcard: {
            name: '',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [],
            additionalParams: {}
        },
        operatormaintenance: {
            name: 'operatormaintenanceView',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'operatormaintenance', actionType: FETCH_OPMAINTENANCE_VIEW_SETTINGS }],
            additionalParams: {}

        },
        permit: {
            name: 'permit',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [],
            additionalParams: {}
        },
        editworkcard: {
            name: '',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [
                { groupType: ['faultnotice', 'workcard', 'workcardreport', 'workcardadd', 'workcardaddreport'], actionType: FETCH_WORKCARD_VIEW_SETTINGS },
                { groupType: 'workcardphase', actionType: FETCH_WORKPHASE_VIEW_SETTINGS }
            ],
            additionalParams: {
                responsiblePerson: 'workcard'
            }
        },
        editworkphase: {
            name: 'workphase',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'workcardphase', actionType: FETCH_WORKPHASE_VIEW_SETTINGS }],
            additionalParams: {
                workers: 'workers'
            }
        },
        editmachine: {
            name: 'machine',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'machine', actionType: FETCH_MACHINE_VIEW_SETTINGS }],
            additionalParams: {}
        },
        editmaterial: {
            name: 'material',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'material', actionType: FETCH_MATERIAL_VIEW_SETTINGS }],
            additionalParams: {}
        },
        editmachinesparepart: {
            name: 'sparepart',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'material', actionType: FETCH_MATERIAL_VIEW_SETTINGS }],
            additionalParams: {}
        },
        editsparepart: {
            name: 'sparepart',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'sparepart', actionType: FETCH_SPARE_PART_VIEW_SETTINGS }],
            additionalParams: {
                thirdparties: 'thirdPartyOptions'
            }
        },
        editpermit: {
            name: 'permit',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'workpermit', actionType: FETCH_PERMIT_VIEW_SETTINGS }],
            additionalParams: {
                workers: 'workers'
            }
        },
        warehouseoperation: {
            name: '',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: 'sparepart', actionType: FETCH_SPARE_PART_VIEW_SETTINGS }],
            additionalParams: {}
        },
        hourcards: {
            name: 'hourcard',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: ['hourcard'], actionType: FETCH_HOURCARD_VIEW_SETTINGS }],
            additionalParams: {}
        },
        editMeasurement: {
            name: 'measurement',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [],
            additionalParams: {}
        },
        purchases: {
            name: "purchases",
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [{ groupType: ['purchases'], actionType: FETCH_WORK_CARD_MEASUREMENTS }],
            additionalParams: {}
        },
        counters: {
            name: 'counter',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [],
            additionalParams: {}
        },
        safety: {
            name: 'safety',
            quickSearchType: '',
            quickSearchParamType: '',
            defaultQuickSearchData: {},
            viewSettings: [],
            additionalParams: {}
        }
    },
    // for warehouse operations like take, return, invent (exc. arrive)
    "warehouseOperationFields": [
        {
            name: 'warehouse',
            label: 'WAREHOUSE',
            type: 'select',
            tabOrder: 100,
            required: true,
            operationTypes: ['take', 'return', 'invent', 'change']
        },
        {
            name: 'amount',
            label: 'AMOUNT',
            type: 'number',
            tabOrder: 110,
            required: true,
            operationTypes: ['take', 'return', 'arrive', 'invent', 'transfer']
        },
        {
            name: 'costpoolType',
            label: 'COSTPOOL_TYPE',
            type: 'select-choice',
            tabOrder: 112,
            required: true,
            operationTypes: ['take', 'return']
        },
        {
            name: 'price',
            label: 'PRICE',
            type: 'number',
            tabOrder: 114,
            required: true,
            operationTypes: ['change']
        },
        {
            name: 'shelfLocation',
            label: 'SHELF_LOCATION',
            type: 'input',
            tabOrder: 115,
            required: false,
            operationTypes: ['arrive', 'transfer']
        },
        {
            name: 'amountInWarehouse',
            label: 'AMOUNT_IN_WAREHOUSE',
            type: 'label',
            tabOrder: 116,
            required: true,
            operationTypes: ['take', 'transfer']
        },
        {
            name: 'fromWarehouse',
            label: 'FROM_WAREHOUSE',
            type: 'select',
            tabOrder: 100,
            required: true,
            operationTypes: ['transfer']
        },
        {
            name: 'toWarehouse',
            label: 'TO_WAREHOUSE',
            type: 'select',
            tabOrder: 112,
            required: true,
            operationTypes: ['arrive', 'transfer']
        },
        {
            name: "plannedAmount",
            label: "PLANNED_AMOUNT",
            type: "label",
            tabOrder: 100,
            required: false,
            operationTypes: ['arrive']
        },
        {
            name: "takeAmount",
            label: "TAKEN_AMOUNT",
            type: "label",
            tabOrder: 101,
            required: false,
            operationTypes: ['arrive']
        },
    ],
    'warehouseWildOperationFields': [
        {
            name: "plannedAmount",
            label: "PLANNED_AMOUNT",
            type: "label",
            tabOrder: 100,
            required: false,
            operationTypes: ['take']
        },
        {
            name: "takeAmount",
            label: "TAKEN_AMOUNT",
            type: "label",
            tabOrder: 101,
            required: false,
            operationTypes: ['take', 'return']
        },
        {
            name: "amount",
            label: "AMOUNT",
            type: "number-input",
            tabOrder: 102,
            required: true,
            operationTypes: ['take', 'return']
        }
    ],
    'warehousePurchaseOrderOperationFields' : [
        {
            name: "purchaseOrder",
            label: "PURCHASE_ORDER",
            type: "label",
            tabOrder: 100,
            required: false,
            operationTypes: ['arrive']
        },
        {
            name: "sparePartName",
            label: "SPARE_PART",
            type: "label",
            tabOrder: 110,
            required: false,
            operationTypes: ['arrive']
        },
        {
            name: "orderedAmount",
            label: "ORDERED_AMOUNT",
            type: "label",
            tabOrder: 100,
            required: false,
            operationTypes: ['arrive']
        },
        {
            name: "arrivedAmount",
            label: "ARRIVED_AMOUNT",
            type: "label",
            tabOrder: 101,
            required: false,
            operationTypes: ['arrive']
        },
        {
            name: "amount",
            label: "AMOUNT_TO_ARRIVE",
            type: "number-input",
            tabOrder: 102,
            required: true,
            operationTypes: ['arrive']
        },
        {
            name: "price",
            label: "PRICE",
            type: "number-input",
            tabOrder: 102,
            required: true,
            operationTypes: ['arrive']
        },
        {
            name: 'shelfLocation',
            label: 'SHELF_LOCATION',
            type: 'input',
            tabOrder: 115,
            required: false,
            operationTypes: ['arrive']
        },
        {
            name: 'toWarehouse',
            label: 'TO_WAREHOUSE',
            type: 'select',
            tabOrder: 112,
            required: true,
            operationTypes: ['arrive']
        }
    ],
    'material': [
        { field: 'sparepart', type: 'text', label: 'SPAREPART', tabOrder: 100 },
        { field: 'wildsparepart', type: 'text', label: 'WILD_SPAREPART', tabOrder: 100 },
        { field: 'amount', type: 'number', label: 'AMOUNT', tabOrder: 101 },
        { field: 'type', type: 'text', label: 'TYPE', tabOrder: 102 },
        { field: 'memo', type: 'textarea', label: 'MEMO', tabOrder: 103 },
    ],
    'novimodules': {//Temporary default enabled modules
        dashboard: {
            enabled: true,
            type: 'dashboard',
            props: null,
            icon: 'home',
            translation: 'MAIN_MENU',
            link: '/dashboard'

        },
        worklist: {
            enabled: true,
            viewRight: 'workscheduleview',
            type: 'worklist',
            props: null,
            icon: null,
            translation: 'WORKCARDS',
            link: '/worklist'

        },
        machines: {
            enabled: true,
            viewRight: 'machineregistryview',
            type: 'machines',
            props: null,
            icon: null,
            translation: 'MACHINES',
            link: '/machines'
        },
        maintenances: {
            enabled: true,
            viewRight: 'maintenances',
            type: 'maintenances',
            props: { open: false },
            icon: null,
            translation: 'MAINTENANCES',
            subitems: [
                {
                    enabled: false,
                    type: 'plans',
                    icon: null,
                    translation: 'CALENDAR_MAINTENANCES',
                    link: {
                        pathname: "/maintenances",
                        state: 'plans'
                    }
                },
                {
                    enabled: false,
                    type: 'operators',
                    icon: null,
                    translation: 'OPERATOR_MAINTENANCES',
                    link: {
                        pathname: "/maintenances",
                        state: 'operators'
                    }
                },
                {
                    enabled: false,
                    type: 'RTM',
                    icon: null,
                    translation: 'RTM',
                    link: {
                        pathname: "/maintenances",
                        state: 'RTM'
                    }
                },
                {
                    enabled: false,
                    type: 'routes',
                    icon: null,
                    translation: 'ROUTE_MAINTENANCES',
                    link: {
                        pathname: "/maintenances",
                        state: 'routes'
                    }
                }
            ]
        },
        settings: {
            enabled: false,
            viewRight: '',
            type: 'settings',
            props: null,
            icon: null,
            translation: '',
            link: '/'
        },
        profile: {
            enabled: false,
            viewRight: '',
            type: 'profile',
            props: null,
            icon: null,
            translation: '',
            link: '/'
        },
        reports: {
            enabled: false,
            viewRight: '',
            type: 'reports',
            props: null,
            icon: null,
            translation: '',
            link: '/'
        },
        spareparts: {
            enabled: false,
            viewRight: '',
            type: 'spareparts',
            props: null,
            icon: null,
            translation: '',
            link: '/'
        }
    }
}

export default settingsAPI;
