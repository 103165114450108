import noviAPI from "../../api/noviAPI";

export const openDocument = (document: IDocumentData) => {
    // open new tab if document is a link and return
    if (document.isUrl && document.documentLink) {
        window.open(document.documentLink, document.caption);
        return;
    }

    // get image as blob object
    noviAPI.documents.download(document.id, { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(response.data);

            const officeDocument = 'application/vnd.openxmlformats-officedocument';

            // create a link elemenet and simulate click event to download document with original file name
            if (response.data.type.includes(officeDocument)) {
                let link = window.document.createElement('a');
                link.href = url;
                link.download = document.caption;
                link.click();
                link.remove();
                return;
            }

            // open document in a new tab 
            window.open(url, '_blank');

        }).catch(error => {
            console.log(error);
        });
}