import React from 'react';
import { HasRight, restrictRenderingByParameters } from '../../../../../components/HelperFunctions';
import PopoverAction from '../../../../../components/PopoverAction';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { actionIcons } from '../../../../../constants/icons';
import i18n from '../../../../../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { UserRights } from '../../../../../constants/userRights';

const MaterialMenu = ({ materialItem, handleClick, userRights, sparePartReservationEnabled, preventMaterialDelete, ...position }: IProps) => {
    // TODO: Define userRights and sparePartReservationEnabled here after OverlayTrigger components have been changed to Overlay components
    // const userRights = useSelector((state: State) => state.settings.userRights);
    // const sparePartReservationEnabled = useSelector((state: State) => state.settings.noviConfigs.SparePartReservationEnabled);

    const preventPurchArrival = useSelector((state: State) => state.settings.noviConfigs.PreventPurchaseArrivalBeforeOrderedStatus);
    const purchaseItems = useSelector((state: State) => state.workcards.purchaseOrderItems) ?? [];
    const correspondingPurchaseItem = materialItem.purchaseOrderItem?.id ? purchaseItems.find(poItem => poItem.id == materialItem.purchaseOrderItem.id) : null;

    const isTakeButtonEnabled = () => {
        let buttonEnabled = true;
        if (!HasRight('warehouseoperationtake', userRights)) {
            buttonEnabled = false;
        }
        if (sparePartReservationEnabled && !isTakeButtonEnabledWhenReservationsEnabled()) {
            buttonEnabled = false;
        }
        if (materialItem.purchaseOrderItem !== null) {
            buttonEnabled = false;
        }
        return buttonEnabled;
    }

    const isTakeButtonEnabledWhenReservationsEnabled = () => materialItem.spReservationId ? false : true;

    let menuContents = [];

    if (restrictRenderingByParameters('workschedulerestrictedmaterialsedit', userRights)) {
        menuContents.push(<div>
            <PopoverAction
                icon={actionIcons.EDIT}
                label={i18n.t('EDIT_MATERIAL')}
                paClassName={''}
                onClick={() => handleClick('edit', materialItem)}
            />
            <hr />
        </div>)}
    if (!preventMaterialDelete && restrictRenderingByParameters('workschedulerestrictedmaterialsedit', userRights)) {

        const isDisabled = ((materialItem?.details?.takeAmount ?? 0) > 0);

        menuContents.push(<div>
            <PopoverAction
                icon={actionIcons.DELETE}
                label={i18n.t('DELETE_MATERIAL')}
                paClassName={isDisabled ? 'disabled' : ''}
                onClick={() => !isDisabled && handleClick('delete', materialItem)}
            />
            <hr />
        </div>)
    }
    if (restrictRenderingByParameters(UserRights.PurchasesEdit, userRights) && correspondingPurchaseItem) {

        const orderIsInArrivableStatus = [9, 10].includes(parseInt(correspondingPurchaseItem.purchaseOrder?.status?.code));

        if (materialItem.purchaseOrderItem && (!(preventPurchArrival == "True") || orderIsInArrivableStatus)) {

            const { amount = 0, arrivedAmount = 0 } = materialItem?.purchaseOrderItem ?? {};

            const alreadyArrived = arrivedAmount >= amount;

            menuContents.push(
                <div>
                    <PopoverAction
                        icon={!alreadyArrived ? actionIcons.ARRIVE : null}
                        label={`${i18n.t('ARRIVE')} ${i18n.t("PURCHASE_ORDER_ROW")}`}
                        paClassName={!alreadyArrived ? '' : 'disabled'}
                        onClick={() => !alreadyArrived
                            ? handleClick('arrive', materialItem, materialItem.purchaseOrderItem)
                            : null
                        }
                    />
                    <hr />
                </div>
            );
            

        }
    }
    if (isTakeButtonEnabled()) {
        menuContents.push(<div>
            <PopoverAction
                icon={materialItem.spReservationId ? null : actionIcons.TAKE}
                label={i18n.t('TAKE')}
                paClassName={materialItem.spReservationId ? 'disabled' : ''}
                onClick={() => handleClick('take', materialItem)}
            />
            <hr />
        </div>)
    }
    if (HasRight('warehouseoperationreturn', userRights)) {
        menuContents.push(<div>
                <PopoverAction
                    icon={materialItem.details.takeAmount > 0 ? actionIcons.RETURN : null}
                    label={i18n.t('RETURN')}
                    paClassName={materialItem.details.takeAmount > 0 ? '' : 'disabled'}
                    onClick={() => materialItem.details.takeAmount > 0
                        ? handleClick('return', materialItem)
                        : null
                    }
                />
        </div>)
    }

    return (
        <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose={true}
            overlay={
                <Popover id="settings-menu-popover" className="panel-menu-popover">
                    {React.Children.map(menuContents, (child, i) => (
                        <div className="sub-section" key={`setting_${i}`}>
                            <div className="setting-option">
                                {child}
                            </div>
                        </div>
                    ))}
                </Popover>
            }
        >
            <span className="options-button" ><FontAwesomeIcon icon={faEllipsisV} size="lg" /></span>
        </OverlayTrigger>
    );
}

type ActionName = 'take' | 'return' | 'arrive' | 'delete' | 'edit';

interface IProps {
    materialItem;
    handleClick: (actionName: ActionName, material: IMaterial, purchaseOrder?: any) => void;
    userRights: IUserRight[];
    sparePartReservationEnabled: boolean;
    preventMaterialDelete?: boolean;
}

export default MaterialMenu;
