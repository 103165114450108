export const actionIcons = {
    ADD_SQUARE: 'add-square',
    ADD_CIRCLE: 'add-circle',
    TASK: 'task',
    CARDS: 'cards',
    TABLE: 'table',
    LIST: 'list',
    TREE: 'tree',
    DELETE: 'delete',
    EDIT: 'edit',
    SHOW: 'show',
    HIDE: 'hide',
    SIGNATURE: 'signature',
    VIEW: 'view',
    CHART_BAR: 'chart-bar',
    TAKE: 'take',
    RETURN: 'return',
    ARRIVE: 'arrive',
    CHANGE: 'change',
    TRANSFER: 'transfer',
    SAVE: 'save',
    SEARCH: 'search',
    COPY: 'copy',
    NEXT_STATUS: 'next-status',
    MOVE: 'move',
    CLOSE: 'close',
    CANCEL: 'cancel',
    SETTINGS_MENU:  'settings-menu',
    NO_ICON: ''
} as const;
