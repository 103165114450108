import React from 'react';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import '../../styles/global.scss';
import './styles/dashboard.scss';
import NavigationBar from '../navigation';
import i18n from '../../translations/i18n';
import { HandleError, HasRight, Toaster } from '../../components/HelperFunctions';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faEdit, faPlus, faTrash, faSpinner, faStar, faSave } from '@fortawesome/free-solid-svg-icons';
import DashboardCustomizer from '../../customizationTools/dashboardCustomizer/DashboardCustomizer';
import DashboardItem from './components/DashboardItem';
import DialogModal from '../../components/dialogs/DialogModal';
import noviAPI from '../../api/noviAPI';
import { getDialogContent, getDialogInfo } from '../../components/dialogs/DialogContentService';
import SwipeableViews from 'react-swipeable-views';
import FormElement from '../work-schedule/work-card/components/FormElement';
import DialogFooter from '../../components/dialogs/DialogFooter';
import DialogBody from '../../components/dialogs/DialogBody';
import DialogHeader from '../../components/dialogs/DialogHeader';
import { ItemTypes } from '../../constants/dashboard/itemTypes';
import { PropsFromRedux } from '.';
import { RouteChildrenProps } from 'react-router-dom';
import { ComponentTypes } from '../../constants/dashboard/componentTypes';
import { Flex, Box } from 'reflexbox';
import SparePartFiltering from './../warehouse/components/SparePartFiltering';
import MachineFiltering from './../machines/components/MachineFiltering';
import HourCardFiltering from './../hour-cards/components/HourCardFiltering';
import WorkCardFiltering from './../work-schedule/work-list/components/WorkCardFiltering';
import { UserRights } from 'constants/userRights';
import axios from 'axios';
import ScannerRoot from 'components/scanners/ScannerRoot';

enum DashboardItemValue {
    NONE,
    MACHINE_QR_READER,
    SPAREPART_QR_READER,
    SPAREPART_BARCODE_READER,
    OPERATOR_MAINTENANCE_QR_READER,
    WORKCARD_QR_READER,
    WAREHOUSE_INVENTORY_QR_READER 
};

type LocationState = { notificationMsg: string; }
type IProps = PropsFromRedux & RouteChildrenProps<null, LocationState>;

interface IState {
    useDashboardCustomizer: boolean;
    machineCode: string;
    dialogOpen: boolean;
    scannedItem: any;
    dialogProperty: string;
    counts: IDashboardCounts;
    activeDashboardIndex: number;
    activeScan: IDashboardItem;
    dashboardDialogOpen: boolean;
    dashboardName: string;
    dialogType: string;
    showSpinner: boolean;
    itemLoadingStatuses: object;
    qrContainerRef: any;
    searchModalOpen?: { search: String, field?: String };
    dashboards: IDashboard[];
    dashboardItemId: number;
    personGroup: IOptionType;
    personGroupSpecific: boolean;
}

class Dashboard extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            useDashboardCustomizer: false,
            machineCode: '',
            dialogOpen: false,
            scannedItem: null,
            dialogProperty: '',
            counts: {
                defaultCounts: null,
                savedCounts: null,
                sqlCounts: null
            },
            activeDashboardIndex: 0,
            activeScan: null,
            dashboardDialogOpen: false,
            dashboardName: null,
            dialogType: '',
            showSpinner: false,
            itemLoadingStatuses: {},
            qrContainerRef: null,
            searchModalOpen: null,
            dashboards: [],
            dashboardItemId: null,
            personGroup: null,
            personGroupSpecific: false,
        }
    }

    componentDidMount() {
        // Show toaster message
        const prevState = this.props.location.state;
        if (prevState && prevState.notificationMsg) {
            toast.success(i18n.t(prevState.notificationMsg), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });

            delete prevState.notificationMsg;
            this.props.history.replace({ state: prevState });
        }

        // Clean possible existing scan results
        const { scanners, clearScanResult } = this.props;
        if (scanners.QR?.result === null && scanners.QR?.isOpen || scanners.QR?.result !== null) {
            clearScanResult('QR');
        }

        this.getDashboardModules();
        this.props.fetchUsersPersonGroups();
        this.props.fetchSearches('workCardSearches')
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { settings, dashboardUpdateStatus, scanners } = this.props;

        if (prevProps.settings.machineGroupId !== settings.machineGroupId && settings.machineGroupId) {
            this.getDashboardModules();
            this.props.fetchUsersPersonGroups();
            this.props.fetchUserRights();
            this.setState({ activeDashboardIndex: 0 });
            this.props.fetchSearches('workCardSearches')
        }
        else if (prevProps.dashboardUpdateStatus !== dashboardUpdateStatus && dashboardUpdateStatus === 'fulfilled') {
            this.getDashboardModules();
        }

        if (scanners.QR.result !== null && (scanners.QR.result !== prevProps.scanners.QR.result)) {
            this.handleQrResult();
        }
    }

    getDashboards = async () => {
        const { machineGroupId, userId, userAdNameDetails } = this.props.settings;
        const personId = userAdNameDetails.id ?? userId;

        const response = await noviAPI.dashboards.fetchAll(machineGroupId, personId);
        const dashboards = response.data;

        return dashboards;
    }

    sortDashboards = (dashboards: IDashboard[], defaultDashboard = null) => {
        const defaultDashboardId = defaultDashboard ?? this.props.defaultDashboardId;


        if (defaultDashboardId) {
            // Sort dashboards by id
            dashboards.sort((a, b) => a.id - b.id);

            const index = dashboards.findIndex(i => i.id === defaultDashboardId);
            const defaultDashboard = dashboards[index];

            if (index !- -1 && defaultDashboard) {
                // Move default dashboard to first position
                dashboards.splice(index, 1);
                dashboards.unshift(defaultDashboard);
            }
        }
    }

    getDashboardItemCounts = async (dashboards: IDashboard[]) => {
        try {
            const activeDashboardIndex = this.state.activeDashboardIndex;
            const currentDashboard = dashboards[activeDashboardIndex];

            const dashboardItems = currentDashboard.dashboardModules.flatMap(module =>
                module.dashboardItems.map(item => item)
            ) ?? [];

            let searchParams = new URLSearchParams();

            dashboardItems
                .forEach(i => {
                    if (i.itemTypeId === ItemTypes.Default) {
                        searchParams.append("DefaultSearches", i.itemValue);
                    } else if (i.itemTypeId === ItemTypes.WorkCardSQL || i.itemTypeId === ItemTypes.MachineSQL || i.itemTypeId === ItemTypes.WarehouseSQL) {
                        searchParams.append("SqlSearches", i.itemValue);
                    } else if (i.itemTypeId === ItemTypes.WorkCardSavedSearch) {
                        searchParams.append("SavedSearches", i.itemValue);
                    }
                });

            const { machineGroupId } = this.props.settings;
            const response = await noviAPI.dashboardStatistics.fetch(machineGroupId, searchParams);
            const counts = response.data;

            let itemLoadingStatuses = {};
            dashboardItems.map(i => i.itemValue).forEach(i => {
                Object.assign(itemLoadingStatuses, { [i]: false });
            });

            this.setState({ counts, itemLoadingStatuses });

            // Fetch fault notice default text only after counts are fetched
            if (this.props.defaultFaultNoticeText === null) {
                this.props.fetchFaultNoticeDefaultText();
            }
        } catch (error) {
            console.log(error);
        }
    }

    getItemCount = (item: IDashboardItem) => {
        const { counts } = this.state;

        if (item.itemTypeId === ItemTypes.Default && counts?.defaultCounts) {
            return counts.defaultCounts[item.itemValue];
        } else if (item.itemTypeId === ItemTypes.WorkCardSavedSearch && counts?.savedCounts) {
            return counts.savedCounts[item.itemValue];
        } else if ((item.itemTypeId === ItemTypes.WorkCardSQL || item.itemTypeId === ItemTypes.MachineSQL || item.itemTypeId === ItemTypes.WarehouseSQL) && counts.sqlCounts) {
            return counts.sqlCounts[item.itemValue];
        } else {
            return '';
        }
    } 

    getDashboardModules = async (fetchDashboards = true) => {
        this.setState({ itemLoadingStatuses: {} });
        try {

            const { machineGroupId, userId, userAdNameDetails } = this.props.settings;
            const personId = userAdNameDetails.id ?? userId;

            // Only fetch dashboards if we have the required api call data
            if (machineGroupId && personId) {
                let dashboards = this.state.dashboards;
                if (fetchDashboards) {
                    dashboards = await this.getDashboards();
                    this.sortDashboards(dashboards);
                    this.setState({ dashboards });
                }

                this.getDashboardItemCounts(dashboards);
            }
        } catch (e) {
            console.log(e);
        }
    }

    setDefaultDashboard = (id: number) => {
        this.props.updateFavouriteDashboard(id);

        let dashboards = this.state.dashboards;
        const currentDashboard = dashboards[this.state.activeDashboardIndex];

        // When using props instead of localStorage the id doesn't update instantly so we have to pass it instead
        this.sortDashboards(dashboards, id);
        const newIndex = dashboards.findIndex(i => i.id === currentDashboard.id);

        this.setState({
            dashboards,
            activeDashboardIndex: newIndex
        }, () => {
            /**
             * Here dashboards are sorted and activeDashboardIndex is correct 
             * which is important so we can fetch right item counts in getDashboardModules
             * */
            this.getDashboardModules(false);
        });
    }

    isSavedSearch = (dashboardItem: IDashboardItem) => (
        dashboardItem.itemTypeId === ItemTypes.WorkCardSavedSearch || dashboardItem.itemTypeId === ItemTypes.MachineSavedSearch
    )

    isSqlSearch = (dashboardItem: IDashboardItem) => (
        dashboardItem.itemTypeId === ItemTypes.WorkCardSQL || dashboardItem.itemTypeId === ItemTypes.WarehouseSQL
    )

    searchOnDashboardItemExists = async (dashboardItem: IDashboardItem): Promise<boolean> => {
        let exists = true;
        const searchId = Number(dashboardItem.itemValue);
        if (this.isSavedSearch(dashboardItem)) {
            try {
                await noviAPI.searches.fetch(searchId);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response.status === 404) {
                        exists = false;
                    }
                }
            }
        } else if (this.isSqlSearch(dashboardItem)) {
            try {
                const { machineGroupId } = this.props.settings;
                await noviAPI.quickSearches.fetchResults(machineGroupId, searchId, { pageSize: 0 })
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response.status === 404) {
                        exists = false;
                    }
                }
            }
        }
        return exists;
    }

    handleClick = async (dashboardItem: IDashboardItem) => {
        const exists = await this.searchOnDashboardItemExists(dashboardItem);
        if (!exists) {
            this.openActionFailedDialog(dashboardItem.id);
            return;
        }

        const quickSearchType = {
            value: parseInt(dashboardItem.itemValue, 10),
            label: i18n.t(dashboardItem.label),
            isSqlSearch: this.isSqlSearch(dashboardItem),
            isSavedSearch: this.isSavedSearch(dashboardItem)
        };

        const propertyType = dashboardItem.propertyType === 'workcard'
            ? 'worklist'
            : dashboardItem.propertyType === 'machine'
                ? 'machines'
                : dashboardItem.propertyType === 'sparepart'
                    ? 'warehouse'
                    : null;


        if (propertyType === 'warehouse') {
            this.props.resetCurrentWarehouseFilters();
        }
        this.props.setQuickSearch(quickSearchType, propertyType);

        // Go to given view (worklist, machines, warehouse etc.)
        this.props.history.push({ pathname: '/' + propertyType });
    }

    toggleCustomizer = (dashboardSaved = false) => {
        const { useDashboardCustomizer } = this.state;

        if (useDashboardCustomizer) {
            // Skip getDashboardModules() after save. We call it in ComponentDidUpdate after the save is completed.
            if (!dashboardSaved) {
                this.getDashboardModules();
            }
            this.setState({ useDashboardCustomizer: false, dashboardItemId: null });
        } else {
            this.props.fetchDashboardItemTypes();
            this.props.fetchDashboardTypeOptions();
            this.setState({ useDashboardCustomizer: true });
        }

        window.scrollTo(0, 0);
    }

    hasAdminRight = () => {
        const { settings } = this.props;

        return settings.userRights && settings.userRights.find(right => right.adminedit);
    }

    getExtraStyle = (isDelayType, count) => {
        if (isDelayType) {
            return count > 0 ? 'delays-warning' : 'no-delays';
        }

        return '';
    }

    closeDialog = () => {
        this.props.clearScanResult('QR');
        this.setState(state => ({
            dialogOpen: false
        }));
    }

    changeDashboard = (newIndex: number) => {
        this.setState({
            activeDashboardIndex: newIndex
        }, () => {
            this.getDashboardModules(false);
        });
    };
    
    addDashboard = async () => {
        this.setState({ showSpinner: true });

        const { machineGroupId, userId } = this.props.settings;
        const { dashboardName, personGroup, personGroupSpecific } = this.state;

        const newDashboard: IDashboardAddition = {
            machineGroupId,
            personId: userId,
            name: dashboardName ?? "",
            personGroupId: personGroupSpecific ? parseInt(personGroup?.value) : null
        }
        
        await noviAPI.dashboards.add(newDashboard)
            .then(({ data, status }) => {

                if (status === 200 && data.id) {
                    Toaster({ msg: 'NEW_DASHBOARD_ADDED', type: 'success' });

                    this.props.fetchDashboardItemTypes();
                    this.props.fetchDashboardTypeOptions();

                    this.setState({
                        dashboardDialogOpen: false,
                        useDashboardCustomizer: true,
                        activeDashboardIndex: this.state.dashboards.length,
                        showSpinner: false
                    }, () => {
                        this.getDashboardModules();
                    })
                }
            })
            .catch(err => {
                HandleError(err, "Add new dashboard");
                this.setState({ showSpinner: false });
            });
    }

    updateDashboard = async () => {
        const { personGroup, dashboards, activeDashboardIndex } = this.state;
        const currentDashboard = dashboards[activeDashboardIndex];

        const pGroupId = parseInt(personGroup?.value ?? "");
        const updateName = currentDashboard.name !== this.state.dashboardName;

        const newDashboard: any = {};

        if (pGroupId && pGroupId != currentDashboard.personGroupId)
            newDashboard.personGroupId = pGroupId;
        if (updateName)
            newDashboard.name = this.state.dashboardName;

        this.props.updateDashboard(currentDashboard.id, newDashboard)
            ['then'](() => {
                this.setState({
                    dashboardDialogOpen: false, 
                    dialogType: '',
                });
            })
    }
    
    deleteDashboard = () => {
        const { dashboards, activeDashboardIndex } = this.state;
        const { defaultDashboardId } = this.props;
        const currentDashboard = dashboards[activeDashboardIndex];
        const factoryDefaultDashboard = dashboards?.find(i => i.personId === null) ?? { id: -1 };

        noviAPI.dashboards.delete(currentDashboard.id)
            .then(() => {
                if (defaultDashboardId === currentDashboard.id) {
                    this.props.updateFavouriteDashboard(factoryDefaultDashboard.id);
                }

                this.setState({
                    dashboardDialogOpen: false,
                    activeDashboardIndex: 0
                }, () => {
                    this.getDashboardModules();
                });

                Toaster({ msg: 'DASHBOARD_DELETED', type: 'success' });
            })
            .catch(e => { console.log(e); })
    }

    getDialogBodyContent = () => {
        const { personGroupSpecific, dashboards, activeDashboardIndex } = this.state;
        const { settings, options } = this.props;
        const currentDashboard = dashboards[activeDashboardIndex];

        if (this.state.dialogType === 'dashboardAdd') {
            return (
                <div data-cy="dashboardNameInput">
                    <FormElement type="text" name="dashboard_name" label={i18n.t('NAME')} value={this.state.dashboardName}
                        onChange={(e) => this.setState({ dashboardName: e.target.value })} isDisabled={this.state.showSpinner} />
                    {HasRight(UserRights.DashboardPersonGroupEdit, settings.userRights) && 
                        <div>
                            <FormElement 
                                type="singleline-checkbox"
                                name="personGroupSpecific"
                                value={personGroupSpecific}
                                label={i18n.t("PERSON_GROUP_SPECIFIC")}
                                onChange={() => this.setState(prevState => ({ personGroupSpecific: !prevState.personGroupSpecific }))}
                            />
                            {personGroupSpecific &&
                                <FormElement 
                                    type="select-choice" 
                                    name="personGroup" 
                                    label={i18n.t('KAYTTAJARYHMA')} 
                                    value={this.state.personGroup}
                                    onChange={(opt) => {
                                        this.setState({ personGroup: opt });
                                    }} 
                                    isDisabled={this.state.showSpinner} 
                                    options={options}
                                />}
                        </div>}
                </div>
            )
        }
        else if (this.state.dialogType === 'dashboardEdit') {
            const isPersonGroupDashboard = currentDashboard.personGroupId > 0;
            const personGroup = this.state.personGroup ?? options.find(opt => +opt.value == currentDashboard.personGroupId);
            return (
                <div data-cy="dashboardNameInput">
                    <FormElement type="text" name="dashboard_name" label={i18n.t('NAME')} value={this.state.dashboardName}
                        onChange={(e) => this.setState({ dashboardName: e.target.value })} isDisabled={this.state.showSpinner} />
                    {HasRight(UserRights.DashboardPersonGroupEdit, settings.userRights) && 
                        <div>
                            <FormElement 
                                type="singleline-checkbox"
                                name="personGroupSpecific"
                                value={isPersonGroupDashboard}
                                label={i18n.t("PERSON_GROUP_SPECIFIC")}
                                onChange={() => null}
                                isDisabled={true}
                            />
                            {isPersonGroupDashboard &&
                                <FormElement 
                                    type="select-choice" 
                                    name="personGroup" 
                                    label={i18n.t('KAYTTAJARYHMA')} 
                                    value={personGroup}
                                    onChange={opt => this.setState({ personGroup: opt })} 
                                    isDisabled={this.state.showSpinner} 
                                    options={options}
                                />}
                        </div>}
                </div>
            )
        } else if (this.state.dialogType === 'dashboardDelete') {
            return (
                <React.Fragment>
                    <span>{i18n.t('CONFIRM_DASHBOARD_DELETION')}</span>
                    <span>{this.state.dashboards[this.state.activeDashboardIndex].name ?? ''}</span>
                </React.Fragment>
            )
        } else if (this.state.dialogType === 'actionFailed') {
            return (
                <p>{i18n.t('SEARCH_NOT_FOUND_OR_UNAUTHORIZED')}</p>
            )
        }
        return <></>
    }
    
    getDialogButtons = () => {
        switch (this.state.dialogType) {
            case 'dashboardAdd':
                return (
                    <Button
                        id="MainActionButton"
                        className="action action-button novi-default-btn-color"
                        variant="large"
                        onClick={this.addDashboard}
                        disabled={this.state.showSpinner || (this.state.personGroupSpecific && !this.state.personGroup?.value)}
                        data-cy={'saveNewDashboardButton'}>
                        {this.state.showSpinner ?
                            <FontAwesomeIcon icon={faSpinner} size="lg" pulse={true} /> : <div><FontAwesomeIcon icon={faPlus} size="lg" style={{ marginRight: '10px' }} /><span>{i18n.t('SAVE_AND_CREATE_NEW')}</span></div>
                        }
                    </Button>
                );
            case 'dashboardEdit':
                return (
                    <Button
                        id="MainActionButton"
                        className="action action-button novi-default-btn-color"
                        variant="large"
                        onClick={this.updateDashboard}
                        disabled={this.state.showSpinner || (this.state.personGroupSpecific && !this.state.personGroup?.value)}
                        data-cy={'saveNewDashboardButton'}>
                        {this.state.showSpinner ?
                            <FontAwesomeIcon icon={faSpinner} size="lg" pulse={true} /> : <div><FontAwesomeIcon icon={faSave} size="lg" style={{ marginRight: '10px' }} /><span>{i18n.t('SAVE')}</span></div>
                        }
                    </Button>
                );
            case 'dashboardDelete':
                return (
                    <Button
                        id="MainActionButton"
                        className="action action-button novi-default-btn-color"
                        variant="large"
                        onClick={this.deleteDashboard}
                        data-cy={'deleteDashboardButton'}>
                        <div>
                            <FontAwesomeIcon icon={faTrash} size="lg" style={{ marginRight: '10px' }} /><span>{i18n.t('REMOVE')}</span>
                        </div>
                    </Button>
                );
            case 'actionFailed':
                return (
                    <React.Fragment>
                        <Button onClick={() => this.setState({ dashboardDialogOpen: false, dialogType: '', dashboardItemId: null })}>
                            {i18n.t('OK')}
                        </Button>
                        {this.canEdit() && <Button onClick={() => this.setState({ dashboardDialogOpen: false, dialogType: '', useDashboardCustomizer: true })}>
                            {i18n.t('MUOKKAA')}
                        </Button>}
                    </React.Fragment>
                );
            default:
                return <></>;
        }
    }

    getDialog = () => {
        return (
            <DialogModal
                showDialog={this.state.dashboardDialogOpen}
                closeDialog={() => this.setState({ dashboardDialogOpen: false, dashboardItemId: null })}
            >
                <DialogHeader>
                    {i18n.t(getDialogInfo(this.state.dialogType).header)}
                </DialogHeader>
                <DialogBody>
                    {this.getDialogBodyContent()}
                </DialogBody>
                <DialogFooter>
                    {this.getDialogButtons()}
                </DialogFooter>
            </DialogModal>
        )
    }

    getArrowButtonRow = () => {
        const { activeDashboardIndex, dashboards } = this.state;

        if (dashboards.length < 1) {
            return;
        }

        const leftDisabled = activeDashboardIndex < 1;
        const rightDisabled = (dashboards.length - 1) <= activeDashboardIndex;

        const decreaseIndex = () => {
            const newIndex = activeDashboardIndex - 1;
            if (!leftDisabled) {
                this.changeDashboard(newIndex);
            }
        }

        const increaseIndex = () => {
            const newIndex = activeDashboardIndex + 1;
            if (!rightDisabled) {
                this.changeDashboard(newIndex);
            }
        }

        const leftArrow = (
            <div
                className={`${leftDisabled ? 'disabled-element' : ''} dashboard-nav-button`}
                onClick={decreaseIndex}
            >
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </div>
        );

        const rightArrow = (
            <div
                className={`${rightDisabled ? 'disabled-element' : ''} dashboard-nav-button`}
                onClick={increaseIndex}
            >
                <FontAwesomeIcon icon={faChevronRight} size="lg" />
            </div>
        );

        const activeDashboard = dashboards[activeDashboardIndex];
        const name = activeDashboard?.personId == null ? i18n.t(activeDashboard?.name) : activeDashboard?.name ?? "";

        return (
            <div className="dashboard-nav">
                {dashboards.length > 1 && leftArrow}
                <div style={{ whiteSpace: 'nowrap', width: '100%' }}>{name}</div>
                {dashboards.length > 1 && rightArrow}
            </div>
        );
    }

    getSetRef = (e) => {
        this.setState({ qrContainerRef: e })
    }

    handleClickOutside = (e) => {
        if (this.state.qrContainerRef && this.state.qrContainerRef !== e.target) {
            this.setState({
                qrContainerRef: null,
                activeScan: null
            });
            this.props.closeScanner('QR');
        }
    }
    
    openSearch = (componentTypeId, property, itemType, value) => {

        if (itemType == ItemTypes.ExtendedSearch) {
            this.setState({ searchModalOpen: { search: property } })
        }
        else {
            this.setState({ 
                searchModalOpen: {
                    search: property,
                    field: value
                }
             })
        }
    }

    getFilteringComponent = (dialogOpen = true) => {

        const viewType = this.state.searchModalOpen?.search;
        const field = this.state.searchModalOpen?.field;

        const closeFiltersDialog = () => {
            this.setState({ searchModalOpen: null });
        }

        const redirect = (path) => () => {
            this.props.history.replace(`/${path}`);
        }

        switch (viewType) {
            case 'sparepart':
                return (
                    <SparePartFiltering
                        closeDialog={closeFiltersDialog}
                        dialogOpen={dialogOpen}
                        callback={redirect("warehouse")}
                    />
                );
            case 'workcard':
                return (
                    <WorkCardFiltering
                        closeDialog={closeFiltersDialog}
                        dialogOpen={dialogOpen}
                        callback={redirect("worklist")}
                        limitToField={field}
                    />
                );

            // These can be uncommented and added later, needs some fixing though
            // case 'machine':
            //     return (
            //         <MachineFiltering
            //             closeDialog={closeFiltersDialog}
            //             filters={filters}
            //             dialogOpen={dialogOpen}
            //             callback={redirect("machines")}
            //         />
            //     );
            // case 'hourcard':
            //     return (
            //         <HourCardFiltering
            //             closeDialog={closeFiltersDialog}
            //             dialogOpen={dialogOpen}
            //             //callback={redirect("hourcards")}
            //         />
            //     )
            default:
                return null;
        }
    }

    handleQrResult = async () => {
        const { activeScan } = this.state;
        const { scanners } = this.props;
        
        let url: URL = null;
        try {
            url = new URL(scanners.QR.result);
        } catch (error) {
            try {
                const machine = (await noviAPI.machines.fetchByCode(scanners.QR.result)).data;
                this.setState({
                    machineCode: machine.code,
                    dialogOpen: true,
                    scannedItem: machine,
                    dialogProperty: 'machine'
                });
            } catch (error) {
                Toaster({ msg: i18n.t('ENTITY_NOT_FOUND'), type: 'warning' });
                this.props.clearScanResult('QR');
            }
            return;
        }

        const params = url.searchParams;
        const pathname = url.pathname.toString().toLowerCase();

        const isWorkCard = pathname.includes('workcardview');
        const isMachine = pathname.includes('machineview');
        const isSparePart = pathname.includes('sparepartview');
        const isOperatorMaintenence = pathname.includes('operatormaintenanceform');

        if (isMachine) {
            const machineId = Number(params.get('Id'));
            try {
                const machine = (await noviAPI.machines.fetch(machineId)).data;
                this.setState({
                    machineCode: machine.code,
                    dialogOpen: true,
                    scannedItem: machine,
                    dialogProperty: 'machine'
                });
            } catch (error) {
                Toaster({ msg: i18n.t('MACHINE_NOT_FOUND'), type: 'error' });
                this.props.clearScanResult('QR');
            }
        } else if (isWorkCard) {
            const workCardId = Number(params.get('Id'));
            this.props.history.push(`/workcard/${workCardId}`);
        } else if (isSparePart) {
            const sparePartId = Number(params.get('Id'));
            if (activeScan) {
                const { id, itemTypeId, componentTypeId, itemValue } = activeScan;
                const isInventScan = parseInt(itemValue) == DashboardItemValue.WAREHOUSE_INVENTORY_QR_READER;

                if (isInventScan) {
                    noviAPI.warehouseSparePartLinks.fetchSparePartWarehouseData(sparePartId)
                        .then(({ data }) => {
                            const inventFromSparePart = data[0]?.sparePart;
                            const warehouseOptions: IdLabel[] = data.reduce((options, { warehouse }) => {
                                return options.concat({ id: warehouse.id, label: warehouse.name });
                            }, []);

                            this.props.history.push(`/warehouseinvent`, { inventFromSparePart, warehouseOptions });
                        })
                        .catch(error => {
                            HandleError(error, "Fetch sparepart for invent");
                        })
                }
                else {
                    this.props.history.push(`/sparepart/${sparePartId}`);
                }
            }
            else {
                // For some reason activeScan wasn't set at all, redirect to sparePart anyway
                this.props.history.push(`/sparepart/${sparePartId}`);
            }
        } else if (isOperatorMaintenence) {
            const operatorMaintenenceId = Number(params.get('OMId'));
            this.props.history.push(`/operatormaintenance/${operatorMaintenenceId}`);
        } else {
            Toaster({ msg: i18n.t('ENTITY_NOT_FOUND'), type: 'warning' });
            this.props.clearScanResult('QR');
        }
    }

    canEdit = () => {
        const { settings } = this.props;
        const { dashboards, activeDashboardIndex } = this.state;
        const activeDashboard = dashboards[activeDashboardIndex];
        return (
            (activeDashboard.personGroupId === null && activeDashboard.personId === settings.userId && HasRight(UserRights.DashboardUserEdit, settings.userRights)) ||
            (activeDashboard.personId === null && HasRight(UserRights.DashboardEnvironmentEdit, settings.userRights)) ||
            (activeDashboard.personGroupId !== null && HasRight(UserRights.DashboardPersonGroupEdit, settings.userRights))
        )
    }
    
    canUseDashboardItemButton = (dashboardItem: IDashboardItem, userRights: IUserRight[]) => {
        if (dashboardItem.propertyType === 'workcard') {
            return HasRight(UserRights.WorkScheduleView, userRights);
        } else if (dashboardItem.propertyType === 'machine') {
            return HasRight([UserRights.MachineRegistryView, UserRights.FaultNotice, UserRights.WorkScheduleAdd], userRights);
        } else if (dashboardItem.propertyType === 'sparepart') {
            return HasRight(UserRights.WarehouseView, userRights);
        } else if (dashboardItem.propertyType === 'operatormaintenance') {
            return HasRight(UserRights.OperatorMaintenanceView, userRights);
        }
    }

    openActionFailedDialog = (dashboardItemId: number) => {
        this.setState({ 
            dashboardDialogOpen: true, 
            dialogType: 'actionFailed', 
            dashboardItemId: dashboardItemId
        });
    }

    render() {
        document.addEventListener('click', this.handleClickOutside, true);

        const { history, location, settings, defaultDashboardId } = this.props;
        const {
            useDashboardCustomizer,
            dialogOpen,
            scannedItem,
            dialogProperty,
            activeDashboardIndex,
            itemLoadingStatuses,
            dashboards
        } = this.state;

        const sceneData = {
            view: 'dashboard',
            title: null,
            location: location,
            history: history
        };

        const userData = settings.userAdNameDetails?.id ? settings.userAdNameDetails : settings.userDetails;
        const currentDashboard = dashboards[activeDashboardIndex];
        const factoryDefaultDashboard = dashboards?.find(i => i.personId === null) ?? { id: -1 };

        if (!currentDashboard) {
            return <></>;
        }

        const isFactoryDefaultDashboard = currentDashboard.id == factoryDefaultDashboard.id;
        const isDefaultDashboard = defaultDashboardId == currentDashboard.id;
        const dashboardEnvironmentEditAllowed = HasRight(UserRights.DashboardEnvironmentEdit, settings.userRights);
        const dashboardUserEditAllowed = HasRight(UserRights.DashboardUserEdit, settings.userRights);

        return (
            <div>
                {useDashboardCustomizer && this.canEdit() && <DashboardCustomizer
                    history={history}
                    location={location}
                    toggleCustomizer={this.toggleCustomizer}
                    currentDashboard={currentDashboard}
                    setDefaultDashboard={this.setDefaultDashboard}
                    factoryDefaultDashboard={factoryDefaultDashboard}
                    noviConfigs={settings.noviConfigs}
                    dashboardEnvironmentEditAllowed={dashboardEnvironmentEditAllowed}
                    dashboardItemId={this.state.dashboardItemId}
                    toggleModal={() => {
                        this.setState({ 
                            dashboardDialogOpen: true, 
                            dialogType: 'dashboardEdit', 
                            dashboardName: currentDashboard.name, 
                            personGroup: this.props.options.find(x => +x.value == currentDashboard.personGroupId)
                        });
                    }}
                />}
                {this.state.dashboardDialogOpen && this.getDialog()}
                {!useDashboardCustomizer && <div>
                    <NavigationBar
                        currentView={sceneData}
                        viewAction={HasRight('faultnotice', settings.userRights) ? 'newFaultNotice' : ''}
                    />
                    {/*TODO: FIX. Should not need to check dialogOpen outside DialogModal*/}
                    {dialogOpen && <DialogModal
                        closeDialog={this.closeDialog}
                        showDialog={dialogOpen}
                    >
                        <DialogHeader>
                            <label>{i18n.t(getDialogInfo(dialogProperty, scannedItem)?.headerLabel)}</label>
                            {i18n.t(getDialogInfo(dialogProperty, scannedItem)?.header)}
                        </DialogHeader>
                        <DialogBody>
                            {getDialogContent(dialogProperty, scannedItem, (settingName) => HasRight(settingName, settings.userRights))}
                        </DialogBody>
                    </DialogModal>}
                    {this.getFilteringComponent()}
                    <Container className="dashboard-container">
                        {this.getArrowButtonRow()}
                        <SwipeableViews index={activeDashboardIndex} onChangeIndex={this.changeDashboard} enableMouseEvents>
                            {dashboards.map(dashboard => (
                                <Row key={dashboard.id} style={{ marginLeft: '0', marginRight: '0' }}>
                                    {dashboard.dashboardModules.map(module => (
                                        module.dashboardItems.length > 0 &&
                                        <Flex key={module.id} flexWrap="wrap" className="dashboard-module">
                                            {module.dashboardItems
                                                .sort((a, b) => a.rowNumber - b.rowNumber)
                                                .map(dashboardItem => {
                                                    if (dashboardItem.componentTypeId === ComponentTypes.Button) {
                                                        return (
                                                            <Box key={dashboardItem.id} width={[1, 1 / 2]} p={2}>
                                                                <Card className="dashboard-button-panel">
                                                                    <DashboardItem
                                                                        eventKey={dashboardItem.id}
                                                                        componentTypeId={dashboardItem.componentTypeId}
                                                                        label={dashboardItem.label}
                                                                        openScannerWrapper={(openScanner) => {
                                                                            if (this.canUseDashboardItemButton(dashboardItem, settings.userRights)) {
                                                                                this.setState({ activeScan: dashboardItem });
                                                                                openScanner();
                                                                            } else {
                                                                                this.openActionFailedDialog(dashboardItem.id);
                                                                            }
                                                                        }}
                                                                    />
                                                                </Card>
                                                            </Box>);
                                                        }
                                                        else if (dashboardItem.componentTypeId === ComponentTypes.Search) {
                                                            return <Box key={dashboardItem.id} width={[1, 1 / 2]} p={2}>
                                                                <Card className="dashboard-button-panel">
                                                                    <DashboardItem
                                                                        componentTypeId={dashboardItem.componentTypeId}
                                                                        label={dashboardItem.label}
                                                                        property={dashboardItem.propertyType}
                                                                        dashboardItem={dashboardItem}
                                                                        openSearch={this.openSearch}
                                                                    />
                                                                </Card>
                                                            </Box>
                                                        
                                                    } else if (dashboardItem.componentTypeId === ComponentTypes.Link || dashboardItem.componentTypeId === ComponentTypes.SmallLink) {
                                                        return (
                                                            <Box key={dashboardItem.id} width={[1, 1 / 2]} p={2}>
                                                                <Card
                                                                    className="dashboard-panel narrow-container"
                                                                    onClick={() => this.handleClick(dashboardItem)}
                                                                >
                                                                    <DashboardItem
                                                                        componentTypeId={dashboardItem.componentTypeId}
                                                                        label={dashboardItem.label}
                                                                        unitLabel={i18n.t('PCS')}
                                                                        count={this.getItemCount(dashboardItem)}
                                                                        property={dashboardItem.propertyType}
                                                                        loadingStatus={itemLoadingStatuses[Number(dashboardItem.itemValue)]}
                                                                    />
                                                                </Card>
                                                            </Box>
                                                            );
                                                        }
                                                        else return <></>;
                                                    })}
                                            </Flex>
                                        ))}
                                        <Col xs={12}>
                                            
                                            {isDefaultDashboard 
                                                ? isFactoryDefaultDashboard ? <></> : <div className="dashboard-button" title={i18n.t('REMOVE_DEFAULT')} onClick={() => this.setDefaultDashboard(factoryDefaultDashboard.id)}>
                                                    <FontAwesomeIcon icon={faStar} size="2x" style={{ color: "orange" }}/>
                                                </div>
                                                : <div className="dashboard-button" title={i18n.t('SET_AS_DEFAULT')} onClick={() => this.setDefaultDashboard(currentDashboard.id)}>
                                                    <FontAwesomeIcon icon={faStar} size="2x" />
                                                </div>}
                                            {dashboard.personId && this.canEdit() && <div className="dashboard-button" title={i18n.t('DELETE')} onClick={() => this.setState({ dashboardDialogOpen: true, dialogType: 'dashboardDelete' })}>
                                                    <FontAwesomeIcon icon={faTrash} size="2x" />
                                                </div>}
                                            {this.canEdit() && <div className="dashboard-button" title={i18n.t('EDIT')} onClick={() => this.toggleCustomizer()}>
                                                <FontAwesomeIcon icon={faEdit} size="2x" />
                                            </div>}
                                            {dashboardUserEditAllowed && <div className="dashboard-button" title={i18n.t('ADD')} onClick={() => this.setState({ dashboardDialogOpen: true, dialogType: 'dashboardAdd', dashboardName: '', personGroup: null, personGroupSpecific: false })}>
                                                <FontAwesomeIcon icon={faPlus} size="2x" />
                                            </div>}
                                        </Col>
                                    </Row>)
                                )}
                            </SwipeableViews>
                        <ScannerRoot />
                    </Container>
                </div>}
            </div>
        );
    }
}

export default Dashboard;