import React from 'react';
import { Container } from 'react-bootstrap';
import '../../../styles/global.scss';
import './styles/worklist.scss';
import NavigationBar from '../../navigation';
import i18n from '../../../translations/i18n';
import 'react-toastify/dist/ReactToastify.css';
import PaginationContainer from '../../../components/PaginationContainer';
import { CountFilters, HandleError } from '../../../components/HelperFunctions';
import { toast } from 'react-toastify';
import settingsAPI from '../../../config/settingsAPI';
import { Loader } from '../../../components/Loader';
import WorkCards from './components/WorkCards';
import SearchResultInfo from '../../../components/SearchResultInfo';
import WorkListSettingsMenu from './components/WorkListSettingsMenu';
import { WorkListPropsFromRedux } from '.';
import noviAPI from '../../../api/noviAPI';
import { RouteChildrenProps } from 'react-router-dom';
import { DefaultQuickSearches } from 'constants/quickSearches/DefaultQuickSearches';
import { sortTypeKey, withStorage, StorageProps } from 'hooks/useLocalStorage';
import { DATE_FORMAT, getDateTimeBase } from 'utils';

type LocationState = { notificationMsg; heightPos; }
type IProps = WorkListPropsFromRedux & RouteChildrenProps<null, LocationState> & StorageProps;

interface IState {
    showAllPhases: object;
    hasInitialized: boolean;
    dialogOpen: boolean;
    sortType: IItemPropertyType;
}

const moduleSettings = settingsAPI.moduleData.worklist;

class WorkList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showAllPhases: {},
            hasInitialized: false,
            dialogOpen: false,
            sortType: props.getStorageSetting(sortTypeKey, 'worklist')
        };
    }

    componentDidMount() {

        const { location, quickSearchWorkCards, getWorkCards } = this.props;

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });

        // Do a quick search if there's a quick search selected
        if (this.props.activeQuickSearch !== null) {
            this.fetchWorkCards(quickSearchWorkCards);
        }
        // Otherwise fetch work cards normally
        else {
            this.fetchWorkCards(getWorkCards);
        }


        if (location.state) {
            if (location.state.notificationMsg) {
                toast.success(i18n.t(location.state.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete location.state.notificationMsg;
                this.props.history.replace({ state: location.state });

                if (this.props.activeQuickSearch !== null) {
                    this.fetchWorkCards(quickSearchWorkCards);
                }
                else {
                    this.fetchWorkCards(getWorkCards);
                }
            }

        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { location, settings, displaySettings, currentFilters, activeQuickSearch, quickSearchWorkCards, getWorkCards, wcsLoadStatus } = this.props;

        // Always do a quick search when a quick search is selected
        if (activeQuickSearch !== null && (prevProps.activeQuickSearch !== activeQuickSearch || this.state.sortType?.id !== prevState.sortType?.id)) {
            this.fetchWorkCards(quickSearchWorkCards);
        }
        // Fetch new work cards when the page number or the page size changes
        else if (prevProps.displaySettings.pageNumber !== displaySettings.pageNumber
            || prevProps.displaySettings.pageSize !== displaySettings.pageSize
            || prevProps.displaySettings.cardlayout !== displaySettings.cardlayout
        ) {
            // Check if a quick search is active
            if (activeQuickSearch !== null) {
                this.fetchWorkCards(quickSearchWorkCards);
            }
            else {
                this.fetchWorkCards(getWorkCards);
            }
        }
        // Fetch new work cards when default search is activated or unactivated
        else if (prevProps.displaySettings.useDefaultSearch !== displaySettings.useDefaultSearch
            // if machine group id (factory) is changed
            || prevProps.settings.machineGroupId !== settings.machineGroupId
            // or any amount of filters are set or unset
            || prevProps.currentFilters !== currentFilters)
        {
            this.fetchWorkCards(getWorkCards);
        }
        else if (this.state.sortType && this.state.sortType.id != prevState.sortType?.id) {
            this.fetchWorkCards(getWorkCards);
        }

        // Set scroll position if returning from workcard or workphase view
        if (prevProps.wcsLoadStatus !== wcsLoadStatus && wcsLoadStatus === 'fulfilled') {
            if (location.state?.heightPos) {
                window.scrollTo(0, location.state?.heightPos);
                delete location.state.heightPos;
                this.props.history.replace({ state: location.state });
            }

            this.initWorkCardColors();
        }
    }

    fetchWorkCards = (fetchFunction) => {
        fetchFunction(this.state.sortType)
            .then(async () => {

                if (!this.state.hasInitialized) {

                    await Promise.allSettled([
                        this.initQuickSearches(),
                        this.initWorkCardSettings(),
                        this.props.fetchSearches('workCardSearches')
                    ]);

                    this.setState({ hasInitialized: true });

                }

            })
            .catch(error => {
                HandleError(error, "Fetch and init workcards");
            })
    }

    updateSortType = (newType) => {
        this.setState({ sortType: newType });
    }

    initWorkCardSettings = async () => {
        const workCardSettings = await this.getWorkCardSettings();
        this.props.setWorkCardSettings(workCardSettings);

        const workStatuses = await this.getWorkStatuses();
        this.props.setWorkStatuses(workStatuses);

        const urgencies = await this.getUrgencies();
        this.props.setUrgencies(urgencies);
    }

    initWorkCardColors = async () => {
        this.props.getWorkCardColors();
    }

    initWorkCardDetails = async () => {
        const workCardDetails = await this.getWorkCardDetails();
        this.props.setWorkCardDetails(workCardDetails);
    }

    initQuickSearches = async () => {
        const quickSearchGroup = moduleSettings.quickSearchType;
        const quickSearches = await this.getQuickSearchesByGroup(quickSearchGroup);
        this.props.setQuickSearches(quickSearches, quickSearchGroup);
    }

    getWorkCardSettings = async () => {
        const response = await noviAPI.workCardSettings.fetchAll();
        return response.data;
    }

    getWorkStatuses = async () => {
        const response = await noviAPI.workCardSettings.fetchWorkStatuses();
        return response.data;
    }

    getUrgencies = async () => {
        const response = await noviAPI.workCardSettings.fetchUrgencies();
        return response.data;
    }

    getWorkCardDetails = async () => {
        const response = await noviAPI.workCardDetails.fetchAll(this.props.settings.machineGroupId);
        return response.data;
    }

    getQuickSearchesByGroup = async (group: string) => {
        const response = await noviAPI.quickSearches.fetchAllByGroup(this.props.settings.machineGroupId, group);
        return response.data;
    }

    setPageNumber = (value: number) => {
        this.props.saveWorkListSettings({ pageNumber: value });
    }

    formQuickSearchHierarchy = (quickSearchData) => {
        let i = 0;
        let optsLength = quickSearchData.length;

        do {
            const item = quickSearchData[i];

            if (item && item.parentId) {
                const parentOpt = quickSearchData.find(parent => parent.value === item.parentId);

                // search how many children option has
                let childOpts = 0;
                quickSearchData.forEach(opt => {
                    if (opt.parentId === item.value) {
                        childOpts++;
                    }
                });

                // if no child options, push it to its parent options list
                if (childOpts === 0 && parentOpt) {
                    quickSearchData[quickSearchData.indexOf(parentOpt)].options.push({ ...item });
                    quickSearchData[quickSearchData.indexOf(item)].deleteMe = true;
                }
                // if item's options list equals with its child count and item itself is a child of some another item, 
                // push item to that item's options list
                else if (childOpts === quickSearchData[i].options.length && parentOpt) {
                    quickSearchData[quickSearchData.indexOf(parentOpt)].options.push({ ...item });
                    quickSearchData[quickSearchData.indexOf(item)].deleteMe = true;
                }
            }
            i = i + 1;
            optsLength = optsLength - 1;
        } while (optsLength > 0);

        return quickSearchData.filter(opt => (!opt.deleteMe));
    }

    getQuickSearchData = () => {
        const { worklistQuickSearches, workCardSearches } = this.props;

        //TODO: resolve unidentified data issue on "worklistQuickSearches"
        let quickSearches = worklistQuickSearches.map(quickSearchOpt => ({ ...quickSearchOpt, label: i18n.t(quickSearchOpt.label), options: [], isSqlSearch: true }))
        let quickSearchHierarchyData = this.formQuickSearchHierarchy(quickSearches);
        let workCardSearchOptions = workCardSearches
            .map(opt => ({ value: opt.id, label: opt.name, ...opt }))
            .sort((a, b) => a.label.localeCompare(b.label));

        quickSearchHierarchyData.push({
            value: -2,
            label: i18n.t(moduleSettings.defaultQuickSearchData.label),
            options: moduleSettings.defaultQuickSearchData.options
                .filter(opt => opt.value != DefaultQuickSearches.OWN_DEFAULT_SEARCH)
                .map(option => ({ value: option.value, label: i18n.t(option.label) })
            )
        });
        quickSearchHierarchyData.push({
            value: -3,
            label: i18n.t('PERSONAL_SEARCHES'),
            options: workCardSearchOptions.filter(opt => opt.personId !== null)
        });
        quickSearchHierarchyData.push({
            value: -4,
            label: i18n.t('MACHINE_GROUP_SPECIFIC_SEARCHES'),
            options: workCardSearchOptions.filter(opt => opt.personId === null)
        });

        return quickSearchHierarchyData;
    }

    getTimeSpan = () => {
        const { currentFilters } = this.props;

        if (!currentFilters) return;

        let dateStart = currentFilters.startDate
            ? getDateTimeBase(currentFilters.startDate).format(DATE_FORMAT)
            : null;
        let dateEnd = currentFilters.endDate
            ? getDateTimeBase(currentFilters.endDate).format(DATE_FORMAT)
            : null;

        let timeSpan = i18n.t('TIME_PERIOD') + ': ';
        if (!dateStart && !dateEnd) return '';

        if (dateStart) timeSpan = timeSpan + dateStart + ' - ';
        if (dateStart && dateEnd) timeSpan = timeSpan + dateEnd;
        if (dateEnd && !dateStart) timeSpan = timeSpan + ' - ' + dateEnd;

        return timeSpan;
    }

    toggleCardPhases = wcId => {
        this.setState({
            showAllPhases: {
                ...this.state.showAllPhases, [wcId]: this.state.showAllPhases[wcId] ? false : true
            }
        });
    }

    toggleSettingsMenuDialog = () => {
        this.setState(state => ({
            dialogOpen: !state.dialogOpen
        }));
    }

    closeDialog = () => {
        this.setState(state => ({
            dialogOpen: false
        }));
    }

    render() {
        const {
            workcards,
            wcsLoadStatus,
            settings,
            location,
            history,
            currentFilters,
            displaySettings,
            totalResultCount,
            totalPageCount,
            activeQuickSearch
        } = this.props;

        const {
            showAllPhases,
            dialogOpen
        } = this.state;

        const enabledPhases = settings['noviConfigs'] && settings['noviConfigs'].EnablePhases;
        const sceneData = {
            view: moduleSettings.name,
            title: null,
            filtersCount: CountFilters(currentFilters),
            location: location,
            history: history,
            currentFilters: currentFilters,
            quickSearch: activeQuickSearch,
            dialogOpen: dialogOpen,
            closeDialog: this.closeDialog,
            toggleDialog: this.toggleSettingsMenuDialog
        };

        return (
            <div>
                <NavigationBar
                    currentView={sceneData}
                    quickSearchOptions={this.getQuickSearchData()}
                    viewAction={"settings"}
                    filters={true}
                    popover={
                        <WorkListSettingsMenu
                            enabledPhases={enabledPhases === 'True'}
                            closeDialog={this.closeDialog}
                            dialogOpen={dialogOpen}
                            toggleDialog={this.toggleSettingsMenuDialog}
                        />
                    }
                    updateSortType={this.updateSortType}
                />
                <div className="work-card-container">
                    <Container className="bottom-margin-100" fluid>
                        <div className="v-paddings-15">
                            <SearchResultInfo
                                pageSize={displaySettings.pageSize}
                                pageNumber={displaySettings.pageNumber}
                                totalResultCount={totalResultCount}
                            />
                            {this.props.currentFilters &&
                                <span className="sub-header align-right float-right">
                                    {this.getTimeSpan()}
                                </span>
                            }
                        </div>
                        {wcsLoadStatus === 'fulfilled' && totalResultCount === 0 && <div className="no-results"><p>{i18n.t('NO_RESULTS')}</p></div>}
                        {workcards.length > 0 && <PaginationContainer
                            currentPage={displaySettings.pageNumber}
                            setCurrentPage={this.setPageNumber}
                            pageCount={totalPageCount}
                        />}
                        <Loader status={wcsLoadStatus} />
                        {wcsLoadStatus === 'fulfilled' && <WorkCards
                            cardList={workcards}
                            showPhases={enabledPhases && displaySettings.displayphases}
                            cardLayout={displaySettings.cardlayout}
                            showAllPhases={enabledPhases && showAllPhases}
                            togglePhases={this.toggleCardPhases}
                        />}
                        {workcards.length > 0 && <PaginationContainer
                            currentPage={displaySettings.pageNumber}
                            setCurrentPage={this.setPageNumber}
                            pageCount={totalPageCount}
                        />}
                    </Container>
                </div>
            </div>
        );
    }
}

export default withStorage(WorkList);
