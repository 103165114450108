import React from 'react';
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import noviAPI from '../../../api/noviAPI'
import NavigationBar from '../../navigation'
import settingsAPI from '../../../config/settingsAPI'
import i18n from '../../../translations/i18n'
import { Loader } from '../../../components/Loader'
import { ScrollToTop } from '../../../components/ScrollToTop'
import { Toaster } from '../../../components/HelperFunctions';
import axios from 'axios';
import MeasurementForm from 'components/measurement/MeasurementForm';
import measurementUtils from 'components/measurement/utils';

const moduleSettings = settingsAPI.moduleData.editMeasurement;

type LocationState = { workCardId?: number; measurementGroupId?: number; };
type Values = { [name: string]: any; };

const EditMeasurement = () => {
    const settings = useSelector((state: State) => state.settings);

    let location = useLocation<LocationState>();
    let history = useHistory();

    const [values, setValues] = useState<Values>({});
    const [showLoader, setShowLoader] = useState('pending');
    const [measurementGroups, setMeasurementGroups] = useState<{ value: string, label: string }[]>([]);
    const [measurementQuestions, setMeasurementQuestions] = useState<IMeasurementQuestion[]>([]);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    const fetchMeasurementGroupSpecificDetails = (mGroupId) => {
        // Fetch measurement questions
        noviAPI.measurementQuestions.getByGroup(mGroupId)
            .then(qResponse => {
                if (qResponse.data?.length >= 0) {
                    setMeasurementQuestions(qResponse.data)
                }

                // Fetch existing measurement values
                noviAPI.measurementValues.fetchAllByGroupAndWorkCard(mGroupId, location.state.workCardId)
                    .then(response => {
                        /* Handle existing measurement values */
                        if (response?.data?.length > 0) {
                            response.data.forEach(mValue => {
                                // Define question type
                                const questionType = measurementUtils.questionTypes[mValue.measurementQuestion.type];
                                if (!questionType) return;
                                // Search possible question option as selection when measurement value is select type (useOptions) and it has value
                                const question = qResponse?.data?.find(opt => opt.id === mValue.measurementQuestion?.id && opt.useOptions);
                                const qOption = question?.valueOptions.find(opt => opt[questionType] === mValue[questionType]);

                                // Set existing values in fields and store their id -values for update purposes 
                                setValues(prevState => ({
                                    ...prevState,
                                    [mValue.measurementQuestion.caption]: qOption
                                        ? { id: qOption.id, name: qOption[questionType] }
                                        : mValue[questionType],
                                    mValueIds: {
                                        ...prevState.mValueIds,
                                        [mValue.measurementQuestion.caption]: mValue.id
                                    }
                                }))
                            });

                        } else {
                            // Reset possible id values when there new measurement is chosen
                            setValues(prevState => ({
                                ...prevState,
                                mValueIds: null
                            }));
                        }
                    })
                    .catch(() => {
                        setShowErrorMessage(true);
                    })
            })
            .catch(() => {
                setShowErrorMessage(true);
            })
    }

    useEffect(() => {
        noviAPI.measurementGroups.fetchAll(settings.machineGroupId)
            .then(response => {
                if (response.data?.length >= 0) {
                    setMeasurementGroups(response.data.map(mGroup => ({ value: mGroup.id.toString(), label: mGroup.name })))
                }

                if (location.state?.measurementGroupId) {
                    const mGroup = response.data.find(mg => mg.id === location.state?.measurementGroupId);
                    if (mGroup) {
                        setValues({ 'measurementGroup': { value: mGroup.id.toString(), label: mGroup.name } });
                        fetchMeasurementGroupSpecificDetails(mGroup.id);
                    }
                }
            })
            .catch(() => {
                setShowErrorMessage(true);
            })

        setShowLoader('fulfilled');
    }, [settings.machineGroupId]);

    useEffect(() => {
        if (values.measurementGroup) {
            setShowErrorMessage(false);
            fetchMeasurementGroupSpecificDetails(values.measurementGroup.value);
        }
    }, [values.measurementGroup])

    const getBackButtonConfig = () => { 
        if (hasChanged === true) {
            return { action: history.goBack, params: {mainEditView: true}};
        }
        return null;
    }

    const sceneData = {
        view: moduleSettings.name,
        title: i18n.t('MEASUREMENTS'),
        location: location,
        history: history,
        backAction: getBackButtonConfig(),
        hasChanged: hasChanged
    }

    const viewAction = {
        icon: 'save',
        label: '',
        clickFn: () => handleSubmit(),
        isActionFn: true,
    }

    const handleSubmit = async () => {
        // Update measurement values
        if (values.mValueIds) {
            let mValues: { id: number; value: IMeasurementValueUpdate }[] = [];

            measurementQuestions.forEach(mQ => {
                if (values.mValueIds[mQ.caption]) {
                    let measurementValue = measurementUtils.mapValueToMeasurementValueAddition(mQ, values[mQ.caption]);
                    measurementValue.workCardId = location.state.workCardId;
                    mValues.push({
                        id: values.mValueIds[mQ.caption],
                        value: measurementValue
                    });
                }
            });

            // Update all values before reponse and redirecting back to previous scene
            axios.all(mValues.map(mValue => {
                return noviAPI.measurementValues.update(mValue.id, mValue.value)
                    .then(response => {
                        return response;
                    });
            })).then(responses => {
                Toaster({ msg: 'MEASUREMENT_UPDATE_SUCCESS', type: 'success' });
                history.goBack();
            });
        } else {
            // Add new measurement group workcard and measurement values
            const measurementGroupWorkCard: IMeasurementGroupWorkCardAddition = {
                measurementGroupId: values.measurementGroup.value,
                workCardId: location.state.workCardId,
                measurementValues: measurementQuestions.map(mQ => {
                    let measurementValue = measurementUtils.mapValueToMeasurementValueAddition(mQ, values[mQ.caption]);
                    measurementValue.workCardId = location.state.workCardId;
                    return measurementValue;
                })
            };

            noviAPI.measurementGroupWorkCards.add(measurementGroupWorkCard)
                .then(response => {
                    if (response.status === 200) {
                        Toaster({ msg: 'ADD_MEASUREMENT_SUCCESS', type: 'success' });
                        history.goBack();
                    }
                })
        }
    }

    return (
        <div>
            <ScrollToTop />
            <NavigationBar
                currentView={sceneData}
                popoverData={''}
                viewAction={viewAction}
            />
            <Loader status={showLoader} />
            <MeasurementForm
                showErrorMessage={showErrorMessage}
                measurementGroup={values.measurementGroup}
                measurementGroups={measurementGroups}
                measurementQuestions={measurementQuestions}
                measurementValues={values}
                setMeasurementGroup={(value) => setValues({ ...values, measurementGroup: value })}
                setMeasurementValues={setValues}
                setHasChanged={setHasChanged}
            />
        </div>
    );
}

export default EditMeasurement;
