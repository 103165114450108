import React from 'react';
import ActionButton from '../buttons/ActionButton';
import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';
import DialogModal from './DialogModal';
import { isNullOrWhitespace } from '../HelperFunctions';
import i18n from '../../translations/i18n';

const DialogWithTextarea = (props: IProps) => {
    return (
        <DialogModal
            showDialog={props.showDialog}
            closeDialog={() => props.setShowDialog(false)}
        >
            <DialogHeader>
                {props.headerText}
            </DialogHeader>
            <DialogBody>
                <textarea
                    onChange={e => props.onChange(e)}
                    value={props.textareaValue}
                />
            </DialogBody>
            <DialogFooter>
                <ActionButton
                    handleClick={props.onButtonClick}
                    disabled={props.textareaValueRequired ? isNullOrWhitespace(props.textareaValue) : false}
                >
                    {i18n.t('OK')}
                </ActionButton>
            </DialogFooter>
        </DialogModal>
    );
}

interface IProps {
    headerText: string;
    textareaValue: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    showDialog: boolean;
    setShowDialog: (s: boolean) => void;
    onButtonClick: () => void;
    textareaValueRequired?: boolean;
}

export default DialogWithTextarea;