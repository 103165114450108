import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FormElement from './FormElement';
import i18n from '../../../../translations/i18n';

const RoutePhaseForm = ({ onChange, onDateChange, handleSelect, routePhase, stateData, hourCardFields = [] }) => {
    const isCompleted = (routePhase.isComplete || routePhase.skipped) ? true : false;
    const doneOptions = [
        { id: 1, label: i18n.t('ROUTEMAINTENANCE_COMPLETED') },
        { id: 2, label: i18n.t('ROUTEMAINTENANCE_SKIPPED') }
    ];

    return (
        <div className={isCompleted ? 'disabled-element' : ''}>
            <Row>
                <Col xs={12} className={stateData.invalidFields.includes('isDone' + routePhase.id) ? 'invalid-field' : ''} data-cy={'isdone_test'}>
                    <FormElement
                        name={'isDone' + routePhase.id}
                        type={'singleline-select'}
                        label={'IS_DONE'}
                        value={stateData['isDone' + routePhase.id]}
                        onChange={(value) => handleSelect(value, { name: 'isDone' }, routePhase.id)}
                        options={doneOptions}
                        isDisabled={isCompleted}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} className={stateData.invalidFields.includes('comment' + routePhase.id) ? 'invalid-field' : ''} data-cy={'comment_test'}>
                    <FormElement
                        name={'comment' + routePhase.id}
                        type={'textarea'}
                        label={'ROUTE_MAINTENANCE_PHASE_COMMENT'}
                        value={stateData['comment' + routePhase.id]}
                        onChange={onChange}
                        options={[]}
                        isDisabled={isCompleted}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={9} className={stateData.invalidFields.includes('doneDate' + routePhase.id) ? 'invalid-field' : ''} data-cy={'donedate_test'}>
                    <FormElement
                        name={'doneDate' + routePhase.id}
                        type={'datetime'}
                        label={'COMPLETED_AT'}
                        value={stateData['doneDate' + routePhase.id]}
                        onChange={onDateChange}
                        options={[]}
                        isDisabled={isCompleted}
                    />
                </Col>
                {hourCardFields.length === 0 && <Col xs={3} className={stateData.invalidFields.includes('workHours' + routePhase.id) ? 'invalid-field' : ''} data-cy={'workhours_test'}>
                    <FormElement
                        name={'workHours' + routePhase.id}
                        type={'float-input'}
                        label={'ROUTE_MAINTENANCE_PHASE_WORK_HOURS'}
                        value={stateData['workHours' + routePhase.id]}
                        onChange={onChange}
                        options={[]}
                        isDisabled={isCompleted}
                    />
                </Col>}
                {hourCardFields.length > 0 && <div>
                    {hourCardFields.map(setting => (
                        <Col xs={12} key={setting.field}>
                            <FormElement
                                name={setting.field + routePhase.id}
                                type={setting.type}
                                label={setting.translationKey}
                                value={stateData[setting.field + routePhase.id]}
                                onChange={setting.type === 'select' ? (value) => handleSelect(value, { name: setting.field }, routePhase.id) : onChange}
                                options={setting.options}
                                isDisabled={!stateData['isDone' + routePhase.id] || stateData['isDone' + routePhase.id]?.id === 2}
                                required={stateData['isDone' + routePhase.id]?.id === 1}
                            />
                        </Col>))
                    }
                </div>}
            </Row>
        </div>
    );
}

export default RoutePhaseForm;
