export enum ItemTypes {
    Default = 1,
    WorkCardSQL = 2,
    MachineSQL = 3,
    WarehouseSQL = 4,
    Scanner = 5,
    WorkCardSavedSearch = 6,
    MachineSavedSearch = 7,
    ExtendedSearch = 8,
    FieldSearch = 9
}