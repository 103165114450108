
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CountFilters as countActiveFilters, HasRight } from '../../../components/HelperFunctions';
import PopoverAction from '../../../components/PopoverAction';
import { PAGE_SIZES } from '../../../constants/displaySettings';
import { UserRights } from '../../../constants/userRights';
import i18n from '../../../translations/i18n';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { SET_SAFETY_SETTINGS } from 'config/actionTypes';

interface IProps {
    toggleDialog?: () => void;
    dialogOpen?: boolean;
    safetyTypeId: string | number;
}

const SafetyCardsPopover = (props: IProps) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const userRights = useSelector((state: State) => state.settings.userRights);

    const { settings: displaySettings } = useSelector((state: State) => state.safetycards);

    const setDisplaySetting = (setting: IDisplaySetting) => {
        dispatch({
            type: SET_SAFETY_SETTINGS,
            payload: setting
        });
    }

    const toggleLayout = (value: boolean) => {
        if (displaySettings.cardLayout !== value) {
            setDisplaySetting({ cardLayout: value });
        }
    }

    const setPageSize = (value: number) => {
        if (displaySettings.pageSize !== value) {
            setDisplaySetting({ pageSize: value });
        }
    }

    const setUseDefaultSearch = (value: boolean) => {
        if (displaySettings.useDefaultSearch !== value) {
            setDisplaySetting({ useDefaultSearch: value });
        }
    }

    const addNewSafetyNotice = () => {
        history.push(`/safetynotices/${props.safetyTypeId}/new`);
    }

    let settingsMenuHeadings = [];
    let settingsMenuContents = [];

    settingsMenuHeadings.push(i18n.t('LISTING_TYPE'));
    settingsMenuContents.push(<div key={settingsMenuContents.length}>
        <PopoverAction
            icon="cards"
            label={i18n.t('LAYOUT_CARDS')}
            active={displaySettings.cardLayout}
            onClick={() => toggleLayout(true)}
            inline
        />
        <PopoverAction
            icon="table"
            label={i18n.t('LAYOUT_TABLE')}
            active={!displaySettings.cardLayout}
            onClick={() => toggleLayout(false)}
            inline
            disabled={true}
        />
        <hr />
    </div>);

    settingsMenuHeadings.push(i18n.t('DISPLAY_AMOUNT'));
    settingsMenuContents.push(<div key={settingsMenuContents.length}>
        <div className="small-options-list">
            <PopoverAction
                label={PAGE_SIZES.OPT_1.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_1}
                onClick={() => setPageSize(PAGE_SIZES.OPT_1)}
                inline
            />
            <PopoverAction
                label={PAGE_SIZES.OPT_2.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_2}
                onClick={() => setPageSize(PAGE_SIZES.OPT_2)}
                inline
            />
            <PopoverAction
                label={PAGE_SIZES.OPT_3.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_3}
                onClick={() => setPageSize(PAGE_SIZES.OPT_3)}
                inline
            />
            <PopoverAction
                label={PAGE_SIZES.OPT_4.toString()}
                active={displaySettings.pageSize === PAGE_SIZES.OPT_4}
                onClick={() => setPageSize(PAGE_SIZES.OPT_4)}
                inline
            />
        </div>
        <hr />
    </div>);

    settingsMenuContents.push(<div key={settingsMenuContents.length}>
        <PopoverAction
            icon="search"
            label={i18n.t('RESET_SEARCH')}
            active={displaySettings.useDefaultSearch}
            onClick={() => setUseDefaultSearch(!displaySettings.useDefaultSearch)}
        />
        <hr />
    </div>);

    if (HasRight(UserRights.SafetyAdd, userRights)) {
        settingsMenuContents.push(<div data-cy={'new_safety_notice'} key={settingsMenuContents.length}>
            <PopoverAction
                icon="add-square"
                label={i18n.t(`NEW_SAFETY_NOTICE${props.safetyTypeId}`)}
                onClick={addNewSafetyNotice}
            />
        </div>);
    }

    return (
        <div>
            {props.dialogOpen && <div className="overlay-canvas" onClick={() => props.toggleDialog()} ></div>}
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={<Popover id="settings-menu-popover" className="menu-popover">
                    {React.Children.map(settingsMenuContents, (child, i) => (
                        <div className="sub-section" key={`setting_${i}`}>
                            {settingsMenuHeadings?.[i] &&
                                <div className="setting-header">
                                    {settingsMenuHeadings[i]}
                                </div>
                            }
                            <div className="setting-option">
                                {child}
                            </div>
                        </div>
                    ))}
                </Popover>}
            >
                <Button
                    id="footerMenuButton"
                    variant="primary"
                    className="action action-button novi-default-btn-color"
                    size="lg"
                    onClick={() => props.toggleDialog()}
                >
                    <div>
                        <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
                    </div>
                </Button>
            </OverlayTrigger>
        </div>
    );
}

export default SafetyCardsPopover;