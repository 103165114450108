import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

interface Props {
    filters: any;
    toggleFiltersDialog: any;
}

class Search extends React.Component<Props, {}> {
    render() {
        return (
            <div className="header-element-container" onClick={this.props.toggleFiltersDialog}>
                <div className="header-action-element">
                    {this.props.filters.count > 0 && <span className="upper-number-badge">
                        {this.props.filters.count}
                    </span>}
                    <div className={(this.props.filters.count > 0 ? '' : '')}>
                        <FontAwesomeIcon icon={faFilter} size="2x" />
                    </div>
                </div>
            </div>  

        );
    }
}

export default Search;