import React from 'react';
import '../../../styles/global.scss';
import { Container } from 'react-bootstrap';
import SparePartsRow from './SparePartsRow';
import { useSelector } from 'react-redux';

interface Props {
    sparePartLinks: any[];
    warehousesEnabled: boolean;
    toggleDialog?: (spId: number) => void;
    dialogOpen?: any;
    closeDialog?: () => void;
}

const THIRD_PARTY_TYPES = {
    supplier: 1,
    manufacturer: 2
}

const WarehouseSpareParts = (props: Props) => {
    const currentWarehouse = useSelector((state: State) => state.warehouse.currentWarehouse);
    const getThirdPartiesByType = (thirdPartiesByTypes: IThirdPartiesByType[], type: string): IThirdParty[] => {
        let thirdParties = [];
        if (thirdPartiesByTypes) {
            for (const thirdPartiesByType of thirdPartiesByTypes) {
                if (THIRD_PARTY_TYPES[type] === thirdPartiesByType.type.id) {
                    thirdParties = thirdPartiesByType.thirdParties;
                }
            }
        }
        return thirdParties;
    }

    return (
        <Container>
            {props.sparePartLinks.map((sparePartLink, i) => {
                const filteredLinks = props.warehousesEnabled 
                    ? sparePartLink.warehouseSparePartLinks 
                    : sparePartLink.warehouseSparePartLinks.filter(link => link.warehouse?.id == currentWarehouse?.id);

                return <SparePartsRow
                    key={i}
                    id={sparePartLink.sparePart.id}
                    code={sparePartLink.sparePart.code}
                    name={sparePartLink.sparePart.name}
                    amount={filteredLinks[0]?.amount || 0}
                    shelfLocation={filteredLinks[0]?.shelfLocation || ''}
                    manufacturers={getThirdPartiesByType(sparePartLink.sparePart.thirdPartiesByTypes, 'manufacturer')}
                    suppliers={getThirdPartiesByType(sparePartLink.sparePart.thirdPartiesByTypes, 'supplier')}
                    editPath={`/warehouselink/${filteredLinks[0]?.id}/edit`}
                    bgColor={i % 2 === 0 ? '#181818' : '#272727'}
                    listWarehouses={props.warehousesEnabled}
                    sparePartWarehouseData={filteredLinks}
                    toggleDialog={props.toggleDialog}
                    dialogOpen={props.dialogOpen}
                    closeDialog={props.closeDialog}
                />
            })}
        </Container>
    );
}

export default WarehouseSpareParts;