import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import Dashboard from './Dashboard';
import { fetchUserRights, setQuickSearch, resetQuickSearch, fetchFaultNoticeDefaultText, fetchPersonGroups, fetchUsersPersonGroups, fetchSearches, fetchPersonMachineGroupSetting } from '../../commonActions/actions';
import { saveWorkListSettings, resetCurrentFilters as resetWorkCardFilters } from '../work-schedule/work-list/actions';
import {
    fetchDashboardItemTypes,
    fetchDashboardTypeOptions,
    updateDashboard,
    updateFavouriteDashboard
} from './actions';
import { clearScanResult, closeScanner, openScanner, setScanResult } from '../../components/scanners/actions';
import { resetCurrentWarehouseFilters } from '../warehouse/actions';
import { mapIdNameToOptionType } from 'scenes/machines/utils';

const mapStateToProps = (state: State) => {
    const { machineGroupId, userId } = state.settings;
    const defaultDashboardSetting = state.settings.personMachineGroupSettings.find(s => s.group === 'defaultDashboard' && s.machineGroupId == machineGroupId && s.personId == userId);

    return {
        settings: state.settings,
        scanners: state.scanners,
        isActiveQuickSearch: state.navigation.activeQuickSearches.worklist !== null,
        workCardFilters: state.workcards.currentFilters,
        user: state.settings.userAdNameDetails?.id ? state.settings.userAdNameDetails : state.settings.userDetails,
        dashboardUpdateStatus: state.dashboard.dashboardUpdateStatus,
        defaultFaultNoticeText: state.workcards.faultNoticeDefaultText,
        options: (state.settings.personGroups ?? []).map(mapIdNameToOptionType),
        defaultDashboardId: parseInt(defaultDashboardSetting?.value) || null
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    clearScanResult,
    setQuickSearch,
    resetQuickSearch,
    saveWorkListSettings,
    resetWorkCardFilters,
    fetchDashboardItemTypes,
    fetchDashboardTypeOptions,
    closeScanner,
    resetCurrentWarehouseFilters,
    fetchFaultNoticeDefaultText,
    fetchPersonMachineGroupSetting,
    fetchPersonGroups,
    updateDashboard,
    fetchUserRights,
    fetchUsersPersonGroups,
    fetchSearches,
    updateFavouriteDashboard
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Dashboard);
