import React, { useState } from 'react';
import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashboardName = (props: IProps) => {
    const [isEdit, setIsEdit] = useState(false);

    return (
        <div className="dashboard-name">
            {isEdit
                ? <div style={{ whiteSpace: 'nowrap', width: '100%' }}>
                    <input
                        placeholder="Name of the dashboard"
                        className="quick-edit-input"
                        name={'dashboardName'}
                        type="text"
                        value={props.name}
                        onChange={props.onChange}
                        maxLength={35}
                    />
                </div>
                : <div style={{ whiteSpace: 'nowrap', width: '100%' }}>
                    {props.name}
                </div>
            }
            {isEdit
                ? <div className="left-margin-5" onClick={() => setIsEdit(false)}>
                    <FontAwesomeIcon icon={faCheck} size="sm" />
                </div>
                : <div className="left-margin-5" onClick={() => setIsEdit(true)}>
                    <FontAwesomeIcon icon={faPen} size="sm" />
                </div>
            }
        </div>
    )
}

interface IProps {
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default DashboardName;