import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import WorkList from './WorkList';
import {
    getWorkCards,
    saveWorkListSettings,
    defaultSearch,
    resetCurrentFilters,
    quickSearchWorkCards,
    setWorkCardSettings,
    setWorkCardDetails
} from './actions';
import {
    fetchViewSettings,
    setWorkStatuses,
    setUrgencies,
    setQuickSearches,
    getWorkCardColors,
    fetchWorkerGroups,
    fetchSearches
} from './../../../commonActions/actions';
import { fetchWorkcardOptions } from './../work-card/actions';
import moment from 'moment';
import { DefineColors, ToArray } from '../../../components/HelperFunctions';
import { getInputDateTime } from 'utils';

const processItems = (wcs, filters = null, userId, wTypes = [], colorConfigData, phasesEnabled, statusTypes) => {
    const wcColorConfigs = {
        colors: colorConfigData.colors,
        settings: colorConfigData.colorSettings,
        config: colorConfigData.config['FillTags']
    }
    const workPhaseColorConfigs = {
        colors: colorConfigData.colors,
        settings: colorConfigData.colorSettings,
        config: colorConfigData.config['FillPhaseTags']
    }

    // Determine actual workcard status types
    let openTypes = [];
    let startedTypes = [];
    let cancelledTypes = [];
    let doneTypes = [];
    statusTypes.forEach(sType => {
        if (sType.statusValue === 1) {
            openTypes.push(sType.id);
        } else if (sType.statusValue === 2) {
            startedTypes.push(sType.id);
        } else if (sType.statusValue === 3) {
            cancelledTypes.push(sType.id);
        } else if (sType.statusValue === 4) {
            doneTypes.push(sType.id);
        }
    });

    const user = parseInt(userId, 10);
    const findItem = function (wc) {
        if (!filters) {
            wc.colors = DefineColors(wc, wcColorConfigs);
            if (wc.phases && wc.phases.length > 0) {
                wc.phases.forEach(wp => {
                    wp.colors = DefineColors(wp, workPhaseColorConfigs);
                });
            }
            return true
        };

        const now = moment();
        const workCanBegin = getInputDateTime(wc.workCanBegin);

        if (filters.quickSearch && filters.quickSearch.value >= 0) {
            const quickSearchType = parseInt(filters.quickSearch.value, 10);
            if (quickSearchType === 0) return true;

            const isOwn = function (wc) {
                if ((wc.workers && wc.workers.find(worker => parseInt(worker.id, 10) === user))
                    || (phasesEnabled && wc.phases && wc.phases.find(wcPhase => wcPhase.workers.find(w => w.id === user)))
                ) {
                    return true;
                } else {
                    return false;
                }
            }

            wc.colors = DefineColors(wc, wcColorConfigs);
            if (wc.phases && wc.phases.length > 0) {
                wc.phases.forEach(wp => {
                    wp.colors = DefineColors(wp, workPhaseColorConfigs);
                });
            }

            switch (quickSearchType) {
                case 0:// all
                    return true;
                case 1:// open
                    return openTypes.find(type => type === wc.workStatus);
                case 2:// own open
                    return openTypes.find(type => type === wc.workStatus) && isOwn(wc);
                case 3:// started
                    return startedTypes.find(type => type === wc.workStatus);
                case 4:// own started
                    return startedTypes.find(type => type === wc.workStatus) && isOwn(wc);
                case 5:// delayed
                    return moment(workCanBegin).diff(now, 'days') < 0;
                case 6:// own delayed
                    return moment(workCanBegin).diff(now, 'days') < 0 && isOwn(wc);
                case 7:// unassigned
                    return !wc.workers || wc.workers.length === 0;
                case 8:// stopped / cancelled
                    return cancelledTypes.find(type => type === wc.workStatus);
                case 9:// done / completed
                    return doneTypes.find(type => type === wc.workStatus);
                default:
                    return true;
            }
        } else {
            let exists = true;

            let dateStart = filters.filterDateStart
                ? filters.filterDateStart
                : null;
            if (dateStart) {
                if (moment(workCanBegin).diff(dateStart, 'hours') < 0) {
                    exists = false;
                }
            }

            let dateEnd = filters.filterDateEnd
                ? filters.filterDateEnd
                : null;
            if (dateEnd) {
                if (moment(dateEnd).diff(workCanBegin, 'hours') < 0) {
                    exists = false;
                }
            }

            const wcMachines = ToArray(wc.machine);

            if (filters.filterByWorker && filters.filterByWorker.length > 0 && !filters.filterByWorker.find(worker => {
                const workerId = parseInt(worker.value, 10);
                return wc.workers.find(wcWorker => wcWorker.id === workerId)
                    || (phasesEnabled && wc.phases?.find(wcPhase => wcPhase.workers?.find(w => w.id === workerId)))
            })) { exists = false; }

            if (filters.filterByWorkerGroup && filters.filterByWorkerGroup.length > 0 && !filters.filterByWorkerGroup.find(wGroup => {
                return wc.workerGroups.find(wcWorkerGroup => parseInt(wcWorkerGroup.id, 10) === parseInt(wGroup, 10));
            })) { exists = false; }

            if (filters.filterByWorktype && filters.filterByWorktype.length > 0) {
                const detail = filters.filterByWorktype.find(wcType => wc.workCardDetailIds && wc.workCardDetailIds.find(wcDetail => wcDetail === wcType.value));

                // Check if result is a worktype detail
                if (!detail || !wTypes.find(type => type.id === detail.value)) {
                    exists = false;
                }
            }
            if (filters.filterByWorkStatus && filters.filterByWorkStatus.length > 0) {
                //TODO: offline workstatus filtering
            }

            if (filters.searchString) {
                let keywordSearchMatch = false;
                // If none of the target properties exist, the workcard is filtered out
                if ((wcMachines.length === 0)
                    && (wc.faultDescription === '' || !wc.faultDescription)) {
                    exists = false;
                }

                // Check whether search value mathces with workcard code
                if (wc.code?.toString().includes(filters.searchString)) {
                    keywordSearchMatch = true;
                }

                // Check whether search value mathces with any machine names 
                if (wcMachines.length > 0) {
                    if (wcMachines.find(m => m.name.toLowerCase().includes(filters.searchString.toLowerCase()))) {
                        keywordSearchMatch = true;
                    }
                }

                // Check whether search value mathces with fault description
                if (wc.faultDescription) {
                    if (wc.faultDescription.toLowerCase().includes(filters.searchString.toLowerCase())) {
                        keywordSearchMatch = true;
                    }
                }

                if (!keywordSearchMatch) exists = false;
            }

            if (exists) {
                wc.colors = DefineColors(wc, wcColorConfigs);
                if (wc.phases && wc.phases.length > 0) {
                    wc.phases.forEach(wp => {
                        wp.colors = DefineColors(wp, workPhaseColorConfigs);
                    });
                }
                return true;
            }
        }
    }

    return wcs
        //.filter(wc => !(
        //    (wc.workCardType === 2 && doneTypes.find(type => type === wc.workStatus)
        //    ||
        //    wc.isRouteMaintenanceWorkCard
        //    && wc.phases
        //    && wc.phases.find(phase => (phase.isComplete || phase.skipped)))
        //))
        .filter(wc => findItem(wc));
}

const calculateDaysLate = (workCanBegin) => {
    let now = moment();
    let date = getInputDateTime(workCanBegin);
    return moment(date).diff(now, 'days');
}

//const compare = (key, order = 'asc') => (
//    (a, b) => {
//        const varA = a[key];
//        const varB = b[key];

//        return order === 'desc'
//            ? varB - varA
//            : varA - varB;
//    }
//)

const byDaysLate = wcs => {
    //TODO: sorting only in offline mode
    const workCards = wcs.map(workCard => {
        const daysLate = calculateDaysLate(workCard.workCanBegin);

        return { workCard, daysLate };
    }, {});

    //const sortedWcs = workCards.sort(compare('daysLate', 'asc'));

    return workCards;
}

const calculatePageCount = wcState => {
    const wcsLength = wcState.workcards.length;
    const itemsPerPage = wcState.settings.itemsPerPage || 5;
    const pageCount = Math.ceil(wcsLength / itemsPerPage);

    return pageCount;
}

const mapStateToProps = (state: State) => {
    const workTypes = state.workcards.details && state.workcards.details.filter(wcd => wcd.group === 'worktype');
    const userId = state.settings.userId;

    const colorConfigData = {
        colors: state.settings.colors,
        settings: state.settings.colorSettings,
        config: state.settings.colorConfig
    }
    const { statusTypes, sqlQuickSearches } = state.settings;

    const phasesEnabled = (state.settings.noviConfigs && state.settings.noviConfigs.EnablePhases) || null;
    const isOffline = !state.offline.online;
    const workcards = [...state.workcards.workcards];

    let doneStatusValues = [];

    statusTypes.forEach(sType => {
        if (sType.statusValue === 4) {
            doneStatusValues.push(sType.id);
        }
    });

    const workCardsData = isOffline
        ? byDaysLate(processItems(workcards, state.workcards.currentFilters, userId, workTypes, colorConfigData, phasesEnabled, statusTypes))
        : byDaysLate(processItems(workcards, null, userId, [], colorConfigData, phasesEnabled, statusTypes));
    const viewSettings = (state.workcards.viewSettings && state.workcards.viewSettings.workschedulegrid) || [];

    return {
        workcards: workCardsData || [],
        worklistQuickSearches: sqlQuickSearches && sqlQuickSearches.workschedulequicksearch
            ? sqlQuickSearches.workschedulequicksearch.map(qSearch => ({
                value: qSearch.id,
                label: qSearch.label,
                own: qSearch.personal,
                tabOrder: qSearch.tabOrder,
                parentId: qSearch.parentId
            }))
            : [],
        workCardSearches: state.settings.searches?.workCardSearches || [],
        workTypes: workTypes,
        totalResultCount: isOffline ? workCardsData.length : state.workcards.workListDisplayInfo?.totalResultCount,
        totalPageCount: isOffline ? calculatePageCount(state.workcards) : state.workcards.workListDisplayInfo?.totalPageCount,
        currentFilters: state.workcards.currentFilters,
        displaySettings: state.workcards.settings,
        viewSettings: viewSettings,
        status: state.workcards.status,
        wcsLoadStatus: state.workcards.workcardsStatus,
        settings: state.settings,
        activeQuickSearch: state.navigation.activeQuickSearches.worklist,
        user: state.oidc.user,
        isOffline
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getWorkCards,
    saveWorkListSettings,
    fetchViewSettings,
    defaultSearch,
    resetCurrentFilters,
    quickSearchWorkCards,
    setWorkCardSettings,
    setWorkStatuses,
    setUrgencies,
    setWorkCardDetails,
    setQuickSearches,
    getWorkCardColors,
    fetchWorkcardOptions,
    fetchWorkerGroups,
    fetchSearches
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps)

export type WorkListPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WorkList);
