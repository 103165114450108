import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Image } from 'react-bootstrap';

interface Props {
    toggleImage: (val: boolean) => void;
    imgSrc: string;
}

interface State {
    fullImage: any;
    imgNameShown: boolean;
    wrapperRef: any;
}

class ImageViewerComponent extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            fullImage: this.props.imgSrc,
            imgNameShown: false,
            wrapperRef: null
        }

    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleOutsideClick);
    }

    setWrapperRef = node => {
        this.setState({ wrapperRef: node });
    }

    handleOutsideClick = event => {
        if (this.state.wrapperRef && !this.state.wrapperRef.contains(event.target)) {
            this.props.toggleImage(null);
        }
    }

    render() {
        return (
            <div className="modal-container">
                <div>
                    <div className="image-label">
                        <span onClick={() => this.props.toggleImage(null)}>
                            <FontAwesomeIcon icon={faTimes} size="2x" />
                        </span>
                    </div>
                    <div className="image-container" ref={this.setWrapperRef}>
                        <Image src={this.props.imgSrc} />
                    </div>
                </div>
            </div>
        );
    }
};

export default ImageViewerComponent;