import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './config/Routes';
import i18n from './translations/i18n';
import { ToastContainer } from 'react-toastify';
import authAPI from './api/authAPI';
import {
    fetchThirdParties,
    fetchThirdPartyTypes,
    fetchResponsiblePeopleCategories,
    setStatusesFulfilled,
    fetchUserGroups,
    fetchUserDetailsByAdName,
    setApplicationSettings,
    setDefaultLanguage,
    setMachineGroups,
    setUserSettings,
    setMachineGroupId,
    setUserId,
    fetchAllPersonMachineGroupSettings
} from './commonActions/actions';
import { connect, ConnectedProps } from 'react-redux';
import { UserState } from "redux-oidc";
import { bindActionCreators } from 'redux';
import { logout, logoutSSO } from './scenes/login/actions';
import CacheBuster from './CacheBuster';
import noviAPI from './api/noviAPI';
import { HandleError, mapLanguageCode, mapTranslations } from './components/HelperFunctions';

interface IState {
    ready: boolean;
    fetchedUserDetails: boolean;
}

class App extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            fetchedUserDetails: false
        }
    }

    componentDidMount() {
        const { oidcUserSub, userId } = this.props;

        // Cancel proceeding to mobile site if url indicates to desktop site
        const url = window.location.href;
        if (url.includes('/desk') || url.includes('/api')) {
            //window.location.href = window.location.href
            return;
        }

        //TODO: correct session check for sso login too
        if (!this.isSSO()) {
            if ((userId && !this.isAuthenticated())) {
                this.props.logout();
                this.props.setStatusesFulfilled();
            }
        }

        if (!this.isAuthenticated()) {
            this.initDefaultSettings();
        } else {
            this.initSettings((oidcUserSub && this.props.userAdNameDetails) ? this.props.userAdNameDetails.id : userId);
        }
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {

        const ssoUserId = this.props.userAdNameDetails?.id ?? null;
        const { oidcUserSub, userId } = this.props;


        if (this.isSSO() && !this.state.fetchedUserDetails && oidcUserSub) {
            try {
                if (this.isAuthenticated()) {
                    this.props.fetchUserDetailsByAdName(oidcUserSub)
                        ['then']((userDetails: IPerson) => {
                            // Authentication has succeeded, now we can initialize settings properly
                            this.initSettings(userDetails?.id);
                            this.setState({ fetchedUserDetails: true });
                        })
                        .catch(err => {
                            console.log("[ FAILED TO FETCH ]", err);
                        })
                }
            } catch (err) {
                HandleError(err, 'Fetch user details');
                if (this.state.fetchedUserDetails)
                    this.setState({ fetchedUserDetails: false });
            }
        }

        // App changed into SSO mode OR updated in SSO mode and SSO->userId != redux->userId
        if (this.isSSO() && (ssoUserId != this.props.userId || !prevProps.oidcUserSub)) {
            this.props.setUserId(ssoUserId);
            this.initSettings(ssoUserId);
        }

        // User logged in (both normal and SSO uses userDetails)
        if (prevProps.userDetails.id !== this.props.userDetails.id && this.isAuthenticated()) {
            this.setState({ ready: false });
            this.initSettings(this.props.userDetails.id);
        }

        // User logged in with SSO
        if (prevProps.oidcUserSub !== this.props.oidcUserSub && this.isAuthenticated()) {
            this.props.setUserId(ssoUserId);
            this.setState({ ready: false });
            this.initSettings(ssoUserId);
        }

        // TODO: Move these requests to components where they are needed
        if (!prevState.ready && this.state.ready && this.isAuthenticated()) {
            const { userAdNameDetails, userDetails } = this.props;
            this.props.fetchThirdParties();
            this.props.fetchThirdPartyTypes();
            this.props.fetchResponsiblePeopleCategories();
            if (!this.isSSO()) {
                this.props.fetchUserGroups(userDetails.id);
            } else {
                this.props.fetchUserGroups(userAdNameDetails.id);
            }
        }

        if (prevProps.machineGroupId !== this.props.machineGroupId) {
            this.setTimeDiff()
        }

        //TODO: redirect to login ( / ) if sso and adName not found
        /*const location = {
            pathname: '/',
            state: { notificationMsg: 'Your Novi account is not linked to SSO, please contact administrator' }
        }*/
    }

    getDefaultLanguage = async () => {
        try {
            const response = await noviAPI.translations.openLanguage();
            return response.data;
        } catch (e) { console.log(e); }
    }

    getOpenTranslations = async (lang: Language) => {
        try {
            const response = await noviAPI.translations.fetchAllOpenTranslationsByLang(lang);
            return response.data;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    getTranslations = async (lang: Language) => {
        const response = await noviAPI.translations.fetchAllByLang(lang);
        return response.data;
    }

    getMachineGroups = async () => {
        const response = await noviAPI.machineGroups.fetchAll();
        return response.data;
    }

    getPersonSettings = async (id: number) => {
        const response = await noviAPI.personSettings.fetchAll(id);
        return response.data;
    }

    getApplicationSettings = async () => {
        const response = await noviAPI.applicationSettings.fetchAll();
        return response.data;
    }

    getMachineGroupSettings = async (id: number, group: string) => {
        const response = await noviAPI.machineGroupSettings.fetch(id, group);
        return response.data;
    }

    initDefaultSettings = async () => {
        const defaultLanguage = await this.getDefaultLanguage();
        this.props.setDefaultLanguage(defaultLanguage);

        if (!this.isSSO()) {
            const language = this.getLanguage(defaultLanguage);
            const openTranslations = await this.getOpenTranslations(defaultLanguage);

            this.translate(openTranslations, language);
        }

        this.setState({ ready: true });
    }

    initSettings = async (userId: number) => {
        try {
            if (!userId || !this.isAuthenticated()) {
                return;
            }

            if (this.isSSO() && this.props.userId != userId) {
                this.props.setUserId(userId);
            }

            const applicationSettings = await this.getApplicationSettings();
            this.props.setApplicationSettings(applicationSettings);

            const machineGroups = await this.getMachineGroups();
            this.props.setMachineGroups(machineGroups);

            const personSettings = await this.getPersonSettings(userId);
            this.props.setUserSettings(personSettings);

            await this.props.fetchAllPersonMachineGroupSettings();

            const storedId = Number(localStorage.getItem("machineGroupId"));
            const storedFactory = storedId ? machineGroups.find(mGroup => mGroup.id == storedId) : null;
            const defaultFactory = personSettings.find(i => i.group === 'defaultfactory');

            const machineGroupId = Number(storedFactory?.id ?? defaultFactory?.field ?? machineGroups[0].id);
            this.props.setMachineGroupId(machineGroupId);

            const language = this.getLanguage(this.props.defaultLanguage, personSettings);
            const translations = await this.getTranslations(language);
            this.translate(translations, language);

            this.setTimeDiff();

            this.setState({ ready: true });
        } catch (e) {
            HandleError(e, 'Initialize application settings');
            console.log(e);
            this.initDefaultSettings();
        }

    }

    getLanguage = (defaultLanguage: Language, userSettings?: IUserSetting[]) => {
        let language = 'en-GB';

        if (defaultLanguage) {
            language = defaultLanguage;
        }

        if (userSettings) {
            const userLanguage = userSettings.find(i => i.group === 'language');
            if (userLanguage) {
                language = userLanguage.field;
            }
        }

        return language;
    }

    translate = (translations: ITranslation[], language: Language) => {
        const languageCode = mapLanguageCode(language);
        const recources = mapTranslations(translations);

        i18n.changeLanguage(languageCode);
        i18n.addResourceBundle(languageCode, 'translation', recources, false, false);
    }

    setTimeDiff = async () => {
        if (this.props.noviConfigs.TimeDiffEnabled?.toLowerCase() === 'true') {
            const userSetting = this.props.userSettings.find(i => i.group === 'timediff') ?? null;
            let timeDiff = '0';
            if (userSetting !== null) {
                timeDiff = userSetting.field ?? timeDiff;
            } else {
                const machineGroupSettings = await this.getMachineGroupSettings(this.props.machineGroupId, 'timediff');
                const machineGroupSetting = machineGroupSettings.length === 1 ? machineGroupSettings[0] : null;
                if (machineGroupSetting !== null) {
                    timeDiff = machineGroupSetting.field ?? timeDiff;
                }
            }
            localStorage.setItem('timediff', timeDiff);
        }
    }

    isAuthenticated = () => {

        // TODO?: SSO Doesn't necessarily have to use authAPI
        // instead could get tokens from userManager.getUser()
        const accessToken = this.isSSO() 
            ? this.props.SSOAccessToken
            : authAPI.getAccessToken();

        const refreshToken = authAPI.getRefreshToken();

        const validAccessToken = accessToken && accessToken !== 'undefined';
        const validRefreshToken = refreshToken && refreshToken !== 'undefined';

        const validTokens = Boolean(validAccessToken && validRefreshToken)

        return validTokens;
    }

    // Is SSO authentication gateway defined in runConfig
    isSSO = () => !!(window['runConfig'].authGatewayURL);

    render() {
        return (
            <div>
                <CacheBuster />
                <ToastContainer autoClose={3000} />
                <BrowserRouter basename={window['runConfig'].baseLocation}>
                    <Routes ready={this.state.ready} />
                </BrowserRouter>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    logout,
    logoutSSO,
    fetchThirdParties,
    fetchThirdPartyTypes,
    fetchUserGroups,
    fetchResponsiblePeopleCategories,
    setStatusesFulfilled,
    fetchUserDetailsByAdName,
    setApplicationSettings,
    setDefaultLanguage,
    setMachineGroups,
    setUserSettings,
    setMachineGroupId,
    setUserId,
    fetchAllPersonMachineGroupSettings
}, dispatch);

interface MapState extends State { oidc: UserState }

const mapStateToProps = (state: MapState) => {
    const {
        noviConfigs,
        defaultLanguage,
        colorSettings,
        userId,
        userSettings,
        userDetails,
        userAdNameDetails,
        machineGroupId,
        SSOAccessToken
    } = state.settings;

    const sub = window['runConfig']?.openIdConnectType === 'ADFS'
        ? state?.oidc?.user?.profile?.upn
        : state?.oidc?.user?.profile?.sub;

    return {
        oidcUserSub: sub,
        userDetails,
        userAdNameDetails,
        userId,
        userSettings,
        noviConfigs,
        defaultLanguage,
        colorSettings,
        machineGroupId,
        SSOAccessToken
    }
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(App);
