import {
    OPEN_SCANNER,
    CLOSE_SCANNER,
    SET_SCANNER_RESULT,
    CLEAR_SCANNER_RESULT,
    CLEAR_CACHE,
    UPDATE_CURRENT_MACHINE_GROUP_ID
} from "../config/actionTypes"


const initialState: IScannersState = {
    QR: {
        eventKey: null,
        isOpen: false,
        result: null
    },
    barcode: {
        eventKey: null,
        isOpen: false,
        result: null
    }
}

const setIsOpen = (state, action, value) => {
    return Object.assign({}, state, {
        [action.scanner]: Object.assign({}, state[action.scanner], {
            ...state[action.scanner],
            eventKey: action.eventKey || null,
            isOpen: value
        })
    })
}

const setResult = (state, action) => {
    return Object.assign({}, state, {
        [action.scanner]: Object.assign({}, state[action.scanner], {
            eventKey: null,
            result: action.result,
            isOpen: false
        })
    })
}

const scannersReducer = (state = initialState, action): IScannersState => {
    switch (action.type) {
        case OPEN_SCANNER: {
            return setIsOpen(state, action, true)
        }
        case CLOSE_SCANNER: {
            return setIsOpen(state, action, false)
        }
        case SET_SCANNER_RESULT:
        case CLEAR_SCANNER_RESULT: {
            return setResult(state, action)
        }
        case UPDATE_CURRENT_MACHINE_GROUP_ID:
        case CLEAR_CACHE:
            return initialState
        default:
            return state;
    }
}

export default scannersReducer;