import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import i18n from 'translations/i18n';
import PanelContainer from './PanelContainer';
import RouteMaintenanceWorkCardPhase from './RouteMaintenenceWorkCardPhase';

type LocationState = { rowId?: number; }

const RouteMaintenanceWorkCardPhaseList = (props: IProps) => {
    const location = useLocation<LocationState>();

    const getPhaseIconSets = () => {
        let icons = {}
        props.phases?.forEach(phase => {
            const param = 'isDone' + phase.id;
            let phaseStatusIcon = null;

            if (phase.isComplete) {
                phaseStatusIcon = 'disabled_done';
            } else if (phase.skipped) {
                phaseStatusIcon = 'disabled_skipped';
            } else if (props.routeWcState[param] && props.routeWcState[param].id) {
                if (props.routeWcState[param].id === 1) {
                    phaseStatusIcon = 'done';
                } else if (props.routeWcState[param].id === 2) {
                    phaseStatusIcon = 'skipped';
                }
            }

            icons[phase.id] = [{ 'icon': phaseStatusIcon }];
        });

        return icons;
    }

    const icons = getPhaseIconSets();

    return (
        <Accordion
            id="route-phase-panel"
            className="margin-top-15"
            defaultActiveKey={location.state?.rowId >= 0 ? location.state.rowId.toString() : null}
        >
            {props.phases?.map((phase, i) => (
                <div key={phase.id}>
                    <PanelContainer
                        id={phase.id}
                        title={phase.machine ? phase.machine.code + ' / ' + phase.machine.name : i18n.t('MACHINE_NOT_DEFINED')}
                        panelIcons={icons[phase.id]}
                        subTitle={phase.task}
                        customClass={(phase.isComplete || phase.skipped) ? 'disabled-element' : ''}
                    >
                        <RouteMaintenanceWorkCardPhase
                            phase={phase}
                            routeWcState={props.routeWcState}
                            handleInputChange={props.handleInputChange}
                            handleDatetime={props.handleDatetime}
                            handleSelect={props.handleSelect}
                            hourCardFields={props.hourCardFields}
                        />
                    </PanelContainer>
                    {i + 1 < props.phases.length && <hr className="v-margins-5" />}
                </div>
            ))}
        </Accordion>
    )
}

interface IProps {
    phases: IRouteMaintenancePhase[];
    routeWcState;
    handleInputChange;
    handleDatetime;
    handleSelect;
    hourCardFields;
}

export default RouteMaintenanceWorkCardPhaseList;