import * as types from '../config/actionTypes';

export const warehouseFilters: IWarehouseFilters = {
    searchString: '',
    code: '',
    productNumber: '',
    shelfLocation: '',
    orderNumber: '',
    alertLimit: { min: '', max: '' },
    price: { min: '', max: '' },
    amount: { min: '', max: '' },
    memo: '',
    name: '',
    warrantyEnded: null,
    thirdParties: {},
    details: {},
    orderAmount: { min: '', max: '' },
    sparePartType: ''
}

const initialState: IWarehouseState = {
    warehouses: [],
    allWarehouseIds: [],
    searchedWarehouses: [],
    warehouseSparePartLinks: [],
    sparePartWarehouseLogs: [],
    sparePartWarehouseData: [],
    status: '',
    sPartLinksStatus: '',
    error: null,
    settings: {
        listLayout: true,
        pageNumber: 1,
        pageSize: 5,
        useDefaultSearch: true
    },
    viewSettings: {
        sparepartregistrygrid: [],
        sparepart: [],
        extraDatas: []
    },
    currentWarehouse: {},
    currentFilters: warehouseFilters,
    sparePartDetails: {
        byGroup: {},
        allGroups: []
    },
    sparePartConsumption: {
        sumTaken: 0,
        sumReturned: 0,
        totalConsumption: 0
    }
}

const warehouseReducer = (state = initialState, action): IWarehouseState => {
    switch (action.type) {
        case `${types.FETCH_SPARE_PART_VIEW_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_SPARE_PART_VIEW_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type[0]]: action.payload.viewSettings[action.payload.type[0]],
                }
            }
        case `${types.FETCH_SPARE_PART_VIEW_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_SPARE_PART_EXTRA_DATAS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_SPARE_PART_EXTRA_DATAS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                viewSettings: {
                    ...state.viewSettings,
                    extraDatas: action.payload.data
                }
            }
        case `${types.FETCH_SPARE_PART_EXTRA_DATAS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_WAREHOUSE_GRID_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WAREHOUSE_GRID_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type]: action.payload.gridSettings,
                }
            }
        case `${types.FETCH_WAREHOUSE_GRID_SETTINGS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_WAREHOUSES}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WAREHOUSES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                warehouses: action.payload
            }
        case `${types.FETCH_WAREHOUSES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case `${types.FETCH_WAREHOUSES_WITH_PARAMS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WAREHOUSES_WITH_PARAMS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                searchedWarehouses: action.payload
            }
        case `${types.FETCH_WAREHOUSES_WITH_PARAMS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
            }
        case types.INVENT_WAREHOUSE_SPARE_PART:
            const updatedSparePartLinks = state.warehouseSparePartLinks.map(spLink => {
                if (spLink.id !== action.payload.id) {
                    return spLink
                }
                return {
                    ...spLink,
                    ...action.payload
                }
            });

            return {
                ...state,
                warehouseSparePartLinks: updatedSparePartLinks
            }
        case `SELECT_CURRENT_WAREHOUSE`:
            return {
                ...state,
                currentWarehouse: action.payload,
                settings: {
                    ...state.settings,
                    pageNumber: 1
                }
            }
        case `SET_WAREHOUSE_PAGE_NUMBER`:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageNumber: action.payload
                }
            }
        case `SET_WAREHOUSE_PAGE_SIZE`:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageSize: action.payload,
                    pageNumber: 1
                }
            }
        case `SET_WAREHOUSE_LIST_LAYOUT`:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    listLayout: action.payload
                }
            }
        case `SET_WAREHOUSE_SETTINGS`:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...action.payload
                }
            }
        case `SET_WAREHOUSE_FILTERS`:
            return {
                ...state,
                currentFilters: action.payload
            }
        case `CLEAR_WAREHOUSE_FILTERS`:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    pageNumber: 1
                },
                currentFilters: initialState.currentFilters
            }
        case `${types.TAKE_WAREHOUSE_SPARE_PART}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.TAKE_WAREHOUSE_SPARE_PART}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
            }
        case `${types.RETURN_WAREHOUSE_SPARE_PART}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.RETURN_WAREHOUSE_SPARE_PART}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
            }
        case `${types.RETURN_WAREHOUSE_SPARE_PART}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case 'FETCH_SPARE_PART_DETAILS': {
            const allGroups = action.payload.results.reduce((arr, item) =>
                !arr.includes(item.group)
                    ? arr.concat(item.group)
                    : arr,
                []
            );
            const byGroup = allGroups.reduce((obj, group) =>
                Object.assign(
                    obj,
                    { [group]: action.payload.results.filter(detail => detail.group === group) }
                ),
                {}
            );
            return {
                ...state,
                sparePartDetails: {
                    byGroup,
                    allGroups
                }
            };
        }
        case types.CLEAR_CACHE:
            return initialState
        case types.STATUS_FULFILLED:
            return {
                ...state,
                status: 'fulfilled',
            }
        case types.FETCH_SPARE_PART_WAREHOUSE_DATA:
            return {
                ...state,
                sparePartWarehouseData: action.payload
            }
        case `${types.FETCH_SPARE_PART_WAREHOUSE_DATA}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_ANNUAL_SPARE_PART_CONSUMPTION}`:
            return {
                ...state,
                sparePartConsumption: action.payload
            }
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
            return {
                ...initialState,
                settings: {
                    ...state.settings,
                    pageNumber: initialState.settings.pageNumber
                }
            }
        case types.RESET_WAREHOUSE_CURRENTFILTERS: {
            return {
                ...state,
                currentFilters: initialState.currentFilters
            }
        }
        case types.FETCH_ALL_WAREHOUSES: {
            return {
                ...state,
                allWarehouseIds: action.payload
            }
        }
        default:
            return state;
    }
}

export default warehouseReducer;
