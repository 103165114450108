import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import OperatorMaintenanceList from './OperatorMaintenanceList';
import { fetchOperatorMaintenances, fetchMaintenanceMachines } from './actions';

const mapStateToProps = (state: State) => {
    return {
        selectedMachine: state.maintenances.selectedMachine,
        operatorMaintenances: state.maintenances.operatorMaintenanceList?.length > 0 ? state.maintenances.operatorMaintenanceList : [],
        opmMachines: state.maintenances.opmMachines ? state.maintenances.opmMachines.map(m => ({ id: m.id, label: m.code + ' / ' + m.name })) : [],
        machineGroupId: state.settings.machineGroupId
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchOperatorMaintenances,
    fetchMaintenanceMachines
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OperatorMaintenanceList);
