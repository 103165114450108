import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import noviAPI from '../api/noviAPI';

const fetchViewSettings = (payload, actionName) => ({
    type: actionName,
    payload
});

const useViewSettings = (group: string, actionName?: string) => {
    const [viewSettings, setViewsettings] = useState<IViewSetting[]>([]);
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const dispatch = useDispatch();

    useEffect(() => {
        noviAPI.machineGroupSettings.fetch(machineGroupId, group)
            .then(({ data }) => {
                const sortedVS = data?.sort((a, b) => a.tabOrder - b.tabOrder) ?? [];
                setViewsettings([...sortedVS]);
                dispatch(fetchViewSettings([...sortedVS], actionName))
            });
    }, []);
    return [viewSettings];
}

export default useViewSettings;
