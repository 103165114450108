export enum UserRights {
    RestrictedMaterialEdit = 'workschedulerestrictedmaterialsedit',
    RestrictedTimestampsEdit = 'workschedulerestrictedtimestampsedit',
    WorkScheduleView = 'workscheduleview',
    WorkScheduleViewMaterials = 'workscheduleviewmaterials',
    WorkScheduleAdd = 'workscheduleadd',
    WorkScheduleEdit = 'workscheduleedit',
    WorkScheduleDelete = 'workscheduledele',
    FaultNotice = 'faultnotice',
    PurchasesEdit = 'purchasesedit',
    DashboardEnvironmentEdit = 'dashboardenvironmentedit',
    DashboardPersonGroupEdit = 'dashboardpersongroupedit',
    DashboardUserEdit = 'dashboarduseredit',
    MachineRegistryView = 'machineregistryview',
    MachineRegistryEdit = 'machineregistryedit',
    MachineRegistryDelete = 'machineregistrydele',
    WarehouseView = 'warehouseview',
    OperatorMaintenanceView = 'operatormaintenanceview',
    ThirdPartyAddition = 'thirdpartyregistryadd',
    CountersView = 'countersview',
    CountersEdit = 'countersedit',
    WorkPermitAdd = 'workpermitadd',
    WorkPermitView = 'workpermitview',
    WorkPermitViewAll = 'workpermitviewall',
    WorkPermitEdit = 'workpermitedit',
    WorkPermitEditOwn = 'workpermiteditown',
    SafetyView = 'safetyview',
    SafetyViewOwn = 'safetyonlyown',
    SafetyAdd = 'safetyadd',
    HourCardView = 'hourcardview',
}
