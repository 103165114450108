import React from 'react';
import { Modal } from 'react-bootstrap';

const DialogBody = (props: IProps) => {
    return (
        <Modal.Body className="modal-content">
            {props.children}
        </Modal.Body>
    );
}

interface IProps {
    children: React.ReactNode | React.ReactNode[];
}

export default DialogBody;