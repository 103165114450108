import React from 'react';
import i18n from '../../translations/i18n';
import LargeActionButton from '../buttons/LargeActionButton';
import LargeYellowButton from '../buttons/LargeYellowButton';
import SortElemenetsByRowNumber from '../SortElemenetsByRowNumber';

const SearchModalButtons = (props: IProps) => {
    const defaultSearchButtonRowNumber = -2;
    const defaultClearButtonRowNumber = -1;

    const searchButton = (
        <LargeActionButton
            handleClick={props.applyFilters}
            rowNumber={props.searchButtonRowNumber || defaultSearchButtonRowNumber}
        >
            {i18n.t('SEARCH')}
        </LargeActionButton>
    );

    const clearButton = (
        <LargeYellowButton
            handleClick={props.clearFilters}
            rowNumber={props.clearButtonRowNumber || defaultClearButtonRowNumber}
        >
            {i18n.t('CLEAR_SELECTIONS')}
        </LargeYellowButton>
    );

    return (
        <SortElemenetsByRowNumber addHrAfterEachElement>
            {!props.hideSearchButton && searchButton}
            {!props.hideClearButton && clearButton}
            {props.children}
        </SortElemenetsByRowNumber>
    );
}

interface IProps {
    applyFilters: () => void;
    clearFilters: () => void;
    hideSearchButton?: boolean;
    hideClearButton?: boolean;
    searchButtonRowNumber?: number;
    clearButtonRowNumber?: number;
    children?: React.ReactNode | React.ReactNode[];
}

export default SearchModalButtons;