import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ListGroup, ListGroupItem, Button, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faAngleDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../translations/i18n';
import Avatar from 'react-avatar';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import { HasRight, isNullOrWhitespace } from '../../../components/HelperFunctions';
import RedirectToDesktopButton from '../../../components/RedirectToDesktopButton';
import MenuItems from './MenuItems';

const tempIconsList = { home: faHome }

const getClass = (module, viewType) => {
    let classes = '';
    if (module.subitems && module.subitems.length > 0) return 'menu-subitem-list';
    if (module.type === 'dashboard') classes = 'home';
    if (viewType === module.type) classes = classes + ' selected';

    return classes;
}

const MainMenu = ({
    view,
    toggleSubItems,
    modules,
    itemStates,
    avatarImg = null,
    settings,
    onChange,
    logoutUser,
    permissionSettings,
    closeSideBar
}) => {
    let history = useHistory();

    const showDesktopLinkButton = !isNullOrWhitespace(settings.mobileUrl);

    return (
        <div className="sidenav">
            <Offcanvas.Header>
                <Offcanvas.Title>
                    {view !== 'login' && <div>
                        <div className="profile-container">
                            <div className="sidenav-container">
                                <div className="sidenav-top-section">
                                    <div className="profile-avatar-container">
                                        {settings.userDetails && <div>
                                            {avatarImg
                                                ? <Avatar round={true} size="48" src={avatarImg} />
                                                : <Avatar round={true} size="48" name={settings.userDetails.name} />
                                            }
                                        </div>}
                                    </div>
                                    <div className="current-factory-container">
                                        {settings.factoryOptions.options && settings.factoryOptions.options.length > 1
                                            ? <FormElement
                                                name={'factoryselect'}
                                                type={'select-choice'}
                                                label={'CURRENT_FACILITY'}
                                                value={settings.factoryOptions.current}
                                                options={settings.factoryOptions.options}
                                                onChange={onChange}
                                                searchable={false}
                                            />
                                            : <div>
                                                <label>{i18n.t('CURRENT_FACILITY')}</label>
                                                <p>{settings.factoryOptions.current && settings.factoryOptions.current.label}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {settings.userDetails && <div className="profile-info">
                                    <h4>{settings.userDetails.name}</h4>
                                    {settings.userDetails.role && <p>{settings.userDetails.role}</p>}
                                </div>}
                            </div>
                        </div>
                    </div>}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="sidenav-container">
                    <ListGroup className="sidenav-list">
                        {Object.keys(modules).map(key => (
                            modules[key] && modules[key].enabled
                            && (HasRight(modules[key].viewRight, permissionSettings) || modules[key].type === 'dashboard')
                            && <ListGroup.Item
                                key={key}
                                className={getClass(modules[key], view)}

                            >
                                {modules[key].link
                                    ? <Link to={modules[key].link} onClick={(e) => {
                                        e.preventDefault();
                                        closeSideBar();
                                        history.push({state: {pathname: modules[key].link}})
                                    }}>
                                        {modules[key].icon
                                            ? <div>
                                                <span>{i18n.t(modules[key].translation)}</span>
                                                <FontAwesomeIcon icon={tempIconsList[modules[key].icon]} className="sidenav-menu-icon" />
                                            </div>
                                            : <div>
                                                <span>{i18n.t(modules[key].translation)}</span>
                                            </div>}
                                    </Link>
                                    : <div>
                                        <div className="action main-item" onClick={() => toggleSubItems(modules[key])}>
                                            <div>{i18n.t(modules[key].translation)}</div>
                                            {modules[key].subitems
                                                && modules[key].subitems.length > 0
                                                && <div className={'icon ' + (itemStates[modules[key].type] ? 'toggle-open' : 'toggle-close')}>
                                                    <FontAwesomeIcon icon={faAngleDown} size="lg" />
                                                </div>}
                                        </div>
                                        {modules[key].subitems
                                            && modules[key].subitems.length > 0
                                            && itemStates[modules[key].type]
                                            && <ListGroup>
                                                {modules[key].subitems.map(key => (
                                                    <ListGroup.Item
                                                        key={key.type}
                                                        className={view === key.link.state ? 'selected' : ''}
                                                    >
                                                        <Link to={key.link}>
                                                            {i18n.t(key.translation)}
                                                        </Link>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>}
                                    </div>}
                            </ListGroup.Item>
                        ))}
                        {modules.maintenances.enabled
                            && HasRight(modules.maintenances.viewRight, permissionSettings)
                            && <ListGroupItem className="menu-subitem-list">
                                <div className="action" onClick={() => toggleSubItems(modules.maintenances)}>
                                    <div>{i18n.t(modules.maintenances.title)}</div>
                                    {modules.maintenances.subItems
                                        && modules.maintenances.subItems[0].title
                                        && <div className={itemStates[modules.maintenances.value]
                                            ? 'toggle-open'
                                            : 'toggle-close'}><FontAwesomeIcon icon={faAngleDown} size="lg" />
                                        </div>}
                                </div>
                                {itemStates[modules.maintenances.value] &&
                                    <ListGroup>
                                        {Object.keys(modules.maintenances.subItems).map((key, i) => (
                                            <ListGroupItem
                                                className={view === modules.maintenances.subItems[key].link.state ? 'selected' : ''}
                                                key={modules.maintenances.subItems[key].title}
                                            >
                                                <Link to={modules.maintenances.subItems[key].link}>
                                                    {i18n.t(modules.maintenances.subItems[key].title)}
                                                </Link>
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>}
                            </ListGroupItem>}
                    </ListGroup>
                </div>
                <div>
                    <hr />
                    <MenuItems machineGroupId={settings.factoryOptions.current?.value} />
                    <div style={{ marginTop: '25px', width: '100%', textAlign: 'center', fontSize: '12px', bottom: '70px' }}>
                        <header>
                            {showDesktopLinkButton && <RedirectToDesktopButton />}
                            <p>
                                Novi version - <code>v{localStorage.getItem('frontendversion')}</code>
                            </p>
                        </header>
                        <hr />
                    </div>
                    {(settings.userDetails?.id || window['runConfig'].authGatewayURL) && <div className="sidenav-actions">
                        <Button
                            variant="danger"
                            size="sm"
                            className="action action-button"
                            onClick={() => logoutUser()}
                        >{i18n.t('LOGOUT')} <FontAwesomeIcon icon={faSignOutAlt} size="lg" /></Button>
                    </div>}
                </div>
            </Offcanvas.Body>
        </div>
    );
}

export default MainMenu;
