import React, { useRef, useState } from 'react';
import { Button, ButtonGroup, Dropdown, OverlayTrigger, Popover, SplitButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToiletPaper, faChevronCircleUp, faSave, faCheckCircle, faClipboardList, faArrowAltCircleRight, faSpinner, faCopy } from '@fortawesome/free-solid-svg-icons';
import '../../../styles/global.scss';
import './../styles/navigation.scss';
import i18n from '../../../translations/i18n';
import PopoverAction from '../../../components/PopoverAction';
import { useHistory } from 'react-router-dom';
import ActionIcon from '../../../components/ActionIcon';

interface IProps {
    footerData: any;
}

const actionIcon = {
    newFaultNotice: faClipboardList,
    settings: faToiletPaper,
    save: faSave,
    next: faArrowAltCircleRight,
    confirm: faCheckCircle,
    saveDashboardEdit: faSave,
    spinner: faSpinner,
    settingsMenu: faChevronCircleUp
}
const actionTitle = {
    newFaultNotice: 'NEW_FAULT_NOTICE',
    newWorkCard: 'NEW_WORKCARD',
    settings: ''
}

const ActionMenu = ({ footerData }: IProps) => {
    let history = useHistory();

    const handleClick = (action) => {
        if (footerData.viewAction.status === 'pending') return;
        if (action === 'newFaultNotice' || action === 'newWorkCard') {
            history.push('/workcard/new/' + (action == 'newFaultNotice' ? 'faultnotice' : 'workcard'), { cardType: action} );
        } else if (action === 'settings') {
        }
    }

    const handlePopoverClick = popoverAction => {
        // Close popover and set overlay show state to false
        if (popoverAction.isActionFn) {
            footerData.togglePopover(false)
            popoverAction.clickFn.apply(this, popoverAction.params); 
        }
    }

    const getPopoverOverlay = popoverData => {
        const children = Object.keys(popoverData.popoverActions).map(key => (
            <div key={key} data-cy={popoverData.popoverActions[key].dataCy}>
                <PopoverAction
                    icon={popoverData.popoverActions[key].icon}
                    label={popoverData.popoverActions[key].label}
                    paClassName={popoverData.popoverActions[key].paClass}
                    onClick={() => handlePopoverClick(popoverData.popoverActions[key])}
                    disabled={popoverData.popoverActions[key].disabled}
                />
                {parseInt(key, 10) < popoverData.popoverActions.length - 1 && <hr />}
            </div>
        ));

        return (
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose={true}
                overlay={<Popover id="settings-menu-popover" className={popoverData.popoverClass}>
                    {React.Children.map(children, (child, i) => (
                        <div className="sub-section" key={`setting_${i}`}>
                            {popoverData?.headings !== null &&
                                popoverData.headings?.length > 0
                                && popoverData.headings[i] !== null &&
                                <div className="setting-header">
                                    {popoverData.headings[i]}
                                </div>
                            }
                            <div className="setting-option">
                                {child}
                            </div>
                        </div>
                    ))}
                </Popover>    }
            >
                <Button
                    id="footerMenuButton"
                    variant="primary"
                    className="action action-button novi-default-btn-color"
                    size="lg"
                >
                    <div>
                        <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
                    </div>
                </Button>
            </OverlayTrigger>  
        );
    }

    return (
        <div>
            {footerData.viewAction !== 'settings'
                ? footerData.splitButtonMenu?.length > 0
                    ? <Dropdown as={ButtonGroup} className="split-dropdown">
                        <SplitButton
                            id="MainActionButton"
                            className="action-split-button novi-default-btn-color"
                            variant="large"
                            title={<div>
                                <FontAwesomeIcon
                                    icon={footerData.viewAction.icon ? actionIcon[footerData.viewAction.icon] : actionIcon[footerData.viewAction]}
                                    pulse={footerData.viewAction?.icon === 'spinner' ? true : false}
                                />
                                {footerData.viewAction.label && <span className="left-margin-5">
                                    {footerData.viewAction.label ? footerData.viewAction.label : i18n.t(actionTitle[footerData.viewAction])}
                                </span>}
                            </div>}
                            onClick={() => footerData.viewAction.clickFn ? footerData.viewAction.clickFn() : handleClick(footerData.viewAction)}
                            drop="up"
                        >
                            {footerData.splitButtonMenu.map((item, i) => (
                                <Dropdown.Item key={i} eventKey={i} onClick={item.action}>
                                    <div style={{ display: 'flex' }}>
                                        {item.icon && <div className="right-margin-5"><ActionIcon iconType={item.icon} /></div>} {i18n.t(item.label)}
                                    </div>
                                </Dropdown.Item>
                            ))}
                        </SplitButton>
                    </Dropdown>
                    : <Button
                        id="MainActionButton"
                        className="action action-button novi-default-btn-color"
                        variant="large"
                        onClick={() => footerData.viewAction.clickFn ? footerData.viewAction.clickFn() : handleClick(footerData.viewAction)}
                    >
                        <div>
                            <FontAwesomeIcon
                                icon={footerData.viewAction.icon ? actionIcon[footerData.viewAction.icon] : actionIcon[footerData.viewAction]}
                                size="lg"
                                pulse={footerData.viewAction?.icon === 'spinner' ? true : false}
                            />
                            {footerData.viewAction.label && <span className="left-margin-5">
                                {footerData.viewAction.label ? footerData.viewAction.label : i18n.t(actionTitle[footerData.viewAction])}
                            </span>}
                        </div>
                    </Button>
                : <>
                    {footerData.popoverData
                        ? getPopoverOverlay(footerData.popoverData)
                        : footerData.popover
                    }
                </>
            }
        </div>
    );
}

export default ActionMenu;