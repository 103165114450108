/**
 * Check if work card field is required
 * 
 * @param viewSetting
 * @param workStatus
 * @param workType
 */
export const checkRequired = (viewSetting: IViewSetting, workStatus, workType?: IOptionType) => {
    // Make sure machine is always required
    if (viewSetting.field === 'machine') {
        return true;
    }

    let status = workStatus ? workStatus.id : 0;
    let wType = workType ? Number(workType.value) : 0;

    let result = viewSetting.required;

    const r1 = viewSetting.requirements.find(i => i.requirementWorkStatus === status && i.requirementWorkCardDetail === null) ?? null;
    const r2 = viewSetting.requirements.find(i => i.requirementWorkCardDetail?.id === wType && i.requirementWorkStatus === status) ?? null;

    if (r1 !== null) {
        result = r1.required;
    }

    if (r2 !== null) {
        result = r2.required;
    }

    return result;
}

/**
 * Returns unarchived cost pools. Also returns an archived cost pool if it matches with the cost pool of the work card.
 * 
 * @param costPools
 * @param wcCostPool
 */
export const filterArchivedCostPools = (costPools: ICostPool[], wcCostPool) => {
    return costPools.filter(i => !i.archived || (wcCostPool !== null && i.id === wcCostPool.id))
}

/**
 * Returns material mapped to IWorkCardSparePartAddition
 * 
 * @param material 
 * @param workCardId
 */
export const mapMaterialAddition = (material, workCardId): IWorkCardSparePartAddition => {
    let newMaterial: IWorkCardSparePartAddition =  {
        workCardId: workCardId,
        sparePartId: material.sparePart?.id || null,
        wildSparePart: material.wildSparePart || null,
        amount: material.sparepartamount
    }
    if (material.sparepartmemo) {
        newMaterial.memo = material.sparepartmemo;
    }
    if (material.sparepartprice) {
        newMaterial.price = material.sparepartprice;
    }
    if (material.spareparttype) {
        newMaterial.type = material.spareparttype;
    }
    if (material.sparepartunit) {
        newMaterial.unit = material.sparepartunit.id > 0 ? material.sparepartunit.label : null;
    }
    return newMaterial;
}

/**
 * Returns material mapped to IWorkCardSparePartUpdate
 * 
 * @param material 
 * @param workCardId 
 * @param viewSettings 
 */
export const mapMaterialUpdate = (material, workCardId: number, viewSettings: IViewSetting[]): IWorkCardSparePartUpdate => {
    let wSparePart: IWorkCardSparePartUpdate =  {
        workCardId: workCardId,
        sparePartId: material.sparePart?.id || null,
        wildSparePart: material.wildSparePart || null,
        amount: material.sparepartamount,
    }
    if (viewSettings.find(i => i.field === 'sparepartmemo')) {
        wSparePart.memo = material.sparepartmemo;
    }
    if (viewSettings.find(i => i.field === 'sparepartprice')) {
        wSparePart.price = material.sparepartprice;
    }
    if (viewSettings.find(i => i.field === 'spareparttype')) {
        wSparePart.type = material.spareparttype;
    }
    if (viewSettings.find(i => i.field === 'sparepartunit')) {
        wSparePart.unit = material.sparepartunit?.id > 0 ? material.sparepartunit.label : ""
    }
    return wSparePart;
}

export const mapPermitAddition = (values, name, wcId, machineGroupId): IPermitAddition => {
    let addition: IPermitAddition = {
        values: values,
        workCardId: wcId,
        name: name,
        machineGroupId: machineGroupId
    }
    return addition;
}

export const mapPermitUpdate = (values): IPermitUpdate => {
    let addition: IPermitUpdate = {
        values: values
    }
    return addition;
}
