import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import NavigationBar from '../../navigation';
import { GetTranslationKeyByProp } from '../../../components/HelperFunctions';
import i18n from '../../../translations/i18n';
import { addMachineSparePart } from '../actions';
import MachineLinkAdditionForm from './MachineLinkAdditionForm';

type UrlParams = { machineId: string; };

const MachineLinkAddition = () => {
    let history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    const { machineId } = useParams<UrlParams>();
    const [submit, setSubmit] = useState(false);

    const handleSubmit = useCallback((form, type) => {
        if (form === null) {
            setSubmit(false);
            return;
        }

        let data = {
            machineId: parseInt(machineId, 10),
            sparePartId: null,
            wildSparePart: null,
            amountInMachine: parseInt(form.amount, 10),
            memo: form.memo
        }
        if ('sparepart' === type) {
            for (const sparepart of form.spareparts) {
                dispatch(addMachineSparePart({
                    ...data,
                    sparePartId: sparepart.id
                }, history.replace));
            }
        } else {
            dispatch(addMachineSparePart({
                ...data,
                wildSparePart: form.wildsparepart,
            }, history.replace));
        }
    }, [dispatch, machineId, history.replace]);

    const sceneData = {
        view: 'machinelink',
        title: i18n.t(GetTranslationKeyByProp('machinelinkedit')),
        location: location,
        history: history
    }

    const viewAction = {
        icon: 'save',
        label: '',
        clickFn: () => setSubmit(true),
        isActionFn: true,
        paClass: 'start-phase'
    }

    return (
        <div>
            <NavigationBar
                currentView={sceneData}
                navHistory={history}
                viewAction={viewAction}
                popoverData={''}
            />
            <MachineLinkAdditionForm
                machineId={+machineId}
                submit={submit}
                onSubmit={handleSubmit}
            />
        </div>
    )
}

export default MachineLinkAddition;
