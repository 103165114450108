import React from 'react';

const FieldLabel = (props: IProps) => {
    return (
        <label>
            {props.children}{props.required && <span> *</span>}
        </label>
    );
}

interface IProps {
    children: React.ReactNode;
    required?: boolean;
}

export default FieldLabel;