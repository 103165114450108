import React, { useEffect, useState } from 'react'
import noviAPI from '../../../api/noviAPI'
import { HourCardStatusLabels } from '../../../constants/hourCards'
import { getSalaryCategoriesByPerson, getSalaryCategoriesByPersonGroup, initialFilters, mapEnumToOptions, mapPersonGroupPeopleToPersonGroupOptions, mapPersonGroupPeopleToPersonOptions, mapSalaryCategoriesToSalaryCategoryOptions, OptionGroup } from '../utils'
import HourCardFilters from './HourCardFilters'
import { useSelector, useDispatch } from 'react-redux'
import { clearFiltersAction, setFiltersAction } from '../actions'
import DialogModal from '../../../components/dialogs/DialogModal'
import SearchModalHeader from '../../../components/search-modal/SearchModalHeader'
import DialogBody from '../../../components/dialogs/DialogBody'
import DialogFooter from '../../../components/dialogs/DialogFooter'
import SearchModalButtonContainer from '../../../components/search-modal/SearchModalButtonContainer'
import SearchModalButtons from '../../../components/search-modal/SearchModalButtons'
import { handleDateTimeInput } from 'utils'

const initialOptions: Options = {
    groups: [initialFilters.group],
    statuses: [],
    people: {},
    salaryCategories: []
}

const HourCardFiltering = ({ dialogOpen, closeDialog }) => {
    const dispatch = useDispatch()
    const settings = useSelector((state: State) => state.settings)
    const [options, setOptions] = useState(initialOptions)
    const [values, setValues] = useState(initialFilters)
    const filters = useSelector((state: State) => state.hourcards.currentFilters)

    const initHourCardFiltering = async () => {
        try {
            // Fetch person groups that the current user have permission to search.
            const personGroupAppSettings = (await noviAPI.personGroupAppSettings.fetchAllByUserAndGroup('hourcardsupervisedgroup', settings.machineGroupId)).data;

            // Fetch people by the allowed person groups.
            const personGroupPeople = (await noviAPI.personGroups.fetchPeopleByGroup(personGroupAppSettings.map(i => Number(i.field)))).data;

            // Fetch salary categories.
            let salaryCategories: ISalaryCategoryLite[] = [];
            if (filters.group.value === 'own') {
                const userId = Number(settings.userId);
                salaryCategories = await getSalaryCategoriesByPerson(userId, settings.machineGroupId);
            }
            else {
                const personGroupId = Number(filters.group.value);
                salaryCategories = await getSalaryCategoriesByPersonGroup(Number(personGroupId));
            }

            setOptions({
                groups: options.groups.concat(mapPersonGroupPeopleToPersonGroupOptions(personGroupPeople)),
                statuses: mapEnumToOptions(HourCardStatusLabels),
                people: mapPersonGroupPeopleToPersonOptions(personGroupPeople),
                salaryCategories: mapSalaryCategoriesToSalaryCategoryOptions(salaryCategories)
            });

            setValues(filters);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        initHourCardFiltering();
    }, []);

    const handleSelect = async (value, actionMeta) => {
        if (actionMeta.name === 'group') {
            let salaryCategories: ISalaryCategoryLite[] = [];
            if (value.value === 'own') {
                // "Own" group is selected -> person field is empty
                setValues(prevState => ({
                    ...prevState,
                    person: initialFilters.person
                }));
                // "Own" group is selected -> salary category options are shown from users own person groups
                salaryCategories = await getSalaryCategoriesByPerson(Number(settings.userId), settings.machineGroupId);
            }
            else {
                // Group is selected -> salary category options are shown from selected person group
                salaryCategories = await getSalaryCategoriesByPersonGroup(Number(value.value));
            }
            setOptions(prevState => ({
                ...prevState,
                salaryCategories: mapSalaryCategoriesToSalaryCategoryOptions(salaryCategories)
            }));
        }

        setValues(prevState => ({
            ...prevState,
            [actionMeta.name]: value
        }));
    }

    const handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        setValues(prevState => ({
            ...prevState,
            [dateInput.name]: dateInput.value
        }))
    }

    const clearFilters = () => {
        setValues(initialFilters)
        dispatch(clearFiltersAction())
        closeDialog()
    }

    const applyFilters = () => {
        dispatch(setFiltersAction(values))
        closeDialog()
    }

    return (
        <DialogModal showDialog={dialogOpen} closeDialog={closeDialog}>
            <SearchModalHeader />
            <DialogBody>
                <HourCardFilters
                    values={values}
                    options={options}
                    handleSelect={handleSelect}
                    handleDateTime={handleDateTime}
                />
            </DialogBody>
            <DialogFooter>
                <SearchModalButtonContainer>
                    <SearchModalButtons
                        applyFilters={applyFilters}
                        clearFilters={clearFilters}
                        hideClearButton
                    />
                </SearchModalButtonContainer>
            </DialogFooter>
        </DialogModal>
    )
}

type Options = {
    groups: IOptionType[];
    people: OptionGroup;
    statuses: IOptionType[];
    salaryCategories: IOptionType[];
}

export default HourCardFiltering
