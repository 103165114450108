import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import noviAPI from '../api/noviAPI';
import { hourCardSearchParams } from '../scenes/hour-cards/utils';

const init = {
    currentPageNumber: 0,
    results: [],
    totalPageCount: 0,
    totalResultCount: 0,
    status: 'fulfilled'
};

const useHourCards = () => {
    const [hourCards, setHourCards] = useState<ISearchDataWithStatus<IHourCard>>(init);
    const pageNumber = useSelector((state: State) => state.hourcards.settings.pageNumber) - 1;
    const pageSize = useSelector((state: State) => state.hourcards.settings.pageSize);
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const filters = useSelector((state: State) => state.hourcards.currentFilters);
    const userId = useSelector((state: State) => state.settings.userId);

    useEffect(() => {
        setHourCards(h => ({ ...h, status: 'pending' }));

        let searchParams = hourCardSearchParams(filters, userId);
        searchParams.append('PageNumber', pageNumber > 0 ? `${pageNumber}` : '0');
        searchParams.append('PageSize', `${pageSize}` || '5');
        searchParams.append('MachineGroupId', `${machineGroupId}`);

        noviAPI.hourCards.search(searchParams)
            .then(response => {
                setHourCards({
                    ...response.data,
                    status: 'fulfilled'
                });
            });
    }, [pageNumber, pageSize, machineGroupId, filters]);

    return [hourCards];
}

interface ISearchDataWithStatus<T> extends ISearchData<T> {
    status: string;
}

export default useHourCards;
