import * as types from '../config/actionTypes';

const initialState: IDashboardState = {
    dashboardItemTypes: [],
    dashboardTypeOptions: [],
    status: '',
    dashboardUpdateStatus: '',
    error: null
}

const dashboardReducer = (state = initialState, action): IDashboardState => {
    switch (action.type) {
        case `${types.FETCH_DASHBOARD_ITEM_TYPES}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_DASHBOARD_ITEM_TYPES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                dashboardItemTypes: action.payload
            }
        case `${types.FETCH_DASHBOARD_ITEM_TYPES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_DASHBOARD_TYPE_OPTIONS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_DASHBOARD_TYPE_OPTIONS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                dashboardTypeOptions: action.payload
            }
        case `${types.FETCH_DASHBOARD_TYPE_OPTIONS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case types.CLEAR_CACHE:
            return initialState;
        case types.STATUS_FULFILLED:
            return {
                ...state,
                status: 'fulfilled',
            }
        case `${types.UPDATE_DASHBOARD_ITEMS}_PENDING`:
            return {
                ...state,
                dashboardUpdateStatus: 'pending'
            }
        case `${types.UPDATE_DASHBOARD_ITEMS}_FULFILLED`:
            return {
                ...state,
                dashboardUpdateStatus: 'fulfilled'
            }
        case `${types.UPDATE_DASHBOARD}_PENDING`:
            return {
                ...state,
                dashboardUpdateStatus: 'pending'
            }
        case `${types.UPDATE_DASHBOARD}_FULFILLED`:
            return {
                ...state,
                dashboardUpdateStatus: 'fulfilled'
            }
        case `${types.UPDATE_DASHBOARD}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        default:
            return state;
    }
}

export default dashboardReducer;
