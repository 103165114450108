import axios from 'axios';
import authAPI from './authAPI';
import userManager from '../config/userManager';
import { Toaster } from '../components/HelperFunctions';
import { logout } from '../scenes/login/actions';
import store from '../config/store';

function getBaseURL() {
    const url = window['runConfig'].apiURL;
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
        return url;
    }
    return `https://${window['runConfig'].apiURL}`;
}

const request = axios.create({
    baseURL: getBaseURL(),
    ...axios.defaults
});

request.interceptors.request.use(
    async config => {
        if (window['runConfig'].authGatewayURL) {
            const user = await userManager.getUser();

            if (user) {
                if (!user.expired) {
                    config.headers.Authorization = `Bearer ${user.access_token}`;
                }
                else {
                    userManager.startSilentRenew();
                }
            }
            return config;
        } else {
            const token = authAPI.getAccessToken();

            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            const url = config.url.split('/');

            if (url[url.length - 1] === 'login') {
                delete config.headers.Authorization;
            }

            return config;
        }
    },
    error => {
        Promise.reject(error)
    },
);

request.interceptors.response.use(
    response => response,
    err => {
        const { config, response } = err;
        const status = response?.status;

        if (config.url.includes('adName')) {
            if (status === 401) {
                Toaster({ msg: 'ACCOUNT_NOT_LINKED_TO_SSO', type: 'info'})
            } else if (!response) {
                // TODO: Missing claims -> failed to create new novi acc doesn't return an error
                Toaster({ msg: 'LINK_SSO_TO_NEW_ACCOUNT_FAILED', type: 'info'})
            }
        } else if (status === 401) {
            if (authAPI.getAccessToken()) {
                store.dispatch(logout())
            }
        }

        return Promise.reject(err);
    });

export default request;