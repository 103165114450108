import React from 'react';
import { Pagination } from 'react-bootstrap';

const nextSet = (currentPage, setCurrentPage) => {
    if (currentPage % 3 === 1) { setCurrentPage(currentPage + 3) }
    if (currentPage % 3 === 2) { setCurrentPage(currentPage + 2) }
    if (currentPage % 3 === 0) { setCurrentPage(currentPage + 1) }
}

const previousSet = (currentPage, setCurrentPage) => {
    if (currentPage % 3 === 1) { setCurrentPage(currentPage - 3) }
    if (currentPage % 3 === 2) { setCurrentPage(currentPage - 4) }
    if (currentPage % 3 === 0) { setCurrentPage(currentPage - 5) }
}

const pagination = (currentPage, pageCount) => {
    let pages = [];
    let isPrevSet = false;
    let isNextSet = false;

    if (currentPage > 3) {
        isPrevSet = true;
    }

    if (currentPage % 3 === 1) {
        pages.push({ pageNumber: currentPage, isActive: true });
        if (currentPage < pageCount) {
            pages.push({ pageNumber: currentPage + 1, isActive: false });
        }
        if (currentPage + 1 < pageCount) {
            pages.push({ pageNumber: currentPage + 2, isActive: false });
        }
        if (currentPage + 2 < pageCount) {
            isNextSet = true;
        }
    }

    if (currentPage % 3 === 2) {
        pages.push({ pageNumber: currentPage - 1, isActive: false });
        pages.push({ pageNumber: currentPage, isActive: true });
        if (currentPage < pageCount) {
            pages.push({ pageNumber: currentPage + 1, isActive: false });
        }
        if (currentPage + 1 < pageCount) {
            isNextSet = true;
        }
    }

    if (currentPage % 3 === 0) {
        pages.push({ pageNumber: currentPage - 2, isActive: false });
        pages.push({ pageNumber: currentPage - 1, isActive: false });
        pages.push({ pageNumber: currentPage, isActive: true });
        if (currentPage < pageCount) {
            isNextSet = true;
        }
    }

    return { pages, isPrevSet, isNextSet };
}

const PaginationContainer = ({ currentPage, setCurrentPage, pageCount }) => {
    const { pages, isPrevSet, isNextSet } = pagination(currentPage, pageCount);
    return (
        <div className="pagination-container">
            <Pagination size="lg">
                {currentPage > 1 && <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />}
                {isPrevSet && <Pagination.Ellipsis onClick={() => previousSet(currentPage, setCurrentPage)} />}
                {pages.map(page => (
                    <Pagination.Item
                        key={page.pageNumber}
                        active={page.isActive}
                        onClick={() => setCurrentPage(page.pageNumber)}>
                        {page.pageNumber}
                    </Pagination.Item>
                ))}
                {isNextSet && <Pagination.Ellipsis onClick={() => nextSet(currentPage, setCurrentPage)} />}
                {currentPage < pageCount && <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />}
            </Pagination>
        </div>
    );
}

export default PaginationContainer;