import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
    fetchRouteWorkCard,
    updateRouteWorkCard,
    fetchRouteMaintenanceDocuments,
    fetchWorkcardOptions,
    deleteRouteWorkCard,
    DeleteRouteWorkCard,
    deleteRouteWorkCardDocument,
    addRouteMaintenanceDocument,
    IAddRouteMaintenanceDocument
} from './actions';
import PanelContainer from './components/PanelContainer';
import FileInput from '../../../components/FileInput';
import { Accordion, Container, Row, Col, Tabs, Tab, OverlayTrigger, Popover } from 'react-bootstrap';
import '../../../styles/global.scss';
import './styles/work-card.scss';
import NavigationBar from '../../navigation';
import i18n from '../../../translations/i18n';
import { toast } from 'react-toastify';
import {
    fetchViewSettings,
    fetchWorkerGroups,
    setMachineGroupId,
    getWorkCardColors,
    fetchDocumentMetadatas
} from '../../../commonActions/actions';
import {
    HasRight,
    DefineColors,
    SortArray,
    HandleSelectOption,
    InitDialogBodyContent,
    Toaster,
    GetPopoverActions,
    GetType,
    parseStringToBoolean,
    isNullOrWhitespace
} from '../../../components/HelperFunctions';
import ConfirmDialogComponent from '../../../components/ConfirmDialogComponent';
import PopoverAction from '../../../components/PopoverAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import request from '../../../api/interceptors';
import PanelContent from '../../../components/PanelContent';
import noviAPI from '../../../api/noviAPI';
import { UserRights } from 'constants/userRights';
import RouteMaintenanceWorkCardPhaseForm from './components/RouteMaintenanceWorkCardForm';
import measurementUtils from 'components/measurement/utils';
import { getDate, getDateTimeBase, getInputDateTime, handleDateTimeInput, parsePayloadDateTime } from 'utils';

type RouteMaintenancePhaseMeasurementObject = { rmwcpid: string, questions: IMeasurementQuestion[]; values: { [caption: string]: any; } }

export interface Props {
    fetchRouteWorkCard: (id: number) => void;
    fetchWorkcardOptions: (arg?: string, ...rest: string[]) => void;
    fetchWorkerGroups: () => void;
    fetchRouteMaintenanceDocuments: (id: number) => void;
    fetchViewSettings: (groupType: string, actionName: string) => void;
    updateRouteWorkCard: (originalRouteWorkCard: IRouteWorkCard, routePhases: {}, redirect) => void;
    fetchDocumentMetadatas: () => void;
    deleteRouteWorkCard: DeleteRouteWorkCard;
    deleteRouteWorkCardDocument: (id: number, wcId: number, documentGroup: string) => void;
    addRouteMaintenanceDocument: IAddRouteMaintenanceDocument,
    setMachineGroupId: (machineGroupId: number) => void;
    location: any;
    history: any;
    rwcPhases: IRouteMaintenancePhase[];
    match: any;
    settings: any;
    documents: any;
    originalDocuments: any;
    routeWc: any;
    wcColors: object;
    workerOptions: {
        id: number;
        label: string;
        personGroupIds: number[];
    }[];
    workerGroupOptions: {
        id: number;
        label: string;
    }[];
    isHourCardsEnabled: boolean;
    hourCardViewSettings: IViewSetting[];
    getWorkCardColors;
    documentMetadatas?: IDocumentMetaDataGroup[];
}

interface State {
    [name: string]: any;
    addWorkCard: boolean;
    routePhaseMachine: any;
    showDialog: boolean;
    dialogType: string;
    targetItem: any;
    invalidFields: string[];
    showFileInput: boolean;
    salaryCategoryOptions;
    docRequirements: IGlobalSetting[];
    invalidDocumentFields: boolean;
    missingMetaDatas: number[];
    measurements: RouteMaintenancePhaseMeasurementObject[];
    hasChanged: boolean;
}

class RouteWorkCardView extends React.Component<Props, State> {
    docRef = React.createRef<FileInput>();

    constructor(props) {
        super(props);

        let stateProps = {
            addWorkCard: false,
            routePhaseMachine: null,
            showDialog: false,
            dialogType: '',
            targetItem: null,
            invalidFields: [],
            showFileInput: false,
            salaryCategoryOptions: [],
            docRequirements: null,
            invalidDocumentFields: false,
            missingMetaDatas: [],
            measurements: [],
            hasChanged: false
        };
        
        if (this.props.location.state && this.props.location.state.routeWcState) {
            const prevState = this.props.location.state;

            let preservedState = { ...stateProps, ...prevState.routeWcState };
            preservedState.rowId = prevState.rowId;
            this.state = preservedState;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                this.props.history.replace({ state: prevState });
            }
        } else {
            if (this.props.rwcPhases) {
                this.props.rwcPhases.forEach(routePhase => {
                    stateProps['comment' + routePhase.id] = routePhase.comment;
                    stateProps['doneDate' + routePhase.id] = getInputDateTime(routePhase.doneDate) || getDateTimeBase().format(moment.HTML5_FMT.DATETIME_LOCAL);
                    stateProps['workHours' + routePhase.id] = routePhase.workHours;
                    stateProps['isDone' + routePhase.id] = routePhase.isComplete
                        ? { id: 1, label: i18n.t('IS_DONE') }
                        : routePhase.skipped
                            ? { id: 2, label: i18n.t('ROUTEMAINTENANCE_SKIPPED') }
                            : null;
                });
            }

            if (this.props.routeWc) {
                stateProps['workers'] = this.props.routeWc.workers
                    ? this.props.routeWc.workers.map(worker => ({ id: worker.id, label: worker.name }))
                    : [];
                stateProps['workerGroups'] = this.props.routeWc.workerGroups 
                    ? this.props.routeWc.workerGroups.map(wGroup => ({ id: wGroup.id, label: wGroup.name }))
                    : [];
            }

            const routeWcId = localStorage.getItem('routeWcId');
            if (parseInt(this.props.match.params.workCardId, 10) !== parseInt(routeWcId, 10)) {
                localStorage.removeItem('activeRouteWcTab');
            }
            const storedTabKey = localStorage.getItem('activeRouteWcTab');
            stateProps['tabGroupActiveKey'] = parseInt(storedTabKey, 10) || 1;

            this.state = stateProps;

        }
    }

    componentDidMount() {
        const { workCardId } = this.props.match.params;
        const { location, settings } = this.props;

        if (!location.state.hasOwnProperty('routeWcState')) {

            noviAPI.routeMaintenanceWorkCards.fetch(workCardId)
                .then(({ data }) => {
                    const mGroupId = data?.machineGroupId;

                    if ((!location.state || location.state.noPreviousPage) && settings.machineGroupId !== mGroupId) {
                        this.props.setMachineGroupId(mGroupId);
                    }

                    this.props.fetchRouteWorkCard(parseInt(workCardId, 10));
                })

            this.props.fetchRouteMaintenanceDocuments(parseInt(workCardId, 10));
            this.props.fetchWorkerGroups();
            this.props.fetchWorkcardOptions();
            this.props.getWorkCardColors();

            if (this.props.isHourCardsEnabled) {
                this.props.fetchViewSettings('hourcard', 'FETCH_HOURCARD_VIEW_SETTINGS');
                noviAPI.salaryCategories.fetchAll()
                    .then(({ data }) => {
                        this.setState({ salaryCategoryOptions: data })
                    });
            }

            noviAPI.documents.getDocumentRequirements()
                .then(({ data }) => {
                    this.setState({ docRequirements: data })
                })
            this.props.fetchDocumentMetadatas();
        }
    }

    componentDidUpdate(prevProps) {
        const { routeWc, rwcPhases, settings } = this.props;

        if (settings.machineGroupId && settings.machineGroupId != prevProps.settings.machineGroupId) {
            this.props.fetchWorkerGroups();
            this.props.fetchWorkcardOptions();
        }

        if (routeWc && this.props.documents.documents.length !== prevProps.documents.documents.length) {
            this.props.fetchRouteMaintenanceDocuments(this.props.routeWc.id);
        }
        if (prevProps.routeWc !== routeWc && routeWc) {
            Object.keys(routeWc).forEach(key => {
                if (routeWc[key] !== null) {
                    this.setState({
                        [key]: key === 'id'
                            ? routeWc[key]
                            : key === 'workers' && routeWc.workers
                                ? (routeWc.workers.map(worker => ({ id: worker.id, label: worker.name })) || [])
                                : key === 'workerGroups' && routeWc.workerGroups
                                    ? (routeWc.workerGroups.map(wGroup => ({ id: wGroup.id, label: wGroup.name })) || [])
                                    : routeWc[key].value
                    });
                }
            });
        }
        if (!prevProps.rwcPhases && rwcPhases) {
            rwcPhases.forEach(routePhase => {
                this.setState({
                    ['comment' + routePhase.id]: routePhase.comment,
                    ['doneDate' + routePhase.id]: getInputDateTime(routePhase.doneDate) || getDateTimeBase().format(moment.HTML5_FMT.DATETIME_LOCAL),
                    ['workHours' + routePhase.id]: routePhase.workHours,
                    ['isDone' + routePhase.id]: routePhase.isComplete
                        ? { id: 1, label: i18n.t('IS_DONE') }
                        : routePhase.skipped
                            ? { id: 2, label: i18n.t('ROUTEMAINTENANCE_SKIPPED') }
                            : null
                });
            })
        }
    }

    //TODO: refactor to serve as general function in every view (workcard, machine, op.-/routemaintenances etc.)
    openDocument = item => {

        // open new tab if document is a link and return
        if (item.pathName && !item.pathName.match(/\\/)) {
            window.open(item.pathName, item.name);
            return;
        }

        // show loading spinner
        this.setState({ imgLoading: true });

        // get image as blob object
        request.get(item.downloadLink || `/api/Documents/${item.id}/download`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ progressPercentage: percentCompleted })
            }
        }).then(response => {
            const { objectUrl } = this.state;
            // revoke previous objectURL
            if (objectUrl) window.URL.revokeObjectURL(objectUrl);

            const url = window.URL.createObjectURL(response.data);
            this.setState({ objectUrl: url });

            // hide loading spinner
            this.setState({ imgLoading: false, progressPercentage: 0 });

            const officeDocument = 'application/vnd.openxmlformats-officedocument';

            // open an empty window
            const tab = window.open('about:blank');

            if (response.data.type.includes(officeDocument)) {
                // create a link elemenet and simulate click event to download document with original file name
                let link = tab.document.createElement('a');
                link.href = url;
                link.download = item.name;
                link.click();
                link.remove();
            } else if (response.data.type.includes('image')) {
                // resize large images to fit the viewport
                let css = 'body { margin: 0 } img { max-width: 100vw; }',
                    head = tab.document.head,
                    style = tab.document.createElement('style'),
                    textNode = tab.document.createTextNode(css);
                style.type = 'text/css';
                head.appendChild(style);
                style.appendChild(textNode)
                // create an image element and append it to the new tab
                let img = tab.document.createElement('img'),
                    body = tab.document.body;
                img.src = url;
                img.alt = item.name;
                body.appendChild(img);
                tab.focus();
            } else {
                tab.location.href = url;
                tab.focus();
            }

        }).catch(error => {
            Toaster({ msg: 'ACTION_FAILED', type: 'error' });
            this.setState({ imgLoading: false, progressPercentage: 0 });
        });
    }

    handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value,
            hasChanged: true
        }, () => { this.specialValidityCheck(name) })
    }

    handleSelect = (value, actionMeta, phaseId = null) => {
        const key = actionMeta.name;
        const selectedValue = HandleSelectOption(key, value);
        this.setState({ hasChanged: true });

        if (phaseId) {
            // Set current time for DoneDate if phase were marked as done
            
            if (key.includes('isDone')) {
                this.setState({
                    [key + phaseId]: selectedValue
                }, () => this.specialValidityCheck(key, phaseId))
                if (selectedValue?.id === 1 && !this.state['doneDate' + phaseId]) {
                    this.setState({
                        ['doneDate' + phaseId]: parsePayloadDateTime(moment())
                    });
                }
                // If phase were marked as not done / skipped, hourcard fields will be reset to default
                if (this.props.isHourCardsEnabled && selectedValue?.id === 2) {
                    this.setState({
                        ['salarycategory' + phaseId]: null,
                        ['hours' + phaseId]: ''
                    });
                }

            } else {
                this.setState({
                    [key + phaseId]: selectedValue
                }, () => this.specialValidityCheck(key, phaseId));
            }
        } else {
            if (key === 'workerGroups' && selectedValue) {
                this.setState({
                    [key]: selectedValue,
                    'workers': null
                }, () => this.specialValidityCheck(key))
            } else {
                this.setState({
                    [key]: selectedValue
                }, () => this.specialValidityCheck(key));
            }
        }
    }

    specialValidityCheck = (key, phaseId = null) => {
        if ('workerGroups' === key || 'workers' === key) {
            return this.checkValidity({ workers: this.state.workers })
        }
        else if ('isDone' === key) {
            // check should there be a comment and if there should, check if it's epmty
            return this.checkValidity(
                { ['comment' + phaseId]: this.state['comment' + phaseId] },
                true,
                false,
                //isDone === 1 -> valid
                (this.state['isDone' + phaseId] && this.state['isDone' + phaseId].id === 1) ||
                //isDone !== 1 -> comment !== empty -> valid
                (this.state['isDone' + phaseId] && this.state['isDone' + phaseId].id !== 1 && this.state['comment' + phaseId] !== '' && this.state['comment' + phaseId] !== null) ||
                //isDone === -1
                (!this.state['isDone' + phaseId])
            )
        }
        else if (key.includes('comment')) {
            return this.checkValidity({ [key]: this.state[key] })
        }
    }

    handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        this.setState({
            [dateInput.name]: dateInput.value,
            hasChanged: true
        });
    }

    getRouteWcData = () => {
        const { rwcPhases, isHourCardsEnabled } = this.props;
        const {
            addWorkCard,
            dialogType,
            routePhaseMachine,
            showDialog,
            tabGroupActiveKey,
            targetItem,
            workerGroups,
            workers,
            invalidFields,
            measurements,
            ...rest
        } = this.state;

        let rPhases = [];
        const routePhases = { ...rest };
        let allMeasurementValues: IMeasurementValueAddition[] = [];

        rwcPhases.forEach(rPhase => {
            let isDone = 0;
            let isSkipped = 0;
            const phaseStatus = routePhases['isDone' + rPhase.id];

            if (phaseStatus && phaseStatus.id > 0) {
                isDone = phaseStatus.id === 1 ? 1 : 0;
                isSkipped = phaseStatus.id === 2 ? 1 : 0;
            }

            let phase = {
                id: rPhase.id,
                comment: routePhases['comment' + rPhase.id],
                doneDate: isDone ? parsePayloadDateTime(routePhases['doneDate' + rPhase.id]) : '',
                workHours: routePhases['workHours' + rPhase.id] ? parseFloat(routePhases['workHours' + rPhase.id]) : 0,
                isComplete: isDone,
                skipped: isSkipped,
                routeMaintenanceHourCard: isHourCardsEnabled && routePhases['salarycategory' + rPhase.id]?.id
                    ? {
                        salaryCategoryId: routePhases['salarycategory' + rPhase.id]?.id,
                        hours: routePhases['hours' + rPhase.id],
                        customerComment: routePhases['comment' + rPhase.id]
                    } : null
            };

            rPhases.push(phase);
        });

        this.state.measurements.forEach(i => {
            const measurementValues = i.questions.map(q => {
                let measurementValue = measurementUtils.mapValueToMeasurementValueAddition(q, i.values[q.caption]);
                measurementValue.routeMaintenanceWorkCardPhaseId = Number.parseInt(i.rmwcpid);
                measurementValue.workCardId = -1;
                return measurementValue;
            })
            allMeasurementValues = allMeasurementValues.concat(measurementValues)
        })

        const routeWcData = {
            workers,
            workerGroups,
            phases: rPhases,
            measurementValues: allMeasurementValues
        };

        return routeWcData;
    }

    filterFields = (fields, filters) => Object.keys(fields)
        .filter(key => filters.some(filter => key.includes(filter)))
        .reduce((obj, key) => {
            obj[key] = fields[key];
            return obj;
        }, {})

    handleSubmit = () => {
        const {
            addWorkCard,
            dialogType,
            routePhaseMachine,
            showDialog,
            tabGroupActiveKey,
            targetItem,
            workerGroups,
            workers,
            invalidFields,
            ...rest
        } = this.state;

        let validityChecks = [];
        // check workers
        validityChecks.push(this.checkValidity({ workers }));
        // filter phases which have to be checked
        const filters = ['isDone'];
        const phaseFields = this.filterFields(rest, filters);
        // check phases
        Object.keys(phaseFields)
            .forEach((field, i) => {
                validityChecks.push(
                    this.specialValidityCheck(
                        field.substring(0, filters[0].length), // field name
                        field.substring(filters[0].length) // phaseId
                    )
                )
            })

        // if fields are valid combine data and update route work card
        if (invalidFields.length <= 0 && !validityChecks.includes(false)) {
            // List of ids of phases where isComplete === true OR skipped === true
            const unfinishedPhaseIds = Object.entries(phaseFields)
                .filter(([_field, value]) => value === null)
                .map(([field, _value]) => Number.parseInt(field.substring(filters[0].length)))

            const unfinishedPhasesWithMeasurement = this.props.rwcPhases
                .filter(i => unfinishedPhaseIds.includes(i.id) && this.isMeasurementEnabledByRoutePhase(i) && this.getMeasurementByRoutePhase(i) !== null);
            
            if (unfinishedPhasesWithMeasurement.length === 0) {
                this.updateRouteWorkCard();
            } else {
                this.setDialogProperties({ type: 'measurements', phases: unfinishedPhasesWithMeasurement }, 'ignore')
            }
        } else {
            Toaster({ msg: 'INVALID_FIELDS', type: 'error' });
        }
    }

    isPhaseDone = (phase: IRouteMaintenancePhase) => (phase.isComplete || phase.skipped); 

    isMeasurementEnabledByRoutePhase = (existingPhase: IRouteMaintenancePhase) => (!this.isPhaseDone(existingPhase) && !!existingPhase.measurementGroupId);

    getMeasurementByRoutePhase = (phase: IRouteMaintenancePhase) => this.state.measurements.find(i => Number.parseInt(i.rmwcpid) === phase.id) ?? null;

    updateRouteWorkCard = () => {
        const { routeWc } = this.props;
        const routeWcData = this.getRouteWcData();
        this.props.updateRouteWorkCard(routeWc, routeWcData, this.props.history.replace);
    }

    closeAddWorkCard = () => {
        this.setState({ addWorkCard: false })
    }

    deleteRouteWorkCard = id => {
        this.props.deleteRouteWorkCard(id, this.props.history.replace);
    }

    deleteItem = item => {
        switch (item.type) {
            case 'doc':
                const { originalDocuments } = this.props;

                let documentGroup = '';
                if (!!originalDocuments.documents.find(doc => doc.id === item.id)) {
                    documentGroup = 'documents';
                } else if (!!originalDocuments.routeMaintenanceDocuments.find(doc => doc.id === item.id)) {
                    documentGroup = 'routeMaintenanceDocuments';
                }

                this.props.deleteRouteWorkCardDocument(item.id, parseInt(this.props.match.params.workCardId, 10), documentGroup);
                toast.success(i18n.t('DOCUMENT_DELETED'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                this.resetDialogProps();
                break;
            default:
                return;
        }
    }

    getDialogContent = () => {
        const { dialogType } = this.state;
        if (!dialogType) return {
            title: '',
            body: '',
            type: '',
            params: null
        }
        
        const dialogContents = {
            'delete_wc': {
                title: i18n.t('DELETE_WORKCARD'),
                body: InitDialogBodyContent('ROUTE_MAINTENANCE', this.props.match.params.workCardId),
                type: 'delete',
                params: this.props.match.params.workCardId
            },
            'delete_doc': {
                title: i18n.t('DELETE_DOCUMENT'),
                body: InitDialogBodyContent('DOCUMENT', this.state.targetItem && (this.state.targetItem.name || this.state.targetItem.id)),
                type: 'delete',
                params: this.state.targetItem && { id: this.state.targetItem.id, type: this.state.targetItem.type }
            },
            'ignore_measurements': {
                title: i18n.t('CONFIRM'),
                body: this.getMeasurementDialogContent(),
                type: 'ignore',
                params: null
            }
        }

        return dialogContents[dialogType];
    }

    getMeasurementDialogContent = () => (
        <React.Fragment>
            <p>{`${i18n.t('UNFINISHED_ROUTE_MAINTENANCE_POINTS_HAVE_UNSAVED_MEASUREMENTS')}:`}</p>
            <ul>
                {this.state.targetItem.phases?.map(i => (
                    <li>{i.machine ? `${i.machine.code} / ${i.machine.name}` : i18n.t('MACHINE_NOT_DEFINED')}</li>
                ))}
            </ul>
            <p>{`${i18n.t('CONFIRM_ROUTE_MAINTENANCE_SAVE')}`}</p>
        </React.Fragment>
    )

    getCallbackFn = () => {
        const { dialogType } = this.state;
        if (dialogType === 'delete_wc') {
            return this.deleteRouteWorkCard;
        }
        if (dialogType === 'delete_doc') {
            return this.deleteItem;
        }
        if (dialogType === 'ignore_measurements') {
            return this.updateRouteWorkCard;
        }
    }

    resetDialogProps = () => {
        this.setState({
            showDialog: false,
            dialogType: null,
            targetItem: null
        });
    }

    setDialogProperties = (item, type) => {
        const { popoverRef } = this.state;

        this.setState({
            showDialog: true,
            dialogType: type + '_' + item.type,
            targetItem: item,
        });
    }

    addDocument = () => {
        const { docRequirements } = this.state;
        const { linkToDocument, description, type, file, metaData } = this.docRef.current.state;
        const routeMaintenanceWorkCardId = this.props.match.params.workCardId;

        if ((file === null && linkToDocument === '') || (file !== null && linkToDocument !== '')) {
            toast.info(i18n.t('SELECT_FILE_OR_LINK'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            return;
        }

        const missingMetaDatas = this.props.documentMetadatas.filter(field => {
            const val = metaData[field.label];
            return (field.required && (isNullOrWhitespace(val) || val?.id == -1));
        });


        if (missingMetaDatas.length || docRequirements?.find(req => req.field === 'description') && !description) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ 
                invalidDocumentFields: true,
                missingMetaDatas: missingMetaDatas.map(x => x.id)
            });
            return;
        }

        const documentMetaDataIds = Object.values(metaData)
            .map(data => data?.id ?? 0)
            .filter(id => id > 0);

        const document = {
            routeMaintenanceWorkCardId,
            linkToDocument,
            description,
            type,
            file,
            documentMetaDataIds
        }

        this.props.addRouteMaintenanceDocument(document, true)

        this.setState({ documents: [document] });
        this.closeDocumentForm();
    }

    openDocumentForm = () => {
        this.setState({ showFileInput: true })
    }

    closeDocumentForm = () => {
        this.setState({
            showFileInput: false,
            invalidDocumentFields: false,
            missingMetaDatas: []
        })
    }

    setOverlayNode = node => {
        if (node && !this.state.popoverRef[node.props.overlay.key]) {
            this.setState(state => ({
                popoverRef: {
                    ...state.popoverRef,
                    [node.props.overlay.key]: node
                }
            }));
        }
    }

    handleTabGroup = (value) => {
        this.setState({ tabGroupActiveKey: value });
        localStorage.setItem('activeRouteWcTab', value)
        localStorage.setItem('routeWcId', this.props.match.params.workCardId)
    }

    handleAccordion = (value) => {
        this.setState({ accordionActiveKey: value });
    }

    handlePopoverClick = (actionName = null, item = null) => {
        if (actionName === 'delete') {
            this.setDialogProperties(item, 'delete');
        }
        if (actionName === 'view') {
            this.openDocument(item);
        }
    }

    navigateBackToWorklist = (params) => {
        this.props.history.replace(params.path, params.state);
    }

    checkValidity = (fields, autoSetState = true, orCondition = true, andCondition = false) => {
        let invalidFields = [];
        Object.keys(fields).forEach(k => {
            const v = fields[k];
            const defaultConditions = v === undefined || v === null || v.length === 0 || (v.id && v.id === -1);
            if ((defaultConditions || !orCondition) && !andCondition) {
                invalidFields.push(k);
            } else {
                this.setState(state => ({ invalidFields: state.invalidFields.filter(field => field !== k) }))
            }
        })
        if (autoSetState) this.setState(state => ({ invalidFields: state.invalidFields.concat(invalidFields) }))
        return invalidFields.length <= 0
    }

    getAddinationalDetailActions = () => {
        return {
            'doc': GetPopoverActions(this.props.settings, 'workcarddocument')
        }
    }

    getMenu = (item) => {
        const children = this.getAddinationalDetailActions()[item.type].map((action, index) => {
            return <div key={index}>
                <PopoverAction
                    icon={action.icon}
                    label={action.label}
                    paClassName={''}
                    onClick={() => this.handlePopoverClick(action.action, item)}
                />
                {this.getAddinationalDetailActions()[item.type].length > index + 1 && <hr />}
            </div>
        });

        return (<Popover id="settings-menu-popover" className="panel-menu-popover">
            {React.Children.map(children, (child, i) => (
                <div className="sub-section" key={`setting_${i}`}>
                    <div className="setting-option">
                        {child}
                    </div>
                </div>
            ))}
        </Popover>);
    }

    render() {
        const { 
            match, 
            location, 
            history, 
            settings, 
            documents, 
            rwcPhases, 
            wcColors,
            routeWc,
            workerOptions, 
            workerGroupOptions,
            isHourCardsEnabled,
            hourCardViewSettings
        } = this.props;

        const { showDialog, salaryCategoryOptions } = this.state;

        const documentSets = {
            'documents': {
                title: 'DOCUMENTS',
                items: documents.documents || {},
                count: documents.documents.length
            },
            'route_maintenance_documents': {
                title: 'MAINTENANCE_DOCUMENTS',
                items: documents.routeMaintenanceDocuments || [],
                count: documents.routeMaintenanceDocuments.length
            }
        }

        const sceneData = {
            view: this.state.addWorkCard ? 'routewcFaultnotice' : 'routeworkcard',
            title: match.params.workCardId,
            location: location,
            history: history,
            itemColors: routeWc ? wcColors[routeWc.id] : [],
            hasChanged: this.state.hasChanged,
            backAction: location.state && location.state.scrollPosition
                ? {
                    action: this.navigateBackToWorklist,
                    params: {
                        path: '/worklist',
                        state: { heightPos: location.state.scrollPosition || 0 },
                        mainEditView: true
                    }
                }
                : this.state.showFileInput
                    ? { action: this.closeDocumentForm }
                : { action: history.goBack, params: {mainEditView: true} }
        }

        const actions = [];

        if (HasRight('routemaintenancedele', settings.userRights)) {
            actions.push({
                icon: 'delete',
                label: i18n.t('REMOVE'),
                clickFn: this.setDialogProperties,
                isActionFn: true,
                params: [{ type: 'wc' }, 'delete'],
                paClass: ''
            });
        }

        if (HasRight(UserRights.WorkScheduleEdit, settings.userRights)) {
            actions.push({
                icon: 'save',
                label: i18n.t('TALLENNA'),
                clickFn: this.handleSubmit,
                isActionFn: true,
                paClass: '',
                dataCy: 'save_button'
            });

            actions.push({
                icon: 'add-circle',
                label: i18n.t('ADD_DOCUMENT'),
                clickFn: this.openDocumentForm,
                isActionFn: true,
                params: [true],
                paClass: ''
            })
        }

        const popoverData = {
            popoverClass: 'menu-popover',
            popoverActions: actions
        };

        const addinationalDetailActions = {
            doc: GetPopoverActions(settings, 'workcarddocument'),
        }

        const panelGroups = {
            accordPanels: [{
                title: 'DOCUMENTS',
                hierarchyEnabled: false,
                panels: [{type: 'documents'}, {type: 'route_maintenance_documents'}]
            }]
        };

        const relationViews = ['routewcFaultnotice', 'routeworkcard', 'workcarddocument'];
        const viewAction = this.state.showFileInput
            ? {
                icon: 'save',
                label: '',
                clickFn: this.addDocument,
                isActionFn: true,
                paClass: 'start-phase',
            } : relationViews.find(view => view === location?.state?.viewProperty)
                ? ''
                : 'settings';

        let itemsLength = 0;
        panelGroups.accordPanels.forEach(accordPanel => {
            accordPanel.panels.forEach(panel => {
                itemsLength = itemsLength + documentSets[panel.type].count;
            })
        });

        let hourCardFields = [];
        if (isHourCardsEnabled && hourCardViewSettings) {
            hourCardViewSettings
                .filter(setting => (setting.field === 'salarycategory' || setting.field === 'hours'))
                .forEach(setting => {
                    setting.type = GetType(setting.field, setting.type)
                    setting.options = setting.field === 'salarycategory' ? salaryCategoryOptions : [];
                    hourCardFields.push(setting);
                });
        }

        return (
            <div>
                <NavigationBar
                    currentView={sceneData}
                    navHistory={history}
                    viewAction={viewAction}
                    popoverData={popoverData}
                />
                {showDialog && <ConfirmDialogComponent
                    dialogContent={this.getDialogContent()}
                    callBack={this.getCallbackFn()}
                    cancelDialog={this.resetDialogProps}
                />}
                {this.state.showFileInput ? <FileInput 
                    ref={this.docRef} 
                    docRequirements={this.state.docRequirements} 
                    invalidFields={this.state.invalidDocumentFields} 
                    missingMetaDatas={this.state.missingMetaDatas}
                    docMetadata={this.props.documentMetadatas}
                /> : 
                    <div id="work-card-view">
                        <Tabs
                            activeKey={this.state.tabGroupActiveKey}
                            onSelect={this.handleTabGroup}
                            id="workCardViewTabs"
                            className="novi-nav-tabs"
                        >
                            <Tab eventKey={1} className="bottom-nav-space" title={<span>{i18n.t('ROUTE').toUpperCase()}{rwcPhases && <span className="number-badge">{rwcPhases.length}</span>}</span>}>
                                <RouteMaintenanceWorkCardPhaseForm
                                    routeWcState={this.state}
                                    workerGroupOptions={workerGroupOptions}
                                    workerOptions={workerOptions}
                                    handleInputChange={this.handleInputChange}
                                    handleDatetime={this.handleDateTime}
                                    handleSelect={this.handleSelect}
                                    hourCardFields={hourCardFields}
                                    phases={rwcPhases}
                                />
                            </Tab>
                            <Tab eventKey={2}
                                title={
                                    <span>
                                        {i18n.t('DETAILS').toUpperCase()}
                                        <span className="number-badge">{itemsLength}</span>
                                    </span>
                                }
                            >
                                <Container>
                                    {panelGroups.accordPanels.map((panelGroup, i) => (
                                        <Accordion
                                            key={i}
                                            id="additional-info-panel"
                                            className="margin-top-15 novi-panel"
                                            defaultActiveKey={this.state.accordionActiveKey}
                                            onSelect={this.handleAccordion}
                                        >
                                            <h2>{i18n.t(panelGroup.title).toUpperCase()}</h2>
                                            {panelGroup.panels.map((panel, j) => (
                                                <PanelContainer
                                                    id={j}
                                                    title={i18n.t(documentSets[panel.type].title)}
                                                    key={j}
                                                    panelIcons={[]}
                                                    subTitle={''}
                                                    itemCount={documentSets[panel.type].count}
                                                >
                                                    <div>
                                                        {documentSets[panel.type].items.map((doc, k) => (
                                                            <Row key={k} className="sub-item-container">
                                                                <Col xs={doc.createDate ? 7 : 10}>
                                                                    <PanelContent
                                                                        name={doc.name}
                                                                        contentType={doc.type}
                                                                        details={doc.details}
                                                                    />

                                                                </Col>
                                                                {doc.createDate &&
                                                                    <Col xs={3}>
                                                                        <p className="small right">{getDate(doc.createDate)}</p>
                                                                    </Col>}
                                                                <Col xs={2}>
                                                                    <div className="options-container">
                                                                        <span className="bar" />
                                                                        <OverlayTrigger
                                                                            trigger="click"
                                                                            placement="left"
                                                                            overlay={this.getMenu(doc)}
                                                                            rootClose={true}
                                                                        >
                                                                            <span className="options-button" ><FontAwesomeIcon icon={faEllipsisV} size="lg" /></span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </div>
                                                </PanelContainer>
                                            ))}
                                        </Accordion>
                                    ))}
                                </Container>
                            </Tab>
                        </Tabs>
                    </div>
                }
            </div>    
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    const wcId = parseInt(ownProps.match.params.workCardId, 10);
    //const isOffline = !state.offline.online;
    const routeWorkCard = state.workcards.workcards.find(wc => wc.id === wcId);
    const workerGroups = state.settings.workerGroups.map(wGroup => ({ id: wGroup.id, label: wGroup.name }));

    const HourCardsEnabled = parseStringToBoolean(state.settings.noviConfigs.HourCardsEnabled);
    const HourCardGenerationOnRouteMaintenance = parseStringToBoolean(state.settings.noviConfigs.HourCardGenerationOnRouteMaintenance);
    
    let documentsData = state.documents.workCard.routeWorkCardDocuments;

    let wcColors = {};
    const colorConfigs = {
        colors: state.settings.colors,
        settings: state.settings.colorSettings,
        config: state.settings.colorConfig['FillTags']
    }

    if (routeWorkCard) {
        wcColors = { [routeWorkCard.id]: DefineColors(routeWorkCard, colorConfigs) };
    }

    const formDocumentsData = (documentData) => {
        return documentData.map(doc => {
            return {
                id: doc.id,
                name: doc.caption || doc.documentLink,
                details: {
                    type: doc.type,
                    description: doc.description
                },
                editable: false,
                removable: true,
                view: true,
                createDate: doc.createDate,
                pathName: doc.documentLink,
                downloadLink: doc.downloadLink,
                type: 'doc'
            }
        });
    }

    const documents = {
        documents: documentsData.documents ? formDocumentsData(documentsData.documents) : [],
        routeMaintenanceDocuments: documentsData.routeMaintenanceDocuments ? formDocumentsData(documentsData.routeMaintenanceDocuments) : []
    }

    const isHourCardsEnabled = (HourCardsEnabled && HourCardGenerationOnRouteMaintenance)

    return {
        routeWc: routeWorkCard,
        wcColors: wcColors,
        rwcPhases: routeWorkCard ? routeWorkCard.phases : [],
        settings: state.settings,
        documents: documents,
        workerOptions: SortArray(state.workcards.options.workerOptions, 'label'),
        workerGroupOptions: SortArray(workerGroups, 'label'),
        originalDocuments: documentsData,
        isHourCardsEnabled,
        hourCardViewSettings: state.hourcards.viewSettings?.hourcard || [],
        documentMetadatas: state.documents.metadatas,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchRouteWorkCard,
    fetchWorkcardOptions,
    fetchWorkerGroups,
    fetchRouteMaintenanceDocuments,
    updateRouteWorkCard,
    deleteRouteWorkCard,
    deleteRouteWorkCardDocument,
    addRouteMaintenanceDocument,
    fetchViewSettings,
    setMachineGroupId,
    getWorkCardColors,
    fetchDocumentMetadatas
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RouteWorkCardView);
