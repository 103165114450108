import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ListWithRemoveItem = (props: IListWithRemoveItemProps) => {

    const list = props.itemList.map((item, index) => (
        <p key={index}>
            {item}
            <span className="row-action icon-warning float-right" onClick={() => props.removeItem(index)}>
                <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </span>
        </p>
    ));

    return (
        <div style={{ width: '100%' }}>
            {list.length > 0
                ? list
                : <p className="icon-default">{props.noItemsText ?? ''}</p>
            }
        </div>
    );
}

export interface IListWithRemoveItemProps {
    itemList: Item[];
    removeItem: (index: number) => void;
    noItemsText?: string;
}

type Item = string;

export default ListWithRemoveItem;