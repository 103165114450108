import React from 'react';
import { Col, Row, OverlayTrigger, Popover } from 'react-bootstrap';
import i18n from '../translations/i18n';
import PanelContent from './PanelContent';
import PopoverContainer from './PopoverContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSpinner, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import PopoverAction from './PopoverAction';
import { getDate } from 'utils';

const PanelItem = ({ item, isOffline = false, actions, handlePopoverClick, documentQueue }) => {
    return (
        <div>
            <Row className={
                (isOffline && item.type === 'doc')
                    || (documentQueue && documentQueue.includes(item.id))
                    ? 'disabled-action sub-item-container'
                    : 'sub-item-container'
            }>
                <Col xs={item.createDate ? 7 : 10}>
                    <PanelContent 
                        name={item.name}
                        contentType={item.type}
                        details={item.details}
                    />
                </Col>
                {item.createDate &&
                    <Col xs={3}>
                        <p className="small right">{getDate(item.createDate)}</p>
                    </Col>}
                <Col xs={2}>
                    <div className="options-container">
                        <span className="bar" />
                        {isOffline && item.type === 'doc'
                            ? <OverlayTrigger
                                trigger="click"
                                placement="left"
                                overlay={
                                    <PopoverContainer popoverclass="panel-menu-popover">
                                        {i18n.t('NO_CONNECTION')}
                                    </PopoverContainer>
                                }
                                rootClose={true}
                            >
                                <div><FontAwesomeIcon icon={faInfoCircle} style={{ color: '#c4c4c4' }} /></div>
                            </OverlayTrigger>
                            : item.type === 'doc' && documentQueue.includes(item.id)
                                ? <FontAwesomeIcon icon={faSpinner} size="lg" pulse />
                                : <OverlayTrigger
                                    trigger="click"
                                    placement="left"
                                    overlay={
                                        <Popover id="settings-menu-popover" className="panel-menu-popover">
                                            {actions[item.type].map((action, index) => {
                                                return <div className="sub-section" key={`setting_${index}`}>
                                                    <div className="setting-option">
                                                        <div>
                                                            <PopoverAction
                                                                icon={action.icon}
                                                                label={action.label}
                                                                paClassName={''}
                                                                onClick={() => handlePopoverClick(action.action, item)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {index !== actions[item.type].length - 1 && <hr />}
                                                </div>
                                            })}
                                        </Popover>
                                    }
                                    rootClose={true}
                                >
                                    <span className="options-button" ><FontAwesomeIcon icon={faEllipsisV} size="lg" /></span>
                                </OverlayTrigger>}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PanelItem;
