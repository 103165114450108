import React from 'react';
import { Modal } from 'react-bootstrap';

const DialogHeader = (props: IProps) => {
    return (
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.children}
            </Modal.Title>
        </Modal.Header>
    );
}

interface IProps {
    children: React.ReactNode | React.ReactNode[];
}

export default DialogHeader;