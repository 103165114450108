import * as types from '../config/actionTypes';

const initialState: IDocumentState = {
    status: null,
    queue: [],
    machine: {
        documents: [],
        childMachineDocuments: [],
        workCardDocuments: []
    },
    storedMachineDocuments: [],
    workCard: {
        workcardDocuments: {
            documents: [],
            machineDocuments: [],
            maintenanceSubPlanDocuments: [],
            operatorMaintenanceDocuments: [],
            routeMaintenancePhaseDocuments: []
        },
        routeWorkCardDocuments: {
            documents: [],
            routeMaintenanceDocuments: []
        }
    },
    storedWorkCardDocuments: [],
    storedRouteWorkCardDocuments: [],
    sparePart: {
        sparePartDocuments: []
    },
    metadatas: []
}

const documentReducer = (state: IDocumentState = initialState, action) => {
    switch (action.type) {
        case types.ADD_WORKCARD_DOCUMENT:
            const { workCardId, ...payload } = action.payload;
            return {
                ...state,
                queue: state.queue.concat([payload.id]),
                workCard: {
                    ...state.workCard,
                    workcardDocuments: {
                        ...state.workCard.workcardDocuments,
                        documents: state.workCard.workcardDocuments.documents.concat([payload])
                    }
                },
                storedWorkCardDocuments:[],
                machine: {
                    ...state.machine,
                    workCardDocuments: state.machine.workCardDocuments.map(wc => {
                        if (wc.workCard.id === workCardId) {
                            return {
                                ...wc,
                                documents: wc.documents.concat([payload])
                            };
                        }
                        return wc;
                    })
                },
                storedMachineDocuments:
                    state.storedMachineDocuments.map(machine => {
                        return {
                            ...machine,
                            data: {
                                ...machine.data,
                                workCardDocuments: machine.data.workCardDocuments.map(wc => {
                                    if (wc.workCard.id === workCardId) {
                                        return {
                                            ...wc,
                                            documents: wc.documents.concat([payload])
                                        };
                                    }
                                    return wc;
                                })
                            }
                        };
                    })
            }
        case `${types.ADD_WORKCARD_DOCUMENT}_COMMIT`:
            const { data: id } = action.payload;
            return {
                ...state,
                queue: state.queue.filter(id => id !== action.meta.tempId),
                workCard: {
                    ...state.workCard,
                    workcardDocuments: {
                        ...state.workCard.workcardDocuments,
                        documents: state.workCard.workcardDocuments.documents.map(document => {
                            if (document.id === action.meta.tempId) {
                                return {
                                    ...document,
                                    id
                                };
                            }
                            return document;
                        })
                    }
                },
                storedWorkCardDocuments: state.storedWorkCardDocuments.map(wc => {
                    if (wc.wcId === action.meta.workCardId) {
                        return {
                            ...wc,
                            data: {
                                ...wc.data,
                                documents: wc.data.documents.map(document => {
                                    if (document.id === action.meta.tempId) {
                                        return {
                                            ...document,
                                            id
                                        };
                                    }
                                    return document;
                                })
                            }
                        };
                    }
                    return wc;
                }),
                machine: {
                    ...state.machine,
                    workCardDocuments: state.machine.workCardDocuments.map(wc => {
                        if (wc.workCard.id === action.meta.workCardId) {
                            return {
                                ...wc,
                                documents: wc.documents.map(document => {
                                    if (document.id === action.meta.tempId) {
                                        return {
                                            ...document,
                                            id
                                        };
                                    }
                                    return document;
                                })
                            };
                        }
                        return wc;
                    })
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    return {
                        ...machine,
                        data: {
                            ...machine.data,
                            workCardDocuments: machine.data.workCardDocuments.map(wc => {
                                if (wc.workCard.id === action.meta.workCardId) {
                                    return {
                                        ...wc,
                                        documents: wc.documents.map(document => {
                                            if (document.id === action.meta.tempId) {
                                                return {
                                                    ...document,
                                                    id
                                                };
                                            }
                                            return document;
                                        })
                                    };
                                }
                                return wc;
                            })
                        }
                    };
                })
            }
        case `${types.ADD_WORKCARD_DOCUMENT}_ROLLBACK`:
            return {
                ...state,
                queue: state.queue.filter(id => id !== action.meta.tempId),
                workCard: {
                    ...state.workCard,
                    documents: state.workCard.workcardDocuments.documents.filter(document => document.id !== action.meta.tempId)
                },
                storedWorkCardDocuments: state.storedWorkCardDocuments.map(wc => {
                    if (wc.wcId === action.meta.workCardId) {
                        return {
                            ...wc,
                            data: {
                                ...wc.data,
                                documents: wc.data.documents.filter(document => document.id !== action.meta.tempId)
                            }
                        };
                    }
                    return wc;
                }),
                machine: {
                    ...state.machine,
                    workCardDocuments: state.machine.workCardDocuments.map(wc => {
                        if (wc.workCard.id === action.meta.workCardId) {
                            return {
                                ...wc,
                                documents: wc.documents.filter(document => document.id !== action.meta.tempId)
                            };
                        }
                        return wc;
                    })
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    return {
                        ...machine,
                        data: {
                            ...machine.data,
                            workCardDocuments: machine.data.workCardDocuments.map(wc => {
                                if (wc.workCard.id === action.meta.workCardId) {
                                    return {
                                        ...wc,
                                        documents: wc.documents.filter(document => document.id !== action.meta.tempId)
                                    };
                                }
                                return wc;
                            })
                        }
                    };
                })
            }
        case types.UPDATE_WORKCARD_DOCUMENT:
            return {
                ...state,
                workCard: {
                    ...state.workCard,
                    documents: state.workCard.workcardDocuments.documents.map(document => {
                        if (document.id !== parseInt(action.id)) { return document }
                        return { ...document, document: action.payload }
                    })
                },
                machine: {
                    ...state.machine,
                    workCardDocuments: state.machine.workCardDocuments.map(item => {
                        if (item.workCard.id !== parseInt(action.wcId, 10)) { return item; }

                        return {
                            ...item,
                            documents: item.documents.map(document => {
                                if (document.id !== parseInt(action.id, 10)) { return document }
                                return { ...document, document: action.payload }
                            })
                        }
                    })
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    return {
                        ...machine,
                        data: {
                            ...machine.data,
                            workCardDocuments: machine.data.workCardDocuments.map(wc => {
                                if (wc.id !== parseInt(action.wcId, 10)) { return wc; }

                                return {
                                    ...wc,
                                    documents: wc.documents.map(document => {
                                        if (document.id !== parseInt(action.id, 10)) { return document; }

                                        return { ...document, document: action.paylaod }
                                    })
                                }
                            })
                        }
                    }
                })
            }
        case `${types.FETCH_WORKCARD_DOCUMENTS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_WORKCARD_DOCUMENTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                workCard: {
                    ...state.workCard,
                    workcardDocuments: action.payload.documents
                },
                storedWorkCardDocuments: state.storedWorkCardDocuments.findIndex(wc => {
                    return wc.wcId === action.payload.wcId
                }) === -1
                    ? state.storedWorkCardDocuments.concat([{ wcId: action.payload.wcId, data: action.payload.documents }])
                    : state.storedWorkCardDocuments.map(wc => {
                        if (wc.wcId === action.payload.wcId) {
                            return {
                                ...wc,
                                data: action.payload.documents
                            }
                        }
                        return wc;
                    })
            }
        case `${types.FETCH_WORKCARD_DOCUMENTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                workCard: {
                    ...state.workCard,
                    workcardDocuments: {
                        documents: [],
                        machineDocuments: [],
                        maintenanceSubPlanDocuments: [],
                        operatorMaintenanceDocuments: [],
                        routeMaintenancePhaseDocuments: [],

                    }
                }
            }
        case `${types.FETCH_ROUTE_WORKCARD_DOCUMENTS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_ROUTE_WORKCARD_DOCUMENTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                workCard: {
                    ...state.workCard,
                    routeWorkCardDocuments: action.payload.documents
                }
            }
        case `${types.FETCH_ROUTE_WORKCARD_DOCUMENTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                workCard: {
                    ...state.workCard,
                    routeWorkCardDocuments: {
                        documents: [],
                        routeMaintenanceDocuments: []
                    }
                }
            }
        case types.ADD_ROUTE_WORKCARD_DOCUMENT:
            const { routeWorkCardId, ...routeWcPayload } = action.payload;
            return {
                ...state,
                queue: state.queue.concat([routeWcPayload.id]),
                workCard: {
                    ...state.workCard,
                    routeWorkCardDocuments: {
                        ...state.workCard.routeWorkCardDocuments,
                        documents: state.workCard.routeWorkCardDocuments.documents.concat([routeWcPayload])
                    }
                },
            }
        case types.ADD_MACHINE_DOCUMENT:
            const {
                machineId,
                ...machineDocPayload
            } = action.payload;

            return {
                ...state,
                machine: {
                    ...state.machine,
                    documents: state.machine.documents.concat([machineDocPayload])
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    if (machine.mId === machineId) {
                        return {
                            ...machine,
                            data: {
                                ...machine.data,
                                documents: machine.data.documents.concat([machineDocPayload])
                            }
                        };
                    }
                    return {
                        ...machine,
                        data: {
                            ...machine.data,
                            childMachineDocuments: machine.data.childMachineDocuments.map(childMachine => {
                                if (childMachine.machine.id === machineId) {
                                    return {
                                        ...childMachine,
                                        documents: childMachine.documents.concat([machineDocPayload])
                                    };
                                }
                                return childMachine;
                            })
                        }
                    };
                })
            }
        case `${types.ADD_MACHINE_DOCUMENT}_COMMIT`:
            const { data: machineDocId }= action.payload;
            return {
                ...state,
                machine: {
                    ...state.machine,
                    documents: state.machine.documents.map(document => {
                        if (document.id === action.meta.tempId) {
                            return {
                                ...document,
                                id: machineDocId
                            };
                        }
                        return document;
                    })
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    if (machine.mId === action.meta.machineId) {
                        return {
                            ...machine,
                            data: {
                                ...machine.data,
                                documents: machine.data.documents.map(document => {
                                    if (document.id === action.meta.tempId) {
                                        return {
                                            ...document,
                                            id: machineDocId
                                        };
                                    }
                                    return document;
                                })
                            }
                        };
                    }
                    return {
                        ...machine,
                        data: {
                            ...machine.data,
                            childMachineDocuments: machine.data.childMachineDocuments.map(childMachine => {
                                if (childMachine.machine.id === action.meta.machineId) {
                                    return {
                                        ...childMachine,
                                        documents: childMachine.documents.map(document => {
                                            if (document.id === action.meta.tempId) {
                                                return {
                                                    ...document,
                                                    id: machineDocId
                                                };
                                            }
                                            return document;
                                        })
                                    };
                                }
                                return childMachine;
                            })
                        }
                    };
                })
            }
        case `${types.ADD_MACHINE_DOCUMENT}_ROLLBACK`:
            return {
                ...state,
                machine: {
                    ...state.machine,
                    documents: state.machine.documents.filter(document => document.id !== action.meta.tempId)
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    if (machine.mId === action.meta.machineId) {
                        return {
                            ...machine,
                            data: {
                                ...machine.data,
                                documents: machine.data.documents.filter(document => document.id !== action.meta.tempId)
                            }
                        };
                    }
                    return {
                        ...machine,
                        data: {
                            ...machine.data,
                            childMachineDocuments: machine.data.childMachineDocuments.map(childMachine => {
                                if (childMachine.machine.id === action.meta.machineId) {
                                    return {
                                        ...childMachine,
                                        documents: childMachine.documents.filter(document => document.id !== action.meta.tempId)
                                    };
                                }
                                return childMachine;
                            })
                        }
                    };
                })
            }
        case `${types.FETCH_MACHINE_DOCUMENTS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MACHINE_DOCUMENTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machine: {
                    ...state.machine,
                    documents: action.payload.data
                }
            }
        case `${types.FETCH_MACHINE_DOCUMENTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                machine: {
                    ...state.machine,
                    documents: []
                }
            }
        case `${types.FETCH_CHILD_MACHINE_DOCUMENTS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_CHILD_MACHINE_DOCUMENTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machine: {
                    ...state.machine,
                    childMachineDocuments: action.payload.data,
                }
            }
        case `${types.FETCH_CHILD_MACHINE_DOCUMENTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                machine: {
                    ...state.machine,
                    childMachineDocuments: []
                }
            }
        case `${types.FETCH_MACHINE_WORKCARD_DOCUMENTS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_MACHINE_WORKCARD_DOCUMENTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                machine: {
                    ...state.machine,
                    workCardDocuments: action.payload.data
                }
            }
        case `${types.FETCH_MACHINE_WORKCARD_DOCUMENTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                machine: {
                    ...state.machine,
                    workCardDocuments: []
                }
            }
        case types.DELETE_WORKCARD_DOCUMENT:
            const { wcDocumentGroup } = action.payload;

            const machineWcDocs = wcDocumentGroup === 'workcardDocuments' ? state.machine.workCardDocuments.map(item => {
                if (item.workCard.id === action.payload.wcId) {
                    return {
                        ...item,
                        documents: item.documents.filter(document => document.id !== action.payload.id)
                    }
                }
                return item;
            }) : state.machine.workCardDocuments;

            return {
                ...state,
                workCard: {
                    ...state.workCard,
                    workcardDocuments: {
                        ...state.workCard.workcardDocuments,
                        [wcDocumentGroup]: state.workCard.workcardDocuments[wcDocumentGroup].filter(document => document.id !== action.payload.id)
                    }

                },
                storedWorkCardDocuments: state.storedWorkCardDocuments.map(wc => {
                    if (wc.wcId === action.payload.wcId) {
                        return {
                            ...wc,
                            data: {
                                ...wc.data,
                                [wcDocumentGroup]: wc.data[wcDocumentGroup].filter(document => document.id !== action.payload.id)
                            }
                        };
                    }
                    return wc;
                }),
                machine: {
                    ...state.machine,
                    workCardDocuments: machineWcDocs
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    const machineWcDocs = wcDocumentGroup === 'workcardDocuments' ? machine.data.workCardDocuments.map(item => {
                        if (item.workCard.id === action.payload.wcId) {
                            return {
                                ...item,
                                documents: item.documents.filter(document => document.id !== action.payload.id)
                            }
                        }
                        return item;
                    }) : machine.data.workCardDocuments;

                    return {
                        ...machine,
                        data: {
                            ...machine.data,
                            workCardDocuments: machineWcDocs
                        }
                    }
                })
            }
        case types.DELETE_ROUTE_WORKCARD_DOCUMENT:
            const { routeWcDocumentGroup } = action.payload;

            return {
                ...state,
                workCard: {
                    ...state.workCard,
                    routeWorkCardDocuments: {
                        ...state.workCard.routeWorkCardDocuments,
                        [routeWcDocumentGroup]: state.workCard.routeWorkCardDocuments[routeWcDocumentGroup].filter(document => document.id !== action.payload.id)
                    }
                }
            }
        case types.DELETE_MACHINE_DOCUMENT:
            return {
                ...state,
                machine: {
                    ...state.machine,
                    documents: state.machine.documents.filter(document => document.id !== action.payload.id)
                },
                storedMachineDocuments: state.storedMachineDocuments.map(machine => {
                    if (machine.mId === action.payload.mId) {
                        return {
                            ...machine,
                            data: {
                                ...machine.data,
                                documents: machine.data.documents.filter(document => document.id !== action.payload.id),
                                childMachineDocuments: machine.data.childMachineDocuments.map(item => {
                                    if (item.machine.id === action.payload.mId) {
                                        return {
                                            ...item,
                                            documents: item.documents.filter(document => document.id !== action.payload.id)
                                        };
                                    }
                                    return item;
                                })
                            }
                        };
                    }
                    return machine;
                })
            }
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
        case types.CLEAR_CACHE:
            return initialState;
        case types.STATUS_FULFILLED:
            return {
                ...state,
                status: 'fulfilled',
            }
        case `${types.FETCH_SPARE_PART_DOCUMENTS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_SPARE_PART_DOCUMENTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                sparePart: {
                    ...state.sparePart,
                    sparePartDocuments: action.payload
                }
            }
        case `${types.FETCH_SPARE_PART_DOCUMENTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                sparePart: {
                    ...state.sparePart,
                    sparePartDocuments: []
                }
            }
        case types.DELETE_SPARE_PART_DOCUMENT:
            return {
                ...state,
                sparePart: {
                    ...state.sparePart,
                    sparePartDocuments: state.sparePart.sparePartDocuments.filter(document => document.id !== action.payload)
                }
            }
        case `${types.FETCH_DOCUMENT_METADATA_GROUPS}_FULFILLED`:
            const { data } = action.payload;
            return {
                ...state,
                metadatas: data
            }
        default:
            return state;
    }
}

export default documentReducer;
