import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/lib/integration/react';
import store, { persistor } from './config/store';
import userManager from './config/userManager';
import { OidcProvider } from 'redux-oidc';

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store} >
            <OidcProvider store={store} userManager={userManager}>
                <PersistGate persistor={persistor}>
                    <App />
                </PersistGate>
            </OidcProvider>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root')
);

// Attach store to window if running inside of cypress
if (window.Cypress) {
    window.store = store
}
  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
