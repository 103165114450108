import React from 'react';
import Dialog from 'react-bootstrap-dialog';
import i18n from '../translations/i18n';

type DialogContent = {
    title: React.ReactNode;
    body: React.ReactNode;
    type?: string;
    params?: any;
}

interface Props {
    dialogContent: DialogContent;
    callBack: (params) => void;
    cancelDialog: () => void;
    onHide?: (dialog) => void;
}

interface State {
    dialog: Dialog;
}

class ConfirmDialogComponent extends React.Component<Props, State > {
    constructor(props) {
        super(props);

        this.state = {
            dialog: null
        }
    }

    componentDidUpdate() {
        const { dialogContent, callBack, cancelDialog, onHide = null } = this.props;

        if (this.state.dialog && dialogContent) {
            Dialog.setOptions({
                defaultOkLabel: i18n.t('YES').toUpperCase(),
                defaultCancelLabel: i18n.t('CANCEL').toUpperCase(),
                primaryClassName: dialogContent.type === 'delete' ? 'btn-danger' : 'btn-primary',
                defaultButtonClassName: 'cancel-btn-color'
            });

            this.state.dialog.show({
                title: dialogContent.title,
                body: dialogContent.body,
                actions: [
                    Dialog.CancelAction(() => cancelDialog()),
                    Dialog.OKAction(() => callBack(dialogContent.params))
                ],
                onHide
            })
        }
    }

    setDialogRef = ref => {
        this.setState({ dialog: ref})
    }

    render() {
        return (
            <Dialog ref={this.setDialogRef} />
        )
    }
}

export default ConfirmDialogComponent;