import React, { useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Loader } from '../../components/Loader';
import i18n from '../../translations/i18n';
import { useSelector, useDispatch } from 'react-redux';
import useWarehouses from '../../hooks/useWarehouses';
import useSparePartLinks from '../../hooks/useSparePartLinks';
import { useHistory, useLocation } from 'react-router';
import NavigationBar from '../navigation/';
import PaginationContainer from '../../components/PaginationContainer';
import WarehouseSpareParts from './components/WarehouseSpareParts';
import WarehouseSparePartTable from './components/WarehouseSparePartTable';
import WarehouseMenu from './components/WarehouseMenu';
import { setPageNumberAction, selectCurrentWarehouseAction } from './actions';
import settingsAPI from '../../config/settingsAPI';
import { Toaster, sortArrayByProperty, SortArray, HandleError } from '../../components/HelperFunctions';
import { setQuickSearches } from '../../commonActions/actions';
import { countActiveWarehouseFilters } from './utils';
import noviAPI from 'api/noviAPI';
import { useState } from 'react'
import { useLocalStorage, warehouseKey } from 'hooks/useLocalStorage';

const moduleSettings = settingsAPI.moduleData.warehousesparepartlist;

type LocationState = {
    notificationMsg: string
}

type Values = { [name: string]: any; };

const Warehouse = () => {
    let history = useHistory();
    let location = useLocation<LocationState>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                Toaster({ msg: prevState.notificationMsg, type: 'success' });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }
        }
    }, [history, location.state]);

    const currentFilters = useSelector((state: State) => state.warehouse.currentFilters);
    const currentWarehouse = useSelector((state: State) => state.warehouse.currentWarehouse);
    const displaySettings = useSelector((state: State) => state.warehouse.settings);
    const { userId, machineGroupId } = useSelector((state: State) => state.settings);
    const activeWarehouseQuickSearch = useSelector((state: State) => state.navigation.activeQuickSearches.warehouse);
    const tableSettings = useSelector((state: State) =>
        state.settings.userSettings
            .filter(setting => setting.group === 'sparepartregistrygrid')
    );

    const [values, setValues] = useState<Values>({});

    // hooks
    const [sparePartLinks] = useSparePartLinks();
    const [warehouses] = useWarehouses();
    const { getStorageSetting } = useLocalStorage();

    const quickSearches : IQuickSearch[] = useSelector(state => state['settings'].sqlQuickSearches.warehouse) ?? [];
    const activeQuickSearch = useSelector(state => state['navigation'].activeQuickSearches?.warehouse);

    const setPageNumber = (value) => {
        dispatch(setPageNumberAction(value));
    }

    const setCurrentWarehouse = useCallback((value) => {
        dispatch(selectCurrentWarehouseAction(value));
    }, [dispatch]);

    // Auto select a warehouse if none is selected yet
    useEffect(() => {
        if (!currentWarehouse.id && warehouses.length > 0 && !activeWarehouseQuickSearch) {

            let warehouseToSet;

            // If a favourite has been saved, use it instead
            const savedFavourite = getStorageSetting(warehouseKey);

            if (savedFavourite) {
                warehouseToSet = savedFavourite;
            } 
            else {
                const wHouses = SortArray(warehouses.slice(0), 'name');
                warehouseToSet = {
                    id: wHouses[0].id,
                    label: wHouses[0].name
                };
            }

            setCurrentWarehouse(warehouseToSet);
        }
    }, [currentWarehouse, warehouses, setCurrentWarehouse]);

    useEffect(() => {
        noviAPI.quickSearches.fetchAllByGroup(machineGroupId, "warehousequicksearch")
            .then(({ data }) => {
                dispatch(setQuickSearches(data, "warehouse"));
            })
            .catch(err => {
                HandleError(err, "Fetch warehouse quicksearches");
                console.log(err);
            })
    }, []);

    const toggleSettingsMenuDialog = () => {
        setValues(prevState => ({
            ...prevState,
            menuDialogOpen: prevState.menuDialogOpen ? !prevState.menuDialogOpen : true
        }))
    }

    const toggleDialog = (spId = null) => {
        setValues(prevState => ({
            ...prevState,
            dialogOpen: {
                ...prevState.dialogOpen,
                [spId]: prevState.dialogOpen ? !prevState.dialogOpen[spId] : true
            }
        }))
    }


    const closeDialog = () => {
        setValues(prevState => ({ ...prevState, ['dialogOpen']: false }))
    }

    const closeSettingsMenuDialog = () => {
        setValues(prevState => ({ ...prevState, menuDialogOpen: false }))
    }

    const navigationProps = () => {
        return {
            currentView: {
                view: moduleSettings.name,
                title: null,
                location,
                history,
                filtersCount: countActiveWarehouseFilters(currentFilters),
                currentFilters: {
                    ...currentFilters,
                    quickSearch: currentWarehouse,
                    warehouse: true
                },
                noHierarchySelect: true,
                doubleSelect: true,
                quickSearch: activeQuickSearch,
                handleQuickSearch: setCurrentWarehouse,
                dialogOpen: values.menuDialogOpen,
                closeDialog: closeDialog
            },
            viewAction: "settings",
            popover: <WarehouseMenu
                closeDialog={closeSettingsMenuDialog}
                dialogOpen={values.menuDialogOpen}
                toggleDialog={toggleSettingsMenuDialog}
            />,
            quickSearchOptions: [
                {
                    label: i18n.t('WAREHOUSES'),
                    options: sortArrayByProperty(warehouses.map(whouse => ({ id: whouse.id, label: whouse.name })), 'label')
                },
                {
                    label: i18n.t('PIKAHAUT'),
                    options: sortArrayByProperty(quickSearches?.map((s) => Object.assign({}, s, { isSqlSearch: true, value: s.id })), "label"),
                }
            ],
            filters: true
        }
    }

    const warehousesEnabled = currentWarehouse.label === "*" && currentWarehouse.id === -1;

    const results = () => {
        const { pageNumber, pageSize } = displaySettings;
        const firstItemCount = (pageNumber - 1) * pageSize + 1;
        const lastItemCount = pageNumber * pageSize;
        const { totalResultCount } = sparePartLinks;
        const results = totalResultCount > 0
            ? `${firstItemCount} - ${lastItemCount <= totalResultCount ? lastItemCount : totalResultCount} / ${totalResultCount}`
            : '0';

        return (
            <span className="sub-header">
                {i18n.t('RESULT')}: {results}
            </span>
        );
    }

    return (
        <div>
            <NavigationBar {...navigationProps()} />
            <div className="work-card-container">
                <Container className="bottom-margin-100">
                    <div className="v-paddings-15">
                        {results()}
                    </div>
                    {sparePartLinks.results.length > 0 && <PaginationContainer
                        currentPage={displaySettings.pageNumber}
                        setCurrentPage={setPageNumber}
                        pageCount={sparePartLinks.totalPageCount}
                    />}
                    {'fulfilled' === sparePartLinks.status
                        ? displaySettings.listLayout
                            ? <WarehouseSpareParts
                                sparePartLinks={sparePartLinks.results}
                                warehousesEnabled={warehousesEnabled}
                                toggleDialog={toggleDialog}
                                dialogOpen={values.dialogOpen}
                                closeDialog={closeDialog}
                                />
                            : <WarehouseSparePartTable
                                tableSettings={tableSettings}
                                sparePartLinks={sparePartLinks.results}
                                warehousesEnabled={warehousesEnabled}
                                />
                        : <Loader status={sparePartLinks.status} />
                    }
                    <div style={{ marginBottom: 15 }} />
                    {sparePartLinks.results.length > 0 && <PaginationContainer
                        currentPage={displaySettings.pageNumber}
                        setCurrentPage={setPageNumber}
                        pageCount={sparePartLinks.totalPageCount}
                    />}
                </Container>
            </div>
        </div>
    );
}

export default Warehouse;
