import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import ToggleButtonGroupContainer from 'components/ToggleButtonGroupContainer';
import { toast } from 'react-toastify';
import { clearScanResult, openScanner } from 'components/scanners/actions';
import ConfirmDialogComponent from 'components/ConfirmDialogComponent';
import noviAPI from 'api/noviAPI';

type Props = {
    machineId: number;
    submit: boolean;
    onSubmit: Function;
}

const MachineLinkAdditionForm = (props: Props) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(1);
    const [formData, setFormData] = useState({
        spareparts: [],
        wildsparepart: '',
        amount: '',
        memo: ''
    });
    const [invalidField, setInvalidField] = useState<string>('');
    const tabs = {
        1: 'SPAREPART',
        2: 'WILD_SPAREPART'
    };
    const [dialog, setDialog] = useState({
        content: null,
        actions: { accept: null, cancel: null }
    });
    
    useEffect(() => {
        if (props.submit) {
            const type = tabs[activeTab].toLowerCase();
            if (!validateMaterial()) {
                toast.error(i18n.t('INVALID_FIELDS'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                props.onSubmit(null, null);
            } else {
                props.onSubmit(formData, type);
            }
        }
    }, [props.submit, props.onSubmit, activeTab, formData]);

    const validateMaterial = () => {
        let isValid = true;
        const type = tabs[activeTab].toLowerCase();

        if (type === 'wild_sparepart' && !formData.wildsparepart) {
            setInvalidField('wildsparepart');
            isValid = false;
        } else if (type === 'sparepart' && formData.spareparts.length === 0) {
            setInvalidField('spareparts');
            isValid = false;
        }

        return isValid;
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSelect = (value, { name }) => {
        setFormData(f => ({ ...f, [name]: value }));
        if (name === invalidField) {
            setInvalidField('');
        }
    }

    const closeDialog = useCallback(() => {
        setDialog(d => ({ ...d, content: null }));
    }, []);

    const acceptScan = useCallback((scanResult: ISparePartLite, scannerType: string) => {
        const sparePart = sparePartToOption(scanResult);
        // Add the scan result to the list of spare parts if the list doesn't already include the scanned spare part
        setFormData(f => {
            if (!f.spareparts.some(sp => sp.id === sparePart.id)) {
                return { ...f, spareparts: f.spareparts.concat([sparePart]) }
            }
            return f;
        })
        dispatch(clearScanResult(scannerType));
        closeDialog();
    }, [dispatch, closeDialog]);

    const handleScannerDialog = useCallback((scanResult: ISparePartLite, scannerType: string) => {
        let content = { title: '', body: '' };
        let actions = { accept: null, cancel: null };

        // Check if scan was succesful and the scan result equals a spare part from spare part options list
        if (scanResult) {
            content.title = i18n.t('CONFIRM');
            content.body = `${scanResult.code} / ${scanResult.name}`;
            actions.accept = () => acceptScan(scanResult, scannerType);
        } else {
            content.title = i18n.t('SPARE_PART_NOT_FOUND');
            content.body = `${i18n.t('TRY_AGAIN')}?`;
            actions.accept = () => {
                dispatch(clearScanResult(scannerType));
                dispatch(openScanner(scannerType));
                closeDialog();
            }
        }
        actions.cancel = () => {
            dispatch(clearScanResult(scannerType));
            closeDialog();
        };

        setDialog({ content, actions });
    }, [dispatch, acceptScan, closeDialog]);

    const qrScanResult = useSelector((state: State) => state.scanners.QR.result);
    useEffect(() => {
        if (qrScanResult) {
            const result = qrScanResult.split('Id=')[1];
            const id = Number.parseInt(result);
            noviAPI.spareParts.fetchLite(id)
                .then(({data}) => handleScannerDialog(data, 'QR'))
                .catch(() => handleScannerDialog(null, 'QR'))
        }
    }, [qrScanResult, handleScannerDialog]);

    const barcodeScanResult = useSelector((state: State) => state.scanners.barcode.result);
    useEffect(() => {
        if (barcodeScanResult) {
            noviAPI.spareParts.fetchByCodeWithSpecialRights(barcodeScanResult, 'editing')
                .then(({data}) => handleScannerDialog(data, 'barcode'))
                .catch(() => handleScannerDialog(null, 'barcode'))
        }
    }, [barcodeScanResult, handleScannerDialog]);

    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const getSparePartsByInput = useCallback(async (inputValue: string) => {
        let spareParts: ISparePartLite[] = [];
        try {
            const sparePartSearchParams = new URLSearchParams([
                ['machineGroupId', `${machineGroupId}`],
                ['searchString', inputValue]
            ])
            const { data } = await noviAPI.spareParts.searchLite(sparePartSearchParams);
            spareParts = data.results;
        } catch (error) {
            console.log(error);
        }
        return spareParts;
    }, []);

    const [debounce, setDebounce] = useState(null);
    useEffect(() => {
        if (debounce !== null) {
            const { cb, delay } = debounce;
            const timeoutID = setTimeout(cb, delay);
            return () => clearTimeout(timeoutID); 
        }
    }, [debounce]);
    
    const loadOptions = (inputValue: string, callback: (options) => void) => {
        if (inputValue.length >= 3) {
            setDebounce({
                cb: () => getSparePartsByInput(inputValue)
                    .then((spareParts) => callback(spareParts.map(sparePartToOption))),
                delay: 500
            })
        } else {
            callback([])
        }
    }

    const sparePartToOption = useCallback((sparePart: ISparePartLite): IdLabel => (
        { id: sparePart.id, label: `${sparePart.code} // ${sparePart.name}` }
    ), [])

    return (
        <Container>
            <ToggleButtonGroupContainer
                label={i18n.t('MATERIAL_TYPE')}
                value={activeTab}
                onChange={setActiveTab}
                options={Object.keys(tabs).map(i => ({ id: +i, name: tabs[i] }))}
            />
            <div className="form-table-container margin-top-15">
                <form>
                    {tabs[activeTab].toLowerCase() === 'sparepart'
                        ? <div className={invalidField === 'spareparts' ? 'invalid-field' : ''}>
                            <FormElement
                                name='spareparts'
                                type='scannable-async-search-multiselect'
                                label={i18n.t('SPAREPART')}
                                value={formData.spareparts}
                                options={loadOptions}
                                onChange={handleSelect}
                                extraFunctions={{ QR: true, BR: true }} 
                            />
                        </div>
                        : <div className={invalidField === 'wildsparepart' ? 'invalid-field' : ''}>
                            <FormElement
                                name='wildsparepart'
                                type='text'
                                label={i18n.t('WILD_SPAREPART')}
                                value={formData.wildsparepart}
                                onChange={handleChange} 
                            />
                        </div>
                    }
                    <FormElement
                        name="amount"
                        type="number"
                        label={i18n.t('AMOUNT')}
                        value={formData.amount}
                        onChange={handleChange} />
                    <FormElement
                        name="memo"
                        type="text"
                        label={i18n.t('MEMO')}
                        value={formData.memo}
                        onChange={handleChange} />
                </form>
            </div>
            <ConfirmDialogComponent
                dialogContent={dialog.content}
                callBack={dialog.actions.accept}
                cancelDialog={dialog.actions.cancel}
            />
        </Container>
    );
}

export default MachineLinkAdditionForm;