import React, { useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { HasRight } from '../components/HelperFunctions';
import { fetchUserRights } from '../commonActions/actions';
import authAPI from '../api/authAPI';
import userManager from './userManager';
import { Image } from 'react-bootstrap';
import { Loader } from '../components/Loader';
import NoviIcon from '../images/novi_logo_rgb.png';

const RestrictedRoute = ({ path, component, ready, viewRight = [], exact = false }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    let history = useHistory();
    const machineGroupId = useSelector(state => state['settings']?.machineGroupId);

    useEffect(() => {
        if (user && machineGroupId) {
            dispatch(fetchUserRights());
        }
    }, [machineGroupId]);

    const checkWarehouseRights = () => {
        const operation = location.state['warehouseOperation'];
        if (operation === 'take') return 'warehouseoperationtake';
        else if (operation === 'return') return 'warehouseoperationreturn';
        else if (operation === 'arrive') return 'warehouseoperationarrive';
        else if (operation === 'change') return 'warehouseoperationchange';
        else if (operation === 'transfer') return 'warehouseoperationtransfer';
        else return viewRight;
    }

    const token = authAPI.getAccessToken();

    const noviConfigs = useSelector((state: State) => state.settings.noviConfigs);
    const userRights = useSelector((state: State) => state.settings.userRights);
    
    const getFirstMatchingUserRight = (viewRight: string[]) => {
        let allowedRights = []
        
        // Gets every userRight keys/names that has value set true.
        userRights.forEach(right => {
            if (Object.keys(right).some(k => right[k])) {
                allowedRights.push(...Object.keys(right))
            }
        });
        
        // Try to find if user has any matching rights and return the first found.
        return allowedRights.find(i => viewRight.indexOf(i) >= 0) || ''
    }
    
    const hasViewRight = () => {
        if (viewRight.length === 0 || userRights.length === 0) return true;

        const settingId = location.state?.hasOwnProperty('cardType')
            ? location.state['cardType'] === 'newFaultNotice'
                ? 'faultnotice'
                : getFirstMatchingUserRight(viewRight)
            : location.state?.hasOwnProperty('warehouseOperation')
                ? checkWarehouseRights()
                : viewRight.length > 1
                    ? getFirstMatchingUserRight(viewRight)
                    : viewRight;

        if (location.pathname.includes('routeworkcard')) {
            if (!noviConfigs || (noviConfigs && noviConfigs.RouteMaintenancesEnabled !== 'True')) {
                history.replace('/dashboard');
            }
        }
        if (location.pathname.includes('workphase')) {
            if (!noviConfigs || (noviConfigs && noviConfigs.EnablePhases !== 'True')) {
                history.replace('/dashboard');
            }
        }
        if (location.pathname.includes('operatormaintenance')) {
            if (!noviConfigs || (noviConfigs && noviConfigs.OperatorMaintenances !== 'True')) {
                history.replace('/dashboard');
            }
        }
        if (location.pathname.includes('warehouse')) {
            if (!noviConfigs || (noviConfigs && noviConfigs.WarehousesEnabled !== 'True')) {
                history.replace('/dashboard');
            }
        }
        if (location.pathname.includes('hourcard')) {
            if (!noviConfigs || (noviConfigs && noviConfigs.HourCardsEnabled !== 'True')) {
                history.replace('/dashboard');
            }
        }
        if (location.pathname.includes('measurement')) {
            if (!noviConfigs || (noviConfigs && noviConfigs.Measurements !== 'True')) {
                history.replace('/dashboard');
            }
        }
        if (location.pathname.includes('counters')) {
            if (!noviConfigs || (noviConfigs && noviConfigs.RTM !== 'True')) {
                history.replace('/dashboard');
            }
        }

        if (!HasRight(settingId, userRights) && location.pathname !== '/dashboard') {
            history.replace('/dashboard');
        } else {
            return true;
        }

    }

    const user = useSelector((state: State) => state.oidc?.user || null);
    if (!user && window['runConfig'].authGatewayURL) {
        let location = window.location.pathname;
        if (window["runConfig"].baseLocation) {
            location = location.replace(window["runConfig"].baseLocation, '').replace('//', '/')
        }
        
        // Other way would be to include this in SSO redirect url but that required configuration on auth gw.
        // This should work just fine.
        window.localStorage.setItem('redirect-path', '');
        window.localStorage.setItem('redirect-path', location);
        
        userManager.signinRedirect();
        return <p>Redirecting...</p>
    } else if (!ready) {
        return <div className="novi-loading">
            <div className="novi-img">
                <Image src={NoviIcon} />
            </div>
            <Loader ready={ready} initialLoader={true} />
        </div>
    } else if (!window['runConfig'].authGatewayURL && !token && location.pathname !== '/') {
        const lastLocation = {
            pathname: '/',
            state: { location: location }
        }

        history.replace(lastLocation);
    } else if (!hasViewRight()) {
        return null;
    }

    return <Route path={path} exact={exact} component={component} />
}

export default RestrictedRoute;
