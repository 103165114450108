import React, { useMemo, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faCaretDown, faCaretUp, faPlusCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import PhaseComponent from './PhaseComponent';
import i18n from '../../../../translations/i18n';
import { useSelector } from 'react-redux';
import TextTruncate from 'react-text-truncate';
import { useHistory } from 'react-router-dom';
import ListTruncate from '../../../../components/ListTruncate';
import { restrictRenderingByParameters, toKeyFormat } from 'components/HelperFunctions';
import { SwipeableCard, ISwipeableListItem } from 'components/SwipeableCard';
import { UserRights } from 'constants/userRights';

const WorkcardBody = ({ cardData, showPhases, showAllPhases = {}, togglePhases }) => {
    let history = useHistory();
    const settings = useSelector((state: State) => state.settings);
    const viewSettings = useSelector((state: State) => state.workcards.viewSettings);

    const bodyRef = useRef<ISwipeableListItem>();
    const workPhaseRefs = useMemo(() => {
        const refs = {};
        cardData?.phases?.forEach((item) => {
            refs[item.id] = React.createRef<ISwipeableListItem>();
        });
        return refs;
	}, [cardData]);

    const handleWorkCardClick = () => {

        if (bodyRef.current?.isSwiping?.()) {
            bodyRef.current.playReturnAnimation();
            return;
        }

        let path = '';
        let search = '';
        let locationState = { scrollPosition: window.pageYOffset };

        if (cardData.isRouteMaintenanceWorkCard) {
            path = `/routeworkcard/${cardData.id}`;
        }
        else if (cardData.isTimedOperatorMaintenanceWorkCard) {
            path = `/operatormaintenance/${cardData.operatorMaintenanceId}`;
            search = `?workCardId=${cardData.id}`;
        }
        else {
            path = `/workcard/${cardData.id}`;
        }

        history.push({
            pathname: path,
            search: search,
            state: locationState
        });
    }

    const handleWorkPhaseClick = (path, wcCode, swipeRef) => {

        if (swipeRef.current?.isSwiping()) {
            swipeRef.current.playReturnAnimation();
            return;
        }

        let locationState = {
            scrollPosition: window.pageYOffset,
            viewProperty: wcCode
        };

        history.push({
            pathname: path,
            state: locationState
        });
    }

    const getWorkerCollection = (cardData) => {
        if (!cardData.isRouteMaintenanceWorkCard && cardData['phases'] && cardData['phases'].length > 0) {
            const result = cardData.phases.reduce((result, phase) => {
                if (phase.workers) {
                    result.push(phase.workers);
                }
                return result
            }, []).concat(cardData.workers).flat(2);
            return result;
        } else {
            return cardData.workers;
        }
    }
    const getWorkerGroupsCollection = (cardData) => {
        if (!cardData.isRouteMaintenanceWorkCard && cardData['phases'] && cardData['phases'].length > 0) {
            const result = cardData.phases.reduce((result, phase) => {
                if (phase.workerGroups) {
                    result.push(phase.workerGroups);
                }
                return result
            }, []).concat(cardData.workerGroups).flat(2);
            return result;
        } else {
            return cardData.workerGroups;
        }
    }


    const workersCollection = getWorkerCollection(cardData);
    const workerGroups = [...Array.from(new Set(getWorkerGroupsCollection(cardData)))];
    
    var duplicates = {};
    const workers = workersCollection?.filter(function (item) {
        return duplicates.hasOwnProperty(item) ? false : (duplicates[item] = true);
    }); 

    // Get machines list either from machine objects or use simplified machineLabels
    const machineList = cardData.isRouteMaintenanceWorkCard ? cardData.routeMachines.map(m => (m.code ? `${m.code} / ${m.name}` : m)) : cardData.machineLabel ? [cardData.machineLabel] : [];

    var wcFieldList = [...viewSettings.workcard];
    var wcReportFieldList = [...viewSettings.workcardreport];

    wcFieldList.sort((a, b) => a['tabOrder'] - b['tabOrder']);
    wcReportFieldList.sort((a, b) => a['tabOrder'] - b['tabOrder']);

    var wcViewSettings = wcFieldList.concat(wcReportFieldList);

    const enabledPhases = settings['noviConfigs'] && settings['noviConfigs'].EnablePhases === 'True';

    const leftActions = [], rightActions = [];

    const workcardId = cardData?.id;

    // Add swipeable actions
    if (restrictRenderingByParameters(UserRights.RestrictedMaterialEdit, settings.userRights)) {

        leftActions.push({
            callback: () => { 
                history.push(`/workcard/${workcardId}/material/new/`);
            },
            icon: faPlusCircle,
            label: i18n.t("MATERIAL")
        })
    }
    if (restrictRenderingByParameters('workcardedit', settings.userRights)) {

        rightActions.push({
            callback: () => { 
                history.push(`/workcard/${workcardId}/edit/`);
            },
            icon: faPen,
            label: i18n.t("EDIT")
        });

        leftActions.push({ 
            callback: () => { 
                history.push(`/workcard/${workcardId}/`, { showFileInput: true });
            },
            icon: faPlusCircle,
            label: i18n.t("DOCUMENT")
        });

        if (enabledPhases) {
            leftActions.push({
                callback: () => { 
                    history.push(`/workcard/${workcardId}/workphase/new/`);
                },
                icon: faPlusCircle,
            label: i18n.t("WORKPHASE")
            });
        }
    }

    const mainContent = (
        <div style={{ width: "100%" }}>
            {wcViewSettings.map((fieldItem, i) => (
                <div key={i}>
                    {fieldItem.field === 'machine' && <Row className="margin-bottom-5">
                        <Col xs={4} className="sub-header-light break-word">
                            <TextTruncate line={2}
                                truncateText="…"
                                text={i18n.t('MACHINE')}
                            />
                        </Col>
                        <Col xs={8} className="break-word">
                            {!cardData.isTimedOperatorMaintenanceWorkCard
                                ? <ListTruncate>
                                    {machineList.map(m => m)}
                                </ListTruncate>
                                : cardData.operatorMaintenance.machineLabel
                            }
                        </Col>
                    </Row>}
                    {fieldItem.field === 'faultdescription' && <Row className="margin-bottom-5">
                            <Col xs={4} className="sub-header-light break-word">
                                <TextTruncate line={1}
                                    truncateText="…"
                                    text={i18n.t('FAULT_DESCRIPTION')}
                                />
                            </Col>
                            <Col xs={8} className="break-word">
                                <TextTruncate line={3}
                                    truncateText="…"
                                    text={!cardData.isTimedOperatorMaintenanceWorkCard ? cardData.faultDescription : cardData.operatorMaintenance.name}
                                />
                            </Col>
                    </Row>}
                    {fieldItem.field === 'workstatus' && !cardData.isRouteMaintenanceWorkCard && <Row className="margin-bottom-5">
                            <Col xs={4} className="sub-header-light break-word">
                                <TextTruncate line={1}
                                    truncateText="…"
                                    text={i18n.t('WORK_STATUS')}
                                />
                        </Col>
                        {<Col xs={8}>{i18n.t(cardData.statusLabel)}</Col>}
                    </Row>}
                    {fieldItem.field === 'worktype' && <Row className="margin-bottom-5">
                            <Col xs={4} className="sub-header-light break-word">
                                <TextTruncate line={1}
                                    truncateText="…"
                                    text={i18n.t('WORK_TYPE')}
                                />
                            </Col>
                            <Col xs={8}>{toKeyFormat(cardData.workTypeLabel)}</Col>
                    </Row>}
                    {fieldItem.field === 'procedure' && <Row className="margin-bottom-5">
                            <Col xs={4} className="sub-header-light break-word">
                                <TextTruncate line={1}
                                    truncateText="…"
                                    text={i18n.t('PROCEDURES')}
                                />
                            </Col>
                            {<Col xs={8} className="break-word">
                                <TextTruncate line={3}
                                    truncateText="…"
                                    text={cardData.procedure}
                                />
                                </Col>}
                    </Row>}
                </div>
            ))}
        </div>
    );

    const { isRouteMaintenanceWorkCard, isTimedOperatorMaintenanceWorkCard } = cardData;

    return (
        <div>
            <div 
                onClick={event => {
                    event.stopPropagation();
                    handleWorkCardClick();
            }}>
                {wcViewSettings && <SwipeableCard 
                    mainComponent={mainContent}
                    leftActions={leftActions}
                    rightActions={rightActions}
                    itemRef={bodyRef}
                    customStyle={null}
                    config={{
                        blockSwipe: isRouteMaintenanceWorkCard || isTimedOperatorMaintenanceWorkCard
                    }}
                />}
                <hr />
                {wcViewSettings.map((fieldItem, i) => (
                <div key={i}>
                    {fieldItem.field === 'worker' && <div className="margin-bottom-5">
                        <Col xs={12}>
                            <div className="sub-header-light flex">
                                <FontAwesomeIcon icon={faUser} />
                                {workers?.length > 0
                                    ? <span className="left-margin-5">
                                        {workers.map(worker => {
                                            if (workers.indexOf(worker) < 3) {

                                                let workerName = worker;
                                                if (typeof worker === 'object' && worker?.name) {
                                                    workerName = worker.name;
                                                }

                                                return workerName + (workers.indexOf(worker) < workers.length - 1 ? ', ' : ' ');
                                            }
                                        })}
                                        {workers.length > 3 && <span>
                                            {i18n.t('AND')} {workers.length - 3} {i18n.t('OTHER_WORKERS')}
                                        </span>}
                                    </span>
                                    : <span className="left-margin-5 cursive">{i18n.t('NO_WORKERS')}</span>}
                            </div>
                        </Col>
                    </div>}
                    {fieldItem.field === 'workergroup' &&<div className="margin-bottom-5">
                        <Col xs={12}>
                            <div className="sub-header-light flex">
                                <FontAwesomeIcon icon={faUsers} />
                                {workerGroups?.length > 0
                                    ? <span className="left-margin-5">
                                        {workerGroups.map((workerGroup: any) => {
                                            if (workerGroups.indexOf(workerGroup) < 3) {

                                                let groupName = workerGroup;
                                                if (typeof workerGroup === 'object' && workerGroup?.name) {
                                                    groupName = workerGroup.name;
                                                }

                                                return groupName + (workerGroups.indexOf(workerGroup) < workerGroups.length - 1 ? ', ' : ' ');
                                            }
                                        })}
                                        {workerGroups.length > 3 && <span>
                                            {i18n.t('AND')} {workerGroups.length - 3} {i18n.t('OTHER_WORKER_GROUPS')}
                                        </span>}
                                    </span>
                                    : <span className="left-margin-5 cursive">{i18n.t('NO_WORKER_GROUPS')}</span>}
                            </div>
                        </Col>
                    </div>}
                </div>))}  
            </div>

            {showPhases && cardData.phases && viewSettings.workcardphase && 
                <div className="workphases-list" style={{ width: "100%" }}>
                    {cardData.phases
                        .sort((a, b) => a['rowNumber'] - b['rowNumber'])
                        .map((phase, key) => (
                            !showAllPhases[cardData.id] && key < 1
                                ? <div key={phase.id}
                                    onClick={() => handleWorkPhaseClick('/workcard/' + cardData.id + '/workphase/' + phase.id, cardData.code, workPhaseRefs[phase.id])}
                                >
                                    <PhaseComponent phase={phase} viewSettings={viewSettings.workcardphase} settings={settings} swipeRef={workPhaseRefs[phase.id]} workCardId={cardData.id} history={history} />
                                </div>
                                : showAllPhases[cardData.id] && <div key={phase.id}
                                    onClick={() => handleWorkPhaseClick('/workcard/' + cardData.id + '/workphase/' + phase.id, cardData.code, workPhaseRefs[phase.id])}
                                >
                                    <PhaseComponent phase={phase} viewSettings={viewSettings.workcardphase} settings={settings} swipeRef={workPhaseRefs[phase.id]} workCardId={cardData.id} history={history} />
                                </div>
                            ))}
                    {cardData.phases.length > 1 && <div className="phase-toggle-section" onClick={() => togglePhases(cardData.id)}>
                        <FontAwesomeIcon icon={!showAllPhases[cardData.id] ? faCaretDown : faCaretUp} /> {i18n.t('SHOW_ALL_PHASES')}
                    </div>}
                </div>
            }
        </div>
                
    );
}

export default WorkcardBody;
