import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import '../../../styles/global.scss';
import './../styles/navigation.scss';
import ActionMenu from './ActionMenu';
import BackButton from '../../../components/buttons/BackButton';

interface Props {
    sceneData: any;
}

class NavFooter extends React.Component<Props> {
    render() {
        const {
            titles,
            navFooter
        } = this.props.sceneData;
        const loginView = this.props.sceneData.location.pathname === '/';

        return (
            <div className="nav-actions">
                <Container>
                    {loginView
                        ? <p style={{ textAlign: 'center', color: '#555' }}>
                            {localStorage.getItem('frontendversion')} © {new Date().getFullYear()}
                        </p>
                        : <Row>
                            {titles.label !== 'dashboard' &&
                            <Col xs={!navFooter.viewAction ? 6 : 4} className="nav-action">
                                {navFooter?.hasChanged && navFooter.backAction?.params?.mainEditView === true
                                    ? <BackButton backAction={navFooter.backAction} handleClick={navFooter.showPopup} />
                                    : <BackButton backAction={navFooter.backAction} />
                                }
                            </Col>
                            }
                            <Col xs={!navFooter.viewAction ? 5 : titles.label !== 'dashboard' ? 3 : 5} className="nav-action">
                                <div id="navBarButton" onClick={navFooter.toggleSideBar} className="action">
                                    <FontAwesomeIcon icon={faBars} size="2x" className="novi-default-icon-color" />
                                </div>
                            </Col>
                            {navFooter.viewAction && 
                            <Col xs={titles.label !== 'dashboard' ? 5 : 7} className="nav-action">
                                <ActionMenu footerData={navFooter} />
                            </Col>
                            }
                        </Row>
                    }
                </Container>
            </div>
        );
    }
}

export default NavFooter;