import React from 'react';
import { Button } from 'react-bootstrap';

const LargeYellowButton = (props: IProps) => {
    return (
        <Button
            variant="warning"
            className="action action-button button-shadow"
            size="lg"
            onClick={props.handleClick}
            disabled={props.disabled}
        >
            {props.children}
        </Button>
    );
}

interface IProps {
    handleClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    rowNumber?: number; /** Gives a position related to other items in <SortElemenetsByRowNumber></SortElemenetsByRowNumber> */
}

export default LargeYellowButton;