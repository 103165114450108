import React from 'react';
import { Button } from 'react-bootstrap';
import '../../../styles/global.scss';
import './../styles/dashboardCustomizer.scss';
import i18n from '../../../translations/i18n';
import FormElement from '../../../scenes/work-schedule/work-card/components/FormElement';
import { HandleSelectOption } from '../../../components/HelperFunctions';
import settingsAPI from '../../../config/settingsAPI';
import noviAPI from '../../../api/noviAPI';
import { ItemTypes } from '../../../constants/dashboard/itemTypes';
import { ItemPropertyTypes, ItemPropertyTypeTranslations } from '../../../constants/dashboard/itemPropTypes';
import DialogModal from 'components/dialogs/DialogModal';
import DialogHeader from 'components/dialogs/DialogHeader';
import DialogBody from 'components/dialogs/DialogBody';
import DialogFooter from 'components/dialogs/DialogFooter';
import { TypeGroups } from 'constants/dashboard/typeGroups';
import { QuickSearches } from 'constants/quickSearches/quickSearches';
import { ComponentTypes } from 'constants/dashboard/componentTypes';
import { searchTypeSearchesTypeParamMap } from 'utils';

type ItemContent = {
    compId: number;
    itemValue: string;
    itemTypeId: number;
    itemType: IDashboardItemType;
    itemName: string;
    item: ItemMenuDialogItem;
    itemPropertyType: string;
    itemRowId: number;
    componentTypeId: number;
    isNewComponent: boolean;
}

export type ContentChoices = {
    id: number;
    itemName: string;
    itemType: IDashboardItemType;
    item: ItemMenuDialogItem;
    itemRowId: number;
    itemPropertyType: IItemPropertyType["label"];
}

export type ItemMenuDialogItem = (IQuickSearch & { value? }) | (IDashboardTypeOption & { value? }) | (ISearch & { value? }) | { id?; value?; label; };

type ItemMenuDialogItemType = IDashboardItemType & { label?: string };

type SavedSearches = {
    workCardSearches: ISearch[];
    machineSearches: ISearch[];
    sparePartSearches: ISearch[];
}

interface IProps {
    dashboard: IDashboard;
    itemContent: ItemContent;
    dashBoardItemTypes: IDashboardItemType[];
    dashboardTypeOptions: IDashboardTypeOption[];
    onHide: () => void;
    applyContentChoices: (content: ContentChoices) => void;
    removeComponent: (compId: number) => void;
    showDialog: boolean;
    quickSearchOptions: IQuickSearch[];
    machineGroupId: number;
    userId: number;
    noviConfigs: INoviConfigs;
    dashboardEnvironmentEditAllowed: boolean;
    fieldSearchOptions: any;
}

interface IState {
    itemName: string;
    itemType: ItemMenuDialogItemType;
    item: ItemMenuDialogItem;
    itemRowId: number;
    itemPropertyType: IItemPropertyType;
    savedSearches: SavedSearches;
    itemPropertyTypes: IItemPropertyType[];
}

class ItemMenuDialog extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        const { itemContent, dashBoardItemTypes, noviConfigs } = this.props;
        const warehousesEnabled = noviConfigs.WarehousesEnabled === 'True';

        let itemPropTypes = [{
            id: ItemPropertyTypes.workcard,
            label: i18n.t(ItemPropertyTypeTranslations[ItemPropertyTypes.workcard])
        }];
        if (itemContent.componentTypeId === ComponentTypes.Button) {
            itemPropTypes.push({
                id: ItemPropertyTypes.machine,
                label: i18n.t(ItemPropertyTypeTranslations[ItemPropertyTypes.machine])
            });
        }
        if (warehousesEnabled) {
            itemPropTypes.push({
                id: ItemPropertyTypes.sparepart,
                label: i18n.t(ItemPropertyTypeTranslations[ItemPropertyTypes.sparepart])
            });
        }
        if (noviConfigs?.OperatorMaintenances?.toLowerCase() === 'true' && itemContent.componentTypeId === ComponentTypes.Button) {
            itemPropTypes.push({ 
                id: ItemPropertyTypes.operatormaintenance,
                label: i18n.t(ItemPropertyTypeTranslations[ItemPropertyTypes.operatormaintenance])
            });
        }

        const itemTypeValue = itemContent.itemType?.id
            ? itemContent.itemType
            : itemContent.itemTypeId
                ? dashBoardItemTypes.find(itemType => itemType.id === itemContent.itemTypeId)
                : null;

        let item: ItemMenuDialogItem = null;

        if (itemTypeValue?.typeGroup === TypeGroups.Link) {
            if (itemTypeValue.id === ItemTypes.Default) {
                item = settingsAPI.moduleData.worklist.defaultQuickSearchData?.options.find(opt => opt.value === parseInt(itemContent.itemValue));
            } else if (itemTypeValue.id === ItemTypes.WorkCardSavedSearch || itemTypeValue.id === ItemTypes.MachineSavedSearch) {
                this.getSavedSearchesByType(itemContent.itemPropertyType);
            } else {
                item = this.props.quickSearchOptions.find(opt => opt.id === parseInt(itemContent.itemValue)) || null;
            }
        } else if (itemTypeValue?.typeGroup === TypeGroups.Button) {
            item = this.props.dashboardTypeOptions?.find(opt => opt.id === parseInt(itemContent.itemValue)) || null;
        }
        else if (itemTypeValue?.typeGroup === TypeGroups.Search) {
            item = this.props.fieldSearchOptions?.find(opt => opt.id === itemContent.itemValue) || null;
        }

        const category = itemPropTypes.find(i => i.id === ItemPropertyTypes[itemContent.itemPropertyType]);

        this.state = {
            itemName: itemContent.itemName ? itemContent.itemName : '',
            itemType: itemTypeValue ? { ...itemTypeValue, label: itemTypeValue.typeName }: null,
            item: itemContent.item?.id ? itemContent.item : item,
            itemRowId: itemContent.itemRowId ? itemContent.itemRowId : 0,
            itemPropertyType: category ?? (itemPropTypes.length == 1 ? itemPropTypes[0] : null),
            savedSearches: {
                workCardSearches: [],
                machineSearches: [],
                sparePartSearches: []
            },
            itemPropertyTypes: itemPropTypes
        };
    }

    submitContentChoices = () => {
        const content: ContentChoices = {
            id: this.props.itemContent.compId,
            itemName: this.state.itemName,
            itemType: this.state.itemType,
            item: this.state.item,
            itemRowId: this.state.itemRowId,
            itemPropertyType: ItemPropertyTypes[this.state.itemPropertyType.id]
        }

        this.props.applyContentChoices(content);
        this.props.onHide();
    }

    removeItem = () => {
        this.props.onHide();
        this.props.removeComponent(this.props.itemContent.compId)
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        this.setState(state => ({
            ...state,
            [name]: value
        }))
    }

    handleSelect = (value, actionMeta) => {
        //TODO: unselect value from 'Haku tai toiminto' -field if 'Tyyppi' -field value is changed
        const key = actionMeta.name;
        const selectedValue = HandleSelectOption(key, value);
        if (key === 'itemPropertyType') {
            this.setState({
                itemPropertyType: selectedValue,
                itemType: null,
                item: null
            });
        } else if (key === 'itemType') {
            this.setState({
                item: (value?.id == ItemTypes.ExtendedSearch) ? selectedValue : null,
                itemType: selectedValue
            });
        } else {
            this.setState(state => ({
                ...state,
                [key]: selectedValue
            }));
        }
    }

    getSavedSearchesByType = (type: string) => {
        const { machineGroupId, userId, itemContent, dashboardEnvironmentEditAllowed } = this.props;
        const params = new URLSearchParams();

        params.append('PersonId', userId.toString());
        if (machineGroupId) { 
            params.append('MachineGroupId', machineGroupId.toString());
        }
        params.append('Type', type);
        if (dashboardEnvironmentEditAllowed) {
            params.append('IncludeMachineGroupSpecific', true.toString());
        }

        noviAPI.searches.fetchSearches({ params })
            .then(({ data }) => {
                const searches = data.sort((a, b) => a.name.localeCompare(b.name));
                if (searches?.length > 0 && this.state.savedSearches[type]?.length === 0) {
                    this.setState(state => ({
                        savedSearches: {
                            ...state.savedSearches,
                            [type]: searches
                        }
                    }))

                    const searchTypes = [ItemTypes.WorkCardSavedSearch, ItemTypes.MachineSavedSearch];
                    if (this.state.itemType !== null && searchTypes.includes(this.state.itemType.id) && itemContent.itemValue) {
                        const search = searches.find(search => search.id === parseInt(itemContent.itemValue, 10));
                        this.setState({ item: search })
                    }
                }
            })
    };

    getTypeSelect = (itemType: ItemMenuDialogItemType) => {
        const typeOptions = this.getTypeOptions();
        if (typeOptions.length > 0) {
            return (
                <FormElement
                    name="itemType"
                    type="select-choice"
                    label={i18n.t('DASHBOARD_COMPONENT_TYPE')}
                    value={itemType}
                    onChange={this.handleSelect}
                    options={typeOptions}
                />
            )
        } else {
            return (
                <div>
                    <label>
                        {i18n.t('DASHBOARD_COMPONENT_TYPE')}
                    </label>
                    <div className="cursive">
                        {i18n.t('NO_ITEM_TYPES_AVAILABLE')}
                    </div>
                </div>
            )
        }
    }

    getItemSelect = (item: ItemMenuDialogItem) => {
        const itemOptions = this.getItemOptions();
        if (itemOptions.length > 0) {
            return (
                <FormElement 
                    name="item"
                    type="select-choice"
                    label={i18n.t('DASHBOARD_COMPONENT_SEARCH_OR_ACTION')}
                    value={item}
                    onChange={this.handleSelect}
                    options={itemOptions}
                />
            )
        }
         else if (this.state.itemType?.id !== ItemTypes.ExtendedSearch) {
            return (
                <div>
                    <label>
                        {i18n.t('DASHBOARD_COMPONENT_SEARCH_OR_ACTION')}
                    </label>
                    <div className="cursive">{i18n.t('NO_SEARCHES_OR_ACTIONS_AVAILABLE')}</div>
                </div>
            )
        }
    }

    getTypeOptions = (): ItemMenuDialogItemType[] => {
        const { dashBoardItemTypes, itemContent, dashboard } = this.props;
        const { itemPropertyType } = this.state;

        const { componentTypeId: cTypeId } = itemContent;
        
        const { Default, WorkCardSQL, MachineSQL, WarehouseSQL, Scanner, WorkCardSavedSearch, MachineSavedSearch, ExtendedSearch, FieldSearch } = ItemTypes;

        const optionsPropertyTypeMap = {
            [ItemPropertyTypes.workcard]:            cTypeId === ComponentTypes.Search ? [   FieldSearch    ] : cTypeId === ComponentTypes.Button ? [Scanner] : [Default, WorkCardSQL, WorkCardSavedSearch],
            [ItemPropertyTypes.machine]:             cTypeId === ComponentTypes.Search ? [/*ExtendedSearch*/] : cTypeId === ComponentTypes.Button ? [Scanner] : [MachineSQL, MachineSavedSearch],
            [ItemPropertyTypes.sparepart]:           cTypeId === ComponentTypes.Search ? [  ExtendedSearch  ] : cTypeId === ComponentTypes.Button ? [Scanner] : [WarehouseSQL],
            [ItemPropertyTypes.operatormaintenance]: cTypeId === ComponentTypes.Search ? [/*ExtendedSearch*/] : cTypeId === ComponentTypes.Button ? [Scanner] : []
        }

        const searchType = ItemPropertyTypes[itemPropertyType.id];

        this.getSavedSearchesByType(searchTypeSearchesTypeParamMap[searchType]);

        const typesByPropertyType = optionsPropertyTypeMap[itemPropertyType.id];

        return dashBoardItemTypes
            .filter(itemOpt => typesByPropertyType.find(type => type === itemOpt.id))
            .map(typeOpt => ({ ...typeOpt, label: i18n.t(typeOpt.typeName) }));
    }

    getItemOptions = (): ItemMenuDialogItem[] | { label: string; options: ItemMenuDialogItem[] }[] => {
        const { dashboard, quickSearchOptions, dashboardTypeOptions, fieldSearchOptions } = this.props;
        const { itemType, itemPropertyType, savedSearches } = this.state;
        const isPersonGroupDashboard = dashboard.personGroupId != null && dashboard.personGroupId > 0;

        if (itemType?.id) {
            if (itemType.typeGroup === TypeGroups.Link) {
                if (itemType.id === ItemTypes.Default) {
                    return settingsAPI.moduleData.worklist.defaultQuickSearchData?.options.map(opt => ({ value: opt.value, label: i18n.t(opt.label) })) || [];
                } else if (itemType.id === ItemTypes.WorkCardSavedSearch) {
                    const options = savedSearches.workCardSearches
                        .map(opt => ({ id: opt.id, label: opt.name,  ...opt }))
                        .sort((a, b) => a.label.localeCompare(b.label));
                    const mgSpecific = { 
                        label: i18n.t('MACHINE_GROUP_SPECIFIC_SEARCHES'),
                        options: options.filter(opt => opt.personId === null)
                    }
                    if (dashboard.personId === null || isPersonGroupDashboard) {
                        return [mgSpecific];
                    }
                    const personal = { 
                        label: i18n.t('PERSONAL_SEARCHES'),
                        options: options.filter(opt => opt.personId !== null)
                    }
                    return [personal, mgSpecific];
                } else if (itemType.id === ItemTypes.WorkCardSQL || itemType.id === ItemTypes.MachineSQL || itemType.id === ItemTypes.WarehouseSQL) {
                    let quickSearchGroup = ''; 
                    if (itemType.id === ItemTypes.WorkCardSQL) {
                        quickSearchGroup = QuickSearches.WorkScheduleQuickSearch;
                    } else if (itemType.id === ItemTypes.MachineSQL) {
                        quickSearchGroup = QuickSearches.MachineRegistryQuickSearch;
                    } else if (itemType.id === ItemTypes.WarehouseSQL) {
                        quickSearchGroup = QuickSearches.WarehouseQuickSearch;
                    } else if (itemType.id === 999) {
                        quickSearchGroup = QuickSearches.PurchaseContractQuickSearch;
                    }
                    return quickSearchOptions
                        ?.filter(searchOpt => searchOpt.group === quickSearchGroup)
                        ?.map(opt => ({ id: opt.id, label: opt.label }))
                        ?.sort((a, b) => a.label.localeCompare(b.label)) || [];
                }
            } else if (itemType.typeGroup === TypeGroups.Button) {
                // Temporarely filtered out barcode reader since feature is not implemented yet
                return dashboardTypeOptions
                    ?.filter(typeOpt => typeOpt.propertyType === ItemPropertyTypes[itemPropertyType?.id] && typeOpt.id != 3)
                    .map(opt => ({ id: opt.id, label: i18n.t(opt.name) })) || [];
            }
            else if (itemType.typeGroup === TypeGroups.Search && itemType.id != ItemTypes.ExtendedSearch) {
                return fieldSearchOptions;
            }
        }
        return [];
    }

    isValidComponent(component) {
        const { itemName, itemType, item, itemPropertyType } = component;
        return !!(itemName !== "" && item && itemType && itemPropertyType);
    }

    render() {
        const { showDialog, onHide, itemContent: { isNewComponent } } = this.props;
        const { itemName, itemType, item, itemPropertyType, itemPropertyTypes } = this.state;

        const mainContent = (
            <React.Fragment>
                <FormElement 
                    name="itemName"
                    type="text"
                    label={i18n.t('DASHBOARD_COMPONENT_NAME')}
                    value={itemName}
                    onChange={this.handleInputChange}
                    options={[]}
                />
                <FormElement 
                    name="itemPropertyType"
                    type="select-choice"
                    label={i18n.t('DASHBOARD_COMPONENT_CATEGORY')}
                    value={itemPropertyType}
                    onChange={this.handleSelect}
                    options={itemPropertyTypes}
                />
                {itemPropertyType && <div>
                    {this.getTypeSelect(itemType)}
                    {itemType && this.getItemSelect(item)}
                </div>}
            </React.Fragment>
        )

        const footerContent = (
            <div className="filters-button-container">
                <Button
                    variant="primary"
                    className="action action-button button-shadow large"
                    size="lg"
                    onClick={this.submitContentChoices}
                    disabled={!this.isValidComponent(this.state)}
                >
                    <div>{i18n.t('OK')}</div>
                </Button>
                <hr />
                <Button
                    variant="danger"
                    className="action action-button button-shadow"
                    size="lg"
                    onClick={this.removeItem}
                >
                    <div>{i18n.t('REMOVE')}</div>
                </Button>
            </div>
        )

        return (
            <DialogModal
                showDialog={showDialog}
                closeDialog={() => {
                    const existingItemIsValid = this.isValidComponent(this.props.itemContent);

                    // Remove item if trying to close modal before item has been given values
                    if (isNewComponent && !existingItemIsValid) {
                        this.removeItem();
                    } else {
                        onHide();
                    }
                }}
                className="filtering-modal"
            >
                <DialogHeader>
                    {i18n.t("DASHBOARD_COMPONENT_EDIT")}
                </DialogHeader>
                <DialogBody>
                    {mainContent}
                </DialogBody>
                <DialogFooter>
                    {footerContent}
                </DialogFooter>
            </DialogModal>
        )
    }
}

export default ItemMenuDialog;
