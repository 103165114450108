import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import QrReader from 'modern-react-qr-reader'

interface IProps {
    readerState: boolean;
    onChange: any;
    scanQrData: any;
}

interface IState {
    qrReaderOpen: boolean;
}

class QrReaderComponent extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            qrReaderOpen: this.props.readerState
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.readerState !== this.props.readerState) {
            this.setState({ qrReaderOpen: this.props.readerState });
        }
    }

    openReader = value => {
        this.props.onChange(value);
    }

    handleScan = data => {
        if (data) {
            const id = data.split('Id=')[1];
            if (id) {
                this.props.scanQrData(id)
                this.props.onChange(false);
            } else {
                this.props.scanQrData(data)
                this.props.onChange(false);
            }
        }
    }

    handleError = err => {
        //err
    }

    render() {
        return (
            <span>
                <Button onClick={() => this.openReader(true)} className="action input-row-button novi-default-btn-color">
                    <div>
                        <FontAwesomeIcon icon={faQrcode} size="2x" />
                    </div>
                </Button>
                {this.state.qrReaderOpen && <div className="qr-wrapper">
                    <div className="qr-container">
                        <QrReader
                            delay={200}
                            onError={this.handleError}
                            onScan={this.handleScan}
                            facingMode={"environment"}
                            resolution={900}
                            constraints={{
                                facingMode: 'environment'
                            }}
                        />
                    </div>
                </div>}
            </span>
        );
    }
};

export default QrReaderComponent;