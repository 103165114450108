import React from 'react';
import SelectField from '../../../components/form/SelectField';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import { OptionGroup } from '../utils';

const HourCardFilters = (props: Props) => (
    <div>
        <label>{i18n.t('GROUP_FILTER')}</label>
        <SelectField
            name="group"
            value={{ value: props.values.group.value, label: i18n.t(props.values.group.label) }}
            options={props.options.groups.map(opt => ({ value: opt.value, label: i18n.t(opt.label)}))}
            onChange={props.handleSelect}
            noDefaultOption
            required
        />
        <SelectField
            name="person"
            value={props.values.person}
            options={props.values.group?.value ? (props.options.people[props.values.group.value] || []) : []}
            onChange={props.handleSelect}
        />
        <SelectField
            name="status"
            label={i18n.t('STATUS')}
            value={props.values.status}
            options={props.options.statuses}
            onChange={props.handleSelect}
        />
        <SelectField
            name="salaryCategory"
            label={i18n.t('SALARY_CATEGORY')}
            value={props.values.salaryCategory}
            options={props.options.salaryCategories}
            onChange={props.handleSelect}
        />
        <label>{i18n.t('TIME')}</label>
        <FormElement
            name="searchBegin"
            type="datetime"
            value={props.values.searchBegin}
            onChange={props.handleDateTime}
        />
        <FormElement
            name="searchEnd"
            type="datetime"
            value={props.values.searchEnd}
            onChange={props.handleDateTime}
        />
    </div>
);

type Props = {
    values: IHourCardFilters;
    options: Options;
    handleSelect: (selectedOption, actionMeta) => void;
    handleDateTime: (date, name) => void;
}

type Options = {
    groups: IOptionType[];
    people: OptionGroup;
    statuses: IOptionType[];
    salaryCategories: IOptionType[];
}

export default HourCardFilters;
