import React from 'react';
import { sortArrayByProperty } from '../../../components/HelperFunctions';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';

const SparePartFilters = ({
    values,
    optionGroups,
    onChange,
    handleSelect
}: IProps) => (
    <div>
        <FormElement
            name={'code'}
            type={'text'}
            label={i18n.t('CODE')}
            value={values.code}
            onChange={onChange}
        />
        <FormElement
            name={'productNumber'}
            type={'text'}
            label={i18n.t('PRODUCT_NUMBER')}
            value={values.productNumber}
            onChange={onChange}
        />
        <FormElement
            name={'shelfLocation'}
            type={'text'}
            label={i18n.t('SHELF_LOCATION')}
            value={values.shelfLocation}
            onChange={onChange}
        />
        <FormElement
            name={'orderNumber'}
            type={'text'}
            label={i18n.t('ORDER_NUMBER')}
            value={values.orderNumber}
            onChange={onChange}
        />
        <FormElement
            name={'alertLimit'}
            type={'min-max'}
            label={i18n.t('ALERT_LIMIT')}
            value={values.alertLimit}
            onChange={onChange}
        />
        <FormElement
            name={'price'}
            type={'min-max'}
            label={i18n.t('PRICE')}
            value={values.price}
            onChange={onChange}
        />
        <FormElement
            name={'amount'}
            type={'min-max'}
            label={i18n.t('AMOUNT')}
            value={values.amount}
            onChange={onChange}
        />
        <FormElement
            name={'memo'}
            type={'text'}
            label={i18n.t('MEMO')}
            value={values.memo}
            onChange={onChange}
        />
        <FormElement
            name={'name'}
            type={'text'}
            label={i18n.t('NAME')}
            value={values.name}
            onChange={onChange}
        />
        <FormElement
            name={'warrantyEnded'}
            type={'machinehalt'}
            label={i18n.t('WARRANTY_ENDED')}
            value={values.warrantyEnded}
            options={optionGroups.warrantyEnded.options}
            onChange={handleSelect}
        />
        {values.thirdParties.allTypes.map(type => {
            return <FormElement
                key={type}
                name={type}
                type={'worker'}
                label={i18n.t(optionGroups[type].label.toUpperCase())}
                value={values.thirdParties.byType[type]}
                options={sortArrayByProperty(optionGroups[type].options.slice(0), 'label')}
                onChange={handleSelect}
            />
        })}
        <FormElement
            name={'orderAmount'}
            type={'min-max'}
            label={i18n.t('ORDER_AMOUNT')}
            value={values.orderAmount}
            onChange={onChange}
        />
        <FormElement
            name={'criticality'}
            type={'text'}
            label={i18n.t('CRITICALITY')}
            value={values.criticality}
            onChange={onChange}
        />
        <FormElement
            name={'sparePartType'}
            type={'text'}
            label={i18n.t('SPARE_PART_TYPE')}
            value={values.sparePartType}
            onChange={onChange}
        />
        <FormElement
            name={'material'}
            type={'text'}
            label={i18n.t('MATERIAL')}
            value={values.material}
            onChange={onChange}
        />
        <FormElement
            name={'catalogueNumber'}
            type={'text'}
            label={i18n.t('SPAREPART_CATALOGUENUMBER')}
            value={values.catalogueNumber}
            onChange={onChange}
        />
        <FormElement
            name={'warehouseSparePartMemo'}
            type={'text'}
            label={i18n.t('WAREHOUSE_MEMO')}
            value={values.warehouseSparePartMemo}
            onChange={onChange}
        />
        {values.details.allGroups.map(group => {
            return <FormElement
                key={group}
                name={group}
                type={'worker'}
                label={i18n.t(optionGroups[group].label.toUpperCase())}
                value={values.details.byGroup[group]}
                options={optionGroups[group].options}
                onChange={handleSelect}
            />
        })}
    </div>
);

interface IProps {
    optionGroups: { [name: string]: OptionGroup; };
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (value, actionMeta) => void;
    values: Values;
}

type OptionGroup = {
    label: string;
    options: ISelectOption[];
}

type MinMax = {
    min: string;
    max: string;
}

type Values = {
    code: string;
    productNumber: string;
    shelfLocation: string;
    orderNumber: string;
    alertLimit: MinMax;
    price: MinMax;
    amount: MinMax;
    memo: string;
    name: string;
    warrantyEnded: ISelectOption;
    thirdParties: {
        byType: { [type: string]: ISelectOption[]; };
        allTypes: number[];
    };
    details: {
        byGroup: { [group: string]: ISelectOption[]; };
        allGroups: string[];
    };
    orderAmount: MinMax;
    criticality: string;
    sparePartType: string;
    material: string;
    catalogueNumber: string;
    warehouseSparePartMemo: string;
}

export default SparePartFilters;
