import * as types from '../../config/actionTypes';
import { HandleError } from '../../components/HelperFunctions';
import noviAPI from '../../api/noviAPI';

export const fetchOperatorMaintenances = (param) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_OPERATOR_MAINTENANCES}_PENDING`,
        });
        try {
            const machineId = param.machineType ? param.machineType : param;
            const { data } = await noviAPI.operatorMaintenances.fetchByMachineId(machineId);

            dispatch({
                type: `${types.FETCH_OPERATOR_MAINTENANCES}_FULFILLED`,
                payload: {
                    mId: machineId,
                    opMaintenances: data
                },
            })
        } catch (error) {
            HandleError(error, 'Fetch operator maintenances');

            dispatch({
                type: `${types.FETCH_OPERATOR_MAINTENANCES}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchMaintenanceMachines = () => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_MAINTENANCE_MACHINES}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            const { data } = await noviAPI.operatorMaintenances.fetchMachines(machineGroupId);

            dispatch({
                type: `${types.FETCH_MAINTENANCE_MACHINES}_FULFILLED`,
                payload: data,
            })
        } catch (error) {
            HandleError(error, 'Fetch maintenance machines');

            dispatch({
                type: `${types.FETCH_MAINTENANCE_MACHINES}_REJECTED`,
                payload: error
            })
        }
    }
);