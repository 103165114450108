import OperatorMaintenance from './OperatorMaintenance';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import {
    fetchOperatorMaintenance,
    fetchProcedureDataTypes,
    fetchPointImage,
    deleteOpmDocument,
    addOperatorMaintenanceDocument
} from './actions';
import {
    fetchViewSettings,
    fetchDocumentMetadatas
} from './../../commonActions/actions';
import i18n from '../../translations/i18n';
import {
    addWorkcard,
    fetchWorkcardOptions
} from '../work-schedule/work-card/actions';
import { 
    GetFormInputValueByField,
    GetOptionsDataByField,
    GetProperty,
    GetType,
    GetOnChangeType,
    GetDetail
} from '../../components/HelperFunctions';
import { fetchWorkCardDetails } from '../work-schedule/work-list/actions';
import { fetchMaintenanceMachines } from '../operator-maintenance-list/actions';
import { fetchCounters } from 'scenes/machines/actions';

const mapStateToProps = (state: State, ownProps) => {
    const { userId } = state.settings;
    const opMaintenance = {...state.maintenances.operatorMaintenance};
    const phaseWorkHoursEnabled = state.settings?.noviConfigs?.OperatorMaintenancePhaseEdition === 'True' ? true : false;
    const { AllowOneWorkerPerPhase } = state.settings && state.settings.noviConfigs;
    let opmMachine = opMaintenance ? opMaintenance.machine : null;

    const counters = state.machines.counters?.filter(c => c.machine?.id == opmMachine?.id);

    let proceduresdata = [];
    let tempOpProcedures = [];
    let tempOpm = {};
    let generalSettingsForm = {};
    const { workerOptions, ordererOptions } = state.workcards.options;
    const { statusTypes, urgencyTypes } = state.settings;

    let operatormaintenanceFields = state.maintenances.viewSettings['operatormaintenance'];

    let optionLists = { ...state.workcards.options, urgencyOptions: [] };
    optionLists.workerOptions = workerOptions;
    optionLists.ordererOptions = ordererOptions;
    optionLists.urgencyOptions = urgencyTypes?.map(urgency => ({ id: urgency.id, name: urgency.label, label: urgency.label }));

    if (opMaintenance?.procedures) {
        Object.keys(opMaintenance).forEach(key => {
            if (key !== 'procedures') {
                tempOpm[key.toLowerCase()] = opMaintenance[key] || null;
            }
        });
        opMaintenance.procedures.forEach(procedure => {
            let tempProcedure = {};
            Object.keys(procedure).forEach(key => {
                tempProcedure[key.toLowerCase()] = procedure[key];
            });
            tempOpProcedures.push(tempProcedure);
        });
    }

    const getOrderer = () => {
        const { userId } = state.settings;
        const orderers = optionLists.ordererOptions;

        const orderer = orderers.find(orderer => orderer.id === userId)

        return orderer ? orderer : null
    }

    const generateFormElementData = function (fields, column = '', item, dataPrototype, dataType) {
        if (!fields) return [];
        const fieldsData = [...fields];
        fieldsData.sort((a, b) => a['tabOrder'] - b['tabOrder']);
        fieldsData.forEach(fieldItem => {
            const { field, type, translationKey, values, required } = fieldItem;
            const fieldValue = field === 'faultdescription' ? 'description' : field

            if (field && type) {
                const fieldProp = GetProperty(fieldValue, type);
                const wcDetails = state.workcards.details ? [...state.workcards.details] : [];
                const dataPrototypeKey = fieldProp;

                item[fieldProp] = {
                    label: i18n.t(translationKey),
                    value: type === 'detail'
                        ? GetDetail(opMaintenance?.workDetails, field)
                        : GetFormInputValueByField(
                            dataPrototype[dataPrototypeKey],
                            type,
                            field,
                            ownProps.location,
                            state.settings.noviConfigs,
                            optionLists,
                            userId,
                            null,
                            null,
                            [],
                            false,
                            [],
                            fieldItem.defaultValue,
                            null
                        ),
                    options: GetOptionsDataByField(field, type, values, optionLists, wcDetails, dataType),
                    type: type === 'worker' && AllowOneWorkerPerPhase === 'True'
                        ? 'select'
                        : GetType(field, type),
                    onChangeType: GetOnChangeType(type, fieldProp),
                    column: column,
                    settingKey: field,
                    isDisabled: false,
                    required: required ?? false
                }
            }
        });
        return fieldsData;
    }

    if (tempOpProcedures?.length) {

        //TODO: refactor procedures form data generation to use generateFormElementData -function and it's helper functions
        tempOpProcedures.forEach(opProcedure => {
            let procedureData = {}

            Object.keys(opProcedure).forEach(key => {
                procedureData[key] = opProcedure[key];
            });

            procedureData['isDone'] = {
                label: i18n.t('IS_DONE'),
                value: opProcedure.datatype === 5 || opProcedure.datatype === 6
                    ? { id: -1, label: i18n.t('NO_SELECTION') }
                    : opProcedure.isdone,
                options: opProcedure.datatype === 5 || opProcedure.datatype === 6
                    ? [{ id: 0, label: i18n.t('NO') }, { id: 1, label: i18n.t('YES') }]
                    : [],
                type: opProcedure.datatype,
                required: false
            }

            proceduresdata.push(procedureData);
        });
       
    }

    const formFieldSets = {
        operatormaintenance: operatormaintenanceFields
            ? generateFormElementData(operatormaintenanceFields, '', generalSettingsForm, tempOpm, 'operatormaintenance')
            : null,
    }

    return {
        procedures: (opMaintenance && opMaintenance.procedures) || null,
        proceduresFormData: proceduresdata,
        opmForm: generalSettingsForm,
        opmMachine: opMaintenance?.machine,
        opmMachineId: opmMachine?.id,
        opMaintenance,
        settings: state.settings,
        loadStatus: state.maintenances.loadStatus,
        procedureFileData: state.maintenances.procedureFileData,
        formFieldSets: formFieldSets,
        ordererOptions: state.workcards.options ? state.workcards.options.ordererOptions : [],
        orderer: getOrderer(),
        phaseWorkHoursEnabled,
        documentMetadatas: state.documents.metadatas,
        counters
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchOperatorMaintenance,
    fetchViewSettings,
    fetchProcedureDataTypes,
    addWorkcard,
    fetchPointImage,
    fetchWorkcardOptions,
    addOperatorMaintenanceDocument,
    deleteOpmDocument,
    fetchWorkCardDetails,
    fetchMaintenanceMachines,
    fetchDocumentMetadatas,
    fetchCounters
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type OperatorMaintenancePropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OperatorMaintenance);
