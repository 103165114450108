import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import FormElement from '../scenes/work-schedule/work-card/components/FormElement';
import i18n from '../translations/i18n';
import noviAPI from 'api/noviAPI';
import { Loader } from './Loader';
import { GetDetail, HandleError, isNullOrWhitespace } from './HelperFunctions';
import { toast } from 'react-toastify';
import moment from 'moment';
import SubPermitForm from './SubPermitForm';
import '../styles/global.scss';

type Props = {
    submit: boolean;
    workCardId: number;
    onSubmit: (permits, type) => void;
    subview: boolean;
    toggleSubview: (subview: boolean) => void;
    setHasChanged: (changed: boolean) => void;
    permitId: string;
}

const WorkPermitForm = (props: Props) => {
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const user = useSelector((state: State) => state.settings.userDetails);
    const [status, setStatus] = useState('pending');
    const [isFetched, setFetch] = useState(false);
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState();
    const [permitName, setPermitName] = useState();
    const [workCard, setWorkCard] = useState<IWorkCard>(null);
    const [subpermit, setSubpermit] = useState();

    const [invalidFields, setInvalidFields] = useState<string[]>([]);

    const [permits, setPermit] = useState([]);

    useEffect(() => {  
        if (!props.permitId) {
            noviAPI.workPermits.fetchForms(machineGroupId)
                .then(({ data }) => {
                const forms = data
                    .filter(option => option.isActive === true)
                    .map(option => ({ id: option.id, label: option.name.toUpperCase(), name: option.name }));
                   setForms(forms);
                })
                .catch(error => {
                    console.log("[ ERROR ]", error);
                    HandleError(error, "Fetch work permit types");
                    setStatus('error');
                })
        }  
    }, []);

    useEffect(() => {
        if (props.permitId && isFetched === false) {
            noviAPI.workPermits.fetchUpdate(parseInt(props.permitId, 10))
                .then(({ data }) => {
                    setStatus('fulfilled');
                    if (workCard === null){
                        noviAPI.workCards.fetchComplete(props.workCardId)
                            .then(({ data }) => {
                                setWorkCard(data);
                    })}
                    setPermit(data);
                    setFetch(true);
                })
                .catch(error => {
                    const errorCode = error?.response?.status;
                    console.log(errorCode);
                    let errorText = ""
                    if (errorCode === 422) {
                        errorText = "Selected work permit's format unsupported on mobile, use desktop";
                    }
                    else {
                        errorText = "Fetch work permit fields";
                    }
                    console.log("[ ERROR ]", error);
                    HandleError(error, errorText);
                    setStatus('error');
                })
        }  
    }, []);

    const handleSubpermitSubmit = (data) => {
        setPermit(data);
        props.toggleSubview(false);
    }
    
    useEffect(() => {
        if (props.submit) {
            if (props.subview){props.onSubmit(null, null);}
            else if (!validatePermit() || (permitName === null && !props.permitId)) {
                toast.error(i18n.t('INVALID_FIELDS'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                props.onSubmit(null, null);
            } else {
                props.onSubmit(permits, permitName);
            }
        }
    }, [props.submit, props.onSubmit]);

    const validatePermit = () => {
        let isValid = true;
        let array = [];
        
        permits.forEach(( permit ) => {

            let validField = true;
            // check for required fields that are empty (don't include separator and label fields)
            if (isNullOrWhitespace(permit.value) && permit.required === true && permit.field !== "" && permit.type !== "label2" && permit.type !== "label"){
                if (permit.parentWorkPermitFieldId !== null){
                    const parentId = permit.parentWorkPermitFieldId;
                    validField = !permits.some(permit => (permit.id === parentId && permit.value === true))
                }
                else {
                    validField = false;
                }
            }

            // separate check for required multidetail worker/detail selections, which can't be empty
            else if ((permit.typeOption === 'multi' || permit.type === 'worker') && permit.required === true && permit.value.length === 0 ) {
                validField = false;
            }

            // seperate check for required checkboxes, which can't be left unchecked
            else if (permit.typeOption === "checkbox" && permit.required === true && permit.value === false ){
                validField = false;
            }

            // seperate check for detail fields, if "no selection" is used in required field
            else if (permit.type === "detail" && permit.required === true && permit.value.id === -1 ){
                validField = false;
            }

            if (!validField){
                array.push(permit.field);
                isValid = false;
            }
        });

        setInvalidFields(array);
        return isValid;
    }

    const handleChange = e => {
        const { name, value } = e.target;

        setPermit(permits.map(permit =>
            permit.field === name && isNullOrWhitespace(permit.parentWorkPermitFieldId)
            ? {...permit, value : value}
            : permit))
        setInvalidFields(i => i.filter(str => str !== name));
        props.setHasChanged(true);
    }

    const handleSelect = (value, { name }) => {
        if (name === "permitforms"){
            noviAPI.workPermits.fetchOnlyFields(parseInt(value.id, 10))
                .then(({ data }) => {
                    setStatus('fulfilled');
                    setPermit(data);
                    setFetch(true);
                    setSelectedForm(value);
                    setPermitName(value.label);
                    if (workCard === null){
                        noviAPI.workCards.fetchComplete(props.workCardId)
                            .then(({ data }) => {
                                setWorkCard(data);
                    })}
                })
                .catch(error => {
                    console.log("[ ERROR ]", error);
                    HandleError(error, "Fetch work permit fields");
                    setStatus('error');
                })
        }
        else {
            setPermit(permits.map(permit =>
                permit.field === name && isNullOrWhitespace(permit.parentWorkPermitFieldId)
                ? {...permit, value : value}
                : permit))
            setInvalidFields(i => i.filter(str => str !== name));
            props.setHasChanged(true);
        }
    }

    const handleCheckbox = (value, name ) => {
        if (value === null){
            value = false;
        }
        else {
            value = !value;
        }
        setPermit(permits.map(permit =>
            permit.field === name && isNullOrWhitespace(permit.parentWorkPermitFieldId)
            ? {...permit, value : value}
            : permit))
        props.setHasChanged(true);
    }

    const handleDatetime = (e, name) => {
        let datetime = null;
        if (moment.isMoment(e)) {
            datetime = e.format(moment.HTML5_FMT.DATETIME_LOCAL);
        }
        else if (!isNullOrWhitespace(e)) {
            datetime = e.target.value;
        }

        setPermit(permits.map(permit =>
            permit.field === name && isNullOrWhitespace(permit.parentWorkPermitFieldId)
            ? {...permit, value : datetime}
            : permit))
        setInvalidFields(i => i.filter(str => str !== name));
        props.setHasChanged(true);
    }

    const handleDate = (e, name) => {
        let date = null;
        if (moment.isMoment(e)) {
            date = e.format(moment.HTML5_FMT.DATE);
        }
        else if (!isNullOrWhitespace(e)) {
            date = e.target.value;
        }

        setPermit(permits.map(permit =>
            permit.field === name && isNullOrWhitespace(permit.parentWorkPermitFieldId)
            ? {...permit, value : date}
            : permit))
        setInvalidFields(i => i.filter(str => str !== name));
        props.setHasChanged(true);
    }

    const handleSubpermit = (item) => {
        setSubpermit(item);
        props.toggleSubview(true);
    }

    const rowItem = (item) => {
        let reqString = "";
        if (item.field === ""){
            return;
        }
        if (item.required){
            // placeohlder for required field mark (fields don't have normal labels here for * to attach to)
            reqString = " *"
        }

        // Change "string type booleans" to real ones. Not great but needs to be done somewhere due to nature of workpermits.
        if (item.typeOption === 'checkbox' && item.value && item.value !== true && item.value !== false) {
            item.value = item.value.toUpperCase() === "TRUE";
        }

        // TODO: make handling for the workpermitregister fields in m1.21 
        const registerFields = ["workpermitcreateddate", "workpermitmodifieddate", "workpermitstartdate", "workpermitclosedate", "responsibleperson", "executor", "issuer"]
        if (registerFields.includes(item.field)){
            return;
        }

        if (item.parentWorkPermitFieldId !== null){
            return;
        }

        // Add all the autofilled form data to data structure also 
        let autofill = "";
        if (item.type === "workcardvalue" && workCard !== null){
            if (item.field === "machine"){
                autofill = workCard.machine.code +" "+ workCard.machine.name;
            } else if (item.field === "code"){
                autofill = props.workCardId.toString();
            } else if (item.field === "faultdescription"){
                autofill = workCard.faultDescription;
            } else if (item.field === "procedure"){
                autofill = workCard.procedure;
            } else if (item.field === "tyolupa_lknumero"){
                autofill = workCard.machine.hierarchyPath;
            }
        } else if (user !== null && (item.field === 'grantor' || item.field === 'orderer')){
            autofill = user.name;
        } else if (item.field === 'status'){
            // TODO: status support in the m.1.21
            autofill = 'WORKPERMIT_STATUS0';
        }

        if (autofill !== "" && item.value === null){
            item.value = autofill;
        }

        let isMulti = false;
        if (item.typeOption === 'multi' || item.type === 'worker') isMulti = true;

        // set the values for a worker or detail field
        if (props.permitId && (item.type === 'detail' || item.type === 'worker') && item.values !== null && item.value === null) {
            item.value = GetDetail(item.values, item.field, isMulti, null, null, "workPermit");
            if (item.value === "") {
                item.value = null;
            }
        }
        
        return (
            <Row key={item.id}>
                {item.type === 'caption' 
                    ? <div className='v-paddings-15'><Col xs={4} className="header">{item.label}{reqString}</Col></div>
                    : item.type === 'workpermit'
                        ? <Col xs={4} className="header" onClick={()=>handleSubpermit(item)}>{item.label}{reqString} ⇨</Col>
                        : <Col xs={4} className="sub-header break-word">{item.label}{reqString}</Col>
                }
                
                <Col xs={8}>
                    <div className={invalidFields.includes(item.field) ? 'invalid-field' : ''}>
                    {item.type === 'label' ||item.type === 'label2' || item.type === 'caption'
                        ? item.field === 'status'
                            ? i18n.t('WORKPERMIT_STATUS0')
                            : item.field === 'grantor' && user !== null
                                ? user.name
                                : item.value
                        : <FormElement
                            key={item.id} 
                            name={item.field}
                            type={item.typeOption && item.type === 'extradata'
                                ? item.typeOption
                                : item.type === 'workpermit'
                                    ? item.typeOption
                                    : item.type === 'workcardvalue'
                                        ? 'text'
                                        : item.typeOption === 'multi'
                                            ? item.typeOption
                                            : item.type }
                            value={item.value === null && item.typeOption === 'checkbox'
                                ? false
                                : item.field === 'status'
                                    ? i18n.t('WORKPERMIT_STATUS0')
                                    : item.field === 'grantor' && user !== null
                                        ? user.name
                                        : item.field === 'orderer' && user !== null
                                            ? user.name
                                            : item.field === 'orderer_tel' && user !== null
                                                ? user.phone
                                                : item.type === 'workcardvalue'
                                                    ? autofill
                                                    : item.value}
                            options={item.details}
                            onChange={item.typeOption === 'checkbox'
                                ? handleCheckbox
                                : item.type === 'detail' || item.type === 'worker' || item.type === 'status'
                                    ? handleSelect
                                    : item.typeOption === 'datetime'
                                        ? handleDatetime
                                        : item.typeOption === 'date'
                                            ? handleDate
                                            : handleChange}
                            required={item.required}
                            isDisabled={item.field === 'status' || item.field === 'grantor' || item.type === 'workcardvalue' || item.field === 'orderer'
                                ? true
                                : false}
                            customClass='float-right'
                    />}
                    </div>
                </Col>
            </Row>
        );
    }

    const createRow = (field) => {
        return rowItem(field);
    }

    return (
        <Container>
            {props.subview && 
            <SubPermitForm
                workCardId={props.workCardId}
                submit={props.submit}
                onSubmit={handleSubpermitSubmit}
                subpermit={subpermit}
                permits={permits}
                workCard={workCard}
                permitId={props.permitId}
            />}
            {!props.subview &&
            <div className="form-table-container bottom-nav-space margin-top-15">
                <form className="workpermit-form">
                    <div className={invalidFields.includes('permitforms') ? 'invalid-field' : ''}>
                        {!props.permitId && <FormElement
                            name='permitforms'
                            type='select'
                            options={forms}
                            label={i18n.t('WORK_PERMIT')}
                            value={selectedForm}
                            onChange={handleSelect}
                            required
                        />}
                    </div>
                    {isFetched && <>
                    <Loader status={status} />
                    {permits
                        .sort((a, b) => a.mobileTabOrder - b.mobileTabOrder)
                        .map(item => createRow(item))}
                    </>}
                </form>
            </div>}
        </Container>
    );
}

export default WorkPermitForm;
