export enum DocumentSource {
    Machine = 1,
    SparePart = 2,
    WorkCard = 3,
    PurchaseOrder = 4,
    Project = 5,
    UnreleasedMachine = 6,
    OperatorMaintenance = 7,
    OperatorMaintenanceProcedure = 8,
    RouteMaintenance = 9,
    RouteMaintenanceWorkCard = 10,
    MaintenanceSubPlan = 11,
    PurchaseRequisition = 12,
    Person = 13,
    TaskItem = 14,
    SafetyNotice = 15,
    Tender = 16
}