import React from 'react';
import i18n from 'translations/i18n';
import { Loader } from './Loader';
import PaginationContainer from './PaginationContainer';
import SearchResultInfo from './SearchResultInfo';

const ListLayout = (props: IProps) => {
    const resultInfo = (
        <div className="v-paddings-15">
            <SearchResultInfo
                pageSize={props.pageSize}
                pageNumber={props.pageNumber}
                totalResultCount={props.displayInfo.totalResultCount}
            />
        </div>
    )

    const pagination = (
        <PaginationContainer
            currentPage={props.pageNumber}
            setCurrentPage={props.setPageNumber}
            pageCount={props.displayInfo.totalPageCount}
        />
    )

    const noResultsMessage = (
        <div className="no-results">
            <p>{i18n.t('NO_RESULTS')}</p>
        </div>
    )

    const getContent = () => {
        if (props.status === 'pending') {
            return <Loader status="pending" />
        }
        if (props.displayInfo.totalResultCount === 0) {
            return noResultsMessage;
        }
        return props.children;
    }

    const showPagination = props.displayInfo.totalPageCount > 1;

    return (
        <React.Fragment>
            {resultInfo}
            {showPagination && pagination}
            {getContent()}
            {showPagination && pagination}
        </React.Fragment>
    )
}

interface IProps { 
    children: React.ReactNode | React.ReactNode[];
    pageNumber: number;
    pageSize: number;
    displayInfo: IDisplayInfo;
    status?: string;
    setPageNumber: (value) => void;
}

export default ListLayout;