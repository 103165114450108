import { createUserManager } from "redux-oidc";

let baseLocation = window["runConfig"].baseLocation;
baseLocation = baseLocation === undefined ? "" : baseLocation.replace("/", "") + "/";
const uri = `https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/${baseLocation}`;

const clientId = window['runConfig']?.clientId ?? 'novi-mobile';
const identifier = window['runConfig']?.adfsRelayingPartyIdentifier ?? 'novi_client';
const authType = window['runConfig']?.openIdConnectType ?? "SSO";

const baseUserManagerConfig = {
    client_id: clientId,
    redirect_uri: uri + "callback",
    post_logout_redirect_uri: uri,
    response_type: 'code',
    scope: 'openid novi profile offline_access', // using offline_access gives refresh_tokens
    authority: 'https://'+ window['runConfig'].authGatewayURL,
    silent_redirect_uri: uri + "silent_renew.html",
    automaticSilentRenew: true, // set to false if need to use manual silentRenew instead
    filterProtocolClaims: true,
    loadUserInfo: true,
    monitorSession: false
};

/**
 * If need to dynamically adjust userManagerConfig:
 * - Set 'openIdConnectType' property in runtime-config.js
 * - Create a new configuration below with 'openIdConnectType' value as the key
 */
const authTypeSpecificSettings = {
    ADFS: {
        loadUserInfo: false, // Calls 'userInfo' endpoint, not in use with ADFS
        scope: `${identifier}/openid`,
    },
    AZURE: {
        loadUserInfo: false, // Calls 'userInfo' endpoint, not in use with AZURE
        scope: `openid ${clientId}/.default profile email offline_access`, 
    }
}

const userManagerConfig = {
    ...baseUserManagerConfig,
    ...(authTypeSpecificSettings[authType] ?? {})
};


const userManager = createUserManager((userManagerConfig) as any);
export default userManager;
