import React from 'react';
import TextTruncate from 'react-text-truncate';
import i18n from '../translations/i18n';

const TableRowComponent = ({ keyId = null, row = null, children = null, handleClick = null }) => {
    if (children) {
        return (
            <tr onClick={handleClick}>
                {children.map((cell, i) => (
                    <td key={i}>{cell.id ? cell.value : cell}</td>
                ))}
            </tr>
        );
    }

    let args;

    if (Array.isArray(row)) {
        const argsProp = row.find(rCell => rCell.key === 'args').value;
        args = argsProp ? [argsProp.id, argsProp.arg] : [row];
    } else {
        args = row.args ? [row.args.id, row.args.arg] : [row];
    }

    if (children !== null) {
        return (
            <tr onClick={() => handleClick(...args)}>
                {children}
            </tr>
        );
    }

    return (
        <tr key={args[0]}
            className="workcard-element"
            onClick={() => handleClick(...args)}
            style={{ backgroundColor: keyId % 2 === 0 ? '#272727' : '#2f2f2f' }}
        >
            {!Array.isArray(row)
                ? Object.keys(row).map((td, i) => {
                    return td !== 'args' && <td key={row.args.id + i}>{row[td]}</td>
                })
                : row.map((td, i) => {
                    const value = td.value
                        ? td.value.id
                            ? (td.value.name || i18n.t(td.value.label) || td.value.description)
                            : td.value
                        : '';

                    return td.key !== 'args' && <td key={args[0] + i}>
                        {typeof td.value === 'string' && td.key.toLowerCase() !== 'inputdate'
                            ? <span className="long-text-column">
                                <TextTruncate
                                    line={1}
                                    truncateText="..."
                                    text={value}
                                />
                            </span>
                            : <span>{value}</span>}
                    </td>
                })
            }
        </tr>
    );

}

export default TableRowComponent;
