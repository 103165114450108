import React from 'react';
import NavigationBar from '../navigation';
import { Container, Row } from 'react-bootstrap';
import i18n from '../../translations/i18n';
import { toast } from 'react-toastify';
import settingsAPI from '../../config/settingsAPI';
import OperatorMaintenanceCard from './components/OperatorMaintenanceCard';
import { RouteChildrenProps } from 'react-router-dom';
import { PropsFromRedux } from '.';

type LocationState = { notificationMsg; }
type Props = PropsFromRedux & RouteChildrenProps<null, LocationState>;

const moduleSettings = settingsAPI.moduleData.operatormaintenaces;

class OperatorMaintenanceList extends React.Component<Props> {
    componentDidMount = () => {
        const { selectedMachine, location, history } = this.props;
        if (location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }
        }

        if (selectedMachine) {
            this.props.fetchOperatorMaintenances(selectedMachine);
        }

        this.props.fetchMaintenanceMachines();
    }

    getCurrentMachine = (mId) => {
        if (!mId) return '';

        const machine = this.props.opmMachines.find(opMachine => opMachine.id === mId);
        if (!machine) return '';
        return { value: machine.id, label: machine.label };
    }

    render() {
        const { operatorMaintenances, selectedMachine, opmMachines, location, history } = this.props;

        const sceneData = {
            view: moduleSettings.name,
            title: null,
            filtersCount: null,
            location: location,
            history: history,
            currentFilters: {
                quickSearch: this.getCurrentMachine(selectedMachine),
            },
            noFilters: true,
            noHierarchySelect: true
        };

        return (
            <div>
                <NavigationBar
                    fetchData={'fetchOperatorMaintenances'}
                    currentView={sceneData}
                    quickSearchOptions={opmMachines}
                    viewAction={null}
                    filters={true}
                />
                <div className="work-card-container">
                    <Container className="v-paddings-15 bottom-margin-100" fluid>
                        {operatorMaintenances?.length === 0 && <div className="no-results"><p>{i18n.t('SELECT_MACHINE')}</p></div>}
                        <Row>
                            {operatorMaintenances
                                .map(operatorMaintenance => (
                                    <OperatorMaintenanceCard
                                        key={operatorMaintenance.id}
                                        content={operatorMaintenance}
                                        machineId={selectedMachine}
                                    />
                                ))}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default OperatorMaintenanceList;
