import { LOGIN, LOGOUT, CLEAR_USER_CACHE } from '../../config/actionTypes';
import authAPI from '../../api/authAPI';
import { HandleError, Toaster } from '../../components/HelperFunctions';
import userManager from '../../config/userManager';
import * as types from '../../config/actionTypes';
import axios from 'axios';
import { parseJwt, redirectToChangePasswordPage } from './utils';
import i18n from '../../translations/i18n';
import { getDateTime } from 'utils';

export const login = (params) => (
    async dispatch => {
        dispatch({
            type: `${LOGIN}_PENDING`
        });
        try {
            const { data } = await authAPI.userLogin(params);
            const { access_token, refresh_token, expires_in } = data;
            const jwtDecoded = parseJwt(access_token);
            const userId = jwtDecoded.sub;

            if (access_token && userId) {
                authAPI.setAccessToken(access_token);
                authAPI.setRefreshToken(refresh_token);
                authAPI.setExpiresIn(expires_in);
                
                dispatch({
                    type: `${LOGIN}_FULFILLED`,
                    payload: {
                        user: userId,
                        lockedStatus: { message: "" }
                    }
                });
            }
        } catch (error) {

            let message = '';
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    // Request made and server responded
                    if (error.response.status === 400) {
                        const errorDesc = error.response.data.error_description;

                        if (errorDesc == "USER_LOCKED_OUT_MOBILE") {
                            const date = getDateTime(error.response.data.lockedUntil);

                            message = i18n.t(errorDesc, {
                                user: params.name,
                                date: date
                            });
                        }
                        else if (errorDesc === 'password_expired') {
                            redirectToChangePasswordPage();
                        } else {
                            Toaster({ msg: 'INVALID_LOGIN_VALUES', type: 'error' });
                        }
                    } else {
                        HandleError(error, 'Login');
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    Toaster({ msg: error.request, type: 'error' });
                } else if (error.message) {
                    // Something happened in setting up the request that triggered an Error
                    Toaster({ msg: error.message, type: 'error' });
                }
            }
            dispatch({
                type: `${LOGIN}_REJECTED`,
                payload: { message }
            });
        }
    }
);

export const logout = () => {
    return async (dispatch, getState: () => State) => {
        dispatch({
            type: `${LOGOUT}_PENDING`
        });
        try {
            // Set user id for redirecting current location after login again
            const { userDetails, userAdNameDetails } = getState().settings;
            const userId = userAdNameDetails?.id ? userAdNameDetails.id : userDetails.id
            localStorage.setItem('lastUser', `${userId}`);

            authAPI.removeAccessToken();
            authAPI.removeRefreshToken();

            dispatch({
                type: `${LOGOUT}_FULFILLED`,
                payload: null
            })
            dispatch({
                type: CLEAR_USER_CACHE,
                payload: null
            });

        } catch (error) {
            HandleError(error, 'Logout')
            dispatch({
                type: `${LOGOUT}_REJECTED`,
                payload: error
            })
            dispatch({
                type: CLEAR_USER_CACHE,
                payload: null
            });
        }
    }
};

export const logoutSSO = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: `${LOGOUT}_PENDING`
        });
        try {
            userManager.getUser().then(
                user => {
                    // Set user id for redirecting current location after login again
                    const userId = getState().settings.userAdNameDetails.id
                    localStorage.setItem('lastUser', userId?.toString());

                    const hint = user.id_token;
                    userManager.removeUser();
                    userManager.revokeAccessToken();
                    userManager.clearStaleState();
                    userManager.signoutRedirect({ 'id_token_hint': hint });

                    authAPI.removeAccessToken();
                    authAPI.removeRefreshToken();

                    dispatch({
                        type: types.STATUS_FULFILLED
                    });
                },
                error => {
                    HandleError(error, 'Logout');

                    dispatch({
                        type: types.STATUS_FULFILLED
                    });
                }
            )

        } catch (error) {
            HandleError(error, 'Logout')
            dispatch({
                type: `${LOGOUT}_REJECTED`,
                payload: error
            })
            dispatch({
                type: CLEAR_USER_CACHE,
                payload: null
            });
        }
    }
};