export const VIEW_FILTER = {
    HOUR_CARD: 1,
    MACHINE: 2,
    PROJECT: 3,
    PURCHASE_ORDER_ITEM_ARRIVAL: 4,
    PURCHASE_ORDER_ITEM: 5,
    PURCHASE_ORDER: 6,
    WAREHOUSE: 7,
    WORKCARD: 8
} as const;
