import React, { useEffect, useCallback } from 'react';
import Quagga from 'quagga';

const config = {
    inputStream: {
        type: "LiveStream",
        constraints: {
            facingMode: "environment",
        }
    },
    locator: {
        patchSize: "medium",
        halfSample: true
    },
    decoder: {
        readers: [{
            format: "code_128_reader",
            config: {}
        }]
    },
    locate: true
}

interface IProps { onDetected: (code: string) => void; }

const BarcodeReader = React.forwardRef<null, IProps>(({ onDetected }, ref) => {

    const cb = useCallback(result => {
        onDetected(result.codeResult.code)
    }, [onDetected]);

    useEffect(() => {
        Quagga.init(config, error => {
            if (error) {
                console.log(error);
                return;
            }

            Quagga.start();
        });

        Quagga.onProcessed(result => {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0,
                        0,
                        Number(drawingCanvas.getAttribute("width")),
                        Number(drawingCanvas.getAttribute("height"))
                    );
                    result.boxes
                        .filter(function (box) {
                            return box !== result.box;
                        })
                        .forEach(function (box) {
                            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                                color: "green",
                                lineWidth: 2
                            });
                        });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                        color: "#00F",
                        lineWidth: 2
                    });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(
                        result.line,
                        { x: "x", y: "y" },
                        drawingCtx,
                        { color: "red", lineWidth: 3 }
                    );
                }
            }
        });

        Quagga.onDetected(cb);

        return () => {
            Quagga.stop();
        }
    }, [cb]);

    return (
        // When target is not specified,
        // QuaggaJS looks for an element that matches
        // the CSS selector #interactive.viewport
        <div ref={ref} id="interactive" className="viewport" />
    );
});

export default BarcodeReader;