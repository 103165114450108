import React from 'react';
import CardComponent from '../../../components/CardComponent';
import OperatorMaintenanceCardTitle from './OperatorMaintenanceCardTitle';
import OpmCardBody from './OpmCardBody';

const OperatorMaintenanceCard = (props: IProps) => {
    return (
        <CardComponent
            title={
                <OperatorMaintenanceCardTitle
                    text={props.content.name}
                />
            }
            body={
                <OpmCardBody
                    content={props.content}
                    machineId={props.machineId}
                />
            }
        />
    );
}

interface IProps {
    content: IOperatorMaintenanceLite;
    machineId: number;
}

export default OperatorMaintenanceCard;