import * as types from '../config/actionTypes';

const initialState = {
    operatorLevels: [],
    routeLevels: [],
    operatorMachines: [],
    routeMachines: [],
    operatorMaintenanceList: [],
    operatorMaintenance: null,
    routeMaintenances: [],
    opmPhases: [],
    viewSettings: { operatormaintenance: [] },
    status: '',
    loadStatus: {},
    procedureFileData: {},
    error: null,
    opmDocuments: {
        documents: [],
        machineDocuments: {},
        workCardDocuments: [],
        procedureDocuments: []
    },
    selectedMachine: null,
    opmMachines: [],
    procedureDataTypes: []
}

const maintenanceReducer = (state: IMaintenanceState = initialState, action): IMaintenanceState => {
    switch (action.type) {
        case `${types.FETCH_MAINTENANCE_ITEMS}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_MAINTENANCE_ITEMS}_FULFILLED`:
            state[action.payload.params.type] = action.payload.items;

            return {
                ...state,
                status: 'fulfilled'
            }
        case `${types.FETCH_MAINTENANCE_ITEMS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
                //maintenances: []
            }
        case `${types.FETCH_OPERATOR_MAINTENANCES}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_OPERATOR_MAINTENANCES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                operatorMaintenanceList: action.payload.opMaintenances,
                selectedMachine: action.payload.mId
            }
        case `${types.FETCH_OPERATOR_MAINTENANCES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                operatorMaintenanceList: [],
                error: action.payload
            }
        case `${types.FETCH_MAINTENANCE_MACHINES}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_MAINTENANCE_MACHINES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                opmMachines: action.payload
            }
        case `${types.FETCH_MAINTENANCE_MACHINES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload,
                opmMachines: []
            }
        case `${types.FETCH_OPERATOR_MAINTENANCE}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_OPERATOR_MAINTENANCE}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                operatorMaintenance: {
                    id: action.payload.opmId,
                    ...action.payload.data
                }
            }
        case `${types.FETCH_OPERATOR_MAINTENANCE}_REJECTED`:
            return {
                ...state,
                status: 'error',
                operatorMaintenance: null,
                error: action.payload
            }
        case `${types.SAVE_OPERATOR_MAINTENANCE}`:
            //TODO: 1) add new workcard with relation to operator maintenance 2) save any operator maintenance data? 

            return {
                ...state,
                status: 'fulfilled'
            }
        case `${types.FETCH_OPMAINTENANCE_VIEW_SETTINGS}_PENDING`:
            return {
                ...state,
                status: 'pending'
            }
        case `${types.FETCH_OPMAINTENANCE_VIEW_SETTINGS}_FULFILLED`:
            return {
                ...state,
                status: 'pending',
                viewSettings: {
                    ...state.viewSettings,
                    [action.payload.type[0]]: action.payload.viewSettings['operatormaintenance'],
                }
            }
        case `${types.FETCH_PROCEDURE_DATATYPES}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_PROCEDURE_DATATYPES}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                procedureDataTypes: action.payload
            }
        case `${types.FETCH_PROCEDURE_DATATYPES}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case `${types.FETCH_POINT_IMAGE}_PENDING`:
            return {
                ...state,
                loadStatus: {
                    ...state.loadStatus,
                    [action.payload]: 'pending'
                },
                error: null
            }
        case `${types.FETCH_POINT_IMAGE}_FULFILLED`:
            return {
                ...state,
                loadStatus: {
                    ...state.loadStatus,
                    [action.payload.docItemId]: 'fulfilled'
                },
                procedureFileData: {
                    ...state.procedureFileData,
                    [action.payload.docItemId]: action.payload.fileData
                }
            }
        case `${types.FETCH_POINT_IMAGE}_REJECTED`:
            return {
                ...state,
                loadStatus: {
                    ...state.loadStatus,
                    [action.payload.docItemId]: 'error'
                },
                error: action.payload.error
            }
        case `${types.FETCH_OPMAINTENANCE_DOCUMENTS}_PENDING`:
            return {
                ...state,
                status: 'pending',
                error: null
            }
        case `${types.FETCH_OPMAINTENANCE_DOCUMENTS}_FULFILLED`:
            return {
                ...state,
                status: 'fulfilled',
                opmDocuments: action.payload
            }
        case `${types.FETCH_OPMAINTENANCE_DOCUMENTS}_REJECTED`:
            return {
                ...state,
                status: 'error',
                error: action.payload
            }
        case types.ADD_OPERATOR_MAINTENANCE_DOCUMENT:
            const {
                operatorMaintenanceId,
                ...opmDocPayload
            } = action.payload;

            return {
                ...state,
                opmDocuments: {
                    ...state.opmDocuments,
                    documents: state.opmDocuments.documents.concat([opmDocPayload])
                }
            }
        case `${types.ADD_OPERATOR_MAINTENANCE_DOCUMENT}_COMMIT`:
            const { data: opmDocId } = action.payload;

            return {
                ...state,
                opmDocuments: {
                    ...state.opmDocuments,
                    documents: state.opmDocuments.documents.map(document => {
                        if (document.id === action.meta.tempId) {
                            return {
                                ...document,
                                id: opmDocId
                            };
                        }
                        return document;
                    })
                }
            }
        case `${types.ADD_OPERATOR_MAINTENANCE_DOCUMENT}_ROLLBACK`:
            return {
                ...state,
                opmDocuments: {
                    ...state.opmDocuments,
                    documents: state.opmDocuments.documents.filter(document => document.id !== action.meta.tempId)
                }
            }
        case types.DELETE_OPMAINTENANCE_DOCUMENT:
            return {
                ...state,
                opmDocuments: {
                    ...state.opmDocuments,
                    documents: state.opmDocuments.documents.filter(document => document.id !== action.payload)
                }
            }
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
        case types.CLEAR_CACHE:
            return initialState
        case types.STATUS_FULFILLED:
            return {
                ...state,
                status: 'fulfilled',
            }
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
            return {
                ...state,
                selectedMachine: null
            }
        default:
            return state;
    }
}

export default maintenanceReducer;