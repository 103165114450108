export enum ItemPropertyTypes {
    workcard,
    machine,
    sparepart,
    operatormaintenance
}

export enum ItemPropertyTypeTranslations {
    "WORKCARD",
    "LAITE",
    "WAREHOUSE_HEADER",
    "OPERATOR_MAINTENANCE"
}