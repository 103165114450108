import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import {
    fetchMachines,
    setMachinesSettings,
    fetchMachineOptions,
    fetchHierarchyMachines,
    fetchHierarchyBreadCrumb,
    machineDefaultSearch,
    resetCurrentFilters,
    fetchMeasurements,
    setPageNumberAction as setPageNumber
} from './actions';
import { fetchViewSettings } from '../../commonActions/actions';
import { withRouter } from 'react-router';
import Machines from './Machines';
import { RouteChildrenProps } from 'react-router-dom';

type MatchParams = { machineId?: string; mId?: string; };
type LocationState = { hierarchyItem; notificationMsg; heightPos; };

export interface IMachinesProps extends RouteChildrenProps<MatchParams, LocationState> {
    onMachineSelect: (isSelection, isLevel) => void;
    machineSelect: boolean;
    itemStatus: string;
    referrer: string[];
}

const mapStateToProps = (state: State, ownProps: IMachinesProps) => {
    const { settings } = state;

    return {
        machines: state.machines.machines,
        hierarchyMachines: state.machines.hierarchyMachines,
        hierarchyItems: state.machines.hierarchyItems, // breadcrumb items
        currentFilters: state.machines.currentFilters,
        displaySettings: state.machines.settings,
        settings: state.settings,
        status: state.machines.status,
        machinesDisplayInfo: state.machines.machinesDisplayInfo,
        error: state.machines.error,
        viewSettings: settings.userSettings ? settings.userSettings.filter(setting => setting.group === 'machineregistrygrid') : []
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMachines,
    setMachinesSettings,
    fetchHierarchyMachines,
    fetchMachineOptions,
    fetchHierarchyBreadCrumb,
    fetchViewSettings,
    machineDefaultSearch,
    resetCurrentFilters,
    fetchMeasurements,
    setPageNumber
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(Machines));
