export enum DefaultQuickSearches {
    OPEN_WORK_CARDS = 1,
    OWN_OPEN_WORKCARDS = 2,
    OWN_STARTED_WORKS = 3,
    OWN_DELAYED_WORKS = 4,
    USERS_WORKER_GROUP_WORKS = 5, // Deprecated, not in use
    DELAYED_WORKS = 6,
    NO_WORKERS_DASHBOARD_HEADER = 7,
    STARTED_WORKS = 8,
    OWN_DEFAULT_SEARCH = 9
}