import React from 'react';
import { Container, Button } from 'react-bootstrap';
import NavigationBar from './../../scenes/navigation';
import '../../styles/global.scss';
import './../../scenes/dashboard/styles/dashboard.scss';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import ItemMenuDialog, { ItemMenuDialogItem, ContentChoices } from './components/ItemMenuDialog';
import DashboardElement from './components/DashboardElement';
import i18n from '../../translations/i18n';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import ItemPickerDialog from './components/ItemPickerDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { updateDashboardItems, updateDashboard } from '../../scenes/dashboard/actions';
import { fetchAllQuickSearches } from '../../commonActions/actions';
import { faStar, } from '@fortawesome/free-regular-svg-icons';
import { Flex } from 'reflexbox';
import DashboardName from './components/DashboardName';
import { withStorage, dashboardKey, StorageProps } from 'hooks/useLocalStorage';
import { DashboardCustomizerPopover, IPopoverAction } from './components/DashboardCustomizerPopover';

interface IProps extends PropsFromRedux {
    location: any;
    history: any;
    toggleCustomizer: (dashboardSaved?: boolean) => void;
    toggleModal: () => void;
    currentDashboard: IDashboard;
    setDefaultDashboard: (newDefaultDashboardId: number) => void;
    factoryDefaultDashboard: IDashboard;
    noviConfigs: INoviConfigs;
    dashboardEnvironmentEditAllowed: boolean;
    dashboardItemId: number;
}

type ComponentItem = IDashboardItem & {
    moduleId: number;
    itemName: string;
    itemType: IDashboardItemType;
    item: ItemMenuDialogItem;
    isNewComponent: boolean;
}

interface IState {
    draggedItem: any;
    currentComponentId: number;
    itemDialogOpen: boolean;
    targetItemName: string;
    componentItems: ComponentItem[];
    showItemPicker: boolean;
    selectedModule: number;
    dragEnabled: boolean;
    dragOver: any;
    removedComponents: any[];
    dashboardName: string;
    defaultDashboardId: number;
    dialogOpen: boolean;
}

class DashboardCustomizer extends React.Component<IProps & StorageProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            draggedItem: null,
            currentComponentId: null,
            itemDialogOpen: false,
            targetItemName: '',
            componentItems: [],
            showItemPicker: false,
            selectedModule: null,
            dragEnabled: false,
            dragOver: null,
            dialogOpen: false,
            removedComponents: [],
            dashboardName: '',
            defaultDashboardId: parseInt(props.getStorageSetting(dashboardKey)?.id, 10)
        };
    }

    componentDidMount() {
        const { currentDashboard, dashboardItemId, fetchAllQuickSearches } = this.props;
        if (currentDashboard?.dashboardModules) {
            const mapComponentItem = (module: IDashboardModule, item: IDashboardItem): ComponentItem => ({
                ...item,
                moduleId: module.id,
                itemName: item.label,
                itemType: null,
                item: null,
                isNewComponent: false
            })

            let componentItems = currentDashboard.dashboardModules
                .flatMap(module => module.dashboardItems.map(item => mapComponentItem(module, item)))
                .sort((a,b ) => a.rowNumber - b.rowNumber);

            this.setState({
                componentItems: componentItems,
                dashboardName: currentDashboard.name
            });

            if (dashboardItemId !== null) {
                this.setState({
                    currentComponentId: dashboardItemId,
                    itemDialogOpen: true
                })
            }
        }

        fetchAllQuickSearches();
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>): void {
        if ((prevProps.currentDashboard?.id != this.props.currentDashboard?.id) && this.props.currentDashboard?.name) {
            this.setState({ dashboardName: this.props.currentDashboard.name });
        }
        if ((prevProps.currentDashboard?.name != this.props.currentDashboard?.name) && this.props.currentDashboard?.name) {
            this.setState({ dashboardName: this.props.currentDashboard.name });
        }
    }

    addComponent = (componentTypeId: number) => {
        const { componentItems, selectedModule } = this.state;

        let maxId = 0;
        for (const component of componentItems) {
            if (component && component.id > maxId) maxId = component.id;
        }

        let newComponentItem: ComponentItem = {
            id: maxId + 1,
            componentTypeId: componentTypeId,
            itemTypeId: null,
            itemValue: '',
            label: '',
            propertyType: '',
            rowNumber: null,
            moduleId: selectedModule || 1,
            itemName: '',
            itemType: null,
            item: null,
            isNewComponent: true
        }

        this.setState(state => ({
            componentItems: [...state.componentItems, newComponentItem]
        })); 

        this.toggleActions(newComponentItem.id);
    }

    toggleActions = (componentId) => {
        const { componentItems } = this.state;
        const currentComponent = componentItems.find(comp => comp.id === componentId);

        this.setState(state => ({
            itemDialogOpen: state.currentComponentId === componentId && state.itemDialogOpen ? false : true,
            currentComponentId: componentId,
            showItemPicker: false
        }))
    }

    onDragStart = (e) => {
        const item = e.target;
        e.dataTransfer.setData('itemId', item.id);
    };

    onDragOver = (e) => {
    }

    toggleDragging = () => {
        this.setState(state => ({ dragEnabled: !state.dragEnabled }))
    }

    onDrop = (e) => {
        const targetId = parseInt(e.target?.id, 10);

        if (targetId) {

            const { componentItems } = this.state;
            let components = [...componentItems];
            const itemId = e.dataTransfer.getData('itemId');
   
            let draggedComponent = components.find(component => component.id === parseInt(itemId, 10));

            // Move a dashboard item from one module to another
            const changeModule = (targetModule) => {
                components[components.indexOf(draggedComponent)] = {
                    ...draggedComponent,
                    moduleId: targetModule
                };
            };

            // Swap 2 dashboard items within same module
            const swapItems = (itemA, itemB) => {
                components[components.indexOf(itemA)] = {
                    ...itemB,
                    rowNumber: itemA.rowNumber,
                };
                components[components.indexOf(itemB)] = {
                    ...itemA,
                    rowNumber: itemB.rowNumber,
                };
            };

            if (e.target?.draggable) {
                const componentToSwapWith = components.find(component => component.id === targetId);
                const sameModule = draggedComponent.moduleId === componentToSwapWith.moduleId;
                const sameItem = draggedComponent.id === componentToSwapWith.id;

                if (sameItem) {
                    // Drag on itself, do nothing
                    return;
                }
                else if (sameModule) {
                    // Drag on another item within same module, switch items
                    swapItems(draggedComponent, componentToSwapWith);
                }
                else {
                    // Drag on an item from another module, move to that module
                    changeModule(componentToSwapWith.moduleId);
                }
            } else {
                // Drag on the background of another module
                changeModule(targetId);
            }

            this.setState({
                draggedItem: null,
                componentItems: components
            });
        }
    }

    dragOver = (e) => {
        e.preventDefault();
    }

    closeItemMenuDialog = () => {
        this.setState({ itemDialogOpen: false });
    }

    closeItemPickerDialog = () => {
        this.setState({ showItemPicker: false });
    }

    applyContentChoices = (content: ContentChoices) => {
        const { componentItems } = this.state;
        let components = [...componentItems]
        let currentComponent = components.find(component => component.id === content.id);
        let componentWithSamePosition = components[content.itemRowId];

        if (componentWithSamePosition?.id !== currentComponent.id) {
            components[components.indexOf(currentComponent)] = {
                ...componentWithSamePosition
            };
            components[components.indexOf(componentWithSamePosition)] = {
                ...currentComponent,
                itemName: content.itemName,
                itemType: content.itemType,
                item: content.item,
                propertyType: content.itemPropertyType,
                itemValue: content.item.value
            };
        } else {
            components[components.indexOf(currentComponent)] = {
                ...currentComponent,
                itemName: content.itemName,
                itemType: content.itemType,
                item: content.item,
                propertyType: content.itemPropertyType,
                itemValue: content.item.value
            };
        }

        this.setState({ componentItems: components });
    }

    removeComponent = (compId) => {
        const { componentItems } = this.state;
        let components = [...componentItems];
        let currentComponent = components.find(component => component.id === compId);
        components.splice(components.indexOf(currentComponent), 1);

        this.setState(state => ({
            componentItems: components,
            removedComponents: [...state.removedComponents, currentComponent] 
        }));
    }

    openDashboardItemPicker = (moduleId: number) => {
        this.setState({ showItemPicker: true, selectedModule: moduleId });
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    saveDashboardEdit = () => {
        const { currentDashboard } = this.props;
        const { componentItems, removedComponents, dashboardName } = this.state;

        if (dashboardName !== currentDashboard.name) {
            this.props.updateDashboard(currentDashboard.id, { name: dashboardName });
        }

        let moduleID = 1;
        let rowNumber = 0;

        const dashboardItems = componentItems.sort((a, b) => a.moduleId - b.moduleId).map((comp, i) => {
            rowNumber++;

            if (comp.moduleId !== moduleID) {
                moduleID = comp.moduleId;
                rowNumber = 1;
            }

            return {
                id: comp.isNewComponent ? null : comp.id,
                label: comp.itemName || comp.label,
                itemValue: comp.item?.value
                    ? comp.item.value
                    : comp.item?.id
                        ? comp.item.id
                        : comp.itemValue || '',
                itemTypeId: comp.itemType?.id ? comp.itemType.id : comp.itemTypeId,
                componentTypeId: comp.componentTypeId,
                rowNumber: rowNumber,
                propertyType: comp.propertyType,
                moduleId: comp.moduleId,
            }
        });

        if (currentDashboard?.id) {
            if (removedComponents?.length > 0) {
                removedComponents.filter(comp => !comp.isNewComponent).map(comp => {
                    return {
                        id: comp.id,
                        moduleId: comp.moduleId
                    }
                });
            }

            this.props.updateDashboardItems(dashboardItems, removedComponents);
        }

       this.props.toggleCustomizer(true);
    }

    setDefaultDashboard = () => {
        try {
            const id = this.props.currentDashboard.id;
            this.props.setDefaultDashboard(id);
            this.setState({ defaultDashboardId: id});
        } catch (e) { console.log(e); }
    }

    resetDefaultDashboard = () => {
        try {
            const id = this.props.factoryDefaultDashboard.id;
            this.props.setDefaultDashboard(id);
            this.setState({ defaultDashboardId: id});
        } catch (e) { console.log(e); }
    }

    validateDashboardItems = () => {
        let isInvalid = false;
        const { componentItems } = this.state;
        if (componentItems?.length === 0) return isInvalid;

        componentItems.forEach(comp => {
            if (comp.isNewComponent) {
                if ((!comp.item?.value && !comp.item?.id) || !comp.itemType?.id) {
                    isInvalid = true;
                }
            } else if ((!comp.itemType && !comp.itemTypeId) || (!comp.itemValue && !comp.item)) {
                isInvalid = true;
            }
        });

        return isInvalid;
    }

    togglePopover = enabled => {
        this.setState({ dialogOpen: enabled });
    }

    render() {
        const {
            location,
            history,
            dashboardItemTypes,
            allQuickSearches,
            currentDashboard,
            dashboardTypeOptions,
            fieldSearchOptions,
            machineGroupId,
            userId,
            noviConfigs,
            dashboardEnvironmentEditAllowed,
            getStorageSetting,
            factoryDefaultDashboard,
            toggleModal
        } = this.props;
        const { componentItems, itemDialogOpen, currentComponentId, showItemPicker, dragEnabled } = this.state;
        const currentComponent = componentItems.find(component => component.id === currentComponentId);

        const actions: IPopoverAction[] = [
            {
                icon: 'edit',
                label: i18n.t('EDIT_DASHBOARD'),
                clickFn: toggleModal,
                isActionFn: true,
                paClass: '',
            },
            {
                icon: dragEnabled ? 'close' : 'move',
                label: dragEnabled ? i18n.t('STOP_MOVE_COMPONENTS') : i18n.t("MOVE_COMPONENTS"),
                clickFn: this.toggleDragging,
                isActionFn: true,
                params: [],
                paClass: ''
            },
            {
                icon: 'save',
                label: i18n.t('SAVE_AND_CLOSE'),
                clickFn: this.saveDashboardEdit,
                isActionFn: true,
                paClass: '',
                disabled: this.validateDashboardItems()
            }
        ];

        const sceneData = {
            view: 'dashboardEdit',
            title: null,
            location: location,
            history: history,
            customFunction: this.addComponent,
            backAction: { action: () => this.props.toggleCustomizer() }
        };
        const viewAction = 'settings';

        // either use existing dashboard's modules or init default modules with temporary id -values 
        const modules = currentDashboard ? currentDashboard.dashboardModules : [{ id: 1 }, { id: 2 }, { id: 3 }];

        const { defaultDashboardId, dialogOpen } = this.state;
        const isDefaultIdCurrentId = defaultDashboardId === currentDashboard?.id;

        return (
            <div>
                {dialogOpen && <div className="overlay-canvas" onClick={() => this.togglePopover(false)} ></div>}
                <NavigationBar
                    currentView={sceneData}
                    viewAction={viewAction}
                    popover={
                        <DashboardCustomizerPopover 
                            dialogOpen={dialogOpen} 
                            togglePopover={this.togglePopover} 
                            actions={actions}
                        />
                    }
                />
                {itemDialogOpen && <ItemMenuDialog
                    dashboard={currentDashboard}
                    showDialog={itemDialogOpen}
                    onHide={this.closeItemMenuDialog}
                    itemContent={{
                        compId: currentComponent.id,
                        itemValue: currentComponent.itemValue,
                        itemTypeId: currentComponent.itemTypeId,
                        itemType: currentComponent.itemType,
                        itemName: (currentComponent.itemName || currentComponent.label || ''),
                        item: currentComponent.item,
                        itemPropertyType: currentComponent.propertyType,
                        itemRowId: componentItems.indexOf(currentComponent),
                        componentTypeId: currentComponent.componentTypeId,
                        isNewComponent: currentComponent.isNewComponent
                    }}
                    applyContentChoices={this.applyContentChoices}
                    removeComponent={this.removeComponent}
                    dashBoardItemTypes={dashboardItemTypes}
                    dashboardTypeOptions={dashboardTypeOptions}
                    fieldSearchOptions={fieldSearchOptions}
                    quickSearchOptions={allQuickSearches}
                    machineGroupId={machineGroupId}
                    userId={userId}
                    noviConfigs={noviConfigs}
                    dashboardEnvironmentEditAllowed={dashboardEnvironmentEditAllowed}
                />}
                {showItemPicker && <ItemPickerDialog
                    showDialog={showItemPicker}
                    closeDialog={this.closeItemPickerDialog}
                    addItem={this.addComponent}
                />}
                <div>
                    <DndProvider backend={Backend}>
                        <Container className="dashboard-container bottom-margin-100">
                            <DashboardName
                                name={this.state.dashboardName}
                                onChange={this.handleInputChange}
                            />
                            {modules.map((module) => (
                                <Flex
                                    id={module.id}
                                    key={module.id}
                                    flexWrap='wrap'
                                    className={(dragEnabled ? "dashboard-module dragging-area" : "dashboard-module")}
                                    onDrop={this.onDrop}
                                    onDragOver={this.dragOver}
                                >
                                    <div className="module-info">{i18n.t('MODULE') + ' ' + module.id.toString()}</div>
                                        {componentItems
                                            .filter(item => item.moduleId === module.id)
                                            .map(component => (
                                                <DashboardElement
                                                    key={component.id}
                                                    id={component.id}
                                                    type={component.componentTypeId}
                                                    content={component}
                                                    toggleActions={this.toggleActions}
                                                    onDragStart={this.onDragStart}
                                                    onDragOver={this.onDragOver}
                                                    isDraggable={dragEnabled}
                                                />
                                            ))}
                                        {!dragEnabled && <div className="floating-button">
                                            <Button
                                                className="large"
                                                variant="large"
                                                onClick={() => this.openDashboardItemPicker(module.id)}
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </div>}
                                </Flex>
                            ))}
                            {!dragEnabled && <div style={{ height: '150px', margin: '20px auto' }}>
                                <Button
                                    className="action dashboard-button-center novi-default-btn-color"
                                    variant={"small" + (isDefaultIdCurrentId && factoryDefaultDashboard?.id == defaultDashboardId ? " disabled" : "")}
                                    onClick={isDefaultIdCurrentId ? this.resetDefaultDashboard : this.setDefaultDashboard}
                                >
                                    <div>
                                        <FontAwesomeIcon icon={isDefaultIdCurrentId ? faMinusCircle : faStar} size="lg" />
                                        <span className="left-margin-5">
                                            {isDefaultIdCurrentId ? i18n.t('REMOVE_DEFAULT') : i18n.t('SET_AS_DEFAULT')}
                                        </span>
                                    </div>
                                </Button>
                            </div>}
                        </Container>
                    </DndProvider>
                </div>    
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchAllQuickSearches,
    updateDashboardItems,
    updateDashboard
}, dispatch);

const mapStateToProps = (state: State) => {
    return {
        dashboardItemTypes: state.dashboard.dashboardItemTypes,
        dashboardTypeOptions: state.dashboard.dashboardTypeOptions,
        allQuickSearches: state.settings.allQuickSearches,
        machineGroupId: state.settings.machineGroupId,
        userId: state.settings.userId,

        // TODO: If/when want more options for field specific search implement a better way than inlining the options here
        fieldSearchOptions: [{ id: "workCardCode", label: i18n.t("WORKCARD_CODE") }],
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withStorage(DashboardCustomizer));
