import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import settingsAPI from '../../../config/settingsAPI';
import { fetchCostPools, fetchThirdParties, fetchViewSettings, resetQuickSearch } from '../../../commonActions/actions';
import SearchModalHeader from '../../../components/search-modal/SearchModalHeader';
import DialogModal from '../../../components/dialogs/DialogModal';
import DialogBody from '../../../components/dialogs/DialogBody';
import DialogFooter from '../../../components/dialogs/DialogFooter';
import SearchModalButtons from '../../../components/search-modal/SearchModalButtons';
import SearchModalButtonContainer from '../../../components/search-modal/SearchModalButtonContainer';
import MachineFilters from './MachineFilters';
import { clearFiltersAction, fetchMachineDetails, fetchMachineOptions, setFiltersAction, setMachinesSettings } from '../actions';
import { getEmptyMachineFilters } from 'reducers/machineReducer';
import { VIEW_FILTER } from 'constants/costPools';
import { mapOldOptionTypesToOptionsType } from '../utils';

const initFields = {
    searchString: '',
    code: '',
    machineType: [],
    model: '',
    productNumber: '',
    yearOfManufacture: '',
    deliveryNumber: '',
    machineRequirements: [],
    extraLocation: '',
    weight: '',
    dimensions: '',
    url: '',
    memo: '',
    name: '',
    thirdParties: {
        byType: {},
        allTypes: []
    },
    details: {
        byGroup: {},
        allGroups: []
    },
    costPools: {
        byGroup: {},
        allGroups: []
    },
    extraData: {
        byGroup: {},
        allGroups: []
    }
}

const { viewSettings } = settingsAPI.moduleData.machine;

const MachineFiltering = (props) => {
    const dispatch = useDispatch();
    const [optionGroups, setOptionGroups] = useState({
        machineType: {
            label: 'MSP_MACHINE_TYPE',
            options: []
        },
        machineRequirements: {
            label: 'MACHINE_REQUIREMENTS',
            options: []
        }
    });
    const filters = useSelector((state: State) => state.machines.currentFilters)
    const [fields, setFields] = useState({
        ...initFields,
        ...Object.keys(initFields).reduce((acc, cur) => {
            return cur !== 'thirdParties' && cur !== 'details' && cur !== 'costPools' && cur !== 'extraData'
                ? Object.assign(acc, { [cur]: filters[cur] || initFields[cur] })
                : acc
            }, {})
    });

    useEffect(() => {
        viewSettings.forEach(viewSetting => {
            dispatch(fetchViewSettings(viewSetting.groupType, viewSetting.actionType));
        });
        dispatch(fetchMachineOptions(['machinetypes, machinerequirements']))
        dispatch(fetchThirdParties());
        dispatch(fetchMachineDetails());
        dispatch(fetchCostPools());
    }, [dispatch]);

    // Set options for predefined fields
    const machineTypes = useSelector((state: State) => state.machines.options.machineTypeOptions);
    const machineRequirements = useSelector((state: State) => state.machines.options.machineRequirementOptions);
    useEffect(() => {
        const machineTypeOptions = mapOldOptionTypesToOptionsType(machineTypes);
        const machineRequirementOptions = mapOldOptionTypesToOptionsType(machineRequirements);
        setOptionGroups((optionGroups) => ({
            ...optionGroups,
            machineType: {
                ...optionGroups.machineType,
                options: machineTypeOptions
            },
            machineRequirements: {
                ...optionGroups.machineRequirements,
                options: machineRequirementOptions
            }
        }))
    }, [machineTypes, machineRequirements])

    // Set third party fields and third party options
    const thirdParties = useSelector((state: State) => state.settings.thirdParties);
    useEffect(() => {
        let byType = {};
        let thirdPartyOptions = {};
        thirdParties.allTypes.forEach(type => {
            Object.assign(byType, { [type.typeId.toString()]: filters.thirdParties[type.typeId] || [] });
            Object.assign(
                thirdPartyOptions,
                {
                    [type.typeId.toString()]: {
                        label: type.name,
                        options: thirdParties.byType?.[type.typeId].map(i => ({ value: i.id, label: i.name })) || []
                    }
                }
            );
        });

        setFields(f => ({
            ...f,
            thirdParties: {
                byType,
                allTypes: thirdParties.allTypes.map(type => type.typeId.toString())
            }
        }));

        setOptionGroups(o => Object.assign({}, o, thirdPartyOptions));
    }, [thirdParties, filters]);

    // Set detail fields and detail options
    const details = useSelector((state: State) => state.machines.machineDetails);
    const machineSettings = useSelector((state: State) => state.machines.viewSettings.machine);
    useEffect(() => {
        let byGroup = {};
        let detailOptions = {};
        const findMachineSettingByGroup = (group) => machineSettings.find(setting => setting.field === group);
        details.allGroups.forEach(group => {
            if (!findMachineSettingByGroup(group)) return;
            Object.assign(byGroup, { [group]: filters.details[group] || [] });
            Object.assign(
                detailOptions,
                {
                    [group]: {
                        label: findMachineSettingByGroup(group).translationKey,
                        options: details.byGroup[group].map(i => ({ value: i.id, label: i.value }))
                    }
                }
            );
        });

        setFields(f => ({
            ...f,
            details: {
                byGroup,
                allGroups: details.allGroups.filter(group => findMachineSettingByGroup(group))
            }
        }));

        setOptionGroups(o => Object.assign({}, o, detailOptions));
    }, [details, machineSettings, filters]);

    
    const costPools = useSelector((state: State) => state.costpools);
    useEffect(() => {
        let byGroup = {};
        let costPoolOptions = {};
        const parseCostPoolGroup = (group: number) => `costpoolgroup_${group}`;
        const findMachineSettingByGroup = (group: number) => machineSettings.find(setting => setting.field === parseCostPoolGroup(group));
        costPools.groups.forEach(group => {
            if (!findMachineSettingByGroup(group)) return;
            Object.assign(byGroup, { [parseCostPoolGroup(group)]: filters.costPools[parseCostPoolGroup(group)] || [] });
            Object.assign(
                costPoolOptions,
                {
                    [parseCostPoolGroup(group)]: {
                        label: findMachineSettingByGroup(group).translationKey,
                        options: costPools.byGroup[group]
                            .filter(i => i.viewFilter.some(j => j === VIEW_FILTER.MACHINE))
                            .map(i => ({ value: i.id, label: i.translationKey }))
                    }
                }
            );
        });

        setFields(f => ({
            ...f,
            costPools: {
                byGroup,
                allGroups: costPools.groups.filter(group => findMachineSettingByGroup(group)).map(group => parseCostPoolGroup(group))
            }
        }));

        setOptionGroups(o => Object.assign({}, o, costPoolOptions));
    }, [costPools, machineSettings, filters]);

    useEffect(() => {
        const extraDataFields = machineSettings.filter(i => i.type === 'extradata');
        setFields(f => ({
            ...f,
            extraData: {
                byGroup: extraDataFields.reduce((acc, cur) => Object.assign(acc, { [cur.field]: filters.extraData[cur.field] || '' }), {}),
                allGroups: extraDataFields.map(i => i.field)
            }
        }))
    }, [machineSettings, filters])

    useEffect(() => {
        /** Reset fields if the state of filters in store are changed outside this component.
         * For example when default search is set on */
        if (JSON.stringify(filters) === JSON.stringify(getEmptyMachineFilters())) {
            resetFields();
        }
    }, [filters]);

    const mapFilters = () => {
        let newFilters = {};
        Object.keys(fields).forEach(field => {
            let value = null;
            if ('thirdParties' === field) {
                value = fields.thirdParties.allTypes
                    .reduce((acc, cur) => Object.assign(acc, { [cur]: fields.thirdParties.byType[cur] }), {});
            } else if ('details' === field) {
                value = fields.details.allGroups
                    .reduce((acc, cur) => Object.assign(acc, { [cur]: fields.details.byGroup[cur] }), {});
            } else if ('costPools' === field) {
                value = fields.costPools.allGroups
                    .reduce((acc, cur) => Object.assign(acc, { [cur]: fields.costPools.byGroup[cur] }), {});
            } else if ('extraData' === field) {
                value = fields.extraData.allGroups
                    .reduce((acc, cur) => Object.assign(acc, { [cur]: fields.extraData.byGroup[cur] }), {});
            } else {
                value = fields[field];
            }
            Object.assign(newFilters, { [field]: value });
        });
        return newFilters;
    }

    
    const displaySettings = useSelector((state: State) => state.machines.settings);
    const applyFilters = () => {
        dispatch(resetQuickSearch('machines'));
        let settings = { useDefaultSearch: false, pageNumber: 1 }
        if (displaySettings.layoutType === 'treelayout') {
            Object.assign(settings, { layoutType: 'cardlayout' })
        }
        dispatch(setMachinesSettings(settings));
        const newFilters = mapFilters();
        dispatch(setFiltersAction(newFilters));
        props.closeDialog();
        props.callback?.();
    }

    const handleSelect = (value, actionMeta) => {
        // handle third party fields
        if (fields.thirdParties.allTypes.includes(actionMeta.name)) {
            setFields(f => ({
                ...f,
                thirdParties: {
                    ...f.thirdParties,
                    byType: {
                        ...f.thirdParties.byType,
                        [actionMeta.name]: value
                    }
                }
            }));
        // handle detail fields
        } else if (fields.details.allGroups.includes(actionMeta.name)) {
            setFields(f => ({
                ...f,
                details: {
                    ...f.details,
                    byGroup: {
                        ...f.details.byGroup,
                        [actionMeta.name]: value
                    }
                }
            }));
        // handle costPool fields
        } else if (fields.costPools.allGroups.includes(actionMeta.name)) {
            setFields(f => ({
                ...f,
                costPools: {
                    ...f.costPools,
                    byGroup: {
                        ...f.costPools.byGroup,
                        [actionMeta.name]: value
                    }
                }
            }));
        // handle other select input fields
        } else {
            setFields(f => ({ ...f, [actionMeta.name]: value }));
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFields(f => ({ ...f, [name]: value }));
    }

    const handleExtraData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFields(f => ({ ...f, extraData: { ...f.extraData, byGroup: { ...f.extraData.byGroup, [name]: value } } }));
    }

    const resetFields = () => {
        /**
         * Extra step is needed to clear the third party and the detail 
         * fields so the fields themselves don't get removed like they 
         * would if we were to use initFields alone to set empty fields.
         * */
        let byType = {};
        fields.thirdParties.allTypes.forEach(type => {
            Object.assign(byType, { [type]: [] });
        })

        let detailsByGroup = {};
        fields.details.allGroups.forEach(group => {
            Object.assign(detailsByGroup, { [group]: [] });
        })

        let costPoolsByGroup = {};
        fields.costPools.allGroups.forEach(group => {
            Object.assign(costPoolsByGroup, { [group]: [] });
        })

        let extraDataByGroup = {};
        fields.extraData.allGroups.forEach(group => {
            Object.assign(extraDataByGroup, { [group]: '' });
        })
 
        setFields(f => ({
            ...initFields,
            thirdParties: {
                ...f.thirdParties,
                byType: byType
            },
            details: {
                ...f.details,
                byGroup: detailsByGroup
            },
            costPools: {
                ...f.costPools,
                byGroup: costPoolsByGroup
            },
            extraData: {
                ...f.extraData,
                byGroup: extraDataByGroup
            }
        }));
    } 

    const clearFilters = () => {
        resetFields();
        dispatch(setMachinesSettings({ useDefaultSearch: false, pageNumber: 1 }));
        dispatch(clearFiltersAction());
        dispatch(resetQuickSearch('machines'));
    }

    return (
        <DialogModal showDialog={props.dialogOpen} closeDialog={props.closeDialog}>
            <SearchModalHeader />
            <DialogBody>
                <MachineFilters
                    handleChange={handleChange}
                    handleSelect={handleSelect}
                    handleExtraData={handleExtraData}
                    fields={fields}
                    optionGroups={optionGroups}
                />
            </DialogBody>
            <DialogFooter>
                <SearchModalButtonContainer>
                    <SearchModalButtons
                        applyFilters={applyFilters}
                        clearFilters={clearFilters}
                    />
                </SearchModalButtonContainer>
            </DialogFooter>
        </DialogModal>
    )
}

export default MachineFiltering;