import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import i18n from '../../../translations/i18n';
import TextTruncate from 'react-text-truncate';
import ListTruncate from '../../../components/ListTruncate';

const OpmCardBody = (props: IProps) => {
    let history = useHistory();

    const handleClick = () => {
        const locationState = {
            opmMachine: props.machineId,
            opMaintenanceName: props.content.name
        }

        history.push({
            pathname: 'operatormaintenance/' + props.content.id,
            state: locationState
        });
    }

    return (
        <div onClick={handleClick} style={{ minHeight: '25px' }}>
            <Row className="margin-bottom-5">
                {props.content.procedures.length > 0 && <>
                    <Col xs={4} className="sub-header-light break-word">
                        <TextTruncate line={2}
                            truncateText="…"
                            text={i18n.t('PROCEDURES')}
                        />
                    </Col>
                    <Col xs={8} className="break-word">
                        <ListTruncate>
                            {props.content.procedures.map(i => i.caption)}
                        </ListTruncate>
                    </Col>
                </>}
            </Row>
        </div>
    );
}

interface IProps {
    content: IOperatorMaintenanceLite;
    machineId: number;
}

export default OpmCardBody
