﻿import React, { useState, useEffect } from 'react';
import i18n from '../translations/i18n';
import Moment from 'moment';
import { faArrowUp, faArrowDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noviAPI from './../api/noviAPI';
import { HandleError } from './HelperFunctions';
import { getDateTime } from 'utils';

const PanelContent = ({ contentType, name, details }) => {
    const isValid = detail => typeof detail !== 'undefined' && detail !== null;
    const type = contentType === 'wild_mat' 
        ? 'mat' 
        : (contentType === 'maintenance_doc' || contentType === 'm_doc')
            ? 'doc' 
            : contentType;

    const { purchaseOrder, sparePartId } = details;
    const [isExpanded, toggleExpand] = useState(false);
    const [whLinks, setLinks] = useState(null);

    useEffect(() => {
        if (whLinks === null && isExpanded) {

            if (sparePartId) {
                noviAPI.warehouseSparePartLinks.fetchSparePartWarehouseData(sparePartId)
                    .then(({ data }) => {
                        setLinks(data);
                    })
                    .catch(err => {
                        HandleError(err, "Fetch warehouse sparepart links")
                        setLinks([]);
                    })
            }
            else {
                setLinks([]);
            }

        }
    }, [isExpanded]);

    const WarehouseInfo = () => {
        if (whLinks == null) {
            return (
                <>
                    <div className="list-divider" />
                    <FontAwesomeIcon icon={faSpinner} pulse />
                </>
            );
        }
        else if (whLinks.length == 0) {
            return (
                <>
                    <div className="list-divider" />
                    <span style={{ fontStyle: "italic", color: "#777" }}>{i18n.t("NOT_IN_WAREHOUSE")}</span>
                </>
            );
        }
        else {
            return <div>
                {whLinks?.map((x, i) => {
                    return (
                        <div className={"sub-header"} key={i}>
                            <div className="list-divider" />
                            <div>{`${i18n.t("WAREHOUSE")}: ${x?.warehouse.name ?? "-"}`}</div>
                            <div>{`${i18n.t("AMOUNT")}: ${x?.amount ?? "-"}`}</div>
                            <div>{`${i18n.t("SHELF_LOCATION")}: ${x?.shelfLocation ?? "-"}`}</div>
                        </div>
                    );
                })}
            </div>
        }
    }

    // Calculate the list height for animation
    // Sub-header: 18px + 10px margin
    // Content: 65px + 10px margin each
    // Loader/empty list: 40px
    const listHeight = 28 + (whLinks?.length > 0 ? (75 * whLinks.length) : 40);

    const content = details && {
        'doc': <div>
            {isValid(details.type) && <p className="small no-margins">{i18n.t('TYPE')}: {details.type}</p>}
            {isValid(details.description) && <p className="small no-margins">{i18n.t('DESCRIPTION')}: {details.description}</p>}
        </div>,
        'mat': <div>
            <div className="inline-bullets">
                {isValid(details.amount) && <span className="small no-margins">
                    {i18n.t('AMOUNT')}: {details.amount}{details.type && ' ' + details.type}
                </span>}
                {isValid(details.takeAmount) && <span className="small no-margins">
                    {i18n.t('TAKEN_AMOUNT')}: {details.takeAmount}{details.type && ' ' + details.type}
                </span>}
            </div>
            {isValid(details.memo) && <p className="small no-margins">{i18n.t('MEMO')}: {details.memo}</p>}
            {purchaseOrder && isValid(purchaseOrder?.orderNumber) && <p className="small no-margins">{i18n.t('PURCHASE_ORDER')}: {purchaseOrder?.orderNumber}</p>}
            <div 
                style={{ maxHeight: isExpanded ? `${listHeight}px` : "28px" }} 
                className="expandWrapper"
            >
                <div className="sub-header action"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleExpand(!isExpanded);
                    }}
                >
                    {i18n.t("WAREHOUSE_BREAKDOWN")} 
                    <FontAwesomeIcon icon={isExpanded ? faArrowUp : faArrowDown} size="1x" className="left-margin-5"/>
                </div>
                <WarehouseInfo />
            </div>
        </div>,
        'workPermit': <div>
            <p className="small no-margins">
                {i18n.t('STATUS')}: {i18n.t("WORKPERMIT_STATUS" + (details.status ?? 0))}
            </p>
            <p className="small no-margins">
                {i18n.t('WORKER')}: {details.workPermitWorker?.length > 2 ? `${details.workPermitWorker.length} ${i18n.t("X_WORKERS")}` : details?.workPermitWorker?.map(i => i.name)?.join(", ")}
            </p>
        </div>,
        'hourcard': <div>
            <p className="small no-margins">
                {i18n.t('INPUT_DATE')}: {getDateTime(details?.inputDate ?? null)}
            </p>
            <p className="small no-margins">
                {i18n.t('PERSON')}: {details?.person?.name}
            </p>
            <p className="small no-margins">
                {i18n.t('AMOUNT')}: {details?.hours}
            </p>
            <p className="small no-margins">
                {i18n.t('SALARY_CATEGORY')}: {details?.salaryCategory?.name || ''}
            </p>
        </div>
    }

    return (
        <div>
            <p className="no-margins word-break">
                {name}
            </p>
            {details && content[type]}
        </div>
    );
}

export default PanelContent;
