import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import WorkPermitForm from '../../../../components/WorkPermitFrom';
import NavigationBar from '../../../navigation';
import i18n from '../../../../translations/i18n';
import settingsAPI from '../../../../config/settingsAPI';
import { addPermit, updatePermit } from '../actions';
import { ScrollToTop } from 'components/ScrollToTop';
import { mapPermitAddition, mapPermitUpdate } from '../utils';
import { HandleError, HasRight, isNullOrWhitespace } from 'components/HelperFunctions';
import { toast } from 'react-toastify';
import { UserRights } from 'constants/userRights';
import noviAPI from 'api/noviAPI';

type UrlParams = { workCardId?: string; permitId?: string; };

const moduleSettings = settingsAPI.moduleData.editpermit;

const EditPermit = () => {

    let history = useHistory();
    let location = useLocation();
    const dispatch = useDispatch();
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const userRights = useSelector((state: State) => state.settings.userRights);
    const userId = useSelector((state: State) => state.settings.userId);
    const { workCardId, permitId } = useParams<UrlParams>();
    const [submit, setSubmit] = useState(false);
    const [subview, toggleSubview] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [permitOwner, setPermitOwner] = useState(null);

    const getBackButtonConfig = () => {
        if (subview === true) {
            return {action:()=> backActions()}
        } else if (hasChanged === true) {
            return { action: history.goBack, params: {mainEditView: true}};
        }
        return null;
    }

    const getSubmitButton = () => { 
        if (subview === true) {
            return 'confirm';
        }
        return 'save';
    }

    const backActions = () => {
        toggleSubview(false);
        handleSubmit(null, null);
    }

    useEffect(() => {
        if (permitId) {
            noviAPI.workPermits.fetchLite(parseInt(permitId, 10))
                .then(({ data }) => {
                    setPermitOwner(data.personId);
                })
                .catch(error => {
                    console.log("[ ERROR ]", error);
                    HandleError(error, "Fetch work permit information");
                })
        }  
    }, [permitId]);

    const handleSubmit = useCallback((data, name) => {
        setSubmit(false);

        if (data === null || data.length === 0) {
            return;
        }

        // Check user's rights again, view could have been accessed from somewhere else than workcardview.
        if (permitId) {
            let hasRights = false;

            if (HasRight([UserRights.WorkPermitEdit], userRights)) {
                hasRights = true;
            } else if (HasRight([UserRights.WorkPermitEditOwn], userRights)) {       
                if (permitOwner === userId) {
                    hasRights = true;
                }
            }

            if (hasRights === false) {
                toast.error(i18n.t('ALERT_USER_NO_SAVE_RIGHTS'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                return;
            }
        }

        const fields = data.filter(item => item.field !== "grantor" && item.field !== "status"); // TODO: don't filter status in m.1.21

        // List checked subpermits and filter the subpermitfields that have a checked subpermit field
        let checkedSubpermits = [...fields];
        fields.forEach(field => {
            if (field.type === 'workpermit' && field.value === true){
                checkedSubpermits.push(field.id);
            }
        });

        const subsFiltered = fields.filter(item => item.parentWorkPermitFieldId === null || (checkedSubpermits !== undefined && checkedSubpermits.includes(item.parentWorkPermitFieldId)));

        //Separate single values from multifield data
        let singleValues = [...subsFiltered];
        subsFiltered.forEach(field => {
            if (field.typeOption === 'multi' && !isNullOrWhitespace(field.value)){
                field.value.forEach(value => {
                    const single = {...field, value: value, typeOption: null};
                    singleValues.push(single);
                })
            }
            if (field.type === 'worker' && !isNullOrWhitespace(field.value)){
                field.value.forEach(value => {
                    const single = {...field, value: value, type: null};
                    singleValues.push(single);
                })
            }
        });

        const filtered = singleValues.filter(item => {
            if(item.type !== "extradata" && (item.value === null || item.value.id === -1)){
                return false;
            } else if (item.typeOption === "multi"){
                return false;
            } else if (item.type === "worker"){
                return false;
            } else {return true;}
        })

        const structured = filtered.map(permit => {
            if (permit.type === "extradata" || permit.type === "status" || permit.type === "workpermit"){
                if (permit.typeOption === "checkbox" && permit.value === null) {
                    return {"id": permit.id, "value": false, "type": permit.type, "group": permit.field};
                }
                else {
                    if (permit.value === null){
                        return {"id": permit.id, "value": "", "type": permit.type, "group": permit.field};
                    }
                }
                return {"id": permit.id, "value": permit.value, "type": permit.type, "group": permit.field};
            }
            if (permit.type === "detail"){
                return {"id": permit.id, "intvalue": permit.value.value, "type": permit.type, "group": permit.field};
            }
            if (permit.field === "worker"){
                return {"id": permit.id, "intvalue": permit.value.value, "type": permit.field, "group": permit.field};
            }
            if (permit.type === "workcardvalue" || permit.type === "text" || permit.type === "number"){
                return {"id": permit.id, "value": permit.value, "type": permit.type, "group": permit.field};
            }
            return;
        });

        const redirectFn = history.replace;

        const wcId = parseInt(workCardId);

        if (permitId){
            dispatch(updatePermit(mapPermitUpdate(structured), wcId, permitId, redirectFn));
        } else {
            dispatch(addPermit(mapPermitAddition(structured, name, wcId, machineGroupId), wcId, redirectFn));
        }
        
    }, [dispatch, workCardId, history.replace, permitOwner]);

    const sceneData = {
        view: moduleSettings.name,
        title: workCardId + ' > ' + (permitId
            ? permitId
            : i18n.t('WORK_PERMIT_ADD')),
        location: location,
        history: history,
        backAction: getBackButtonConfig(),
        hasChanged: hasChanged
    }

    const viewAction = {
        icon: getSubmitButton(),
        label: '',
        clickFn: () => setSubmit(true),
        isActionFn: true,
        paClass: 'start-phase'
    }

    return (
        <div>
            <ScrollToTop />
            <NavigationBar
                currentView={sceneData}
                navHistory={history}
                viewAction={viewAction}
                popoverData={''}
            />
            <Container>
                <WorkPermitForm
                    submit={submit}
                    workCardId={parseInt(workCardId)}
                    onSubmit={handleSubmit}
                    subview={subview}
                    toggleSubview={toggleSubview}
                    setHasChanged={setHasChanged}
                    permitId={permitId}
                />
            </Container>
        </div>
    );
}

export default EditPermit;