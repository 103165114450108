import { UserRights } from 'constants/userRights';
import React from 'react';
import { CardGroup } from "react-bootstrap";
import LinkPanelContainer from "../../scenes/machines/components/LinkPanelContainer";
import i18n from "../../translations/i18n";

export const getDialogInfo = (type: string, item = null): { header: string; headerLabel: string; } => {
    const headerMap = {
        machine: {
            header: 'SELECT_ACTION_FOR_THE_MACHINE',
            headerLabel: item ? item.code ? item.code + ' / ' + item.name : item.name : ''
        },
        sparepart: {
            header: '',
            headerLabel: ''
        },
        dashboardAdd: {
            header: 'ADD_NEW_DASHBOARD',
            headerLabel: ''
        },
        dashboardEdit: {
            header: 'EDIT_DASHBOARD',
            headerLabel: ''
        },
        dashboardDelete: {
            header: 'DELETE_DASHBOARD',
            headerLabel: ''
        },
        actionFailed: {
            header: 'ACTION_FAILED',
            headerLabel: ''
        }
    }

    return headerMap[type];
}

export const getDialogContent = (type: string, item, hasRight: (settingName: string | string[]) => boolean) => {
    const dialogModalContent = {
        machine: (<CardGroup
            className="margin-top-15 novi-panel"
        >
            {hasRight(UserRights.MachineRegistryView) && (
                <LinkPanelContainer
                    title={i18n.t('GO_TO_MACHINE_VIEW')}
                    linkTo={'/machine/' + item.id}
                    hasView
                />
            )}
            {hasRight(UserRights.FaultNotice) && (
                <LinkPanelContainer
                    title={i18n.t('FAULT_NOTICE')}
                    linkTo="/workcard/new"
                    linkState={{ machine: item, cardType: 'newFaultNotice' }}
                    hasView
                />
            )}
            {hasRight(UserRights.WorkScheduleAdd) && (
                <LinkPanelContainer
                    title={i18n.t('NEW_WORKCARD')}
                    linkTo="/workcard/new"
                    linkState={{ machine: item, cardType: 'newWorkCard' }}
                    hasView
                />
            )}
        </CardGroup>)
    };

    return dialogModalContent[type]
}
