import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import SparePartView from './SparePartView';
import {
    fetchViewSettings,
    fetchDocumentMetadatas
} from './../../commonActions/actions';
import { fetchSparePartExtraDatas, fetchSparePartWarehouseData, deleteSparePart, fetchDocuments, deleteDocument, fetchAnnualSparePartConsumption, addDocument } from './actions';

const mapStateToProps = (state: State) => {
    return {
        viewSettings: state.warehouse.viewSettings ? state.warehouse.viewSettings.sparepart : null,
        settings: state.settings,
        extraDatas: state.warehouse.viewSettings.extraDatas,
        childData: { sparePartTakes: state.warehouse.sparePartConsumption.totalConsumption },
        sparePartWarehouseData: state.warehouse.sparePartWarehouseData,
        documents: state.documents.sparePart?.sparePartDocuments?.length > 0 ? state.documents.sparePart.sparePartDocuments : [],
        documentMetadatas: state.documents.metadatas
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchViewSettings,
    fetchSparePartExtraDatas,
    fetchDocuments,
    fetchSparePartWarehouseData,
    deleteSparePart,
    deleteDocument,
    addDocument,
    fetchAnnualSparePartConsumption,
    fetchDocumentMetadatas
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SparePartView);
