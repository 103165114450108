import * as types from '../../config/actionTypes';
import request from '../../api/interceptors';
import { HandleError, HasRight, Toaster, isNullOrWhitespace } from '../../components/HelperFunctions';
import {
    documentPayload,
    getTempId,
    documentTransformRequest,
} from '../work-schedule/work-card/actions';
import noviAPI from '../../api/noviAPI';
import { saveWorkListSettings, setFiltersAction as setWorkListFilters } from '../work-schedule/work-list/actions';
import { resetQuickSearch } from '../../commonActions/actions';
import { workListFilters } from '../../reducers/workcardsReducer';
import { LocationDescriptor } from 'history';
import { filtersToSearchParams } from './utils';
import i18n from 'translations/i18n';

export const fetchMachine = (id: number) => (
    dispatch => {
        dispatch({
            type: `${types.FETCH_MACHINE}_PENDING`,
        });
        return noviAPI.machines.fetch(id)
            .then(({ data }) => {
                dispatch({
                    type: `${types.FETCH_MACHINE}_FULFILLED`,
                    payload: {
                        machine: data
                    }
                });
                return data;
            }).catch(error => {
                HandleError(error, 'Fetch machine');
            })
    }
);

export const fetchMachines = () => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${types.FETCH_MACHINES}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            const filters = getState().machines.currentFilters;
            const settings = getState().machines.settings;

            let searchParams = new URLSearchParams();

            searchParams.append('PageSize', `${settings.pageSize ?? 5}`);
            searchParams.append('PageNumber', `${(settings.pageNumber ?? 1) - 1}`);
            searchParams.append('MachineGroupId', `${machineGroupId}`);

            let searchParamsObj = filtersToSearchParams(filters);
            Object.keys(searchParamsObj).forEach(key => {
                const searchParamValue = searchParamsObj[key];
                if (Array.isArray(searchParamValue)) {
                    searchParamValue.forEach(i => {
                        searchParams.append(key, i.toString())
                    })
                } else if (key === 'extraDatasWithGroup') {
                    let extraDatasWithGroup = {};
                    Object.keys(searchParamValue).forEach(extraDataKey => {
                        const extraData = searchParamValue[extraDataKey];
                        if (!isNullOrWhitespace(extraData)) {
                            extraDatasWithGroup[extraDataKey] = [extraData];
                            searchParams.append(key, JSON.stringify(extraDatasWithGroup))
                        }
                    })
                } else {
                    searchParams.append(key, searchParamValue)
                }
            })

            const { data } = await noviAPI.machines.search(searchParams);
            
            dispatch({
                type: `${types.FETCH_MACHINES}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch machines');

            dispatch({
                type: `${types.FETCH_MACHINES}_REJECTED`,
                payload: error
            })
        }
    }
);

type DefaultSearchParams = {
    pageNumber?: number;
    pageSize?: number;
    startIndex?: number;
    endIndex?: number;
}

export type DefaultSearch = (params?: DefaultSearchParams) => void;
export const machineDefaultSearch: DefaultSearch = (params = null) => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_MACHINES}_PENDING`,
        });
        try {
            const pageNumber = params?.pageNumber !== undefined ? params.pageNumber : 0;
            const pageSize = params?.pageSize !== undefined ? params.pageSize : 5;

            const defaultSearchParams = new URLSearchParams();
            defaultSearchParams.append('PageSize', pageSize.toString());
            defaultSearchParams.append('PageNumber', pageNumber.toString());
            defaultSearchParams.append('MachineGroupId', getState().settings.machineGroupId.toString());

            const { data } = await noviAPI.machines.defaultSearch(defaultSearchParams);
            dispatch({
                type: `${types.FETCH_MACHINES}_FULFILLED`,
                payload: {
                    filters: getState().machines.currentFilters,
                    ...data
                }
            });
        } catch (error) {
            HandleError(error, 'Default search machines');

            dispatch({
                type: `${types.FETCH_MACHINES}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchHierarchyMachines = (machineId = null, searchStr = '') => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_HIERARCHY_MACHINES}_PENDING`,
        });
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            const mId = machineId || '';
            let url = '/api/MachineGroups/' + machineGroupId + '/HierarchyItems/';

            if (searchStr) {
                url = url + 'Search?'

                if (mId) {
                    url = url + 'parentId=' + mId + '&searchString=' + searchStr;
                } else {
                    url = url + 'searchString=' + searchStr;
                }
            } else {
                url = url + '?parentId=' + mId;
            }

            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_HIERARCHY_MACHINES}_FULFILLED`,
                payload: data
            });
        } catch (error) {
            HandleError(error, 'Fetch machines');

            dispatch({
                type: `${types.FETCH_HIERARCHY_MACHINES}_REJECTED`,
                payload: error
            })
        }
    }
);

// Don't use this, it only fetches immediate child workcards instead of all child workcards
export const fetchWorkCardsByMachine = (machineId) => (
    async (dispatch, getState: () => State) => {
        dispatch({
            type: `${types.UPDATE_WORKCARDS}_PENDING`,
        });
        request.get('/api/Machines/' + machineId)
            .then(res => {
                const { machineGroupId, userId, statusTypes } = getState().settings;
                const machine = res.data;

                const machineIds = machine.childMachines
                    ? [machine.id, ...machine.childMachines.map(cm => cm.id)]
                    : [machine.id];

                const openWcStatuses = statusTypes
                    .filter(sType => (sType.statusValue < 4))
                    .map(sType => (sType.id));

                const url = '/api/WorkCards/search/combined';
                const reqData = {
                    "machineGroupId": machineGroupId,
                    "userId": userId,
                    "machineIds": machineIds,
                    "workStatuses": openWcStatuses
                };

                request.post(url, reqData).then(({ data }) => {
                    dispatch({
                        type: `${types.UPDATE_WORKCARDS}_FULFILLED`,
                        payload: {
                            data: data.results,
                            user: userId
                        }
                    });
                }).catch(error => {
                    HandleError(error, 'Fetch machine workcards');

                    dispatch({
                        type: `${types.UPDATE_WORKCARDS}_REJECTED`,
                        payload: error
                    })
                    console.log(error);
                });
            }).catch(error => {
                HandleError(error, 'Fetch machine workcards');
                console.log(error);
            });
    }
);

export const fetchMachineWorkCards = (machineId, params) => (
    async (dispatch, getState: () => State) => {

        dispatch({
            type: `${types.FETCH_MACHINE_WORKCARDS}_PENDING`,
        });

        noviAPI.workCards.searchCombined(params)
            .then(({ data }) => {

                const openWorkCards = [];
                const childMachineOpenWorkCards = [];

                /**
                 * TODO: Maybe implement a better fix later and possibly use searchWorkCardCards endpoint instead
                 * However currently we need to access data that isn't returned from card endpoint so just for now implement 
                 * a quick fix by manually building the cardData in front end (copy-paste from backend)
                 */
                data.results.forEach((wc: IWorkCard | IRouteWorkCard | IOperatorMaintenance | any) => {


                    // Normal workcard
                    if (wc.machine?.id) {
                        const cardData = {
                            id: wc.id,
                            code: wc.code,
                            daysLate: wc.daysLate,
                            faultDescription: wc.faultDescription,
                            isRouteMaintenanceWorkCard: wc.isRouteMaintenanceWorkCard,
                            isTimedOperatorMaintenanceWorkCard: wc.isTimedOperatorMaintenanceWorkCard,
                            machineId: wc.machine?.id,
                            machineLabel: `${wc.machine?.code} / ${wc.machine?.name}`,
                            phases: wc.phases,
                            procedure: wc.procedure,
                            statusLabel: wc.workStatus && `WORKSTATUS${wc.workStatus}`,
                            urgencyLabel: wc.urgency && `URGENCY${wc.urgency}`,
                            workCardType: wc.workCardType,
                            workTypeLabel: wc.details.find(d => d.group == "worktype")?.value,
                            workerGroups: wc.workerGroups,
                            workers: wc.workers,
                        };

                        if (wc.machine.id == machineId) {
                            openWorkCards.push(cardData);
                        }
                        else {
                            childMachineOpenWorkCards.push(cardData);
                        }
                    }

                    // Operator maintenance workcard
                    else if (wc['operatorMaintenance']?.machine) {
                        const cardData = {
                            id: wc.id,
                            workCanBegin: wc.workCanBegin,
                            operatorMaintenanceId: wc.operatorMaintenanceId,
                            machineGroupId: wc.machineGroupId,
                            operatorMaintenance: {
                                id: wc.operatorMaintenance.id,
                                description: wc.operatorMaintenance.description,
                                machineLabel: `${wc.operatorMaintenance.machine?.code} / ${wc.operatorMaintenance.machine?.name}`,
                                name: wc.operatorMaintenance.name,
                                procedure: wc.operatorMaintenance.procedure,
                                urgencyLabel: wc.operatorMaintenance.urgency && `URGENCY${wc.operatorMaintenance.urgency}`,
                            },
                            isTimedOperatorMaintenanceWorkCard: wc.isTimedOperatorMaintenanceWorkCard
                        };

                        if (wc['operatorMaintenance'].machine.id == machineId) {
                            openWorkCards.push(cardData);
                        }
                        else {
                            childMachineOpenWorkCards.push(cardData);
                        }
                    }

                    // Route maintenance workcard
                    else if (wc['routeMachines']) {
                        const cardData = {
                            id: wc.id,
                            faultDescription: wc.faultDescription,
                            urgencyLabel: wc.urgency && `URGENCY${wc.urgency}`,
                            workers: wc.workers.map(i => i.name),
                            workerGroups: wc.workerGroups.map(i => i.name),
                            routeMachines: wc.phases.map(i => i.machine?.name + " / " + i.machine?.code),
                            isRouteMaintenanceWorkCard: wc.isRouteMaintenanceWorkCard
                        };
                        if (wc.routeMachines.find(m => m.id == machineId)) {
                            openWorkCards.push(cardData);
                        }
                        else {
                            childMachineOpenWorkCards.push(cardData);
                        }
                    }

                    // Something else ?
                    else {
                        console.log("[ UNCAUGHT WORKCARD? ]", wc);
                    }
                });

                dispatch({
                    type: `${types.FETCH_MACHINE_WORKCARDS}_FULFILLED`,
                    payload: {
                        workCardsByMachine: openWorkCards,
                        workCardsByChildMachines: childMachineOpenWorkCards
                    }
                });
            })
            .catch(error => {
                HandleError(error, 'Fetch machine workcards');

                dispatch({
                    type: `${types.FETCH_MACHINE_WORKCARDS}_REJECTED`,
                    payload: error
                })
                console.log(error);
            });
    }
);

export const deleteMachine = id => (
    async dispatch => {
        try {
            const url = '/api/Machines/' + id;
            await request.delete(url);

            dispatch({
                type: types.DELETE_MACHINE,
                payload: { id: id }
            })
        } catch (error) {
            HandleError(error, 'Delete machine');
        }
    }
);

export type IRedirect = (location: LocationDescriptor<{ notificationMsg: string; }>) => void;

export const addMachine = (machine: IMachineAddition, extraDatas: IMachExtraData[], redirect: IRedirect) => (
    dispatch => {
        noviAPI.machines.add(machine)
            .then(response => {
                const { data: mId } = response;

                dispatch({
                    type: types.ADD_MACHINE,
                    payload: machine
                });

                if (extraDatas.length > 0) {
                    dispatch(updateMachineExtraDatas(extraDatas, mId));
                }

                const location = {
                    pathname: '/machines',
                    state: { notificationMsg: 'NEW_MACHINE_ADDED' }
                }

                redirect(location);
            })
            .catch(error => {
                HandleError(error, 'Add machine');
            });
    }
);

export const updateMachine = (machineId: number, machine: IMachineUpdate, extraDatas: IMachExtraData[], redirect: IRedirect) => (
    dispatch => {
        noviAPI.machines.update(machineId, machine)
            .then(() => {
                if (extraDatas.length > 0) {
                    dispatch(updateMachineExtraDatas(extraDatas, machineId));
                }

                const location = {
                    pathname: `/machine/${machineId}`,
                    state: { notificationMsg: 'MACHINE_SAVED' }
                }

                redirect(location);
            }).catch(error => {
                HandleError(error, 'Update machine');
            });
    }
);

export type AddMachineSparePart = (machineSparePart: IMachineSparePartAddition, redirect: IRedirect) => void;

export const addMachineSparePart: AddMachineSparePart = (machineSparePart, redirect) => (
    dispatch => {
        noviAPI.machineSpareParts.add(machineSparePart)
            .then(() => {

                dispatch({
                    type: types.ADD_MACHINE_SPARE_PART,
                    payload: machineSparePart
                });

                const location = {
                    pathname: `/machine/${machineSparePart.machineId}/spareparts`,
                    state: { notificationMsg: 'MACHINE_SPARE_PART_ADDED' }
                }

                redirect(location);
            }).catch(error => {
                HandleError(error, 'Add machine spare part');
            });
    }
);

export type IFetchMachineOptions = (arr?: string[]) => void;

export const fetchMachineOptions: IFetchMachineOptions = (arr = []) => (
    async (dispatch, getState: () => State) => {
        try {
            const { machineGroupId, userRights } = getState().settings;

            if (!machineGroupId) return;

            const machineTypeOptions = (arr.length === 0 || arr.includes('machinetypes'))
                ? (await noviAPI.machines.fetchTypes(machineGroupId)).data
                    .map(option => ({ id: option.id, label: option.name }))
                : getState().machines.options.machineTypeOptions;

            const thirdPartyOptions = (arr.length === 0 || arr.includes('thirdparties'))
                ? (await noviAPI.thirdParties.fetchAll()).data
                    .map(option => ({ id: option.id, label: option.name }))
                : getState().machines.options.thirdPartyOptions;


            let machineRequirementOptions = getState().machines.options.machineRequirementOptions;
            
            if (HasRight("thirdpartyregistryview", userRights) && (arr.length === 0 || arr.includes('machinerequirements'))) {
                machineRequirementOptions = (await noviAPI.machineRequirements.fetchAll()).data.results
                    .map(option => ({ id: option.id, label: option.value }));
            }

            const machineRatingOptions = (arr.length === 0 || arr.includes('machineratingss'))
                ? (await noviAPI.machines.fetchMachineRatings(machineGroupId)).data
                    .map(option => ({ id: option.id, label: option.value }))
                : getState().machines.options.ratingOptions;

            const personOptions = (arr.length === 0 || arr.includes('responsiblepeople'))
                ? (await noviAPI.responsiblePeople.fetchAll(machineGroupId, 'workcard')).data
                    .map(option => ({ id: option.id, label: option.name }))
                : getState().machines.options.personOptions;

            const payload: IMachineOptions = {
                machineTypeOptions: machineTypeOptions,
                thirdPartyOptions: thirdPartyOptions,
                machineRequirementOptions: machineRequirementOptions,
                ratingOptions: machineRatingOptions,
                personOptions: personOptions
            }

            dispatch({
                type: types.FETCH_MACHINE_OPTIONS,
                payload
            })
        } catch (error) {
            HandleError(error, 'Fetch machine options')
        }
    }
);

export const fetchMachineDetails = () => (
    async dispatch => {
        try {
            const response = await noviAPI.machines.fetchDetails();

            dispatch({
                type: types.FETCH_MACHINE_DETAILS,
                payload: response.data
            });

        } catch (error) {
            console.log(error);
            HandleError(error, 'Fetch machine details');
        }
    }
);

export const fetchMachineSpareParts = mId => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_MACHINE_SPARE_PARTS}_PENDING`,
        });
        try {
            const url = `/api/v2/Machines/${mId}/SpareParts`;
            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_MACHINE_SPARE_PARTS}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch machine spare parts')

            dispatch({
                type: `${types.FETCH_MACHINE_SPARE_PARTS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchMachineSparePartWarehouseLinks = (mId) => (
    async dispatch => {

        dispatch({
            type: `${types.FETCH_MACHINE_SPARE_PART_LINKS}_PENDING`,
        });

        try {
            let queryParams = new URLSearchParams();
            queryParams.append('MachineIds', mId);

            noviAPI.warehouseSparePartLinks.search(queryParams)
                .then(response => {
                    dispatch({
                        type: `${types.FETCH_MACHINE_SPARE_PART_LINKS}_FULFILLED`,
                        payload: response?.data?.results ?? []
                    })
                });

        } catch (error) {

            HandleError(error, 'Fetch machine spare part warehouse links')

            dispatch({
                type: `${types.FETCH_MACHINE_SPARE_PART_LINKS}_REJECTED`,
                payload: error
            })
            
        }
    }
);

export type UpdateMachineSparePart = (id: number, data: IMachineSparePartUpdate, machineId: number, redirect: IRedirect) => void;

export const updateMachineSparePart: UpdateMachineSparePart = (id, data, machineId, redirect) => (
    dispatch => {
        noviAPI.machineSpareParts.update(id, data)
            .then(() => {
                dispatch({
                    type: `${types.UPDATE_MACHINE_SPARE_PART}_FULFILLED`,
                    payload: { id, ...data }
                });

                const location = {
                    pathname: `/machine/${machineId}/spareparts`,
                    state: { notificationMsg: 'MACHINE_SPARE_PART_SAVED' }
                }

                redirect(location);
            })
            .catch(error => {
                HandleError(error, 'Update machine spare part')
            })
    }
);

export const fetchMachineExtraData = mId => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_MACHINE_EXTRA_DATA}_PENDING`,
        });

        if (!mId) {
            dispatch({
                type: `${types.FETCH_MACHINE_EXTRA_DATA}_FULFILLED`,
                payload: []
            })
        }
        else {
            noviAPI.machineExtraDatas.fetch(mId)
                .then(({ data }) => {
                    dispatch({
                        type: `${types.FETCH_MACHINE_EXTRA_DATA}_FULFILLED`,
                        payload: data
                    })
                })
                .catch(error => {
                    HandleError(error, 'Fetch machine extra data')

                    dispatch({
                        type: `${types.FETCH_MACHINE_EXTRA_DATA}_REJECTED`,
                        payload: error
                    });
                });
        }

    }
);

export const updateMachineExtraDatas = (extraDatas, mId) => {
    return async (dispatch, getState) => {
        extraDatas.forEach(extraData => {
            try {
                if (extraData.id) {
                    noviAPI.machineExtraDatas.update(mId, extraData.id, extraData);

                    dispatch({
                        type: types.UPDATE_M_EXTRA_DATA,
                        payload: {
                            data: extraData,
                            machineId: mId
                        }
                    });
                } else if (extraData.value || extraData.valueNumber) {
                    noviAPI.machineExtraDatas.add(mId, extraData);
                    dispatch({
                        type: types.ADD_M_EXTRA_DATA,
                        payload: {
                            data: extraData,
                            machineId: mId
                        }
                    });
                }
            } catch (error) {
                HandleError(error, 'Update machine extra datas');
            }
        });
    }
}

export const fetchMachineDocuments = id => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_MACHINE_DOCUMENTS}_PENDING`,
        });
        try {
            const url = `/api/v2/Machines/${id}/documents`;
            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_MACHINE_DOCUMENTS}_FULFILLED`,
                payload: {
                    data: data,
                    machineId: id
                }
            })
        } catch (error) {
            HandleError(error, 'Fetch machine documents')

            dispatch({
                type: `${types.FETCH_MACHINE_DOCUMENTS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchChildMachineDocuments = (id, pageNumber = 0, itemsPerPage = 5) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_CHILD_MACHINE_DOCUMENTS}_PENDING`,
        });
        try {
            const url = `/api/Machines/${id}/Machines/documents`;

            let queryParams = new URLSearchParams();
            queryParams.append('PageNumber', pageNumber.toString());
            queryParams.append('PageSize', itemsPerPage.toString());
            queryParams.append('groupByEntity', 'false');

            const { data } = await request.get(url, { params: queryParams });

            dispatch({
                type: `${types.FETCH_CHILD_MACHINE_DOCUMENTS}_FULFILLED`,
                payload: {
                    machineId: id,
                    data
                }
            })
        } catch (error) {
            HandleError(error, 'Fetch child machine documents')

            dispatch({
                type: `${types.FETCH_CHILD_MACHINE_DOCUMENTS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchMachineWorkCardDocuments = (id, pageNumber = 0, itemsPerPage = 5) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_MACHINE_WORKCARD_DOCUMENTS}_PENDING`,
        });
        try {
            const url = `/api/Machines/${id}/WorkCards/documents`;

            let queryParams = new URLSearchParams();
            queryParams.append('PageNumber', pageNumber.toString());
            queryParams.append('PageSize', itemsPerPage.toString());
            queryParams.append('groupByEntity', 'false');

            const { data } = await request.get(url, { params: queryParams });

            dispatch({
                type: `${types.FETCH_MACHINE_WORKCARD_DOCUMENTS}_FULFILLED`,
                payload: {
                    machineId: id,
                    data
                }
            })
        } catch (error) {
            HandleError(error, 'Fetch machine workcard documents')

            dispatch({
                type: `${types.FETCH_MACHINE_WORKCARD_DOCUMENTS}_REJECTED`,
                payload: error
            })
        }
    }
);

export type IAddMachineDocument = (
    document: IMachineLinkDocument | IMachineFileDocument
) => void;

export const addMachineDocument: IAddMachineDocument = document => {
    return async (dispatch, getState) => {
        try {
            const url = '/api/Documents';
            const { documents } = getState().documents.machine;
            const { machineId } = document;

            // create temporary id for new document
            const tempId = getTempId(documents);
            const payload = documentPayload(document, tempId);

            dispatch({
                type: types.ADD_MACHINE_DOCUMENT,
                payload,
                meta: {
                    offline: {
                        // the network action to execute
                        effect: {
                            method: 'post',
                            url,
                            data: document,
                            transformRequest: documentTransformRequest.concat(request.defaults.transformRequest),
                            headers: { 'Content-Type': 'multipart/form-data' }
                        },
                        // action to dispatch when effect succeeds
                        commit: { type: `${types.ADD_MACHINE_DOCUMENT}_COMMIT`, meta: { machineId, tempId } },
                        // action to dispatch if network action fails permanently
                        rollback: { type: `${types.ADD_MACHINE_DOCUMENT}_ROLLBACK`, meta: { machineId, tempId } }
                    }
                }
            })

            Toaster({ msg: 'DOCUMENT_ADDED', type: 'success' });

        } catch (error) {
            HandleError(error, 'Add document');
        }
    }
}

export const resetCurrentFilters = () => (
    dispatch => {
        dispatch({
            type: types.RESET_MACHINE_CURRENTFILTERS
        })
    }
)

// TODO: consider removing if...else and set toaster messages to redux store 
// using commit action when effect succeeds and using rollback when network action fails.
// However toaster would need changes to it too so it could read messages from redux store.
export const deleteMachineDocument = (id, mId) => {
    return (dispatch, getState) => {
        if (getState().offline.online) {
            // online 
            noviAPI.machines.deleteDocument(id)
                .then(() => {
                    dispatch({
                        type: types.DELETE_MACHINE_DOCUMENT,
                        payload: { id, mId }
                    });

                    Toaster({
                        msg: 'DOCUMENT_DELETED',
                        type: 'success'
                    });
                })
                .catch(error => {
                    HandleError(error, 'Delete document');
                });
        }
        else {
            // offline
            const url = `/api/Machines/documents/${id}`;
            dispatch({
                type: types.DELETE_MACHINE_DOCUMENT,
                payload: { id, mId },
                meta: {
                    offline: {
                        effect: { method: 'delete', url }
                    }
                }
            });
        }
    }
}

export const fetchHierarchyBreadCrumb = hierarchyItem => (
    async (dispatch, getState) => {
        dispatch({
            type: `${types.FETCH_HIERARCHY_BREADCRUMB}_PENDING`,
        })
        try {
            const { machineGroupId } = getState().settings;
            if (!machineGroupId) return;

            return noviAPI.hierarchyItems.fetchParents(machineGroupId, hierarchyItem.id)
                .then(({ data }) => {
                    let hierarchyItems = data;
                    hierarchyItems.push(hierarchyItem);

                    dispatch({
                        type: `${types.FETCH_HIERARCHY_BREADCRUMB}_FULFILLED`,
                        payload: hierarchyItems
                    });
                }).catch(error => {
                    HandleError(error, 'Fetch hierarchy breadcrumb');
                })
        } catch (error) {
            HandleError(error, 'Fetch hierarchy breadcrumb');

            dispatch({
                type: `${types.FETCH_HIERARCHY_BREADCRUMB}_REJECTED`,
                payload: error
            })
        }
    }
);

function machinePayloadData(machine, obj = {}, init = {}) {
    // Deconstruct values that need transforming
    const {
        extraLocation,
        parent,
        type,
        machineRequirements,
        ...rest
    } = machine;
    // Initialize payload data if work card is new and add values that doesn't need transforming
    const payload = { ...init, ...rest, ...obj };

    [{ extraLocationId: extraLocation }, { extraLocationText: extraLocation }, { parent }, { type }, { machineRequirements }]
        // Add deconstructed values to payload if they were submitted (=field exists in view settings) 
        // and transform their data into format required by the API
        .forEach(function (obj) {
            const newObj = transformMachineValue(obj);
            if (newObj !== null) Object.assign(payload, newObj);
        });

    return payload;
}

function machineRequestData(machine, obj = {}) {
    // Deconstruct values that need transforming
    const {
        extraLocation,
        type,
        parent,
        responsibilityPerson,
        thirdPartiesByTypes,
        rating,
        costPool,
        costPools,
        machineRequirements,
        ...rest
    } = machine;

    // Add values that doesn't need transforming into payload
    const payload = { ...rest, ...obj };

    // Add values that need transforming into the array
    [
        // Rename keys if they differ from their API counterparts: { newKey: oldKey }
        { extraLocationId: extraLocation },
        { extraLocationText: extraLocation },
        { machineTypeId: type },
        { parentId: parent },
        { responsibilityPersonId: responsibilityPerson },
        { thirdPartyIdsByTypeIds: thirdPartiesByTypes },
        { ratingId: rating },
        { costPool },
        { costPoolIds: costPools },
        { machineRequirementIds: machineRequirements }
    ]
        // Add deconstructed values to payload if they were submitted (=field exists in view settings) 
        // and transform their data into format required by the API
        .forEach(function (obj) {
            const newObj = transformMachineValue(obj);
            if (newObj !== null) Object.assign(payload, newObj);
        });
    return payload;
}

function transformMachineValue(obj) {
    const key = Object.keys(obj)[0];
    const value = obj[key];

    // Return null if value was not submitted
    if (value === null || value === undefined) return null;

    if (key === 'extraLocationId'
        || key === 'machineTypeId'
        || key === 'parentId'
    ) { return { [key]: value.id } }
    else if (key === 'parent') { return { [key]: value } }
    else if (key === 'extraLocationText') { return { [key]: value } }
    else if (key === 'responsibilityPersonId') { return { [key]: value.id } }
    else if (key === 'ratingId') { return { [key]: value.id } }
    else if (key === 'type') { return { [key]: { id: value.id, name: value.label } } }
    else if (key === 'costPool') { return { [key]: value.label } }
    else if (key === 'costPoolIds') {
        return {
            [key]: value.map(i => i.id)
        }
    }
    else if (key === 'thirdPartyIdsByTypeIds') {
        return {
            [key]: value.map(val =>
                ({ typeId: parseInt(val.type.id, 10), thirdPartyIds: val.thirdParties.map(tParty => (tParty.id)) })
            )
        }
    }
    else if (key === 'machineRequirementIds') {
        return {
            [key]: value.map(val => (val.id))
        }
    }
}

/**
 * Action for fetching machine history count
 *
 * @param machineId
 */
export const fetchWorkHistoryCount: FetchWorkHistoryCount = machineId => (
    async (dispatch, getState: () => State) => {
        try {
            const workStatuses = getState()?.settings?.noviConfigs?.MaintenanceGenReadyWorkStatuses?.split(",");
            let searchParams = new URLSearchParams();
            searchParams.append('machineGroupId', `${getState().settings.machineGroupId}`);
            searchParams.append('pageSize', '0');
            searchParams.append('MachineIds', machineId);
            
            workStatuses.forEach(status => {
                searchParams.append('workStatuses', status);
            })
            const { data } = await noviAPI.workCards.searchCombined(searchParams);

            dispatch({
                type: types.FETCH_WORK_HISTORY,
                payload: data.totalResultCount
            })
        } catch (error) {
            HandleError(error, 'Fetch work history count');
            dispatch({ type: `${types.FETCH_WORK_HISTORY}_REJECTED` });
        }
    }
);
export type FetchWorkHistoryCount = (machineId: string) => void;

/**
 * Action for setting a machine for the work history
 *
 * @param machine
 */
export const setWorkHistory: SetWorkHistory = machine => (
    (dispatch, getState: () => State) => {
        const isDefaultSearchActive = getState().workcards.settings.useDefaultSearch;
        const isQuickSearchActive = getState().navigation.activeQuickSearches.worklist !== null;

        const allStatuses = getState()?.settings?.statusTypes;
        const workStatuses = getState()?.settings?.noviConfigs?.MaintenanceGenReadyWorkStatuses?.split(",").map(s => { 
            const id = parseInt(s);
            const statusOption = allStatuses.find(x => x.id == id);
            return {
                value: id,
                label: i18n.t(statusOption?.label)
            }
        });

        const newFilters = Object.assign({}, workListFilters, { machineIds: [machine.id], workStatuses });

        if (isDefaultSearchActive) {
            dispatch(saveWorkListSettings({ useDefaultSearch: false }));
        }

        if (isQuickSearchActive) {
            dispatch(resetQuickSearch('worklist'));
        }

        dispatch(setWorkListFilters(newFilters));

        dispatch({
            type: types.SET_WORK_HISTORY,
            payload: machine
        });
    }
);
export type SetWorkHistory = (machine: Pick<IMachineLite, 'id' | 'code' | 'name'>) => void;

export const fetchMeasurements = (id: number) => (
    dispatch => {
        noviAPI.machines.fetchMeasurements(id).then(response => {
            dispatch({
                type: types.FETCH_MACHINE_MEASUREMENTS,
                payload: response.data
            })
        })
    }
)

export const removeMachineFromList = (machineId: number) => ({
    type: types.REMOVE_MACHINE_FROM_LIST,
    payload: { id: machineId }
})

export const setMachinesSettings = payload => ({
    type: 'SET_MACHINES_SETTINGS',
    payload
});

export const setPageNumberAction = pageNumber => setMachinesSettings({ pageNumber });

export const setFiltersAction = payload => ({
    type: 'SET_MACHINE_FILTERS',
    payload
});

export const clearFiltersAction = () => ({
    type: 'CLEAR_MACHINE_FILTERS'
});

export const fetchCounters = (mId) => (
    dispatch => {

        const searchParams = new URLSearchParams();
        searchParams.append("MachineIds", `${mId}`);

        return noviAPI.counters.search(searchParams)
            .then(({ data }) => {
                dispatch({
                    type: types.FETCH_MACHINE_COUNTERS,
                    payload: data.results
                });
            })
            .catch(err => {
                HandleError(err, "Fetch Machine Counters");
            })
    }
);

export const updateTypeSpecificExtraDatas = (typeSpecificExtraDatas, values) => (
    dispatch => {
        Object.entries(values).forEach(([id, value]) => {
            const oldValue = typeSpecificExtraDatas.find(x => x.id == id)?.value;
            const valueChanged = value != oldValue;
            
            if (valueChanged) {
                noviAPI.extraDatas.update(parseInt(id), { value })
                    .catch(error => {
                        HandleError(error, "Update extradata");
                    })
            }
        })
    }
);