import { isNullOrWhitespace } from "../../components/HelperFunctions";
import { VIEW_FILTER } from '../../constants/costPools';
import { HourCardStatusLabels, HourCardStatusEnum } from '../../constants/hourCards';
import i18n from '../../translations/i18n';
import noviAPI from '../../api/noviAPI';
import { getDateAndDayName, getDateTimeAndDayName, initInputDateTime, parsePayloadDateTime } from "utils";

/**
 * Returns translated hour card status.
 * 
 * @param hourCardStatus
 */
export const getStatusLabel = (hourCardStatus: number) => {
    switch (hourCardStatus) {
        case 1: {
            return i18n.t(HourCardStatusLabels.Reported)
        }
        case 2: {
            return i18n.t(HourCardStatusLabels.Approved)
        }
        case 3: {
            return i18n.t(HourCardStatusLabels.Invoiced)
        }
        case 4: {
            return i18n.t(HourCardStatusLabels.Paid)
        }
        default:
            return '';
    }
}

/**
 * Generates hour card table headers and fields
 * @param userSettings
 */
export const generateTableHeadersAndFields = (userSettings: IUserSetting[]) => {
    let headers = [];
    let fields = {};

    userSettings = userSettings
        .filter(setting => (setting.group === 'hourcardregistry'))
        .sort((a, b) => a['tabOrder'] - b['tabOrder']);

    userSettings.forEach(setting => {
        fields[setting.field] = { enabled: true, colOrder: setting.tabOrder, type: setting.type };
        if (setting.field) {
            // Skip generating field for inputdayofweek because the data is included in inputDate field.
            if (setting.field !== 'inputdayofweek') {
                headers.push({
                    label: i18n.t(setting.label),
                    width: '150px'
                });
            }
        }
    });

    return { headers: headers, fields: fields }
}

/**
 * Generates hour card table rows
 * @param hourCards
 * @param fields
 */
export const generateTableRows = (hourCards, fields) => {
    let rows = []

    hourCards?.results.forEach(hourCardData => {
        let row = [];
        let key, keys = Object.keys(hourCardData);
        let n = keys.length;
        let hourCard = { ...hourCardData }
        while (n--) {
            key = keys[n];
            hourCard[key.toLowerCase()] = hourCardData[key];
        }

        Object.keys(fields).forEach(key => {
            // Skip generating field for inputdayofweek because the data is included in inputDate field.
            if (key !== 'inputdayofweek') {
                if (key === 'creator') {
                    row.push({ key: key, value: hourCard['person']?.name })
                } else if (key === 'inputdate') {
                    row.push({
                        key: key, value: fields[key]?.type === 'date' ? `${getDateAndDayName(hourCard[key])}`
                            : fields[key]?.type === 'datetime' ? `${getDateTimeAndDayName(hourCard[key])}` : ''})
                } else if (key === 'workcard') {
                    row.push({ key: key, value: hourCard[key]?.code });
                    // Detail handling
                } else if (key === 'hourcardroutemaintenance' || key === 'hourcardbillable' || key === 'hourcardcostpool') {
                    const detail = hourCard.details.find(i => i.group === key);
                    row.push({ key: key, value: i18n.t(detail?.value) })
                } else if (key === 'status') {
                    row.push({ key: key, value: getStatusLabel(hourCard[key]) })
                } else {
                    row.push({ key: key, value: hourCard[key], colOrder: fields[key].colOrder });
                }
            }

        });

        //Arguments for handleClick
        row.push({ key: 'args', value: { id: hourCard.id } });
        rows.push(row)
    })
    return rows;
}

/**
 * Initializes values using viewSettings.
 * 
 * @param viewSettings
 */
export const initValues = (viewSettings: IViewSetting[]) => {
    return viewSettings.reduce((prev, setting) => (
        { ...prev, [setting.field]: initFieldByFieldType(setting.type) }
    ), {});
}

/**
 * Initializes a single value by field type (viewSetting type).
 * 
 * @param type
 */
export const initFieldByFieldType = (type: string) => {
    if (type === 'status' || type === 'salarycategory' || type === 'costpoolgroup' || type === 'extradata') {
        return null;
    }
    if (type === 'detail') {
        return [];
    }
    if (type === 'date' || type === 'datetime') {
        return initInputDateTime();
    }
    return '';
}

/**
 * Checks that field has a value.
 * Returns true if field is valid or false if field is invalid.
 * 
 * Note: Does not check whether the field is required
 * 
 * @param value
 */
export const validateField = (value) => {
    if ((Array.isArray(value) && value.length < 1) || isNullOrWhitespace(value) || value == 0) {
        return false;
    }
    return true;
}


/**
 * Maps values from HourCardEdit to HourCardUpdate object.
 * 
 * @param values
*/
export const mapValuesToHourCardAddition = (values, viewSettings: IViewSetting[], machineGroupId: number): IHourCardAddition => {
    let newHourCard: IHourCardAddition = {
        personId: values.creator.id,
        machineGroupId: machineGroupId,
        salaryCategoryId: Number(values.salarycategory.value)
    };

    Object.keys(values).forEach(key => {
        const value = values[key]; // string | IOptionType | IOptionType[]
        const setting = viewSettings.find(i => i.field === key);

        // Return if value is empty
        if (isNullOrWhitespace(value) || (Array.isArray(value) && value.length < 1)) {
            return;
        }

        try {
            // Handle fields excluding details, extra data and cost pools
            switch (key) {
                case 'inputdate': { newHourCard.inputDate = parsePayloadDateTime(value); return; }
                case 'hours': { newHourCard.hours = !isNullOrWhitespace(value) ? Number(value) : 0; return; }
                case 'creatorcomment': { newHourCard.creatorComment = value; return; }
                case 'supervisorcomment': { newHourCard.supervisorComment = value; return; }
                case 'customercomment': { newHourCard.customerComment = value; return; }
                case 'status': { newHourCard.status = !isNullOrWhitespace(value.value) ? value.value : null; return; }
                case 'workcard': { newHourCard.workCardId = !isNullOrWhitespace(value) ? Number(value) : null; return; }
            }

            // Handle cost pools
            if (key.startsWith('costpoolgroup')) {
                // Return if "no selection" is selected
                if (isNullOrWhitespace(value.value)) { return; }
                // initialize costPoolIds before adding the first id to it
                if (typeof newHourCard.costPoolIds === 'undefined') {
                    newHourCard.costPoolIds = [];
                }
                newHourCard.costPoolIds = newHourCard.costPoolIds.concat(Number(value.value));
            } else if (setting) {
                // Handle details
                if (setting.type === 'detail' || setting.type === 'multidetail') {
                    // Return if "no selection" is selected
                    if (!value || (!Array.isArray(value) && isNullOrWhitespace(value.value))) { return; }
                    // initialize detailsIds before adding the first id to it
                    if (typeof newHourCard.detailIds === 'undefined') {
                        newHourCard.detailIds = [];
                    }
                    const ids = Array.isArray(value) ? value.map(i => Number(i.value)) : Number(value.value);
                    newHourCard.detailIds = newHourCard.detailIds.concat(ids);
                }

                // Handle extra data
                if (setting.type === 'extradata') {
                    // initialize costPoolIds before adding the first id to it
                    if (typeof newHourCard.extraDatas === 'undefined') {
                        newHourCard.extraDatas = [];
                    }
                    newHourCard.extraDatas = newHourCard.extraDatas.concat(value);
                }
            }
        } catch (error) {
            console.log(error); // Check console if value is not saving properly
        }
    });

    return newHourCard;
}


/**
 * Maps values from HourCardEdit to HourCardUpdate object.
 * 
 * @param values
*/
export const mapValuesToHourCardUpdate = (values, viewSettings: IViewSetting[]): IHourCardUpdate => {
    let hourCardUpdate: IHourCardUpdate = {};

    Object.keys(values).forEach(key => {
        const value = values[key]; // string | IOptionType | IOptionType[]
        const setting = viewSettings.find(i => i.field === key);

        if (!setting || setting.type === 'label') {
            return;
        }

        try {
            // Handle fields excluding details, extra data and cost pools
            switch (key) {
                case 'inputdate': { hourCardUpdate.inputDate = value; return; }
                case 'hours': { hourCardUpdate.hours = !isNullOrWhitespace(value) ? Number(value) : 0; return; }
                case 'creatorcomment': { hourCardUpdate.creatorComment = value; return; }
                case 'supervisorcomment': { hourCardUpdate.supervisorComment = value; return; }
                case 'customercomment': { hourCardUpdate.customerComment = value; return; }
                case 'status': { hourCardUpdate.status = value && !isNullOrWhitespace(value.value) ? Number(value.value) : null; return; }
                case 'creator': { hourCardUpdate.personId = !isNullOrWhitespace(value) ? value.id : null; return; }
                case 'approver': { hourCardUpdate.approverId = value; return; }
                case 'billingapprover': { hourCardUpdate.billingApproverId = value; return; }
                case 'paymentapprover': { hourCardUpdate.paymentApproverId = value; return; }
                case 'salarycategory': { hourCardUpdate.salaryCategoryId = value && !isNullOrWhitespace(value.value) ? Number(value.value) : null; return; }
                case 'workcard': { hourCardUpdate.workCardId = !isNullOrWhitespace(value) ? Number(value) : null; return; }
            }

            // Handle cost pools
            if (setting.type === 'costpoolgroup') {
                // Return if the field is left completely empty or "no selection" is selected
                if (!value || isNullOrWhitespace(value.value)) { return; }
                // initialize costPoolIds before adding the first id to it
                if (typeof hourCardUpdate.costPoolIds === 'undefined') {
                    hourCardUpdate.costPoolIds = [];
                }
                hourCardUpdate.costPoolIds = hourCardUpdate.costPoolIds.concat(Number(value.value));
            }

            // Handle details
            if (setting.type === 'detail' || setting.type === 'multidetail') {
                // Return if the field is left completely empty or "no selection" is selected
                if (!value || (!Array.isArray(value) && isNullOrWhitespace(value.value))) { return; }
                // initialize detailsIds before adding the first id to it
                if (typeof hourCardUpdate.detailIds === 'undefined') {
                    hourCardUpdate.detailIds = [];
                }
                const ids = Array.isArray(value) ? value.map(i => Number(i.value)) : Number(value.value);
                hourCardUpdate.detailIds = hourCardUpdate.detailIds.concat(ids);
            }

            // Handle extra data
            if (setting.type === 'extradata') {
                // Return if extra data doesn't exist yet and it's field is left empty
                if (isNullOrWhitespace(value)) { return; }
                // initialize costPoolIds before adding the first id to it
                if (typeof hourCardUpdate.extraDatas === 'undefined') {
                    hourCardUpdate.extraDatas = [];
                }
                hourCardUpdate.extraDatas = hourCardUpdate.extraDatas.concat(value);
            }

        } catch (error) {
            console.log(error); // Check console if value is not saving properly
        }
    });

    return hourCardUpdate;
}

export type OptionGroup = { [name: string]: IOptionType[]; }

/**
 * Maps cost pools to cost pool options
 * Returns an object with cost pool options grouped by cost pool group ID
 * 
 * @param costPools
 * @param hourCardCostPools
 */
export const mapCostPoolsToCostPoolOptions = (costPools: ICostPool[], hourCardCostPools: ICostPoolLite[]): OptionGroup => {
    let costPoolOptions: OptionGroup = {};
    const costPoolList = costPools
        .filter(hourCardCostPoolViewFilter)
        .filter(i => archivedCostPoolFilter(i, hourCardCostPools));

    for (const costPool of costPoolList) {
        // initialize costPoolOptions[costPool.costPoolGroupId] before adding options to it
        if (typeof costPoolOptions[costPool.costPoolGroupId] === 'undefined') {
            Object.assign(costPoolOptions, { [costPool.costPoolGroupId]: [] });
        }
        costPoolOptions[costPool.costPoolGroupId].push({ value: `${costPool.id}`, label: costPool.translationKey })
    }

    return costPoolOptions;
}

/**
 * Returns true if the cost pool is restricted to hour card view or it is not restricted to any view.
 * 
 * @param costPool
 */
const hourCardCostPoolViewFilter = (costPool: ICostPool) => {
    return costPool.viewFilter.length < 1 || costPool.viewFilter.includes(VIEW_FILTER.HOUR_CARD)
}

/**
 * Returns true if cost pool is unarchived. Also returns true if archived cost pool matches with any of the hour card cost pools.
 * 
 * @param costPool
 * @param hourCardCostPools
 */
export const archivedCostPoolFilter = (costPool: ICostPool, hourCardCostPools: ICostPoolLite[]) => {
    return !costPool.archived || hourCardCostPools.find(i => i.id === costPool.id)
}

/**
 * Maps hour card details to hour card detail options
 * Returns an object with hour card detail options grouped by detail groups
 * 
 * @param hourCardDetails
 */
export const mapHourCardDetailsToHourCardDetailOptions = (hourCardDetails: IHourCardDetail[]): OptionGroup => {
    const allGroups = hourCardDetails.reduce((prev, detail) => {
        if (prev.indexOf(detail.group) > 0) {
            return prev;
        }
        return prev.concat(detail.group);
    }, []);

    return allGroups.reduce((prev, group) => (
        Object.assign(prev, {
            [group]: hourCardDetails.filter(i => i.group === group)
                .map(i => ({ value: `${i.id}`, label: i.value }))
        })
    ), {});
}

/**
 * Maps salary categories to salary category options
 * Takes an array of { id: number; name string; isWorkHours: boolean; } and returns it as an array of { value: string; label: string; }
 * 
 * @param salaryCategories
 */
export const mapSalaryCategoriesToSalaryCategoryOptions = (salaryCategories: ISalaryCategoryLite[]): IOptionType[] => {
    let salaryCategoryOptions: IOptionType[] = [];

    for (const salaryCategory of salaryCategories) {
        salaryCategoryOptions.push({ value: `${salaryCategory.id}`, label: salaryCategory.name });
    }

    return salaryCategoryOptions;
}

/**
 * Maps PersonGroupPeople to person group options
 * Takes an array of { personGroup: IPersonGroupLite; people: IPersonLite[]; } and returns it as an array of { value: string; label: string; }
 * 
 * @param personGroupPeople
 */
export const mapPersonGroupPeopleToPersonGroupOptions = (personGroupPeople: IPersonGroupPeople[]): IOptionType[] => {
    let personGroupOptions: IOptionType[] = [];

    for (const personGroupPerson of personGroupPeople) {
        // Skip possible duplicates.
        if (!personGroupOptions.some(i => (i.value === personGroupPerson.personGroup.id.toString()))) {
            personGroupOptions.push({ value: `${personGroupPerson.personGroup.id}`, label: personGroupPerson.personGroup.name });
        }
    }
    return personGroupOptions;
}

/**
 * Maps PersonGroupPeople to person options
 * Returns an object with person options grouped by person group ID
 * 
 * @param personGroupPeople
 */
export const mapPersonGroupPeopleToPersonOptions = (personGroupPeople: IPersonGroupPeople[]): OptionGroup => {
    let personOptions: OptionGroup = {};

    for (const personGroupPerson of personGroupPeople) {
        const personGroupId = personGroupPerson.personGroup.id;

        // initialize personOptions[personGroupId] before adding options to it
        if (typeof personOptions[personGroupId] === 'undefined') {
            Object.assign(personOptions, { [personGroupId]: [] });
        }

        personOptions[personGroupId] = personOptions[personGroupId].concat(personGroupPerson.people.map(i => ({ value: `${i.id}`, label: i.name })));
    }

    return personOptions;
}

/**
 * Maps enum to options
 * @param param
 */
export const mapEnumToOptions = (param) => Object.values(param).map((value, i) => ({ value: `${i + 1}`, label: i18n.t(value.toString()) }));

/**
 * Returns allowed hour card status options
 * @param hourCard
 * @param settings
 */
export const getAllowedStatusOptions = (hourCard: IHourCard, settings: ISettingsState, isUserSupervisor: boolean) => {
    let statusOptions: IOptionType[] = [];
    if (hourCard.status === HourCardStatusEnum.Reported) {
        statusOptions.push({ value: `${HourCardStatusEnum.Reported}`, label: getStatusLabel(HourCardStatusEnum.Reported) });
        if (hourCard.person.id === Number(settings.userId)) {
            if (isUserSupervisor && settings.noviConfigs.CanAcceptOwnHourCards === 'True') {
                statusOptions.push({ value: `${HourCardStatusEnum.Approved}`, label: getStatusLabel(HourCardStatusEnum.Approved) });
            }
        }
        else if (isUserSupervisor) {
            statusOptions.push({ value: `${HourCardStatusEnum.Approved}`, label: getStatusLabel(HourCardStatusEnum.Approved) });
        }
    }
	else if (hourCard.status === HourCardStatusEnum.Approved) {
        statusOptions.push({ value: `${HourCardStatusEnum.Reported}`, label: getStatusLabel(HourCardStatusEnum.Reported) });
        statusOptions.push({ value: `${HourCardStatusEnum.Approved}`, label: getStatusLabel(HourCardStatusEnum.Approved) });
    }
    return statusOptions;
}

/**
 * Takes IHourCardFilters object (and optional predefined URLSearchParams) and returns search parameters for hour card search
 * */
export const hourCardSearchParams = (filters: IHourCardFilters, userId: number, searchParams: URLSearchParams = new URLSearchParams()) => {
    Object.keys(filters)
        .forEach(key => {
            const filterValue = filters[key];

            // Return if field is left empty
            if (!filterValue) {
                return;
            }
            if (key === 'searchBegin') {
                searchParams.append('SearchBegin', filterValue)
            }
            if (key === 'searchEnd') {
                searchParams.append('SearchEnd', filterValue)
            }

            // Return if "no selection" option has been selected
            if (isNullOrWhitespace(filterValue.value)) {
                return;
            }
            if (key === 'salaryCategory') {
                searchParams.append('SalaryCategoryIds', filterValue.value)
            }
            if (key === 'group') {
                if (filterValue.value === 'own') {
                    searchParams.append('PersonIds', `${userId}`)
                    return;
                }
                searchParams.append('PersonGroupIds', filterValue.value)
            }
            if (key === 'person') {
                searchParams.append('PersonIds', filterValue.value)
            }
            if (key === 'status') {
                searchParams.append('Statuses', filterValue.value)
            }
        });

    return searchParams;
}

/**
 * Takes IHourCardFilters object and returns the number of active filters
 * @param filters
 */
export const countActiveHourCardFilters = (filters: IHourCardFilters) => {
    let count = 0;
    Object.keys(filters)
        .forEach(key => {
            const filterValue = filters[key];
            if (isNullOrWhitespace(filterValue)) {
                return;
            }
            // searchBegin or searchEnd filters
            if (typeof filterValue === 'string') {
                count++;
                return;
            }
            // Select field filters
            if (isNullOrWhitespace(filterValue.value)) {
                return;
            }
            count++;
        });
    return count;
}

export const initialFilters: IHourCardFilters = {
    salaryCategory: null,
    person: null,
    status: null,
    group: { value: 'own', label: i18n.t('OWN') },
    searchBegin: initInputDateTime(date => date.subtract(14, 'days')),
    searchEnd: initInputDateTime()
}

/**
 * Returns salary categories by person
 * @param userId
 * @param machineGroupId
 */
export const getSalaryCategoriesByPerson = async (userId: number, machineGroupId: number) => {
    let salaryCategories: ISalaryCategoryLite[] = [];
    try {
        salaryCategories = (await noviAPI.salaryCategories.fetchAllByUser(userId, machineGroupId)).data;
    } catch (error) {
        console.log(error);
    }
    return salaryCategories;
}

/**
 *
 * Returns salary categories by person group
 * @param personGroupId
 */
export const getSalaryCategoriesByPersonGroup = async (personGroupId: number) => {
    let salaryCategories: ISalaryCategoryLite[] = [];
    try {
        salaryCategories = (await noviAPI.salaryCategories.fetchAllByPersonGroup(personGroupId)).data;
    } catch (error) {
        console.log(error);
    }
    return salaryCategories;
}
