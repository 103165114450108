import React from 'react'
import { Accordion } from 'react-bootstrap';
import PanelContainer from '../../work-schedule/work-card/components/PanelContainer';
import i18n from 'i18next'
import MeasurementsDataTable from './MeasurementsDataTable';

type Props = {
    machineMeasurements: IMeasurementGroupWorkCard[];
}

const MeasurementsPanelGroup = ({ machineMeasurements }: Props) => {
    return (
        <div id="panel-group-wrapper" className="margin-top-15 novi-panel">
            <h2>{i18n.t('MEASUREMENTS').toUpperCase()}</h2>
            <Accordion
                id="panel-with-sub-panels"
                className="margin-top-15 novi-panel"
            >
                <PanelContainer
                    id={2}
                    title={i18n.t('MEASUREMENTS')}
                    itemCount={machineMeasurements.length}
                >
                    <MeasurementsDataTable machineMeasurements={machineMeasurements} />
                </PanelContainer>
            </Accordion>
        </div>
    );
}

export default MeasurementsPanelGroup
