import React, { useState } from 'react';
import MachineLinkForm from './MachineLinkForm';
import NavigationBar from '../../navigation';
import { GetTranslationKeyByProp } from '../../../components/HelperFunctions';
import i18n from '../../../translations/i18n';
import { useHistory, useLocation, useParams } from 'react-router-dom';

type LocationState = {
	machineId: string
}

const MachineLinkEdit = () => {
	let history = useHistory();
	let location = useLocation<LocationState>();
	const { machineLinkId } = useParams<UrlParams>();
    const [submit, setSubmit] = useState(false);

	const sceneData = {
		view: 'machinelink',
		title: i18n.t(GetTranslationKeyByProp('machinelinkedit')),
		location: location,
		history: history,
		backAction: { action: history.goBack }
	}

	const viewAction = {
		icon: 'save',
		label: '',
		clickFn: () => setSubmit(true),
		isActionFn: true,
		paClass: 'start-phase',
	}

	return (
		<div>
			<NavigationBar
				currentView={sceneData}
				viewAction={viewAction}
			/>
            <MachineLinkForm
				submit={submit}
				machineLinkId={parseInt(machineLinkId, 10)}
				machineId={parseInt(location.state.machineId, 10)}
            />
        </div>
    );
}

type UrlParams = { machineLinkId: string; };

export default MachineLinkEdit;
