import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, rootElementId='root-portal' }) => {
    const [container] = useState(() => {
      return document.createElement('div');
    });

    useEffect(() => {
        const root = document.getElementById(rootElementId);
        root.appendChild(container);
        return () => {
            root.removeChild(container);
        }
    }, []);

    return ReactDOM.createPortal(children, container);
}

export default Portal;