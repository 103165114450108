import React from 'react';

const SearchModalButtonContainer = (props: IProps) => {
    return (
        <div className="filters-button-container">
            {props.children}
        </div>
    );
}

interface IProps {
    children: React.ReactNode | React.ReactNode[];
}

export default SearchModalButtonContainer;