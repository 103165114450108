import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import noviAPI from '../../../../api/noviAPI';
import { Loader } from '../../../../components/Loader';
import FormElement from '../../../work-schedule/work-card/components/FormElement';
import { defaultFields, getArriveViewSettingsWithFixedTypes } from '../../utils';
import { RouteChildrenProps } from 'react-router-dom';
import SelectField from '../../../../components/form/SelectField';
import i18n from '../../../../translations/i18n';
import { GetTranslationKeyByProp, isNullOrWhitespace } from '../../../../components/HelperFunctions';
import NavigationBar from '../../../navigation';
import { arriveWarehouseSparePart } from '../../actions';
import { toast } from 'react-toastify';
import { getDate } from 'utils';

const WarehouseArrive = (props: IProps) => {
    // Hooks
    const [viewSettings, setViewSettings] = useState<IViewSetting[]>([]);
    const [values, setValues] = useState<Values>({
        warehouse: props.location.state?.warehouse && {
            value: `${props.location.state.warehouse.id}`,
            label: props.location.state.warehouse.label
        } || null,
        amount: '',
        price: '',
        shelfLocation: '',
        purchaseOrder: null,
        purchaseOrderOpenAmount: ''
    });
    const [sparePartName, setSparePartName] = useState('');
    const [options, setOptions] = useState<OptionGroup>({});
    const [invalidFields, setInvalidFields] = useState<string[]>([]);
    const [fetching, setFetching] = useState('pending');
    const dispatch = useDispatch();

    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const noviConfigs = useSelector((state: State) => state.settings.noviConfigs);
    const sparePartId = Number(props.match.params.sparePartId);

    const isWarehouseArrivalsOnlyFromOpenPurchaseOrdersEnabled = () => noviConfigs.WarehouseArrivalsOnlyFromOpenPurchaseOrders?.toLowerCase() === 'true';
    const isWarehouseArriveNoPriceEnabled = () => noviConfigs.WarehouseArriveNoPrice?.toLowerCase() === 'true';

    // Fetch ViewSettings
    useEffect(() => {
        // Set spare part name for the header
        noviAPI.spareParts.fetch(sparePartId)
            .then(response => { setSparePartName(response.data.name) })
            .catch(error => { console.log(error); })

        // Set view settings
        noviAPI.machineGroupSettings.fetch(machineGroupId, 'warehousearrive')
            .then(response => {
                let result = response.data;
                // Use defaults if there are no warehousearrive settings
                if (result.length === 0) {
                    result = defaultFields;
                }
                if (isWarehouseArriveNoPriceEnabled()) {
                    result = result.filter(i => i.field !== 'price');
                }
                if (!isWarehouseArrivalsOnlyFromOpenPurchaseOrdersEnabled()) {
                    result = result.filter(i => i.field !== 'purchaseOrder' && i.field !== 'purchaseOrderOpenAmount');
                }
                result = getArriveViewSettingsWithFixedTypes(result);
                setViewSettings(result.sort((a, b) => (a.tabOrder - b.tabOrder)));
                setFetching('fulfilled');
            })
            .then(async () => {
                // Set options
                try {
                    const warehouses = (await noviAPI.warehouses.fetchAllByMachineGroup(machineGroupId)).data;
                    setOptions(o => ({
                        ...o,
                        warehouse: warehouses.map(i => ({
                            value: `${i.id}`,
                            label: i.name
                        }))
                    }))
                    if (isWarehouseArrivalsOnlyFromOpenPurchaseOrdersEnabled()) {
                        const purchaseOrders = (await getPurchaseOrders()).results || [];
                        setOptions(o => ({
                            ...o,
                            purchaseOrder: purchaseOrders
                                .filter(i => calculatePurchaseOrderOpenAmount(i) > 0)
                                .map(i => ({
                                    value: `${i.id}`,
                                    label: `${i.orderer.name} / ${getDate(i.orderDate)} / ${i.orderNumber}`
                                }))
                        }))
                    }
                } catch {
                }
            })
    }, []);

    useEffect(() => {
        if (values.warehouse) {
            const warehouseId = Number(values.warehouse.value);
            noviAPI.warehouseSparePartLinks.fetchByWarehouseId(warehouseId, sparePartId)
                .then(response => {
                    const sparePartLink = response.data;
                    setValues(v => ({
                        ...v,
                        shelfLocation: sparePartLink.shelfLocation
                    }))
                })
                .catch(() => {
                    setValues(v => ({
                        ...v,
                        shelfLocation: ''
                    }))
                })
        }
    }, [values.warehouse])

    // when purchase purchase order is changed, calculate new purchaseOrderOpenAmount
    useEffect(() => {
        if (!options.purchaseOrder) { return };
        const purchaseOrderOpenAmount = async () => {
            try {
                const purchaseOrderId = Number(values.purchaseOrder.value);
                const result = await noviAPI.purchaseOrders.fetch(machineGroupId, purchaseOrderId);
                const purchaseOrder = result.data;
                const openAmount = calculatePurchaseOrderOpenAmount(purchaseOrder)
                const purchaseOrderItem = purchaseOrder?.orderRows.find(i => i.sparePart.id === sparePartId);
                const unitPrice = purchaseOrderItem?.unitPrice || '';

                setValues(v => ({
                    ...v,
                    purchaseOrderOpenAmount: openAmount,
                    price: unitPrice
                }));

                if (openAmount > 0) {
                    setInvalidFields(i => i.filter(str => str !== 'purchseOrderOpenAmount'));
                }
                if (!isNullOrWhitespace(unitPrice.toString())) {
                    setInvalidFields(i => i.filter(str => str !== 'price'));
                }
            } catch (e) {
                console.log(e);
            }
        }

        purchaseOrderOpenAmount();

    }, [values.purchaseOrder])

    const getPurchaseOrders = async () => {
        const params = new URLSearchParams();
        params.append('MachineGroupId', `${machineGroupId}`);
        params.append('SparePartIds', `${sparePartId}`);
        params.append('StatusIds', '4'); // 4 = Ordered
        params.append('StatusIds', '5'); // 5 = Partially delivered
        try {
            const purchaseOrders = (await noviAPI.purchaseOrders.search(params)).data;
            return purchaseOrders;
        } catch {
            return null;
        }
    }

    const calculatePurchaseOrderOpenAmount = (p: IPurchaseOrderSearchResult): number => {
        const purchaseOrderItems = p?.orderRows.filter(o => o.sparePart?.id === sparePartId) || null;
        let totalAmount = purchaseOrderItems?.map(i => i.amount).reduce((accumulator, currentValue) => (accumulator + currentValue));
        let totalArrivedAmount = purchaseOrderItems?.map(i => i.arrivedAmount).reduce((accumulator, currentValue) => (accumulator + currentValue));

        return purchaseOrderItems !== null ? totalAmount - totalArrivedAmount : 0;
    }

    const handleSelect = (value, actionMeta) => {
        setValues(v => ({ ...v, [actionMeta.name]: value }));
        setInvalidFields(i => i.filter(str => str !== actionMeta.name));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(v => ({ ...v, [name]: value }));
        setInvalidFields(i => i.filter(str => str !== name));
    }

    const isFormValid = () => {
        let array = [];
        viewSettings.forEach(setting => {
            if (setting.required) {
                if (isNullOrWhitespace(values[setting.field]) || (setting.field === 'warehouse' && values.warehouse?.value == "-1"))
                    array.push(setting.field);
            }
        })
        if (isWarehouseArrivalsOnlyFromOpenPurchaseOrdersEnabled()) {
            const { purchaseOrderOpenAmount, amount } = values;
            if (purchaseOrderOpenAmount < 1) array.push('purchaseOrderOpenAmount');
            else if (purchaseOrderOpenAmount < Number(amount)) array.push('amount');
        }
        setInvalidFields(array);
        return array.length === 0;
    }

    const createSparePartArriveData = (): ISparePartArrive => {
        const { amount, price, shelfLocation, purchaseOrder } = values;
        let sparePart: ISparePartArrive = {
            amount: Number(amount),
            shelfLocation: shelfLocation
        };
        if (isWarehouseArrivalsOnlyFromOpenPurchaseOrdersEnabled()) {
            sparePart.purchaseOrderId = purchaseOrder?.value ? Number(purchaseOrder.value) : null
        }
        if (!isWarehouseArriveNoPriceEnabled()) {
            sparePart.price = Number(price);
        }
        return sparePart;
    }

    const handleSubmit = () => {
        const warehouseId = values.warehouse ? Number(values.warehouse.value) : null;

        if (!isFormValid()) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            return;
        }

        const sparePart = createSparePartArriveData();
        const pathname = props.location.state?.warehouse ? '/warehouse' : null;

        const location = {
            pathname: pathname || `/sparepart/${sparePartId}`,
            state: { notificationMsg: 'MATERIAL_SAVED' }
        }

        dispatch(arriveWarehouseSparePart(warehouseId, sparePartId, sparePart, () => props.history.replace(location)));
    }

    const sceneData = {
        view: 'warehouse',
        title: sparePartName,
        subPhaseLabel: i18n.t(GetTranslationKeyByProp('arrive')).toLowerCase(),
        location: props.location,
        history: props.history,
        backAction: { action: props.history.goBack }
    }

    const viewAction = {
        icon: 'save',
        label: '',
        clickFn: handleSubmit,
        isActionFn: true,
        paClass: 'start-phase',
    }

    return (
        <div>
            <NavigationBar
                currentView={sceneData}
                navHistory={props.history}
                viewAction={viewAction}
                popoverData={''}
            />
            <div className="work-card-view">
                <Container>
                    <div className="form-table-container bottom-nav-space margin-top-15">
                        <Loader status={fetching} />
                        {fetching === 'fulfilled' && <form>
                            {viewSettings.map(viewSetting => <div key={viewSetting.id} className={invalidFields.includes(viewSetting.field) ? 'invalid-field' : ''}>
                                {viewSetting.type === 'select'
                                    ? <SelectField
                                        key={viewSetting.id}
                                        name={viewSetting.field}
                                        label={i18n.t(viewSetting.translationKey)}
                                        value={values[viewSetting.field] || ''}
                                        onChange={handleSelect}
                                        options={options[viewSetting.field] || []}
                                        required={viewSetting.required}
                                        noDefaultOption
                                    />
                                    : <FormElement
                                        key={viewSetting.id}
                                        name={viewSetting.field}
                                        type={viewSetting.type}
                                        label={viewSetting.translationKey}
                                        value={values[viewSetting.field] || ''}
                                        required={viewSetting.required}
                                        onChange={handleChange}
                                    />}
                            </div>)}
                        </form>}
                    </div>
                </Container>
            </div>
        </div>
    )
}

type IProps = RouteChildrenProps<MatchParams, LocationState>;
type MatchParams = { sparePartId: string; }
type LocationState = { warehouse?: { id: number; label: string; }; }

type Values = {
    warehouse: IOptionType;
    amount: React.ReactText;
    price: React.ReactText;
    purchaseOrder: IOptionType;
    shelfLocation: string;
    purchaseOrderOpenAmount: React.ReactText;
}

type OptionGroup = { [name: string]: IOptionType[]; }

export default WarehouseArrive;
