import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import i18n from '../translations/i18n';

const ToggleButtonGroupContainer = ({ label, value, onChange, options }) => {
    return <div className="toggle-button-group-container">
        <label>{label}</label>
        <Tabs activeKey={value}
            onSelect={onChange}
        >
            {options.map(o => <Tab key={o.id} title={i18n.t(o.name)} eventKey={o.id}></Tab>)}
        </Tabs>
    </div>
};

export default ToggleButtonGroupContainer;