import React from 'react';
import i18n from '../translations/i18n';

const redirectToDesktopSite = () => {
    // use desktop url from config if it's configured
    if (window['runConfig'].desktopURL) {
        let url = new URL(window['runConfig'].desktopURL);
        window.location.href = url.href;
    } else {
        let url = new URL(window.location.href);

        if (url.hostname.indexOf('m.') === 0) {
            const array = url.href.split('m.');
            url.href = array[0] + array[1];
        }
        // pathname to desktop site
        url.pathname = '/desk';
        window.location.href = url.href;
    }
}

const RedirectToDesktopButton = () => (
    <button className="desktop-button" onClick={redirectToDesktopSite}>
        {i18n.t('GO_TO_DESKTOP')}
    </button>
);

export default RedirectToDesktopButton;
