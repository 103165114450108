import React from 'react';
import PanelContainer from './components/PanelContainer';
import LinkPanelContainer from '../../machines/components/LinkPanelContainer';
import { Link } from 'react-router-dom';
import { Accordion, CardGroup, Container, Row, Col, Tabs, Tab, Popover, OverlayTrigger } from 'react-bootstrap';
import '../../../styles/global.scss';
import NavigationBar from '../../navigation';
import './styles/work-card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import PopoverContainer from '../../../components/PopoverContainer';
import PopoverAction from '../../../components/PopoverAction';
import { ScrollToTop } from '../../../components/ScrollToTop';
import i18n from '../../../translations/i18n';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormList from '../../../components/FormList';
import FileInput from './../../../components/FileInput';
import ConfirmDialogComponent from '../../../components/ConfirmDialogComponent';
import {
    HasRight,
    Toaster,
    GetWorkCardPhasesData,
    GetSimplifiedHierarchyPathString,
    GetPopoverActions,
    GetFormDataFromViewSettings,
    GetTypeTranslationKey,
    InitDialogBodyContent,
    restrictRenderingByParameters,
    isNullOrWhitespace,
    HandleError
} from '../../../components/HelperFunctions';
import request from '../../../api/interceptors';
import { Loader } from '../../../components/Loader';
import {
    IAddWorkCardDocument,
    IDeleteMaterial
} from './actions';
import { InfoContainer } from '../../../components/InfoContainer';
import settingsAPI from '../../../config/settingsAPI';
import PanelContent from '../../../components/PanelContent';
import PanelItem from '../../../components/PanelItem';
import { actionIcons } from '../../../constants/icons';
import MaterialMenu from './components/menus/MaterialMenu';
import noviAPI from '../../../api/noviAPI';
import { UserRights } from '../../../constants/userRights';
import { getDate } from 'utils';

interface Props {
    storeWorkCard: (workCard: IWorkCard) => void;
    setWorkCardStatusPending: () => void;
    setWorkCardInformation: (data: IWorkCardComplete) => void;
    fetchWorkcardPermits: (id: number) => void;
    fetchViewSettingsByGroups: (array: string[]) => void;
    fetchMaterial: (id: number) => void;
    fetchMachine: (machineId: number) => void;
    fetchPurchaseOrderItems: (ids: any) => void;
    getDocument: (item: any) => void;
    deleteWorkcard: (id: number) => void;
    deleteMaterial: IDeleteMaterial;
    deleteWorkCardDocument: (id: number, wcId: number, documentGroup: string) => void;
    deleteMeasurementGroupWorkCard: (measurement: IMeasurementGroupWorkCard) => void;
    deleteWorkPhase: (workCardId: number, workPhaseId: number, redirect?: any) => void,
    downloadDocument: (id: number) => void;
    updateWorkCard: (id: number, obj: object) => void;
    addWorkCardDocument: IAddWorkCardDocument;
    setMachineGroupId: (machineGroupId: number) => void;
    fetchWorkCardLimitedWorkStatuses: (machineGroupId: number, group: string) => void;
    fetchDocumentMetadatas: () => void;
    viewSettings: any;
    settings: any;
    workcard: IWorkCard;
    documents: any;
    documentQueue: number[];
    materials: any[];
    permits: any[];
    location: any;
    history: any;
    match: any;
    error: any;
    status: string;
    wcColors: object;
    wcDetails: any[];
    isOffline: boolean;
    originalDocuments: any;
    machine: IMachine;
    hourCards: any[];
    measurements: any[];
    limitedWorkStatuses: ILimitedWorkStatuses;
    sparePartReservationEnabled: boolean;
    purchaseOrderItems: any[];
    getWorkCardColors;
    documentMetadatas?: IDocumentMetaDataGroup[];
    workPermits: IWorkPermit[];
}

interface State {
    tabGroupActiveKey: number;
    accordionActiveKey: number;
    showFileInput: boolean;
    document: any;
    [name: string]: any;
    showDialog: boolean;
    dialogType: string;
    targetItem: any;
    imgLoading: boolean;
    progressPercentage: any;
    objectUrl: string;
    overlayRef: string;
    viewReady: string;
    docRequirements: IGlobalSetting[];
    invalidFields: boolean;
    missingMetaDatas: number[];
}

const moduleSettings = settingsAPI.moduleData.workcard;

class WorkCardView extends React.Component<Props, State> {
    docRef = React.createRef<FileInput>();
    constructor(props) {
        super(props);

        const wcId = localStorage.getItem('wcId');
        if (parseInt(this.props.match.params.workCardId, 10) !== parseInt(wcId, 10)) {
            localStorage.removeItem('activeWorkcardTab');
        }

        const showFileInput = this.props.location.state?.showFileInput ?? false;

        const storedTabKey = localStorage.getItem('activeWorkcardTab');

        this.state = {
            tabGroupActiveKey: parseInt(storedTabKey, 10) || 1,
            accordionActiveKey: -1,
            showFileInput: showFileInput,
            document: null,
            showDialog: false,
            dialogType: '',
            targetItem: null,
            imgLoading: false,
            progressPercentage: null,
            objectUrl: null,
            overlayRef: '',
            unlinkedMaterials: false,
            purchaseItems: props.purchaseOrderItems,
            viewReady: '',
            docRequirements: null,
            invalidFields: false,
            missingMetaDatas: [],
        }
    }

    componentDidMount() {
        const { location, history, match, workcard, settings } = this.props;
        const { workCardId } = match.params;

        this.setState({ viewReady: 'pending' });

        this.props.setWorkCardStatusPending();

        noviAPI.workCards.fetchComplete(workCardId)
            .then(({ data }) => {
                if (data) {
                    const { documents, hourCards, measurements, spareParts, workPermits, ...fetchedWorkCard } = data;

                    const mGroupId = data?.machineGroupId;

                    if ((!location.state || location.state.noPreviousPage) && settings.machineGroupId !== mGroupId) {
                        this.props.setMachineGroupId(mGroupId);
                    }

                    const groups = moduleSettings.viewSettings.reduce((allViewSettings, { groupType }) => {
                        return allViewSettings.concat(...(Array.isArray(groupType) ? groupType : [groupType]));
                    }, []);

                    this.props.fetchViewSettingsByGroups(groups);

                    this.props.setWorkCardInformation(data);

                    if (settings.noviConfigs?.WorkStatusLimitedEdit === 'True') {
                        this.props.fetchWorkCardLimitedWorkStatuses(mGroupId, 'allowededitworkstatus')
                    }

                    this.props.fetchDocumentMetadatas();

                    // Store fetched work card in redux state
                    this.props.storeWorkCard(fetchedWorkCard);
                    this.getPurchaseOrderItems(spareParts);
                }
            })
            .catch(error => {
                HandleError(error, "Fetch complete workcard");
            });

        if (location.state) {
            const prevState = location.state;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                history.replace({ state: prevState });
            }
        }

        this.props.getWorkCardColors();

        
        // TODO: ENABLE THIS WHEN PERMITS SCENE WORKS CORRECTLY 
        //if (settings.noviConfigs?.WorkPermits === 'True') {
        //    this.props.fetchWorkcardPermits(parseInt(workCardId, 10));
        //}

        noviAPI.documents.getDocumentRequirements()
            .then(({ data }) => {
                this.setState({ docRequirements: data })
            })
    }

    componentDidUpdate(prevProps) {
        const { settings, workcard, match, status, materials } = this.props;
        const { workCardId } = match.params;

        if ((status === 'fulfilled' || status === 'error') && status !== prevProps.status) {
            this.setState({ viewReady: 'fulfilled' })
        }

        if ((!this.state.unlinkedMaterials && materials.length) || (materials.length != prevProps.materials.length)) {

            const anyUnlinkedMaterials = materials.some(({ purchaseOrderItem, orderable }) => {
                const noExistingPO = (purchaseOrderItem === null || purchaseOrderItem === undefined);
                return noExistingPO && orderable;
            });
            
            if (this.state.unlinkedMaterials !== anyUnlinkedMaterials)
                this.setState({ unlinkedMaterials: anyUnlinkedMaterials });
        }

        if (this.state.purchaseItems.length != this.props.purchaseOrderItems.length) {
            this.setState({ purchaseItems: this.props.purchaseOrderItems });
        }


        if (settings?.userRights?.length > 0 && (settings.noviConfigs !== prevProps.settings.noviConfigs)) {

            if (settings.noviConfigs?.WorkStatusLimitedEdit === 'True') {
                this.props.fetchWorkCardLimitedWorkStatuses(workcard?.machineGroupId, 'allowededitworkstatus')
            }
        }
    }

    componentWillUnmount = () => {
        if (this.state.objectUrl) window.URL.revokeObjectURL(this.state.objectUrl);
    }

    editWorkcard = (id, state = null) => {
        this.props.history.push(id + '/edit/', { cardType: 'editWorkCard', ...state });
    }

    createPurchaseOrder = (wcId) => {
        this.props.history.push(`/purchases/${wcId}`);
    }

    getPurchaseOrderItems = (spareParts) => {
        const params = new URLSearchParams();

        spareParts.forEach(mat => {
            if (mat.purchaseOrderItem) {
                params.append("ids", mat.purchaseOrderItem.id);
            }
        });

        if (params.getAll("ids").length > 0) {
            this.props.fetchPurchaseOrderItems(params);
        }
    }

    editItem = (item, wcId) => {
        const itemType = (item.type === 'mat' || item.type === 'wild_mat') ? 'material' : item.type === 'doc' ? 'document' : item.type;
        this.props.history.push(wcId + '/' + itemType + '/' + item.id + '/edit/');
    }

    //TODO: refactor to serve as general function in every view (workcard, machine, op.-/routemaintenances etc.)
    openDocument = item => {
        // open new tab if document is a link and return
        if (item.pathName && item.isUrl) {
            window.open(item.pathName, item.name);
            return;
        }
        
        // show loading spinner
        this.setState({ imgLoading: true });

        // open an empty window

        // get image as blob object
        request.get(item.downloadLink || `/api/Documents/${item.id}/download`, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ progressPercentage: percentCompleted })
            }
        }).then(response => {
            const { objectUrl } = this.state;
            // revoke previous objectURL
            if (objectUrl) window.URL.revokeObjectURL(objectUrl);

            const url = window.URL.createObjectURL(response.data);
            this.setState({ objectUrl: url });

            // hide loading spinner
            this.setState({ imgLoading: false, progressPercentage: 0 });

            const officeDocument = 'application/vnd.openxmlformats-officedocument';

            if (response.data.type.includes(officeDocument)) {
                // create a link elemenet and simulate click event to download document with original file name
                const tab = window.open('about:blank');
                let link = tab.document.createElement('a');
                link.href = url;
                link.download = item.name;
                link.click();
                link.remove();
            } else if (response.data.type.includes('image')) {
                // resize large images to fit the viewport
                const tab = window.open('about:blank');
                let css = 'body { margin: 0 } img { max-width: 100vw; }',
                    head = tab.document.head,
                    style = tab.document.createElement('style'),
                    textNode = tab.document.createTextNode(css);
                style.type = 'text/css';
                head.appendChild(style);
                style.appendChild(textNode)
                // create an image element and append it to the new tab
                let img = tab.document.createElement('img'),
                    body = tab.document.body;
                img.src = url;
                img.alt = item.name;
                body.appendChild(img);
                tab.focus();
            } else {
                window.open(url, '_blank');
            }

        }).catch(error => {
            Toaster({ msg: 'ACTION_FAILED', type: 'error' });
            this.setState({ imgLoading: false, progressPercentage: 0 });
        });
    }

    setDialogProperties = (item, type) => {
        this.setState({
            showDialog: true,
            dialogType: type + '_' + item.type,
            targetItem: item,
        });
    }

    deleteWorkcard = id => {
        this.props.deleteWorkcard(id);
        this.props.history.push('/worklist/', { notificationMsg: i18n.t('WORKCARD_DELETED') });
    }

    deleteWorkPhase = ({ workCardId, id }: {workCardId: number, id: number }) => {
        this.props.deleteWorkPhase(workCardId, id, this.props.history.replace);
        this.resetDialogProps();
    }

    deleteItem = item => {
        switch (item.type) {
            case 'doc':
                const { originalDocuments } = this.props;

                let documentGroup = '';
                if (!!originalDocuments.documents.find(doc => doc.id === item.id)) {
                    documentGroup = 'documents';
                } else if (!!originalDocuments.machineDocuments.find(doc => doc.id === item.id)) {
                    documentGroup = 'machineDocuments';
                } else if (!!originalDocuments.maintenanceSubPlanDocuments.find(doc => doc.id === item.id)) {
                    documentGroup = 'maintenanceSubPlanDocuments';
                } else if (!!originalDocuments.operatorMaintenanceDocuments.find(doc => doc.id === item.id)) {
                    documentGroup = 'operatorMaintenanceDocuments';
                } else if (!!originalDocuments.routeMaintenancePhaseDocuments.find(doc => doc.id === item.id)) {
                    documentGroup = 'routeMaintenancePhaseDocuments';
                }

                this.props.deleteWorkCardDocument(item.id, parseInt(this.props.match.params.workCardId, 10), documentGroup);
                this.resetDialogProps();
                break;
            case 'mat':
            case 'wild_mat':
                this.props.deleteMaterial(item.id);
                this.resetDialogProps();
                break;
            case 'permit':
                //this.props.deletePermit(item.id); TODO: delete method for delete permit
                break;
            case 'measurement':
                this.props.deleteMeasurementGroupWorkCard(item);
                this.resetDialogProps();
                break;
            default:
                return;
        }
    }

    addWorkPhase = id => {
        this.props.history.push(id + '/workphase/new/');
    }

    addMaterial = id => {
        this.props.history.push(id + '/material/new/');
    }

    quickAction = (workCard = null) => {
        if (!workCard) return;

        if (workCard.workStatus === 1) {
            this.props.updateWorkCard(workCard.id, { workStatus: { id: 2 } });

            let notificationMsg = i18n.t('WORKCARD_STARTED', { workcardId: workCard.id });

            toast.success(notificationMsg, {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
        } else {
            this.editWorkcard(workCard.id, { reportReady: true });
        }
        this.resetDialogProps()
    }

    getActionLabel = (wcStatusId) => {
        return wcStatusId === 1 ? i18n.t('START_WORKCARD') : i18n.t('REPORT_WC_READY')
    }

    addDocument = () => {
        const { docRequirements } = this.state;
        const { linkToDocument, description, type, file, metaData } = this.docRef.current.state;
        const workCardId = parseInt(this.props.match.params.workCardId, 10);

        if ((file === null && linkToDocument === '') || (file !== null && linkToDocument !== '')) {
            toast.info(i18n.t('SELECT_FILE_OR_LINK'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            return;
        }

        const missingMetaDatas = this.props.documentMetadatas.filter(field => {
            const val = metaData[field.label];
            return (field.required && (isNullOrWhitespace(val) || val?.id == -1));
        });


        if (missingMetaDatas.length || docRequirements?.find(req => req.field === 'description') && !description) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
            this.setState({ 
                invalidFields: true,
                missingMetaDatas: missingMetaDatas.map(i => i.id) 
            });
            return;
        }
        
        const documentMetaDataIds = Object.values(metaData)
            .map(data => data?.id ?? 0)
            .filter(id => id > 0)

        const { addWorkCardDocument } = this.props;

        addWorkCardDocument({
            workCardId,
            description,
            type,
            isUrl: !file ? true : false,
            documentMetaDataIds,
            ...(file === null && linkToDocument !== '' && { linkToDocument }),
            ...(file !== null && linkToDocument === '' && { file }),
        })

        this.closeDocumentForm();
    }

    handleTabGroup = (value) => {
        this.setState({ tabGroupActiveKey: value });
        localStorage.setItem('activeWorkcardTab', value);
        localStorage.setItem('wcId', this.props.match.params.workCardId);
    }

    handleAccordion = (value) => {
        this.setState({ accordionActiveKey: value });
    }

    openDocumentForm = () => {
        this.setState({ showFileInput: true })
    }

    closeDocumentForm = () => {
        this.setState({
            showFileInput: false,
            invalidFields: false,
            missingMetaDatas: []
        })

    }

    getDialogContent = contentType => {
        if (!contentType) return {
            title: '',
            body: '',
            type: '',
            params: null
        }

        const dialogContents = {
            'delete_wc': {
                title: i18n.t('DELETE_WORKCARD'),
                body: InitDialogBodyContent('WORKCARD', this.props.workcard.code),
                type: 'delete',
                params: this.props.workcard.id
            },
            'delete_doc': {
                title: i18n.t('DELETE_DOCUMENT'),
                body: InitDialogBodyContent('DOCUMENT', this.state.targetItem && (this.state.targetItem.name || this.state.targetItem.id)),
                type: 'delete',
                params: this.state.targetItem && { id: this.state.targetItem.id, type: this.state.targetItem.type }
            },
            'delete_mat': {
                title: i18n.t('DELETE_MATERIAL'),
                body: InitDialogBodyContent('MATERIAL', this.state.targetItem && (this.state.targetItem.name || this.state.targetItem.id)),
                type: 'delete',
                params: this.state.targetItem && { id: this.state.targetItem.id, type: this.state.targetItem.type }
            },
            'delete_permit': {
                title: i18n.t('DELETE_PERMIT'),
                body: InitDialogBodyContent('PERMIT', this.state.targetItem && (this.state.targetItem.name || this.state.targetItem.id)),
                type: 'delete',
                params: this.state.targetItem && this.state.targetItem.id
            },
            'delete_measurement': {
                title: i18n.t('MEASUREMENT'),
                body: InitDialogBodyContent('MEASUREMENT', this.state.targetItem && (this.state.targetItem.name || this.state.targetItem.id)),
                type: 'delete',
                params: this.state.targetItem && { measurementGroupId: this.state.targetItem.id, workCardId: this.props.workcard.id, type: this.state.targetItem.type }
            },
            'start_work': {
                title: this.getActionLabel(this.props.workcard.workStatus),
                body: InitDialogBodyContent('WILL_WORK_BE_STARTED', this.props.workcard.code),
                type: 'start',
                params: this.props.workcard
            },
            'delete_phase': {
                title: i18n.t("DELETE_WORKCARD_PHASE"),
                body: InitDialogBodyContent('WORKPHASE', this.state.targetItem.id),
                type: 'delete',
                params: this.state.targetItem
            }
        }

        return dialogContents[contentType === 'delete_wild_mat' ? 'delete_mat' : contentType];
    }

    getCallbackFn = () => {
        const { targetItem } = this.state
        const type = targetItem?.type;

        if (targetItem && type) {
            return type === 'work'
                ? this.quickAction
                : type === 'wc'
                    ? this.deleteWorkcard
                        : type === "phase"
                            ? this.deleteWorkPhase
                            : this.deleteItem
        }
    }

    resetDialogProps = () => {
        this.setState({
            showDialog: false,
            dialogType: null,
            targetItem: null
        });
    }

    navigateBackToWorklist = (params) => {
        this.props.history.replace(params.path, params.state);
    }

    warehouseOperation = (actionName, item, wild = false, purchaseOrder = null) => {
        const url = `/warehouseoperations/${wild ? item.id : item.sparePartId}/${actionName}`;
        this.props.history.push({
            pathname: url,
            state: {
                costpooltype: 'WORKCARD',
                costpool: this.props.workcard.code,
                workCardId: this.props.workcard.id,
                purchaseOrder,
                wSparePartId: item.id,
                wild
            }
        });
    }

    handlePopoverClick = (actionName = null, item = null, purchaseOrder = null) => {
        const isWildMaterial = item?.type === 'wild_mat';
        if (actionName === 'take') {
            this.warehouseOperation(actionName, item, isWildMaterial);
        }
        if (actionName === 'return') {
            this.warehouseOperation(actionName, item, isWildMaterial);
        }
        if (actionName === 'arrive') {
            this.warehouseOperation(actionName, item, isWildMaterial, purchaseOrder);
        }
        if (actionName === 'delete') {
            this.setDialogProperties(item, 'delete');
        }
        if (actionName === 'edit') {
            this.editItem(item, this.props.match.params.workCardId);
        }
        if (actionName === 'view') {
            this.openDocument(item);
        }
        if (actionName === 'viewHourCard') {
            this.props.history.push('/hourcard/' + item.id);
        }
        if (actionName === 'editMeasurement') {
            this.props.history.push('/measurement/edit', { measurementGroupId: item.id, workCardId: this.props.workcard.id });
        }
        if (actionName === 'viewMeasurement') {
            const machineId = this.props.machine?.id || this.props.workcard?.machine?.id;
            this.props.history.push(`/machine/${machineId}/measurement/${item.id}/workcard/${this.props.workcard.id}`);
        }
        if (actionName === 'viewPermit') {
            this.props.history.push('/workpermit/' + item.id);
        }
        if (actionName === 'editPermit') {
            this.props.history.push('/workcard/'+ this.props.match.params.workCardId +'/workpermit/' + item.id + '/edit');
        }
    }
    handlePhasePopoverClick = (action, item) => {
        const { history } = this.props;

        switch(action) {
            case 'edit':
                window.scrollTo(0, 0);
                const link = this.props.match.params.workCardId + '/workphase/' + item.id + "/edit";
                history.push(link);
                break;
            case 'copy':
                window.scrollTo(0, 0);
                history.push(history.location.pathname + "/workphase/new", { defaultValues: item });
                break;
            case 'delete':
                this.setDialogProperties({ type: 'phase', ...item }, 'delete');
                break;
        }
    }

    getPhaseDetails = (phase) => {
        const { settings } = this.props;
        const { workcardphase } = this.props.viewSettings;

        let phaseDetails = [];

        if (workcardphase !== undefined && workcardphase !== null && workcardphase.find !== undefined) {
            const showWpWorker = !!workcardphase.find(setting => setting.field === 'worker')
            const showWpStatus = !!workcardphase.find(setting => setting.field === 'workstatus')

            if (showWpWorker) {
                phaseDetails.push({ label: i18n.t('WORKER'), value: FormList({ list: phase.workers, listItemName: 'name' }) })
            }
            if (showWpStatus) {
                phaseDetails.push({ label: i18n.t('WORKSTATUS'), value: i18n.t(GetTypeTranslationKey('workstatus', phase.workStatus, settings)) });
            }
        }

        return phaseDetails;
    }

    getAddinationalDetailActions = (item?) => {
        return {
            'doc': GetPopoverActions(this.props.settings, 'workcarddocument'),
            'maintenance_doc': GetPopoverActions(this.props.settings, 'workcardmaintenancedocument'),
            'm_doc': GetPopoverActions(this.props.settings, 'workcardmachinedocument'),
            'permit': GetPopoverActions(this.props.settings, 'workcardpermit'),
            'hourcard': GetPopoverActions(this.props.settings, 'hourcard'),
            'measurement': GetPopoverActions(this.props.settings, 'measurement'),
            'workPermit': GetPopoverActions(this.props.settings, 'workcardpermit', null, null, item),
        }
    }

    getMenu = (item) => {
        const children = this.getAddinationalDetailActions(item)[item.type]?.map((action, index) => {
            return <div key={index}>
                <PopoverAction
                    icon={action.icon}
                    label={action.label}
                    paClassName={''}
                    onClick={() => this.handlePopoverClick(action.action, item)}
                />
                {this.getAddinationalDetailActions(item)[item.type]?.length > index + 1 && <hr />}
            </div>
        });
        return (<Popover id="settings-menu-popover" className="panel-menu-popover">
            {React.Children.map(children, (child, i) => (
                <div className="sub-section" key={`setting_${i}`}>
                    <div className="setting-option">
                        {child}
                    </div>
                </div>
            ))}
        </Popover>);
    }

    render() {
        const {
            workcard,
            viewSettings,
            settings,
            location,
            history,
            wcColors,
            status,
            isOffline,
            documents,
            materials,
            permits,
            machine,
            hourCards,
            measurements,
            sparePartReservationEnabled,
            workPermits
        } = this.props;

        const {
            imgLoading,
            progressPercentage,
            showFileInput,
            showDialog,
            viewReady
        } = this.state;

        const maintenaceDocsCount = function (docListItem) {
            let count = 0;

            docListItem.forEach(item => {
                count = count + item.documents.length;
            });

            return count;
        }

        const workPermitsList = workPermits?.map(wP => {
            return { 
                ...wP,
                type: "workPermit"
            }
        })

        let panels = [
            {
                title: 'WORK_DOCUMENTS',
                items: documents.workcardDocuments || [],
                count: documents.workcardDocuments.length
            },
            {
                title: 'MACHINE_DOCUMENTS',
                items: documents.machineDocuments || [],
                count: documents.machineDocuments.length
            },
            {
                title: 'MAINTENANCE_DOCUMENTS',
                items: documents.maintenanceDocumentLists || {},
                count: maintenaceDocsCount(documents.maintenanceDocumentLists.subList)
            }
        ];

        if (HasRight(UserRights.WorkScheduleViewMaterials, settings.userRights)) {
            panels.push({
                title: 'MATERIALS',
                items: materials || [],
                count: materials.length
            });
        }

        if (settings.noviConfigs?.HourCardsEnabled === 'True' && HasRight(UserRights.HourCardView, settings.userRights)) {
            panels.push({
                title: 'HOUR_CARDS',
                items: hourCards || [],
                count: hourCards?.length || 0
            });
        }

        if (settings.noviConfigs?.Measurements === 'True') {
            panels.push({
                title: 'MEASUREMENTS',
                items: measurements || [],
                count: measurements?.length || 0
            });
        }

        if (settings.noviConfigs?.WorkPermits === 'True' && HasRight([UserRights.WorkPermitView, UserRights.WorkPermitViewAll], settings.userRights)) {
            panels.push({
                title: 'WORK_PERMITS',
                items: workPermitsList || [],
                count: workPermitsList?.length ?? 0
            });
        }

        let itemsLength = 0;
        for (let i = 0; i < panels.length; i++) {
            itemsLength = itemsLength + panels[i].count;
        }

        let noticeViewSettings = [];
        let reportViewSettings = [];

        if (workcard?.id) {
            let key, keys = Object.keys(workcard);
            let n = keys.length;
            let wcObj = { extraDatas: [] };
            while (n--) {
                key = keys[n];
                wcObj[key.toLowerCase()] = workcard[key];
            }

            // Add machinethirdparties to wcObj
            wcObj['thirdpartiesbytypes'] = [];

            machine?.thirdPartiesByTypes?.forEach((tParty: IThirdPartiesByType) => {

                const match = wcObj['thirdpartiesbytypes'].find(x => x.type.id == tParty.type.id);

                if (match) {
                    tParty.thirdParties.forEach(tP => {
                        if (!match.thirdParties.find(t => t.id == tP.id))
                            match.thirdParties.push(tP);
                    })
                }
                else {
                    wcObj['thirdpartiesbytypes'].push(tParty);
                }

            });

            let phasesData = GetWorkCardPhasesData(workcard.phases);

            if (viewSettings.workcard) {
                noticeViewSettings = GetFormDataFromViewSettings([...viewSettings.workcard], wcObj, phasesData, settings, workcard.extraDatas, isOffline, machine || { costPools: [] });
            }
            if (viewSettings.workcardreport) {
                reportViewSettings = GetFormDataFromViewSettings([...viewSettings.workcardreport], wcObj, phasesData, settings, workcard.extraDatas, isOffline, machine || { costPools: [] });
            }
        }

        const actions = [];
        const phaseActions = [];

        const wcId = workcard ? workcard.id : null;
        const wcCode = workcard ? workcard.code : null;
        //const wcStatus = workcard ? workcard.workStatus : null;
        const enabledPhases = settings['noviConfigs'] && settings['noviConfigs'].EnablePhases === 'True';
        const preventMaterialDelete = this.props.settings?.noviConfigs?.PreventWorkCardMaterialDelete === 'True';
        //const startedType = settings.statusTypes.find(sType => sType.statusValue === 2)

        if (!this.state.showFileInput) {
            if (HasRight('workscheduledele', settings.userRights)) {
                actions.push({
                    icon: actionIcons.DELETE,
                    label: i18n.t('REMOVE'),
                    clickFn: this.setDialogProperties,
                    isActionFn: true,
                    params: [{ type: 'wc', id: wcId }, 'delete'],
                    paClass: ''
                });
            }
            // Render edit button always if user has correct userRights. If WorkStatusLimitedEdit is True we need to handle rendering depending on the current work status and person group configurations.
            if (restrictRenderingByParameters('workcardedit', settings.userRights)) {
                if (settings.noviConfigs.WorkStatusLimitedEdit === 'True') {
                    if (workcard?.workStatus !== undefined && this.props.limitedWorkStatuses?.allowededitworkstatus?.some(i => i.field === workcard?.workStatus?.toString())) 
                    {
                        actions.push({
                            icon: actionIcons.EDIT,
                            label: i18n.t('EDIT'),
                            clickFn: this.editWorkcard,
                            isActionFn: true,
                            params: [wcId],
                            paClass: ''
                        });
                    }
                } else {
                    actions.push({
                        icon: actionIcons.EDIT,
                        label: i18n.t('EDIT'),
                        clickFn: this.editWorkcard,
                        isActionFn: true,
                        params: [wcId],
                        paClass: ''
                    });
                }
            }
            // Materials on workcard that are UNLINKED to purchaseOrders
            if (this.state.unlinkedMaterials) {

                if (restrictRenderingByParameters('purchasesedit', settings.userRights)) {
                    actions.push({
                        icon: actionIcons.ADD_CIRCLE,
                        label: i18n.t('ORDER_MATERIALS'),
                        clickFn: this.createPurchaseOrder,
                        isActionFn: true,
                        params: [wcId],
                        paClass: ''
                    });
                }
            }
            if (restrictRenderingByParameters('workcardedit', settings.userRights)) {
                if (enabledPhases) {
                    actions.push({
                        icon: actionIcons.ADD_CIRCLE,
                        label: i18n.t('ADD_WORKPHASE'),
                        clickFn: this.addWorkPhase,
                        isActionFn: true,
                        params: [wcId],
                        paClass: ''
                    });
                }
            }
            if (restrictRenderingByParameters('workschedulerestrictedmaterialsedit', settings.userRights)) {
                actions.push({
                    icon: actionIcons.ADD_CIRCLE,
                    label: i18n.t('ADD_MATERIAL'),
                    clickFn: this.addMaterial,
                    isActionFn: true,
                    params: [wcId],
                    paClass: ''
                });
            }
            if (restrictRenderingByParameters('workcardedit', settings.userRights)) {
                actions.push({
                    icon: actionIcons.ADD_CIRCLE,
                    label: i18n.t('ADD_DOCUMENT'),
                    clickFn: this.openDocumentForm,
                    isActionFn: true,
                    params: [true],
                    paClass: ''
                });
            }
            if (HasRight('hourcardedit', settings.userRights)) {
                actions.push({
                    icon: actionIcons.ADD_CIRCLE,
                    label: i18n.t('ADD_HOUR_CARD'),
                    clickFn: () => this.props.history.push(
                        {
                            pathname: '/hourcard/new',
                            state: {
                                workCardId: wcId,
                                workCardCode: workcard.code,
                                costPools: workcard.costPools
                            }
                        }),
                    isActionFn: true,
                    params: [wcId],
                    paClass: ''
                });
            }

            if (settings.noviConfigs.Measurements === 'True') {
                if (HasRight([UserRights.WorkScheduleEdit, UserRights.RestrictedMaterialEdit, UserRights.RestrictedTimestampsEdit], settings.userRights)) {
                    actions.push({
                        icon: actionIcons.ADD_CIRCLE,
                        label: i18n.t('ADD_MEASUREMENT'),
                        clickFn: () => this.props.history.push(
                            {
                                pathname: '/measurement/edit',
                                state: { workCardId: wcId }
                            }),
                        isActionFn: true,
                        params: [wcId],
                        paClass: ''
                    });
                }
            }

            if (settings.noviConfigs.WorkPermits === 'True') {
                if (HasRight([UserRights.WorkPermitAdd], settings.userRights)) {
                    actions.push({
                        icon: actionIcons.ADD_CIRCLE,
                        label: i18n.t('WORK_PERMIT_ADD'),
                        clickFn: () => this.props.history.push(
                            {
                                pathname: '/workcard/'+ wcId +'/editworkpermit',
                                state: { workCardId: wcId }
                            }),
                        isActionFn: true,
                        params: [wcId],
                        paClass: ''
                    });
                }
            }
        }


        if (restrictRenderingByParameters("workcardedit", settings.userRights)) {

            phaseActions.push({
                icon: actionIcons.EDIT,
                label: i18n.t('EDIT'),
                action: 'edit',
                paClass: 'icon-warning',
                isActionFn: false,
                params: null
            });

            phaseActions.push({
                icon: actionIcons.COPY,
                label: i18n.t('COPY'),
                action: 'copy',
                paClass: 'icon-warning',
                isActionFn: false,
                params: null
            });

        }
        
        if (HasRight(UserRights.WorkScheduleDelete, settings.userRights)) {
            phaseActions.push({
                icon: actionIcons.DELETE,
                label: i18n.t('DELETE'),
                action: 'delete',
                paClass: 'icon-warning',
                isActionFn: false,
                params: null
            });
        }

        const popoverData = {
            popoverClass: 'menu-popover',
            popoverActions: actions
        };

        const viewAction = showFileInput
            ? {
                icon: actionIcons.SAVE,
                label: '',
                clickFn: this.addDocument,
                isActionFn: true,
                paClass: 'start-phase'
            }
            : 'settings';

        const sceneData = {
            view: moduleSettings.name,
            title: showFileInput ? i18n.t('ADD_DOCUMENT') : (wcCode || null),
            location: location,
            history: history,
            itemColors: workcard ? wcColors[workcard.id] : [],
            backAction: !showFileInput
                ? location.state == undefined || location.state?.noPreviousPage === true
                    ? {
                        action: this.navigateBackToWorklist,
                        params: {
                            path: '/worklist',
                            state: { heightPos: location.state?.scrollPosition ?? 0 }
                        }
                    }
                    : { action: history.goBack }
                : { action: this.closeDocumentForm }
        };

        const viewSettingsReady = !!(viewSettings.workcard.length || viewSettings.workcardreport.length);

        return (
            <div>
                <ScrollToTop />
                {showDialog && <ConfirmDialogComponent
                    dialogContent={this.getDialogContent(this.state.dialogType)}
                    callBack={this.getCallbackFn()}
                    cancelDialog={this.resetDialogProps}
                />}
                <NavigationBar
                    currentView={sceneData}
                    popoverData={popoverData}
                    viewAction={viewAction}
                />
                <Loader status={viewSettingsReady ? viewReady : 'pending'} loading={imgLoading} progressPercentage={progressPercentage} />
                {viewSettingsReady && viewReady !== 'pending' && <div id="work-card-view">
                    {showFileInput
                        ? <FileInput 
                            ref={this.docRef}
                            docRequirements={this.state.docRequirements} 
                            invalidFields={this.state.invalidFields} 
                            missingMetaDatas={this.state.missingMetaDatas} 
                            docMetadata={this.props.documentMetadatas}
                            />
                        : workcard?.id ? <Tabs
                            id="workCardViewTabs"
                            className="novi-nav-tabs"
                            activeKey={this.state.tabGroupActiveKey}
                            onSelect={this.handleTabGroup}
                        >
                            <Tab
                                eventKey={1}
                                title={i18n.t('INFORMATION').toUpperCase()}
                            >
                                {workcard.id ? <Container fluid={false}>
                                    <div className="workcardDetails">
                                        <h2>{i18n.t('NOTICE').toUpperCase()}</h2>
                                        {noticeViewSettings.map((item, i) => {
                                            const noticeValue = item.value;
                                            const listingStyle = item.field === ('phaseprocedures' || item.field !== 'phasecomments')
                                                ? 'listed'
                                                : null;
                                            return <Row key={i}>
                                                <Col xs={4} className="sub-header break-word">
                                                    {i18n.t(item.translationKey)}
                                                </Col>
                                                {(noticeValue !== undefined && noticeValue !== null) &&
                                                    <Col xs={8} className="white word-break" data-cy={item.field}>
                                                        {item.field !== 'machine'
                                                            ? item.field !== 'hierarchypath'
                                                                ? Array.isArray(noticeValue)
                                                                    ? <FormList list={noticeValue} listItemName='name' listingStyle={listingStyle} />
                                                                    : (noticeValue.id || noticeValue.value)
                                                                        ? noticeValue.name || noticeValue.label || noticeValue.description
                                                                        : noticeValue
                                                                : GetSimplifiedHierarchyPathString(noticeValue)
                                                            : Object.keys(noticeValue).map(mKey => (
                                                                <Link key={mKey} to={'/machine/' + noticeValue[mKey]['id']}>
                                                                    {noticeValue[mKey]['code'] + (noticeValue[mKey]['name'] ? ' / ' + noticeValue[mKey]['name'] : '')}
                                                                </Link>
                                                            ))
                                                        }
                                                    </Col>}
                                            </Row>
                                        })}
                                        <h2>{i18n.t('REPORTAGE').toUpperCase()}</h2>
                                        {reportViewSettings.map(item => {
                                            const reportValue = item.value;
                                            
                                            const listingStyle = item.field === ('phaseprocedures' || item.field !== 'phasecomments')
                                                ? 'listed'
                                                : null

                                            return <Row key={item.field}>
                                                <Col xs={4} className="sub-header break-word">
                                                    {i18n.t(item.translationKey)}
                                                </Col>
                                                {(reportValue !== undefined && reportValue !== null) && <Col xs={8} data-cy={item.field}>
                                                    {item.field !== 'machineparent'
                                                        ? Array.isArray(reportValue)
                                                            ? <FormList list={reportValue} listItemName='name' listingStyle={listingStyle} />
                                                            : (reportValue && typeof reportValue === 'object')
                                                                ? reportValue.name ?? reportValue.label ?? reportValue.description ?? ''
                                                                : reportValue
                                                        : reportValue.levelId === null
                                                            ? <Link key={reportValue.machineId} to={'/machine/' + reportValue.machineId}>
                                                                {reportValue.machineCode + (reportValue.name ? ' / ' + reportValue.name : '')}
                                                            </Link>
                                                            : reportValue.name
                                                    }
                                                </Col>}
                                            </Row>
                                        })}
                                    </div>

                                    {enabledPhases && workcard.phases && workcard.phases.length > 0 && <div className="margin-top-15">
                                        <h2>{i18n.t('PHASES').toUpperCase()}</h2>
                                        <CardGroup id="work-phase-panel-group">
                                            {workcard.phases
                                                .sort((a, b) => a['rowNumber'] - b['rowNumber'])
                                                .map((phase, i) => {
                                                return <LinkPanelContainer
                                                    key={phase.id}
                                                    title={phase.procedure || i18n.t('WORKPHASE') + ' #' + phase.id}
                                                    linkTo={this.props.match.params.workCardId + '/workphase/' + phase.id}
                                                    hasView={true}
                                                    hasChildren={false}
                                                    property={workcard.code?.toString()}
                                                    linkToChildren={this.props.match.params.workCardId + '/workphase/' + phase.id}
                                                    itemDetails={this.getPhaseDetails(phase)}
                                                    item={phase}
                                                    linkState={{ viewProperty: workcard.code?.toString() }}
                                                    popOverActions={phaseActions}
                                                    handleClick={this.handlePopoverClick}
                                                    handlePopoverClick={this.handlePhasePopoverClick}
                                                />
                                            })}
                                        </CardGroup>
                                    </div>}
                                </Container>
                                    : <div> {status === 'fulfilled' && <Container style={{ paddingTop: 20 }}>{i18n.t('WORK_CARD_NOT_FOUND')}</Container>}</div>}
                            </Tab>
                            <Tab
                                eventKey={2}
                                title={
                                    <span>
                                        {i18n.t('DETAILS').toUpperCase()}
                                        <span className="number-badge">
                                            {itemsLength}
                                        </span>
                                    </span>
                                }
                            >
                                <Container>
                                    <Accordion
                                        id="additional-info-panel"
                                        className="margin-top-15 novi-panel"
                                        defaultActiveKey={this.state.accordionActiveKey?.toString()}
                                        onSelect={this.handleAccordion}
                                    >
                                        {panels
                                            .map((data, j) => (
                                                <PanelContainer
                                                    id={j}
                                                    title={i18n.t(data.title)}
                                                    key={j}
                                                    panelIcons={[]}
                                                    subTitle={''}
                                                    itemCount={data.count}
                                                >
                                                    {data.items.subList
                                                        ? <div>
                                                            {data.items.subList.map((subListItem, i) => (
                                                                <div key={i}>
                                                                    <div className="sublist-header">{subListItem.subHeader}</div>
                                                                    {subListItem.documents.length > 0 ? subListItem.documents.map((item, i) => (
                                                                        <PanelItem
                                                                            key={i}
                                                                            item={item}
                                                                            actions={this.getAddinationalDetailActions()}
                                                                            handlePopoverClick={this.handlePopoverClick}
                                                                            documentQueue={this.props.documentQueue}
                                                                        />
                                                                    )) : <div className="no-sub-items">{i18n.t('NO_DOCUMENTS')}</div>}
                                                                </div>
                                                            ))}
                                                        </div>
                                                        : <div>
                                                            {data.items.map((item, i) => {

                                                                const poItem = this.state.purchaseItems.find(x => {
                                                                    return (x.id === (item.purchaseOrderItem?.id ?? -1));
                                                                });

                                                                return (
                                                                    <div key={i} className={
                                                                        (isOffline && item.type === 'doc')
                                                                            || (this.props.documentQueue && this.props.documentQueue.includes(item.id))
                                                                            ? 'disabled-action sub-item-container'
                                                                            : 'sub-item-container'
                                                                    }>
                                                                        <Col xs={item.createDate ? 7 : 10}>
                                                                            {((item.type === 'workPermit' || item.type === 'hourcard' || item.type === 'measurement') && item.type !== null && data.items.length > 0) ?
                                                                                <PanelContent
                                                                                    name={item.name}
                                                                                    contentType={item.type}
                                                                                    details={item}
                                                                                />
                                                                                :
                                                                                <PanelContent
                                                                                    name={item.name}
                                                                                    contentType={item.type}
                                                                                    details={Object.assign({}, item.details, { 
                                                                                        purchaseOrder: poItem?.purchaseOrder, 
                                                                                        sparePartId: item.sparePartId
                                                                                    })}
                                                                                />
                                                                            }
                                                                        </Col>
                                                                        {item.createDate &&
                                                                            <Col xs={3}>
                                                                                <p className="small right">{getDate(item.createDate)}</p>
                                                                            </Col>}
                                                                        <Col xs={2}>
                                                                            <div className="options-container">
                                                                                <span className="bar" />
                                                                                {isOffline && item.type === 'doc'
                                                                                    ? <OverlayTrigger
                                                                                        trigger="click"
                                                                                        placement="left"
                                                                                        overlay={
                                                                                            <PopoverContainer popoverclass="panel-menu-popover">
                                                                                                {i18n.t('NO_CONNECTION')}
                                                                                            </PopoverContainer>
                                                                                        }
                                                                                        rootClose={true}
                                                                                    >
                                                                                        <span className="options-button" ><FontAwesomeIcon icon={faInfoCircle} style={{ color: '#c4c4c4' }} /></span>
                                                                                    </OverlayTrigger>
                                                                                    : item.type === 'doc' && this.props.documentQueue.includes(item.id)
                                                                                        ? <FontAwesomeIcon icon={faSpinner} size="lg" pulse />
                                                                                        : item.type === 'mat' || item.type === 'wild_mat'
                                                                                            ? <MaterialMenu
                                                                                                materialItem={item}
                                                                                                handleClick={this.handlePopoverClick}
                                                                                                userRights={this.props.settings.userRights}
                                                                                                sparePartReservationEnabled={sparePartReservationEnabled}
                                                                                                preventMaterialDelete={preventMaterialDelete}
                                                                                            />
                                                                                            : <OverlayTrigger
                                                                                                trigger="click"
                                                                                                placement="left"
                                                                                                overlay={this.getMenu(item)}
                                                                                                rootClose={true}
                                                                                            >
                                                                                                <span className="options-button" ><FontAwesomeIcon icon={faEllipsisV} size="lg" /></span>
                                                                                            </OverlayTrigger>}
                                                                            </div>
                                                                        </Col>
                                                                    </div>
                                                                )})}
                                                        </div>}
                                                </PanelContainer>
                                            ))}
                                    </Accordion>
                                </Container>
                            </Tab>
                        </Tabs> : status === 'error' ? <InfoContainer type={'notfound'} content={'WC_NOT_FOUND'} /> : <></>
                    }
                </div>}
            </div>
        );
    }
}

export default WorkCardView;
