import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import noviAPI from '../../../api/noviAPI';
import { updateMachineSparePart } from '../actions';
import { useHistory } from 'react-router-dom';

type Props = {
    submit: boolean;
    machineLinkId: number;
    machineId: number;
}

const MachineLinkForm = (props: Props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        amount: '',
        memo: ''
    });

    const handleSubmit = useCallback(() => {
        dispatch(updateMachineSparePart(
            props.machineLinkId,
            {
                memo: formData.memo,
                amountInMachine: formData.amount.length ? parseInt(formData.amount, 10) : null
            },
            props.machineId,
            history.replace
        ));
    }, [dispatch, props.machineLinkId, props.machineId, formData.memo, formData.amount, history.replace]);

    useEffect(() => {
        if (props.submit) {
            handleSubmit();
        }
    }, [props.submit, handleSubmit]);

    useEffect(() => {
        noviAPI.machineSpareParts.fetch(props.machineLinkId)
            .then(response => {
                setFormData({
                    name: response.data.sparePart?.name || response.data.wildSparePart,
                    amount: response.data.amountInMachine.toString(),
                    memo: response.data.memo
                });
            })
            .catch(error => {
                console.log(error);
            })
    }, [props.machineLinkId]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    return (
        <Container>
            <div className="form-table-container margin-top-15">
                <form>
                    <FormElement
                        name="name"
                        type="label"
                        label={i18n.t('NAME')}
                        value={formData.name}
                        onChange={handleChange}
                        isDisabled />
                    <FormElement
                        name="amount"
                        type="number"
                        label={i18n.t('AMOUNT')}
                        value={formData.amount}
                        onChange={handleChange} />
                    <FormElement
                        name="memo"
                        type="text"
                        label={i18n.t('MEMO')}
                        value={formData.memo}
                        onChange={handleChange} />
                </form>
            </div>
        </Container>
    );
}

export default MachineLinkForm;