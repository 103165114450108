import React, { useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import i18n from '../../../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faStar } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LocationState } from 'history';
import { useLocalStorage, warehouseKey } from 'hooks/useLocalStorage';

interface IProps {
    data: any;
    filters: any;
    toggleHierarchySelect: (close?: boolean) => void;
    handleSelect: (value) => void;
    toggleMenu?: (closeMenu?: boolean) => void;
    setWrapperRef: (node: any) => void;
    menuOpen: boolean;
}

const QuickSearch = ({ data, filters, toggleHierarchySelect, handleSelect, toggleMenu, setWrapperRef, menuOpen }: IProps) => {
    const quickSearchSelect = useRef(null);

    const { getStorageSetting, setStorageSetting } = useLocalStorage();
    const [currentFavourite, setFavourite] = React.useState(getStorageSetting(warehouseKey));


    const location = useLocation<LocationState>();
    const isWarehouseView = location.pathname.substr(1) === "warehouse";

    let isQuickSearchActive = false;
    const quickSearch = filters.current.quickSearch;

    if (quickSearch && (quickSearch.isSqlSearch || quickSearch.isSavedSearch)) {
        isQuickSearchActive = true;
    }
    // data.quickSearch comes with sceneData from WorkList
    else if (data.quickSearch) {
        isQuickSearchActive = true;
    }

    const isDouble = data.doubleSelect;
    const selectOptions = isDouble ? [data.quickSearchOptions?.[0]] ?? [] : data.quickSearchOptions;

    const selectedValue = !filters.current.warehouse && filters.count > 0
        ? { value: -1, label: i18n.t('ACTIVE_SEARCH_OPTIONS') }
        : filters.current.quickSearch && (filters.current.quickSearch.label || filters.current.quickSearch.id > 0)
            ? filters.current.quickSearch
                : ''

    const customOption = (props) => {
        const { isDisabled, data } = props;

        return !isDisabled ? (
            <components.Option {...props}>
                {props.label}
                <FontAwesomeIcon icon={faStar} size="1x" 
                    style={{ position: "absolute", right: "10px", color: currentFavourite?.id == data.id ? "orange" : "grey" }} 
                    onClick={(e) => {
                        e.stopPropagation(); 
                        const newSelection = (currentFavourite?.id == data?.id) ? null : data; 
                        setStorageSetting(warehouseKey, newSelection);
                        setFavourite(newSelection);
                    }}
                />
            </components.Option>
        ) : null;
    }

    const onChange = (value) => {
        quickSearchSelect.current.blur();
        handleSelect(value);
    }

    return (
        <div className="header-element-container" ref={setWrapperRef}>
            {menuOpen && <div className="overlay-canvas" onClick={() => toggleMenu(true)}></div>}
            {(data.noHierarchySelect || isDouble) && <Select
                ref={quickSearchSelect}
                placeholder={i18n.t('NO_SELECTION')}
                className="react-select-container bordered-field"
                classNamePrefix="react-select"
                value={selectedValue}
                options={selectOptions}
                onChange={(e) => onChange(e)}
                components={isWarehouseView && { Option: customOption }}
                onFocus={toggleMenu}
                />}
            {(!data.noHierarchySelect || isDouble) && 
                <div className={'header-action-element' + (isDouble ? '-double ' : ' ') + (isQuickSearchActive ? 'icon-primary' : '')} onClick={() => toggleHierarchySelect()}>
                    <FontAwesomeIcon icon={faSearch} size="2x" />
                </div>
            }
            </div>
    );
}

export default QuickSearch;