import React from 'react'
import i18n from 'i18next'
import { Link } from 'react-router-dom';
import { getDateTime } from 'utils';

type Props = {
    machineMeasurements: IMeasurementGroupWorkCard[];
}

const MeasurementsDataTable = ({ machineMeasurements }: Props) => {
    const headers = ['WORKCARD', 'DATE', 'MEASUREMENT'];
    return (
        <div style={{ overflow: 'auto' }}>
            <table className="spare-part-machines-table">
                <thead>
                    {machineMeasurements.length > 0 && <tr style={{ backgroundColor: '#2f2f2f' }}>
                        {headers
                            .map((h, i) => (
                                <th key={i}>
                                    {i18n.t(h)}
                                </th>))}
                    </tr>}
                </thead>
                <tbody>
                    {machineMeasurements.sort((a, b) => a.workCardId - b.workCardId).map((m, i) => (
                        <tr key={i} style={{ backgroundColor: i % 2 === 0 ? '2f2f2f' : '#272727' }}>
                            <td>
                                <Link key={m.workCard?.id} to={'/workcard/' + m.workCard?.id}>{m.workCard?.code}</Link>
                            </td>
                            <td>
                                {getDateTime(m.workCard?.workCanBegin ?? null)}
                            </td>
                            <td>
                                <Link key={m.measurementGroup.id} to={{
                                    pathname: `${window.location.href.split("machine/")[1]}/measurement/${m.measurementGroup.id}/workcard/${m.workCardId}`,
                                    state: {
                                        measurementId: m.measurementGroup.id,
                                        workCardId: m.workCardId,
                                    }
                                }}>
                                    {m.measurementGroup?.name}
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MeasurementsDataTable
