import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

type BackAction = { action: (params?) => void; params?; };
interface IProps extends RouteComponentProps { backAction?: BackAction; handleClick?: (params?) => void; };

const BackButton = (props: IProps) => {

    const icon = <FontAwesomeIcon size="2x" icon={faArrowLeft} />;

    if (props.handleClick) {
        return (
            <div onClick={props.handleClick}>
                {icon}
            </div>
        );
    }
    // TODO: remove (requires some )
    else if (props.backAction) {
        const { action, params } = props.backAction;
        return (
            <div onClick={() => params ? action(params) : action()}>
                {icon}
            </div>
        );
    } else {
        return (
            <div onClick={() => props.history.goBack()}>
                {icon}
            </div>
        );
    }

}

export default withRouter(BackButton);