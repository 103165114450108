import { parsePayloadDateTime } from "utils";
import { isNullOrWhitespace, toKeyFormat } from "../../components/HelperFunctions";
import { VIEW_FILTER } from "../../constants/costPools";
import { ThirdPartyTypes } from "constants/thirdParties/thirdPartyTypes";

/**
 * Maps values from MachineEdit to MachineAddition object.
 * 
 * @param values
 * @param viewSettings
 * @param machineGroupId
 */
export const mapValuesToMachineAddition = (values, viewSettings: IViewSetting[], machineGroupId: number): IMachineAddition => {
    let newMachine: IMachineAddition = { machineGroupId };

    Object.keys(values).forEach(key => {
        const value = values[key]; // string | IOptionType | IOptionType
        const setting = viewSettings.find(i => i.field === key);

        // Skip when value is empty
        if (isNullOrWhitespace(value) || (Array.isArray(value) && value.length < 1) || (value?.hasOwnProperty("value") && isNullOrWhitespace(value.value))) {
            return;
        }

        try {
            // Handle fields excluding details, third parties, extra data and cost pools
            switch (key) {
                case 'code': { newMachine.code = value; return; }
                case 'name': { newMachine.name = value; return; }
                case 'model': { newMachine.model = value; return; }
                case 'productnumber': { newMachine.productNumber = value; return; }
                case 'yearofmanufacture': { newMachine.yearOfManufacture = value; return; }
                case 'deliverynumber': { newMachine.deliveryNumber = value; return; }
                case 'purchasedate': { newMachine.purchaseDate = parsePayloadDateTime(value); return; }
                case 'deliverydate': { newMachine.deliveryDate = parsePayloadDateTime(value); return; }
                case 'deploymentdate': { newMachine.deploymentDate = parsePayloadDateTime(value); return; }
                case 'dimensions': { newMachine.dimensions = value; return; }
                case 'weight': { newMachine.weight = value; return; }
                case 'price': { newMachine.price = Number(value); return; }
                case 'deprecationperiod': { newMachine.deprecationPeriod = Number(value); return; }
                case 'tracking': { newMachine.tracking = value; return; }
                case 'hourprice': { newMachine.hourPrice = Number(value); return; }
                case 'memo': { newMachine.memo = value; return; }
                case 'machinetype': { newMachine.machineTypeId = Number(value.value); return; }
                case 'rownumber': { newMachine.rowNumber = Number(value); return; }
                case 'warrantyend': { newMachine.warrantyEnd = parsePayloadDateTime(value); return; }
                case 'extralocationtext': { newMachine.extraLocationText = value; return; }
                case 'url': { newMachine.url = value; return; }
                case 'costpool': { newMachine.costpool = value.label; return; }
                case 'responsibilityperson': { newMachine.responsibilityPersonId = Number(value.value); return; }
                case 'rating': { newMachine.ratingId = Number(value.value); return; }
                case 'extraLocationId': { newMachine.extraLocationId = Number(value.value); return; }
                case 'machineShiftId': { newMachine.machineShiftId = Number(value.value); return; }
                case 'parent': { newMachine.parentId = value.id; return; }
                case 'level': { newMachine.levelId = value.id; return; }
                case 'machinerequirements': { newMachine.machineRequirementIds = value.map(i => Number(i.value)); return; }
                case 'extralocation': { newMachine.extraLocationText = value; return; }
                case 'person': { newMachine.responsibilityPersonId = parseInt(value?.value) || null; return; }
            }

            // Handle details
            if (setting.type === 'detail' || setting.type === 'multidetail') {
                // initialize detailsIds before adding the first id to it
                if (typeof newMachine.machineDetailIds === 'undefined') {
                    newMachine.machineDetailIds = [];
                }
                const ids = Array.isArray(value) ? value.map(i => Number(i.value)) : Number(value.value);
                newMachine.machineDetailIds = newMachine.machineDetailIds.concat(ids);
            }

            // Handle third parties
            if (setting.type === 'thirdparty') {
                // initialize thirdPartyIdsByTypeIds before adding the first item to it
                if (typeof newMachine.thirdPartyIdsByTypeIds === 'undefined') {
                    newMachine.thirdPartyIdsByTypeIds = [];
                }

                const thirdPartyIdsByTypeId: IThirdPartiesByTypeLite = {
                    typeId: Number(key),
                    thirdPartyIds: value.map(i => Number(i.value))
                }

                newMachine.thirdPartyIdsByTypeIds.push(thirdPartyIdsByTypeId);
            }

            // Handle cost pools
            if (key.startsWith('costpoolgroup')) {
                // initialize costPoolIds before adding the first id to it
                if (typeof newMachine.costPoolIds === 'undefined') {
                    newMachine.costPoolIds = [];
                }
                newMachine.costPoolIds = newMachine.costPoolIds.concat(Number(value.value));
            }
        } catch (error) {
            console.log(error); // Check console if value is not saving properly
        }
    });

    return newMachine;
}

/**
 * Maps values from MachineEdit to MachineUpdate object.
 * 
 * @param values
 * @param viewSettings
 * @param machineGroupId
 */
export const mapValuesToMachineUpdate = (values, viewSettings: IViewSetting[], machineGroupId: number): IMachineUpdate => {
    let machineUpdate: IMachineUpdate = { machineGroupId };

    Object.keys(values).forEach(key => {
        const value = values[key]; // string | IOptionType | IOptionType
        const setting = viewSettings.find(i => i.field === key);

        try {
            // Handle fields excluding details, third parties, extra data and cost pools
            switch (key) {
                case 'code': { machineUpdate.code = value; return; }
                case 'name': { machineUpdate.name = value; return; }
                case 'model': { machineUpdate.model = value; return; }
                case 'productnumber': { machineUpdate.productNumber = value; return; }
                case 'yearofmanufacture': { machineUpdate.yearOfManufacture = value; return; }
                case 'deliverynumber': { machineUpdate.deliveryNumber = value; return; }
                case 'purchasedate': { machineUpdate.purchaseDate = parsePayloadDateTime(value); return; }
                case 'deliverydate': { machineUpdate.deliveryDate = parsePayloadDateTime(value); return; }
                case 'deploymentdate': { machineUpdate.deploymentDate = parsePayloadDateTime(value); return; }
                case 'dimensions': { machineUpdate.dimensions = value; return; }
                case 'weight': { machineUpdate.weight = value; return; }
                case 'price': { machineUpdate.price = !isNullOrWhitespace(value) ? Number(value) : null; return; }
                case 'deprecationperiod': { machineUpdate.deprecationPeriod = !isNullOrWhitespace(value) ? Number(value) : null; return; }
                case 'tracking': { machineUpdate.tracking = value; return; }
                case 'hourprice': { machineUpdate.hourPrice = !isNullOrWhitespace(value) ? Number(value) : null; return; }
                case 'memo': { machineUpdate.memo = value; return; }
                case 'machinetype': { machineUpdate.machineTypeId = !isNullOrWhitespace(value?.value) ? Number(value.value) : null; return; }
                case 'rownumber': { machineUpdate.rowNumber = !isNullOrWhitespace(value) ? Number(value) : null; return; }
                case 'warrantyend': { machineUpdate.warrantyEnd = parsePayloadDateTime(value); return; }
                case 'extralocationtext': { machineUpdate.extraLocationText = value; return; }
                case 'url': { machineUpdate.url = value; return; }
                case 'costpool': { machineUpdate.costpool = !isNullOrWhitespace(value) ? value.label : ''; return; }
                case 'responsibilityperson': { machineUpdate.responsibilityPersonId = !isNullOrWhitespace(value?.value) ? Number(value.value) : null; return; }
                case 'rating': { machineUpdate.ratingId = !isNullOrWhitespace(value?.value) ? Number(value.value) : null; return; }
                case 'extraLocationId': { machineUpdate.extraLocationId = !isNullOrWhitespace(value?.value) ? Number(value.value) : null; return; }
                case 'machineShiftId': { machineUpdate.machineShiftId = !isNullOrWhitespace(value?.value) ? Number(value.value) : null; return; }
                case 'parent': { machineUpdate.parentId = !isNullOrWhitespace(value) ? value.id : null; return; }
                case 'level': { machineUpdate.levelId = !isNullOrWhitespace(value) ? value.id : null; return; }
                case 'machinerequirements': { machineUpdate.machineRequirementIds = value.map(i => Number(i.value)); return; }
                case 'extralocation': { machineUpdate.extraLocationText = value; return; }
                case 'person': { machineUpdate.responsibilityPersonId = parseInt(value?.value) || null; return; }
            }

            // Handle details
            if (setting.type === 'detail' || setting.type === "multidetail") {
                // initialize detailsIds before adding the first id to it
                if (typeof machineUpdate.machineDetailIds === 'undefined') {
                    machineUpdate.machineDetailIds = [];
                }
                const ids = Array.isArray(value) ? value.map(i => Number(i.value)) : Number(value.value);
                machineUpdate.machineDetailIds = machineUpdate.machineDetailIds.concat(ids);
            }

            // Handle third parties
            if (setting.type === 'thirdparty') {
                // initialize thirdPartyIdsByTypeIds before adding the first item to it
                if (typeof machineUpdate.thirdPartyIdsByTypeIds === 'undefined') {
                    machineUpdate.thirdPartyIdsByTypeIds = [];
                }

                const thirdPartyIdsByTypeId: IThirdPartiesByTypeLite = {
                    typeId: Number(key),
                    thirdPartyIds: value.map(i => Number(i.value))
                }

                machineUpdate.thirdPartyIdsByTypeIds.push(thirdPartyIdsByTypeId);
            }

            // Handle cost pools
            if (key.startsWith('costpoolgroup')) {
                // initialize costPoolIds before adding the first id to it
                if (typeof machineUpdate.costPoolIds === 'undefined') {
                    machineUpdate.costPoolIds = [];
                }
                if (isNullOrWhitespace(value)) { return; }
                if (!(value?.hasOwnProperty("value") && isNullOrWhitespace(value.value)))
                    machineUpdate.costPoolIds = machineUpdate.costPoolIds.concat(Number(value.value));
            }
        } catch (error) {
            console.log(error); // Check console if value is not saving properly
        }
    });

    return machineUpdate;
}

/**
 * Initializes values using viewSettings.
 * 
 * @param viewSettings
 */
export const initValues = (viewSettings: IViewSetting[]) => {
    return viewSettings.reduce((prev, setting) => (
        { ...prev, [setting.field]: initFieldByFieldType(setting.type) }
    ), {});
}

/**
 * Initializes a single value by field type (viewSetting type).
 * 
 * @param type
 */
export const initFieldByFieldType = (type: string) => {
    if (type === 'select' || type === 'level') {
        return null;
    }
    if (type === 'detail' || type === 'multidetail' || type === 'thirdparty' || type === 'requirements') {
        return [];
    }
    if (type === 'boolean') {
        return false;
    }
    return '';
}

/**
 * Checks that required fields have values.
 * Returns true if form is valid or false if form is invalid.
 * 
 * @param values
 * @param viewSettings
 */
export const validateForm = (values, viewSettings: IViewSetting[]) => {
    let validity = true;
    Object.keys(values).forEach(key => {
        const value = values[key]; // string | IOptionType | IOptionType
        const setting = viewSettings.find(i => i.field === key);
        if (setting.required && !validateField(value)) {
            validity = false;
        }
    });
    return validity;
}

/**
 * Checks that field has a value.
 * Returns true if field is valid or false if field is invalid.
 * 
 * Note: Does not check whether the field is required
 * 
 * @param value
 */
export const validateField = (value) => {
    if ((Array.isArray(value) && value.length < 1) || isNullOrWhitespace(value) || (value?.hasOwnProperty("value") && isNullOrWhitespace(value.value))) {
        return false;
    }
    return true;
}

type OptionGroup = { [name: string]: IOptionType[]; }

/**
 * Maps machine details to machine detail options
 * Returns an object with machine detail options grouped by detail groups
 * 
 * @param machineDetails
 */
export const mapMachineDetailsToMachineDetailOptions = (machineDetails: IMachineDetails): OptionGroup => (
    machineDetails.allGroups.reduce((obj, group) => (
        Object.assign(obj, {
            [group]: machineDetails.byGroup[group]
                .map(i => ({ value: `${i.id}`, label: i.value }))
        })
    ), {})
)

/**
 * Maps third parties to third party options
 * Returns an object with third party options grouped by third party types
 * 
 * @param thirdParties
 */
export const mapThirdPartiesToThirdPartyOptions = (thirdParties: IThirdParties): OptionGroup => (
    thirdParties.allTypes.reduce((obj, { typeId }) => (
        Object.assign(obj, {
            [typeId]: thirdParties.byType[typeId]
                .map(i => ({ value: `${i.id}`, label: i.name }))
                .sort((a, b) => a.label.localeCompare(b.label))
        })
    ), {})
)

/**
 * Maps cost pools to cost pool options
 * Returns an object with cost pool options grouped by cost pool group ID
 * 
 * @param costPools
 * @param machineCostPools
 */
export const mapCostPoolsToCostPoolOptions = (costPools: ICostPool[], machineCostPools: ICostPoolLite[]): OptionGroup => {
    let costPoolOptions: OptionGroup = {};

    const costPoolList = costPools
        .filter(machineCostPoolViewFilter)
        .filter(i => archivedCostPoolFilter(i, machineCostPools))

    for (const costPool of costPoolList) {
        if (typeof costPoolOptions[costPool.costPoolGroupId] === 'undefined') {
            Object.assign(costPoolOptions, { [costPool.costPoolGroupId]: [] });
        }
        costPoolOptions[costPool.costPoolGroupId].push({ value: `${costPool.id}`, label: costPool.translationKey })
    }

    return costPoolOptions;
}

/**
 * Returns true if the cost pool is restricted to machine view or it is not restricted to any view.
 * 
 * @param costPool
 */
const machineCostPoolViewFilter = (costPool: ICostPool) => {
    return costPool.viewFilter.length < 1 || costPool.viewFilter.includes(VIEW_FILTER.MACHINE)
}

/**
 * Returns true if cost pool is unarchived. Also returns true if archived cost pool matches with any of the machine cost pools.
 * 
 * @param costPool
 * @param machineCostPools
 */
export const archivedCostPoolFilter = (costPool: ICostPool, machineCostPools: ICostPoolLite[]) => {
    return !costPool.archived || machineCostPools.find(i => i.id === costPool.id)
}

/**
 * Takes IdName and returns it as IOptionType
 * @param obj
 */
export const mapIdNameToOptionType = (obj: IdName): IOptionType => {
    return { value: `${obj.id}`, label: obj.name || obj.value }
}

/**
 * Takes IdLabel and returns it as IOptionType
 * @param obj
 */
export const mapIdLabelToOptionType = (obj: IdLabel): IOptionType => {
    return { value: `${obj.id}`, label: obj.label || obj.value }
}

/**
 * Takes IdValue and returns it as IOptionType
 * @param obj
 */
export const mapIdValueToOptionType = (obj: IdValue, translate?: boolean): IOptionType => {
    let label = '';
    if (translate) {
        label = toKeyFormat(obj.value);
    } else {
        label = obj.value;
    }
    return { value: `${obj.id}`, label: label }
}

const instanceOfIdName = (obj: IdName | IdLabel): obj is IdName => {
    return 'name' in obj;
}

/**
 * Takes an array of { id: number; name: string } or an array of { id: number; label: string } and returns it as an array of { value: string; label: string; }
 * @param oldOptions
 */
export const mapOldOptionTypesToOptionsType = (oldOptions: IdName[] | IdLabel[]): IOptionType[] => {
    let newOptions: IOptionType[] = [];

    for (const option of oldOptions) {
        if (instanceOfIdName(option)) {
            newOptions.push(mapIdNameToOptionType(option));
        } else {
            newOptions.push(mapIdLabelToOptionType(option));
        }
    }

    return newOptions;
}

/**
 * Takes an array of { id: number; value: string } and returns it as an array of { value: string; label: string; }
 * @param machineRequirements
 */
export const mapMachineRequirementsToMachineRequirementsValue = (machineRequirements: { id: number; value: string; }[]): IOptionType[] => {
    let machineRequirementsValue: IOptionType[] = [];

    for (const machineRequirement of machineRequirements) {
        machineRequirementsValue.push(mapIdValueToOptionType(machineRequirement));
    }

    return machineRequirementsValue;
}

export const getExtraDataFieldValues = (machineValues, viewSettings, extraData) => {
    let extraDatas = [];

    Object.keys(machineValues).forEach(key => {
        const extraDataSetting = viewSettings.filter(i => i.type.includes('extradata')).find(i => i.field === key);
        if (extraDataSetting) {
            const extraDataVal = machineValues[extraDataSetting.field];
            const existingExtraData = extraData ? extraData.find(extraData => extraData.group === key) : null;

            let mExtData = {
                value: extraDataSetting.type === 'extradatanumber' ? null : extraDataVal || null,
                valueNumber: extraDataSetting.type === 'extradata' ? null : extraDataVal || null,
                group: existingExtraData ? existingExtraData.group : key
            }
            if (existingExtraData) {
                mExtData['id'] = existingExtraData.id
            }

            extraDatas.push(mExtData);
        }
    });

    return extraDatas;
}

const emptyMachineSearchParams: IMachineSearchParams = {
    searchString: '',
    code: '',
    machineTypeIds: [],
    model: '',
    productNumber: '',
    yearOfManufacture: '',
    deliveryNumber: '',
    machineRequirementIds: [],
    extraLocation: '',
    weight: '',
    dimensions: '',
    url: '',
    memo: '',
    detailIds: [],
    costPools: [],
    suppliers: [],
    manufacturers: [],
    contractors: [],
    extraDatasWithGroup: {}
}

export const filtersToSearchParams = (filter: IMachineFilters): IMachineSearchParams => {
    let searchParams = emptyMachineSearchParams;
    searchParams.searchString = filter.searchString;
    searchParams.code = filter.code;
    searchParams.machineTypeIds = filter.machineType.map(i => Number.parseInt(i.value));
    searchParams.model = filter.model;
    searchParams.productNumber = filter.productNumber;
    searchParams.yearOfManufacture = filter.yearOfManufacture;
    searchParams.deliveryNumber = filter.deliveryNumber;
    searchParams.machineRequirementIds = filter.machineRequirements.map(i => Number.parseInt(i.value));
    searchParams.extraLocation = filter.extraLocation;
    searchParams.weight = filter.weight;
    searchParams.dimensions = filter.dimensions;
    searchParams.url = filter.url;
    searchParams.memo = filter.memo;
    searchParams.detailIds = Object.values(filter.details).flatMap(arr => arr.map(i => Number.parseInt(i.value)));
    searchParams.costPools = Object.values(filter.costPools).flatMap(arr => arr.map(i => Number.parseInt(i.value)));
    searchParams.suppliers = filter.thirdParties[ThirdPartyTypes.Supplier]?.map(i => Number.parseInt(i.value)) ?? [];
    searchParams.manufacturers = filter.thirdParties[ThirdPartyTypes.Manufacturer]?.map(i => Number.parseInt(i.value)) ?? [];
    searchParams.contractors = filter.thirdParties[ThirdPartyTypes.Contractor]?.map(i => Number.parseInt(i.value)) ?? [];
    searchParams.extraDatasWithGroup = filter.extraData;
    return searchParams;
}

/**
 * Takes IMachineFilters object and returns the number of active filters
 * @param filters
 */
export const countActiveMachineFilters = (filters: IMachineFilters) => {
    let count = 0;
    Object.entries(filters).forEach(([key, value]) => {
        try {
            if (isNullOrWhitespace(value)) {
                return;
            }
            else if (key === 'thirdParties') {
                // value of thirdParties filter is type of { [t: string]: IOptionType[];  }
                Object.keys(value).forEach(tpKey => {
                    if (value[tpKey].length > 0) {
                        count++;
                    }
                })
            }
            else if (key === 'details') {
                // value of details filter is type of { [d: string]: IOptionType[];  }
                Object.keys(value).forEach(detailKey => {
                    if (value[detailKey].length > 0) {
                        count++;
                    }
                })
            }
            else if (key === 'costPools') {
                // value of costPools filter is type of { [c: string]: IOptionType[];  }
                Object.keys(value).forEach(costPoolKey => {
                    if (value[costPoolKey].length > 0) {
                        count++;
                    }
                })
            }
            else if (key === 'extraData') {
                // value of extraData filter is type of { [e: string]: string;  }
                Object.keys(value).forEach(extraDataKey => {
                    if (!isNullOrWhitespace(value[extraDataKey])) {
                        count++;
                    }
                })
            }
            else if (Array.isArray(value)) {
                if (value.length > 0) {
                    count++;
                }
            }
            else {
                count++;
            }
        } catch (error) {
            console.log(error)
        }
    });
    return count;
}