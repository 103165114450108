import React from 'react';
import Select from 'react-select';
import i18n from '../../translations/i18n';
import FieldLabel from './FieldLabel';

const SelectField = (props: IProps) => {
    const getFieldLabel = () => {
        return (
            <FieldLabel required={props.required}>
                {props.label}
            </FieldLabel>
        );
    }

    const defaultOption = { value: '', label: i18n.t('NO_SELECTION') }

    const options = (props.noDefaultOption || props.isMulti) ? props.options : [defaultOption].concat(props.options);

    const colorStyles = {
        option: (styles, state) => {
            if (state.data?.value === '') {
                return { ...styles, color: 'grey !important' };
            }
            return styles;
        }
    }

    const handleChange = (value, actionMeta) => {
        if (value?.value === '') {
            value = null;
        }
        props.onChange(value, actionMeta)
    } 

    return (
        <React.Fragment>
            {props.label && getFieldLabel()}
            <Select
                placeholder={i18n.t('CHOOSE')}
                name={props.name}
                className="react-select-container"
                classNamePrefix="react-select"
                value={props.value}
                options={options}
                onChange={handleChange}
                isMulti={props.isMulti || false}
                disabled={props.disabled || false}
                isClearable={props.isMulti || !props.required}
                styles={colorStyles}
                noOptionsMessage={() => false}
            />
        </React.Fragment>
    )
}

interface IProps {
    name: string;
    value: IOptionType | IOptionType[];
    options: IOptionType[];
    onChange: (selectedOption, actionMeta) => void;
    isMulti?: boolean;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    noDefaultOption?: boolean;
}

export default SelectField;