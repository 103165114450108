import React from 'react';
import NavigationBar from '../../../navigation'
import { Container, Row, Col } from 'react-bootstrap';
import '../../../../styles/global.scss';
import '../styles/work-card.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { deleteWorkPhase, fetchWorkCard } from '../actions';
import {
    fetchViewSettings,
    getWorkCardColors
} from '../../../../commonActions/actions';
import i18n from '../../../../translations/i18n';
import FormList from '../../../../components/FormList';
import ConfirmDialogComponent from '../../../../components/ConfirmDialogComponent';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DefineColors, GetFormDataFromViewSettings, InitDialogBodyContent } from '../../../../components/HelperFunctions';
import { isArray } from 'util';
import { InfoContainer } from '../../../../components/InfoContainer';
import settingsAPI from '../../../../config/settingsAPI';
import { fetchWorkPhaseDetails } from '../../work-list/actions';

interface Props {
    workPhase: IWorkPhase;
    deleteWorkPhase: (wcId: number, phaseId: number, redirect) => void;
    match: any;
    history: any;
    location: any;
    fetchWorkCard: (id: number) => void;
    fetchViewSettings: (groupType: string, actionName: string, machineGroupId: number) => void;
    fetchWorkPhaseDetails: () => void;
    viewSettings: IWorkPhaseSettings[];
    workCard: any;
    wcColors: any;
    wpColors: any;
    settings: ISettingsState;
    getWorkCardColors;
    status: string;
}

interface State {
    showDialog: boolean;
    dialogType: string;
}

const moduleSettings = settingsAPI.moduleData.workphase;

class WorkPhase extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            dialogType: ''
        }
    }

    componentDidMount() {
        const { workPhase } = this.props;
        if (this.props.location.state) {
            const prevState = this.props.location.state;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                delete prevState.notificationMsg;
                this.props.history.replace({ state: prevState });
            }
        }

        this.props.fetchWorkCard(this.props.match.params.workCardId);
        this.props.fetchWorkPhaseDetails();
        this.props.getWorkCardColors();
    }

    componentDidUpdate(prevProps) {
        const { workPhase } = this.props;
        if (workPhase?.id !== prevProps.workPhase.id) {
            const mGroupId = workPhase?.machineGroupId;

            if (mGroupId) {
                moduleSettings.viewSettings.forEach(viewSetting => {
                    this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType, mGroupId);
                });
            }
        }
    }

    editWorkPhase = id => {
        this.props.history.push(id + '/edit/');
    }

    copyWorkPhase = item => {
        this.props.history.push(`/workcard/${item.workCardId}/workphase/new`, { defaultValues: item });
    }

    delete = () => {
        this.setState({
            showDialog: true,
            dialogType: 'delete'
        });
    }

    deleteWorkPhase = ({ workCardId, workPhaseId }: {workCardId: number, workPhaseId: number }) => {
        this.props.deleteWorkPhase(workCardId, workPhaseId, this.props.history.replace);
    }

    reportWorkPhase = workPhase => {
        const location = {
            pathname: workPhase.id + '/edit/',
            state: { reportReady: true }
        };
        this.props.history.push(location);
    }

    setDialogProperties = type => {
        this.setState({
            showDialog: true,
            dialogType: type
        })
    }

    resetDialogProperties = () => {
        this.setState({
            showDialog: false,
            dialogType: ''
        })
    }

    getCallbackFn = () => {
        const { dialogType } = this.state;
        if (dialogType === 'delete') return this.deleteWorkPhase;
    }

    getDialogContent = contentType => {
        const { workCardId, workPhaseId } = this.props.match.params;
        const { procedure } = this.props.workPhase;
        
        const dialogContents = {
            'delete': {
                title: i18n.t('DELETE_WORKCARD_PHASE'),
                body: InitDialogBodyContent('WORKPHASE', procedure),
                type: 'delete',
                params: {
                    workCardId: parseInt(workCardId, 10),
                    workPhaseId: parseInt(workPhaseId, 10)
                }
            }
        }

        return dialogContents[contentType];
    }

    navigateBackToWorklist = (params) => {
        this.props.history.replace(params.path, params.state);
    }

    render() {
        const { 
            workCard,
            workPhase, 
            viewSettings, 
            location, 
            history, 
            wpColors,
            settings,
            status
        } = this.props;

        const { showDialog } = this.state;

        const popoverData: {
            popoverClass: string;
            popoverActions: {}[]
        } = {
            popoverClass: 'menu-popover',
            popoverActions: [
                {
                    icon: 'edit',
                    label: i18n.t('EDIT'),
                    clickFn: this.editWorkPhase,
                    isActionFn: true,
                    params: [workPhase.id],
                    paClass: ''
                },
                {
                    icon: 'copy',
                    label: i18n.t('COPY'),
                    clickFn: this.copyWorkPhase,
                    isActionFn: true,
                    params: [workPhase],
                    paClass: ''
                },
                {
                    icon: 'delete',
                    label: i18n.t('REMOVE'),
                    clickFn: this.setDialogProperties,
                    isActionFn: true,
                    params: ['delete'],
                    paClass: ''
                },
            ]
        };

        let phaseViewSettings = [];

        if (workPhase?.id) {
            let key, keys = Object.keys(workPhase);
            let n = keys.length;
            let wpObj = { extraDatas: [] };
            while (n--) {
                key = keys[n];
                wpObj[key.toLowerCase()] = workPhase[key];
            }

            if (viewSettings) {
                phaseViewSettings = GetFormDataFromViewSettings([...viewSettings], wpObj, null, settings, workPhase.extraDatas);
            }
        }

        const sceneData = {
            view: moduleSettings.name,
            title: workCard && workCard.code,
            subPhase: workPhase && workPhase.id
                ? workPhase.procedure
                    ? workPhase.procedure
                    : i18n.t('WORKPHASE') + ' #' + workPhase.id
                : '?',//TODO: add tranlsation 'WORKPHASE_NOT_FOUND'
            location: location,
            history: history,
            itemColors: wpColors,
            backAction: location.state && location.state.scrollPosition
                ? {
                    action: this.navigateBackToWorklist,
                    params: {
                        path: '/worklist',
                        state: { heightPos: location.state.scrollPosition || 0 }
                    }
                }
                : { action: history.goBack }
        }

        return (
            <div>
                {showDialog && <ConfirmDialogComponent
                    dialogContent={this.getDialogContent(this.state.dialogType)}
                    callBack={this.getCallbackFn()}
                    cancelDialog={() => { }}
                />}
                <NavigationBar
                    currentView={sceneData}
                    navHistory={this.props}
                    viewAction="settings"
                    popoverData={popoverData}
                />
                {workPhase && <Container>
                    {workPhase.id ? (
                        phaseViewSettings.map(item => {
                            const phaseValue = item.value;

                            const listingStyle = null;

                            return <Row key={item.field} className="margin-bottom-5">
                                <Col xs={4} className="sub-header">
                                    {i18n.t(item.translationKey)}
                                </Col>
                                <Col xs={8} data-cy={item.field}>
                                    {(phaseValue !== undefined && phaseValue !== null) && 
                                        isArray(phaseValue)
                                            ? <FormList list={phaseValue} listItemName='name' listingStyle={listingStyle} />
                                            : phaseValue && phaseValue.id
                                                ? phaseValue.name || phaseValue.label || phaseValue.description
                                                : phaseValue
                                        
                                    }
                                </Col>
                            </Row>
                        })
                    ) : status === 'error' ? <InfoContainer type={'notfound'} content={i18n.t('WORKPHASE_NOT_FOUND')} /> : <></>}
                    
                </Container>}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    deleteWorkPhase,
    fetchWorkCard,
    fetchViewSettings,
    fetchWorkPhaseDetails,
    getWorkCardColors
}, dispatch)

const mapStateToProps = (state, ownProps) => {
    const wcId = ownProps.match.params.workCardId
    const wpId = ownProps.match.params.workPhaseId
    const isOffline = !state.offline.online;

    const wc = !isOffline
        ? state.workcards.workcards.find(wc => wc.id === parseInt(wcId, 10))
        : state.workcards.storedWorkCards.find(wc => wc.id === parseInt(wcId, 10));
    const wp = (wc && wc.phases.find(wp => wp.id === parseInt(wpId, 10))) || {};

    const wpColorConfigs = {
        colors: state.settings.colors,
        settings: state.settings.colorSettings,
        config: state.settings.colorConfig['FillPhaseTags']
    }

    return {
        settings: state.settings,
        workCard: wc,
        workPhase: wp,
        statusTypes: state.settings.statusTypes,
        wpColors: wp && wp.id ? DefineColors(wp, wpColorConfigs) : null,
        viewSettings: state.workcards.viewSettings && state.workcards.viewSettings.workcardphase,
        status: state.workcards.wcStatus
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkPhase);
