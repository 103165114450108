import React, { useState, useReducer, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { HandleError, isNullOrWhitespace } from './HelperFunctions';
import FormElement from 'scenes/work-schedule/work-card/components/FormElement';
import i18n from 'translations/i18n';
import noviAPI from 'api/noviAPI';
import { toast } from 'react-toastify';
import { FETCH_THIRD_PARTY_DETAILS } from 'config/actionTypes';
import { handleDateTimeInput } from 'utils';

type IProps = {
    addLocalThirdParty: (newThirdParty) => void;
    additionState: {
        type: number;
        submit: boolean;
    };
    formRef: any;
    setSubmit: (enabled: boolean) => void;
}

const ThirdPartyAddition = (props: IProps) => {
    
    const { additionState, addLocalThirdParty, setSubmit } = props;
    const { type, submit } = additionState;

    const [viewSettings, setViewSettings] = useState([]);
    const [invalidFields, setInvalidFields] = useState([]);

    const details = useSelector((state: State) => state.settings.thirdPartyDetails);
    const { thirdParties } = useSelector((state: State) => state.settings);
    const tPartyType = thirdParties?.allTypes?.find(t => t.id == type);
    const [existingCodes, setExistingCodes] = useState([]);
    const dispatch = useDispatch();

    const [formValues, setValue] = useReducer((oldValues, newValue) => {
        return Object.assign({}, oldValues, newValue);
    }, {});

    useEffect(() => {
        const existingCodes = Object.values(thirdParties.byType).flatMap(byType => byType.map(tP => tP.code));
        setExistingCodes(existingCodes);
    }, [thirdParties])
    
    useEffect(() => {
        noviAPI.globalSettings.getByGroup('thirdparty')
            .then(({ data }) => {
                const requiredFields = data
                    .filter(({ required, field }) => required?.toLowerCase() == "true" || ['name', 'code'].includes(field))
                    .sort((a, b) => a.taborder - b.taborder);

                setViewSettings(requiredFields);
            })
            .catch(error => {
                console.error(error);
                HandleError(error, "Fetch thirdparty view settings");
            })
    }, []);

    useEffect(() => {
        if (viewSettings.find(field => field.type === 'detail')) {
            noviAPI.thirdPartyDetails.fetchAll()
                .then(({ data }) => {
                    dispatch({
                        payload: data,
                        type: FETCH_THIRD_PARTY_DETAILS
                    })
                })
                .catch(error => {
                    console.error(error);
                    HandleError(error, "Fetch thirdparty detail options")
                })
        }
    }, [viewSettings, dispatch]);

    useEffect(() => {
        if (submit) {

            if (!validateFields()) {
                toast.error(i18n.t("INVALID_FIELDS"), { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
                setSubmit(false);
                return;
            }

            if (!validateCode()) {
                toast.error(i18n.t("CODE_ALREADY_IN_USE"), { position: toast.POSITION.TOP_CENTER, hideProgressBar: true });
                setSubmit(false);
                return;
            }

            const newThirdParty = mapFormValuesToAdditionModel(formValues);

            noviAPI.thirdParties.validate(newThirdParty)
                .then((data) => {
                    addLocalThirdParty(newThirdParty);
                })
                .catch(err => {

                    const errors = err.response?.data?.errors;

                    // Backend validation errors have 2 different types:
                    // - Array of { reason: <errorMessage> } objects
                    // - Single object { errors: { [field]: <errorMessage> } }
                    const errorsList = Array.isArray(errors) 
                        ? errors.map(x => x.reason) 
                        : Object.values(errors);

                    errorsList.forEach(errorMessage => {
                        toast.error(i18n.t(errorMessage), { 
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                         })
                    });
                })
                .finally(() => {
                    setSubmit(false);
                })
        }
    }, [submit]);
    
    const validateFields = () => {
        let invalidFields = [];

        viewSettings.forEach(({ field, type }) => {
            const v = formValues[field];
            const value = typeof v === 'object' ? v?.value : v;

            const hasValue = !isNullOrWhitespace(value) && value != -1;

            if (!hasValue && type != 'boolean') {
                invalidFields.push(field)
            }

        });

        setInvalidFields(invalidFields);
        return invalidFields.length === 0;
    }

    const validateCode = () => {
        const duplicateFields = [];

        if (existingCodes.find(code => code === formValues.code)) {
            duplicateFields.push("code");
        }
        setInvalidFields(duplicateFields)
        return duplicateFields.length === 0;
    }

    const mapFormValuesToAdditionModel = formValues => {
        const obj = {};
        Object.entries(formValues).forEach(([ key, value ]) => {
            const newKey = typeof value === 'object' ? key + 'id' : key;
            const newValue = (typeof value === 'object' ? value?.['value'] : value);

            obj[newKey] = newValue;
        });

        return obj;
    }

    return (
        <Container className={"bottom-nav-space"} >

            <div className="form-table-container">
                <form onSubmit={null} ref={props.formRef}>
                    <FormElement 
                        name="role"
                        type="text"
                        label={i18n.t("TYPE")}
                        value={tPartyType?.name}
                        required={true}
                        onChange={null}
                        isDisabled={true}
                    />
                    {viewSettings.map(vs => {
                        const { field, group, id, label, options, tabOrder, type, values, required } = vs;
                        const value = formValues[field];
                        return (
                            <div key={id} className={invalidFields.includes(field) ? 'invalid-field' : ''}>
                                <FormElement 
                                    name={field}
                                    type={type === 'boolean' ? 'singleline-checkbox' : type}
                                    label={i18n.t(label)}
                                    options={type === 'detail' ? details?.[field] ?? [] : []}
                                    value={value}
                                    required={true}
                                    onChange={(e) => {
                                        if (type === 'boolean') {
                                            setValue({ [field]: !value });
                                        }
                                        else if (type === 'datetime' && e?._isAMomentObject) {
                                            const { value } = handleDateTimeInput(e, field);
                                            setValue(({ [field]: value }));
                                        }
                                        else {
                                            const value = type === 'detail' ? e : e.target?.value;
                                            setValue(({ [field]: value }));
                                        }
                                    }}
                                />
                            </div>
                        );
                    })}
                </form>
            </div>
        </Container>
    );
}

export default ThirdPartyAddition;