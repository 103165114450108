import React from 'react';
import { faSquareFull, faTasks, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { DetermineBackgroundColor, GetTypeTranslationKey } from '../../../../components/HelperFunctions';
import TableComponent from '../../../../components/TableComponent';
import TableRowComponent from '../../../../components/TableRowComponent';
import settingsAPI from '../../../../config/settingsAPI';
import i18n from '../../../../translations/i18n';
import FormList from '../../../../components/FormList';
import { getDateTime } from 'utils';

function createCodeTableDataCellElement(workCard, daysLate, finishedWorkStasuses: Number[]) {
    const { isRouteMaintenanceWorkCard, isTimedOperatorMaintenanceWorkCard } = workCard;

    return (
        <div className="work-code">
            <div>
                <span className="code-info">
                    {isRouteMaintenanceWorkCard ? <FontAwesomeIcon icon={faTasks} size="lg" />
                        : isTimedOperatorMaintenanceWorkCard ? <FontAwesomeIcon icon={faTools} size="lg" />
                            : ''}
                    <span className='left-margin-5'>{isTimedOperatorMaintenanceWorkCard || isRouteMaintenanceWorkCard ? `${workCard.id}` : workCard.code}</span>
                </span>
            </div>
            <span className={'dayslate bold ' + ((daysLate < 0 && !finishedWorkStasuses.includes(workCard.workStatus))? 'workcard-delayed' : '')}>
                <FontAwesomeIcon icon={faSquareFull} color={DetermineBackgroundColor(workCard.colors)} />
                <span className='left-margin-5'>{!finishedWorkStasuses.includes(workCard.workStatus) && daysLate}</span>
            </span>
        </div>
    );
}

function handleMachines(workCard: IWorkCard) {
    if (workCard.isTimedOperatorMaintenanceWorkCard) {
        return <span>{workCard['operatorMaintenance'].machine ? `${workCard['operatorMaintenance']?.machine.code} / ${workCard['operatorMaintenance']?.machine.name}` : ''}</span>
    } else if (workCard.isRouteMaintenanceWorkCard && workCard.routeMachines?.length > 0) {
        return (
            <div className='machine-column-wrap'>
                {workCard.routeMachines.map(i =>
                    <span>{`${i.code} / ${i.name}`}</span>)}
            </div>
            )
    } else {
        return <span>{workCard.machine ? `${workCard.machine.code} / ${workCard.machine.name}` : ''}</span>
    }
}

function createRowData(columns, workCardRow: { daysLate: number; workCard: IWorkCard; }, settings) {
    const row = {};
    const columnNames = columns.map(column => column.name);
    const NUMBER_TYPES = ['number', 'decimal'];

    function isNumberType(property) {
        return NUMBER_TYPES.includes(columns.find(column => column.name === property.toLowerCase()).type);
    }

    const { workCard, daysLate } = workCardRow;
    
    // We need to add code & machine properties to RouteMaintenanceWorkCards && OperatorMaintenanceWorkCards to match rendering 
    if (workCard.isRouteMaintenanceWorkCard || workCard.isTimedOperatorMaintenanceWorkCard) {
        workCard.code = null
        workCard.machine = null
    }

    // handle properties of work card
    for (const property in workCard) {
        if (columnNames.includes(property.toLowerCase())) {
            let value = workCard[property];
            let lowerCaseProperty = property.toLowerCase();

            if ('color' === lowerCaseProperty || 'latedays' === lowerCaseProperty) {
                return;
            }
            else if ('code' === lowerCaseProperty) {
                value = createCodeTableDataCellElement(workCard, daysLate, settings.finishedWorkStasuses)
            }
            else if ('machine' === lowerCaseProperty) {
                value = handleMachines(workCard)
            }
            else if ('workstatus' === lowerCaseProperty) {
                const translationKey = GetTypeTranslationKey(property, value, settings);
                value = i18n.t(translationKey);
            }
            else if ('worktype' === lowerCaseProperty) {
                const workTypeGroup = workCard.details.find(i => i.group === 'worktype');
                value = workTypeGroup ? workTypeGroup.value : '';
            }
            else if ('machinehalt' === lowerCaseProperty) {
                value = value ? i18n.t('YES') : i18n.t('NO');
            }
            else if ('urgency' === lowerCaseProperty) {
                const translationKey = GetTypeTranslationKey(property, value, settings);
                value = i18n.t(translationKey);
            }
            else if ('worker' === lowerCaseProperty) {
                const reduceWorkers = (result, worker) => {
                    if (result.some(i => i.id === worker.id)) {
                        return result;
                    }
                    return result.concat(worker);
                }

                let workers = [];

                workCard.phases.forEach(phase => {
                    workers = phase.workers.reduce(reduceWorkers, workers);
                });

                workers = workCard.workers.reduce(reduceWorkers, workers);

                value = <span>
                    <FormList list={workers.slice(0, 3)} listItemName="name" />
                    {workers.length > 3 && <span>{i18n.t('AND')} {workers.length - 3} {i18n.t('OTHER_WORKERS')}</span>}
                </span>
            }
            else if ('workertext' === lowerCaseProperty) {
                value = workCard.workerName;
            }
            else if ('workergroup' === lowerCaseProperty) {
                value = FormList({ list: workCard.workerGroups, listItemName: 'name' });
            }
            else if ('workcardtype' === lowerCaseProperty) {
                const translationKey = `WORK_CARD_TYPE${value}`;
                value = i18n.t(translationKey);
            }
            else if ('revision' === lowerCaseProperty) {
                const revision = workCard.details.find(i => i.group === 'revision');
                value = revision ? revision.value : '';
            }
            else if ('orderer' === lowerCaseProperty) {
                value = workCard.ordererText || workCard.orderer?.name;
            }
            else /* check type */ {
                let type = columns.find(i => i.name === lowerCaseProperty).type;
                if (type === 'datetime') {
                    value = getDateTime(value);
                }
            }

            Object.assign(
                row,
                { [lowerCaseProperty]: value || (isNumberType(property) ? 0 : '') }
            );
        }
    }

    // handle situation where column name wasn't found as a property in work card
    for (const column of columns) {
        if (!row.hasOwnProperty(column.name)) {
            Object.assign(
                row,
                { [column.name]: NUMBER_TYPES.includes(column.type) ? 0 : '' }
            );
        }
    }

    // handle required properties to identify different types of work cards and other required data.
    for (const property in workCard) {
        if (!row.hasOwnProperty(property)) {
            switch (property.toLowerCase()) {
                case 'isroutemaintenanceworkcard':
                case 'istimedoperatormaintenanceworkcard':
                case 'operatormaintenance':
                    Object.assign(
                        row,
                        { [property]: workCard[property]}
                    );
                    break;
                default:
                    break;
            }
        }
    }

    Object.assign(row, { id: workCardRow.workCard.id });

    return row;
}

const WorkCardTable = ({ tableSettings, workCards }: IProps) => {
    let history = useHistory();
    const statusTypes = useSelector((state: State) => state.settings.statusTypes);
    const urgencyTypes = useSelector((state: State) => state.settings.urgencyTypes);
    const finishedWorkStasuses = useSelector((state: State) => state.workcards.workcardsSettings.finishedWorkStatuses ? state.workcards.workcardsSettings.finishedWorkStatuses : []);
    
    const headings = tableSettings
        .sort((a, b) => a.tabOrder - b.tabOrder)
        .filter(setting => setting.field !== 'color' && setting.field !== 'latedays')
        .map(setting => ({
            label: i18n.t(setting.label),
            width: settingsAPI.wcGridColSizes[setting.field] ? settingsAPI.wcGridColSizes[setting.field] : '120px'
        }));

    const columns = tableSettings
        .filter(setting => setting.field !== 'color' && setting.field !== 'latedays')
        .map(setting => ({ name: setting.field, type: setting.type }));

    const rows = workCards.map(workCard => {
        return createRowData(columns, workCard, { statusTypes, urgencyTypes, finishedWorkStasuses });
    })

    const handleClick = row => {
        let path = '';
        if (row.isRouteMaintenanceWorkCard) {
            path = `/routeworkcard/${row.id}`;
        } else if (row.isTimedOperatorMaintenanceWorkCard) {
            path = `/operatormaintenance/${row.operatorMaintenance.id}`;
        } else {
            path = `/workcard/${row.id}`;
        }
        history.push(path);
    }

    return (
        <TableComponent headings={headings}>
            {rows.map((row, i) => (
                <TableRowComponent key={i} handleClick={() => handleClick(row)}>
                    {columns.map(column => (
                        row[column.name]
                    ))}
                </TableRowComponent>
            ))}
        </TableComponent>
    );
}

interface IProps {
    tableSettings: IUserSetting[];
    workCards: { daysLate: number; workCard: IWorkCard }[];
}

export default WorkCardTable;
