import request from '../api/interceptors';
import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue';
import * as types from './actionTypes';
import { isArray } from 'util';
import { toast } from 'react-toastify';
import i18n from '../translations/i18n';
import { Toaster, HandleError } from '../components/HelperFunctions';

const EFFECT = 'EFFECT';
const COMMIT = 'COMMIT';
const ROLLBACK = 'ROLLBACK';

// Effect and discard functions using the axios library
export const effect = (effect, _action) => request(effect);

export const discard = (error, _action, _retries) => {
    const { request, response } = error;

    if (!request) throw error; // There was an error creating the request
    if (!response) return false; // There was no response
    if (response.status === 500 && response.data.length > 0) {
        let errMsg = '';

        response.data.forEach(msg => {
            errMsg = errMsg + ' ' + i18n.t(msg);
        })
        toast.error(errMsg, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });

        return true;
    } else if (response.status === 401) {
        Toaster({ msg: 'SESSION_HAS_BEEN_EXPIRED', type: 'info' });
    } else if (response.status === 400) {
        HandleError(error, 'Request failed');
    }
    return 400 <= response.status && response.status < 500;
};

export const queue = {
    ...defaultQueue,
    dequeue
}

// Dequeue executes when action has been successfully resolved or discarded.
// Update actions in queue that have relations to the resolved action so actions'
// data in queue relate to new ID returned from the API and not the old temporary ID.
function dequeue(outbox, resolvedAction, _context) {
    // Unpack resolved action from outbox and assign the remaining actions to a variable (rest).
    const [, ...rest] = outbox;
    // If we know that action can have relations, continue, otherwise just return the rest
    if (// Add action type here if it changes temporary ID of a data item to real ID 
        // and if the change of the ID affects how other actions in the queue are going to be resolved
        resolvedAction.type !== `${types.ADD_WORKCARD}_COMMIT`
        && resolvedAction.type !== `${types.ADD_WORKCARD_DOCUMENT}_COMMIT`
        && resolvedAction.type !== `${types.ADD_WORKPHASE}_COMMIT`
        && resolvedAction.type !== `${types.ADD_MACHINE_DOCUMENT}_COMMIT`
        && resolvedAction.type !== `${types.ADD_OPERATOR_MAINTENANCE_DOCUMENT}_COMMIT`
        && resolvedAction.type !== `${types.ADD_MATERIAL}_COMMIT`
    ) { return rest; }

    // Check if remaining actions are related to the resolved action
    const newOutbox = rest.map(action => {
        // Get property name of the ID that is related to ID of the resolved action
        const name = getPropertyNameByActionTypes(action.type, resolvedAction.type);
        // Compare the parent ID in a payload of an action in the queue to the temporary ID of the resolved action
        if (action.payload[name] !== resolvedAction.meta.tempId) {
            return action;
        }

        // Deconstruct response of resolved action
        const { data: id } = resolvedAction.payload;

        // Update IDs of the related actions in the queue so they equal to the new value returned from the API
        // TODO: no arrays
        const property = { [name]: isArray(id) ? id[0] : id }
        return updateAction(action, property);
    });

    // Return the updated outbox
    return newOutbox;
}

// Update ID in both payload of the action and offline meta data with the new ID value
function updateAction(action, property) {
    return {
        ...action,
        payload: updatePayload(action, property),
        meta: updateOfflineMetaData(action, property)
    }
}

const updatePayload = (action, property) => {
    const { payload } = action;

    // Make copy of action payload (to avoid mutating) and merge the property with the new ID
    const newPayload = Object.assign({}, payload, property);
    // Return updated action payload
    return newPayload;
}

const updateOfflineMetaData = (action, property) => {
    // Make a copy of offline meta data to avoid mutating state
    const offline = Object.assign({}, action.meta.offline)

    // If offline action exists, update and merge it
    if (offline.effect) {
        Object.assign(offline, updateOfflineAction(action, property, EFFECT))
    }

    if (offline.commit) {
        Object.assign(offline, updateOfflineAction(action, property, COMMIT))
    }

    if (offline.rollback) {
        Object.assign(offline, updateOfflineAction(action, property, ROLLBACK))
    }

    // Return updated offline action(s)
    return {
        ...action.meta,
        offline
    }
}

const updateOfflineAction = (action, property, offlineActionType) => {
    // Check offline action type to be updated
    switch (offlineActionType) {
        case EFFECT:
            const { effect } = action.meta.offline;
            // If request method is 'POST' make changes to request data
            if (effect.method.toLowerCase() === 'post') {
                return {
                    effect: {
                        ...effect,
                        // Make copy of effect.data (to avoid mutating) and merge the property with the new ID
                        data: Object.assign({}, effect.data, property)
                    }
                }
            }
            // If request method is 'PUT' make changes to URL or DATA used for the request
            else if (effect.method.toLowerCase() === 'put') {
                // Set value to go to the new url. If 'id' doesn't exist in property, url is not wanted to be updated. 
                // In that case property will have other key which value needs to update effect.data.
                // e.g. response of ADD_MATERIAL_COMMIT will be added to property.id and url of UPDATE_MATERIAL needs to be updated with it
                //      but response of ADD_WORK_CARD_COMMIT will be added to property.workCardId and effect.data of EDIT_MATERIAL needs to merged with it
                const value = property['id'];
                return {
                    effect: {
                        ...effect,
                        // Set new URL based on action type
                        ...(typeof value !== 'undefined' && { url: getUrl(action, value) }),
                        // Merge the effect.data with the new ID
                        ...(typeof value === 'undefined' && { data: Object.assign({}, effect.data, property) })
                    }
                }
            }
            // If request method is 'DELETE' make changes to URL used for the request
            else {
                const value = Object.values(property)[0];
                return {
                    effect: {
                        ...effect,
                        // Set new URL based on action type
                        url: getUrl(action, value)
                    }
                }
            }
        case COMMIT:
            const { commit } = action.meta.offline;
            return {
                commit: {
                    ...commit,
                    // Make copy of commit.meta (to avoid mutating) and merge the property with the new ID
                    meta: Object.assign({}, commit.meta, property)
                }
            }
        case ROLLBACK:
            const { rollback } = action.meta.offline;
            return {
                rollback: {
                    ...rollback,
                    // Make copy of rollback.meta (to avoid mutating) and merge the property with the new ID
                    meta: Object.assign({}, rollback.meta, property)
                }
            }
    }
}

const getUrl = (action, value) => {
    switch (action.type) {
        case types.DELETE_WORKCARD_DOCUMENT:
            return `/api/WorkCards/documents/${value}`;
        case types.DELETE_MACHINE_DOCUMENT:
            return `/api/Machines/documents/${value}`;
        case types.UPDATE_MATERIAL:
        case types.DELETE_MATERIAL:
            return `/api/WorkCards/spareParts/${value}`;
        default:
            return action.meta.offline.effect.url
    }
}

const getPropertyNameByActionTypes = (actionType, resolvedActionType) => {
    switch (resolvedActionType) {
        case `${types.ADD_WORKCARD}_COMMIT`:
            switch (actionType) {
                case types.ADD_WORKPHASE:
                    return 'workCardId';
                case types.ADD_WORKCARD_DOCUMENT:
                    return 'workCardId';
                case types.ADD_MACHINE_DOCUMENT:
                    return 'machineId';
                case types.ADD_OPERATOR_MAINTENANCE_DOCUMENT:
                    return 'operatorMaintenanceId';
                case types.DELETE_WORKCARD_DOCUMENT:
                    return 'wcId';
                case types.DELETE_MACHINE_DOCUMENT:
                    return 'mId';
                case types.ADD_MATERIAL:
                case types.UPDATE_MATERIAL:
                    return 'workCardId';
            }
            break;
        case `${types.ADD_WORKCARD_DOCUMENT}_COMMIT`:
            switch (actionType) {
                case types.DELETE_WORKCARD_DOCUMENT:
                    return 'id';
            }
            break;
        case `${types.ADD_MACHINE_DOCUMENT}_COMMIT`:
            switch (actionType) {
                case types.DELETE_MACHINE_DOCUMENT:
                    return 'id';
            }
            break;
        case `${types.ADD_OPERATOR_MAINTENANCE_DOCUMENT}_COMMIT`:
            switch (actionType) {
                case types.DELETE_OPERATOR_MAINTENANCE_DOCUMENT:
                    return 'id';
            }
            break;
        case `${types.ADD_MATERIAL}_COMMIT`:
            switch (actionType) {
                case types.UPDATE_MATERIAL:
                case types.DELETE_MATERIAL:
                    return 'id';
            }
    }
}
