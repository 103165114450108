import noviAPI from "api/noviAPI";
import { HandleError, HasRight, Toaster } from "components/HelperFunctions";
import { FETCH_SAFETY_NOTICES } from "config/actionTypes";
import { SortTypes } from "constants/quickSearches/SortTypes";
import { UserRights } from "constants/userRights";
import { addSortingParams } from "./utils";

export type SearchSafetyCards = (safetyTypeId: string) => void;
export const searchSafetyCards: SearchSafetyCards = safetyTypeId => (
    async (dispatch, getState: () => State) => {

        const { machineGroupId } = getState().settings;
        const pagination = getState().safetycards.settings;

        if (machineGroupId && safetyTypeId) {

            dispatch({ 
                type: `${FETCH_SAFETY_NOTICES}_PENDING` 
            });
            
            let searchParams = new URLSearchParams();

            searchParams.append('machineGroupId', `${getState().settings.machineGroupId}`);
            searchParams.append('SafetyTypeId', safetyTypeId);

            searchParams.append('pageSize', `${pagination.pageSize ?? 5}`);
            searchParams.append('pageNumber', `${pagination.pageNumber - 1}`);

            addSortingParams(searchParams, SortTypes.safetyNotices.NEWEST);

            noviAPI.safetyNotices.search(searchParams)
                .then(({ data }) => {
                    dispatch({
                       type: `${FETCH_SAFETY_NOTICES}_FULFILLED`,
                       payload: data
                    })
                })
                .catch(error => {
                    HandleError(error, "Search safety notices");
                    dispatch({
                        type: `${FETCH_SAFETY_NOTICES}_REJECTED`,
                        error: error
                    })
                })
        }

    }
)

export const addSafetyNoticeDocument = (document, addToasterMsg = true) => {
    return async (dispatch, getState: () => State) => {
        noviAPI.documents.add(document)
            .then(data => {
                if (addToasterMsg) {
                    Toaster({ msg: 'DOCUMENT_ADDED', type: 'success' });
                }
            })
            .catch(error => {
                console.log("[ ERROR ADDING SAFETY NOTICE DOCUMENT ]", error, document);
                HandleError(error, 'Add document');
            });

    }
}