import { Loader } from 'components/Loader';
import React from 'react';
import i18n from 'translations/i18n';
import MachineTreeSingleMachine from "./MachineTreeSingleMachine";

const MachineTree = ({ machines, machineSelect, onMachineSelect, itemStatus, params, referrer, status = '' }) => {
    const fromId = params && (params.workCardId || params.machineId|| params.sparePartId);

    let targetData;
    if (itemStatus === 'newWorkCard') targetData = { target: 'workcard', action: 'new', targetId: fromId, referrer, itemStatus }
    if (itemStatus === 'newFaultNotice') targetData = { target: 'workcard', action: 'new', targetId: fromId, referrer, itemStatus }
    if (itemStatus === 'editWorkCard') targetData = { target: 'workcard', action: 'edit', targetId: fromId, referrer, itemStatus }
    if (itemStatus === 'newMachine') targetData = { target: 'machine', action: 'new', targetId: fromId, referrer }
    if (itemStatus === 'editMachine') targetData = { target: 'machine', action: 'edit', targetId: fromId, referrer }
    if (itemStatus === 'warehouseoperation') targetData = { target: 'warehouseoperations', action: 'take', targetId: fromId, referrer }
    if (itemStatus === 'newSafetyNotice') targetData = { target: `safetynotices/${params.id}`, action: 'new', targetId: fromId, referrer }

    if (status === 'pending') {
        return <Loader status="pending" />
    }
    if (machines.length === 0) {
        return (
            <div className="no-results">
                <p>{i18n.t('NO_RESULTS')}</p>
            </div>
        );
    }
    return (
        <div>
            {Object.keys(machines).map(key => (
                <MachineTreeSingleMachine
                    key={machines[key].id}
                    machine={machines[key]}
                    machineSelect={machineSelect}
                    onMachineSelect={onMachineSelect}
                    targetData={targetData}
                />
            ))}
        </div>
    );
}

export default MachineTree;