import React from 'react';
import { useSelector } from 'react-redux';
import { HasRight } from '../../../components/HelperFunctions';
import PopoverAction from '../../../components/PopoverAction';
import { UserRights } from '../../../constants/userRights';
import i18n from '../../../translations/i18n';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';

const MachineActionMenu = (props: IProps) => {
    const userRights = useSelector((state: State) => state.settings.userRights);

    const menuContent = [];
    if (HasRight(UserRights.MachineRegistryDelete, userRights)) {
        menuContent.push(<div key="remove">
            <PopoverAction
                icon="delete"
                label={i18n.t('REMOVE')}
                onClick={props.deleteMachine}
            />
            <hr />
        </div>);
    }
    if (HasRight(UserRights.MachineRegistryEdit, userRights)) {
        menuContent.push(<div key="edit">
            <PopoverAction
                icon="edit"
                label={i18n.t('EDIT')}
                onClick={props.openMachineEdit}
            />
            <hr />
        </div>);
    }
    if (HasRight(UserRights.MachineRegistryEdit, userRights)) {
        menuContent.push(<div key="add_material">
            <PopoverAction
                icon="add-circle"
                label={i18n.t('ADD_MATERIAL')}
                onClick={props.openNewMaterial}
            />
            <hr />
        </div>);
    }
    if (HasRight(UserRights.MachineRegistryEdit, userRights)) {
        menuContent.push(<div key="add_document">
            <PopoverAction
                icon="add-circle"
                label={i18n.t('ADD_DOCUMENT')}
                onClick={props.openNewDocument}
            />
            <hr />
        </div>);
    }
    if (HasRight(UserRights.FaultNotice, userRights)) {
        menuContent.push(<div key="fault_notice">
            <PopoverAction
                icon="task"
                label={i18n.t('FAULT_NOTICE')}
                onClick={props.openFaultNotice}
            />
            <hr />
        </div>);
    }
    if (HasRight(UserRights.WorkScheduleAdd, userRights)) {
        menuContent.push(<div key="new_work_card">
            <PopoverAction
                icon="task"
                label={i18n.t('NEW_WORKCARD')}
                onClick={props.openNewWorkCard}
            />
        </div>);
    }

    return (
        <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose={true}
            overlay={
                <Popover id="settings-menu-popover" className="menu-popover">
                    {React.Children.map(menuContent, (child, i) => (
                        <div className="sub-section" key={`setting_${i}`}>
                            <div className="setting-option">
                                {child}
                            </div>
                        </div>
                    ))}
                </Popover>
            }
        >
            <Button
                id="footerMenuButton"
                variant="primary"
                className="action action-button novi-default-btn-color"
                size="lg"
            >
                <div>
                    <FontAwesomeIcon icon={faChevronCircleUp} size="lg" />
                </div>
            </Button>
        </OverlayTrigger>
    );
}

interface IProps {
    deleteMachine: () => void;
    openMachineEdit: () => void;
    openNewMaterial: () => void;
    openNewDocument: () => void;
    openFaultNotice: () => void;
    openNewWorkCard: () => void;
}

export default MachineActionMenu;
