import * as types from '../../config/actionTypes';
import request from '../../api/interceptors';
import { HandleError, Toaster } from '../../components/HelperFunctions';
import noviAPI from '../../api/noviAPI';

export const fetchOperatorMaintenance = (opMaintenanceId: number) => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_OPERATOR_MAINTENANCE}_PENDING`,
        });
        try {
            
            const { data } = await noviAPI.operatorMaintenances.fetch(opMaintenanceId);

            dispatch({
                type: `${types.FETCH_OPERATOR_MAINTENANCE}_FULFILLED`,
                payload: {
                    opmId: opMaintenanceId,
                    data: data
                }
            });
        } catch (error) {
            HandleError(error, 'Fetch operator maintenance');

            dispatch({
                type: `${types.FETCH_OPERATOR_MAINTENANCE}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchProcedureDataTypes = () => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_PROCEDURE_DATATYPES}_PENDING`,
        });
        try {
            const url = '/api/OperatorMaintenances/datatypes';
            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_PROCEDURE_DATATYPES}_FULFILLED`,
                payload: data
            });
        } catch (error) {
            HandleError(error, 'Fetch procedure datatypes');

            dispatch({
                type: `${types.FETCH_PROCEDURE_DATATYPES}_REJECTED`,
                payload: error
            })
        }
    }
);

export const saveOperatorMaintenance = payload => ({
    type: types.SAVE_OPERATOR_MAINTENANCE,
    payload
});

export const fetchOperatorMaintenanceDocuments = opMaintenanceId => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_OPMAINTENANCE_DOCUMENTS}_PENDING`,
        });
        try {
            const url = '/api/OperatorMaintenances/' + opMaintenanceId + '/documents';

            const { data } = await request.get(url);

            dispatch({
                type: `${types.FETCH_OPMAINTENANCE_DOCUMENTS}_FULFILLED`,
                payload: data
            })
        } catch (error) {
            HandleError(error, 'Fetch operator maintenance documents');

            dispatch({
                type: `${types.FETCH_OPMAINTENANCE_DOCUMENTS}_REJECTED`,
                payload: error
            })
        }
    }
);

export const fetchPointImage = docItem => (
    async dispatch => {
        dispatch({
            type: `${types.FETCH_POINT_IMAGE}_PENDING`,
            payload: docItem.id
        });
        try {
            const doc = docItem.documents[0];
            if (doc) {

                if (doc.files[0]?.isUrl) {
                    dispatch({
                        type: `${types.FETCH_POINT_IMAGE}_FULFILLED`,
                        payload: {
                            fileData: {
                                url: doc.documentLink,
                                type: "url",
                            },
                            docItemId: docItem.id
                        }
                    })
                }
                else {
                    const { data } = await request.get(doc.downloadLink, { responseType: 'blob' });
                    const url = window.URL.createObjectURL(data);
                    dispatch({
                        type: `${types.FETCH_POINT_IMAGE}_FULFILLED`,
                        payload: {
                            fileData: {
                                url: url,
                                type: data.type,
                            },
                            docItemId: docItem.id
                        }
                    })

                }

            } else {
                dispatch({
                    type: `${types.FETCH_POINT_IMAGE}_FULFILLED`,
                    payload: {
                        fileData: null,
                        docItemId: docItem.id
                    }
                })
            }
        } catch (error) {
            HandleError(error, 'Fetch operator maintenance point image');

            dispatch({
                type: `${types.FETCH_POINT_IMAGE}_REJECTED`,
                payload: {
                    error: error,
                    docItemId: docItem.id
                }
            })
        }
    }
);

export const deleteOpmDocument = id => (
    dispatch => {
        noviAPI.operatorMaintenances.deleteDocument(id)
            .then(() => {
                dispatch({
                    type: types.DELETE_OPMAINTENANCE_DOCUMENT,
                    payload: id
                });

                Toaster({
                    msg: 'DOCUMENT_DELETED',
                    type: 'success'
                });
            })
            .catch(error => {
                HandleError(error, 'Delete document');
            });
    }
);

export type IAddOperatorMaintenanceDocument = (
    document: IOpMaintenanceLinkDocument | IOpMaintenanceFileDocument
) => void;

export const addOperatorMaintenanceDocument: IAddOperatorMaintenanceDocument = document => {
    return async (dispatch, getState) => {
        try {
            const url = '/api/Documents';
            const { documents } = getState().maintenances.opmDocuments;
            const { operatorMaintenanceId } = document;

            // create temporary id for new document
            const tempId = getTempId(documents);
            const payload = documentPayload(document, tempId);

            dispatch({
                type: types.ADD_OPERATOR_MAINTENANCE_DOCUMENT,
                payload,
                meta: {
                    offline: {
                        // the network action to execute
                        effect: {
                            method: 'post',
                            url,
                            data: document,
                            transformRequest: documentTransformRequest.concat(request.defaults.transformRequest),
                            headers: { 'Content-Type': 'multipart/form-data' }
                        },
                        // action to dispatch when effect succeeds
                        commit: { type: `${types.ADD_OPERATOR_MAINTENANCE_DOCUMENT}_COMMIT`, meta: { operatorMaintenanceId, tempId } },
                        // action to dispatch if network action fails permanently
                        rollback: { type: `${types.ADD_OPERATOR_MAINTENANCE_DOCUMENT}_ROLLBACK`, meta: { operatorMaintenanceId, tempId } }
                    }
                }
            })

            Toaster({ msg: 'DOCUMENT_ADDED', type: 'success' });

        } catch (error) {
            HandleError(error, 'Add document');
        }
    }
}

export const documentTransformRequest = [(data, _headers) => {
    const formData = new FormData();

    for (const key in data) {
        if (key === 'type') {
            formData.append(key, data[key].label)
        } else {
            formData.append(key, data[key])
        }
    }

    return formData;
}]

export function getTempId(array: Array<{ id: number } & { [key: string]: any }>): number {
    let maxId = 0;
    for (const element of array) {
        if (element && element.id > maxId) maxId = element.id;
    }
    return maxId + 1;
}

export function documentPayload(document, tempId, obj = {}) {
    Object.assign(obj, {
        caption: document.file
            ? document.file.name
            : document.linkToDocument
                ? document.linkToDocument.replace(/(^\w+:|^)\/\//, '')
                : null,
        documentLink: document.linkToDocument ? document.linkToDocument : null,
        createDate: Date.now(),
        description: document.description,
        id: tempId,
        type: document.type?.label ?? '',
        ...(document.operatorMaintenanceId && { operatorMaintenanceId: document.operatorMaintenanceId })
    });
    return obj;
}