import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import QrReader from 'modern-react-qr-reader'
import { useSelector } from 'react-redux';
import i18n from '../../translations/i18n';
import Portal from 'components/Portal';

interface IProps {
    eventKey: number | string;
    handleScan: (code: string) => void;
    handleClick: (e: React.MouseEvent) => void;
    buttonLabel?: string;
    customClass?: string;
}

const QrReaderContainer = React.forwardRef<null, IProps>((props, ref) => {
    const QR = useSelector((state: State) => state.scanners.QR);
    const showReader = QR.isOpen && (props.eventKey === null || props.eventKey === QR.eventKey);
    const rootElementId = 'root-scanner';

    const handleError = (error) => {
        console.log(error);
    }

    const reader = (
        <div className={props.customClass || "qr-wrapper"}>
            <div
                ref={ref}
                className="qr-container"
            >
                <QrReader
                    delay={200}
                    onScan={props.handleScan}
                    onError={handleError}
                />
            </div>
        </div>
    )

    const getReader = () => {
        if (document.getElementById(rootElementId) === null) {
            return showReader && reader;
        }

        return (
            <Portal rootElementId="root-scanner">
                {showReader && reader}
            </Portal>
        )
    }

    return (
        <span>
            <Button onClick={props.handleClick} className="action input-row-button novi-default-btn-color ">
                <div className="flex button-contents-centered">
                    {props.buttonLabel && <span className="qr-button-label" >{i18n.t(props.buttonLabel)}</span>}
                    <FontAwesomeIcon icon={faQrcode} size="2x" />
                </div>
            </Button>
            {getReader()}
        </span>
    );
});

export default QrReaderContainer;