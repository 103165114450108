import {
    OPEN_SCANNER,
    CLOSE_SCANNER,
    SET_SCANNER_RESULT,
    CLEAR_SCANNER_RESULT
} from "../../config/actionTypes";


export const openScanner = (scannerType: string, eventKey: number | string = null) => ({
    type: OPEN_SCANNER,
    scanner: scannerType,
    eventKey
});

export const closeScanner = (scannerType: string) => ({
    type: CLOSE_SCANNER,
    scanner: scannerType
});

export const setScanResult = (result: string, scannerType: string) => ({
    type: SET_SCANNER_RESULT,
    scanner: scannerType,
    result
});

export const clearScanResult = (scannerType: string) => ({
    type: CLEAR_SCANNER_RESULT,
    scanner: scannerType,
    result: null
});