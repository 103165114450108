import React from 'react';
import { useHistory } from "react-router-dom";
import TableComponent from '../../../components/TableComponent';
import TableRowComponent from '../../../components/TableRowComponent';
import FormList from '../../../components/FormList';
import i18n from '../../../translations/i18n';
import settingsAPI from '../../../config/settingsAPI';
import { toKeyFormat } from '../../../components/HelperFunctions';

function createRowData(columns, sparePartLinks, warehousesEnabled) {
    const row = {};
    const columnNames = columns.map(column => column.name);
    const NUMBER_TYPES = ['number', 'decimal'];
    const sparePartLink = sparePartLinks[0];

    function isNumberType(property) {
        return NUMBER_TYPES.includes(columns.find(column => column.name === property.toLowerCase()).type);
    }

    // handle properties of spare part link
    for (const property in sparePartLink) {
        // skip the property 'memo' of spare part link. (memo of spare part is the one we want to show)
        if ('memo' === property) { continue; }
        // handle warehouse total or count
        if ('amount' === property) {
            if (columnNames.includes('warehousetotal')) {
                Object.assign(row, {
                    warehousetotal: sparePartLink[property] || 0
                });
            } else if (columnNames.includes('count')) {
                //TODO: change to to calculate the count from selected warehouses when warehouse filter is implemented in filteroptions
                Object.assign(row, {
                    count: sparePartLink[property] || 0
                });
            }
        }
        else if (columnNames.includes(property.toLowerCase())) {
            Object.assign(row, {
                [property.toLowerCase()]: sparePartLink[property] || (isNumberType(property) ? 0 : '')
            });
        }
    }

    // handle properties of spare part of spare part link
    const { sparePart } = sparePartLink;
    for (const property in sparePart) {
        // handle third parties
        if ('thirdPartiesByTypes' === property) {
            sparePart.thirdPartiesByTypes.forEach(thirdPartiesByType => {
                if (columnNames.includes(thirdPartiesByType.type.id) || columnNames.includes(`${thirdPartiesByType.type.id}`)) {
                    Object.assign(row, {
                        [thirdPartiesByType.type.id]: FormList({ list: thirdPartiesByType.thirdParties, listItemName: 'name' })
                    });
                }
            });
        }
        else if ('details' === property) {
            sparePart.details.forEach(detail => {
                if (columnNames.includes(detail.group)) {
                    Object.assign(row, {
                        [detail.group]: detail.value
                    });
                }
            });
        }
        else if (columnNames.includes(property.toLowerCase())) {
            // check if property of spare part link with same name wasn't already assigned
            if (!row.hasOwnProperty(property.toLowerCase())) {
                Object.assign(row, {
                    [property.toLowerCase()]: sparePart[property] || (isNumberType(property) ? 0 : '')
                });
            }
        }
    }

    if (warehousesEnabled) {

        // Overwrite these values with the sum of all sparePartLink values
        const amounts = [];
        const shelfLocations = [];
        const alertLimits = [];

        sparePartLinks.forEach(sPL => {
            amounts.push(`${sPL.warehouse.name} / ${sPL.amount ?? "-"}`);
            shelfLocations.push(`${sPL.warehouse.name} / ${sPL.shelfLocation ?? "-"}`);
            alertLimits.push(`${sPL.warehouse.name} / ${sPL.alertLimit ?? "-"}`);
        });
        
        const overwriteData = {
            count: amounts.map(count => <div>{count}</div>),
            shelflocation: shelfLocations.map(location => <div>{location}</div>),
            alertlimit: alertLimits.map(limit => <div>{limit}</div>),
        }
        Object.assign(row, overwriteData);
    }

    // handle situation where column name wasn't found as a property in spare part link
    for (const column of columns) {
        if (!row.hasOwnProperty(column.name)) {
            Object.assign(row, {
                [column.name]: NUMBER_TYPES.includes(column.type) ? 0 : ''
            });
        }
    }

    Object.assign(row, { id: sparePartLink.sparePart.id });

    return row;
}

const WarehouseSparePartTable = ({ tableSettings, sparePartLinks, warehousesEnabled }) => {
    let history = useHistory();

    const headings = tableSettings
        .sort((a, b) => a.tabOrder - b.tabOrder)
        .map(setting => ({ label: toKeyFormat(setting.label), type: setting.type, width: settingsAPI.spGridColSizes[setting.field] ? settingsAPI.spGridColSizes[setting.field] : '120px' }));

    const columns = tableSettings
        .map(setting => ({ name: setting.field, type: setting.type }));

    const rows = sparePartLinks.map(sparePartLink => {
        return createRowData(columns, sparePartLink.warehouseSparePartLinks, warehousesEnabled);
    });
    
    const handleClick = id => {
        history.push(`/sparepart/${id}`);
    }

    return (
        <TableComponent headings={headings}>
            {rows.map((row, i) => (
                <TableRowComponent key={i} handleClick={() => handleClick(row.id)}>
                    {columns.map(column => (
                        row[column.name]
                    ))}
                </TableRowComponent>
            ))}
        </TableComponent>
    );
}

export default WarehouseSparePartTable;