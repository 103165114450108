import React, { useState, useEffect } from 'react';
import MeasurementForm from 'components/measurement/MeasurementForm';
import { useHistory, useLocation } from 'react-router-dom';
import noviAPI from 'api/noviAPI';
import settingsAPI from 'config/settingsAPI';
import i18n from 'translations/i18n';
import { Loader } from 'components/Loader';
import NavigationBar from 'scenes/navigation';
import { ScrollToTop } from 'components/ScrollToTop';

const moduleSettings = settingsAPI.moduleData.editMeasurement;

type LocationState = { routeWcState; rowId; }

const RouteMaintenanceWorkCardPhaseMeasurementEdit = () => {
    const [values, setValues] = useState({});
    const [questions, setQuestions] = useState<IMeasurementQuestion[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const location = useLocation<LocationState>();
    const { rmwcid, rmwcpid, measurementGroupId } = Object.fromEntries(new URLSearchParams(location.search));
    let history = useHistory();

    useEffect(() => {
        const groupId = Number.parseInt(measurementGroupId);
        if (groupId) {
            noviAPI.measurementQuestions.getByGroup(groupId)
                .then(response => {
                    setQuestions(response.data)
                    let m = location.state.routeWcState.measurements.find(i => i.rmwcpid === rmwcpid);
                    if (m) { 
                        setValues(m.values) 
                    } else {
                        let obj = {}
                        response.data.forEach(i => {
                            Object.assign(obj, initValue(i))
                        })
                        setValues(obj)
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setShowErrorMessage(true);
                    setIsLoading(false);
                })
        } else {
            setShowErrorMessage(true);
            setIsLoading(false);
        }
    }, [measurementGroupId])

    const handleSave = () => {
        let routePhaseMeasurements = [...location.state.routeWcState.measurements];
        const index = routePhaseMeasurements.findIndex(i => i.rmwcpid === rmwcpid);
        if (index < 0) {
            routePhaseMeasurements = routePhaseMeasurements.concat([{ rmwcpid, values, questions }]);
        } else {
            routePhaseMeasurements[index] = { rmwcpid, values, questions };
        }

        history.replace(`/routeworkcard/${rmwcid}`, {
            routeWcState: {
                ...location.state.routeWcState,
                measurements: routePhaseMeasurements
            },
            rowId: rmwcpid
        })
    }

    const initValue = (q: IMeasurementQuestion) => {
        if (q.type === 'BOOLEAN') {
            return { [q.caption]: false};
        }
        if (q.useOptions) {
            return { [q.caption]: null };
        }
        return { [q.caption]: '' };
    }

    const sceneData = {
        view: moduleSettings.name,
        title: i18n.t('MEASUREMENTS'),
        location: location,
        history: history,
        backAction: { action: () => history.replace(`/routeworkcard/${rmwcid}`, location.state) }
    }

    const viewAction = {
        icon: 'confirm',
        label: '',
        clickFn: handleSave,
        isActionFn: true,
    }

    const spinner = <Loader status="pending" />

    const form = (
        <MeasurementForm
            showErrorMessage={showErrorMessage}
            measurementQuestions={questions}
            measurementValues={values}
            setMeasurementValues={setValues}
        />
    )

    return (
        <div>
            <ScrollToTop />
            <NavigationBar
                currentView={sceneData}
                popoverData={''}
                viewAction={viewAction}
            />
            {isLoading ? spinner : form}
        </div>
    )
}

export default RouteMaintenanceWorkCardPhaseMeasurementEdit;