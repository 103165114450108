import React from 'react';
import '../../styles/global.scss';
import './styles/styles.scss';
import i18n from '../../translations/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { RouteChildrenProps } from 'react-router-dom';

const NotFound = (props: IProps) => {

    const goBack = (location: string) => {
        location === 'dashboard' ? props.history.push('/dashboard') : props.history.goBack();
    }

    return (
        <div className="not-found-wrapper">
            <h1 className="icon-novi-default not-found-error-text">404</h1>
            <h1 className="not-found-header">{i18n.t('PAGE_NOT_FOUND')}</h1>
            <br />
            <div className="button-wrapper-column">
                <button className="action input-row-button novi-default-btn-color not-found-button" type={'button'} onClick={() => goBack('back')}>{i18n.t('BACK_TO_PREVIOUS_PAGE')} </button>
                <br />
                <button className="action input-row-button novi-default-btn-color not-found-button" type={'button'} onClick={() => goBack('dashboard')}>{i18n.t('BACK_TO_DASHBOARD')} </button>
            </div>
        </div>
    );
}

interface IProps extends RouteChildrenProps {};

export default NotFound;
