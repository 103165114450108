import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from "../translations/i18n";
import FormList from "./FormList";
import settingsAPI from "../config/settingsAPI";
import { VIEW_FILTER } from '../constants/costPools';
import { actionIcons } from '../constants/icons';
import authAPI from "../api/authAPI";
import { UserRights } from '../constants/userRights';
import { getDateTime, getInputDateTime, initInputDateTime, parsePayloadDate } from 'utils';

//TODO: categorize functions into different files

export function DefineParametersByQuickSearch(type = null, quickSearchValue, props) {
    if (type || quickSearchValue >= 0) {
        const params = {};
        if (type === 'machine') {
            // machine type
            params['machineType'] = quickSearchValue;

            return params;
        } else if (type === 'warehouse') {
            params['warehouseQuickSearch'] = quickSearchValue;
        }
        return params
    } else {
        return {};
    }
}

export function InvalidFields(state, machineGroupSettings, property = null) {
    const isValid = key => (!state[key] && state[key] !== 0) || ((state[key].label || state[key].name) && state[key].id < 0) ? false : true;
    return machineGroupSettings.filter(({ required, name }) => required && !isValid(name)).map(setting => property ? setting[property] : setting)
}

export function ValidateForm(formData = null, stateData = null, type = 'default') {
    if (!formData || !stateData || !type) return false;

    let validity = true;
    const isIdValid = key => !stateData[key] || ((stateData[key].label || stateData[key].name) && stateData[key].id < 0) ? false : true;
    const isArrayValid = key => Array.isArray(stateData[key]) && stateData[key].length < 1 ? false : true;

    if (Array.isArray(formData)) {
        return formData.filter(field => field.required)
            .forEach(({ name }) => {
                if (!isIdValid(name)) {
                    validity = false;
                }
            })
    } else if (type === 'default') {
        Object.keys(formData)
            .filter(key => key !== 'id' && formData[key].required)
            .forEach(key => {
                if (!isIdValid(key) || !isArrayValid(key)) {
                    validity = false;
                }
            });
    } else if (type === 'machine') {
        Object.keys(formData).forEach(key => {
            const propKey = key === 'level'
                ? !stateData[key]
                    ? 'parentId'
                    : key
                : key

            if (formData[key].required) {
                if (!isIdValid(propKey) || !isArrayValid(propKey)) {
                    validity = false;
                }
            }
        });
    }

    return validity;
}

//generic
export function SortArray(array, sortKey) {
    if (!sortKey) return array;
    array.sort(function (a, b) {

        const valA = a[sortKey] === null ? '' : a[sortKey];
        const valB = b[sortKey] === null ? '' : b[sortKey];
        const textA = valA.toUpperCase();
        const textB = valB.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    return array;
}

export function TranslateOptions(options, key) {
    return options.map(opt => {
        opt[key] = i18n.t(opt[key]);
        return opt;
    });
}

export function HasRight(settingName: string | string[], userRights: IUserRight[]): boolean {
    if (Array.isArray(settingName)) {
        let allowedRights = []
        
        // Gets every userRight keys/names that has value set true.
        userRights.forEach(right => {
            if (Object.keys(right).some(k => right[k])) {
                allowedRights.push(...Object.keys(right))
            }
        });

        return allowedRights.some(i => settingName.includes(i))
    } else {
        return userRights.some(right => right[settingName]);
    }
}

// Status colors for workcards and workphases
export function DefineColors(item, colorSettings) {
    if (!item) return [];
    let colors = [];

    // Temporary method to convert properties to lower case 
    var key, keys = Object.keys(item);
    var n = keys.length;
    var newobj = {}
    while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = item[key];
    }

    if (colorSettings && colorSettings.config) {
        const { colorConfigurations, defaultColorIds } = colorSettings.config;

        /* 
         * Loop colorConfigurations and compare its prerequisite properties with given item's properties 
         * and determine if item values match with prerequisites 
         */
        colorConfigurations.forEach(conf => {
            let validConfig = true;

            conf.prerequisites.forEach(pr => {
                // Check whether workcard has requested property and check if required value is found or not
                if (newobj.hasOwnProperty(pr.property)) {
                    if (typeof newobj[pr.property] === "boolean") {
                        const requirement = (pr.requiredValue === '1' || pr.requiredValue.toLowerCase() === 'true')
                            ? true
                            : false;
                        if (newobj[pr.property] !== requirement) {
                            validConfig = false;
                        }
                    } else if (newobj[pr.property] !== null && newobj[pr.property] !== undefined) {
                        if (newobj[pr.property].toString() !== pr.requiredValue) {
                            validConfig = false;
                        }
                    } else if (newobj[pr.property] === null && pr.requiredValue !== 'false') {
                        validConfig = false;
                    }
                } else {
                    validConfig = false;
                }
            });

            /* 
                * If colorSettings has colors and item has matching values with prerequisites (validConfig),
                * set colors of matching configuratuion (conf)
                */
            if (colorSettings.colors && validConfig) {
                colorSettings.colors.forEach(col => {
                    if (conf.colorIds.find(colorId => parseInt(colorId, 10) === parseInt(col.id, 10))) {
                        let color = { ...col };
                        color['priority'] = conf.priority;
                        colors.push(color);
                    }
                });
            }
        });

        // If item didn't match with any colorConfigurations, set default Novi colors
        colors.length === 0 && colorSettings.colors.forEach(col => {
            if (defaultColorIds.find(colorId => parseInt(colorId, 10) === parseInt(col.id, 10))) {
                let color = { ...col };
                color['priority'] = 1;
                colors.push(color);
            }
        });

        return colors || [];
    } else {
        return [];
    }
}

export function DetermineBackgroundColor(itemColors) {
    let itemColor = null;

    if (itemColors) {
        const highestPriority = Math.max.apply(Math, itemColors.map(function (o) { return o.priority; }));

        itemColor = itemColors && itemColors
            .find(itemCol => itemCol.type && itemCol.type.name === 'background' && itemCol.priority === highestPriority);
    }

    return itemColor ? itemColor.colorCode : '#e30059';//TODO: add default color
}

export function GetErrorMessage(errResponse, type) {
    const messages = {
        401: 'Unauthorized. ( ' + type + ')',
        404: type + ' failed.',
        400: type + ' failed.',
        500: 'Server error. '
    }

    if (!Object.keys(messages).includes(errResponse.status)){
        return type + ' (' + errResponse.request.status + ')';
    }

    return messages[errResponse.status] + ' (' + errResponse.request.status + ')';
}

export function HandleError(error, type) {
    if (error && (error.response || error.request) && (error.response?.status || error.request?.status) && !type.includes('color')) {
        if (error.response?.status === 401 || error.request?.status === 401) {
            if(!authAPI.getAccessToken())
            {
                // Skip error message if we are supposed to get 401
                return;
            } else {
                Toaster({ msg: 'SESSION_HAS_BEEN_EXPIRED', type: 'info' });
                authAPI.removeAccessToken();
                authAPI.removeRefreshToken();
                return;
            }
        }

        if (error.response?.status == 400 && error.response?.data?.errors?.length > 0) {

            error.response.data.errors.forEach(err => {
                toast.error(i18n.t(err.reason), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            });
        }

        if (error.response?.status == 403 && error.response?.data?.reason) {
            toast.error(i18n.t(error.response?.data?.reason) + ' (' + type + ') ', {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
        }
        else {
            toast.error(GetErrorMessage(error.response || error.request, type), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });
        }
        
    }
    else {
        console.error(error);
        toast.error(i18n.t(error?.message ?? "Unknown error"), {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true
        });
    }
}

export type IToaster = (props: { msg: string, type?: string, position?: string, hideProgressBar?: boolean }) => void;

export const Toaster: IToaster = props => {
    const { msg, type = 'info', position = 'TOP_CENTER', hideProgressBar = true } = props;
    toast[type](i18n.t(msg), {
        position: toast.POSITION[position],
        hideProgressBar
    });
}

//generic
export function ToArray(item) {
    const arrItem = item ?
        !Array.isArray(item)
            ? [item]
            : item
        : [];

    return arrItem;
}

// sort function to sort array of objects by given property.
export function sortArrayByProperty<T, K extends keyof T>(array: T[], propertyToOrderBy: K) {
    return array.sort((a, b) => {
        if (typeof a[propertyToOrderBy] === 'string' && typeof b[propertyToOrderBy] === 'string') {
            const aString = String(a[propertyToOrderBy]).toUpperCase();
            const bString = String(b[propertyToOrderBy]).toUpperCase();

            if (aString < bString) {
                return -1;
            }

            if (aString > bString) {
                return 1;
            }
            return 0;
        } else {
            if (a[propertyToOrderBy] < b[propertyToOrderBy]) {
                return -1;
            }

            if (a[propertyToOrderBy] > b[propertyToOrderBy]) {
                return 1;
            }
            return 0;
        }
    });
}

export function GetPopoverActions(settings, type, fn = null, isAction = false, params = null) {
    let popoverActions = [];
    if (type === 'workcarddocument') {
        //if (HasRight('workcarddocumentdel', settings.userRights)) {
        popoverActions.push({
            icon: actionIcons.DELETE,
            label: i18n.t('DELETE_DOCUMENT'),
            action: 'delete',
            paClass: 'icon-warning',
            clickFn: fn,
            isActionFn: isAction,
            params: params
            //onClick={() => this.setDialogProperties(item, 'delete')}
        })
        //}
        //if (HasRight('workcarddocumentview', settings.userRights)) {
        popoverActions.push({
            icon: actionIcons.VIEW,
            label: i18n.t('OPEN'),
            action: 'view',
            clickFn: fn,
            isActionFn: isAction,
            params: params
            //onClick={() => this.openDocument(item)}
        })
    }
    if (type === 'workcardmachinedocument') {
        if (HasRight('machineregistryview', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.VIEW,
                label: i18n.t('OPEN'),
                action: 'view',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
    }
    if (type === 'workcardmaintenancedocument') {
        popoverActions.push({
            icon: actionIcons.VIEW,
            label: i18n.t('OPEN'),
            action: 'view',
            clickFn: fn,
            isActionFn: isAction,
            params: params
        })
    }
    if (type === 'machinedocument') {
        if (HasRight('machineregistryedit', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.DELETE,
                label: i18n.t('DELETE_DOCUMENT'),
                action: 'delete',
                paClass: 'icon-warning',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
        if (HasRight('machineregistryview', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.VIEW,
                label: i18n.t('OPEN'),
                action: 'view',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
    }
    if (type === 'hourcard') {
        if (HasRight('hourcardview', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.VIEW,
                label: i18n.t('OPEN'),
                action: 'viewHourCard',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
    }
    if (type === 'measurement') {
        popoverActions.push({
            icon: actionIcons.VIEW,
            label: i18n.t('OPEN'),
            action: 'viewMeasurement',
            clickFn: fn,
            isActionFn: isAction,
            params: params
        })
        if (HasRight([UserRights.WorkScheduleEdit, UserRights.RestrictedMaterialEdit, UserRights.RestrictedTimestampsEdit], settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.EDIT,
                label: i18n.t('MUOKKAA'),
                action: 'editMeasurement',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
            popoverActions.push({
                icon: actionIcons.DELETE,
                label: i18n.t('DELETE'),
                action: 'delete',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
    }
    if (type === 'workcardpermit') {
        if (HasRight([UserRights.WorkPermitView, UserRights.WorkPermitViewAll], settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.VIEW,
                label: i18n.t('OPEN'),
                action: 'viewPermit',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
            if (HasRight([UserRights.WorkPermitEdit], settings.userRights)) { 
                popoverActions.push({
                    icon: actionIcons.EDIT,
                    label: i18n.t('EDIT_PERMIT'),
                    action: 'editPermit',
                    clickFn: fn,
                    isActionFn: isAction,
                    params: params
                })
            } else if (HasRight([UserRights.WorkPermitEditOwn], settings.userRights) && params?.personId === settings.userId) {
                popoverActions.push({
                    icon: actionIcons.EDIT,
                    label: i18n.t('EDIT_PERMIT'),
                    action: 'editPermit',
                    clickFn: fn,
                    isActionFn: isAction,
                    params: params
                })
            }
        }
        // if (HasRight('workpermitdele', settings.userRights)) {
        //     popoverActions.push({
        //         icon: actionIcons.DELETE,
        //         label: i18n.t('DELETE_PERMIT'),
        //         action: 'delete',
        //         paClass: 'icon-warning',
        //         clickFn: fn,
        //         isActionFn: isAction,
        //         params: params
        //     })
        // }
    }
    if (type === 'sparepart' || type == 'wildsparepart') {
        if (HasRight('warehouseedit', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.EDIT,
                label: i18n.t('EDIT'),
                action: 'edit',
                paClass: 'edit-option',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
    }
    if (type === 'sparepart') {
        if (HasRight('warehouseoperationtake', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.TAKE,
                label: i18n.t('TAKE'),
                action: 'take',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
        if (HasRight('warehouseoperationreturn', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.RETURN,
                label: i18n.t('RETURN'),
                action: 'return',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
        if (HasRight('warehouseoperationinvent', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.CHART_BAR,
                label: i18n.t('INVENT'),
                action: 'invent',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
        if (HasRight('warehouseoperationarrive', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.ARRIVE,
                label: i18n.t('ARRIVE'),
                action: 'arrive',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
        if (HasRight('warehouseoperationchange', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.CHANGE,
                label: i18n.t('WAREHOUSE_VALUE_CHANGE'),
                action: 'change',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
        if (HasRight('warehouseoperationtransfer', settings.userRights)) {
            popoverActions.push({
                icon: actionIcons.TRANSFER,
                label: i18n.t('TRANSFER'),
                action: 'transfer',
                clickFn: fn,
                isActionFn: isAction,
                params: params
            })
        }
    }

    return popoverActions;
}

export function GetDetail(details = [], key, isMultiSelectDetail = false, defaultValue = null, detOpts = [], cardType = '') {
    let detail = details?.length > 0
        ? isMultiSelectDetail
            ? details.filter(det => det.group === key)
            : details.find(det => det.group === key)
        : null;

    // Try to find default detail value if there's no existing detail value 
    if (!detail && defaultValue && cardType !== 'editWorkCard') {
        detail = detOpts.find(d => d.id === parseInt(defaultValue, 10))
    }

    // Both 'value' and 'name' keys are used as the actual value property
    return detail
        ? isMultiSelectDetail
            ? cardType === 'workPermit'
                ? detail.map(det => ({ value: det.id, label: det.value }))
                : detail.map(det => ({ id: det.id, value: toKeyFormat(det.value), name: toKeyFormat(det.value) }))
            : cardType === 'workPermit'
                ? { value: detail.id, label: detail.value }
                : { id: detail.id, label: toKeyFormat(detail.value) }
        : '';
}

export function GetOnChangeType(type, fieldKey, isMultiSelectDetail = false) {
    const key = fieldKey.replace('_wp', '');

    return type === 'phasedetail' && isMultiSelectDetail
        ? 'select'
        : type === 'datetime'
            ? 'date-input'
            : type === 'notificationtarget'
                ? 'tag-select'
                : key === 'workstatus'
                    || key === 'machinehalt'
                    || key === 'worktype'
                    || key === 'worker'
                    || key === 'workers'
                    || key === 'workergroups'
                    || key === 'workstatus'
                    || key === 'responsibleperson'
                    || type === 'detail'
                    || type === 'phasedetail'
                    || type === 'machinetype'
                    || type === 'person'
                    || type === 'thirdparty'
                    || type === 'orderer'
                    || type === 'costpool'
                    || type === 'costpoolgroup'
                    || type === 'select'
                    || type === 'boolean'
                    || type === 'rating'
                    || key === 'machinerequirements'
                    || key === 'investmentcode'
                    ? 'select'
                    : '';
}

export function GetTranslationKeyByProp(key) {
    const headerInfo = {
        login: 'LOGIN_TITLE',
        worklist: 'WORKCARDS',
        dashboard: 'MAIN_MENU',
        workcard: 'WORKCARD',
        newworkcard: 'NEW_WORKCARD',
        newfaultnotice: 'NEW_FAULT_NOTICE',
        routeworkcard: 'ROUTE_MAINTENANCE',
        routewcFaultnotice: 'RM_ADD_WC',
        machines: 'MACHINES',
        machine: 'MACHINE',
        workphase: 'WC_PHASE',
        routes: 'ROUTE_MAINTENANCES',
        operators: 'OPERATOR_MAINTENANCES',
        RTM: 'RTM',
        plans: 'CALENDAR_MAINTENANCES',
        material: 'WC_MATERIAL',
        permit: 'WC_PERMIT',
        operatormaintenance: 'OPERATOR_MAINTENANCE_SHORT',
        operatormaintenanceView: 'OPERATOR_MAINTENANCE',
        warehouse: 'WAREHOUSE',
        sparepart: 'SPARE_PART',
        editsparepart: 'EDIT',
        take: 'TAKE',
        return: 'RETURN',
        arrive: 'ARRIVE',
        invent: 'INVENT',
        change: 'WAREHOUSE_VALUE_CHANGE',
        warehouselinkedit: 'WAREHOUSE_LINK_EDIT',
        machinelinkedit: 'MACHINE_LINK',
        dashboardEdit: 'EDIT_DASHBOARD',
        measurement: "MEASUREMENT"
    }

    return headerInfo[key];
}

export function GetSimplifiedHierarchyPathString(hierarchyPath: string): string {
    if (isNullOrWhitespace(hierarchyPath)) {
        return '';
    }

    return hierarchyPath.split(";")
        .filter(Boolean)
        .map(pathName => {
            const pathNameParts = pathName.split(",");
            const isLevel = pathNameParts[0].startsWith("l");
            if (isLevel) {
                return pathNameParts[2];
            } else {
                return pathNameParts[1] + " / " + pathNameParts[2];
            }
        })
        .join(" > ");
}

//generic
export const isNullOrWhitespace = (input: string | object): boolean => {
    return input === null || input === undefined || (typeof input === 'string' && !input.trim());
}

export function CountFilters(filters) {
    let count = 0;

    if (filters) {
        Object.keys(filters).forEach(key => {
            if ((filters[key] !== ''
                    && typeof filters[key] !== 'undefined'
                && !Array.isArray(filters[key])
                    && filters[key] !== null
                    && typeof filters[key] !== 'object')
                || (Array.isArray(filters[key]) && filters[key].length > 0)
                    || (filters[key] && typeof filters[key] === 'object' && Object.keys(filters[key]).length > 0)
            ) {
                if (key === 'filterByThirdParties') {
                    Object.values(filters[key]).forEach(value => { if (Array.isArray(value) && value.length > 0) count++; });
                } else if (key === 'extraDatas') {
                    count += Object.values(filters[key]).filter(x => !isNullOrWhitespace(`${x}`)).length;
                } else {
                    count++;
                }
            }
        })
    }

    return count;
}

export function GetWorkCardPhasesData(workPhases) {
    const workPhasesData = {
        phasehours: 0,
        phaseprocedures: [],
        phasecomments: [],
        workbegin: null,
        workended: null
    }

    if (!workPhases) { return workPhasesData };

    workPhases.forEach(phase => {
        const hours = (typeof phase.workHours === 'string' || phase.workHours instanceof String) ? parseFloat(phase.workHours) : phase.workHours;

        workPhasesData.phasehours = phase.workHours ? +(workPhasesData.phasehours + hours).toFixed(2) : workPhasesData.phasehours;
        if (phase.procedure) { workPhasesData.phaseprocedures.push({ name: phase.procedure }); }
        if (phase.comment) { workPhasesData.phasecomments.push({ name: phase.comment }); }
        if (phase.workBegin) {
            if (workPhasesData.workbegin === null) {
                workPhasesData.workbegin = phase.workBegin
            } else {
                workPhasesData.workbegin = phase.workBegin < workPhasesData.workbegin ? phase.workBegin : workPhasesData.workbegin;
            }
        }
        if (phase.workEnded) {
            if (workPhasesData.workended === null) {
                workPhasesData.workended = phase.workEnded
            } else {
                workPhasesData.workended = phase.workEnded > workPhasesData.workended ? phase.workEnded : workPhasesData.workended;
            }
        }

    });

    return workPhasesData;
}

export function GetThirdParty(type, thirdPartyLists, nameOnly = false) {
    if (!thirdPartyLists) return null;
    const list = thirdPartyLists.find(item => item.type && parseInt(item.type.id, 10) === parseInt(type, 10));

    if (list && list.thirdParties) {
        if (nameOnly) {
            return FormList({ list: list.thirdParties, listItemName: 'name' });
        } else {
            return list.thirdParties.map(thirdParty => ({ id: thirdParty.id, label: thirdParty.name || list.thirdParty.label, code: thirdParty.code }));
        }
    }

    return null;
}

export function GetExtraDataValue(field, extraDatas, defaultValue = '', cardType = '') {
    const extraData = extraDatas?.find(item => {
        // work card extra data 
        if (Object.keys(item).includes('group')) {
            return item.group === field;
        }
        // spare part extra data or machine extra data
        return item.caption?.caption === field;
    });

    return extraData ? (extraData.value || extraData.valueNumber) : cardType != 'editWorkCard' ? defaultValue : '';
}

export function GetMachineDetailValue(field, machineDetails) {
    const detail = machineDetails.find(item => item.group === field)
    return detail ? detail.value : null;
}

export function GetTypeTranslationKey(field, value, settings) {
    let type;

    if (field === 'urgency') {
        type = settings?.urgencyTypes?.find(urgency => urgency.id === value);
    } else {
        type = settings?.statusTypes?.find(status => status.id === value);
    }

    return type ? type.label : '';
}

export function TransformNotificationTargets(field, notificationTargets) {
    // We need to transform notificationTargets a little bit so we can use them in FormList: (value => name).
    return notificationTargets.map(item => ({ id: item.id, name: item.value, workCardId: item.workCardId }))
}

export function GetWarrantyEnd(mainData) {
    const warrantyEndValue = mainData.machine ? mainData.machine.warrantyEnd : mainData.warrantyend || '';
    return getDateTime(warrantyEndValue);
}

export function GetAlertLimit(mainData) {
    return mainData['alertlimit'] ? mainData['alertlimit'].toString() : '';
}

export function formListFromObjectArray(array) {
    return array ? array.map(i => i.value).join(', ') : [];
}

function getMachineRequirementData(mainData, parentData) {
    return mainData?.machinerequirements ? mainData.machinerequirements :
        parentData?.machineRequirements ? parentData.machineRequirements :
            [];
}

/**
 * Loops through ICostPoolLite array and compares its items to IViewSetting object to find a cost pool matching the group ID.
 * 
 * Returns costPool.translationKey.
 */
export function getCostPoolTranslationKey(costPools: ICostPoolLite[], fieldObj: IViewSetting) {
    let value = '';
    try {
        const groupId = fieldObj.field.split('costpoolgroup_')[1];
        const costPool = costPools.find(i => i.groupId === Number(groupId));
        value = costPool ? costPool.translationKey : '';
    } catch (e) {
        console.log(e);
    }
    return value;
}

const getFieldValue = (formFieldItem, mainData, parentData, childData, settings, extraDatas) => {

    const { field, type, group } = formFieldItem;
    const fieldValue = mainData?.[field];

    let value = fieldValue;

    if (field === 'urgency') {
        const hasValue = parseInt(fieldValue, 10) > 0;
        value = hasValue ? i18n.t(GetTypeTranslationKey(field, fieldValue, settings)) : '';
    } else if (field === 'hierarchypath') {
        value = mainData.hierarchypath || mainData.machinehierarchypath;
    } else if (type === 'thirdparty' || type === 'machinethirdparty') {
        value = GetThirdParty(field, mainData.thirdpartiesbytypes, true);
    } else if (field === 'machine_location' || field === 'machine_classification') {
        value = GetMachineDetailValue(field, mainData.machinedetails);
    } else if (type === 'machinedetail') {
        value = GetMachineDetailValue(field, parentData.machineDetails);
    } else if (field === 'machine') {
        value = ToArray(fieldValue);
    } else if (field === 'parent') {
        value = fieldValue?.machineCode ? fieldValue.machineCode + " / " + fieldValue?.name : fieldValue?.name
    } else if (field === 'person') {
        value = mainData['responsibilityperson'];
    } else if (field === 'count') {
        value = mainData['amount']
    } else if (['amount', 'type', 'comment'].includes(field)) {
        value = childData[field];
        if (group === 'sparepart' && field === 'type') {
            value = mainData.type;
        }
    } else if (field === 'machinetype') {
        value = mainData['type'] ?? mainData['machine']?.type
    } else if (field === 'idletime' && fieldValue) {
        value = fieldValue.toFixed(2)
    } else if (field === 'machinehalt') {
        value = fieldValue ? i18n.t('YES') : i18n.t('NO')
    } else if (type === 'detail' || type === 'multidetail' || type === 'phasedetail' || type === 'sparepartdetail') {
        const isMultiDetail = type === 'multidetail';
        value = GetDetail([...(mainData.details ?? []), ...(mainData.machinedetails ?? [])], field, isMultiDetail || type === "multidetail")
    } else if (field === 'phasehours' || field === 'phaseprocedures' || field === 'phasecomments') {
        value = childData && childData[field];
    } else if (field === 'workstatus') {
        value = i18n.t(GetTypeTranslationKey(field, fieldValue, settings))
    } else if (type === 'machineextradata') {
        value = GetExtraDataValue(field, parentData.extraDatas);
    } else if (type.includes('extradata')) {
        value = GetExtraDataValue(field, extraDatas);
    } else if (field === 'price' && fieldValue !== null) {
        value = fieldValue.toFixed(2)
    } else if (field === 'workcardid') {
        value = mainData['id'];
    } else if (field === 'warrantyend') { // machine, workcard.machine 
        value = GetWarrantyEnd(mainData);
    } else if (field === 'notificationtarget') {
        value = TransformNotificationTargets(field, mainData.notificationtargets);
    } else if (field === 'worker') {
        value = mainData['workers'];
    } else if (field === 'workergroup') {
        value = mainData['workergroups'];
    } else if (field === 'alertlimit') {
        value = GetAlertLimit(mainData);
    } else if ([
        'orderdate', 'workbegin', 'workended', 'faultbegin', 'workcanbegin', 'workcanend', 
        'warrantyends', 'deploymentdate', 'lastmodified', 'lastpurchasedate', 'lastarriveddate'].includes(field)) {
        //TODO: change field name checks to checking actual input field type if it's a datetime (not implemented yet)
        value = getDateTime(fieldValue);
    } else if (field === 'extralocation') {
        value = mainData[type === 'text' ? 'extralocationtext' : 'extraLocation']
    } else if (field === 'workertext') {
        value = mainData['workername'];
    } else if (field === 'orderer') {
        value = (type === 'text' || type === 'label')
            ? mainData['orderertext'] || mainData['orderer'] || ''
            : mainData['orderer'] || mainData['orderertext'] || ''
    } else if (field === 'machineparent') {
        value = parentData?.parent;
    } else if (field === 'machinerequirements') {
        value = formListFromObjectArray(getMachineRequirementData(mainData, parentData));
    } else if (field === 'machinecostpool') {
        value = parentData?.costpool;
    } else if (type === 'costpoolgroup') {
        if (parentData?.costPools) {
            value = field.startsWith('machine_costpoolgroup') // work card view
                ? getCostPoolTranslationKey(parentData.costPools, formFieldItem) // machine costpoolgroup
                : getCostPoolTranslationKey(mainData['costpools'], formFieldItem) // work card costpoolgroup
        } else {
            value = getCostPoolTranslationKey(mainData['costpools'] || mainData['costPools'], formFieldItem) // machine view ['costpools'] // hour card view ['costPools'] (this doesn't actually show up in the hour card view. we just avoid an error this way)
        }
    } else if (field === 'rating' || field === 'investmentcode') {
        value = fieldValue?.value;
    } else if (field === 'tracking') {
        value = fieldValue ? i18n.t('KYLLA') : i18n.t('EI');
    } else if (field === 'consumption') {
        value = childData?.sparePartTakes.toString();
    } else if (field === 'workphasecostestimate') {
        value = mainData['costestimate'];
    } else if (field === 'workphasepaused' && mainData['paused']) {
        value = getDateTime(mainData['paused']);
    } else if (field === 'workhours' && mainData['workhours']) {
        value = parseFloat(mainData['workhours']).toFixed(2);
    } else if (field === 'warehouseshelflocation') {
        value = FormList({ list: parentData.map(i => ({ name: `${i.warehouse.name} ${i.shelfLocation !== null ? i.shelfLocation : ''}`.trimEnd() })), listingStyle: 'listed' })
    } else if (field === 'warehousetotal') {
        value = parentData?.reduce((a, c) => a + (c?.amount ?? 0), 0) ?? 0
    } else if (field === 'machinerating') {
        value = parentData.rating?.value
    }

    return value;
}

// Function to generate correct form data from machinegroupsettings(viewSettings) data for view and edit scenes 
export function GetFormDataFromViewSettings(viewSettings, mainData, childData = null, settings, extraDatas, isOffline = false, parentData = null) {
    viewSettings.sort((a, b) => a['tabOrder'] - b['tabOrder']);

    let formDataFields = [];

    viewSettings.forEach(fieldItem => {
        try {
            let formFieldItem = { ...fieldItem };

            // Dealing with multiple translationKeys in a single string would require special checks and handling
            // Instead of doing that everywhere, do it here once and set the translationKey as the already translated value
            if (formFieldItem.type === "costpoolgroup" && fieldItem.translationKey?.match) {

                // COSTPOOL_ON_<Target> (eg. MACHINE) + <Space or _> + <translationKey>
                const matches = fieldItem.translationKey.match(/^(COSTPOOL_ON_[A-Z0-9]*)[ |_](.*)$/i);

                if (matches && matches[1] && matches[2]) {
                    formFieldItem.translationKey = `${i18n.t(matches[1])} ${i18n.t(matches[2])}`;
                }
            }

            formFieldItem.value = getFieldValue(formFieldItem, mainData, parentData, childData, settings, extraDatas);

            formDataFields.push(formFieldItem);
        } catch (error) {
            console.log(error);
        }
    });

    return formDataFields;
}


// Get valid property name as app level settings (MachineGroupSettings) have different namings than workcard or machine properties have
export function GetProperty(field, type = null) {
    const propertyData = field === 'worker'
        ? 'workers'
        : field === 'workergroup'
            ? 'workergroups'
            : field === 'hierarchypath'
                ? 'machinehierarchypath'
                    : field === 'extralocation'
                        ? type === 'text'
                            ? 'extralocationtext'
                            : 'extralocation'
                        : field === 'person'
                            ? 'responsibilityperson'
                            : field === 'workertext'
                                ? 'workername'
                                : field === 'orderer'
                                    ? type === 'text'
                                        ? 'orderertext'
                                        : 'orderer'
                                    : type === 'costpoolgroup'
                                        ? 'costpools'
                                        : field === 'workphasecostestimate'
                                            ? 'costestimate'
                                            : field === 'workphasepaused'
                                                ? 'paused'
                                                : type === 'machinethirdparty'
                                                    ? `${type}_${field}`
                                                    : field;

    return propertyData;
}

// Get field type (i.e. is field input, select, datetime, etc.)
export function GetType(field, type) {
    const typeData = field === 'workstatus'
        ? 'select-choice'
            : (field === 'machinehalt'
            || (field === 'orderer' && type !== 'text')
            || type === 'machinetype'
            || type === 'person'
            || type === 'costpool'
            || type === 'rating'
            || type === 'responsibilityperson'
            || type === 'responsibleperson'
            || type === 'salarycategory'
            || type === 'investmentcode')
                ? 'select'
                : field === 'machinerequirements'
                    ? 'machinerequirements'
                    : type.match(/machine(extradata|detail|costpool|thirdparty|extralocation|parent)/gi)
                        ? 'label'
                        : field === 'workergroup'
                            ? 'worker'
                            : type === 'thirdparty'
                                ? 'async-search-multiselect'
                                : type === 'level' && field === 'machine'
                                    ? 'machine'
                                        : type === 'boolean'
                                            ? 'singleline-checkbox'
                                            : type === 'costpoolgroup'
                                                ? field.startsWith('costpoolgroup')
                                                    ? 'select'
                                                    : 'label' // 'machine_costpoolgroup'
                                                : type;

    return typeData;
}

export function GetLabelValue(fieldValue, fieldKey = null, data) {
    if (fieldKey && fieldKey === 'phasehours' && data) {
        return data[fieldKey];
    } else if ((fieldKey === 'phaseprocedures' || fieldKey === 'phasecomments') && data) {
        return FormList({ list: data[fieldKey], listItemName: 'name' });
    } else if (fieldKey === 'orderdate') {
        return fieldValue ? getInputDateTime(fieldValue) : initInputDateTime();
    } else if (fieldKey === 'machinetype' && data) { 
        return data['type']?.name ?? ''
    } else if (fieldValue) {
        return fieldValue.id ? (fieldValue.value || fieldValue.name || fieldValue.description) : fieldValue;
    } else {
        return '';
    }
}

function getWorkerValue(currentValue, defaultValue = null, dataType = '', user, workerOptions, noviConfigs, location, cardType = '') {
    const workerValue = defaultValue ? parseInt(defaultValue, 10) : parseInt(user, 10);
    const oneWorkerPerPhase = noviConfigs.AllowOneWorkerPerPhase?.toLowerCase() === 'true';
    const automaticWorker = noviConfigs.AutomaticWorker?.toLowerCase() === 'true';

    if (!currentValue || currentValue?.length === 0) {
        // Handle automatic worker value
        if (automaticWorker || location?.pathname?.includes('operatormaintenance')) {
            // work phase
            if (dataType === 'workphase' && oneWorkerPerPhase) {
                return workerOptions.find(worker => worker.id === workerValue) || [];
            }
            // work card
            else {
                const worker = workerOptions.find(worker => worker.id === workerValue);
                return worker ? [worker] : [];
            }
        }
        // Handle possible default value
        else if (defaultValue && cardType !== 'editWorkCard') {
            const worker = workerOptions.find(worker => worker.id === workerValue);
            return worker ? [worker] : [];
        }
    }
    // Handle existing (currentValue) value
    else {
        // work phase
        if (dataType === 'workphase' && oneWorkerPerPhase) {
            return Array.isArray(currentValue) && currentValue?.length > 0 ? currentValue[0] : [];
        }
        // work card
        else {
            return currentValue;
        }
    }
}

/**
 * Function to handle predefined values to form fields
 * 
 * currentValue  = value of given field
 * type          = select, async, etc.
 * field         = field name
 * location      = navigation location
 * noviConfigs   = Novi app configuration settings
 * optionsLists  = different option sets, i.e. worker, urgency, work status options, ...
 * user          = current logged in user's id
 * relationData  = possible related data set like workcard phases data
 * dataType      = type for filtering or changing options data, e.g. dataType could be 'workcard', 'workphase', etc.
 * extraDatas    = extra data array
 * defaultValue  = predefined default value for field 
 * cardType      = predefined default value for field 
 */
export function GetFormInputValueByField(
    currentValue,
    type,
    field,
    location,
    noviConfigs,
    optionsLists,
    user,
    relationData = null,
    dataType = null,
    extraDatas = [],
    isOffline = false,
    notificationTargets = [],
    defaultValue?,
    cardType = null
) {
    if (type === 'label' || type === 'textarealabel') {
        return GetLabelValue(currentValue, field, relationData)
    } else if (type === 'extradata' || type === 'extradatanumber') {
        return GetExtraDataValue(field, extraDatas, defaultValue, cardType);
    } else if ((field === 'workcanend' || field === 'workbegin' || field === 'workended' || field === 'workphasepaused') && !isNullOrWhitespace(currentValue)) {
        return getInputDateTime(currentValue);
    } else if (field === 'faultbegin' || field === 'workcanbegin' || field === 'orderdate') {
        if (isNullOrWhitespace(currentValue)) {
            return initInputDateTime();
        }
        return getInputDateTime(currentValue);
    } else if (relationData && noviConfigs.AutomaticWCTimeStampsByPhases === 'True' && (field === 'workbegin' || field === 'workended') && isOffline) {
        return field === 'workbegin' ? relationData.workbegin : relationData.workended;
    } else if (type === 'costpoolgroup') {
        let value = '';
        try {
            const groupId = field.split('costpoolgroup_')[1];
            if (relationData) {
                // work card edit
                if (field.startsWith('machine')) {
                    // machine_costpoolgroup
                    const costPool = relationData.costPools.find(i => i.groupId === Number(groupId));
                    value = costPool ? costPool.translationKey : '';
                } else {
                    // costpoolgroup
                    const costPool = currentValue.find(i => i.groupId === Number(groupId));
                    value = costPool ? { ...costPool, label: costPool.translationKey } : null;
                }
            } else if (currentValue) {
                // machine edit
                const costPool = currentValue.find(i => i.groupId === Number(groupId));
                value = costPool ? { ...costPool, label: costPool.translationKey } : null;
            }
        } catch (e) {
            console.log(e);
        }
        return value;
    } else {
        switch (field) {
            case 'worker':
                return getWorkerValue(currentValue, defaultValue, dataType, user, optionsLists.workerOptions, noviConfigs, location);
            case 'machinetype':
                return currentValue
                    ? { id: currentValue.id, label: currentValue.name }
                    : null;
            case 'person':
                return currentValue
                    ? { id: currentValue.id, label: currentValue.name || currentValue.label }
                    : null;
            case 'machine':
                return currentValue
                    ? currentValue
                    : location.state.machine
                        ? { id: location.state.machine.id, name: location.state.machine.name, code: location.state.machine.code }
                        : null;
            case 'machinehalt':
                if (currentValue || (cardType !== 'editWorkCard' && defaultValue?.toUpperCase() === 'TRUE')) {
                    return { id: 1, label: i18n.t('YES') }
                }

                return { id: 0, label: i18n.t('NO') };
            case 'urgency':
                const urgencyValue = currentValue ? parseInt(currentValue, 10)
                    : defaultValue ? parseInt(defaultValue, 10)
                        : null;

                const urgencyOption = optionsLists.urgencyOptions.find(urgercyOpt => urgercyOpt.urgencyValue === urgencyValue);

                return urgencyOption ? { id: urgencyOption.id, label: i18n.t(urgencyOption.label) } : null;
            case 'workstatus':
                const statusValue = currentValue
                    ? currentValue.id
                        ? parseInt(currentValue.id, 10)
                        : parseInt(currentValue, 10)
                    : defaultValue
                        ? parseInt(defaultValue, 10)
                        : noviConfigs.WorkStatusNoticed
                            ? parseInt(noviConfigs.WorkStatusNoticed, 10)
                            : null;

                // TODO: Figure out optionsList -> statusOptions vs workStatusOptions
                const statusOption = optionsLists.workStatusOptions?.length > 0
                    ? optionsLists.workStatusOptions.find(statusOpt => statusOpt.id === statusValue)
                    : optionsLists.statusOptions?.find(statusOpt => statusOpt.id === statusValue);
                return statusOption ? { id: statusOption.id, label: i18n.t(statusOption.label), isDisabled: statusOption.isDisabled } : '';
            case 'orderer':
                return currentValue
                    ? { id: currentValue.id, label: currentValue.name }
                    : null;
            case 'phasehours' && relationData:
                return ''
            case ('phaseprocedures' || 'phasecomments') && relationData:
                return FormList({ list: relationData[field], listItemName: 'name' });
            case 'notificationtarget':
                return notificationTargets?.length > 0 ? notificationTargets : defaultValue ? [{ id: null, value: defaultValue, workCardId: null }] : []
            case 'rating':
                return currentValue?.id ? { id: currentValue.id, label: currentValue.value } : null
            case 'tracking':
                return currentValue ? currentValue : false
            case 'investmentcode':
                return currentValue ? { id: currentValue.id, name: currentValue.value } : null
            case 'machinerequirements':
                return Array.isArray(currentValue) ? currentValue.map(val => ({ id: val.id, label: val.label || val.value })) : [];
            case 'workergroup':
                if (currentValue) {
                    return Array.isArray(currentValue) ? currentValue.map(val => ({ id: val.id, label: val.name })) : [{ id: currentValue.id, label: currentValue.name }];
                } else if (defaultValue) {
                    const wGroupDefault = optionsLists.workerGroups?.find(wGroup => wGroup.id === parseInt(defaultValue, 10));
                    return wGroupDefault ? [wGroupDefault] : []
                }

                return [];
            default:
                return currentValue || defaultValue;
        }
    }
}


/**
 * Function to handle options init for select elements
 * 
 * field               = field name
 * type                = select, async, etc.
 * values              = which specific values given setting has, e.g. work status values
 * optionsLists        = different option sets, i.e. worker, urgency, work status options, ...
 * details             = item specific details data (machineDetails, workCardDetails, ...)
 * dataType            = type for filtering or changing options data, e.g. dataType could be 'workcard', 'workphase', etc.
 * isMultiSelectDetail = is detail multiselect enabled
 *
 */
export function GetOptionsDataByField(field, type, values, optionsLists, details = [], dataType = '', isMultiSelectDetail = false) {
    if (type === 'detail' || type === 'multidetail' || type === 'phasedetail') {
        let detailData = details ? details.filter(detailObj => detailObj.group === field) : null;

        if (isMultiSelectDetail || type === 'multidetail') {
            detailData = detailData.map(detail => ({
                    id: detail.id,
                    group: detail.group,
                    value: toKeyFormat(detail.value),
                    rowNumber: detail.rowNumber,
                    parentId: detail.parentId
                }));
        }

        if (detailData && detailData.some(obj => obj.hasOwnProperty('rowNumber'))) {
            return detailData.map(d => ({
                    id: d.id,
                    group: d.group,
                    label: toKeyFormat(d.value),
                    rowNumber: d.rowNumber,
                    parentId: d.parentId
                })).sort((a, b) => a.rowNumber - b.rowNumber);
        }

        if (detailData) {
            let sortedDetails = SortArray(detailData, 'value');
            return sortedDetails.map(d => ({
                id: d.id,
                group: d.group,
                label: toKeyFormat(d.value),
                rowNumber: d.rowNumber,
                parentId: d.parentId
            }));
        } else { return []; }
    } else if (type === 'thirdparty') {
        return [];
    } else if (type === 'costpoolgroup') {
        let options = [];
        try {
            const groupId = field.split('costpoolgroup_')[1];
            const costPoolsByGroupId = optionsLists.costPoolOptions?.byGroup[groupId];
            if (costPoolsByGroupId !== undefined) {
                options = costPoolsByGroupId
                    .filter(i => i.viewFilter.length < 1 || i.viewFilter.some(j => j === VIEW_FILTER[dataType.toUpperCase()]))
                    .map(i => ({ ...i, label: i.translationKey }))
                    .sort((a, b) => a.tabOrder - b.tabOrder);
            }
        } catch (e) { console.log(e); }
        return options;
    } else {
        const fieldOptionsSets = {
            'worker': optionsLists.workerOptions ? SortArray(optionsLists.workerOptions, 'label') : [],
            'workstatus': optionsLists.statusOptions
                ? optionsLists.statusOptions
                    .filter(sType => (values.find(val => parseInt(val, 10) === sType.id)))
                    .map(sType => ({ id: sType.id, label: GetCustomLabel(dataType, sType), isDisabled: sType.isDisabled }))
                : [],
            'machinehalt': [
                { id: 0, label: i18n.t('NO') },
                { id: 1, label: i18n.t('YES') }
            ],
            'urgency': optionsLists.urgencyOptions
                ? optionsLists.urgencyOptions
                    .filter(uType => (values.find(val => parseInt(val, 10) === uType.id)))
                    .map(uType => ({ id: uType.id, label: i18n.t(uType.label) }))
                : [],
            'orderer': optionsLists.ordererOptions ? SortArray(optionsLists.ordererOptions, 'label') : [],
            'workergroup': optionsLists.workerGroups ? SortArray(optionsLists.workerGroups, 'label') : [],
            'machinetype': optionsLists.machineTypeOptions || [],
            'person': optionsLists.workerOptions ? SortArray(optionsLists.workerOptions, 'label') : [],
            'responsibleperson': optionsLists.responsiblePersonOptions ? SortArray(optionsLists.responsiblePersonOptions, 'label') : [],
            'machinerequirements': optionsLists.machineRequirementOptions ? SortArray(optionsLists.machineRequirementOptions, 'label') : [],
            'rating': optionsLists.ratingOptions ? SortArray(optionsLists.ratingOptions, 'label') : [],
            'investmentcode': optionsLists.investmentCodeOptions ? SortArray(optionsLists.investmentCodeOptions, 'label') : []
        };

        return fieldOptionsSets[field] || [];
    }
}


function GetCustomLabel(dataType, sType) {
    if (dataType === 'workcard') {
        return sType.isDisabled
            ? i18n.t(sType.label) + ' (' + i18n.t('UNFINISHED_WORKPHASES_ON_WORKCARD') + ')'
            : i18n.t(sType.label);
    } else {
        return i18n.t(sType.label);
    }
}

export function PropertyNamesToCamelCase(obj) {
    Object.keys(obj).forEach(oldKey => {
        let newKey = settingsAPI.propertyCasingMap[oldKey];

        if (newKey) {
            delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey];
        }
    });

    return obj;
}

//generic
export function propertiesToLowerCase(obj: object): object {
    if (typeof obj === 'undefined' || obj === null) return {};
    let key, keys = Object.keys(obj);
    let n = keys.length;
    let newObj = {}
    while (n--) {
        key = keys[n];
        newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
}

export function TruncateString(string, tuncateLimit) {
    if (!string || !tuncateLimit) return '';
    return string.length > tuncateLimit ? string.substring(0, (tuncateLimit - 3)) + "..." : string;
}

export function sortHierarchyItems(arr, parentId = null, result = []) {
    arr.forEach(el => {
        if (el.parentId === parentId) {
            result.push(el);
            sortHierarchyItems(arr, el.id, result);
        }
    });
    return result;
}

export function HandleSelectOption(key, option) {
    let value;

    if (Array.isArray(option)) {
        value = [];
        key === 'notificationtarget' ?
            option.map((option, i) => {
                value[i] = option
            }) :
            key === 'machinerequirements' ?
                option.map((option, i) => {
                    value[i] = { id: option.value, value: option.label }
                }) :
                option.map((option, i) => (
                    value[i] = { id: option.value, label: option.label }
            ))
    } else {
        value = (option.id === -1 || option.value === -1)
            ? null
            : key !== 'machineHalt'
                ? option
                : { value: option.value, label: option.label, ...option };
    }
    return value;
}

export function InitDialogBodyContent(typeKey, targetValue) {
    return i18n.t('DELETE_ITEM_MESSAGE') + ' ' + i18n.t(typeKey).toLowerCase() + ' ' + targetValue;
}

export function GetTokenExpTime(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const jwt = JSON.parse(jsonPayload);

    return jwt.exp;
}
/**
 * Handle change of value when parent field value is changed.  
 * **NOTE:** Only use for hardcoded child detail fields.
 */
export function filterValuesByHardCodedParent(fieldData, stateValues, parentValue) {
    // if parent selection was null OR field not in use, then present existing value will be returned
    if (!parentValue || !fieldData) {
        return stateValues;
    }

    // specfic options list
    const options = fieldData.options;
    // map parent selections to id -values
    const parentValues = Array.isArray(parentValue) ? parentValue.map(selection => selection.value) : [parentValue.value];
    // filter possible options by parent selection
    const possibleOpts = options.filter(opt => (parentValues.find(parentVal => parentVal === opt.parentId) || opt.parentId === null));
    // filter currently selected child values by finding matches from previously filtered options list
    const currentValues = Array.isArray(stateValues) ? [...stateValues] : [{ ...stateValues }];
    const filteredValues = currentValues.filter(currentVal => possibleOpts.find(opt => opt.id === currentVal.value));

    // return as Object if stateValues is not an array (expected to be a single-select field) and in other case return as is
    return Array.isArray(stateValues)
            ? filteredValues
            : filteredValues[0] ?? null;
}

/**
 * Handle value change for child detail fields when parent field value is changed  
 * **NOTE:** Requires detail fields/options to have properly configured 'parentId'
 */
export function filterValuesByParentSelection(fieldData, stateValues, parentValue) {
    // if field not in use, then present existing value will be returned
    if (!fieldData) {
        return stateValues;
    }

    // specfic options list
    const options = fieldData.options;
    // map parent selections to id -values
    const parentValues = parentValue 
        ? Array.isArray(parentValue) 
            ? parentValue.map(selection => selection.value) 
            : [parentValue.value] 
        : [null];

    // filter possible options by parent selection
    const possibleOpts = options.filter(opt => (parentValues.find(parentVal => parentVal === opt.parentId)));
    // filter currently selected child values by finding matches from previously filtered options list
    const currentValues = Array.isArray(stateValues) ? [...stateValues] : [{ ...stateValues }];
    const filteredValues = currentValues.filter(currentVal => possibleOpts.find(opt => opt.id === currentVal.value || opt.id === currentVal.id));

    // return as Object if stateValues is not an array (expected to be a single-select field) and in other case return as is
    return Array.isArray(stateValues)
            ? filteredValues
            : filteredValues[0] ?? null;
}

/**
 * determine if an array contains one or more items from another array.
 * arr1 array to search from.
 * arr2 values to check for.
 */
export const containsAny = (arr1: any[], arr2: any[]): boolean => arr1.some(v => (arr2.includes(v)))

export const filteredByKey = (obj, filters) =>
    Object.entries(obj).filter(([key, _value]) => filters.includes(key))
        .reduce((result, current) => {
            result[current[0]] = current[1];
            return result;
        }, {})

export const filteredByValue = (obj, filters) =>
    Object.entries(obj).filter(([_key, value]) => filters.includes(value))
        .reduce((result, current) => {
            result[current[0]] = current[1];
            return result;
        }, {})

export const mapLanguageCode = (languageCode: string) => {
    switch (languageCode.toLowerCase()) {
        case 'fi-fi':
            return 'fi';
        case 'sv-se':
        case 'sv-fi':
            return 'sv';
        case 'be-by':
            return 'be';
        case 'de-de':
            return 'de';
        case 'es-cl':
            return 'es-US';
        case 'es-es':
            return 'es';
        case 'et-ee':
            return 'et';
        case 'fr-fr':
            return 'fr';
        case 'it-lt':
            return 'lt';
        case 'lv-lv':
            return 'lv';
        case 'nl-nl':
            return 'nl';
        case 'no-no':
            return 'nb';
        case 'pl-pl':
            return 'pl';
        case 'ru-ru':
            return 'ru';
        case 'vi-vn':
            return 'vi';
        case 'sl-si':
            return 'sl';
        case 'xx-xx':
            return 'xx-xx'; // No Translations -> Translation keys will be shown in the UI.
        default:
            return languageCode;
    }
}

export const mapTranslations = (translations: ITranslation[]) => {
    let translationMap: { [key: string]: string } = {};
    for (const { key, value } of translations) {
        Object.assign(translationMap, { [key]: value });
    }
    return translationMap;
}

export const drawLink = (address: string, value: string): JSX.Element => (
    <a href={address} target="_blank">{value}</a>
);

export const filterDetailOptions = (detailOpts, stateProp: { id; label; } | IOptionType | IOptionType[]) => {
    if (stateProp !== undefined && stateProp !== null) {
        if (Array.isArray(stateProp)) {
            if (stateProp.length > 0) {
                return detailOpts.filter(detail => (
                    stateProp.find(parent => parent.value === detail.parentId) || detail.parentId === null
                ));
            }
        } else if ('value' in stateProp) {
            return detailOpts.filter(detail => (
                detail.parentId === stateProp.value || detail.parentId === null
            ));
        } else if ('id' in stateProp) {
            return detailOpts.filter(detail => (
                detail.parentId === stateProp.id || detail.parentId === null
            ));
        }
    }

    return detailOpts.filter(detail => detail.parentId === null);
}

//TODO: move to workcards specific helperfunctions file
export const defineSearchQueryParams = (params, queryParams) => {
        params.timePeriod = {
            startDate: parsePayloadDate(params.filterDateStart) || '',
            endDate: parsePayloadDate(params.filterDateEnd) || ''
        }

        queryParams.append('SearchString', params.searchValue || '');
        queryParams.append('NoWorkers', params.noWorkers || false);
        queryParams.append('StartDate', params.timePeriod.startDate);
        queryParams.append('EndDate', params.timePeriod.endDate);
        queryParams.append('IsDelayed', params.isDelayed || false);

        if (params.filterByCode) {
            queryParams.append('WorkCardCode', params.filterByCode);
        }
        if (params.filterByWorktype && params.filterByWorktype.length > 0) {
            params.filterByWorktype.forEach(wType => { queryParams.append('DetailIds', wType.id); })
        }

        if (params.workerGroupIds && params.workerGroupIds.length > 0) {
            params.workerGroupIds.forEach(wGroupId => { queryParams.append('WorkerGroupIds', wGroupId); })
        } else if (params.filterByWorkerGroup && params.filterByWorkerGroup.length > 0) {
            params.filterByWorkerGroup.forEach(wGroup => { queryParams.append('WorkerGroupIds', wGroup.id); })
        }
        if (params.filterByWorkCardType && params.filterByWorkCardType.length > 0) {
            params.filterByWorkCardType.forEach(wcType => { queryParams.append('WorkCardTypes', wcType.id); })
        }

        if (params.workStatuses && params.workStatuses.length > 0) {
            params.workStatuses.forEach(wStatusId => { queryParams.append('WorkStatuses', wStatusId); });
        } else if (params.filterByWorkStatus && params.filterByWorkStatus.length > 0) {
            params.filterByWorkStatus.forEach(wStatus => { queryParams.append('WorkStatuses', wStatus.id); })
        }
        if (params.workerIds && params.workerIds.length > 0) {
            params.workerIds.forEach(workerId => { queryParams.append('WorkerIds', workerId); })
        } else if (params.filterByWorker && params.filterByWorker.length > 0) {
            params.filterByWorker.forEach(worker => { queryParams.append('WorkerIds', worker.id); })
        }
        if (params.ordererIds && params.ordererIds.length > 0) {
            params.ordererIds.forEach(orderer => { queryParams.append('OrdererIds', orderer.id); })
        } else if (params.filterByOrderer && params.filterByOrderer.length > 0) {
            params.filterByOrderer.forEach(orderer => { queryParams.append('OrdererIds', orderer.id); })
        }
    return queryParams;
}

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

export const restrictRenderingByParameters = (params: string, userRights) => {
    switch (params) {
        // Handles workcanbegin & workcanend fields IsDisabled property.
        // Sets IsDisabled property accordingly.
        case 'workcanbegin':
        case 'workcanend':
            if (HasRight(UserRights.WorkScheduleEdit, userRights)) {
                return false;
            } else {
                if (HasRight(UserRights.RestrictedTimestampsEdit, userRights)) {
                    return true;
                } else {
                    return false;
                }
            }
            
        // Handles rendering work card material's add, edit & delete buttons.
        case UserRights.RestrictedMaterialEdit:
            if (HasRight(UserRights.RestrictedMaterialEdit, userRights)) {
                return false;
            }
            if (HasRight(UserRights.WorkScheduleEdit, userRights) || HasRight(UserRights.RestrictedTimestampsEdit, userRights)) {
                return true;
            }
            return false;
            
        // Handles rendering work card's action buttons
        case 'workcardedit': {
            if (HasRight(UserRights.WorkScheduleEdit, userRights) ||
                HasRight(UserRights.RestrictedTimestampsEdit, userRights) ||
                HasRight(UserRights.RestrictedMaterialEdit, userRights)) {
                return true
            } else {
                return false
                }
            }
        case UserRights.PurchasesEdit:
            return HasRight(UserRights.PurchasesEdit, userRights);
        default:
            break;
    }
}

/**
 * Tries to parse string to boolean.
 * @param {string}value A String to convert to Boolean.
 * 
 * Converts given string to lower case and checks if value equals 'true' - Returns boolean value representing `true`.
 * In other cases - Returns `false`.
 */
export const parseStringToBoolean = (value: string): boolean => {
    return value?.toLowerCase() === 'true'
}

/**
 * Replaces scandic letters, white spaces and parenthesis, and returns either translation by the new key or by the original one.
 * 
 * @param (string)translationKey A translation key
 * 
 * Returns string
 */
export const toKeyFormat = (translationKey: string) => {
    if (!translationKey) return '';

    const upperCased = translationKey.toUpperCase();
    const scandinavianLettersRemoved = upperCased.replace(/Ä/g, "A").replace(/Ö/g, "O").replace(/Å/g, "A");
    const underScoresAdded = scandinavianLettersRemoved.replace(/ /g, "_").replace(/\(|\)/g, "");
    const translated = i18n.t(underScoresAdded);

    if (translated !== underScoresAdded) {
        return translated;
    } else {
        return i18n.t(translationKey);
    }
}