import React from 'react';
import { Modal } from 'react-bootstrap';

const DialogFooter = (props: IProps) => {
    return (
        <Modal.Footer>
            {props.children}
        </Modal.Footer>
    );
}

interface IProps {
    children: React.ReactNode;
}

export default DialogFooter;