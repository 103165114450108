import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../scenes/dashboard';
import Login from '../scenes/login';
import Machines from '../scenes/machines';
import Machine from '../scenes/machines/components/Machine';
import Measurement from '../scenes/machines/components/MeasurementView';
import EditMachine from '../scenes/machines/components/EditMachine';
import WorkList from '../scenes/work-schedule/work-list';
import WorkCardView from '../scenes/work-schedule/work-card';
import RouteWorkCardView from '../scenes/work-schedule/work-card/RouteWorkCardView';
import EditWorkCard from '../scenes/work-schedule/work-card/components/EditWorkCard';
import WorkPhase from '../scenes/work-schedule/work-card/components/WorkPhase';
import EditWorkPhase from '../scenes/work-schedule/work-card/components/EditWorkPhase';
import Maintenances from '../scenes/maintenances';
import EditMaterial from '../scenes/work-schedule/work-card/components/EditMaterial';
import OperatorMaintenanceList from '../scenes/operator-maintenance-list';
import OperatorMaintenance from '../scenes/operator-maintenance';
import Warehouse from '../scenes/warehouse/Warehouse';
import SparePartView from '../scenes/spare-part';
import WarehouseOperations from '../scenes/warehouse/components/WarehouseOperations/WarehouseOperations';
import WarehouseInvent from '../scenes/warehouse/components/WarehouseInvent';
import EditSparePart from '../scenes/spare-part/components/EditSparePart';
import WorkPermit from '../scenes/work-schedule/work-card/components/WorkPermit';
import EditWorkPermit from '../scenes/work-schedule/work-card/components/EditWorkPermit';
import WarehouseLinkEdit from '../scenes/warehouse/components/WarehouseLinkEdit';
import MachineLinkEdit from '../scenes/machines/components/MachineLinkEdit';
import MachineLinkAddition from '../scenes/machines/components/MachineLinkAddition';
import RestrictedRoute from './RestrictedRoute';
import Callback from '../scenes/callback/Callback';
import NotFound from '../scenes/not-found/NotFound';
import HourCardsList from '../scenes/hour-cards/HourCardsList';
import EditHourCard from '../scenes/hour-cards/components/EditHourCard';
import HourCardView from '../scenes/hour-cards/components/HourCardView';
import { UserRights } from '../constants/userRights';
import WarehouseArrive from '../scenes/warehouse/components/WarehouseOperations/WarehouseArrive';
import EditMeasurement from '../scenes/machines/components/EditMeasurement';
import Purchases from '../scenes/purchases';
import Counters from '../scenes/work-schedule/work-card/components/Counters';
import EditCounter from '../scenes/machines/components/EditCounter';
import RouteMaintenanceWorkCardPhaseMeasurementEdit from 'scenes/work-schedule/work-card/components/RouteMaintenanceWorkCardPhaseMeasurementEdit';
import SafetyCards from 'scenes/safety-cards';
import EditSafetyNotice from 'scenes/safety-cards/EditSafetyNotice';

export const Routes = ({ ready }) => {
    return (
        <div>
            <Switch>
                <RestrictedRoute path="/machines/:machineId?"
                    ready={ready}
                    component={Machines}
                    viewRight={['machineregistryview']}
                />
                <RestrictedRoute path="/machine/:machineId/sparepart/new"
                    ready={ready}
                    component={MachineLinkAddition}
                    viewRight={['machineregistryedit']}
                />
                <RestrictedRoute path="/machine/new/:mId?"
                    ready={ready}
                    component={EditMachine}
                    viewRight={['machineregistryedit']}
                />
                <RestrictedRoute path="/machine/new"
                    ready={ready}
                    component={EditMachine}
                    viewRight={['machineregistryedit']}
                />
                <RestrictedRoute path="/machine/:machineId/edit/:mId?"
                    ready={ready}
                    component={EditMachine}
                    viewRight={['machineregistryedit']}
                />
                <RestrictedRoute path="/machine/:machineId/:property?"
                    ready={ready}
                    component={Machine}
                    viewRight={['machineregistryview']}
                    exact={true}
                />
                <RestrictedRoute path="/machine/:machineId/measurement/:measurementId/workcard/:workCardId?"
                    ready={ready}
                    component={Measurement}
                    viewRight={['machineregistryview']}
                    exact={true}
                />
                <RestrictedRoute path="/machinelink/:machineLinkId/edit"
                    ready={ready}
                    component={MachineLinkEdit}
                    viewRight={['machineregistryedit']}
                />
                <RestrictedRoute path="/workcard/:workCardId/workphase/:workPhaseId/edit"
                    ready={ready}
                    component={EditWorkPhase}
                    viewRight={[UserRights.WorkScheduleEdit, UserRights.RestrictedTimestampsEdit, UserRights.RestrictedMaterialEdit]}
                />
                <RestrictedRoute path="/workcard/:workCardId/workpermit/:permitId/edit"
                    ready={ready}
                    component={EditWorkPermit}
                    viewRight={[UserRights.WorkPermitEdit, UserRights.WorkPermitEditOwn]}
                />
                <RestrictedRoute path="/workpermit/:id"
                    ready={ready}
                    component={WorkPermit}
                    viewRight={[UserRights.WorkPermitView, UserRights.WorkPermitViewAll]}
                />
                <RestrictedRoute path="/workcard/:workCardId/editworkpermit"
                    ready={ready}
                    component={EditWorkPermit}
                    viewRight={[UserRights.WorkPermitAdd]}
                />
                <RestrictedRoute path="/workcard/:workCardId/workphase/new"
                    ready={ready}
                    component={EditWorkPhase}
                    viewRight={[UserRights.WorkScheduleEdit, UserRights.RestrictedTimestampsEdit, UserRights.RestrictedMaterialEdit]}
                />
                <RestrictedRoute path="/workcard/:workCardId/workphase/:workPhaseId"
                    ready={ready}
                    component={WorkPhase}
                    viewRight={['workscheduleview']}
                />
                <RestrictedRoute path="/workcard/:workCardId/material/:materialId/:operation"
                    ready={ready}
                    component={EditMaterial}
                />
                <RestrictedRoute path="/workcard/:workCardId/material/:operation"
                    ready={ready}
                    component={EditMaterial}
                />
                <RestrictedRoute path="/workcard/:workCardId/edit/:machineId?"
                    ready={ready}
                    component={EditWorkCard}
                    viewRight={[UserRights.WorkScheduleEdit, UserRights.RestrictedTimestampsEdit, UserRights.RestrictedMaterialEdit]}
                />
                <RestrictedRoute path="/workcard/new/:type?/:machineId?"
                    ready={ready}
                    component={EditWorkCard}
                    viewRight={['workscheduleadd']}
                />
                <RestrictedRoute path="/workcard/:workCardId"
                    ready={ready}
                    component={WorkCardView}
                    viewRight={['workscheduleview']}
                />
                <RestrictedRoute path="/routeworkcard/measurement/edit"
                    ready={ready}
                    component={RouteMaintenanceWorkCardPhaseMeasurementEdit}
                    viewRight={[]}
                />
                <RestrictedRoute path="/routeworkcard/:workCardId"
                    ready={ready}
                    component={RouteWorkCardView}
                    viewRight={[UserRights.WorkScheduleView, UserRights.WorkScheduleEdit]}
                />
                <RestrictedRoute path="/worklist"
                    ready={ready}
                    component={WorkList}
                    viewRight={['workscheduleview']}
                />
                <RestrictedRoute path="/maintenances"
                    ready={ready}
                    component={Maintenances}
                />
                <RestrictedRoute path="/operatormaintenances"
                    ready={ready}
                    component={OperatorMaintenanceList}
                    viewRight={['operatormaintenanceview']}
                />
                <RestrictedRoute path="/operatormaintenance/:opMaintenanceId"
                    ready={ready}
                    component={OperatorMaintenance}
                    viewRight={['operatormaintenanceview']}
                />
                <RestrictedRoute path="/warehouse/arrive/:sparePartId?"
                    ready={ready}
                    component={WarehouseArrive}
                    viewRight={['warehouseoperationarrive']}
                />
                <RestrictedRoute path="/warehouse"
                    ready={ready}
                    component={Warehouse}
                    viewRight={['warehouseview']}
                />
                <RestrictedRoute path="/warehouselink/:warehouseLinkId/edit"
                    ready={ready}
                    component={WarehouseLinkEdit}
                    viewRight={['warehouseedit']}
                />
                <RestrictedRoute path="/sparepart/:sparePartId/:operation"
                    ready={ready}
                    component={EditSparePart}
                    viewRight={['warehouseedit']}
                />
                <RestrictedRoute path="/sparepart/new"
                    ready={ready}
                    component={EditSparePart}
                    viewRight={['warehouseedit']}
                />
                <RestrictedRoute path="/sparepart/:sparePartId?"
                    ready={ready}
                    component={SparePartView}
                    viewRight={['warehouseview']}
                />
                <RestrictedRoute path="/warehouseoperations/:sparePartId?/:operation/:machineId?"
                    ready={ready}
                    component={WarehouseOperations}
                />
                <RestrictedRoute path="/warehouseinvent/:sparePartId?"
                    ready={ready}
                    component={WarehouseInvent}
                    viewRight={['warehouseoperationinvent']}
                />
                <RestrictedRoute path="/hourcards"
                    ready={ready}
                    component={HourCardsList}
                    viewRight={['hourcardview']}
                />
                <RestrictedRoute path="/hourcard/new"
                    ready={ready}
                    component={EditHourCard}
                    viewRight={['hourcardedit']}
                />
                <RestrictedRoute path="/hourcard/edit/:id"
                    ready={ready}
                    component={EditHourCard}
                    viewRight={['hourcardedit']}
                />
                <RestrictedRoute path="/hourcard/:id"
                    ready={ready}
                    component={HourCardView}
                    viewRight={['hourcardview']}
                />
                <RestrictedRoute path="/counter/:id/edit"
                    ready={ready}
                    component={EditCounter}
                    viewRight={[UserRights.CountersEdit]}
                />
                <RestrictedRoute path="/counters"
                    ready={ready}
                    component={Counters}
                    viewRight={[UserRights.CountersView]}
                />
                <RestrictedRoute path="/measurement/edit"
                    ready={ready}
                    component={EditMeasurement}
                    viewRight={[UserRights.WorkScheduleEdit, UserRights.RestrictedTimestampsEdit, UserRights.RestrictedMaterialEdit]}
                />
                <RestrictedRoute path="/purchases/:workCardId"
                    ready={ready}
                    component={Purchases}
                    viewRight={[UserRights.PurchasesEdit]}
                />
                <RestrictedRoute path="/safety/:id"
                    ready={ready}
                    component={SafetyCards}
                    viewRight={[UserRights.SafetyView, UserRights.SafetyViewOwn]}
                />
                <RestrictedRoute path="/safetynotices/:id/:action/:machineId?"
                    ready={ready}
                    component={EditSafetyNotice}
                    viewRight={[UserRights.SafetyAdd]}
                />
                <Route path="/callback" component={Callback} />
                <RestrictedRoute path="/dashboard"
                    ready={ready}
                    component={Dashboard}
                />
                <RestrictedRoute path="/"
                    ready={ready}
                    component={Login}
                    exact
                />
                <RestrictedRoute path="*"
                    ready={ready}
                    component={NotFound} />
            </Switch>
        </div>
    );
};
