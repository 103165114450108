import React from 'react';
import { Container } from 'react-bootstrap';
import ListWithButtonAndRemoveItem from '../../../components/lists/ListWithButtonAndRemoveItem';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';

const OperatorMaintenanceGeneralSettings = (props: IProps) => {
    const { opmForm } = props;

    const elements = Object.keys(opmForm).map(key => {
        const field = opmForm[key];

        const className = field.required
            && (!props.values[key + '_general'] || props.values[key + '_general'].length < 1)
            && props.invalidFields
            ? 'invalid-field'
            : '';

        const handleChange = field.type === 'datetime'
            ? props.handleDatetime
            : props.handleChange;

        const options = key === 'workers'
            ? props.filterWorkerOptions(field.options)
            : field.options;

        const fieldName = `${key}_general`;

        return (
            <div
                key={key}
                className={className}>
                <FormElement
                    key={key}
                    name={fieldName}
                    type={field.type}
                    label={field.label}
                    value={props.values[fieldName]}
                    options={options}
                    onChange={handleChange}
                    required={props.getRequiredValue(field.settingKey)}
                />
            </div>
        )
    })


    const documentsTitle = i18n.t('DOCUMENTS').toUpperCase();
    const btnAddText = i18n.t('ADD_DOCUMENT');
    const noDocumentsText = i18n.t('NO_DOCUMENTS');

    return (
        <Container>
            <div className="v-margins-15">
                <div className="form-table-container">
                    <form>
                        {elements}
                    </form>
                    <ListWithButtonAndRemoveItem
                        title={documentsTitle}
                        itemList={props.documentList}
                        noItemsText={noDocumentsText}
                        removeItem={props.removeDocument}
                        buttonText={btnAddText}
                        handleBtnClick={props.openDocumentView}
                    />
                </div>
            </div>
        </Container>
    )
}

interface IProps {
    opmForm
    getRequiredValue
    filterWorkerOptions
    handleChange
    handleDatetime
    values
    invalidFields: boolean;
    documentList: string[];
    removeDocument: (index: number) => void;
    openDocumentView: () => void;
}

export default OperatorMaintenanceGeneralSettings;