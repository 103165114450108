import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HandleError } from 'components/HelperFunctions';
import React, { useEffect, useState } from 'react';
import noviAPI from '../../../api/noviAPI';
import { useDispatch } from 'react-redux';
import { fetchSafetyTypes } from 'commonActions/actions';

interface MenuItemsProps {
    machineGroupId: number;
}
 
const MenuItems = (props: MenuItemsProps) => {
    //Hooks
    const [customLinks, setCustomLinks] = useState<ICustomLink[]>([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.machineGroupId) {
            setShowSpinner(true);
            noviAPI.personGroupAppSettings.fetchMainMenuItems(props.machineGroupId, 'customlink').then(response => {
                if (response.status === 200) {
                    setCustomLinks(response.data);
                    setShowSpinner(false);
                }
            })
            .catch(err => {
                setShowSpinner(false);
                HandleError(err, "Fetch customlinks");
            });

            dispatch(fetchSafetyTypes());
        }
    }, [props.machineGroupId]);

    return (
        <div className='customlinks-wrapper'>        
            {!showSpinner ? customLinks?.map((i, index) => (
                i.value?.value 
                    ? <a    className="customlink" href={ i.value?.value } key={index}>{i.label}</a>
                    : <span className="customlink" style={{color: "#999"}} key={index}>{i.label}</span>
            )) : <FontAwesomeIcon icon={faSpinner} size="lg" pulse={true} />}
        </div>
    );
}
 
export default MenuItems;
