import React from 'react';
import '../styles/machines.scss';
import { Row, Col } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import CardComponent from '../../../components/CardComponent';
import TextTruncate from 'react-text-truncate';
import { useHistory } from 'react-router-dom';

const MachineCardComponent = ({ machine, viewSettings }) => {
    let history = useHistory();
    let linkTo;

    const title = (
        <div>
            <b>
                <TextTruncate
                    line={2}
                    truncateText="..."
                    text={machine.name ? machine.name : ''}
                />
            </b>
            <span className="sub-header-light priority-level"></span>
        </div>
    );

    linkTo = typeof machine.model !== 'undefined' && '/machine/' + machine.id;
    //TODO: form rows from viewSettings <-- contains machineGroupSettings data

    const handleClick = (path) => {
        history.push({
            pathname: path,
            state: { scrollPosition: window.pageYOffset }
        });
    }

    const body = (
        <div onClick={() => handleClick(linkTo)}>
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('NAME')}
                </Col>
                <Col xs={8} className="break-word">
                    <TextTruncate
                        line={1}
                        truncateText="..."
                        text={machine.name}
                    />
                </Col>
            </Row>}
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('UPPER_LEVEL')}
                </Col>
                <Col xs={8} className="break-word">
                    {machine.parent
                        ? machine.parent.machineCode
                            ? machine.parent.machineCode + ' / ' + machine.parent.name
                            : machine.parent.name
                        : ''
                    }
                </Col>
            </Row>}
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('MACHINE_CODE')}
                </Col>
                <Col xs={8} className="break-word">
                    {machine.code}
                </Col>
            </Row>}
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('TYPE')}
                </Col>
                <Col xs={8} className="break-word">
                    {machine.type && machine.type.name}
                </Col>
            </Row>}
            {viewSettings && <Row className="margin-bottom-5">
                <Col xs={4} className="sub-header-light break-word">
                    {i18n.t('MODEL')}
                </Col>
                <Col xs={8} className="break-word">
                    {machine.model}
                </Col>
            </Row>}
        </div>
    );

    return <CardComponent title={title} body={body} />;
}

export default MachineCardComponent;
