import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noviAPI from '../api/noviAPI';
import * as types from 'config/actionTypes';

function useWarehouses() {
    const dispatch = useDispatch();
    const [warehouses, setWarehouses] = useState<IWarehouseLite[]>([]);
    const machineGroupId = useSelector((state: State) => state.settings.machineGroupId);
    const allWarehousesSearch = {
        id: -1,
        name: "*"
    }

    useEffect(() => {
        noviAPI.warehouses.fetchAllByMachineGroup(machineGroupId)
            .then(({ data }) => {
                setWarehouses([allWarehousesSearch, ...data]);
                dispatch({
                    type: `${types.FETCH_WAREHOUSES}_FULFILLED`,
                    payload: data
                });
            });
    }, [machineGroupId]);

    return [warehouses];
}

export default useWarehouses;