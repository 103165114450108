import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import BarcodeReader from './BarcodeReader';

interface IProps {
    handleScan: (code: string) => void;
    handleClick: (e: React.MouseEvent) => void;
}

const BarcodeReaderContainer = React.forwardRef<null, IProps>((props, ref) => {
    const isOpen = useSelector((state: State) => state.scanners.barcode.isOpen);
    return (
        <span className="barcode-reader">
            {isOpen && <div className="barcode-scanner">
                <BarcodeReader ref={ref} onDetected={props.handleScan} />
            </div>}
            <Button
                onClick={props.handleClick}
                className="action input-row-button novi-default-btn-color"
            >
                <FontAwesomeIcon icon={faBarcode} size="2x" />
            </Button>
        </span>
    );
});

export default BarcodeReaderContainer;