import PaginationContainer from 'components/PaginationContainer';
import React, { useState } from 'react';
import { Accordion, CardGroup, Col, Container, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { openDocument } from 'scenes/operator-maintenance/utils';
import { getDateTime } from 'utils';
import DocumentPanelRow from '../../../components/panels/DocumentPanelRow';
import PanelText from '../../../components/panels/PanelText';
import i18n from '../../../translations/i18n';
import LinkPanelContainer from '../../machines/components/LinkPanelContainer';
import PanelContainer from '../../work-schedule/work-card/components/PanelContainer';
import { setWorkHistory } from '../actions';
import MeasurementsPanelGroup from './MeasurementsPanelGroup';
import PopoverAction from 'components/PopoverAction';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { HasRight } from 'components/HelperFunctions';
import { UserRights } from 'constants/userRights';

const MachineDetails = (props: IProps) => {
    let dispatch = useDispatch();
    const history = useHistory();
    const { counters } = props;

    const [dialogOpen, toggleDialog] = useState({});

    const { userRights } = useSelector((state: State) => state.settings);
    const countersView = HasRight(UserRights.CountersView, userRights);
    const countersEdit = HasRight(UserRights.CountersEdit, userRights);

    const workCardLinkPanels = (
        <div>
            <h2>{i18n.t('WORKCARDS').toUpperCase()}</h2>
            <CardGroup
                id="machine-additional-info-panel"
                className="margin-top-15 novi-panel"
            >
                <LinkPanelContainer
                    title={i18n.t('OPEN_WORK_CARDS')}
                    linkTo={`/machine/${props.machine.id}/openworkcards`}
                    property="openworkcards"
                    itemCount={props.linkPanelCounts.openworkcards}
                    linkState={{ viewProperty: 'openworkcards' }}
                    hasView
                />
                <LinkPanelContainer
                    title={i18n.t('SUB_MACHINE_OPEN_WORK_CARDS')}
                    linkTo={`/machine/${props.machine.id}/cm-openworkcards`}
                    property="cm-openworkcards"
                    itemCount={props.linkPanelCounts.cmopenworkcards}
                    linkState={{ viewProperty: 'cm-openworkcards' }}
                    hasView
                />
                <LinkPanelContainer
                    title={i18n.t('MACHINE_HISTORY')}
                    linkTo="/worklist"
                    property="workhistory"
                    itemCount={props.linkPanelCounts.workhistory}
                    linkState={{ viewProperty: 'workhistory' }}
                    handleClick={() => dispatch(setWorkHistory({
                        id: props.machine.id,
                        code: props.machine.code,
                        name: props.machine.name
                    }))}
                    hasView
                />
            </CardGroup>
        </div>
    )

    const sparePartLinkPanels = (
        <div>
            <h2>{i18n.t('SPARE_PARTS').toUpperCase()}</h2>
            <CardGroup
                id="machine-additional-info-panel"
                className="margin-top-15 novi-panel"
            >
                <LinkPanelContainer
                    title={i18n.t('SPARE_PARTS')}
                    linkTo={`/machine/${props.machine.id}/spareparts`}
                    property="spareparts"
                    itemCount={props.linkPanelCounts.spareparts}
                    linkState={{ viewProperty: 'spareparts' }}
                    hasView
                />
            </CardGroup>
        </div>
    )

    const { machineDocuments, workCardDocuments, childMachineDocuments } = props.documents;

    const handleDocumentOpen = (document) => {
        const doc = {
            id: document.id,
            isUrl: (document.files && document.files[0]?.isUrl) || document.isUrl,
            documentLink: document.documentLink,
            caption: document.caption
        }
        openDocument(doc);
    }

    const machineDocumentRows = machineDocuments !== null ? machineDocuments.map(document => (
        <DocumentPanelRow
            key={document.id}
            documentName={document.caption}
            documentDescription={document.description}
            documentDate={getDateTime(document.createDate)}
            openDocument={() => handleDocumentOpen(document)}
            deleteDocument={() => props.deleteDocument(document)}
            allowDelete={props.machineRegistryEdit}
        />
    )) : [];

    const workCardDocumentRows = workCardDocuments !== null ? workCardDocuments.results.map(dl => (
        <DocumentPanelRow
            key={dl.document.id}
            documentName={dl.document.caption}
            documentDescription={dl.document.description}
            documentDate={getDateTime(dl.document.createDate)}
            openDocument={() => handleDocumentOpen(dl.document)}
        >
            <PanelText small>{i18n.t('CODE') + ': ' + dl.code}</PanelText>
        </DocumentPanelRow>
    )) : [];

    const childMachineDocumentRows = childMachineDocuments !== null ? childMachineDocuments.results.map(dl => (
        <DocumentPanelRow
            key={dl.document.id}
            documentName={dl.document.caption}
            documentDescription={dl.document.description}
            documentDate={getDateTime(dl.document.createDate)}
            openDocument={() => handleDocumentOpen(dl.document)}
        >
            <PanelText small>{i18n.t('CODE') + ': ' + dl.code}</PanelText>
        </DocumentPanelRow>
    )) : [];

    const documentPagination = (pageNumber: number, pageCount: number, panel: 'wc-documents' | 'cm-documents') => {
        if (pageCount > 1) {
            return (    
                <div className="panel-pagination-container">
                    <PaginationContainer
                        currentPage={pageNumber + 1}
                        setCurrentPage={(pageNumber) => props.setPageNumber(pageNumber - 1, panel)}
                        pageCount={pageCount}
                    />
                </div>
            )
        }
    }

    const documentAccordion = (
        <Accordion
            id="panel-with-sub-panels"
            className="margin-top-15 novi-panel"
        >
            <h2>{i18n.t('DOCUMENTS').toUpperCase()}</h2>
            <PanelContainer
                id={1}
                title={i18n.t('DOCUMENTS')}
                itemCount={machineDocumentRows.length}
            >
                <div>
                    {machineDocumentRows}
                </div>
            </PanelContainer>
            <PanelContainer
                id={2}
                title={i18n.t('WC_DOCUMENTS')}
                itemCount={workCardDocumentRows.length}
                >
                <div>
                    {workCardDocumentRows}
                </div>
                {documentPagination(workCardDocuments.currentPageNumber, workCardDocuments.totalPageCount, 'wc-documents')}
            </PanelContainer>
            <PanelContainer
                id={3}
                title={i18n.t('SUB_MACHINE_DOCUMENTS')}
                itemCount={childMachineDocumentRows.length}
                >
                <div>
                    {childMachineDocumentRows}
                </div>
                {documentPagination(childMachineDocuments.currentPageNumber, childMachineDocuments.totalPageCount, 'cm-documents')}
            </PanelContainer>
        </Accordion>
    )


    const getOverlayMenu = (counter) => {
        const popoverActions = [];
        
        if (countersEdit) {
            popoverActions.push({
                label: i18n.t("DO_EDIT"),
                paClass: 'edit',
                icon: 'edit'
            });
        } 
        const children = Object.keys(popoverActions).map((key, index) => {
            return <div key={index}>
                <PopoverAction
                    icon={popoverActions[key].icon}
                    label={popoverActions[key].label}
                    paClassName={popoverActions[key].paClass}
                    onClick={() => {
                        history.push(`/counter/${counter.id}/edit`);
                    }}
                    disabled={!countersEdit}
                />
                {index < popoverActions.length - 1 && <hr />}
            </div>
        });

        return (
            <OverlayTrigger
                trigger="click"
                rootClose={true}
                placement="left"
                overlay={<Popover id="settings-menu-popover" className="panel-menu-popover">
                    {React.Children.map(children, (child, i) => (
                        <div className="sub-section" key={`setting_${i}`}>
                            <div className="setting-option">
                                {child}
                            </div>
                        </div>
                    ))}
                </Popover>}
            >
                <div className="options-button" onClick={() => toggleDialog(counter.id)}><FontAwesomeIcon icon={faEllipsisV} size="lg" /></div>
            </OverlayTrigger>
        );
    }

    const countersAccordion = (
        <div id="panel-group-wrapper" className="margin-top-15 novi-panel">
            <h2>{i18n.t('COUNTERS').toUpperCase()}</h2>
            <Accordion
                id="panel-with-sub-panels"
                className="margin-top-15 novi-panel"
            >
                <PanelContainer
                    id={3}
                    title={i18n.t('COUNTERS')}
                    itemCount={counters?.length}
                >
                    {counters?.map(counter => (
                        <Row className="item-container-with-color-border" key={counter.id}>
                            <Col xs={10}>
                                <p className="bold">{counter.name}</p>
                                <div className="sub-header">
                                    <span className="right-margin-5">{i18n.t('COUNTER_ID')}: {counter.id}</span>
                                </div>
                                <div className="sub-header">
                                    <span className="right-margin-5">{i18n.t('COUNTER_VALUE')}: {counter.value}</span>
                                </div>
                            </Col>
                            {countersEdit && <Col xs={2}>
                                <div className="options-container">
                                    <span className="bar" />
                                    {dialogOpen == counter.id && <div className="overlay-canvas" onClick={() => toggleDialog(null)} ></div>}
                                    {getOverlayMenu(counter)}
                                </div>
                            </Col>}
                        </Row>
                    ))}
                </PanelContainer>
            </Accordion>
        </div>
    );

    return (
        <Container>
            {workCardLinkPanels}
            {sparePartLinkPanels}
            {documentAccordion}
            <MeasurementsPanelGroup machineMeasurements={props.measurements} />
            {countersView && countersAccordion}
        </Container>
    )
}

interface IProps {
    machine: IMachine;
    linkPanelCounts: LinkPanelCounts;
    documents: Documents;
    measurements: IMeasurementGroupWorkCard[];
    counters: ICounter[];
    machineRegistryEdit: boolean;
    setPageNumber: (pageNumber: number, panel: 'wc-documents' | 'cm-documents') => void;
    deleteDocument: (document: IDocument) => void;
}

type Documents = {
    machineDocuments: IDocument[];
    workCardDocuments: ISearchData<IMachineDocumentLink>;
    childMachineDocuments: ISearchData<IMachineDocumentLink>;
}

type LinkPanelCounts = {
    openworkcards: number;
    cmopenworkcards: number;
    workhistory: number;
    spareparts: number;
}

export default MachineDetails;