export const isOrderable = (mat: IWorkCardSparePart): boolean => {
    let orderable = true;
    let amountLeftToOrder = 0;

    if (mat.takeAmount > 0) {
        amountLeftToOrder = 0
    }
    else if (!mat.purchaseOrderItem) {
        amountLeftToOrder = mat.amount
    }
    else {
        amountLeftToOrder = (mat.amount ?? 0) - (mat.purchaseOrderItem?.amount ?? 0);
    }

    if (amountLeftToOrder <= 0) {
        orderable = false;
    }

    if (mat.sparePartReservationItem) {
        orderable = false;
    }

    return orderable;
}