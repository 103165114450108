import React from 'react';
import { Row, Col } from 'react-bootstrap';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';

const SparePartInventoryForm = ({ sparePartLink, onInputChange, skipInvent, updateSparePart, sparePartsCount, ...props }) => {
    const {
        inventorytype,
        inventoryAmountNotSet,
        inventoryAmount,
        currentSparePartIndex
    } = props;

    const shelfLocation = sparePartLink.warehouseSparePartLinks
        ? sparePartLink.warehouseSparePartLinks[0].shelfLocation
        : sparePartLink.shelfLocation;
    const amount = sparePartLink.warehouseSparePartLinks
        ? sparePartLink.warehouseSparePartLinks[0].amount
        : sparePartLink.amount;

    return (
        <div className={!sparePartLink ? 'blurred' : ''}>
            <Row className="margin-bottom-15">
                <Col xs={6}>
                    <div className="sub-header height-20">
                        <label className="no-margins">{i18n.t('SHELF_LOCATION')} </label>
                        {shelfLocation
                            ? <span className="bold">{shelfLocation}</span>
                            : <span className="cursive icon-default">{i18n.t('NOT_DEFINED')}</span>
                        }
                </div>
                </Col>
                <Col xs={3}>
                    {inventorytype === 1 && <span className="sub-header flex">
                        {i18n.t('SPARE_PART')}:
                    </span>}
                </Col>
                <Col xs={3}>
                    {inventorytype === 1 && currentSparePartIndex > -1 && <span className="sub-header float-right">
                        {currentSparePartIndex + 1} / {sparePartsCount}
                    </span>}
                </Col>
            </Row>
            <Row>
                <Col xs={11}>
                    <div className="v-margins-15 flex text-align-center height-45">
                        <span className="sub-header full-width">{i18n.t('AMOUNT_IN_WAREHOUSE')}: </span>
                        {sparePartLink && <p className="large-number">{amount}</p>}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={8}>
                    <div>
                        {sparePartLink && <p className="no-margins bold">{sparePartLink.sparePart.name}</p>}
                        {/*<div>
                            <p className="sub-header no-margins">
                                {(sparePartLink.sparePart.manufacturer || 'n/a')}
                                <span className="left-margin-5 right-margin-5"> / </span>
                                {(sparePartLink.sparePart.supplier || 'n/a')}
                            </p>
                        </div>*/}
                        <div>
                            {sparePartLink && <p className="sub-header no-margins">{sparePartLink.sparePart.code}</p>}
                        </div>
                    </div>
                </Col>
                <Col xs={4} className="align-right">
                    <div>
                        <div className={'flex height-45 text-align-center ' + (inventoryAmountNotSet && !inventoryAmount ? 'invalid-field' : '')}>
                            <FormElement
                                name={'amount'}
                                type={'number-input'}
                                label={''}
                                value={inventoryAmount ? parseInt(inventoryAmount, 10) : ''}
                                options={[]}
                                onChange={onInputChange}
                            />
                            <p className="sub-header left-margin-5">{i18n.t('PCS').toLowerCase()}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default SparePartInventoryForm;