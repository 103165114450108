import React from 'react';
import { Accordion, Button, Container } from 'react-bootstrap';
import PanelContainer from './../components/PanelContainer';
import i18n from '../../../../translations/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import WorkPhaseForm from './WorkPhaseForm';
import { Loader } from 'components/Loader';

const WorkPhasesAdd = ({
    phases,
    workPhasesState,
    handleInputChange,
    handleDateChange,
    handleSelect,
    getRequiredValue,
    isInvalid,
    filterDetailOptions,
    isMultiSelectDetail = false,
    filterWorkerOptions,
    addPhase = null,
    removePhase = null,
    disabled = false
}) => {
    return (
        <div style={{ marginBottom: '100px' }}>
            <Accordion
                id="phase-panel"
                className="margin-top-15"
                defaultActiveKey={null}
            >
                {phases?.map((phase, i) => {
                    return <div key={i}>
                        <PanelContainer
                            key={i}
                            id={i}
                            title={workPhasesState[i]['procedure_wp'] || i18n.t('NEW_WORKPHASE') + ' #' + (i + 1)}
                            subTitle={''}
                            customClass={''}
                            panelIcons={[]}
                            noIconsInUse={true}
                        >
                            <Container className="no-paddings">
                                <WorkPhaseForm
                                    phase={phase}
                                    workPhaseState={workPhasesState[i]}
                                    handleInputChange={(e) => handleInputChange(e, i)}
                                    handleDateChange={(e, a) => handleDateChange(e, a, i)}
                                    handleSelect={(e, a) => handleSelect(e, a, i)}
                                    getRequiredValue={getRequiredValue}
                                    isInvalid={isInvalid}
                                    filterDetailOptions={filterDetailOptions}
                                    isMultiSelectDetail={isMultiSelectDetail}
                                    filterWorkerOptions={filterWorkerOptions}
                                />
                                <div style={{ width: '50%', display: 'flex', margin: '15px auto' }}>
                                    <Button
                                        style={{ width: '100%' }}
                                        variant="danger"
                                        className="action button-shadow"
                                        onClick={() => removePhase(i)}
                                    >
                                        <span><FontAwesomeIcon icon={faTrash} size="lg" /> {i18n.t('REMOVE')}</span>
                                    </Button>
                                </div>
                            </Container>
                        </PanelContainer>
                        {i + 1 < phases.length && <hr className="v-margins-5" />}
                    </div>
                })}
            </Accordion>
            <hr className="v-margins-5" />
            <Loader ready={!disabled} />
            <Container style={{ width: '50%', display: 'flex', paddingTop: '1em' }} className="v-margins-15">
                <Button
                    style={{ width: '100%' }}
                    variant="primary"
                    className="action button-shadow"
                    onClick={addPhase}
                    disabled={disabled}
                >
                    <span><FontAwesomeIcon icon={faPlus} size="lg" /> {i18n.t('ADD')}</span>
                </Button>
            </Container>
    </div>
    )
}

export default WorkPhasesAdd;