import React from 'react';
import i18n from '../../../translations/i18n';
import TextTruncate from 'react-text-truncate';
import Avatar from 'react-avatar';
import { Image } from 'react-bootstrap';
import NoviIcon from '../../../images/novi_logo_rgb.png';
import { GetTranslationKeyByProp } from '../../../components/HelperFunctions';

/*
 * Component for displaying labels, main titles, scene type like Workcard or Workphase. 
 * Component displays possible object value, like workcard's or machine's id.
 */
const NavTitle = (props: IProps) => {

    const formText = (textArray: string[], divider) => {
        let title = '';

        textArray.forEach(function (strValue) {
            title = title + i18n.t(strValue);
            const itemIndex = textArray.indexOf(strValue) + 1;
            if (itemIndex < textArray.length) {
                title = title + divider;
            }
        });

        return title;
    }

    const showThumbnail = props.titleProps.thumbnail !== undefined;

    const thumbnail = (
        <Avatar 
            name="+"
            round
            size="48"
            textSizeRatio={1.33}
            color="#e30059"
            src={props.titleProps.thumbnail}
            onClick={props.titleProps.onThumbnailClick}
        />
    )
    
    return (
        <div className="header-element-container">
            {props.titleProps && <div className={showThumbnail ? 'with-thumbnail' : ''}>
                {showThumbnail  && <div className="right-margin-5">
                    {thumbnail}
                </div>}
                {props.titleProps.title
                    ? <div className="label-container">
                        {props.titleProps.label && <div>
                            {props.titleProps.subPhaseLabel
                                ? props.titleProps.label.includes('operatormaintenanceView')
                                    ? <div className="nav-label">
                                        <TextTruncate
                                            line={1}
                                            truncateText="..."
                                            text={formText([GetTranslationKeyByProp(props.titleProps.label), props.titleProps.subPhaseLabel], ' / ')}
                                        />
                                    </div>
                                    : <div className="nav-label">{i18n.t(GetTranslationKeyByProp(props.titleProps.label))} - {props.titleProps.subPhaseLabel}</div>
                                : <div className="nav-label">{i18n.t(GetTranslationKeyByProp(props.titleProps.label))}</div>
                            }
                            {props.titleProps.subPhase
                                ? <div className="nav-title">
                                    <TextTruncate
                                        line={1}
                                        truncateText="..."
                                        text={formText([props.titleProps.title, props.titleProps.subPhase], ' - ')}
                                    />
                                </div>
                                : <div className="nav-title">
                                    {typeof props.titleProps.title === 'string'
                                        ? <TextTruncate
                                            line={1}
                                            truncateText="..."
                                            text={props.titleProps.title}
                                        />
                                        : props.titleProps.title}
                                </div>
                            }
                        </div>}
                    </div>
                    : (props.titleProps.label === 'dashboard' || props.titleProps.label === 'login')
                        ? <div className="nav-image">
                            <Image src={NoviIcon} />
                        </div>
                        : <p className="nav-title">
                            {i18n.t(GetTranslationKeyByProp(props.titleProps.label))}
                        </p>
                }
            </div>}
        </div>
    )
}

interface IProps {
    titleProps: TitleProps;
}

type TitleProps = {
    title?: string;
    thumbnail?: string;
    label?: string;
    subPhase?: string;
    subPhaseLabel?: string;
    onThumbnailClick?: () => void;
}

export default NavTitle;
