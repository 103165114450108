import React from 'react';
import { useHistory } from "react-router-dom";
import TableComponent from '../../../components/TableComponent';
import TableRowComponent from '../../../components/TableRowComponent';
import FormList from '../../../components/FormList';
import i18n from '../../../translations/i18n';
import settingsAPI from '../../../config/settingsAPI';
import { toKeyFormat } from '../../../components/HelperFunctions';

function createRowData(columns, machine) {
    const row = {};
    const columnNames = columns.map(column => column.name);
    const NUMBER_TYPES = ['number', 'decimal'];

    function isNumberType(property) {
        return NUMBER_TYPES.includes(columns.find(column => column.name === property.toLowerCase()).type);
    }
    
    // handle properties of machine
    for (const property in machine) {
        if ('type' === property) {
            if (columnNames.includes('machinetype')) {
                Object.assign(
                    row,
                    { machinetype: machine[property] ? machine[property].name : '' }
                );
            }
        }
        // handle responsibility person
        else if ('responsibilityPerson' === property) {
            if (columnNames.includes(property.toLowerCase())) {
                Object.assign(
                    row,
                    { [property.toLowerCase()]: machine[property] ? machine[property].name : '' }
                );
            }
        }
        // handle parent
        else if ('parent' === property) {
            if (columnNames.includes(property.toLowerCase())) {
                let value = '';
                if (machine[property] && machine[property].machineCode) {
                    value = `${machine[property].machineCode} / ${machine[property].name}`;
                } else if (machine[property]) {
                    value = `${machine[property].name}`;
                }
                Object.assign(row, { [property.toLowerCase()]: value });
            }
        }
        // handle third parties
        else if ('thirdPartiesByTypes' === property) {
            machine.thirdPartiesByTypes.forEach(thirdPartiesByType => {
                if (columnNames.includes(thirdPartiesByType.type.id) || columnNames.includes(`${thirdPartiesByType.type.id}`)) {
                    Object.assign(row, { [thirdPartiesByType.type.id]: FormList({ list: thirdPartiesByType.thirdParties, listItemName: 'name' }) })
                }
            });
        }
        // handle extra location
        else if ('extraLocation' === property || 'extraLocationText' === property) {
            if (columnNames.includes('extralocation')) {
                Object.assign(row, {
                    extralocation: machine['extraLocation']
                        ? (machine['extraLocation'].name || '')
                        : (machine['extraLocationText'] || '')
                });
            }
        }
        // handle costpools
        else if ('costPools' === property) {
            // Check if columnNames includes costpool groups and return list of costpool group Id's.
            const costPoolGroupIds = columnNames.filter(i => i.includes('costpoolgroup_')).map(i => Number(i.split('_')[1]))
            
            if (costPoolGroupIds && costPoolGroupIds.length > 0) {
                costPoolGroupIds.forEach(groupId => {
                    machine.costPools?.find(i => {
                        if (i.groupId === groupId) {
                            Object.assign(row, {
                                ['costpoolgroup_'+groupId]: i.translationKey
                            })
                        }
                    });
                });
            }
        }
        // handle extra datas
        else if ("extraDatas" === property) {
            const extraDataColumns = columns.filter(i => i.type === 'extradata')
            
            if (extraDataColumns && extraDataColumns.length > 0) {
                extraDataColumns.forEach(extraData => {
                    machine.extraDatas?.find(i => {
                        if (i.group === extraData.name)
                        Object.assign(
                            row,
                            { [i.group]: i.value }
                        );
                    })
                }); 
            }
        }
        else if ("machineRequirements" === property) {
            Object.assign(row,
                { [property.toLowerCase()]: machine?.[property]?.map(req => req?.value)?.join('\n') ?? '' }
            );
        }
        else if (columnNames.includes(property.toLowerCase())) {
            Object.assign(
                row,
                { [property.toLowerCase()]: machine[property] || (isNumberType(property) ? 0 : '') }
            );
        }
    }

    // handle situation where column name wasn't found as a property in machine
    for (const column of columns) {
        if (!row.hasOwnProperty(column.name)) {
            Object.assign(
                row,
                { [column.name]: NUMBER_TYPES.includes(column.type) ? 0 : '' }
            );
        }
    }

    Object.assign(row, { id: machine.id });

    return row;
}

const MachineTable = ({ tableSettings, machines }) => {
    let history = useHistory();

    const headings = tableSettings
        .sort((a, b) => a.tabOrder - b.tabOrder)
        .map(setting => ({ label: toKeyFormat(setting.label), width: settingsAPI.wcGridColSizes[setting.field] ? settingsAPI.wcGridColSizes[setting.field] : '120px' }));

    const columns = tableSettings
        .map(setting => ({ name: setting.field, type: setting.type }));

    const rows = machines.map(machine => {
        return createRowData(columns, machine);
    })

    const handleClick = id => {
        history.push(`/machine/${id}`);
    }

    return (
        <TableComponent headings={headings}>
            {rows.map((row, i) => (
                <TableRowComponent key={i} handleClick={() => handleClick(row.id)}>
                    {columns.map(column => (
                        row[column.name]
                    ))}
                </TableRowComponent>
            ))}
        </TableComponent>
    );
}

export default MachineTable;
