import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIconType } from "components/ActionIcon";
import PopoverAction from "components/PopoverAction";
import React from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import i18n from "translations/i18n";

export type IPopoverAction = {
    icon: ActionIconType;
    label: string;
    clickFn: any;
    isActionFn: boolean;
    params?: any[];
    paClass?: string; 
    disabled?: boolean;
}
type IProps = {
     actions: IPopoverAction[];
     dialogOpen: boolean;
     togglePopover: (enabled: boolean) => void;
}

export const DashboardCustomizerPopover = (props: IProps) => {

    const { actions, dialogOpen, togglePopover } = props;

    return (
        <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose={true}
            overlay={<Popover id="settings-menu-popover" className="menu-popover">
                {actions.map((action, i) => {
                    const isLastItem = i == actions.length - 1;
                    return (
                        <div className="sub-section" key={`action-${i}`}>
                            <div className="setting-header" />
                            <div className="setting-option">
                                <div className='action'>
                                    <PopoverAction
                                        idValue={'edit_dashboard'}
                                        label={i18n.t(action.label)}
                                        onClick={() => {
                                            togglePopover(false);
                                            action.clickFn();
                                        }}
                                        icon={action.icon}
                                        disabled={action.disabled}
                                    />
                                </div>
                                {!isLastItem && <hr />}
                            </div>
                        </div>
                    );
                })}
            </Popover>}
        >
            <Button
                id="footerMenuButton"
                variant="primary"
                className="action action-button novi-default-btn-color"
                size="lg"
                onClick={() => togglePopover(!dialogOpen)}
            >
                <div><FontAwesomeIcon icon={faChevronCircleUp} size="lg" /></div>
            </Button>
        </OverlayTrigger>
    );
}