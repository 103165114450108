import * as types from '../config/actionTypes';

const initialState = {
    hierarchytree: {
        show: false,
        parentId: null,
        breadcrumb: []
    },
    filtering: {
        workHistory: {}
    },
    activeQuickSearches: {
        worklist: null,
        machines: null,
        warehouse: null
    }
}

// Named after navigation component. Could be changed to a more descriptive name based on the content.
const navigationReducer = (state: INavigationState = initialState, { type, payload }): INavigationState => {
    switch (type) {
        case types.OPEN_HIERARCHY_TREE:
            return {
                ...state,
                hierarchytree: {
                    ...state.hierarchytree,
                    show: true
                }
            }
        case types.CLOSE_HIERARCHY_TREE:
            return {
                ...state,
                hierarchytree: {
                    ...state.hierarchytree,
                    show: false
                }
            }
        case types.SET_HIERARCHY_TREE_PARENT_ID:
            return {
                ...state,
                hierarchytree: {
                    ...state.hierarchytree,
                    parentId: payload
                }
            }
        case types.SET_HIERARCHY_TREE_ROUTE:
            return {
                ...state,
                hierarchytree: {
                    ...state.hierarchytree,
                    breadcrumb: payload
                }
            }
        case types.SET_HIERARCHY_TREE_PARENT_ID_TO_PREVIOUS:
            return {
                ...state,
                hierarchytree: {
                    ...state.hierarchytree,
                    parentId: state.hierarchytree.breadcrumb.length > 0
                        ? state.hierarchytree.breadcrumb[0].id
                        : null
                }
            }
        case types.RESET_HIERARCHY_TREE:
            return {
                ...state,
                hierarchytree: initialState.hierarchytree
            }
        case types.SET_WORK_HISTORY:
            return {
                ...state,
                filtering: {
                    ...state.filtering,
                    workHistory: {
                        ...state.filtering.workHistory,
                        machine: payload
                    }
                }
            }
        case types.RESET_WORK_HISTORY:
        case types.RESET_WORKCARD_CURRENTFILTERS:
            return {
                ...state,
                filtering: {
                    ...state.filtering,
                    workHistory: initialState.filtering.workHistory
                }
            }
        case types.SET_ACTIVE_QUICK_SEARCH:
            return {
                ...state,
                activeQuickSearches: Object.assign({},
                    state.activeQuickSearches,
                    payload
                )
            }
        case types.RESET_QUICK_SEARCH:
            return {
                ...state,
                activeQuickSearches: {
                    ...state.activeQuickSearches,
                    [payload]: null
                }
            }
        case types.UPDATE_CURRENT_MACHINE_GROUP_ID:
        case types.CLEAR_CACHE:
            return initialState;
        default:
            return state;
    }
}

export default navigationReducer;