import { faSpinner, faTasks, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Accordion, CardGroup, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getDateTime } from 'utils';
import DocumentPanelRow from '../../../components/panels/DocumentPanelRow';
import PanelRow from '../../../components/panels/PanelRow';
import PanelText from '../../../components/panels/PanelText';
import i18n from '../../../translations/i18n';
import PanelContainer from '../../work-schedule/work-card/components/PanelContainer';
import { openDocument } from '../utils';

const OperatorMaintenanceDetails = (props: IProps) => {
    let history = useHistory();

    const handleDocumentOpen = (document) => {
        const doc = {
            id: document.id,
            isUrl: (document.files && document.files[0]?.isUrl) || document.isUrl,
            documentLink: document.documentLink,
            caption: document.caption
        }
        openDocument(doc);
    }

    const documentRows = props.operatorMaintenanceDocuments !== null ? props.operatorMaintenanceDocuments.documents.map(document => (
        <DocumentPanelRow
            key={document.id}
            documentName={document.caption}
            documentDescription={document.description}
            documentDate={getDateTime(document.createDate)}
            openDocument={() => handleDocumentOpen(document)}
        />
    )) : [];

    const machineDocumentRows = props.operatorMaintenanceDocuments !== null ? props.operatorMaintenanceDocuments.machineDocuments.documents.map(document => (
        <DocumentPanelRow
            key={document.id}
            documentName={document.caption}
            documentDescription={document.description}
            documentDate={getDateTime(document.createDate)}
            openDocument={() => handleDocumentOpen(document)}
        />
    )) : [];

    const mapWorkCardToRow = (workCard, i) => {
        
        return <PanelRow key={workCard.id} openItem={() => history.push(`/workcard/${workCard.id}`, { noPreviousPage: false })}>
            <PanelText>
                {workCard.isRouteMaintenanceWorkCard
                    ? <span><FontAwesomeIcon icon={faTasks} size="sm" /> {workCard.id} </span>
                    : workCard.isTimedOperatorMaintenanceWorkCard
                        ? <span><FontAwesomeIcon icon={faTools} size="sm" /> {workCard.id} </span>
                        : '#' + workCard.code}
            </PanelText>
            <PanelText small>
                {i18n.t('WORK_CAN_BEGIN')}: {getDateTime(workCard.workCanBegin)}
            </PanelText>
            <PanelText small>
                {i18n.t('FAULT_DESCRIPTION')}: {workCard.faultDescription}
            </PanelText>
            <PanelText small>
                {i18n.t('WORK_STATUS')}: {i18n.t(workCard.workStatus?.label)}
            </PanelText>
        </PanelRow>
    };

    const previousWorkCardRows = props.previousWorkCards.map(mapWorkCardToRow);
    const openWorkCardRows = props.openWorkCards.map(mapWorkCardToRow);

    const openWorkCardCount = props.loadingPanels.openWorkCards ? <FontAwesomeIcon icon={faSpinner} pulse /> : props.openWorkCardsTotalCount;
    const previousWorkCardCount = props.loadingPanels.previousWorkCards ? <FontAwesomeIcon icon={faSpinner} pulse /> : previousWorkCardRows.length;
    const operatorMaintenanceDocumentCount = props.loadingPanels.operatorMaintenanceDocuments ? <FontAwesomeIcon icon={faSpinner} pulse /> : documentRows.length;
    const machineDocumentCount = props.loadingPanels.operatorMaintenanceDocuments ? <FontAwesomeIcon icon={faSpinner} pulse /> : machineDocumentRows.length;


    //TODO TODO TODO
    //return (
    //    <div>
    //        {panelGroups.linkPanels
    //            .filter(panelGroup => !panelGroup.isWorkcardsPanel
    //                || (panelGroup.isWorkcardsPanel && HasRight('workscheduleview', settings.userRights)))
    //            .map((panelGroup, i) => (
    //                <CardGroup
    //                    id="machine-additional-info-panel"
    //                    className="margin-top-15 novi-panel"
    //                    key={panelGroup.title}
    //                >
    //                    <h2>{i18n.t(panelGroup.title).toUpperCase()}</h2>
    //                    {opmMachine && panelGroup.panels.map(panel => (
    //                        <LinkPanelContainer
    //                            id={i}
    //                            title={panel.label}
    //                            key={panel.label}
    //                            linkTo={'/machine/' + opmMachine.id + '/' + panel.linkValue}
    //                            property={panel.linkValue}
    //                            hasView={true}
    //                            itemCount={this.getItemsCount(panel.linkValue)}
    //                            linkState={{ viewProperty: panel.linkValue }}
    //                        />
    //                    ))}
    //                </CardGroup>
    //            ))}
    //        {panelGroups.accordPanels.map((panelGroup, i) => (
    //            <Accordion
    //                key={i}
    //                id="panel-with-sub-panels"
    //                className="margin-top-15 novi-panel"
    //                defaultActiveKey={accordionActiveKey}
    //                onSelect={this.handleAccordion}
    //            >
    //                <h2>{i18n.t(panelGroup.title).toUpperCase()}</h2>
    //                {panelGroup.panels.map((panel, j) => (
    //                    <PanelContainer
    //                        id={j}
    //                        title={i18n.t(panel.label)}
    //                        key={j}
    //                        panelIcons={[]}
    //                        subTitle={''}
    //                        itemCount={documentSets[panel.type].totalCount}
    //                    >
    //                        {documentSets[panel.type].allDocs.map((doc, k) => (
    //                            <div key={k}>
    //                                <div>
    //                                    <Row key={i} className="item-container-with-color-border">
    //                                        <Col xs={doc.createDate ? 7 : 10}>
    //                                            <p className="no-margins word-break">{doc.name}</p>
    //                                            {doc.details && doc.details.map((detail, i) => (
    //                                                <p key={i} className="small no-margins">{detail}</p>
    //                                            ))}
    //                                        </Col>
    //                                        {doc.createDate && <Col xs={3}>
    //                                            <p className="small right">{getDate(doc.createDate)}</p>
    //                                        </Col>}
    //                                        <Col xs={2}>
    //                                            <div className="options-container">
    //                                                <span className="bar" />
    //                                                <OverlayTrigger
    //                                                    trigger="click"
    //                                                    rootClose
    //                                                    placement="left"
    //                                                    overlay={
    //                                                        <PopoverContainer popoverclass="panel-menu-popover">
    //                                                            <div>
    //                                                                { // Documents and m-documents are not allowed to be deleted from this view atleat for now.
    //                                                                    // TODO: After changes to overall Novi document handling we might need to change this rendering logic here. 
    //                                                                    (panel.type !== 'documents' && panel.type !== 'm-documents') &&
    //                                                                    <PopoverAction
    //                                                                        icon="delete"
    //                                                                        paClassName={''}
    //                                                                        label={i18n.t('REMOVE')}
    //                                                                        onClick={() => this.setDialogProperties(doc, 'delete')}
    //                                                                    />
    //                                                                }

    //                                                                {doc.editable && <hr />}
    //                                                                {doc.editable
    //                                                                    ? <PopoverAction
    //                                                                        icon="edit"
    //                                                                        paClassName={''}
    //                                                                        label={i18n.t('EDIT')}

    //                                                                    />
    //                                                                    : <PopoverAction
    //                                                                        icon="view"
    //                                                                        label={i18n.t('VIEW_DOCUMENT')}
    //                                                                        onClick={() => this.openDocument(doc)}
    //                                                                    />}
    //                                                            </div>
    //                                                        </PopoverContainer>
    //                                                    }
    //                                                >
    //                                                    <FontAwesomeIcon icon={faEllipsisV} size="lg" />
    //                                                </OverlayTrigger>
    //                                            </div>
    //                                        </Col>
    //                                    </Row>
    //                                </div>
    //                            </div>
    //                        ))}
    //                        {panel.type === 'wc-documents' && <div className="panel-pagination-container">
    //                            <PaginationContainer
    //                                currentPage={currentWcDocsPage}
    //                                setCurrentPage={(pageNumber) => this.setPageNumber(pageNumber, panel.type)}
    //                                pageCount={documentSets[panel.type].pageCount}
    //                            />
    //                        </div>}
    //                    </PanelContainer>
    //                ))}
    //            </Accordion>
    //        ))}
    //    </div>    
    //);


    return (
        <Container>
            <div>
                <CardGroup
                    id="machine-additional-info-panel"
                    className="margin-top-15 novi-panel"
                >
                    <h2>{i18n.t('WORKCARDS').toUpperCase()}</h2>
                    <Accordion
                        id="panel-with-sub-panels"
                        className="novi-panel"
                    >
                        <PanelContainer
                            id={2}
                            title={i18n.t('MACHINE_OPEN_WORKCARDS')}
                            itemCount={openWorkCardCount}
                            panelIcons={[]}
                            subTitle={''}
                        >
                            <div>
                                {openWorkCardRows}
                            </div>
                        </PanelContainer>
                    </Accordion>
                </CardGroup>
                <Accordion
                    id="panel-with-sub-panels"
                    className="novi-panel"
                >
                    <PanelContainer
                        id={2}
                        title={i18n.t('PREVIOUS_OPERATOR_MAINTENANCE_WORKCARDS')}
                        itemCount={previousWorkCardCount}
                        panelIcons={[]}
                        subTitle={''}
                    >
                        <div>
                            {previousWorkCardRows}
                        </div>
                    </PanelContainer>
                </Accordion>
            </div>
            <Accordion
                id="panel-with-sub-panels"
                className="margin-top-15 novi-panel"
            >
                <h2>{i18n.t('DOCUMENTS').toUpperCase()}</h2>
                <PanelContainer
                    id={1}
                    title={i18n.t('DOCUMENTS')}
                    itemCount={operatorMaintenanceDocumentCount}
                    panelIcons={[]}
                    subTitle={''}
                >
                    <div>
                        {documentRows}
                    </div>
                </PanelContainer>
                <PanelContainer
                    id={2}
                    title={i18n.t('MACHINE_DOCUMENTS')}
                    itemCount={machineDocumentCount}
                    panelIcons={[]}
                    subTitle={''}
                >
                    <div>
                        {machineDocumentRows}
                    </div>
                </PanelContainer>
            </Accordion>
        </Container>
    )
}

interface IProps {
    machineId: number;
    operatorMaintenenaceId: number;
    openWorkCardsTotalCount: number;
    openWorkCards: IWorkCard[];
    previousWorkCards: IWorkCard[];
    operatorMaintenanceDocuments: IOperatorMaintenanceDocuments;
    loadingPanels: LoadingPanels;
}

type LoadingPanels = {
    previousWorkCards: boolean;
    openWorkCards: boolean;
    operatorMaintenanceDocuments: boolean;
}

export default OperatorMaintenanceDetails;