import React from 'react';
import NavigationBar from '../navigation';
import i18n from '../../translations/i18n';
import { Accordion, Tabs, Tab } from 'react-bootstrap';
import PanelContainer from '../work-schedule/work-card/components/PanelContainer';
import { Button } from 'react-bootstrap';
import ImageViewerComponent from '../../components/ImageViewerComponent';
import FileInput from '../../components/FileInput';
import { toast } from 'react-toastify';
import {
    ValidateForm,
    HasRight,
    PropertyNamesToCamelCase,
    HandleSelectOption,
    InitDialogBodyContent,
    containsAny,
    isNullOrWhitespace
} from '../../components/HelperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faClipboardList, faSpinner } from '@fortawesome/free-solid-svg-icons';
import ConfirmDialogComponent from '../../components/ConfirmDialogComponent';
import settingsAPI from '../../config/settingsAPI';
import noviAPI from '../../api/noviAPI';
import OperatorProcedureForm from './components/OperatorProcedureForm';
import { OperatorMaintenancePropsFromRedux } from '.';
import OperatorMaintenanceDetails from './components/OperatorMaintenanceDetails';
import { Loader } from '../../components/Loader';
import { actionIcons } from '../../constants/icons';
import DialogWithTextarea from '../../components/dialogs/DialogWithTextarea';
import { ProcedureDataTypes } from './ProcedureDataTypes';
import OperatorMaintenanceGeneralSettings from './components/OperatorMaintenanceGeneralSettings';
import { RouteChildrenProps } from 'react-router-dom';
import { handleDateTimeInput } from 'utils';
import { UserRights } from 'constants/userRights';

type MatchProps = { opMaintenanceId; }
type LocationState = { opmState; viewProperty; }
type PropsFromRouterAndRedux = OperatorMaintenancePropsFromRedux & RouteChildrenProps<MatchProps, LocationState>;

export interface IProps extends PropsFromRouterAndRedux {
    docsData: any[];
    opmName: any;
    documentMetadatas: IDocumentMetaDataGroup[];
}

interface IState {
    [name: string]: any;
    showFileInput: boolean;
    showCancellationDialog: boolean;
    cancellationReason: string;
    documents: any[];
    rowId: any;
    shouldFetchImages: boolean;
    opmDocs: IOperatorMaintenanceDocuments;
    openWorkCardTotalCount: number;
    previousWorkCards: IWorkCard[];
    loadingDetails: {
        previousWorkCards: boolean;
        openWorkCards: boolean;
        operatorMaintenanceDocuments: boolean;
        machineWorkCardDocuments: boolean;
    },
    invalidFields: boolean,
    missingMetaDatas: number[],
    docRequirements: IGlobalSetting[],
    hasChanged: boolean
}

const moduleSettings = settingsAPI.moduleData.operatormaintenance;

class OperatorMaintenance extends React.Component<IProps, IState> {
    docRef = React.createRef<FileInput>();

    constructor(props) {
        super(props);

        let stateProps = {
            addWorkCard: false,
            fullImage: false,
            showFileInput: false,
            showCancellationDialog: false,
            cancellationReason: '',
            documents: [],
            rowId: null,
            shouldFetchImages: true,
            opmDocs: null,
            previousWorkCards: [],
            openWorkCardTotalCount: 0,
            loadingDetails: {
                previousWorkCards: false,
                openWorkCards: false,
                operatorMaintenanceDocuments: false,
                machineWorkCardDocuments: false
            },
            invalidFields: false,
            missingMetaDatas: [],
            docRequirements: [],
            hasChanged: false
        };

        if (props.location.state && this.props.location.state.opmState) {
            const prevState = props.location.state;

            let preservedState = { ...stateProps, ...prevState.opmState };
            preservedState.rowId = prevState.rowId;
            this.state = preservedState;

            if (prevState.notificationMsg) {
                toast.success(i18n.t(prevState.notificationMsg), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
            }

            this.props.history.replace({ 
                state: { 
                    opMaintenanceName: (prevState.opmName ?? prevState.opMaintenanceName), 
                    opmMachine: (prevState.machine ?? prevState.opmMachine),
                    opmState: preservedState
                }
            });
        } else {
            if (props.location.state?.noPreviousPage) {
                stateProps['noPreviousPage'] = true;
            }
            
            const storedTabKey = localStorage.getItem('activeOpmTab');
            stateProps['tabGroupActiveKey'] = parseInt(storedTabKey, 10) || 1;
            this.state = stateProps;
        }
    }

    componentDidMount() {
        const { match, opmMachineId, orderer, settings } = this.props;
        this.props.fetchMaintenanceMachines();
        if (opmMachineId) {
            this.fetchOpenWorkCards(opmMachineId);

            if (HasRight(UserRights.CountersView, settings.userRights))
                this.props.fetchCounters(opmMachineId);
        }
        this.props.fetchOperatorMaintenance(Number(match.params.opMaintenanceId));
        this.fetchOperatorMaintenanceDocuments(Number(match.params.opMaintenanceId));
        this.fetchPreviousWorkCards(Number(match.params.opMaintenanceId));
        this.props.fetchProcedureDataTypes();
        this.props.fetchWorkCardDetails();

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });

        this.props.fetchWorkcardOptions();

        this.setState(({ orderer_general: orderer ? orderer : null }))

        noviAPI.documents.getDocumentRequirements()
            .then(({ data }) => {
                this.setState({ docRequirements: data })
            })
        this.props.fetchDocumentMetadatas();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { opmMachineId, settings } = this.props;

        if (prevState.opmDocs !== this.state.opmDocs) {
            this.state.opmDocs.procedureDocuments.forEach(docItem => {
                this.props.fetchPointImage(docItem);
            });
        }

        if (prevProps.opmMachineId !== opmMachineId) {
            this.fetchOpenWorkCards(opmMachineId);
            if (HasRight(UserRights.CountersView, settings.userRights))
                this.props.fetchCounters(opmMachineId);
        }

        if (prevProps !== this.props) {

            const { opmForm, proceduresFormData, location } = this.props;

            const { opmState } = location.state;

            if (!opmState) {

                const formValues = {};

                Object.keys(opmForm).forEach(key => {
                    const stateKey = key + '_general';
                    const value = (key + '_general' === 'id') ? opmForm[key] : opmForm[key]?.value;

                    Object.assign(formValues, { [stateKey]: value });
                });

                this.setState({ ...formValues });
            }

            proceduresFormData?.forEach(procedure => {
                Object.keys(procedure)
                    .filter(key => !key.includes('img'))
                    .forEach(key => {
                        const stateKey = key === 'id' ? key : key + procedure.id;
                        if (!this.state[stateKey]) {
                            this.setState({ [stateKey]: procedure[key]?.value || procedure[key] });
                        }
                    });
            });
        }
    }

    fetchOpenWorkCards = async (mId: number) => {
        this.setLoadingDetails({ openWorkCards: true });

        try {
            const { settings } = this.props;
            const { data: machine } = await noviAPI.machines.fetch(mId);
            let searchParams = new URLSearchParams();
            
            searchParams.append('MachineGroupId', `${machine.machineGroupId}`);
            searchParams.append('UserId', `${settings.userId}`);
            searchParams.append('MachineIds', `${machine.id}`);

            const { data: { reportedWorkStatuses } } = await noviAPI.workCardSettings.fetchAll();
            reportedWorkStatuses.forEach(workStatus => {
                searchParams.append('WorkStatuses', `${workStatus}`);
            });

            const { data } = await noviAPI.workCards.fetchMachineOpenWorkCards(searchParams);

            this.setState({ 
                openWorkCardTotalCount: data.totalResultCount,
                openWorkCards: data.results?.sort((a, b) => parseInt(b.code, 10) - parseInt(a.code, 10))
            });
        } catch (error) { }

        this.setLoadingDetails({ openWorkCards: false });
    }

    fetchPreviousWorkCards = async (operatorMaintenanceId: number) => {
        this.setLoadingDetails({ previousWorkCards: true });

        try {
            const pageSize = 10;
            const searchParams = new URLSearchParams();
            searchParams.append('Id', `${operatorMaintenanceId}`);
            searchParams.append('PageSize', `${pageSize}`);

            const { data } = await noviAPI.workCards.fetchOperatorMaintenanceWorkCards(searchParams);

            this.setState({ previousWorkCards: data.results });
        } catch (error) { }

        this.setLoadingDetails({ previousWorkCards: false });
    }

    fetchOperatorMaintenanceDocuments = async (operatorMaintenanceId: number) => {
        this.setLoadingDetails({ operatorMaintenanceDocuments: true });
        try {
            const { data } = await noviAPI.operatorMaintenances.fetchDocuments(operatorMaintenanceId);
            this.setState({ opmDocs: data });
        } catch (error) { }
        this.setLoadingDetails({ operatorMaintenanceDocuments: false });
    }

    setLoadingDetails = (object) => {
        this.setState(prevState => ({
            loadingDetails: {
                ...prevState.loadingDetails,
                ...object
            }
        }))
    }

    onChange = (val, name) => {
        if (val.target) {
            const value = val.target.value;
            const propName = val.target.name.toLowerCase();
            this.setState({
                [propName]: value,
                hasChanged: true
            });
        } else if (name?.action) {
            const key = name.name;
            const selectedValue = HandleSelectOption(key, val);

            if (key === 'workergroups' && selectedValue && selectedValue.length > 0 && this.state.workers) {
                this.setState({
                    [key]: selectedValue,
                    'workers': null
                });
            } else {
                this.setState({
                    [key]: selectedValue
                });
            }
        } else {
            this.setState({ 
                [name]: !val || val === '' ? 'true' : 'false',
                hasChanged: true 
            });
        }
    }

    removeDocument = (index: number) => {
        let documents = this.state.documents.slice(0);
        documents.splice(index, 1);
        this.setState({ documents });
    }

    handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        this.setState({
            [dateInput.name]: dateInput.value,
            hasChanged: true
        });
    }

    handleSubmit = (status = null, statusReason = null, validateForm = true) => {
        let opmData = {
            phases: []
        };

        const opmState = this.state;
        const { opmForm, opMaintenance, procedures, opmMachine, settings, orderer, location } = this.props;
        const opmGeneralForm = {}
        
        let query = new URLSearchParams(location?.search)
        const workCardId = query.get('workCardId') ?? null;
        
        let wcDetails = [];

        Object.keys(opmForm).map(key =>
            opmGeneralForm[key + '_general'] = opmForm[key]
        );

        if (validateForm) {
            const isValid = ValidateForm(opmGeneralForm, opmState);
            if (!isValid) {
                toast.error(i18n.t('INVALID_FIELDS'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });

                this.setState({ invalidFields: true })
                return;
            } else {
                this.setState({ invalidFields: false })
            }
        }

        let extraDatas = [];

        Object.keys(opmState).forEach(key => {
            if (key !== 'invalidFields'
                && key !== 'id'
                && key !== 'edit'
                && key !== 'addWorkCard'
                && key !== 'showFileInput'
                && key !== 'dataType'
                && key !== 'rowId'
                && key !== 'fullImage'
                && key !== 'tabGroupActiveKey'
                && key.includes('general')
            ) {
                Object.keys(opmForm).forEach(opmKey => {
                    if (opmForm[opmKey].type === 'extradata') {
                        let extraData = {
                            value: opmState[key] || '',
                            group: key
                        }
                        extraDatas.push(extraData);
                    }

                    const generalKey = opmKey + '_general';

                    if (generalKey === key) {
                        if (opmForm[opmKey].type === 'detail'
                            && opmState[generalKey]
                            && opmState[generalKey].id >= 0
                        ) {
                            wcDetails.push({
                                id: opmState[generalKey].id,
                                value: opmState[generalKey].label,
                                group: opmKey,
                                parentId: opmState[generalKey].parentId
                            });
                        } else {
                            const property = opmKey === 'signer'
                                ? 'workerName'
                                : opmKey === 'hoursplanned'
                                    ? 'hoursPlanned'
                                    : opmKey;

                            opmData[property] = opmState[key];
                        }
                    }
                });
            }
        });

        // Set work card fault description value from the field. If field is not in use, set the description value from the plan.
        // If description value is not defined then use op.maintenance name as fault description.
        opmData['faultdescription'] = opmState['description_general'] || opMaintenance.description || opMaintenance.name;

        const finishedStatus = settings.noviConfigs?.WorkStatusFinished
            ? parseInt(settings.noviConfigs?.WorkStatusFinished, 10)
            : null;

        const reportedStatus = settings.noviConfigs?.WorkStatusNoticed
            ? parseInt(settings.noviConfigs.WorkStatusNoticed, 10)
            : null;

        if (status === null) {
            opmData['workStatus'] = finishedStatus;
        } else {
            opmData['workStatus'] = status;

            if (!isNullOrWhitespace(statusReason)) {
                opmData['procedure'] = statusReason;
            }
        }
        opmData['operatorMaintenanceid'] = parseInt(this.props.match.params.opMaintenanceId, 10);
        opmData['machine'] = opmMachine;
        opmData['workCardType'] = 4;
        opmData['details'] = wcDetails;
        opmData['orderer'] = this.state['orderer_general'] ? this.state['orderer_general'] : orderer;
        opmData['orderertext'] = this.state['orderertext_general'] ? this.state['orderertext_general'] : orderer?.label || '';

        procedures.forEach(procedure => {
            const procId = procedure.id;
            let procedurePhase = {
                workStatus: (opmState['isDone' + procId] === 1 || opmState['isDone' + procId] === 'true' || opmState['isDone' + procId]?.id > -1) && procedure.dataType !== 3
                    ? finishedStatus
                    : reportedStatus,
                workers: opmState['workers_general'],
                comment: opmState['isDone' + procId]?.id && procedure.dataType === 5
                    ? opmState['isDone' + procId]?.id === 1
                        ? i18n.t('YES')
                        : i18n.t('NO')
                    : '',
                procedure: opmState['comment' + procId],
                workHours: typeof opmState['hoursplanned' + procId] === 'object' && opmState['hoursplanned' + procId] !== null
                    ? opmState['hoursplanned' + procId].value.toString()
                    : opmState['hoursplanned' + procId],
                instructions: procedure.caption
            };
            opmData.phases.push(procedurePhase);
        });

        opmData = PropertyNamesToCamelCase(opmData);
        
        if (workCardId !== null) {
            opmData['isTimedOpm'] = true;
        }

        this.props.addWorkcard(opmData, extraDatas, [], this.state.documents, [], this.props.history.replace);
    }

    cancelTimedOperatorMaintenance = () => {
        const { RouteMaintenanceWorkStatusCanceled } = this.props.settings.noviConfigs;
        const { cancellationReason } = this.state;

        this.handleSubmit(Number(RouteMaintenanceWorkStatusCanceled), cancellationReason, false);
    }

    addFaultNotice = (rowId = null) => {
        const { opmMachine, match, opMaintenance } = this.props;
        const { popoverRef, ...rest } = this.state;

        this.props.history.push('/workcard/new/faultnotice', {
            'machine': opmMachine,
            'opmId': match.params.opMaintenanceId,
            'opmState': rest,
            'opmName': opMaintenance.name,
            'rowId': rowId,
            'cardType': 'newFaultNotice'
        });
    }

    displayFullImage = image => {
        this.setState({ fullImage: image });
    }

    getIconSets = procedures => {
        let icons = {};

        procedures.forEach(proc => {
            const param = 'isDone' + proc.id;

            // Handles which icon to render for operator maintenance procedure based on isDone value of procedure.
            switch (proc.datatype) {
                case ProcedureDataTypes.Checkbox:
                case ProcedureDataTypes.CBAndDescription:
                    return (
                        icons[proc.id] = [{
                            'icon': this.state[param] === 'true' ? 'done' : 'skipped'
                        }]);
                case ProcedureDataTypes.YesNo:
                case ProcedureDataTypes.YesNoAndDescription:
                    return (
                        icons[proc.id] = [{
                            'icon': !this.state[param] || this.state[param]?.id === -1
                                ? 'skipped'
                                : 'done'
                        }]);
                default:
                    return (
                        icons[proc.id] = [{
                            'icon': 'notdone'
                        }]);
            }
        });

        return icons;
    }

    setDialogProperties = (item, type) => {
        this.setState({
            showDialog: true,
            dialogType: type + '_' + item.type,
            targetItem: item
        });
    }

    openDocumentForm = () => {
        this.setState({ showFileInput: true });
    }

    closeDocumentForm = () => {
        this.setState({
            showFileInput: false,
            invalidFields: false,
            missingMetaDatas: []
        });
    }

    deleteItem = item => {
        if (item.type === 'doc') {
            this.props.deleteOpmDocument(item.id);
            this.resetDialogProps();
        }
    }

    getDialogContent = contentType => {
        if (!contentType) return {
            title: '',
            body: '',
            type: '',
            params: null
        }

        const dialogContents = {
            'delete_doc': {
                title: i18n.t('DELETE_DOCUMENT'),
                body: InitDialogBodyContent('DOCUMENT', this.state.targetItem && (this.state.targetItem.name || this.state.targetItem.id)),
                type: 'delete',
                params: this.state.targetItem
            },
        }

        return dialogContents[contentType];
    }

    getCallbackFn = () => {
        const { targetItem } = this.state
        if (targetItem && targetItem.type) {
            return this.deleteItem;
        }
    }

    resetDialogProps = () => {
        this.setState({
            showDialog: false,
            dialogType: null,
            targetItem: null
        });
    }

    handleTabGroup = (value) => {
        this.setState({ tabGroupActiveKey: parseInt(value, 10) });
        localStorage.setItem('activeOpmTab', value)
        localStorage.setItem('opmId', this.props.match.params.opMaintenanceId)
    }

    addDocument = () => {
        const { docRequirements } = this.state;
        const { linkToDocument, description, type, file, metaData } = this.docRef.current.state;

        if (this.docRef.current.state) {
            const noFileNoLink = file === null && linkToDocument === '';
            const fileAndLink = file !== null && linkToDocument !== '';

            if (noFileNoLink || fileAndLink) {
                toast.info(i18n.t('SELECT_FILE_OR_LINK'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                return;
            }
            
            const missingMetaDatas = this.props.documentMetadatas.filter(field => {
                const val = metaData[field.label];
                return (field.required && (isNullOrWhitespace(val) || val?.id == -1));
            });

            if (missingMetaDatas.length || (docRequirements?.find(req => req.field === 'description') && !description)) {
                toast.error(i18n.t('INVALID_FIELDS'), {
                    position: toast.POSITION.TOP_CENTER,
                    hideProgressBar: true
                });
                this.setState({ 
                    invalidFields: true,
                    missingMetaDatas: missingMetaDatas.map(x => x.id)
                });
                return;
            }
        }

        const documentMetaDataIds = Object.values(metaData)
            .map(data => data?.id ?? 0)
            .filter(id => id > 0);

        this.setState(state => ({
            documents: [
                ...state.documents,
                {
                    linkToDocument,
                    description,
                    type,
                    file,
                    documentMetaDataIds
                }
            ]
        }));

        this.closeDocumentForm();
    }

    getRequiredValue = (settingKey) => {
        const { formFieldSets } = this.props;

        const settingsType = 'operatormaintenance';

        if (settingKey) {
            let viewSetting = null;

            viewSetting = formFieldSets[settingsType] && formFieldSets[settingsType].find(type => type.field === settingKey);

            if (viewSetting) {
                return viewSetting.required;
            }
        }
        return false;
    }

    filterWorkerOptions = workerOpts => {
        if (this.state.workergroups && this.state.workergroups.length > 0) {
            const workerGroupIds = this.state.workergroups.map(workerGroup => workerGroup.id);
            return workerOpts.filter(worker => containsAny(worker.personGroupIds, workerGroupIds));
        }

        return workerOpts;
    }

    detailsNumberBadgeTotal = () => {
        let total = 0;
        total += this.state.openWorkCardTotalCount;
        total += this.state.previousWorkCards.length;
        total += this.state.opmDocs?.documents.length || 0;
        total += this.state.opmDocs?.machineDocuments.documents.length || 0;
        return total;
    }

    getGeneralSettingsDocumentList = (): string[] => this.state.documents.map(doc =>
        doc.file?.name || doc.description || doc.linkToDocument
    )

    render() {
        const {
            proceduresFormData,
            opmForm,
            opmMachine,
            settings,
            location,
            opMaintenance,
            loadStatus,
            procedureFileData,
            phaseWorkHoursEnabled,
            counters
        } = this.props;

        const {
            showFileInput,
            showCancellationDialog,
            tabGroupActiveKey,
            invalidFields,
            fullImage,
            showDialog,
            currentWcDocsPage,
            machineWcDocs,
            noPreviousPage,
            openWorkCards
        } = this.state;
        const iconSets = this.getIconSets(proceduresFormData);

        const relationViews = ['openworkcards'];

        const viewAction = showFileInput
            ? {
                icon: 'save',
                label: '',
                clickFn: this.addDocument,
                isActionFn: true,
                paClass: 'start-phase'
            } : relationViews.find(view => view === location.state?.viewProperty)
                ? ''
                : 'settings';

        const sceneData = {
            view: moduleSettings.name,
            title: showFileInput
                ? opMaintenance?.name + ' - ' + i18n.t('ADD_DOCUMENT')
                : opMaintenance?.name,
            subPhaseLabel: opmMachine
                ? opmMachine.code + ' / ' + opmMachine.name
                : '',
            location: location,
            history: this.props.history,
            hasChanged: this.state.hasChanged,
            backAction: !showFileInput
                ? noPreviousPage
                    ? { action: this.props.history.replace, params: {path: '/dashboard', mainEditView: true} }
                    : { action: this.props.history.goBack, params: {mainEditView: true} }
                : { action: this.closeDocumentForm }
        };

        const navPopover = (<></>);

        let popoverData = {
            popoverClass: 'menu-popover',
            popoverActions: []
        };

        if (!this.state.showFileInput) {
            if (location.search !== '') {
                let query = new URLSearchParams(location.search)
                const workCardId = query.get('workCardId');
                if (workCardId !== null) {
                    popoverData.popoverActions.push(
                        {
                            icon: actionIcons.CANCEL,
                            label: i18n.t('TIMED_OPERATOR_MAINTENANCE_CANCEL_WC'),
                            clickFn: () => this.setState({ showCancellationDialog: true }),
                            isActionFn: true,
                            params: [false],
                            paClass: 'icon-warning'
                        }
                    )
                }
            }

            popoverData.popoverActions.push(
                {
                    icon: actionIcons.SAVE,
                    label: i18n.t('SAVE_AND_CLOSE'),
                    clickFn: this.handleSubmit,
                    isActionFn: true,
                    paClass: 'start-phase'
                }
            )
        }

        const detailsTabTitle = (
            <span>{i18n.t('DETAILS').toUpperCase()}
                <span className="number-badge">
                    {Object.values(this.state.loadingDetails).some(i => i)
                        ? <FontAwesomeIcon icon={faSpinner} pulse />
                        : this.detailsNumberBadgeTotal()
                    }
                </span>
            </span>
        );

        return (
            <div>
                {showDialog && <ConfirmDialogComponent
                    dialogContent={this.getDialogContent(this.state.dialogType)}
                    callBack={this.getCallbackFn()}
                    cancelDialog={this.resetDialogProps}
                />}
                <NavigationBar
                    currentView={sceneData}
                    navHistory={this.props}
                    viewAction={viewAction}
                    popover={navPopover}
                    popoverData={popoverData}
                />
                {!opmMachine && <Loader status="pending" />}
                {showFileInput && <FileInput 
                    ref={this.docRef} 
                    docMetadata={this.props.documentMetadatas} 
                    invalidFields={this.state.invalidFields} 
                    missingMetaDatas={this.state.missingMetaDatas} 
                    docRequirements={this.state.docRequirements} 
                />}
                <DialogWithTextarea
                    headerText={i18n.t('TIMED_OPERATOR_MAINTENANCE_CANCEL_REASON')}
                    textareaValue={this.state.cancellationReason}
                    onChange={e => this.setState({ cancellationReason: e.target.value })}
                    showDialog={showCancellationDialog}
                    setShowDialog={(value) => this.setState({ showCancellationDialog: value })}
                    onButtonClick={this.cancelTimedOperatorMaintenance}
                    textareaValueRequired
                />
                {!showFileInput && opmMachine && <div id="work-card-view">
                    <Tabs
                        id="workCardViewTabs"
                        activeKey={parseInt(tabGroupActiveKey, 10) || 1}
                        onSelect={this.handleTabGroup}
                        className="novi-nav-tabs"
                    >
                        <Tab eventKey={1} title={i18n.t('OPERATOR_MAINTENANCE_GENERAL_SETTINGS').toUpperCase()}>
                            <OperatorMaintenanceGeneralSettings
                                opmForm={opmForm}
                                getRequiredValue={this.getRequiredValue}
                                filterWorkerOptions={this.filterWorkerOptions}
                                handleChange={this.onChange}
                                handleDatetime={this.handleDateTime}
                                values={this.state}
                                invalidFields={invalidFields}
                                documentList={this.getGeneralSettingsDocumentList()}
                                removeDocument={this.removeDocument}
                                openDocumentView={this.openDocumentForm}
                            />
                        </Tab>
                        <Tab
                            eventKey={2}
                            title={
                                <span>
                                    {i18n.t('PROCEDURES').toUpperCase()}
                                    {(opMaintenance.id == this.props.match.params.opMaintenanceId) && <span className="number-badge">
                                        {proceduresFormData && proceduresFormData.length}
                                    </span>}
                                    {(opMaintenance.id != this.props.match.params.opMaintenanceId) && <span className="number-badge">
                                        {proceduresFormData && <FontAwesomeIcon icon={faSpinner} pulse />} 
                                    </span>}
                                </span>
                            }
                        >
                            <div>
                                {fullImage && <ImageViewerComponent
                                    toggleImage={this.displayFullImage}
                                    imgSrc={fullImage}

                                />}
                                {(!proceduresFormData || (proceduresFormData && proceduresFormData.length === 0)) && <div className="no-results"><p>{i18n.t('NO_PROCEDURES')}</p></div>}
                                {proceduresFormData?.length > 0 && opMaintenance.id == this.props.match.params.opMaintenanceId &&<Accordion
                                    id="route-phase-panel"
                                    className="margin-top-15"
                                    defaultActiveKey={parseInt(this.state.rowId, 10) >= 0 ? this.state.rowId : null}
                                >
                                    {proceduresFormData.map((procedure, i) => {
                                        return (<div key={i}>
                                            <PanelContainer
                                                id={i}
                                                title={procedure.caption}
                                                panelIcons={iconSets[procedure.id]}
                                                subTitle={''}
                                            >
                                                <div className="no-paddings clearfix">
                                                    <div className="v-margins-15">
                                                        {HasRight('faultnotice', settings.userRights) && <div>
                                                            <span>{i18n.t('NOTES')}</span>
                                                        </div>}
                                                        <div className="form-table-container">
                                                            <OperatorProcedureForm
                                                                onChange={this.onChange}
                                                                procedurePhase={procedure}
                                                                loadStatus={loadStatus}
                                                                procedureFileData={procedureFileData}
                                                                isDoneValue={this.state['isDone' + procedure.id]}
                                                                commentValue={this.state['comment' + procedure.id]}
                                                                workHoursValue={this.state['hoursplanned' + procedure.id]}
                                                                displayFullImage={() => this.displayFullImage(procedureFileData[procedure.id].url)}
                                                                phaseWorkHoursEnabled={phaseWorkHoursEnabled}
                                                            />
                                                        </div>
                                                        <div className="route-phase-buttons">
                                                            {HasRight('faultnotice', settings.userRights) && <Button variant="success" className="action float-right" size="lg" onClick={() => this.addFaultNotice(i)}>
                                                                <FontAwesomeIcon icon={faClipboardList} size="lg" /> {i18n.t('FAULT_NOTICE')}
                                                            </Button>}
                                                            {HasRight(UserRights.CountersView, settings.userRights) && counters.length > 0 && 
                                                                <Button variant="success" className="action float-right" size="lg" onClick={() => {
                                                                    this.props.history.replace({
                                                                        state: { 
                                                                            ...(this.props.location.state ?? {}), 
                                                                            opmState: this.state
                                                                        }
                                                                    })
                                                                    this.props.history.push(`/counters?machineIds=${opmMachine.id}`);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faCalculator} size="lg" /> {i18n.t('COUNTERS')}
                                                            </Button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </PanelContainer>
                                            {i + 1 < proceduresFormData.length && <hr className="v-margins-5" />}
                                        </div>)
                                    }
                                    )}
                                </Accordion>}
                            </div>
                        </Tab>
                        <Tab
                            eventKey={3}
                            title={detailsTabTitle}
                        >
                            <OperatorMaintenanceDetails
                                machineId={opmMachine.id}
                                operatorMaintenenaceId={Number(this.props.match.params.opMaintenanceId)}
                                openWorkCardsTotalCount={this.state.openWorkCardTotalCount}
                                openWorkCards={openWorkCards ?? []}
                                previousWorkCards={this.state.previousWorkCards}
                                operatorMaintenanceDocuments={this.state.opmDocs}
                                loadingPanels={this.state.loadingDetails}
                            />
                        </Tab>
                    </Tabs>
                </div>}
            </div>
        );
    }
}

export default OperatorMaintenance;
