import React from 'react';
import i18n from 'translations/i18n';
import { Container } from 'react-bootstrap';
import ErrorMessage from 'components/ErrorMessage';
import SelectField from 'components/form/SelectField';
import FormElement from 'scenes/work-schedule/work-card/components/FormElement';

import measurementUtils from './utils';
import { handleDateTimeInput } from 'utils';

const MeasurementForm = (props: IProps) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        props.setMeasurementValues({ ...props.measurementValues, [name]: value })
        props.setHasChanged(true);
    }

    const handleCheckbox = (value, name) => {
        props.setMeasurementValues({
            ...props.measurementValues,
            [name]: !value ? true : false
        })
        props.setHasChanged(true);
    }

    const handleDateTime = (date, name) => {
        const { value } = handleDateTimeInput(date, name);
        props.setMeasurementValues({ 
            ...props.measurementValues,
            [name]: value
        })
        props.setHasChanged(true);
    }

    const handleSelect = (value, actionMeta) => {
        props.setMeasurementValues({
            ...props.measurementValues,
            [actionMeta.name]: value
        })
        props.setHasChanged(true);
    }

    const createField = (mQ: IMeasurementQuestion) => {
        if (mQ.useOptions) {
            return (
                <SelectField
                    name={mQ.caption}
                    label={i18n.t(mQ.caption)}
                    value={props.measurementValues[mQ.caption]}
                    options={mapValueOptions(mQ)}
                    onChange={handleSelect}
                />
            )
        } else {
            let onChange: Function = handleChange;
            if (mQ.type === 'BOOLEAN') { onChange = handleCheckbox; }
            if (mQ.type === 'DATETIME') { onChange = handleDateTime; }
            return (
                <FormElement
                    name={mQ.caption}
                    type={measurementUtils.elementTypes[mQ.type]}
                    label={i18n.t(mQ.caption)}
                    value={props.measurementValues[mQ.caption]}
                    onChange={onChange}
                />
            )
        }
    }

    const mapValueOptions = (mQ: IMeasurementQuestion) => {
        const qType = measurementUtils.questionTypes[mQ.type];
        return mQ.valueOptions.map(opt => ({ value: opt[qType], label: opt[qType] }))
    }

    return (
        <Container>
            <div className="form-table-container bottom-nav-space v-margins-15">
                <ErrorMessage show={props.showErrorMessage} />
                {props.measurementGroups && (<React.Fragment>
                    <SelectField
                        name={'measurementGroup'}
                        label={i18n.t('MEASUREMENT')}
                        value={props.measurementGroup}
                        options={props.measurementGroups}
                        onChange={(value) => props.setMeasurementGroup(value)}
                        noDefaultOption={true}
                        required
                    />
                    {props.measurementQuestions.length > 0 && <hr />}
                </React.Fragment>)}
                {props.measurementQuestions.filter(mQ => mQ.type !== 'LABEL').map(mQ => (
                    <React.Fragment key={mQ.id}>
                        {createField(mQ)}
                    </React.Fragment>
                ))}
            </div>
        </Container>
    )
}

interface IProps {
    showErrorMessage: boolean;
    measurementGroup?: IOptionType;
    measurementGroups?: IOptionType[];
    measurementQuestions: IMeasurementQuestion[];
    measurementValues: { [caption: string]: any; }
    setMeasurementGroup?: (value) => void;
    setMeasurementValues: (values) => void;
    setHasChanged?: (changed: boolean) => void;
}

export default MeasurementForm;