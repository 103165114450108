export const SortTypes = {
    worklist: {
        NEWEST: {
            id: 1,
            label: "NEWEST_FIRST"
        },
        OLDEST: {
            id: 2,
            label: "OLDEST_FIRST"
        },
        FAULT_NEWEST: {
            id: 3,
            label: "FAULT_BEGIN_NEWEST_FIRST"
        },
        FAULT_OLDEST: {
            id: 4,
            label: "FAULT_BEGIN_OLDEST_FIRST"
        },
        WORKBEGIN_NEWEST: {
            id: 5,
            label: "WORK_CAN_BEGIN_NEWEST_FIRST"
        },
        WORKBEGIN_OLDEST: {
            id: 6,
            label: "WORK_CAN_BEGIN_OLDEST_FIRST"
        }
    },
    safetyNotices: {
        NEWEST: {
            id: 1,
            label: "NEWEST_FIRST"
        },
        OLDEST: {
            id: 2,
            label: "OLDEST_FIRST"
        },
    }
};