import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import i18n from '../../translations/i18n';
import PopoverAction from '../PopoverAction';
import { Popover } from 'react-bootstrap';
import PanelText from './PanelText';
import { TruncateString } from 'components/HelperFunctions';

const DocumentPanelRow = (props: Props) => {

    const mainContent = (
        <React.Fragment>
            <PanelText>
                {TruncateString(props.documentName, 50)}
            </PanelText>
            <PanelText small>
                {props.documentDescription}
            </PanelText>
            {props.children || ''}
        </React.Fragment>
    )

    const contentRight = (
        <p className="small right">
            {props.documentDate}
        </p>
    )

    const actionWrapper = (el: JSX.Element) => (
        <div className="sub-section">
            <div className="setting-option">
                <div>
                    {el}
                </div>
            </div>
        </div>
    )

    const documentMenu = (
        <Popover id="settings-menu-popover" className="panel-menu-popover">
            {actionWrapper(
                <PopoverAction
                    icon="view"
                    label={i18n.t('VIEW_DOCUMENT')}
                    onClick={props.openDocument}
                />
            )}
            {props.allowDelete && actionWrapper(
                <PopoverAction
                    icon="delete"
                    label={i18n.t('REMOVE')}
                    onClick={props.deleteDocument}
                />
            )}
        </Popover>
    )

    const menuContainer = (
        <div className="options-container">
            <span className="bar" />
            <OverlayTrigger trigger="click" placement="left" overlay={documentMenu} rootClose>
                <span className="options-button" ><FontAwesomeIcon icon={faEllipsisV} size="lg" /></span>
            </OverlayTrigger>
        </div>
    )

    return (
        <Row className="item-container-with-color-border">
            <Col xs={7}>
                {mainContent}
            </Col>
            <Col xs={3}>
                {contentRight}
            </Col>
            <Col xs={2}>
                {menuContainer}
            </Col>
        </Row>
    )
}



interface IBaseProps {
    children?: React.ReactNode;
    documentName: string;
    documentDescription: string;
    documentDate: string;
    openDocument: () => void;
    allowDelete?: boolean;
    deleteDocument?: () => void;
}

interface IPropsWithDelete extends IBaseProps {
    allowDelete: boolean;
    deleteDocument: () => void;
}

interface IPropsWithoutDelete extends IBaseProps {
    allowDelete?: never;
    deleteDocument?: never;
}

type Props = IPropsWithDelete | IPropsWithoutDelete;

export default DocumentPanelRow;