import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchMachine, fetchMachineOptions, fetchMachineDetails, fetchMachineExtraData } from '../actions';
import { fetchViewSettings, fetchCostPools, fetchThirdParties } from '../../../commonActions/actions';
import { Container, Tab, Tabs } from 'react-bootstrap';
import '../../../styles/global.scss';
import '../styles/machines.scss';
import './../../work-schedule/work-list/styles/worklist.scss';
import NavigationBar from '../../navigation';
import { addMachine, updateMachine, updateTypeSpecificExtraDatas } from '../actions';
import Machines from '../';
import i18n from '../../../translations/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWorkcardOptions } from '../../work-schedule/work-card/actions';
import settingsAPI from '../../../config/settingsAPI';
import { RouteChildrenProps } from 'react-router-dom';
import MachineForm from './MachineForm';
import noviAPI from '../../../api/noviAPI';
import { Loader } from '../../../components/Loader';
import { toast } from 'react-toastify';
import {
    getExtraDataFieldValues,
    initValues,
    mapCostPoolsToCostPoolOptions,
    mapIdNameToOptionType,
    mapMachineDetailsToMachineDetailOptions,
    mapMachineRequirementsToMachineRequirementsValue,
    mapOldOptionTypesToOptionsType,
    mapThirdPartiesToThirdPartyOptions,
    mapValuesToMachineAddition,
    mapValuesToMachineUpdate,
    validateForm
} from '../utils';
import { getInputDateTime } from 'utils';
import { HandleError, HasRight } from '../../../components/HelperFunctions';
import ThirdPartyAddition from 'components/ThirdPartyAddition';
import { UserRights } from 'constants/userRights';
import FormElement from 'scenes/work-schedule/work-card/components/FormElement';

interface MatchParams {
    machineId: string;
}

interface LocationState {
    referrer: string[];
}

type IProps = PropsFromRedux & RouteChildrenProps<MatchParams, LocationState>;

interface IState {
    machine: IMachine;
    values: { 
        [name: string]: any; 
    };
    typeSpecificValues: { 
        [name: number]: any; 
    };
    invalidFields: boolean;
    showMachines: boolean;
    showThirdPartyAddition: boolean;
    additionState: {
        type: number;
        scroll: number;
        submit: boolean;
    };
    status: string;
    machineFetched: boolean;
    initialized: boolean;
    localThirdParties: {
        number?: any[];
    };
    hasChanged: boolean;
    tabGroupActiveKey: string;
}

const moduleSettings = settingsAPI.moduleData.editmachine;

class EditMachine extends React.Component<IProps, IState> {
    thirdPartyRef = React.createRef<typeof ThirdPartyAddition>();

    constructor(props) {
        super(props);

        this.state = {
            machine: null,
            values: {},
            typeSpecificValues: {},
            invalidFields: false,
            showMachines: false,
            status: 'pending',
            machineFetched: false,
            initialized: false,
            showThirdPartyAddition: false,
            additionState: null,
            localThirdParties: {},
            hasChanged: false,
            tabGroupActiveKey: "1",
        }
    }

    componentDidMount() {
        const machineId = Number(this.props.match.params.machineId);
        if (machineId) {
            this.fetchMachine(machineId);
        } else {
            this.setState({ status: 'fulfilled' });
        }

        if (this.props.typeSpecificExtraDatas.length) {

            this.setState({
                typeSpecificValues: this.props.typeSpecificExtraDatas.reduce((a, c) => { 
                    return Object.assign({}, a, { [c.id]: c.value }); 
                }, {})
            });
        }

        this.props.fetchMachineOptions();
        this.props.fetchMachineDetails();
        this.props.fetchCostPools();
        this.props.fetchThirdParties();
        this.props.fetchWorkcardOptions();
        this.props.fetchMachineExtraData(machineId);

        moduleSettings.viewSettings.forEach(viewSetting => {
            this.props.fetchViewSettings(viewSetting.groupType, viewSetting.actionType);
        });
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {

        if (Object.keys(this.state.values).length == 0 && this.props.viewSettings.length > 0){
            this.setState({
                values: initValues(this.props.viewSettings)
            });
        }
        this.setFieldValues(prevProps, prevState);

        if (this.props.typeSpecificExtraDatas != prevProps.typeSpecificExtraDatas) {
            this.setState({
                typeSpecificValues: this.props.typeSpecificExtraDatas.reduce((a, c) => { 
                    return Object.assign({}, a, { [c.id]: c.value }); 
                }, {})
            });
        }
    }

    setFieldValues(prevProps: IProps, prevState: IState) {

        const { machineFetched, initialized } = this.state;
        // Set values after the machine is fetched, state.values are set and initial values 
        // are not yet placed in fields (when editing a machine)
        if (machineFetched && !initialized && Object.keys(this.state.values).length > 0) {
            const keysOfValues = Object.keys(this.state.values);
            let machineValues = {};

            // Set detail values
            const { extraDatas, machineDetails, thirdPartiesByTypes } = this.state.machine;

            if (machineDetails.length > 0) {

                // Assign detail to detailValues from machine when detail group and values property matches
                machineDetails.forEach(detail => {
                    if (keysOfValues.includes(detail.group)) {
                        // Initialize array before adding first detail to it
                        if (typeof machineValues[detail.group] === 'undefined') {
                            machineValues[detail.group] = [];
                        }
                        const detailOption = { value: `${detail.id}`, label: detail.value };
                        machineValues[detail.group].push(detailOption);
                    }
                });
            }

             // Set extra data values
            if (extraDatas.length > 0) {
                extraDatas.forEach(extradata => {
                    if (keysOfValues.includes(extradata.group)) {
                        Object.assign(machineValues, { [extradata.group]: extradata.value });
                    }
                });
            }

            // Set third party values
            if (thirdPartiesByTypes.length > 0) {

                // Assign third party to thirdPartyValues when third party type and values property matches
                thirdPartiesByTypes.forEach(thirdPartyByType => {
                    const typeId = thirdPartyByType.type.typeId;
                    const thirdParties = thirdPartyByType.thirdParties;

                    if (keysOfValues.includes(typeId.toString())) {
                        Object.assign(machineValues, {
                            [typeId]: thirdParties.map(i => ({ value: `${i.id}`, label: i.name }))
                        });
                    }
                });
            }


            // Set cost pool group values
            const { costPools } = this.state.machine;
            if (costPools.length > 0) {

                // Assign cost pool to costPoolValues from machine when cost pool group and values property matches
                costPools.forEach(costPool => {
                    const groupId = costPool.groupId;
                    const fieldName = `costpoolgroup_${groupId}`;

                    if (keysOfValues.includes(fieldName)) {
                        Object.assign(machineValues, {
                            [fieldName]: { value: `${costPool.id}`, label: costPool.translationKey }
                        });
                    }
                })
            }

            // Set extra data values
            if (extraDatas?.length > 0) {
                extraDatas.forEach(ext => {
                    if (keysOfValues.includes(ext.group)) {
                        Object.assign(machineValues, {
                            [ext.group]: ext.value || ext.valueNumber
                        });
                    }
                });
            }

            Object.keys(this.state.machine).forEach(key => {
                const lowerCaseKey = key.toLowerCase();
                // Assign a value from machine when machine property and values property matches
                if (keysOfValues.includes(lowerCaseKey)) {
                    let value = this.state.machine[key];
                    // Set machineRequirements
                    if (lowerCaseKey === 'machinerequirements') {
                        value = mapMachineRequirementsToMachineRequirementsValue(value);
                    }
                    // Set extraLocationText
                    if (lowerCaseKey === 'extralocation') { value = this.state.machine.extraLocationText; }
                    // Set datetime values
                    if (lowerCaseKey === 'purchasedate') { value = getInputDateTime(value); }
                    if (lowerCaseKey === 'deliverydate') { value = getInputDateTime(value); }
                    if (lowerCaseKey === 'deploymentdate') { value = getInputDateTime(value); }
                    if (lowerCaseKey === 'warrantyend') { value = getInputDateTime(value); }
                    Object.assign(machineValues, {
                        [lowerCaseKey]: value
                    });
                }
                else if (lowerCaseKey === 'responsibilityperson' && this.state.machine[key]) {
                    Object.assign(machineValues, {
                        person: mapIdNameToOptionType(this.state.machine[key])
                    });
                }
                // Assign a value from machine when machine property and values property are named differently
                else if (lowerCaseKey === 'type' && keysOfValues.includes('machinetype') && this.state.machine.type) {
                    Object.assign(machineValues, {
                        machinetype: mapIdNameToOptionType(this.state.machine.type)
                    });
                }
            });

            this.setState(prevState => ({
                values: Object.assign({}, prevState.values, machineValues),
                status: 'fulfilled',
                initialized: true
            }));
        }
    }

    getItemStatus = () => this.props.match.path === '/machine/new'
        || this.props.match.path === '/machine/new/:mId?'
        ? 'newMachine'
        : 'editMachine';

    fetchMachine = (machineId: number) => {
        noviAPI.machines.fetch(machineId)
            .then(response => {
                this.setState({ machine: response.data, machineFetched: true });
            })
    }


    submitThirdParty = (submit: boolean = true) => {
        this.setState(prevState => {
            return { 
                additionState: {
                    ...prevState.additionState,
                    submit: submit
                }
            }  
        });
    }

    handleSubmit = async () => {

        const { localThirdParties, values, typeSpecificValues } = this.state;
        const { extraData, viewSettings, history, machineGroupId, typeSpecificExtraDatas } = this.props;
        const isValid = validateForm(values, this.props.viewSettings);
        if (!isValid) {
            toast.error(i18n.t('INVALID_FIELDS'), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true
            });

            this.setState({ invalidFields: true });
            return;
        } else {
            this.setState({ invalidFields: false });
        }

        const localThirdPartiesToBeAdded = [];
        
        Object.entries(values).forEach(([ typeId, thirdParties ]) => {
            const localParties = localThirdParties[typeId];

            if (localParties) {

                const toBeAdded = thirdParties.filter(tP => tP.isLocal);

                toBeAdded.map(tP => {
                    const fullTP = localParties.find(x => x.code == tP.code);
                    if (fullTP) {
                        fullTP.ThirdPartyTypeIds = [typeId];
                        localThirdPartiesToBeAdded.push(fullTP);
                    }
                });
            }
        });

        if (localThirdPartiesToBeAdded.length) {
            const requests = localThirdPartiesToBeAdded.map(async (thirdParty) => {
                return await noviAPI.thirdParties.add(thirdParty)
                    .then(({ data: newThirdPartyId }) => {
                        const typeId = thirdParty.ThirdPartyTypeIds[0];

                        // Remove local temp thirdparties from the list and add the real thirdparties
                        values[typeId] = values[typeId].filter(tP => !tP.isLocal);
                        values[typeId].push({
                            value: newThirdPartyId,
                            label: thirdParty.name
                        });
                        return newThirdPartyId;
                    })
                    .catch(error => {
                        HandleError(error, "Failed to add thirdparty");
                        return error;
                    })
            })
            await Promise.all(requests);

        }
        if (this.getItemStatus() === 'newMachine') {
            noviAPI.machines.fetchByCode(values.code)
                .then((res) => {
                    if (res?.data?.id) {
                        toast.error(i18n.t('ALERT_USER_MACHINE_CODE_EXISTS'), {
                            position: toast.POSITION.TOP_CENTER,
                            hideProgressBar: true
                        });
                    } else {
                        const newMachine = mapValuesToMachineAddition(values, viewSettings, machineGroupId);
                        const extraDatas = getExtraDataFieldValues(values, viewSettings, extraData)

                        this.props.addMachine(newMachine, extraDatas, history.replace);
                    }
                }).catch(err => {
                    if (err.response.status == 404) {
                        const newMachine = mapValuesToMachineAddition(values, viewSettings, machineGroupId);
                        const extraDatas = getExtraDataFieldValues(values, viewSettings, extraData)

                        this.props.addMachine(newMachine, extraDatas, history.replace);
                    } else {
                        console.log(err);
                    }
                });
        } else {
            this.props.updateTypeSpecificExtraDatas(typeSpecificExtraDatas, typeSpecificValues);
            const machineUpdate = mapValuesToMachineUpdate(values, viewSettings, machineGroupId);
            const extraDatas = getExtraDataFieldValues(values, viewSettings, this.state.machine.extraDatas)
            this.props.updateMachine(this.state.machine.id, machineUpdate, extraDatas, history.replace);
        }
    }

    setValues = (fieldName, value) => {
        this.setState(prevState => ({
            ...prevState,
            values: {
                ...prevState.values,
                [fieldName]: value
            }, hasChanged: true
        }));
    }

    toggleMachines = () => {
        const { showMachines } = this.state;
        this.setState({
            showMachines: !showMachines
        })
    }

    navigateBackMachineSelect = (params) => {
        const referrer = [...params.referrer];
        this.props.history.replace(referrer.pop(), { referrer });
    }

    setParent = (selection, isLevel) => {
        const id = selection.id ? selection.id : selection;
        const selectedMachine = selection.id
            ? selection
            : this.props.hierarchyMachines.find(m => isLevel ? m.levelId === id : m.machineId === id);

        const name = isLevel
            ? selectedMachine.name
            : selectedMachine.machineCode + ' / ' + selectedMachine.name;

        this.setState(prevState => ({
            values: {
                ...prevState.values,
                parent: { id: id, name: name }
            }, hasChanged: true
        }));

        if (this.getItemStatus() === 'editMachine') {
            this.props.history.replace('/machine/' + this.props.match.params.machineId + '/edit/', true);
        }
        else {
            this.props.history.replace('/machine/new', true);
        }

        this.toggleMachines();
    }

    getOptions = () => {
        return {
            thirdPartyOptions: mapThirdPartiesToThirdPartyOptions(this.props.thirdParties),
            machineDetailOptions: mapMachineDetailsToMachineDetailOptions(this.props.machineDetails),
            machineTypeOptions: mapOldOptionTypesToOptionsType(this.props.machineTypeOptions),
            machineRequirementOptions: mapOldOptionTypesToOptionsType(this.props.machineRequirementOptions),
            costPoolOptions: mapCostPoolsToCostPoolOptions(this.props.costPools, this.state.machine?.costPools ?? []),
            personOptions: mapOldOptionTypesToOptionsType(this.props.personOptions),
            machineRatingOptions: mapOldOptionTypesToOptionsType(this.props.ratingOptions)
        };
    }


    handleTabGroup = (value) => {
        this.setState({ tabGroupActiveKey: value });
    }

    informationTabContent = () => {
        return (
            <Container className="margin-top-15">
                <div className="form-table-container bottom-nav-space margin-top-15">
                    <Loader status={this.state.status} />
                    {'fulfilled' === this.state.status &&
                        <MachineForm
                            viewSettings={this.props.viewSettings}
                            values={this.state.values}
                            setValues={this.setValues}
                            openMachineHierarchy={this.toggleMachines}
                            openThirdPartyAddition={(typeId) => {
                                this.setState({ 
                                    showThirdPartyAddition: true, 
                                    additionState: {
                                        type: typeId,
                                        scroll: window.scrollY,
                                        submit: false
                                    }
                                })}
                            }
                            thirdPartyAdditionEnabled={this.props.thirdPartyAdditionEnabled}
                            options={this.getOptions()}
                            validForm={!this.state.invalidFields}
                        />
                    }
                </div>
            </Container>
        );
    }

    detailsTabContent = () => {
        const { typeSpecificExtraDatas } = this.props;

        return (
            <Container className="margin-top-15">
                <div className="form-table-container bottom-nav-space margin-top-15">
                    <form onSubmit={e => { e.preventDefault(); e.stopPropagation(); }}>
                        {typeSpecificExtraDatas.map(data => (
                            <div key={`machine_type_specific_field_${data.id}`}>
                                <FormElement
                                    name={data.caption.caption}
                                    type={data.caption.dataType}
                                    label={i18n.t(data.caption.caption)}
                                    value={this.state.typeSpecificValues?.[data.id]}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        this.setState(prevState => ({
                                            typeSpecificValues: {
                                                ...prevState.typeSpecificValues,
                                                [data.id]: value
                                            }
                                        }));
                                    }}
                                    required={false}
                                />
                            </div>
                        ))}
                    </form>
                </div>
            </Container>
        );
    }

    render() {
        const { showMachines, showThirdPartyAddition, additionState, invalidFields } = this.state;
        const { history, location, typeSpecificExtraDatas } = this.props;


        let newReferrer;
        if (typeof location.state !== 'undefined') {
            if (!location.state.referrer) {
                newReferrer = [].concat(location.pathname)
            } else {
                newReferrer = [...location.state.referrer].concat(location.pathname)
            }
        } else {
            newReferrer = [].concat(location.pathname)
        }

        const backAction =
            showMachines
                ? newReferrer.length > 1
                    ? {
                        action: this.navigateBackMachineSelect,
                        params: {
                            referrer: location.state.referrer
                        }
                    }
                    : { action: this.toggleMachines }
                : showThirdPartyAddition
                    ? { action: () => this.setState({ showThirdPartyAddition: false }), state: { scrollY: this.state.additionState.scroll } }
                    : { action: history.goBack, params: { mainEditView: true }}

        const sceneData = {
            view: moduleSettings.name,
            title: showMachines
                ? i18n.t('CHOOSE_MACHINE')
                : showThirdPartyAddition
                    ? i18n.t("ADD_SUPPLIER")
                    : this.getItemStatus() === 'editMachine'
                        ? i18n.t('EDIT_MACHINE')
                        : i18n.t('ADD_MACHINE'),
            location: location,
            history: history,
            backAction: backAction,
            hasChanged: this.state.hasChanged
        };

        const viewAction = !showMachines
            ? {
                icon: showThirdPartyAddition ? 'confirm' : 'save',
                label: '',
                clickFn: showThirdPartyAddition ? this.submitThirdParty : this.handleSubmit,
                isActionFn: true,
                paClass: 'start-phase'
            } : null;
        return (
            <React.Fragment>
                <NavigationBar
                    currentView={sceneData}
                    navHistory={history}
                    viewAction={viewAction}
                    popoverData={''}
                />
                {showThirdPartyAddition && 
                    <ThirdPartyAddition 
                        addLocalThirdParty={(newThirdParty) => {
                            const { type } = this.state.additionState;
                            const tPartyOption = {
                                value: newThirdParty.id,
                                label: newThirdParty.name,
                                code: newThirdParty.code,
                                isLocal: true
                            }
                            this.setState(prevState => ({ 
                                showThirdPartyAddition: false,
                                localThirdParties: {
                                    ...prevState.localThirdParties,
                                    [type]: [
                                        ...(prevState.localThirdParties[type] ?? []).concat(newThirdParty)
                                    ]
                                },
                                values: {
                                    ...prevState.values,
                                    [type]: [...prevState.values[type] ?? [], tPartyOption]
                                }
                            }));
                            // Restore previous scroll position
                            window.scrollTo(0, this.state.additionState.scroll);
                        }}
                        additionState={additionState}
                        formRef={this.thirdPartyRef}
                        setSubmit={enabled => this.submitThirdParty(enabled)}
                    />}
                {showMachines &&
                    <Machines
                        machineSelect
                        itemStatus={this.getItemStatus()}
                        onMachineSelect={this.setParent}
                        referrer={newReferrer}
                    />
                }
                {!showMachines && !showThirdPartyAddition && (typeSpecificExtraDatas.length 
                    ?  (
                        <Tabs
                            id="machine-edit-tabs"
                            activeKey={this.state.tabGroupActiveKey}
                            onSelect={this.handleTabGroup}
                            defaultActiveKey={1}
                            className="novi-nav-tabs"
                        >
                            <Tab eventKey={1} tabClassName={invalidFields && 'invalid-tab-content'} title={i18n.t('INFORMATION').toUpperCase()}>{this.informationTabContent()}</Tab>
                            <Tab eventKey={2} title={i18n.t('DETAILS').toUpperCase()}>{this.detailsTabContent()}</Tab>
                        </Tabs>
                    ) : this.informationTabContent())}
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state: State) => {
    const { userRights } = state.settings;
    const hasThirdPartyAdditionRights = HasRight(UserRights.ThirdPartyAddition, userRights);

    return {
        viewSettings: state.machines.viewSettings.machine,
        machineDetails: state.machines.machineDetails,
        extraData: state.machines.machineExtraData,
        hierarchyMachines: state.machines.hierarchyMachines,
        thirdParties: state.settings.thirdParties,
        machineTypeOptions: state.machines.options.machineTypeOptions,
        machineRequirementOptions: state.machines.options.machineRequirementOptions,
        costPools: state.costpools.costPools.results,
        personOptions: state.machines.options.personOptions,
        ratingOptions: state.machines.options.ratingOptions,
        machineGroupId: state.settings.machineGroupId,
        thirdPartyAdditionEnabled: hasThirdPartyAdditionRights,
        typeSpecificExtraDatas: state.machines.machineExtraData,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchMachine,
    addMachine,
    updateMachine,
    updateTypeSpecificExtraDatas,
    fetchViewSettings,
    fetchMachineOptions,
    fetchMachineDetails,
    fetchWorkcardOptions,
    fetchMachineExtraData,
    fetchCostPools,
    fetchThirdParties,
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditMachine);
