import React from 'react';
import '../../styles/global.scss';
import './styles/navigation.scss';
import i18n from '../../translations/i18n';
import NavHeader from './components/NavHeader';
import NavFooter from './components/NavFooter';
import settingsAPI from '../../config/settingsAPI';
import { NavigationPropsFromRedux } from '.';
import { Offcanvas } from 'react-bootstrap';
import MainMenu from './components/MainMenu';
import ConfirmDialogComponent from '../../components/ConfirmDialogComponent';
import { UserRights } from 'constants/userRights';

interface IProps extends NavigationPropsFromRedux {
    currentView: {
        currentFilters?: any;
        filtersCount?: number;
        location: any;
        history: any;
        title: React.ReactText;
        view: string;
        itemColors?: IColor[];
        thumbnail?: string;
        subPhase?: string;
        subPhaseLabel?: string;
        noFilters?: boolean;
        backAction?: {
            action: (params?: object) => void;
            params?;
        };
        quickSearch?: { value: number; label: string; };
        noHierarchySelect?: boolean;
        doubleSelect?: boolean;
        handleQuickSearch?: (value) => void;
        onThumbnailClick?: () => void;
        dialogOpen?: boolean;
        closeDialog?: () => void;
        splitButtonMenu?: any;
        hasChanged?: boolean;
        safetyTypes: any[];
    }
    fetchData?: string;
    filters?: boolean;
    popover?: JSX.Element;
    quickSearchOptions?: any[];
    viewAction: any;
    popoverData?: any;
    navHistory?: any;
    updateSortType?: (newSort: object) => void;
};

interface IState {
    sidebarOpen: boolean;
    show: boolean;
    dialogOpen: boolean;
    menuItemOpen: any;
    unsavedChanges?: boolean;
}

class NavigationBar extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            sidebarOpen: false,
            show: false,
            dialogOpen: false,
            menuItemOpen: []
        };
    }

    componentDidMount() {
        const { currentView } = this.props;

        if (currentView.view !== 'login') {
            this.props.fetchUserRights()
        }
    }

    handleClick = (action) => {
        const { currentView } = this.props
        if (action === 'newFaultNotice' || action === 'newWorkCard') {
            currentView.history.push('/workcard/new', { cardType: action });
        }
    }

    getModulesData = () => {
        const { noviConfigs, safetyTypes } = this.props;
        let modules = { ...settingsAPI['novimodules'] };

        if (noviConfigs.OperatorMaintenances === 'True') {
            modules['operatormaintenance'] = {
                enabled: true,
                viewRight: 'operatormaintenanceview',
                type: 'operatormaintenance',
                props: null,
                icon: null,
                translation: 'OPERATOR_MAINTENANCE',
                link: '/operatormaintenances'

            }
        }

        if (noviConfigs.WarehousesEnabled === 'True') {
            modules['warehouse'] = {
                enabled: true,
                viewRight: 'warehouseview',
                type: 'warehouse',
                props: null,
                icon: null,
                translation: 'WAREHOUSE',
                link: '/warehouse'
            }
        }

        if (noviConfigs.HourCardsEnabled === 'True') {
            modules['hourcards'] = {
                enabled: true,
                viewRight: 'hourcardview',
                type: 'hourcards',
                props: null,
                icon: null,
                translation: 'HOURCARDS',
                link: '/hourcards'
            }
        }
        if (noviConfigs.SafetyEnabled === 'True') {

            safetyTypes.forEach((safetyType: ISafetyType) => {

                modules[safetyType.name] = {
                    enabled: true,
                    viewRight: [UserRights.SafetyView, UserRights.SafetyViewOwn],
                    type: 'safetytype',
                    props: null,
                    icon: null,
                    translation: `SAFETY_CARDS_HEADER${safetyType.id}`,
                    link: `/safety/${safetyType.id}`
                }
            });
        }

        return modules;
    }

    onSetSidebarOpen = () => {
        this.setState(state => ({
            sidebarOpen: !state.sidebarOpen
        }));
    }

    closeSideBar = () => {
        this.setState({ sidebarOpen: false });
    }

    toggleSubItems = obj => {
        if (!obj.subitems || !obj.subitems[0]) { return }

        let newOpen = [...this.state.menuItemOpen];
        newOpen[obj.type] = !this.state.menuItemOpen[obj.type];

        this.setState({
            menuItemOpen: newOpen
        });
    }

    togglePopover = (val = null) => {
        this.setState(prevState => ({ show: val !== null ? val : !prevState.show }));
    };

    backPressed = (unsaved = false) => {
        this.setState({ unsavedChanges: unsaved });
    };

    closeFiltersDialog = (close = false) => {
        this.setState({ dialogOpen: close ? false : !this.state.dialogOpen });
    }

    getCurrentSettings = () => {
        const { currentMachineGroup, machineGroups, userDetails, noviConfigs } = this.props;

        return {
            factoryOptions: {
                label: i18n.t('CURRENT_FACILITY'),
                current: currentMachineGroup,
                options: machineGroups
            },
            userDetails: userDetails,
            mobileUrl: noviConfigs.MobileUrl
        }
    }

    handleFactoryChange = (facility) => {
        this.props.setMachineGroupId(facility.value);
        this.props.currentView.history.replace('/dashboard');
    }

    logoutUser = () => {
        if (window['runConfig'].authGatewayURL) {
            this.props.logoutSSO();
        } else {
            this.props.logout();
            this.props.setStatusesFulfilled();
        }
    }

    handleTransition = () => {
        let routeHistory = this.props.currentView.history;
        const locationState = routeHistory.location.state; 
        if (locationState?.pathname) {
            routeHistory.push(locationState.pathname);
        }
    }

    getDialogContent = contentType => {
        if (!contentType) return {
            title: '',
            body: '',
            type: '',
            params: null
        }

        const dialogContents = {
            'confirm_backaction': {
                title: i18n.t("UNSAVED_INFORMATION"),
                body: i18n.t('RETURN_CONFIRM'),
                type: 'return',
                params: null
            }
        }

        return dialogContents[contentType];
    }

    resetDialogProps = () => {
        this.setState({
            unsavedChanges: false,
        });
    }

    dialogGoBack = () => {
        this.setState({
            unsavedChanges: false,
        });
        this.props.currentView.history.goBack();
    }

    render() {
        const { sidebarOpen, unsavedChanges } = this.state;
        const { currentView, userAdNameDetails, user, userRights } = this.props;
        const userId = userAdNameDetails?.id ? userAdNameDetails.id : user;

        const sceneData = {
            quickSearchData: {
                filters: this.props.filters,
                quickSearchOptions: this.props.quickSearchOptions,
                fetchData: this.props.fetchData,
                user: userId,
                noHierarchySelect: currentView.noHierarchySelect,
                quickSearch: this.props.currentView.quickSearch || null,
                doubleSelect: this.props.currentView.doubleSelect,
                ...(currentView.view === 'warehouse' && { handleQuickSearch: currentView.handleQuickSearch })
            },
            titles: {
                title: currentView.title,
                label: currentView.view,
                itemColors: currentView.itemColors,
                thumbnail: currentView.thumbnail,
                subPhase: currentView.subPhase,
                subPhaseLabel: currentView.subPhaseLabel,
                onThumbnailClick: currentView.onThumbnailClick
            },
            location: currentView.location,
            filters: {
                current: currentView.currentFilters,
                count: currentView.filtersCount,
                noFilters: currentView.noFilters
            },
            navFooter: {
                viewAction: this.props.viewAction,
                popover: this.props.popover,
                popoverData: this.props.popoverData,
                splitButtonMenu: this.props.currentView.splitButtonMenu,
                toggleSideBar: this.onSetSidebarOpen,
                backAction: currentView.backAction,
                showPopup: this.backPressed,
                togglePopover: this.togglePopover,
                show: this.state.show,
                open: sidebarOpen,
                dialogOpen: currentView.dialogOpen,
                closeDialog: currentView.closeDialog,
                hasChanged: currentView.hasChanged
            }
        }

        return (
            <div>
                {unsavedChanges && <ConfirmDialogComponent
                    dialogContent={this.getDialogContent("confirm_backaction")}
                    callBack={this.dialogGoBack}
                    cancelDialog={this.resetDialogProps}
                />}
                <NavHeader sceneData={sceneData} updateSortType={this.props.updateSortType} />
                <div>
                    <Offcanvas show={sidebarOpen} onHide={this.closeSideBar} onExited={this.handleTransition} scroll={false}>
                        <MainMenu
                            view={currentView.view}
                            toggleSubItems={this.toggleSubItems}
                            modules={this.getModulesData()}
                            permissionSettings={userRights}
                            itemStates={this.state.menuItemOpen}
                            settings={this.getCurrentSettings()}
                            onChange={this.handleFactoryChange}
                            logoutUser={this.logoutUser}
                            closeSideBar={this.closeSideBar}
                        />
                    </Offcanvas>
                    <div style={{ position: 'relative', zIndex: 9 }}>
                        <NavFooter sceneData={sceneData} />
                    </div>
                    {this.state.show && <div
                        className="popover-overlay"
                        onClick={() => this.togglePopover(false)}
                    ></div>}
                </div>
            </div>
        );
    }
}

export default NavigationBar;
