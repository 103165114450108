import React from 'react';
import { handleDateTimeInput } from 'utils';
import SelectField from '../../../components/form/SelectField';
import i18n from '../../../translations/i18n';
import FormElement from '../../work-schedule/work-card/components/FormElement';
import { validateField } from '../utils';

const MachineForm = (props: IProps) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setValues(e.target.name, e.target.value);
    }

    const handleDateTime = (date: moment.Moment | React.ChangeEvent<HTMLInputElement>, name: string) => {
        const dateInput = handleDateTimeInput(date, name);
        props.setValues(dateInput.name, dateInput.value);
    }

    const handleSelect = (value, actionMeta) => {
        props.setValues(actionMeta.name, value);
    }

    const handleCheckbox = (value, name) => {
        props.setValues(name, !value);
    }

    const filterOptionsByInput = (inputValue = '', options: IOptionType[]) => {
        if (inputValue.length < 3) return [];
        return options.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const promiseOptions = (fn, options) => {
        return (inputValue) =>
            new Promise(resolve => {
                resolve(fn(inputValue, options));
            });
    }

    const getField = (viewSetting: IViewSetting) => {
        if (viewSetting.type === 'detail' || viewSetting.type === "multidetail") {
            return (
                <SelectField
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={props.values[viewSetting.field]}
                    options={props.options.machineDetailOptions[viewSetting.field] || []}
                    onChange={handleSelect}
                    required={viewSetting.required}
                    isMulti={viewSetting.type === "multidetail"}
                    noDefaultOption
                />
            );
        }
        // Requires probably some advanced logic when editing of machine type specific extra data is implemented
        if (viewSetting.type === 'machinetype') {
            return (
                <SelectField
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={props.values[viewSetting.field]}
                    options={props.options.machineTypeOptions || []}
                    onChange={handleSelect}
                    required={viewSetting.required}
                />
            );
        }
        if (viewSetting.type === 'person') {
            return (
                <SelectField
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    // Type = person, field might be something else
                    value={props.values[viewSetting.type]}
                    options={props.options.personOptions || []}
                    onChange={handleSelect}
                    required={viewSetting.required}
                />
            );
        }
        if (viewSetting.type === 'requirements') {
            return (
                <SelectField
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={props.values[viewSetting.field]}
                    options={props.options.machineRequirementOptions || []}
                    onChange={handleSelect}
                    required={viewSetting.required}
                    isMulti
                    noDefaultOption
                />
            );
        }
        if (viewSetting.type === 'rating') {
            return (
                <SelectField
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={props.values[viewSetting.field]}
                    options={props.options.machineRatingOptions || []}
                    onChange={handleSelect}
                    required={viewSetting.required}
                />
            );
        }
        if (viewSetting.type === 'costpoolgroup') {
            let options: IOptionType[] = [];
            try {
                const costPoolGroup = viewSetting.field.split('_')[1];
                options = props.options.costPoolOptions[costPoolGroup] || [];
            } catch (e) {
                console.log(e);
            }
            return (
                <SelectField
                    name={viewSetting.field}
                    label={i18n.t(viewSetting.translationKey)}
                    value={props.values[viewSetting.field]}
                    options={options}
                    onChange={handleSelect}
                    required={viewSetting.required}
                />
            );
        }
        if (viewSetting.type === 'thirdparty') {
            const value = props.values[viewSetting.field];
            const showThirdPartyAddition = props.thirdPartyAdditionEnabled && !(value?.some(tP => tP?.isLocal));

            return <FormElement
                name={viewSetting.field}
                type={showThirdPartyAddition ? 'thirdparty+addition' : 'thirdparty'}
                label={i18n.t(viewSetting.translationKey)}
                value={value}
                options={promiseOptions(filterOptionsByInput, props.options.thirdPartyOptions[viewSetting.field])}
                onChange={handleSelect}
                extraFunctions={() => {
                    props.openThirdPartyAddition(parseInt(viewSetting.field));
                }}
                required={viewSetting.required}
            />
        }
        if (viewSetting.type === 'boolean') {
            return <FormElement
                name={viewSetting.field}
                type="singleline-checkbox"
                label={i18n.t(viewSetting.translationKey)}
                value={props.values[viewSetting.field]}
                onChange={handleCheckbox}
            />
        }
        return <FormElement
            name={viewSetting.field}
            type={viewSetting.type}
            label={i18n.t(viewSetting.translationKey)}
            value={props.values[viewSetting.field]}
            onChange={
                viewSetting.type === 'datetime'
                    ? handleDateTime
                    : viewSetting.type === 'level'
                        ? props.openMachineHierarchy
                        : handleInputChange
            }
            required={viewSetting.required}
        />
    }

    return (
        <div className="form-table-container bottom-nav-space">
            <form>
                {props.viewSettings
                    .slice(0)
                    .sort((a, b) => a.tabOrder - b.tabOrder)
                    .map(viewSetting => (
                        <div
                            key={`machine_field_${viewSetting.id}`}
                            className={viewSetting.required && !props.validForm && !validateField(props.values[viewSetting.field]) ? 'invalid-field' : ''}
                            data-cy={viewSetting.field}
                        >
                            {getField(viewSetting)}
                        </div>
                    ))
                }
            </form>
        </div>
    );
}

interface IProps {
    viewSettings: IViewSetting[];
    values: { [fieldName: string]: any; };
    setValues: (fieldName, value) => void;
    options: Options;
    openMachineHierarchy: () => void;
    openThirdPartyAddition: (t: number) => void;
    thirdPartyAdditionEnabled: boolean;
    validForm: boolean;
}

type Options = {
    thirdPartyOptions: OptionGroup;
    machineDetailOptions: OptionGroup;
    costPoolOptions: OptionGroup;
    machineTypeOptions: IOptionType[];
    machineRequirementOptions: IOptionType[];
    personOptions;
    machineRatingOptions;
}

type OptionGroup = { [name: string]: IOptionType[]; }

export default MachineForm;