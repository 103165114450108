export enum QuickSearches {
    WorkScheduleQuickSearch = "workschedulequicksearch",
    ChangeToolQuickSearch = "changetoolquicksearch",
    MachineRegistryQuickSearch = "machineregistryquicksearch",
    ProjectQuickSearch = "projectquicksearch",
    PurchaseContractQuickSearch = "purchasecontractquicksearch",
    SafetyNoticeQuickSearch = "safetynoticequicksearch",
    SparePartQuickSearch = "sparepartquicksearch",
    TaskItemQuickSearch = "taskitemquicksearch",
    thirdpartyregistryQuickSearch = "thirdpartyregistryquicksearch",
    WarehouseQuickSearch = "warehousequicksearch",
    PurchasesQuickSearch = "purchasesquicksearch",
    WarehouseLogsQuickSearch = "warehouselogsquicksearch"
}