import Purchases from './Purchases';
import { bindActionCreators } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { fetchWarehouses } from '../warehouse/actions';
import { addPurchaseOrder, addPurchaseOrderItem, fetchPurchaseOrderItems } from './actions';
import { fetchWorkCard, fetchWorkCardMaterials } from '../work-schedule/work-card/actions';
import { fetchViewSettings, fetchCostPools } from './../../commonActions/actions';


const mapStateToProps = (state: State) => {
    return {
        viewSettings: state.warehouse.viewSettings ? state.warehouse.viewSettings.sparepart : null,
        settings: state.settings,
        warehouses: state.warehouse?.warehouses,
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchWarehouses,
    addPurchaseOrder,
    addPurchaseOrderItem,
    fetchPurchaseOrderItems,
    fetchWorkCardMaterials,
    fetchViewSettings,
    fetchWorkCard,
    fetchCostPools
}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Purchases);